import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import {
    CSS2DRenderer,
    CSS2DObject,
} from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { SelectionBox } from 'three/examples/jsm//interactive/SelectionBox.js';
import { ThreeSelectionHelper } from './three-selection-helper';
import { PathObject } from '../_models/path-object';
import { ObjectTypeEnum } from '../_enum/object-type-enum';
import { ThreeObjectControlsEnum } from '../_enum/three-object-controls.enum';
import { FreeShapeLineDetails } from '../_models/freeshape-line-details';
import { UploadedObjectNameEnum } from '../_enum/uploaded-object-type.enum';
import { ThreeGroupEnum } from '../_enum/three-group.enum';
import { ThreeObjectsEnum } from '../_enum/three-objects.enum';
import { Observable, Subject } from 'rxjs';
import { FreezeAction } from '../_models/freeze-action';
import { ThreeUtils } from './three.utils';
import { FreezeActionEnum } from '../_enum/freeze-action.enum';

export class ThreeInstance {
    public static freezeSubject = new Subject<FreezeAction>();

    public scene: THREE.Scene;
    public camera: THREE.PerspectiveCamera;
    public renderer: THREE.WebGLRenderer;
    public controls: OrbitControls;
    public transformControls: TransformControls;
    public raycaster = new THREE.Raycaster();
    public groundGroup: THREE.Group;
    public backgroundGroup: THREE.Group;
    public allObjectsGroup: THREE.Group;
    public objectsGround: THREE.Group;
    public objectsRegular: THREE.Group;
    public transformGroup: THREE.Group;
    public helpersGroup: THREE.Group;
    public intersectedType: ObjectTypeEnum;
    public currentCoordinates: any[] = [];
    public currentInflatedCoordinates: THREE.Vector2[] = [];
    public maxXPointFromArray: any;
    public minYPointFromArray: any;
    public states: any[] = [];
    public perspectiveCubeState = 0;
    public numberOfObjectsToBeLoaded: number;
    public isPublished = false;
    public mylatesttap: number;
    public changeControl = 'changeControl';
    public mobileObjectsIds = [4001, 4002, 6006];
    public currentFocusedObject: any;
    public controlBtns: CSS2DObject;
    public lockedInfo: CSS2DObject;
    public infoParagraph: CSS2DObject;
    public pinCommentPopup: CSS2DObject;
    public htmlRenderer: CSS2DRenderer;
    public loadedMeshes: Map<THREE.Group, THREE.AnimationClip> = new Map<
        THREE.Group,
        THREE.AnimationClip
    >();
    public animationMixers: THREE.AnimationMixer[] = [];
    public clock = new THREE.Clock();
    public objectsToCopy: PathObject[];
    public newGroup: any[];
    public selectionObjects: THREE.Mesh[] = [];
    public defaultMaterialOptions = {
        transparent: true,
        depthTest: true,
        depthWrite: false,
        polygonOffset: true,
        polygonOffsetFactor: -4,
    };
    public customCubeMaxSize = 40;
    public activeTab: string = ThreeObjectControlsEnum.move;
    public lastDragPosition: THREE.Vector2 = new THREE.Vector2(0, 0);
    public lastEvent: MouseEvent | Touch;
    public freeShapeLineDetails?: FreeShapeLineDetails =
        new FreeShapeLineDetails();
    public selectionBox: SelectionBox;
    public selectionHelper: ThreeSelectionHelper;
    public freeshapeHelper: THREE.Mesh;
    public pillarHelper: THREE.Group;
    public pinHelper: THREE.Group;
    public loadedPin: THREE.Group;
    public uploadedObjectHelper: THREE.Group;
    public uploadedCustomObject: THREE.Mesh;
    public uploadedFixedbject: THREE.Mesh;
    public uploadedUnderground: THREE.Mesh;
    public multiselectGroup: any;
    private _intersectedObject: THREE.Object3D;


    public static getFreezeSubject(): Observable<FreezeAction> {
        return ThreeInstance.freezeSubject.asObservable();
    }

    public get customDesign(): any {
        if (!this.uploadedObjectHelper) {
            return;
        }
        return this.uploadedObjectHelper.children.filter(
            (groupChild) =>
                groupChild.name === UploadedObjectNameEnum.customObject
        )[0];
    }

    public get invisiblePlane(): any {
        if (!this.groundGroup) {
            return;
        }
        return this.groundGroup.children.find(
            (groupChild) => groupChild.name === ThreeGroupEnum.invisiblePlane
        );
    }

    public get areaPlane(): any {
        if (!this.groundGroup) {
            return;
        }
        return this.groundGroup.children.find(
            (groupChild) => groupChild.name === ThreeObjectsEnum.areaPlane
        );
    }

    public get freeshapeLinePoints(): THREE.Vector3[] {
        return this.freeShapeLineDetails.points;
    }

    public get intersectedObject(): THREE.Object3D {
        return this._intersectedObject;
    }

    public set intersectedObject(value: any) {
        this.freezeOrUnfreezeObject(
            this._intersectedObject as THREE.Mesh,
            FreezeActionEnum.unfreeze
        );
        this.freezeOrUnfreezeObject(value, FreezeActionEnum.freeze);
        this._intersectedObject = value;
    }

    private freezeOrUnfreezeObject(
        mesh: THREE.Mesh,
        action: FreezeActionEnum
    ): void {
        if (!mesh || mesh === undefined) {
            return;
        }
        const ids = ThreeUtils.getPathObjectIds([mesh]);
        const freezeAction = new FreezeAction(action, ids);
        ThreeInstance.freezeSubject.next(freezeAction);
    }
}
