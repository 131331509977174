import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'apps/furban-client/src/environments/environment';
import { map } from 'rxjs/operators';
import { BackgroundModel } from '@furban/utilities';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BackgroundMediaService {
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(private http: HttpClient) {}

    getBackgroundMedia(projectId): Observable<BackgroundModel> {
        return this.http
            .get(`${environment.apiPath}/background/${projectId}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as BackgroundModel));
    }

    updateBackgroundMedia(media: BackgroundModel) {
        return this.http
            .post(environment.apiPath + '/background/model', media, {
                headers: this._headers,
            })
            .pipe(map((result) => result as BackgroundModel));
    }

    uploadBackgroundMedia(formData: FormData) {
        return this.http
            .put(environment.apiPath + '/background/model', formData)
            .pipe(map((result) => result as BackgroundModel));
    }

    downloadBackgroundMedia(
        backgroundMediaId: string
    ): Observable<ArrayBuffer> {
        return this.http
            .get(
                `${environment.apiPath}/background/model/${backgroundMediaId}`,
                {
                    headers: this._headers,
                    responseType: 'arraybuffer',
                }
            )
            .pipe(map((result) => result));
    }
}
