<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'generic.imageUpload' | translate }}</h2>
    <button class="ghost-button" (click)="closeImageUploadModal()">
        <span class="material-icons"> close </span>
    </button>
</div>
<mat-dialog-content>
    <input
        class="custom-photo-input"
        type="file"
        (change)="fileChangeListener($event)"
        [accept]="fileExtentionFormat"
    />
    <img-cropper
        #cropper
        class="image-cropper"
        [image]="data"
        [settings]="cropperSettings"
    ></img-cropper>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="cancelImageCrop()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="cropImage()">
        <h6>{{ 'generic.okBtn' | translate }}</h6>
    </button>
</div>
