import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentFormComponent } from './comment-form/comment-form.component';
import { CommentActionsComponent } from './comment-actions/comment-actions.component';
import { CommentComponent } from './comment/comment.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    AuthService,
    ModalManager,
    ModalConfigService,
    UtilitiesModule,
    FurbanMaterialModule,
} from '@furban/utilities';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PollOptionsComponent } from './poll-options/poll-options.component';

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule,
        FurbanMaterialModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        PickerModule,
        EmojiModule,
    ],
    declarations: [
        CommentFormComponent,
        CommentActionsComponent,
        CommentComponent,
        PollOptionsComponent
    ],
    exports: [CommentFormComponent, CommentActionsComponent, CommentComponent, PollOptionsComponent],
    providers: [AuthService, ModalConfigService, ModalManager],
})
export class CommonComponentsModule { }
