import {
    Component,
    OnInit,
    Input,
    Output,
    OnChanges,
    SimpleChanges,
    EventEmitter
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';

import {
    Project,
    DesignProposalEnum,
    DesignProposal,
    PathObject,
    ThreeStateEnum,
    FurbanUtil,
    DesignProposalService,
    PathObjectsService,
    MapService,
    routingConstants,
    Menu3DEventsEnum,
    SortTypeEnum,
    DesignProposalRequestParam,
    ProjectStorageService,
    DesignProposalIsAdminFilterEnum,
    MediaService
} from '@furban/utilities';

// REFACTOR -animation out
@Component({
    selector: 'furban-designs-viewer',
    templateUrl: './designs-viewer.component.html',
    styleUrls: ['./designs-viewer.component.scss'],
    animations: [
        trigger('carouselAnimation', [
            transition('void => *', [
                style({ opacity: 0 }),
                animate('600ms', style({ opacity: 1 })),
            ]),
            transition('* => void', [animate('300ms', style({ opacity: 0 }))]),
        ]),
    ],
})
export class DesignsViewerComponent implements OnInit, OnChanges {
    @Input() public project: Project;
    @Output() currentDp: EventEmitter<DesignProposal> =
        new EventEmitter<DesignProposal>();
    public curentPage = 0;
    public filterType: string = DesignProposalEnum.allDesignProposals;
    public curentDPs: DesignProposal[];
    public designProposal: DesignProposal;
    public defaultObjects: PathObject[];

    public threeIsPrepared: boolean;
    public threeState = ThreeStateEnum.view;
    public projectId: string;
    public dpNo: number;
    public displayedDPIndex: number;
    public isSwipeActive: boolean;
    public loadedDP: number;
    public currentDisplayedDp: DesignProposal;
    public getColorBasedOnProposalType = FurbanUtil.getColorBasedOnProposalType;
    public getDesignProposalTypeText = FurbanUtil.getDesignProposalTypeText;

    public currentObjects?: PathObject[];

    public swipeAction = { left: 'swipeleft', right: 'swiperight' };

    public viewDetailsMode: boolean;
    public percentage: number;

    constructor(
        private dpService: DesignProposalService,
        private pathObjectsService: PathObjectsService,
        private mapService: MapService,
        private router: Router,
        private mediaService: MediaService,
        private projectStorageService: ProjectStorageService
    ) { }

    ngOnInit(): void {
        this.initializeValues();
        this.getAllDesignProposals(SortTypeEnum.SORT_BY_DATE);
        this.projectStorageService.projectChanged.subscribe(() => {
            this.viewDetailsMode = false;
            this.percentage = 0;
        });
    }

    public getUserMedia(mediaId: string): string {
        return this.mediaService.getUserMedia(mediaId);
    }

    initializeValues() {
        this.defaultObjects = [];
        this.projectId = this.project.projectId;
        this.isSwipeActive = true;
        this.threeIsPrepared = false;
        this.loadedDP = 0;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.project = changes['project'].currentValue;
        if (
            !changes['project'].firstChange &&
            changes['project'].currentValue.projectId !==
            changes['project'].previousValue.projectId
        ) {
            this.ngOnInit();
        }
    }

    getAllDesignProposals(sortingCriteria) {
        const dpRequestParam = new DesignProposalRequestParam(
            this.projectId,
            this.curentPage,
            sortingCriteria,
            this.filterType
        );
        dpRequestParam.isAdminDesignFilter =
            DesignProposalIsAdminFilterEnum.allDesigns;
        const requests = [
            this.dpService.getDesignProposals(dpRequestParam),
            this.mapService.getPolygon(this.projectId),
            this.pathObjectsService.getPathDefaultObjects(this.projectId),
        ];
        forkJoin(requests).subscribe((data) => {
            this.defaultObjects = data[2] as PathObject[];
            this.curentDPs = data[0];
            if (this.curentDPs.length === 0) {
                this.threeIsPrepared = true;
            }
            this.dpNo = this.curentDPs.length;
            this.currentDisplayedDp = this.curentDPs[0];
            this.currentDp.emit(this.currentDisplayedDp);
            this.displayedDPIndex = 0;
            for (const dp of this.curentDPs) {
                this.getDesignProposalMedia(dp);
            }
            this.threeIsPrepared = true;
        });
    }

    getDesignProposalObjects(dp: DesignProposal) {
        this.currentObjects = [...this.defaultObjects];

        if (dp.groupId) {
            this.viewDetailsMode = true;
            //TO-DO: Should load here objects from collaborative design
            return;
        }

        if (dp.isAdminDesign) {
            this.getMunicipalityDesignObjects(dp);
        } else {
            this.getCitizenDesignObjects(dp);
        }
    }

    getDesignProposalMedia(dp: DesignProposal) {
        dp.src = dp.media
            ? this.getUserMedia(dp.media)
            : '../../../assets/images/default-project.png';
    }

    swipe(action = this.swipeAction.right) {
        this.isSwipeActive = true;
        this.percentage = 0;

        // out of range
        if (
            this.displayedDPIndex > this.curentDPs.length ||
            this.displayedDPIndex < 0
        ) {
            return;
        }
        let nextIndex = 0;

        // swipe right, next avatar
        if (action === this.swipeAction.right) {
            const isLast = this.displayedDPIndex === this.curentDPs.length - 1;
            nextIndex = isLast ? 0 : this.displayedDPIndex + 1;
        }

        // swipe left, previous avatar
        if (action === this.swipeAction.left) {
            const isFirst = this.displayedDPIndex === 0;
            nextIndex = isFirst
                ? this.curentDPs.length - 1
                : this.displayedDPIndex - 1;
        }
        this.displayedDPIndex = nextIndex;
        this.currentDisplayedDp = this.curentDPs[this.displayedDPIndex];
        this.viewDetailsMode = false;
    }

    holdHandler(event: string) {
        if (event === Menu3DEventsEnum.longPress) {
            this.percentage = 100;
            this.getDesignProposalObjects(this.currentDisplayedDp);
            this.isSwipeActive = false;
            return;
        }

        if (!isNaN(Number(event))) {
            this.percentage = Number(event);
        } else {
            this.percentage = 0;
        }
    }

    openDesignProposals() {
        this.router.navigate([routingConstants.designs, this.projectId]);
    }

    close3D() {
        this.viewDetailsMode = false;
        this.isSwipeActive = true;
        this.percentage = 0;
    }

    private getCitizenDesignObjects(dp: DesignProposal) {
        this.pathObjectsService
            .getPathObjects(dp.projectId, dp.userProfile.userProfileId)
            .subscribe((objects) => {
                this.concatDesignProposalSpecificObjects(
                    objects as PathObject[]
                );
            });
    }

    private getMunicipalityDesignObjects(dp: DesignProposal) {
        this.pathObjectsService
            .getPathObjectsForDefaultDP(dp.projectId, dp.designProposalId)
            .subscribe((objects) => {
                this.concatDesignProposalSpecificObjects(
                    objects as PathObject[]
                );
            });
    }

    private concatDesignProposalSpecificObjects(objects: PathObject[]) {
        this.currentObjects = this.currentObjects.concat(objects);
        this.viewDetailsMode = true;
    }
}
