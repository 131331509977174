<div class="flex-row-spaced">
    <h3>{{ 'collaborativeDesign.designContributors' | translate }}</h3>
    <button class="ghost-button" (click)="hideContributors()">
        <span class="material-icons">close</span>
    </button>
</div>

<section
    class="flex-row-start"
    id="collaborators-section"
    [style.margin-top.px]="20"
>
    <ng-container *ngFor="let user of contributorsList | slice : 0 : 5">
        <furban-avatar-picture
            [name]="user.screenName"
            [size]="40"
            [mediaId]="getUserMedia(user.mediaId)"
            [style.margin-right.px]="12"
        >
        </furban-avatar-picture>
    </ng-container>

    <div
        class="image-badge"
        matTooltip="{{ 'collaborativeDesign.viewAll' | translate }}"
        (click)="openContributorsDetails()"
    >
        <span class="material-icons"> more_horiz </span>
    </div>
</section>
