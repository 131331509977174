import { Component, OnInit, ViewContainerRef } from '@angular/core';

import {
    AuthService,
    Card,
    Client,
    routingConstants,
    ShepherdTourService,
    TourNameEnum,
} from '@furban/utilities';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TermsAndConditionsComponent } from '../../shared/terms-and-conditions/terms.component';

@Component({
    selector: 'furban-pioneer-dashboard',
    templateUrl: './pioneer-dashboard.component.html',
    styleUrls: ['./pioneer-dashboard.component.scss'],
})
export class PioneerDashboardComponent implements OnInit {
    public cardConfig: Card = new Card();
    public whiteCardConfig: Card = new Card();
    public client: Client;

    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        private viewContainerRef: ViewContainerRef,
        private shepherdTourService: ShepherdTourService,
        private router: Router
    ) { }

    ngOnInit() {
        this.setCardConfiguration();
        if (!this.authService.userProfile.acceptedTOS) {
            setTimeout(() => {
                this.openTermsModal();
            });
        }
        this.getClient();
    }

    public openTermsModal() {
        const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
            width: '60%',
        });
        dialogRef.disableClose = true;
        dialogRef.componentInstance.parentRef = this.viewContainerRef;

        dialogRef.afterClosed().subscribe((result) => {
            if (result && this.authService.isPioneer()) {
                localStorage.setItem('has-dashboard-tour', 'false');
                this.shepherdTourService.startTour(TourNameEnum.dashboardTour);
            }
        });
    }

    public checkForTour() {
        const hasTour = localStorage.getItem('has-dashboard-tour');
        if (this.authService.isPioneer() && hasTour && hasTour === 'false') {
            this.shepherdTourService.startTour(TourNameEnum.dashboardTour);
        }
    }

    public goToProjects() {
        this.router.navigate([routingConstants.initiativesProjectList]);
    }

    public createInitiativeProject() {
        this.router.navigateByUrl(routingConstants.initiativesProjectNew);
    }

    public createPermitProject() {
        this.router.navigateByUrl(routingConstants.permitProjectNew);
    }

    private setCardConfiguration(): void {
        this.cardConfig.cardType = 'colored';
        this.cardConfig.contentAlignmentHorizontal = 'start';
        this.cardConfig.contentAlignmentVertical = 'center';
        this.cardConfig.hasHeader = false;
        this.cardConfig.hoverable = true;

        this.whiteCardConfig.cardType = 'white';
        this.whiteCardConfig.contentAlignmentVertical = 'center';
        this.whiteCardConfig.contentAlignmentHorizontal = 'center';
        this.whiteCardConfig.hoverable = true;
    }

    private getClient(): void {
        if (this.authService.client) {
            this.client = this.authService.client;
            this.checkForTour();
        } else {
            this.authService.getClient().subscribe((data) => {
                this.client = data;
                this.checkForTour();
            });
        }
    }
}
