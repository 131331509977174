import * as THREE from 'three';
import { ThreeUtils } from './three.utils';

export class ThreeGeometryBuilder {
    public static createInflatedGeometryFromArrayOfPoints(
        currentCoordinates: any,
        hole?: THREE.Path
    ): THREE.ShapeGeometry {
        const shape = new THREE.Shape();
        const inflatedShape = ThreeUtils.inflatePolygon(currentCoordinates, 15);
        shape.moveTo(inflatedShape[0].x, inflatedShape[0].y);

        inflatedShape.forEach((coordinate) => {
            shape.lineTo(coordinate.x, coordinate.y);
        });

        if (hole) {
            shape.holes.push(hole);
        }

        return new THREE.ShapeGeometry(shape);
    }

    public static createGeometryFromArrayOfPoints(
        curentCoordinates: any,
        hole?: THREE.Path
    ): THREE.ShapeGeometry {
        const shape = new THREE.Shape();
        shape.moveTo(curentCoordinates[0].X, curentCoordinates[0].Y);

        curentCoordinates.forEach((coordinate) => {
            shape.lineTo(coordinate.X, coordinate.Y);
        });

        if (hole) {
            shape.holes.push(hole);
        }

        return new THREE.ShapeGeometry(shape);
    }

    public static createPlaneGeometry(
        width: number,
        height: number
    ): THREE.PlaneGeometry {
        return new THREE.PlaneGeometry(width, height);
    }

    public static createElipseGeometry(
        rx: number,
        ry: number
    ): THREE.ShapeGeometry {
        const curve = new THREE.Shape();
        curve.absellipse(0, 0, rx, ry, 0, Math.PI * 2, false, 0);
        return new THREE.ShapeGeometry(curve, 100);
    }

    public static createFreeshapeGeometry(
        parsedDimensions: any
    ): THREE.ShapeGeometry {
        for (let i = 0; i < parsedDimensions.length; i++) {
            parsedDimensions[i]['x'] = parseFloat(
                parsedDimensions[i]['x'].toFixed(3)
            );
            parsedDimensions[i]['y'] = parseFloat(
                parsedDimensions[i]['y'].toFixed(3)
            );
        }

        const shape = new THREE.Shape();
        shape.moveTo(parsedDimensions[0].x, parsedDimensions[0].y);

        parsedDimensions.forEach((coordinate) => {
            shape.lineTo(coordinate['x'], coordinate['y']);
        });

        return new THREE.ShapeGeometry(shape);
    }

    public static createCustomShapeGeometry(
        parsedDimensions: any
    ): THREE.BoxGeometry {
        return new THREE.BoxGeometry(
            parsedDimensions.width,
            parsedDimensions.depth,
            parsedDimensions.height
        );
    }
}
