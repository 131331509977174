import {
    Component,
    OnInit,
    ViewContainerRef,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import {
    House,
    FurbanUtil,
    HouseFormValuesEnum,
    HouseService,
    ColorsDefaultValuesEnum,
    PermitThreeService,
    ControlsUtil,
    PermitUtils,
} from '@furban/utilities';

import { HouseMaterial } from '@furban/utilities';

import { Roof } from '@furban/utilities';
import { MatRadioChange } from '@angular/material/radio';

@Component({
    selector: 'furban-house-drawing-form',
    templateUrl: './house-drawing-form.component.html',
    styleUrls: ['./house-drawing-form.component.scss'],
})
export class HouseDrawingFormComponent implements OnInit, OnDestroy {
    @Input() house: House;
    @Output() changedHouse: EventEmitter<House> = new EventEmitter();

    public permitBasicStepsForm: FormGroup;
    public parentRef: ViewContainerRef;
    public composeImageString = FurbanUtil.composeImageString;
    public getUrl = PermitUtils.getURLForMaterials;
    public houseMaterials: HouseMaterial[] = [];
    public roofMaterials: HouseMaterial[] = [];
    public selectedMaterial: HouseMaterial;
    public selectedRoofMaterial: HouseMaterial;
    private ngUnsubscribe = new Subject();
    private houseChangedObservable: Subscription;

    public hasControlMaxError = ControlsUtil.hasControlMaxError;
    public hasControlMinError = ControlsUtil.hasControlMinError;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;

    constructor(
        private formBuilder: FormBuilder,
        private permitThreeService: PermitThreeService,
        private houseService: HouseService
    ) { }

    ngOnInit() {
        this.getAllMaterials();

        this.permitBasicStepsForm = this.formBuilder.group(
            {
                numberOfFloors: [
                    HouseFormValuesEnum.numberOfFloorsDefault,
                    [
                        Validators.max(HouseFormValuesEnum.numberOfFloorsMax),
                        Validators.min(HouseFormValuesEnum.numberOfFloorsMin),
                        Validators.required,
                    ],
                ],
                floorHeight: [
                    HouseFormValuesEnum.floorHeightDefault,
                    [
                        Validators.max(HouseFormValuesEnum.floorHeightMax),
                        Validators.min(HouseFormValuesEnum.floorHeightMin),
                        Validators.required,
                    ],
                ],
                houseColorControl: [
                    `${ColorsDefaultValuesEnum.defaultHouseColor}`,
                    [],
                ],
                roofColorControl: [
                    `${ColorsDefaultValuesEnum.defaultRoofColor}`,
                    [],
                ],
            },
            { updateOn: 'blur' }
        );
        this.setModalWithServerData();
        this.subscribeToHouseChanged();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
        this.houseChangedObservable.unsubscribe();
    }

    setModalWithServerData(): void {
        if (this.house.numberOfFloors) {
            this.permitBasicStepsForm
                .get('numberOfFloors')
                .setValue(this.house.numberOfFloors);
        }
        if (this.house.floorHeight) {
            this.permitBasicStepsForm
                .get('floorHeight')
                .setValue(this.house.floorHeight);
        }
        if (this.house.houseColor) {
            this.permitBasicStepsForm
                .get('houseColorControl')
                .setValue(this.house.houseColor);
        }

        if (this.house.roof && this.house.roof.roofColor) {
            this.permitBasicStepsForm
                .get('roofColorControl')
                .setValue(this.house.roof.roofColor);
        }

        this.formChanged();
    }

    public objectComparisonFunction = (
        option: HouseMaterial,
        value: HouseMaterial
    ): boolean => option.materialId === value.materialId;

    public onRadioChange(event: MatRadioChange): void {
        this.houseDataUpdated();
    }

    prepareHouseForSaving(): House {
        const houseToReturn = House.copyHouse(this.house);
        houseToReturn.floorHeight =
            this.permitBasicStepsForm.get('floorHeight').value;
        houseToReturn.numberOfFloors =
            this.permitBasicStepsForm.get('numberOfFloors').value;
        houseToReturn.houseColor =
            this.permitBasicStepsForm.get('houseColorControl').value;

        houseToReturn.houseMaterial = this.selectedMaterial;

        if (houseToReturn.roof == null) {
            houseToReturn.roof = new Roof();
        }

        houseToReturn.roof.roofMaterial = this.selectedRoofMaterial;
        houseToReturn.roof.roofColor =
            this.permitBasicStepsForm.get('roofColorControl').value;

        return houseToReturn;
    }

    checkIfHouseMaterials(): boolean {
        return (
            this.houseMaterials &&
            this.houseMaterials.length > 0 &&
            this.selectedMaterial !== undefined
        );
    }

    checkIfRoofMaterials(): boolean {
        return (
            this.roofMaterials &&
            this.roofMaterials.length > 0 &&
            this.selectedRoofMaterial !== undefined
        );
    }

    emitHouseWhenUpdated(): void {
        const house = this.prepareHouseForSaving();
        this.changedHouse.emit(house);
    }

    houseDataUpdated(): void {
        if (this.permitBasicStepsForm.valid) {
            this.emitHouseWhenUpdated();
        }
    }

    roofMaterialUpdated(): void {
        this.permitBasicStepsForm
            .get('roofColorControl')
            .setValue(this.selectedRoofMaterial.materialColor);
        this.houseDataUpdated();
    }

    houseMaterialUpdated(): void {
        this.permitBasicStepsForm
            .get('houseColorControl')
            .setValue(this.selectedMaterial.materialColor);
        this.houseDataUpdated();
    }

    formChanged(): void {
        this.permitBasicStepsForm.statusChanges
            .pipe(debounceTime(300), takeUntil(this.ngUnsubscribe))
            .subscribe((val) => {
                this.houseDataUpdated();
            });
    }

    getAllMaterials(): void {
        if (
            !this.houseService.roofMaterials ||
            !this.houseService.houseMaterials
        ) {
            this.houseService.getAllMaterials().subscribe(() => {
                this.setDefaultMaterials();
            });
        } else {
            this.setDefaultMaterials();
        }
    }

    setDefaultMaterials(): void {
        this.houseMaterials = this.houseService.houseMaterials;
        this.roofMaterials = this.houseService.roofMaterials;
        this.selectedMaterial =
            this.house.houseMaterial != null
                ? this.house.houseMaterial
                : this.houseMaterials[0];
        this.selectedRoofMaterial =
            this.house.roof != null && this.house.roof.roofMaterial != null
                ? this.house.roof.roofMaterial
                : this.roofMaterials[0];
    }

    private setupHouseInformations(house: House): void {
        const doNotEmit = { emitEvent: false };

        if (
            this.permitBasicStepsForm.get('numberOfFloors').value !==
            house.numberOfFloors
        ) {
            this.permitBasicStepsForm
                .get('numberOfFloors')
                .setValue(house.numberOfFloors, doNotEmit);
        }
        if (
            this.permitBasicStepsForm.get('floorHeight').value !==
            house.floorHeight
        ) {
            this.permitBasicStepsForm
                .get('floorHeight')
                .setValue(house.floorHeight, doNotEmit);
        }
        if (
            this.permitBasicStepsForm.get('houseColorControl').value !==
            house.houseColor
        ) {
            this.permitBasicStepsForm
                .get('houseColorControl')
                .setValue(house.houseColor, doNotEmit);
        }
        if (
            this.house.roof.roofColor &&
            this.permitBasicStepsForm.get('roofColorControl').value !==
            house.roof.roofColor
        ) {
            this.permitBasicStepsForm
                .get('roofColorControl')
                .setValue(house.roof.roofColor, doNotEmit);
        }
        if (
            this.selectedMaterial.materialId !== house.houseMaterial.materialId
        ) {
            this.selectedMaterial = house.houseMaterial;
        }
        if (
            this.selectedRoofMaterial.materialId !==
            house.roof.roofMaterial.materialId
        ) {
            this.selectedRoofMaterial = house.roof.roofMaterial;
        }
    }

    private subscribeToHouseChanged(): void {
        this.houseChangedObservable =
            this.permitThreeService.houseUpdatedEmitter.subscribe((data) => {
                this.setupHouseInformations(data);
            });
    }
}
