<ul *ngIf="steps && currentStep" class="stepper">
    <li
        class="material-icons home"
        matTooltip="{{
            'pioneer.permitProcessDashboard.houseIcon' | translate
        }}"
        (click)="navigateToHome()"
    >
        home
    </li>

    <ng-container *ngIf="!isMobile; else mobileStepper">
        <li *ngFor="let step of steps" (click)="changeStep(step)">
            <span
                class="step"
                matTooltip="{{ step.title | translate }}"
                [class.future]="isFutureStep(step)"
                [class.next-step]="isHoverEnabled(step)"
            >
                <span [class.material-icons]="isStepCompleted(step)">{{
                    isStepCompleted(step) ? 'done' : step.id
                }}</span>
            </span>
            <p *ngIf="step.id === currentStep.id">
                {{ step.title | translate }}
            </p>
        </li>
    </ng-container>

    <ng-template #mobileStepper>
        <li>
            <span class="home-line"> </span>

            <span
                *ngIf="checkShowMoreLeft()"
                class="material-icons"
                [style.margin-left.px]="12"
                (click)="navigatePreviousStep()"
                >chevron_left</span
            >

            <span
                class="step"
                matTooltip="{{ currentStep.title | translate }}"
                (click)="changeStep(currentStep)"
            >
                <span [class.material-icons]="isStepCompleted(currentStep)">{{
                    isStepCompleted(currentStep) ? 'done' : currentStep.id
                }}</span>
            </span>
            <p>{{ currentStep.title | translate }}</p>

            <span
                *ngIf="checkShowMoreRight()"
                class="material-icons"
                (click)="navigateNextStep()"
                >chevron_right</span
            >
        </li>
    </ng-template>
</ul>
