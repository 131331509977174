export class CommentNotification {
    senderName: string;
    commentSourceName: string;
    isReply?: boolean;

    constructor(
        senderName: string,
        commentSourceName: string,
        isReply?: boolean
    ) {
        this.senderName = senderName;
        this.commentSourceName = commentSourceName;
        this.isReply = isReply;
    }
}
