export class PermitSparqlQuery {
    public static createQuery(buildingIdentificator: string) {
        return {
            query: `PREFIX bag: <http://bag.basisregistraties.overheid.nl/def/bag#>
      PREFIX geo: <http://www.opengis.net/ont/geosparql#>
      PREFIX geo2: <http://data.pdok.nl/def/pdok#>
      SELECT ?polygon  WHERE {
        ?x a bag:Pand.
          ?x bag:identificatiecode "${buildingIdentificator}".
          ?x bag:geometriePand ?geometry.
          ?geometry geo2:asWKT-RD ?polygon.
      }`,
            format: 'json',
        };
    }
}
