import { Furban3DModel } from './furban-3d-model';
import { SupplierTreeNode } from './supplier-tree-node';

export class LeafTreeNode implements SupplierTreeNode {
    public itemName: string;
    public itemId: string | number;
    public furban3DModel: Furban3DModel;
    public price: number;
    public canBeUnchecked = true;
    public enabled: boolean;
    public projectId: string;

    constructor(
        itemName: string,
        itemId: string | number,
        furban3DModel: Furban3DModel,
        price: number,
        projectId: string,
        enabled: boolean
    ) {
        this.itemName = itemName;
        this.itemId = itemId;
        this.furban3DModel = furban3DModel;
        this.price = price;
        this.projectId = projectId;
        this.enabled = enabled;
    }
}
