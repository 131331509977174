import { ImageUploadService } from './../../shared/image-upload/image-upload.service';
import { Component, ViewChild, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import {
    Path,
    CustomToasterComponent,
    AuthService,
    ModalManager,
    MapService,
    MenuService,
    ProjectService,
    StepperService,
    ToolingActionsEnum,
    CustomToasterService,
    FurbanUtil,
    GeocodingProvider
} from '@furban/utilities';
import { ProjectDetailsService } from '../project-details/project-details.service';
import { ToolingService } from '../../shared/tooling/tooling.service';
import { OpenMapComponent } from '../../shared/open-map/open-map.component';

@Component({
    selector: 'furban-app-project-map',
    templateUrl: './project-map.component.html',
    styleUrls: ['./project-map.component.scss'],
})
export class ProjectMapComponent implements OnInit, OnDestroy {

    @ViewChild(OpenMapComponent, { static: true }) child: OpenMapComponent;

    public projectId: string;
    public layerType: string;
    public snackBarRef: any;
    public filterOptions: Observable<string[]>;
    public adressFormGroup: FormGroup;
    public options: string[];
    public projectIsPublished = false;
    public searchPositionLeft: number;
    public searchPositionWidth: number;
    private toolingActionsSubscription: Subscription;
    public isProjectPublished: boolean;

    constructor(
        public projectService: ProjectService,
        public snackBar: MatSnackBar,
        private customToasterService: CustomToasterService,
        public translateService: TranslateService,
        public containerRef: ViewContainerRef,
        public modal: ModalManager,
        public menuService: MenuService,
        public projectDetailsService: ProjectDetailsService,
        public mapService: MapService,
        public formBuilder: FormBuilder,
        public route: ActivatedRoute,
        public router: Router,
        public authService: AuthService,
        private toolingService: ToolingService,
        private stepperService: StepperService,
        private imageUploadService: ImageUploadService
    ) { }

    public get geocodingProvider(): GeocodingProvider {
        return GeocodingProvider.nominatim;
    }

    public ngOnInit() {
        this.projectId = this.stepperService.project.projectId;
        this.setProjectStatus();

        this.layerType = 'Satelite';
        this.subscribeToToolingActionObservable();
    }

    public ngOnDestroy(): void {
        this.toolingActionsSubscription.unsubscribe();
        this.projectService.isUserDrawing = false;
        this.stepperService.changeProjectModifiedState(false);
    }

    public setProjectStatus(): void {
        this.isProjectPublished = this.stepperService.isProjectPublished;
    }

    public drawPolygon(): void {
        this.stepperService.changeProjectModifiedState(true);
        this.projectService.copyDrawing = new Path();
        this.child.drawPolygon();
        this.projectService.isUserDrawing = true;
    }

    public resetMap(): void {
        this.stepperService.changeProjectModifiedState(false);
        if (this.projectService.curentDrawing.pathId) {
            this.projectService.copyDrawing = new Path();
            this.child.resetMap();
            this.child.redrawPath();
        } else {
            this.projectService.copyDrawing = new Path();
            this.child.resetMap();
        }
    }

    public onMaterialChange(): void {
        this.stepperService.changeProjectModifiedState(true);
    }

    public switchLayer(event: any): void {
        if (this.layerType === 'Satelite') {
            this.layerType = 'Terain';
        } else {
            this.layerType = 'Satelite';
        }
        this.child.changeLayer();
    }

    public showEmptyPathMessage(): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'error',
            'error',
            'admin.map.pathEmpty',
            1000
        );
    }

    public getCopyDrawingPropertiesToCurrentDrawing(): void {
        this.projectService.curentDrawing.latLonCoordinates =
            this.projectService.copyDrawing.latLonCoordinates;
        this.projectService.curentDrawing.xYCoordinates =
            this.projectService.copyDrawing.xYCoordinates;
        this.projectService.curentDrawing.xCenter =
            this.projectService.copyDrawing.xCenter;
        this.projectService.curentDrawing.yCenter =
            this.projectService.copyDrawing.yCenter;
        this.projectService.curentDrawing.imageId =
            this.projectService.copyDrawing.imageId;
    }

    // REFACTOR
    public savePolygon(): void {
        if (this.projectService.copyDrawing.latLonCoordinates) {
            this.getCopyDrawingPropertiesToCurrentDrawing();

            if (this.projectService.curentDrawing.pathId) {
                this.showConfirmationModal();
            } else {
                this.save();
            }
        } else {
            if (!this.projectService.curentDrawing.pathId) {
                this.showEmptyPathMessage();
            } else {
                if (this.stepperService.isModified) {
                    this.showConfirmationModal();
                } else {
                    this.save();
                }
            }
        }
    }

    public showConfirmationModal(): void {
        this.modal
            .showModal(
                this.containerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.map.overwriteMap',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.save();
                } else {
                    this.child.redrawPath();
                    return;
                }
            });
    }

    public save(): void {
        this.child.getMapImage().then((mapImage: string) => {
            this.saveMapImage(mapImage);
        });
    }

    public goToCurentLocation(): void {
        this.child.goToMyCurentGeolocation();
    }

    public isDrawingEnabled(): boolean {
        return this.projectService.isUserDrawing;
    }

    public changeAddress(point): void {
        this.child.setMapPosition(point.x, point.y);
    }

    private subscribeToToolingActionObservable(): void {
        this.toolingActionsSubscription =
            this.toolingService.toolingActionsObservable.subscribe((data) => {
                if (!data) {
                    return;
                }

                switch (data) {
                    case ToolingActionsEnum.drawPolygon:
                        this.drawPolygon();
                        break;
                    case ToolingActionsEnum.resetMap:
                        this.resetMap();
                        break;
                    case ToolingActionsEnum.savePolygon:
                        this.savePolygon();
                        break;
                    default:
                        console.error('Unknown method on map');
                        break;
                }
            });
    }

    private savePolygonPath(): void {
        this.projectService
            .savePolygon(
                this.toolingService.defaultMaterial,
                this.stepperService.projectId
            )
            .subscribe(
                (data) => {
                    this.projectService.copyDrawing = new Path();
                    this.projectService.hasDrawing = true;
                    this.projectService.mapImageId = data.imageId;
                    this.stepperService.changeProjectModifiedState(false);
                    this.stepperService.modifyCurrentStepId(true);
                    this.customToasterService.openCustomToaster(
                        CustomToasterComponent,
                        'check_circle',
                        'success',
                        'admin.map.pathSaved',
                        1000
                    );
                }
            );
    }

    private saveMapImage(image: string): void {
        const parts = image.split(',');
        this.imageUploadService
            .uploadPhoto(
                image.length,
                this.getFileName(),
                parts[1],
                this.imageUploadService.getContentType(parts[0]),
                '/project'
            )
            .subscribe((data) => {
                if (!data) {
                    return;
                }
                this.projectService.curentDrawing.imageId = data;
                this.projectService.mapImageId = data;
                this.savePolygonPath();
            });
    }

    private getFileName(): string {
        const fileNameToCut = this.projectService.curentDrawing.pathId + '.png';
        if (fileNameToCut.length > 124) {
            return FurbanUtil.reduceFileNameLength(fileNameToCut, 120);
        }
        return fileNameToCut;
    }
}
