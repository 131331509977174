<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'admin.projectCodes.addExistingCode' | translate }}</h2>
    <button class="ghost-button" (click)="closePopup()">
        <span class="material-icons">close</span>
    </button>
</div>

<mat-dialog-content>
    <mat-form-field class="full-width" appearance="outline">
        <mat-label>
            {{ 'admin.projectCodes.existingCode' | translate }}
        </mat-label>
        <input required matInput maxlength="12" placeholder="{{ 'admin.projectCodes.existingCode' | translate }}"
            [formControl]="addCodeFormControl" />
        <mat-error *ngIf="hasControlError(addCodeFormControl,'required')">
            {{ 'admin.profile.required' | translate }}
        </mat-error>
        <mat-error *ngIf="hasControlError(addCodeFormControl,'isHTML')">
            {{ 'validators.noHTML' | translate }}
        </mat-error>
        <mat-error *ngIf="hasControlError(addCodeFormControl,'whitespace')">
            {{ 'validators.noWhiteSpaces' | translate }}
        </mat-error>
    </mat-form-field>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closePopup()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="addCodeManually()">
        <h6>{{ 'user.profile.okBtn' | translate }}</h6>
    </button>
</div>