import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as THREE from 'three';

export class ThreeControlBuilder {
    public static createOrbitControls(
        camera: THREE.PerspectiveCamera,
        domElement: HTMLElement,
        position: THREE.Vector3
    ): OrbitControls {
        const controls = new OrbitControls(camera, domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.screenSpacePanning = false;
        controls.maxPolarAngle = Math.PI / 2.1;
        controls.minPolarAngle = 0;
        controls.minDistance = 0;
        controls.maxDistance = 300;
        controls.rotateSpeed = 0.5;
        controls.target = position;
        controls.autoRotate = true;
        controls.update();
        controls.saveState();

        return controls;
    }
}
