<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'superadmin.safetyArea.title' | translate }}</h2>

    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons"> close </span>
    </button>
</div>

<mat-dialog-content>
    <form class="flex-column-end" [formGroup]="safetyAreaForm">
        <mat-form-field class="full-width" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'superadmin.safetyArea.updateSafetyAreaName' | translate }}
            </mat-label>
            <input required matInput placeholder="{{ 'superadmin.safetyArea.updateSafetyAreaName' | translate }}"
                formControlName="safetyInCm" />
            <mat-error *ngIf="hasControlRequiredError('safetyInCm',safetyAreaForm)">
                {{ 'superadmin.safetyArea.requiredSafetyAre' | translate }}
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closeDialog()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="updateSafetyArea()">
        <h6>{{ 'generic.save' | translate }}</h6>
    </button>
</div>