import { UserProfile } from '@furban/utilities';
import { DesignProposalStatus } from './design-proposal-status';

export class DesignProposalLight {
    public designProposalId?: string;
    public description?: string;
    public title: string;
    public mediaId?: string;
    public projectId?: string;
    public isActive: boolean;
    public userProfile?: UserProfile;
    public groupId?: string;
    public winner: boolean; // TO-DO refactor - rename to isWinner
    public price?: number;
    public reportsCount?: number;
    public src?: string;
    public media?: string;
    public designProposalStatus: DesignProposalStatus;
}
