import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserProfile } from '@furban/utilities';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/furban-client/src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ExpertsService {
    constructor(private http: HttpClient) {}

    getExpertsForProject(projectId: string): Observable<UserProfile[]> {
        return this.http
            .get(`${environment.apiPath}/userprofile/experts/${projectId}`)
            .pipe(map((result) => result as UserProfile[]));
    }

    getExpertsCountForProjects(projectIds: string[]): Observable<number> {
        return this.http
            .post(`${environment.apiPath}/userprofile/expertscount`, projectIds)
            .pipe(map((result) => result as number));
    }
}
