import { PollAnswer } from './poll-answer';

export class PollOption {
    public pollOptionId?: number;
    public pollId: string;
    public option: string;
    public isActive?: boolean;
    public totalAnswers?: number;
    public pollAnswers?: PollAnswer[];
}
