import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

import {
    AuthService,
    ClipboardService,
    CodesFilter,
    CodesFilterEnum,
    CodesRequestDTO,
    ControlsUtil,
    CustomToasterComponent,
    CustomToasterService,
    ExportFilesUtil,
    FurbanUtil,
    Group,
    ModalManager,
    RoleEnum,
    User,
    UserLight,
} from '@furban/utilities';
import { AddGroupPopupComponent } from './../add-group-popup/add-group-popup.component';
import { AddingCodesComponent } from '../adding-codes/adding-codes.component';
import { GenerateCodesComponent } from '../generate-codes/generate-codes.component';
import { GroupsService } from './../groups/groups.service';
import { CodesService } from '../../../../shared/_services/codes.service';
import { switchMap } from 'rxjs/operators';
import { ExpertsService } from '../../../user/experts-modal/experts.service';
import { GenerateCollaborativeCodesComponent } from '../../../../project-shared/generate-collaborative-codes/generate-collaborative-codes.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-codes',
    templateUrl: './codes.component.html',
    styleUrls: ['./codes.component.scss'],
})
export class CodesComponent implements OnInit, OnDestroy {
    @Input() isPreviewPopup = false;
    @Input() groupIdPreviewPopup: string;

    public groupId: string;
    public group: Group;
    /* Declarations for table*/
    public displayedColumns: string[] = [
        'select',
        'username',
        'role',
        'createdDate',
        'emailSent',
        'activeUser',
    ];
    public dataSource: MatTableDataSource<UserLight>;
    public codes = [];
    public codesLength = 0;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    /* Declarations for filtering panels*/
    public selectedRole = '';
    public isEmailSentValue: boolean;
    public userCode = new FormControl('', this.htmlValidator);
    public startDate = new FormControl();
    public endDate = new FormControl();
    public expertsCountOnAllProjects = 0;
    /* Declarations for table pagination*/
    public pageIndex = 0;
    public pageSize = 20;
    public hasControlError = ControlsUtil.hasControlError;
    public isApp = FurbanUtil.isApp;

    public get codeFilterEnum(): typeof CodesFilterEnum {
        return CodesFilterEnum;
    }

    public get isPioneer(): boolean {
        return this.authService.isPioneer();
    }

    public get hasData(): boolean {
        return !this.codes || (this.codes && this.codes.length === 0);
    }

    public get codeServiceInstance(): CodesService {
        return this.codesService;
    }

    constructor(
        private clipboardService: ClipboardService,
        private translateService: TranslateService,
        private codesService: CodesService,
        private viewContainerRef: ViewContainerRef,
        private modal: ModalManager,
        private containerRef: ViewContainerRef,
        private customToasterService: CustomToasterService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private groupsService: GroupsService,
        private _location: Location,
        private expertsService: ExpertsService,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.groupId = params['groupId'] || this.groupIdPreviewPopup;
            this.checkIfGroupsAreLoaded();
            this.getGroup();
            this.getCodes();
        });
        this.getExpertsCountForAllProjectOnGroup(this.groupId);
    }

    ngOnDestroy(): void {
        this.codesService.selection.clear();
        this.resetFilters();
    }

    public getTranslatedEmailStatus(row: { emailSent: any }): string {
        return row.emailSent
            ? this.translateService.instant('generic.yesBtn')
            : this.translateService.instant('generic.noBtn');
    }

    public getTranslatedStatusLabel(row: { acceptedTOS: any }): string {
        return row.acceptedTOS
            ? this.translateService.instant('generic.active')
            : this.translateService.instant('generic.notActive');
    }

    /* Methods used by material table for checkboxes */
    public isAllSelected(): boolean {
        const numSelected = this.codesService?.selection.selected.length;
        const numRows = this.dataSource?.data.length;
        return numSelected === numRows;
    }

    public masterToggle(): void {
        if (this.isAllSelected()) {
            this.codesService.selection.clear();
        } else {
            this.dataSource.data.forEach((row) => this.codesService.selection.select(row));
        }
    }

    /* Methods for filtering */
    public findByCode(): void {
        this.codesService.selection.clear();
        this.resetDateVariables();
        this.resetTable();
        this.codesService
            .getUserByCodeAndGroupId(this.userCode.value, this.groupId)
            .subscribe((data) => {
                if (!data) {
                    this.initializeCodeSearchFilter();
                    return;
                }
                const user = this.createUserLight(data);

                this.assingCodesToDataSource([user]);
                this.initializeCodeSearchFilter();
                this.codesLength = 1;
            });
    }

    public filter(filter: string): void {
        this.codesService.selection.clear();
        this.resetTable();
        this.getCodesLength();
        this.getCodes();
        this.showFilter(CodesFilterEnum.noFilters);
        this.codesService.codesFilter.isCodeSearch = false;
        this.codesService.codesFilter[filter] = true;
    }

    public onParentScrollDown(): void {
        if (this.codesLength > this.dataSource.data.length) {
            this.pageIndex = this.pageIndex + 1;
            this.getCodes();
        }
    }

    public resetFilters(): void {
        this.selectedRole = '';
        this.codesService.codesFilter = new CodesFilter();
        this.resetTable();
        this.resetDateVariables();
        this.resetCodeVariables();
        this.isEmailSentValue = null;
        this.codesService.selection.clear();
        this.getCodesLength();
        this.getCodes();
        this.getExpertsCountForAllProjectOnGroup(this.groupId);
    }

    public deleteCodes(): void {
        this.modal
            .showModal(
                this.containerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.projectCodes.codesDeleteWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.deleteCodesResultReset();
                } else {
                    this.codesService.selection.clear();
                }
            });
    }

    public showFilter(filter: CodesFilterEnum): void {
        this.codesService.codesFilter.filterToBeShown = filter;
    }

    public checkAppliedFilterForEmail(): boolean {
        return this.checkAppliedFilter(this.codeFilterEnum.emailSentFilter)
            || this.codesService.codesFilter.isEmailSentFiltering;
    }

    public checkAppliedFilterForDate(): boolean {
        return this.checkAppliedFilter(this.codeFilterEnum.dateFilter) ||
            this.codesService.codesFilter.isDateFiltering;
    }

    public checkAppliedFilterForCode(): boolean {
        return this.checkAppliedFilter(this.codeFilterEnum.codeFilter) ||
            this.codesService.codesFilter.isCodeSearch;
    }

    public checkAppliedFilterForRole(): boolean {
        return this.checkAppliedFilter(this.codeFilterEnum.roleFilter) ||
            this.codesService.codesFilter.isRoleFiltering;
    }

    public checkAppliedFilter(filter: CodesFilterEnum): boolean {
        return this.codesService?.codesFilter.filterToBeShown === filter ? true : false;
    }

    /* Methods for opening modals*/
    public openAddExistingCodeModal(): void {
        const addingCodesDialog = this.dialog.open(AddingCodesComponent, {
            width: '600px',
        });
        addingCodesDialog.componentInstance.expertsCount =
            this.expertsCountOnAllProjects;

        addingCodesDialog.disableClose = true;
        addingCodesDialog.componentInstance.parentRef = this.viewContainerRef;
        addingCodesDialog.componentInstance.groupId = this.groupId;
        addingCodesDialog.componentInstance.onModalClose.subscribe(() => {
            this.resetFilters();
        });
    }

    public openGenerateCodesModal(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '600px';
        dialogConfig.data = {
            expertsCountOnAllProjects: this.expertsCountOnAllProjects,
            groupId: this.groupId,
            parentRef: this.viewContainerRef,
        };
        this.openDialogWithConfig(dialogConfig);
    }

    public openSendEmailModal(): void {
        const addingCodesDialog = this.dialog.open(
            GenerateCollaborativeCodesComponent,
            {
                width: '600px',
            }
        );
        addingCodesDialog.componentInstance.groupId = this.groupId;
        addingCodesDialog.disableClose = true;
        addingCodesDialog.componentInstance.parentRef = this.viewContainerRef;
        addingCodesDialog.componentInstance.onModalClose.subscribe(() => {
            this.resetFilters();
        });
    }

    public showResetButton(): boolean {
        return (
            this.codesService?.codesFilter.isCodeSearch ||
            this.codesService?.codesFilter.isDateFiltering ||
            this.codesService?.codesFilter.isRoleFiltering ||
            this.codesService?.codesFilter.isEmailSentFiltering
        );
    }

    public exportCodes(event: MouseEvent): void {
        const codesType = (event.target as HTMLInputElement).value;

        this.exportCodesObsv(
            this.codesService.exportExpertOrCitizenCodes(
                this.codesService.urls[codesType],
                [this.groupId]
            ),
            codesType.split('E')[0]
        );
    }

    public openEditPopup(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '28%';
        dialogConfig.data = {
            groups: this.groupsService.groups,
            group: this.group,
        };
        const groupDialog = this.dialog.open(AddGroupPopupComponent, dialogConfig);
        groupDialog.componentInstance.parentViewContainerRef = this.containerRef;
        groupDialog.afterClosed().subscribe((data) => {
            if (data) {
                this.group.groupName = (data as Group).groupName;
            }
        });
    }

    public goToLastRoute(): void {
        this._location.back();
    }

    public onCodeCopy(clipboadTextToCopy: string): void {
        FurbanUtil.copyToClipboard(clipboadTextToCopy, this.translateService, this.customToasterService);
    }

    private getExpertsCountForAllProjectOnGroup(groupId: string): void {
        this.groupsService
            .getProjectIdsByGroupId(groupId)
            .pipe(
                switchMap((result) =>
                    this.expertsService.getExpertsCountForProjects(result)
                )
            )
            .subscribe((data) => {
                this.expertsCountOnAllProjects = data;
            });
    }

    private getGroup(): void {
        this.groupsService.getGroupByGroupId(this.groupId).subscribe((data) => {
            if (data) {
                this.group = data;
                this.codesLength =
                    this.group.expertsCount +
                    this.group.usersCount +
                    this.group.neighboursCount;
            }
        });
    }

    private checkIfGroupsAreLoaded(): void {
        if (!this.groupsService.groups) {
            this.groupsService.getGroupsByClientId().subscribe();
        }
    }

    private getCodes(): void {
        const requestParam = new CodesRequestDTO(
            this.pageIndex,
            this.pageSize,
            this.startDate.value,
            this.endDate.value,
            this.groupId,
            RoleEnum[this.selectedRole],
            this.isEmailSentValue
        );

        this.codesService.getAllCodes(requestParam).subscribe((data) => {
            this.assingCodesToDataSource(data);
        });
    }

    private getCodesLength(): void {
        this.codesService
            .getCodesCount(
                this.groupId,
                RoleEnum[this.selectedRole],
                this.startDate.value,
                this.endDate.value
            )
            .subscribe((data) => {
                this.codesLength = data as number;
            });
    }

    private assingCodesToDataSource(codes: UserLight[]): void {
        this.codes = this.codes.concat(FurbanUtil.deepCopy(codes));
        this.dataSource = new MatTableDataSource(this.codes);
    }

    private resetDateVariables(): void {
        this.startDate.reset();
        this.endDate.reset();
    }

    private resetCodeVariables(): void {
        this.userCode.reset();
    }

    private resetTable(): void {
        this.codes = [];
        this.dataSource = new MatTableDataSource([]);
        this.pageIndex = 0;
        this.codesLength = 0;
    }

    private exportCodesObsv(
        observableResult: Observable<ArrayBuffer>,
        type: string
    ): void {
        observableResult.subscribe((data) => {
            ExportFilesUtil.createCSV(data, type + '_codes_');
        });
    }

    public exportNeighborCodes(): void {
        this.codesService.exportNeighborsCodes(this.groupId).subscribe((data) => {
            ExportFilesUtil.createCSV(data, 'neighbourExportCsv_codes_');
        });
    }

    public deleteCodesResultReset(): void {
        this.codesService
            .deleteExpertOrCitizenCodes(this.codesService.selection.selected, this.groupId)
            .subscribe(() => {
                this.resetFilters();
                this.showSuccessToaster('admin.projectCodes.codesDeleteSuccess');
            });
    }

    private openDialogWithConfig(config: MatDialogConfig): void {
        const generateCodesPopup = this.dialog.open(GenerateCodesComponent, config);
        generateCodesPopup.afterClosed().subscribe((data) => {
            if (data) {
                this.showSuccessToaster('admin.codes.codesAdded');
                this.resetFilters();
            }
        });
    }

    private showSuccessToaster(text: string): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            text,
            200
        );
    }

    private createUserLight(user: User): UserLight {
        const userLight = new UserLight();
        userLight.clientId = user.clientId;
        userLight.createdDate = user.createdDate;
        userLight.emailSent = user.emailSent;
        userLight.roleName = user.role.role;
        userLight.userId = user.userId;
        userLight.username = user.username;
        userLight.acceptedTOS = user.userProfile.acceptedTOS;
        return userLight;
    }

    private initializeCodeSearchFilter(): void {
        this.codesService.codesFilter.isCodeSearch = true;
        this.codesService.codesFilter.filterToBeShown = CodesFilterEnum.noFilters;
    }
}
