import {
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    Card,
    CustomToasterComponent,
    CustomToasterService,
    DesignProposal,
    DesignProposalEnum,
    DesignProposalRequestParam,
    DesignProposalService,
    ModalManager,
    SortTypeEnum,
    StepperService,
} from '@furban/utilities';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'furban-generic-municipality-cards',
    templateUrl: './generic-municipality-cards.component.html',
    styleUrls: ['./generic-municipality-cards.component.scss'],
})
export class GenericMunicipalityCardsComponent implements OnInit, OnDestroy {
    public cardConfig: Card = new Card();
    public curentDPs: DesignProposal[];
    public projectId: string;
    public curentPage = 0;
    public designProposalsCount: number;
    public loaded: boolean;

    private routeSub: Subscription;

    constructor(
        protected stepperService: StepperService,
        protected modal: ModalManager,
        protected customToasterService: CustomToasterService,
        protected designProposalService: DesignProposalService,
        protected viewContainerRef: ViewContainerRef,
        protected route: ActivatedRoute
    ) { }

    @HostListener('scroll', ['$event'])
    public onScroll(event: Event): void {
        const target = event.target as HTMLElement;
        if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
            this.onParentScrollDown(SortTypeEnum.SORT_BY_DATE);
        }
    }

    public ngOnInit(): void {
        this.setupCardConfig();
        this.setCurrentProject();
    }

    public ngOnDestroy(): void {
        this.routeSub?.unsubscribe();
    }

    public getMedia(mediaId: string): string {
        return mediaId
            ? `${environment.apiPath}/media/public/${mediaId}`
            : '../../../assets/images/default-project.png';
    }

    private setCurrentProject(): void {
        this.projectId = this.stepperService.projectId;
        if (!this.projectId) {
            this.routeSub = this.route.params.subscribe((params) => {
                this.projectId = params['id'];
            });
        }
    }

    private setupCardConfig(): void {
        this.cardConfig.cardType = 'white';
        this.cardConfig.contentAlignmentHorizontal = 'center';
        this.cardConfig.contentAlignmentVertical = 'center';
        this.cardConfig.hasHeader = false;
        this.cardConfig.height = 224;
        this.cardConfig.hoverable = true;
    }

    public onParentScrollDown(sortingCriteria: string): void {
        if (!this.curentDPs || this.curentDPs.length <= 0) {
            return;
        }
        this.curentPage = this.curentPage + 1;
        if (this.curentDPs.length < this.designProposalsCount) {
            const dpRequestParam = new DesignProposalRequestParam(
                this.projectId,
                this.curentPage,
                sortingCriteria,
                DesignProposalEnum.allDesignProposals
            );
            this.getPaginatedDesignProposals(dpRequestParam);
        }
    }

    public getPaginatedDesignProposals(
        dpRequestParam: DesignProposalRequestParam
    ): void {
        //should be implemented in component that extend this one
    }

    public showDeleteWarningPopup(designProposalId: string): void {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.municipalityDesigns.designDeleteWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (!res) {
                    return;
                }
                this.deleteDesignProposal(designProposalId);
            });
    }

    public getDesignProposals(sortingCriteria: string): void {
        //should be implemented in component that extend this one
    }

    public deleteDesignProposal(designProposalId: string): void {
        this.designProposalService.deleteDP(designProposalId).subscribe(() => {
            this.designProposalsCount--;
            this.curentPage = 0;
            this.getDesignProposals(SortTypeEnum.SORT_BY_DATE);
            this.successDeletedReportMessage();
            this.scrollToTop();
        });
    }

    public scrollToTop(): void {
        //should be implemented in component that extend this one
    }

    public successDeletedReportMessage(): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            'admin.municipalityDesigns.confirmDelete',
            1000
        );
    }
}
