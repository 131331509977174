import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { UserProfile, routingConstants, MediaService } from '@furban/utilities';
import { ChatService } from '../../../shared/chat/chat.service';

@Component({
    selector: 'furban-experts-modal',
    templateUrl: './experts-modal.component.html',
    styleUrls: ['./experts-modal.component.scss'],
})
export class ExpertsModalComponent implements OnInit {
    public experts: UserProfile[];
    public projectId: string;


    constructor(
        private router: Router,
        private chatService: ChatService,
        private mediaService: MediaService,
        private dialogRef: MatDialogRef<ExpertsModalComponent>
    ) { }

    ngOnInit() {
        this.getExperts();
    }

    getExperts() {
        /* TO-DO chat get experts */
    }

    talkToExpert(expert: UserProfile) {
        this.dialogRef.close();
        this.chatService.talkingPerson = expert;
        this.router.navigate([routingConstants.chat]);
    }

    closeDialog() {
        this.dialogRef.close();
    }

    public getUserMedia(mediaId: string): string {
        return this.mediaService.getUserMedia(mediaId);
    }
}
