export class ConversationComment {
    createdBy: string;
    createdDate: string;
    createdHour: string;
    comment: string;
    likes: string[];
    replies: string[];
    attachments: string[];

    constructor(
        createdBy: string,
        createdDate: string,
        createdHour: string,
        comment: string,
        likes: string[],
        replies: string[],
        attachments: string[]
    ) {
        this.createdBy = createdBy;
        this.createdDate = createdDate;
        this.createdHour = createdHour;
        this.comment = comment;
        this.likes = likes;
        this.replies = replies;
        this.attachments = attachments;
    }
}
