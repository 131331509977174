<form class="full-width flex-column-start"
      [formGroup]="commentForm"
      (submit)="saveComment()">
    <mat-form-field class="full-width"
                    appearance="none">
        <input matInput
               placeholder="{{ 'comments.formText' | translate }}"
               formControlName="commentText"
               [maxLength]="256"
               (keyup)="onCommentKey($event)"
               (keydown)="onCommentKey($event)"
               #comment />
        <mat-hint align="end">{{ comment.value.length }} / 256</mat-hint>
        <mat-error *ngIf="checkError('maxlength')">
            {{ 'comments.maxLengthError' | translate }}
        </mat-error>
        <mat-error *ngIf="checkError('isHTML')">
            {{ 'validators.noHTML' | translate }}
        </mat-error>
        <mat-error *ngIf=" checkError('whitespace')">
            {{ 'validators.noWhiteSpaces' | translate }}
        </mat-error>
    </mat-form-field>

    <div class="comment-buttons"
         [class.no-padding]="emojiOpened">
        <ng-container *ngIf="!emojiOpened">
            <div class="flex-row-start">
                <span class="material-icons emoji"
                      matTooltip="{{ 'buttonsTooltips.emoji' | translate }}"
                      (click)="openEmoji()"
                      id="emojiButton">
                    insert_emoticon</span>
            </div>

            <button type="submit"
                    class="primary-button small"
                    (submit)="saveComment()">
                <h6>{{ 'generic.send' | translate }}</h6>
            </button>
        </ng-container>

        <ng-container *ngIf="emojiOpened">
            <div class="emoji-container"
                 [class.no-dialog]="!isDialog || !isPinComment"
                 [class.on-pin]="isPinComment">
                <span class="material-icons pointer"
                      (click)="toggleEmoji()">
                    close
                </span>
                <emoji-mart exclude="{['flags']}"
                            [isNative]="true"
                            [hideRecent]="false"
                            [showPreview]="false"
                            [enableSearch]="false"
                            [color]="emojiColor"
                            [darkMode]="false"
                            (emojiSelect)="handleClick($event)">
                </emoji-mart>
            </div>
        </ng-container>
    </div>
</form>