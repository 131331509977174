import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DesignProposal, MediaService, UserProfile } from '@furban/utilities';
import { CollaborativeDesignService } from '../collaborative-design/collaborative-design.service';
import { ManageCollaborativeUsersComponent } from '../manage-collaborative-users/manage-collaborative-users.component';

@Component({
    selector: 'furban-contributors-short-view',
    templateUrl: './contributors-short-view.component.html',
    styleUrls: ['./contributors-short-view.component.scss'],
})
export class ContributorsShortViewComponent {
    @Input() public isAdmin: boolean;
    @Input() public designProposal: DesignProposal;
    @Input() public isProjectEnded: boolean;
    @Output() public closeContributors: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private collaborativeDesignService: CollaborativeDesignService,
        private mediaService: MediaService,
        private dialog: MatDialog
    ) { }

    public get contributorsList(): UserProfile[] {
        return this.collaborativeDesignService.contributors;
    }

    public getUserMedia(mediaId: string): string {
        return this.mediaService.getUserMedia(mediaId);
    }

    public openContributorsDetails(): void {
        const viewContributors = this.dialog.open(
            ManageCollaborativeUsersComponent,
            {
                width: '460px',
            }
        );

        viewContributors.disableClose = true;
        viewContributors.componentInstance.isAdmin = this.isAdmin;
        viewContributors.componentInstance.designProposal = this.designProposal;
        viewContributors.componentInstance.shouldDisableAddingNewUsers = this.isProjectEnded;
    }

    public hideContributors(): void {
        this.closeContributors.emit();
    }
}
