import { AbstractControl } from '@angular/forms';

export function validateWhitespace(control: AbstractControl) {
    const text = control.value;
    let isValid = true;

    if (text != null && !text.replace(/\s/g, '').length) {
        isValid = false;
    }

    return isValid ? null : { whitespace: true };
}
