<mat-accordion class="full-width">
    <h6 class="category-title">
        <mat-panel-title [style.min-height.px]="24">
            {{ 'userSettings.title' | translate }}
        </mat-panel-title>
    </h6>
    <form [formGroup]="userSettingsCheckForm">
        <div class="flex-row-start padding-20-0">
            <mat-slide-toggle
                formControlName="show3DNotificationsFormControl"
                class="custom-width-50"
            >
                {{ 'userSettings.showNotifications' | translate }}
            </mat-slide-toggle>
            <mat-slide-toggle
                formControlName="isHoverHelperActiveControl"
                padding="0px"
            >
                {{ 'admin.profile.changeObjectsHover' | translate }}
            </mat-slide-toggle>
        </div>
    </form>
</mat-accordion>
