import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    public _socket: WebSocket;

    public get socket(): WebSocket {
        return this._socket;
    }

    public set socket(value: WebSocket) {
        this._socket = value;
    }

    public isSocketClosed(): boolean {
        return this.socket.readyState === this.socket.CLOSED;
    }
}
