export enum ThreeGroupEnum {
    ground = 'furban_ground',
    custom_uploaded_object = 'furban_custom_uploaded_object',
    background = 'furban_background',
    objects = 'furban_all_objects',
    transform = 'furban_transform',
    objectsRegular = 'furban_objects_regular',
    objectsGround = 'furban_objects_ground',
    multiselect = 'furban_multiselect',
    helpers = 'furban_helpers',
    invisiblePlane = 'furban_invisible_plane',
    pin_helpers = 'furban_pin_helper',
}
