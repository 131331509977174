<div *ngIf="isUploadEnabled(); else fileInfo"
     draggable="true"
     class="drop-container">
    <div class="flex-column-start">
        <img />
        <p class="drop-text"
           [class.drop-text-disabled]="disableDrop">
            {{ 'generic.dropFiles' | translate }}
            <a (click)="onFileUploadClick()">
                {{ 'generic.cickBrowse' | translate }}
            </a>
        </p>
        <p class="supports">
            {{ 'generic.supports' | translate }}
        </p>
        <input type="file"
               #file
               [accept]="acceptedFormatsPoint"
               [multiple]="true"
               (change)="onFileChange($event)" />
    </div>
</div>
<p *ngIf="errorCode"
   class="error">
    {{ errorCode | translate }}
</p>

<ng-template #fileInfo>
    <div class="list-element">
        <p *ngIf="project3DUpload; else uploadedFileInfo">
            {{ displayName() }}
        </p>

        <ng-template #uploadedFileInfo>
            <p class="word-break-all">
                {{ uploadedFile.name }}
            </p>
        </ng-template>

        <div class="upload-action-buttons">
            
            <button class="remove" matTooltip="{{ 'buttonsTooltips.delete' | translate }}"
                (click)="removeFile()">
                <span class="material-icons-outlined">delete</span>
            </button>
            <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.edit' | translate }}"
                (click)="updateCustomObject()">
                <span class="material-icons-outlined">edit</span>
            </button>
        
        </div>
        
    </div>
</ng-template>