import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TutorialsService {
    public isTutorialMenuOpened = false;

    public tutorialMenuToggled: EventEmitter<boolean> = new EventEmitter();

    public toggleTutorialMenu(): void {
        this.isTutorialMenuOpened = !this.isTutorialMenuOpened;
        this.tutorialMenuToggled.emit(this.isTutorialMenuOpened);
    }

    public closeTutorialMenu(): void {
        if (!this.isTutorialMenuOpened) {
            return;
        }
        this.isTutorialMenuOpened = false;
        this.tutorialMenuToggled.emit(false);
    }
}
