export enum ToolingButtonsEnum {
    save = 'save',
    changeCamera = 'changeCamera',
    finishDesign = 'finishDesign',
    finishUpdate = 'finishUpdate',
    undo = 'undo',
    redo = 'redo',
    reset = 'reset',
    toggleSunSlider = 'toggleSunSlider',
}
