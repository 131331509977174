import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {
    ModalManager,
    FurbanUtil,
    Project3dModel,
    CategoryObject,
    MenuService,
    PermitAsset,
    ProjectTypeEnum,
    PermitCategoryObject,
} from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-row-of-objects-dialog',
    templateUrl: './row-of-objects-dialog.component.html',
    styleUrls: ['./row-of-objects-dialog.component.scss'],
})
export class RowOfObjectsDialogComponent implements OnInit {
    @Input() public parentRef;
    @Input() public projectId: string;
    @Input() public projectType: ProjectTypeEnum = ProjectTypeEnum.adminProject;

    public categoryObjects: CategoryObject[] | PermitCategoryObject[];
    public composeImageString = FurbanUtil.composeImageString;
    public selectedObject: Project3dModel | PermitAsset;
    public onModalClose = new EventEmitter();
    public selectedCategory: CategoryObject | PermitCategoryObject;
    constructor(
        public translateService: TranslateService,
        private dialogRef: MatDialogRef<RowOfObjectsDialogComponent>,
        private modal: ModalManager,
        public menuService: MenuService
    ) { }

    ngOnInit() {
        this.getObjects();
    }

    get projectTypeEnum(): typeof ProjectTypeEnum {
        return ProjectTypeEnum;
    }

    public closeDialog(): void {
        if (this.projectType === ProjectTypeEnum.adminProject) {
            if (
                (this.selectedObject as Project3dModel).project3DModelId ===
                (this.categoryObjects[0] as CategoryObject).models[0]
                    .project3DModelId
            ) {
                this.dialogRef.close();
                return;
            }
        } else if (
            (this.selectedObject as PermitAsset).assetId ===
            (this.categoryObjects[0] as PermitCategoryObject).models[0].assetId
        ) {
            this.dialogRef.close();
            return;
        }
        this.openDiscardModal();
    }

    private openDiscardModal(): void {
        this.modal
            .showModal(
                this.parentRef,
                ModalManager.createConfiguration(
                    this.translateService.instant('errors.warning'),
                    this.translateService.instant('errors.discardMessage'),
                    this.translateService.instant('generic.yesBtn'),
                    this.translateService.instant('generic.noBtn')
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.dialogRef.close();
                }
            });
    }

    public saveObject(): void {
        this.onModalClose.emit(this.selectedObject);
    }

    public changeSelectedObject(
        theLastSelectedObject: Project3dModel | PermitAsset
    ): void {
        this.selectedObject = theLastSelectedObject;
    }

    public isSelectedObject(
        theLastSelectedObject: Project3dModel | PermitAsset
    ): boolean {
        return this.projectType === ProjectTypeEnum.adminProject
            ? this.isSelectedObjectForMunicipality(
                theLastSelectedObject as Project3dModel
            )
            : this.isSelectedObjectForPermit(
                theLastSelectedObject as PermitAsset
            );
    }

    private isSelectedObjectForPermit(
        theLastSelectedObject: PermitAsset
    ): boolean {
        return (
            (this.selectedObject as PermitAsset).assetId ===
            theLastSelectedObject.assetId
        );
    }

    private isSelectedObjectForMunicipality(
        theLastSelectedObject: Project3dModel
    ): boolean {
        return (
            (this.selectedObject as Project3dModel).project3DModelId ===
            theLastSelectedObject.project3DModelId
        );
    }

    public onSelectCategoryChange(event: any) {
        this.selectedCategory = event.value;
        this.selectedObject = this.selectedCategory.models[0];
    }

    private getObjects(): void {
        if (this.projectType === ProjectTypeEnum.adminProject) {
            this.getObjectsForMunicipality();
            return;
        }
        this.getObjectsForPermit();
    }

    private getObjectsForMunicipality(): void {
        this.menuService
            .getObjectsThatCanBePlacedInRow(this.projectId)
            .subscribe((data) => {
                this.categoryObjects = data;
                this.selectedCategory = this.categoryObjects[0];
                this.selectedObject = this.selectedCategory.models[0];
            });
    }

    private getObjectsForPermit(): void {
        this.menuService.getPermitObjectsToPlaceInRow().subscribe((data) => {
            this.categoryObjects = data;
            this.selectedCategory = this.categoryObjects[0];
            this.selectedObject = this.selectedCategory.models[0];
        });
    }
}
