<div class="main-container">
    <div
        class="flex-row-start"
        [style.margin-left.px]="32"
        [style.margin-top.px]="32"
    >
        <span
            class="material-icons pointer"
            (click)="returnToDashboard()"
            matTooltip="{{
                'buttonsTooltips.pioneerGoToDashboard' | translate
            }}"
            [style.margin-right.px]="8"
        >
            chevron_left
        </span>
        <h3 class="page-title">
            {{ 'pioneer.dashboard.projectsPageTitle' | translate }}
        </h3>
    </div>
    <ng-container *ngIf="!projects || (projects && projects.length === 0)">
        <div class="flex-column-center padding-20">
            <img
                src="../../../assets/images/no_projects.svg"
                [style.height.px]="300"
            />
            <h4 class="placeholder-text">
                {{ 'pioneer.dashboard.noProjects' | translate }}
            </h4>
        </div>
    </ng-container>

    <div class="cards">
        <furban-app-card
            *ngFor="let project of projects"
            class="admin-card"
            [card]="cardConfig"
            [backgroundImage]="getMedia(project.media)"
            (click)="goToProject(project)"
        >
            <div card-type="content" class="project-card-content">
                <ng-container *ngIf="project && project.projectType">
                    <label
                        class="project-type"
                        [style.color]="getColorBasedOnProjectType(project)"
                    >
                        {{ getProjectTypeText(project) | translate }}
                    </label>
                </ng-container>

                <ng-container *ngIf="project && project.projectStatus">
                    <div
                        matTooltip="{{ getStatusTooltipText(project) }}"
                        class="status-background {{
                            getClassForProject(project)
                        }}"
                    ></div>
                </ng-container>
            </div>
            <div card-type="footer" class="full-width">
                <div class="flex-row-spaced full-width">
                    <h6>{{ project.name | titlecase }}</h6>
                </div>
            </div>
        </furban-app-card>
    </div>
</div>
