import { DocumentModel } from './document';
import { ProjectStatus } from './project-status';
import { ProjectType } from './project-type';
import { UserProfile } from './user-profile';

export class Project {
    public projectId?: string;
    public clientId: number;
    public name: string;
    public description: string;
    public startDate: Date;
    public endDate: Date;
    public votingExpiringDate: Date;
    public createdBy: UserProfile;
    public projectStatusId: number;
    public projectStatus: ProjectStatus;
    public media: string;
    public isActive = true;
    public documents: DocumentModel[];
    public ended: boolean;
    public hasWinner: boolean;
    public price?: number;
    public nrOfNotifications?: number;
    public isPriceRestrictionEnabled = false;
    public projectType: ProjectType;
    public isCitizenDesigns: boolean;
    public currentStepId: number;
    public allowCitizenDesigns: boolean;
}
