export class ProjectOverview {
    public projectName: string;
    public clientName: string;
    public projectStartDate: Date;
    public projectEndDate: Date;
    public projectDescription: string;
    public projectImage? = '../../assets/images/default-project.png';
    public projectBudget?: number;

    constructor(
        projectName: string,
        clientName: string,
        projectStartDate: Date,
        projectEndDate: Date,
        projectDescription,
        projectMedia?: string,
        projectBudget?: number
    ) {
        this.projectName = projectName;
        this.clientName = clientName;
        this.projectStartDate = projectStartDate;
        this.projectEndDate = projectEndDate;
        this.projectDescription = projectDescription;
        this.projectImage = projectMedia;
        this.projectBudget = projectBudget;
    }
}
