<ng-container *ngIf="project">

    <div class="header-space-between">
        <furban-tooling>
            <ng-container buttons-type="main">

                <ng-container *ngIf="isAdmin()">
                    <button class="tooling-button" matTooltip="{{ 'generic.back' | translate }}" (click)="goBack()">
                        <span class="material-icons"> arrow_back </span>
                    </button>
                </ng-container>

                <ng-container *ngIf="!sessionFinished && toolingVisibility.editMode">
                    <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.addObjects' | translate }}"
                        (click)="onButtonClick(toolingActions.addObjects)">
                        <mat-icon svgIcon="add-objects-icon"
                            [class.active]="checkActiveButton(toolingActions.addObjects)"></mat-icon>
                    </button>
                    <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.generateObjects' | translate }}"
                        [class.active]="checkActiveButton(toolingActions.generateObjects)"
                        (click)="onButtonClick(toolingActions.generateObjects)">
                        <span class="material-icons">category</span>
                    </button>
                    <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.objectsInLine' | translate }}"
                        [class.active]="checkActiveButton(toolingActions.objectsInLine)"
                        (click)="onButtonClick(toolingActions.objectsInLine)">
                        <span class="material-icons-outlined">auto_graph</span>
                    </button>
                    <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.multiselect' | translate }}"
                        [class.active]="checkActiveButton(toolingActions.multiselect)"
                        (click)="onButtonClick(toolingActions.multiselect)">
                        <span class="material-icons">select_all</span>
                    </button>

                    <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.freeshape' | translate }}"
                        [class.active]="checkActiveButton(toolingActions.freeshape)"
                        (click)="onButtonClick(toolingActions.freeshape)">
                        <span class="material-icons">gesture</span>
                    </button>
                </ng-container>
            </ng-container>
        </furban-tooling>

        <ng-container *ngIf="isAdmin()">
            <section class="collaborative-admin-buttons">
                <ng-container *ngIf="activeConnectionsCount || activeConnectionsCount === 0">
                    <section class="online-count">
                        <span class="material-icons">
                            account_circle
                        </span>

                        <h6>
                            {{ activeConnectionsCount }}/{{totalNumberOfUsers}}
                        </h6>
                    </section>
                </ng-container>

                <ng-container *ngIf="isStartButtonVisible(); else endSessionTemplate">

                    <button class="finish-session" (click)="updateLiveSessionStatus(designProposalStatus.started)">
                        <span class="material-icons">
                            timer
                        </span>
                        <h6>
                            {{ 'buttonsTooltips.startLiveSession' | translate }}
                        </h6>
                    </button>
                </ng-container>

                <ng-template #endSessionTemplate>
                    <button class="finish-session" [disabled]="isFinishButtonDisabled()"
                        [class.disabled]="isFinishButtonDisabled()"
                        (click)="updateLiveSessionStatus(designProposalStatus.finished)">
                        <span class="material-icons">
                            timer
                        </span>
                        <h6>
                            {{'buttonsTooltips.finishLiveSession' | translate}}
                        </h6>
                    </button>
                </ng-template>
            </section>

        </ng-container>

        <furban-tooling id="right-menu-options-step">
            <ng-container buttons-type="main">
                <button class="tooling-button" matTooltip="{{'buttonsTooltips.change3Dperspective' | translate}}"
                    id="change-perspective-step" (click)="callToolingAction(toolingActions.changeCameraPerspective)">
                    <furban-svg-container [svgImage]="svgImageType.changePerspective"></furban-svg-container>
                </button>

                <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.viewFromTop' | translate }}"
                    (click)="callToolingAction(toolingActions.viewFromTheTop)">
                    <span class="material-icons">
                        vertical_align_top
                    </span>
                </button>

                <ng-container *ngIf="toolingVisibility.editMode && !isIOS">
                    <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.changeGridView' | translate }}"
                        [hidden]="toolingVisibility.isPublished"
                        (click)="callToolingAction(toolingActions.toggleGridView)">
                        <span class="material-icons">grid_on</span>
                    </button>
                </ng-container>

                <button class="tooling-button" [hidden]="toolingVisibility.isPublished"
                    matTooltip="{{ 'buttonsTooltips.toggleTransparency' | translate }}"
                    (click)="callToolingAction(toolingActions.toggleTransparency)">
                    <span class="material-icons">{{
                        toolingVisibility.isTransparencyEnabled
                        ? 'layers_clear'
                        : 'layers'
                        }}
                    </span>
                </button>

                <div class="contributors-button">
                    <ng-container *ngIf="!hasUsers && isAdmin(); else hasUsersTemplate">
                        <button class="tooling-button"
                            matTooltip="{{ 'collaborativeDesign.addContributors' | translate }}"
                            (click)="addContributors()">
                            <span class="material-icons">
                                person_add
                            </span>
                        </button>
                    </ng-container>

                    <ng-template #hasUsersTemplate>
                        <button class="toggle-contributtors "
                            matTooltip="{{ 'collaborativeDesign.viewContributors' | translate }}"
                            id="collaborators-section" [class.active]="userListDisplayed"
                            (click)="toggleViewContributors()">
                            <span class="material-icons"> groups </span>
                        </button>
                    </ng-template>
                </div>
            </ng-container>
        </furban-tooling>


    </div>
</ng-container>

<div class="three-replacement">
    <div class="three-div" *ngIf="checkIfReady()">
        <furban-project-menu-three-d #sideMenuUserProj class="side-menu-user-proj"
            [class.collapsed]="!menuService.menuOpened" [class.hide]="!isEditMode" [project]="project"
            [objects]="objects" [availableBudget]="availableBudget" [freeShapeDrawing]="freeShapeDrawing"
            [currentlyEnabledTool]="currentlyEnabledTool" (added3DObject)="onObject3DAdd($event)"
            (addMultiple3DObject)="onMultiple3DObjectAdd($event)"
            (removeLastUnfinishedFreeShape)="removeLastUnfinishedFreeShape()" (addRowOfObjects)="onRowOfObjectsAdd()"
            (changedTool)="onToolChange($event)" (modify)="modify($event)" (addGreenObjects)="onAddGreenObjects($event)"
            (uploadCustomObject)="onFinishUploadCustomObject()" (highlightDropingArea)="onHighlightDropingArea($event)">
        </furban-project-menu-three-d>

        <furban-ngrx-three-live-collaboration *ngIf="is3dReady && authService.user && project && dpId"
            #threeJsLiveComponent [state]="threeState" [availableBudget]="availableBudget" [project]="project"
            [modified]="modified" [designProposal]="designProposal" (screenshotTaken)="onScreenshotTaken()"
            (isEditMode)="toggleEdit($event)" (projectPriceChange)="onProjectPriceChange($event)"
            (finishedDrawing)="onFinishedDrawing()" (disabledTool)="onToolDisable()" (modify)="modify($event)"
            (designProposalStatusUpdate)="onUpdateDesignStatus($event)"
            (connectionCountUpdate)="onConnectionCountUpdate($event)">
        </furban-ngrx-three-live-collaboration>
    </div>

    <ng-container *ngIf="shouldDisplayFreeshapeMenu">
        <div class="freeshape-menu-container">
            <div class="items-container" [class.scroll-hidden]="scrollHidden" [style.padding.px]="20">
                <div class="tools-container" [style.padding-bottom.px]="20">
                </div>
                <ng-container *ngFor="let object of objects">
                    <ng-template [ngIf]="
                            object.category.categoryName ===
                            'user.projectMenu.groundMaterials'">
                        <mat-form-field class="full-width" appearance="outline" id="shapes-step">
                            <mat-label>
                                {{
                                'user.projectMenu.selectGround' | translate
                                }}
                            </mat-label>
                            <mat-select disableOptionCentering placeholder="{{
                                    'user.projectMenu.selectGround' | translate
                                }}" [(value)]="selectedGroundMaterial">
                                <mat-option *ngFor="let model of object.models" [value]="model">
                                    {{
                                    model.furban3DModel.translationsLabel
                                    | translate
                                    }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <section class="free-shape-list" cdkDropList cdkDropListSortingDisabled
                            [cdkDropListData]="shapes" (cdkDropListDropped)="dropShape($event)">
                            <div *ngFor="let type of shapes" class="menu-item-container pointer" cdkDrag
                                [cdkDragData]="type" [cdkDragStartDelay]="getDragDelay()"
                                (click)="onShapeObjectSelection(type)">
                                <img class="menu-item-image full-width" alt="ProjPic" [src]="generateShapesAssetsURL(
                                            selectedGroundMaterial.furban3DModel.objectLookId,
                                            type)" />
                            </div>
                        </section>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="hasUsers && userListDisplayed">
        <section class="conversation-area">
            <furban-contributors-short-view [isAdmin]="isAdmin()" [designProposal]="designProposal"
                (closeContributors)="hideContributors()">
            </furban-contributors-short-view>
        </section>
    </ng-container>
</div>

<ng-container *ngIf="!isAdmin() && showNotStartedOverlay">
    <div class="not-started-overlay">
        <section class="waiting-popup">
            <img src="../../../assets/images/wait_live_session.svg" [style.height.px]="200" />
            <h6 [style.margin-top.px]="20">
                {{ 'collaborativeDesign.waitSessionStartMessage' | translate }}
            </h6>
        </section>
    </div>
</ng-container>