import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MediaService } from '../_services/media.service';

@Component({
    selector: 'furban-avatar-picture',
    templateUrl: './avatar-picture.component.html',
    styleUrls: ['./avatar-picture.component.scss'],
})
export class AvatarPictureComponent implements OnChanges {
    @Input() public name?: string;
    @Input() public size?: number;
    @Input() public mediaId?: string;
    @Input() public highlight?: boolean;

    constructor(private mediaService: MediaService) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['name']) {
            const name = changes['name'].currentValue;
            this.calculateColor(name);
        }
    }

    public calculateColor(str: string) {
        const result = this.intToRGB(this.getStringHash(str));
        return result;
    }

    public getUserMedia(mediaId: string): string {
        return this.mediaService.getUserMedia(mediaId);
    }

    /**
     * Convert string to hash
     * @param str
     * @returns {number}
     */
    public getStringHash(str: string): number {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            // tslint:disable-next-line: no-bitwise
            hash = str.charCodeAt(i) + ((hash << 5) - hash); // eslint-disable-line no-bitwise
        }
        return hash;
    }

    /**
     * Convert the generated hash to RGB
     * @param hash
     * @returns {string}
     */
    public intToRGB(hash: number) {
        // tslint:disable-next-line
        const color = (hash & 0x00ffffff) // eslint-disable-line no-bitwise
            .toString(16)
            .toUpperCase();

        return '#00000'.substring(0, 7 - color.length) + color;
    }


}
