// REFACTOR - camel case
export class PointXY {
    public X?: number; // eslint-disable-line @typescript-eslint/naming-convention
    public Y?: number; // eslint-disable-line @typescript-eslint/naming-convention

    constructor(X?: number, Y?: number) {
        this.X = X;
        this.Y = Y;
    }
}
