import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Request } from '@furban/utilities';
import { SuperadminRequestsService } from './superadmin-requests.service';

@Component({
    selector: 'furban-superadmin-requests',
    templateUrl: './superadmin-requests.component.html',
    styleUrls: ['./superadmin-requests.component.scss'],
})
export class SuperadminRequestsComponent implements OnInit {
    public displayedColumns: string[] = [
        'username',
        'clientName',
        'createdDate',
        'approve',
        'reject',
    ];
    public dataSource: MatTableDataSource<Request>;
    public pendingRequests: Request[] = [];

    constructor(private superadminRequestsService: SuperadminRequestsService) { }
    ngOnInit(): void {
        this.getAllPendingRequests();
    }

    public approveRequest(request: Request) {
        this.superadminRequestsService
            .approveRequest(request)
            .subscribe((data) => {
                this.removeRequestFromList(request);
            });
    }

    public rejectRequest(request: Request) {
        this.superadminRequestsService
            .rejectRequest(request)
            .subscribe((data) => {
                this.removeRequestFromList(request);
            });
    }

    private getAllPendingRequests() {
        this.superadminRequestsService
            .getPendingRequests()
            .subscribe((data) => {
                this.pendingRequests = data as Request[];
                this.dataSource = new MatTableDataSource(this.pendingRequests);
            });
    }

    private removeRequestFromList(request: Request) {
        this.pendingRequests.splice(
            this.pendingRequests.findIndex(
                (value) => value.requestId === request.requestId
            ),
            1
        );
        this.dataSource = new MatTableDataSource(this.pendingRequests);
    }
}
