import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
    AuthService,
    DATE_FORMAT,
    DesignProposal,
    DesignProposalService,
    MediaService,
    Project,
    ProjectService,
    routingConstants,
    Step,
    StepperService,
} from '@furban/utilities';

@Component({
    selector: 'furban-app-project-dashboard',
    templateUrl: './project-dashboard.component.html',
    styleUrls: ['./project-dashboard.component.scss'],
})
export class ProjectDashboardComponent implements OnInit {
    public project: Project;
    public steps: Step[] = [];
    public designProposalsCount: number;

    constructor(
        private router: Router,
        private designProposalService: DesignProposalService,
        private stepperService: StepperService,
        private authService: AuthService,
        private mediaService: MediaService,
        private projectService: ProjectService) {
    }

    public get dateFormat(): string {
        return DATE_FORMAT;
    }

    public get isPioneer(): boolean {
        return this.authService.isPioneer();
    }

    public get isAdmin(): boolean {
        return this.authService.isAdmin();
    }

    ngOnInit(): void {
        this.redirectIfNewProject();
        this.initialSetup();
    }

    public getMedia(mediaId): string {
        return this.mediaService.getMedia(mediaId);
    }

    public getMapImage(mediaId): string {
        return this.mediaService.getMapImage(mediaId);
    }

    public goToStep(step: Step): void {
        if (step.isUntouched()) {
            return;
        }
        this.stepperService.currentStep = this.steps.find(
            (value) => value.id === step.id
        );
        this.router.navigateByUrl(step.path);
    }

    public navigateTo(path: string): void {
        if (this.authService.isPioneer()) {
            this.router.navigateByUrl(`${routingConstants.initiativeProcess}/${this.project.projectId}/${path})`);

        } else {
            this.router.navigateByUrl(`${routingConstants.process}/${this.project.projectId}/${path})`);
        }
        this.stepperService.currentStep = undefined;

    }

    public goBack(): void {
        this.router.navigateByUrl(this.getBackRoute());
    }

    public hasMapImage(): boolean {
        return (
            this.projectService.hasDrawing && !!this.projectService.mapImageId
        );
    }

    public get mapImageId(): string {
        return this.projectService.mapImageId;
    }

    public goToLiveSessions(): void {
        if (this.authService.isPioneer()) {
            return;
        }
        this.router.navigateByUrl(`${routingConstants.process}/${this.project.projectId}/${routingConstants.collaborativeSessions}`);
    }

    public createLiveSession(): void {
        const newCollaborativeDesignProposal = DesignProposal.createCollaborativeDesignProposal(this.project.projectId);
        if (this.authService.isPioneer()) {
            return;
        }
        this.designProposalService.saveCollaborativeDesignProposal(newCollaborativeDesignProposal).subscribe((data) => {
            this.router.navigateByUrl(`${routingConstants.process}/${this.project.projectId}/${routingConstants.collaborativeSessions}/${data.designProposalId}`);
        });

    }

    public pickImageSrc(imageName): string {
        return (
            '../../../../assets/images/project-dashboard-cards-images/' +
            imageName
        );
    }

    public get projectEnded(): boolean {
        return this.project.ended;
    }

    private getBackRoute(): string {
        return this.authService.isPioneer()
            ? `${routingConstants.initiativesProjectList}`
            : `${routingConstants.adminDashboard}`;
    }

    private redirectIfNewProject(): void {
        if (this.stepperService.projectId !== 'new') {
            return;
        }
        this.goBack();
    }

    private initialSetup() {
        this.steps = this.stepperService.steps;
        this.project = this.stepperService.project as Project;
        if (!this.project || !this.project.projectId) {
            return;
        }
        this.countDesignProposals();
    }

    private countDesignProposals() {
        this.designProposalService
            .getDesignProposalCount(this.project.projectId)
            .subscribe((data) => {
                this.designProposalsCount = data;
            });
    }
}
