export class MathUtil {
    public static deg2rad = (degrees: number): number =>
        degrees * (Math.PI / 180);

    public static rad2deg = (radians: number): number =>
        radians * (180 / Math.PI);

    public static getRadiansFromPoints(
        v1x: number,
        v1y: number,
        v2x: number,
        v2y: number,
        v3x: number,
        v3y: number
    ) {
        const numerator =
            v2y * (v1x - v3x) + v1y * (v3x - v2x) + v3y * (v2x - v1x);
        const denominator =
            (v2x - v1x) * (v1x - v3x) + (v2y - v1y) * (v1y - v3y);
        const ratio = numerator / denominator;

        const angleRad = Math.atan(ratio);
        let angleDeg = (angleRad * 180) / Math.PI;

        if (angleDeg < 0) {
            angleDeg = 180 + angleDeg;
        }

        const rad = this.deg2rad(angleDeg);
        return rad * 2;
    }
}
