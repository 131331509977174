export class ObjectPropertiesUtils {
    public static toCamel(o) {
        let newO, origKey, newKey, value;
        if (o instanceof Array) {
            return o.map(function (childValue) {
                if (typeof childValue === 'object') {
                    childValue = this.toCamel(childValue);
                }
                return childValue;
            });
        } else {
            newO = {};
            for (origKey in o) {
                if (o.hasOwnProperty(origKey)) {
                    newKey = (
                        origKey.charAt(0).toLowerCase() + origKey.slice(1) ||
                        origKey
                    ).toString();
                    value = o[origKey];
                    if (
                        value instanceof Array ||
                        (value !== null && value.constructor === Object)
                    ) {
                        value = this.toCamel(value);
                    }
                    newO[newKey] = value;
                }
            }
        }
        return newO;
    }
}
