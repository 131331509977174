import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
    ObjectMenuSettings,
    PermitAssetTypeValues,
    PermitMenuControlsEnum,
    PermitThreeService,
} from '@furban/utilities';

@Component({
    selector: 'furban-permit-controls-menu',
    templateUrl: './permit-controls-menu.component.html',
    styleUrls: ['./permit-controls-menu.component.scss'],
})
export class PermitControlsMenuComponent implements OnInit {
    @Input() menuSettings: ObjectMenuSettings;
    @Input() assetType: number;
    @Input() isMultiselect: boolean;

    @Output() controlAction: EventEmitter<PermitMenuControlsEnum> =
        new EventEmitter();
    public activeTab: PermitMenuControlsEnum = PermitMenuControlsEnum.move;

    constructor(private permitThreeService: PermitThreeService) { }

    public get isRotateActive(): boolean {
        return !this.isMultiselect &&
            (this.assetType === PermitAssetTypeValues.FOR_ROOF.id || this.assetType === PermitAssetTypeValues.FOR_GROUND.id || this.assetType === PermitAssetTypeValues.FOR_PANELS.id);
    }

    public get shouldDisplayConfigureButton(): boolean {
        return this.assetType === PermitAssetTypeValues.FOR_ROOF.id || this.assetType === PermitAssetTypeValues.FOR_DORMERS.id;
    }

    public get isTransformActive(): boolean {
        return (
            this.assetType === PermitAssetTypeValues.FOR_ROOF.id ||
            this.assetType === PermitAssetTypeValues.FOR_EXTENSION.id
        );
    }

    public get threeObjectControlsEnum(): typeof PermitMenuControlsEnum {
        return PermitMenuControlsEnum;
    }

    ngOnInit(): void {
        this.permitThreeService.selectControlsObservable.subscribe((data) => {
            if (data === PermitMenuControlsEnum.discard) {
                this.activeTab = PermitMenuControlsEnum.move;
            }
        });
    }

    public deleteAction(): void {
        this.activeTab = PermitMenuControlsEnum.move;
        this.controlAction.emit(PermitMenuControlsEnum.delete);
    }

    public discardAction(): void {
        this.activeTab = PermitMenuControlsEnum.move;
        this.controlAction.emit(PermitMenuControlsEnum.discard);
    }

    public triggerAction(actionType: PermitMenuControlsEnum): void {
        this.activeTab = actionType;
        this.controlAction.emit(actionType);
    }

    public isActiveTab(tab: string): boolean {
        return this.activeTab === tab;
    }
}
