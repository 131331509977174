<div class="register">
    <section class="register-panel">
        <div class="logo-container">
            <img
                src="../../../assets/images/presentation/furban_color.svg"
                [style.width.px]="240"
                [style.margin-bottom.px]="52"
            />
        </div>
        <ng-container *ngIf="!formSubmitted">
            <form
                class="register-form"
                [formGroup]="registerForm"
                (ngSubmit)="submitRegistration()"
            >
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{ 'generic.email' | translate }}
                    </mat-label>
                    <input
                        required
                        matInput
                        autocomplete="false"
                        placeholder="{{ 'generic.email' | translate }}"
                        formControlName="emailFormControl"
                    />

                    <mat-error
                        *ngIf="registerForm.get('emailFormControl').errors"
                    >
                        {{ 'homepage.citizenRegister.emailHint' | translate }}
                    </mat-error>
                </mat-form-field>

                <re-captcha (resolved)="resolved($event)"></re-captcha>

                <button class="primary-button full-width" type="submit">
                    <h6>
                        {{ 'homepage.citizenRegister.register' | translate }}
                    </h6>
                </button>
                <a class="pointer" (click)="goToCards()">{{
                    'homepage.citizenRegister.backToCards' | translate
                }}</a>
            </form>
        </ng-container>
        <ng-container *ngIf="formSubmitted">
            <div class="padding-20 flex-column-center">
                <img
                    src="../../assets/images/pioneer/email-verification/already-confirmed.svg"
                    [style.height.px]="80"
                />
                <h6 class="placeholder-text">
                    {{ 'homepage.citizenRegister.congratulations' | translate }}
                </h6>
            </div>
        </ng-container>
    </section>
</div>
