import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewContainerRef,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { PermitStepStatusEnum } from '../_enum/permit-step-status.enum';
import { Step } from '../_models/step';
import { StepperService } from './stepper.service';
import { CustomToasterComponent } from '../custom-toaster/custom-toaster.component';
import { Subscription } from 'rxjs';
import { routingConstants } from '../_constants/routing-constants';
import { ModalManager } from '../furban-modal/furban-modal.manager';
import { FurbanUtil } from '../helpers/furbanUtil';
import { CustomToasterService } from '../_services/custom-toaster.service';
import { FileUploadService } from '../_services/file-upload.service';

@Component({
    selector: 'furban-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
})
export class FurbanStepperComponent implements OnInit, OnDestroy {
    @Input() homeRoute: string;
    @Input() parentContainerRef: ViewContainerRef;

    public steps: Step[];
    public currentStep: Step;
    public projectId: string;
    public isMobile = FurbanUtil.isMobile();

    private permitProjectStateSubscription: Subscription;

    constructor(
        private router: Router,
        private stepperService: StepperService,
        public fileUploadService: FileUploadService,
        private customToasterService: CustomToasterService,
        private modal: ModalManager
    ) { }

    ngOnDestroy(): void {
        this.permitProjectStateSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.steps = this.stepperService.steps;
        this.currentStep = this.stepperService.currentStep;
        this.projectId = this.stepperService.projectId;
        this.subscribeToStepperChange();
    }

    showDiscardChanges(path: string): void {
        this.modal
            .showModal(
                this.parentContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.projectDetails.projectUnsavedWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.stepperService.changeProjectModifiedState(false);
                    this.router.navigateByUrl(path);
                }
            });
    }

    changeStep(step: Step): void {

        if (this.fileUploadService.isUndergroundUploaded || this.fileUploadService.isCustomObjectUploaded) {
            this.snackbarMessage('errors.changeStep');
            return;
        }

        if (step.isUntouched()) {
            this.snackbarMessage('pioneer.permitGeneral.incompleteSteps');
            return;
        }

        if (this.stepperService.isModified && this.checkWarningStep(step)) {
            this.showDiscardChanges(step.path);
            return;
        }

        this.stepperService.navigateToStep(step);

    }

    snackbarMessage(error: string): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'warning',
            'info',
            error,
            20000
        );
    }

    private checkWarningStep(step: Step): boolean {
        const currentStep = this.stepperService.currentStep.id;
        return (
            (currentStep === 1 || currentStep === 2) && this.isFutureStep(step)
        );
    }

    isHoverEnabled(step: Step): boolean {
        return (
            (this.isStepCompleted(step) && this.isFutureStep(step)) ||
            this.isStepInProgress(step)
        );
    }

    isFutureStep(step: Step): boolean {
        return step.id > this.currentStep.id;
    }

    isStepCompleted(step: Step): boolean {
        return step.status === PermitStepStatusEnum.completed;
    }

    isStepInProgress(step: Step): boolean {
        return step.status === PermitStepStatusEnum.inProgress;
    }

    isStepUntouched(step: Step): boolean {
        return step.status === PermitStepStatusEnum.untouched;
    }

    navigateToHome(): void {
        if (this.fileUploadService.isUndergroundUploaded || this.fileUploadService.isCustomObjectUploaded) {
            this.snackbarMessage('errors.changeStep');
            return;
        }

        if (this.currentStep.isUntouched()) {
            this.router.navigateByUrl(routingConstants.admin);
            return;
        }
        if (this.stepperService.isModified) {
            this.showDiscardChanges(this.homeRoute);
            return;
        }
        this.router.navigateByUrl(this.homeRoute);

    }

    checkShowMoreLeft(): boolean {
        return this.currentStep.id > 1;
    }

    checkShowMoreRight(): boolean {
        return this.currentStep.id < this.steps.length;
    }

    navigateNextStep(): void {
        const nextStepIndex = this.steps.findIndex(
            (step) => step.id === this.currentStep.id + 1
        );
        this.changeStep(this.steps[nextStepIndex]);
    }

    navigatePreviousStep(): void {
        const previousIndex = this.steps.findIndex(
            (step) => step.id === this.currentStep.id - 1
        );
        this.changeStep(this.steps[previousIndex]);
    }

    private subscribeToStepperChange(): void {
        this.stepperService.currentStepChanged.subscribe((data) => {
            this.currentStep = data;
        });

        this.stepperService.stepsChanged.subscribe((data) => {
            this.steps = data;
        });

        this.permitProjectStateSubscription =
            this.stepperService.projectModifiedStateObservable.subscribe(
                (data) => {
                    this.stepperService.isModified = data;
                }
            );

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                const step = this.steps.find(
                    (element) => `/${element.path}` === event.url
                );
                if (step) {
                    this.stepperService.currentStep = step;
                }
            }
        });
    }

}
