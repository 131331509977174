

import {
    CustomToasterService,
    DesignProposalLight,
    FurbanUtil,
    MediaService,
    ReactionsNameEnum,
    ReactionsTypeEnum,
    routingConstants,
} from '@furban/utilities';
import {
    Component,
    ViewContainerRef,
    Input,
    Output,
    EventEmitter,
    OnInit,
} from '@angular/core';

import {
    AuthService,
    ModalManager,
    DesignProposalService,
    CustomToasterComponent,
    DesignProposal,
    ProjectStatusEnum,
    Report,
    ReportService,
    Project,
} from '@furban/utilities';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { EmbededPopupComponent } from '../../embeded-popup/embeded-popup.component';
import { DesignViewerCarouselService } from '../../_services/design-viewer-carousel.service';

@Component({
    selector: 'furban-design-proposal-card',
    templateUrl: './design-proposal-card.component.html',
    styleUrls: ['./design-proposal-card.component.scss'],
})
export class DesignProposalCardComponent implements OnInit {
    @Input() designProposal: DesignProposal | DesignProposalLight;
    @Input() index?: number;
    @Input() isClicked: boolean;
    @Input() viewContainerRef?: ViewContainerRef;
    @Input() project: Project;
    @Input() disabledActions?= false;
    @Input() isViewerOnly?= false;
    @Input() isCollaborativeProject?: boolean;
    @Input() isEmbeded?= false;
    @Input() isPublicPage?= false;
    @Output() commentDialogOpened?: EventEmitter<
        DesignProposal | DesignProposalLight
    > = new EventEmitter();
    @Output() winnerPopupOpened?: EventEmitter<
        DesignProposal | DesignProposalLight
    > = new EventEmitter();
    @Output() userDeleted?: EventEmitter<void> = new EventEmitter();
    @Output() cardClicked?: EventEmitter<void> = new EventEmitter();

    public getPoints = FurbanUtil.getPoints;
    public userProfileId: string;
    public designProposalReactionId: number;


    constructor(
        public authService: AuthService,
        public dialog: MatDialog,
        private router: Router,
        private designProposalService: DesignProposalService,
        private modal: ModalManager,
        private reportService: ReportService,
        private customToasterService: CustomToasterService,
        private traslateService: TranslateService,
        private mediaService: MediaService,
        private designViewerCarouselService: DesignViewerCarouselService) {
        }

    public get hasEnoughReportsToBlur(): boolean {
        return this.designProposal.reportsCount >= 3
    }

    public ngOnInit(): void {
        if(this.isPublicPage) {
            return;
        }
        this.userProfileId = this.authService.userProfile.userProfileId;
        this.designViewerCarouselService.getReactionToDesignProposal(this.userProfileId, this.designProposal.designProposalId)
            .subscribe(res => this.designProposalReactionId = res?.reactionId);
    }

    public getUserMedia(mediaId: string): string {
        return this.mediaService.getUserMedia(mediaId);
    }

    public getMedia(mediaId): string {
        return this.mediaService.getMedia(mediaId);
    }

    public checkDesignProposalReactionId(): boolean {
        return this.designProposalReactionId !== undefined;
    }

    public getImageURL(reactionId: ReactionsTypeEnum): string {
        return `../../../assets/icons/reactions/${this.getReactionName(reactionId)}.png`;
    }

    public getReactionName(reactionId: ReactionsTypeEnum): string {
        switch (reactionId) {
            case ReactionsTypeEnum.angry:
                return ReactionsNameEnum.angry;
            case ReactionsTypeEnum.dissatisfied:
                return ReactionsNameEnum.dissatisfied;
            case ReactionsTypeEnum.neutral:
                return ReactionsNameEnum.neutral;
            case ReactionsTypeEnum.satisfied:
                return ReactionsNameEnum.satisfied;
            case ReactionsTypeEnum.happy:
                return ReactionsNameEnum.happy;
        }
    }

    public checkIfLiveDesign(): boolean {
        return !!this.designProposal.groupId;
    }

    public checkIfHasMarkWinnerOption(): boolean {
        return (
            this.isAdmin() &&
            this.checkIfProjectEnded() &&
            this.checkIfVotingDateExpired()
        );
    }

    public checkIfProjectEnded(): boolean {
        const projectDate = FurbanUtil.transforDateForIOS(this.project.endDate);
        return (
            projectDate < Date.now() &&
            this.project.projectStatus.statusWeight !==
            ProjectStatusEnum.archived
        );
    }

    public checkIfVotingDateExpired(): boolean {
        if (this.isCollaborativeProject) {
            return true;
        }
        const projectDate = FurbanUtil.transforDateForIOS(
            this.project.votingExpiringDate
        );
        return projectDate < Date.now();
    }

    public cardSectionnClick(): void {
        this.cardClicked.emit();
    }

    public isAdmin(): boolean {
        return this.authService.hasAdministrativeRole();
    }

    public isUser(): boolean {
        return this.authService.isCitizen() || this.authService.isExpert();
    }

    public showReport(): boolean {
        return (
            this.authService.isAdmin() && this.designProposal.reportsCount > 0
        );
    }

    public openCommentDialog(): void {
        this.commentDialogOpened.emit(this.designProposal);
    }

    public openDPDetails(): void {
        if (!this.authService.isLoggedIn()) {
            if (this.isViewerOnly) {
                this.router.navigate([
                    routingConstants.publicDesignProposal,
                    this.designProposal.designProposalId,
                ]);
            } else {
                this.router.navigate([
                    routingConstants.takingPartDesign,
                    this.designProposal.designProposalId,
                ]);
            }
        } else {
            if (this.authService.isAdmin()) {
                this.router.navigate([
                    routingConstants.adminDesignProposals,
                    this.designProposal.designProposalId,
                ]);
            } else if (this.authService.isPioneer()) {
                this.router.navigate([
                    routingConstants.pioneerDesignProposals,
                    this.designProposal.designProposalId,
                ]);
            } else {
                this.router.navigate([
                    routingConstants.designProposals,
                    this.designProposal.designProposalId,
                ]);
            }
        }
    }

    public markAsWinner(event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }
        this.designProposalService
            .findIfWinnerProject(this.project.projectId)
            .subscribe((data) => {
                if (data) {
                    this.showWarningModal();
                } else {
                    this.openWinnerPopup();
                }
            });
    }

    public openWinnerPopup(): void {
        this.winnerPopupOpened.emit(this.designProposal);
    }

    public report(): void {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'report.warningMessage',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.saveReport();
                }
            });
    }

    public userDelete(userProfileId: string): void {
        if (userProfileId) {
            this.userDeleted.emit();
        }
    }

    public isLoggedUserNotWithAdminRole(): boolean {
        return !this.authService.isAdmin() && this.authService.isLoggedIn();
    }

    public shouldDisplayDpReports(): boolean {
        return (
            this.designProposal.reportsCount > 0 && this.authService.isAdmin()
        );
    }

    public deleteReporting(): void {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'report.reportResetMessage',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.manageDeleteReport();
                }
            });
    }

    public getPointsText(dp: DesignProposal): string {
        const pointsNumber = this.getPoints(dp);
        const numberOfPoints =
            this.index < 3
                ? `#${this.index + 1} - ${pointsNumber}`
                : `${pointsNumber}`;
        return `${numberOfPoints} ${this.traslateService.instant(
            'designProposals.points'
        )}`;
    }

    public createEmbededLink(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.minWidth = '400px';
        dialogConfig.width = '40%';

        dialogConfig.data = {
            iframeText: FurbanUtil.generateIframeString(
                this.designProposal.designProposalId,
                false
            ),
        };

        const userProfileDialogRef = this.dialog.open(
            EmbededPopupComponent,
            dialogConfig
        );
        userProfileDialogRef.componentInstance.parentViewContainerRef =
            this.viewContainerRef;
    }

    public showTop(): boolean {
        return (
            !this.isCollaborativeProject &&
            !this.isEmbeded &&
            !this.isPublicPage
        );
    }

    private showWarningModal(): void {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'winner.warningMessage',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.openWinnerPopup();
                }
            });
    }

    private saveReport(): void {
        const report = new Report();
        report.reportType = 0;
        report.reportDesignProposalId = this.designProposal.designProposalId;
        report.reportingUserProfile =
            this.authService.userProfile.userProfileId;
        report.isActive = true;

        this.reportService.saveReport(report).subscribe((data) => {
            this.showToaster(
                'check_circle',
                'success',
                'report.reportDesignSuccesfully'
            );
        });
    }

    private async manageDeleteReport(): Promise<void> {
        const report = new Report();
        report.reportType = 0;
        report.reportDesignProposalId = this.designProposal.designProposalId;

        this.reportService
            .getUserProfilesForDP(this.designProposal.designProposalId)
            .subscribe((userProfiles) => {
                this.reportService.deleteReport(report).subscribe((data) => {
                    this.designProposal.reportsCount = 0;

                    /* TO-DO chat sendAdministrationMessageFromChat - use translation key: 'user.chat.unproperDPMessageWasNot' */

                    this.showToaster(
                        'check_circle',
                        'success',
                        'report.reportResetSuccess'
                    );
                });
            });
    }

    private showToaster(icon: string, type: string, text: string): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            icon,
            type,
            text,
            1000
        );
    }
}
