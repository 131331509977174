import { Vector2, Vector3 } from 'three';
import { FurbanUtil } from '../helpers/furbanUtil';
import { ObjectTypeEnum } from '../_enum/object-type-enum';
import { HouseSceneConfig } from '../_models/house-config';
import { ThreeInstance } from './three-instance';

export class ThreeFreeshapeUtil {
    public threeInstance: ThreeInstance | HouseSceneConfig;

    constructor(threeInstance: ThreeInstance | HouseSceneConfig) {
        this.threeInstance = threeInstance;
    }

    public static computeFreeshapePosition(
        freeshapePoints: Vector3[]
    ): Vector3 {
        const minPointZ = FurbanUtil.getMin(freeshapePoints, 'z');
        const minPointX = FurbanUtil.getMin(freeshapePoints, 'x');

        const maxPointZ = FurbanUtil.getMax(freeshapePoints, 'z');
        const maxPointX = FurbanUtil.getMax(freeshapePoints, 'x');

        return new Vector3(
            (maxPointX - minPointX) / 2 + minPointX,
            0,
            (maxPointZ - minPointZ) / 2 + minPointZ
        );
    }

    public isExistentPoint(point: Vector3): boolean {
        for (
            let pointIndex = 0;
            pointIndex < this.threeInstance.freeshapeLinePoints.length - 1;
            ++pointIndex
        ) {
            if (
                this.threeInstance.freeshapeLinePoints[pointIndex].x ===
                    point.x &&
                this.threeInstance.freeshapeLinePoints[pointIndex].z === point.z
            ) {
                return true;
            }
        }
        return this.isTheLastPointNotAddedAtMouseMove(point);
    }

    public addPointToFreeShape(
        point: Vector3,
        isTapEvent: boolean = false
    ): void {
        if (this.isExistentPoint(point)) {
            return;
        }

        if (isTapEvent) {
            this.threeInstance.freeshapeLinePoints.push(point);
        } else {
            this.threeInstance.freeshapeLinePoints[
                this.threeInstance.freeShapeLineDetails.pointsLength
            ] = point;
        }
        this.threeInstance.freeShapeLineDetails.pointsLength++;
    }

    public hasSuficientNumberOfLinesToFinish(objectType: string): boolean {
        return (
            (objectType === ObjectTypeEnum.freeshape &&
                this.threeInstance.freeShapeLineDetails.pointsLength === 3) ||
            (objectType === ObjectTypeEnum.row &&
                this.threeInstance.freeShapeLineDetails.pointsLength === 1)
        );
    }

    private isTheLastPointNotAddedAtMouseMove(point: Vector3): boolean {
        const index = this.threeInstance.freeshapeLinePoints.length - 1;
        return (
            this.threeInstance.freeshapeLinePoints[index].x === point.x &&
            this.threeInstance.freeshapeLinePoints[index].z === point.z &&
            this.threeInstance.freeshapeLinePoints.length ===
                this.threeInstance.freeShapeLineDetails.pointsLength
        );
    }
}
