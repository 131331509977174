<ng-container *ngIf="shouldShowMenu">
    <section id="menu-div"
             class="menu">
        <nav class="tabs"
             [class.hidden]="!menuService.menuOpened">
            <section *ngFor="let object of objects">
                <img *ngIf="
                    object.category.categoryName !==
                    'user.projectMenu.groundMaterials'
                "
                     class="menu-item pointer"
                     alt="ProjPic"
                     matTooltip="{{ object.category.categoryName | translate }}"
                     [matTooltipDisabled]="isAppOrMobile()"
                     [class.active]="checkForActiveTab(object)"
                     [src]="
                    composeImageString(
                        object.models[0].furban3DModel.objectLookId
                    )
                "
                     [style.padding.px]="4"
                     (click)="navigateToTab(object)" />
            </section>
        </nav>

        <section class="flex-column-start"
                 [hidden]="!menuService.menuOpened">
            <ng-container *ngIf="shouldDisplayPrice()">
                <section class="project-price">
                    <h6 class="price-label">
                        {{ 'price.remaining' | translate }}
                    </h6>
                    <h4>
                        {{
                        availableBudget | currency : 'EUR' : 'symbol' : '0.0-2'
                        }}
                        /
                        {{ project.price | currency : 'EUR' : 'symbol' : '0.0-2' }}
                    </h4>
                </section>
            </ng-container>
            <div *ngIf="canUpdateDesign(); else cannotUpdateDesign">
                <div *ngIf="activeItem; else customTabContent"
                     class="items-container menu-grid"
                     cdkDropList
                     cdkDropListSortingDisabled
                     [class.radius]="shouldDisplayPrice()"
                     [cdkDropListData]="activeItem.models"
                     (cdkDropListDropped)="drop($event, activeItem.models)">
                    <div *ngFor="let obj of activeItem.models"
                         class="menu-item-container pointer">
                        <div class="menu-item-image-container"
                             cdkDrag
                             [id]="obj.project3DModelId"
                             [class.enabled]="isObjectSelected(obj)"
                             [cdkDragStartDelay]="getDragDelay()"
                             (cdkDragStarted)="dragStart(obj)"
                             (mouseenter)="mouseEnterObject(obj, $event)"
                             (mouseleave)="mouseLeave()">
                            <img id="{{ obj.furban3DModel.objectLookId }}"
                                 class="menu-item-image full-width"
                                 alt="ProjPic"
                                 appHoldable
                                 [elementLookId]="obj.furban3DModel.objectLookId"
                                 [longpressTimer]="1000"
                                 [src]="
                                composeImageString(
                                    obj.furban3DModel.objectLookId
                                )
                            "
                                 [style.padding.px]="8"
                                 (eventHandler)="holdHandler($event, obj)" />
                            <div *ngIf="
                                menuService &&
                                menuService.objectToAddOnClick ===
                                    obj.furban3DModel.objectLookId
                            "
                                 class="background-selection"
                                 (click)="removeObjectToAdd()">
                                <span class="material-icons float-icon custom-pos">close</span>
                            </div>
                        </div>
                        <h6 *ngIf="shouldDisplayPrice()"
                            class="objects-prices">
                            {{ obj.price | currency : 'EUR' : 'symbol' : '0.0-2' }}
                        </h6>
                    </div>
                </div>
            </div>
        </section>
        <ng-template #cannotUpdateDesign>
            <div *ngIf="!canUpdateDesign()"
                 class="items-container flex-column-center padding-20">
                <div class="">
                    <img class="full-width"
                         src="../../../assets/images/no_permission.svg"
                         [style.padding-bottom.px]="20" />
                    <h6 *ngIf="!project.ended"
                        class="text-center">
                        {{
                        'user.projectMenu.modificationsForbidenPublishedDesign'
                        | translate
                        }}
                    </h6>
                    <h6 *ngIf="project.ended"
                        class="text-center">
                        {{
                        'user.projectMenu.modificationForEndedProject'
                        | translate
                        }}
                    </h6>
                </div>
            </div>
        </ng-template>
    </section>
</ng-container>
<ng-container *ngIf="enableHoverOnMenuItem">
    <div class="hover-display flex-column-center card-appearance"
         [class.include-dashboard-header]="hasAdministrativeRole()"
         [class.actions]="isToolsHover || exceedTopPosition"
         [style.top.px]="hoverContainerTop"
         [style.height.px]="heightOfHoverDiv"
         [style.width.px]="heightOfHoverDiv"
         [@hoverInOut]>
        <h6>
            {{ hoverObjectName }}
        </h6>
        <img class="full-size"
             alt="ProjPic"
             [src]="hoverImagePath" />
    </div>
</ng-container>