<div id="confetti-container">
    <div mat-dialog-title>
        <h2>{{ 'collaborativeDesign.reconnectTitle' | translate }}</h2>
    </div>

    <mat-dialog-content>
        <div class="flex-column-center">
            <img src="../../../assets/images/wait_live_session.svg"
                 [style.height.px]="200" />

            <p [style.margin-top.px]="20">
                {{ 'collaborativeDesign.reconnectDescription' | translate }}
            </p>

            <div class="buttons-right-container"
                 [style.margin-top.px]="20">
                <button class="primary-button"
                        (click)="reconnect()">
                    <h6>
                        {{ 'collaborativeDesign.reconnectBtn' | translate }}
                    </h6>
                </button>
            </div>
        </div>
    </mat-dialog-content>
</div>