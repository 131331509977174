import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { UserProfile } from '@furban/utilities';
import { WinnerCongratulationsComponent } from './winner-congratulations.component';

@Injectable()
export class WinnerInterceptor implements HttpInterceptor {
    constructor(public dialog: MatDialog) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (
                    event instanceof HttpResponse &&
                    request.url === '/api/userprofile' &&
                    event.body &&
                    event.body.winner
                ) {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = true;
                    dialogConfig.minWidth = '400px';
                    dialogConfig.width = '40%';
                    dialogConfig.data = {
                        userProfile: event.body as UserProfile,
                    };
                    this.dialog.open(
                        WinnerCongratulationsComponent,
                        dialogConfig
                    );
                }
                return event;
            })
        );
    }
}
