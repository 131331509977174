import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

import {
    Comment,
    CommentsComponent,
    DesignProposal,
    Project,
    QuickViewEnum,
} from '@furban/utilities';

@Component({
    selector: 'furban-dp-quick-view',
    templateUrl: './dp-quick-view.component.html',
    styleUrls: ['./dp-quick-view.component.scss'],
})
export class DpQuickViewComponent {
    @ViewChild('comments') commentsRef: CommentsComponent;

    @Input() designProposals: DesignProposal[];
    @Input() project: Project;
    @Input() viewContainerRef: ViewContainerRef;
    @Input() disabledActions = false;
    @Input() isCollaborativeProject: boolean;
    @Input() currentDp?: DesignProposal;
    @Input() quickView: QuickViewEnum;

    @Output() scrollEmitter: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    @Output() designProposalSelect: EventEmitter<DesignProposal> =
        new EventEmitter<DesignProposal>();
    @Output() commentButtonClick: EventEmitter<DesignProposal> =
        new EventEmitter<DesignProposal>();
    @Output() quickViewClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() reportEvent: EventEmitter<Comment> =
        new EventEmitter<Comment>();
    @Output() pinCommentEvent: EventEmitter<void> = new EventEmitter<void>();

    public changeDp(dp: DesignProposal): void {
        this.designProposalSelect.emit(dp);
    }

    public onScroll(event: any): void {
        if (
            event.target.offsetHeight + event.target.scrollTop >=
            event.target.scrollHeight
        ) {
            this.scrollEmitter.emit(true);
        }
    }

    public openCommentDialog(event: DesignProposal) {
        this.commentButtonClick.emit(event);
    }

    public toggleQuickView(): void {
        this.quickViewClose.emit();
    }

    public report(event?: Comment): void {
        this.reportEvent.emit(event);
    }

    public get isCommentsView(): boolean {
        return this.quickView === QuickViewEnum.comments;
    }

    public get isDpView(): boolean {
        return this.quickView === QuickViewEnum.designProposal;
    }
}
