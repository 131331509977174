<furban-login-wrapper [stylingClass]="'initiative'"
                      [infoText]="'user.auth.infoText' | translate">
    <ng-container *ngIf="!submited">
        <form class="login-form"
              [formGroup]="registerForm"
              (ngSubmit)="submitRegistration()">
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'generic.email' | translate }}
                </mat-label>
                <input required
                       matInput
                       autocomplete="false"
                       placeholder="{{ 'generic.email' | translate }}"
                       formControlName="emailFormControl" />

                <mat-hint align="start">{{ getEmailHintMessage() }}</mat-hint>
                <mat-error *ngIf="registerForm.get('emailFormControl').errors">
                    {{ getEmailErrorMessage() }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'auth.password' | translate }}
                </mat-label>
                <input required
                       matInput
                       autocomplete="false"
                       placeholder="{{ 'auth.password' | translate }}"
                       formControlName="passwordFormControl"
                       type="password" />
                <mat-hint align="start"
                          class="password-hint">{{
                    'auth.validPasswordHint' | translate
                    }}</mat-hint>
                <mat-error *ngIf="getPasswordValidationErrors">
                    {{
                    getPasswordErrorMessage(
                    getPasswordValidationErrors,
                    translateService
                    )
                    }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'admin.auth.confirmPassword' | translate }}
                </mat-label>
                <input required
                       matInput
                       autocomplete="false"
                       placeholder="{{ 'admin.auth.confirmPassword' | translate }}"
                       formControlName="passwordConfirmFormControl"
                       type="password"
                       [errorStateMatcher]="errorMatcher" />
                <mat-error *ngIf="
                        registerForm.get('passwordConfirmFormControl').errors ||
                        registerForm.hasError('passwordMatches')
                    ">
                    {{ getConfirmPasswordErrorMessage() }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width"
                            appearance="outline">
                <mat-label>
                    {{ 'auth.municipality' | translate }}
                </mat-label>
                <input matInput
                       required
                       type="text"
                       formControlName="municipalityFormControl"
                       placeholder="{{ 'auth.municipality' | translate }}"
                       [matAutocomplete]="auto" />
                <mat-error *ngIf="hasControlMunicipalityNotSelectedError('municipalityFormControl',registerForm)">
                    {{ 'admin.auth.municipalityNotSelected' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-autocomplete autoActiveFirstOption
                              #auto="matAutocomplete"
                              [displayWith]="displayFn"
                              (optionSelected)="
                    updateSelectedMunicipality($event.option.value)
                ">
                <mat-option *ngFor="let municipality of filteredMunicipalitiesOptions"
                            [value]="municipality">
                    {{ municipality.clientName }}
                </mat-option>
            </mat-autocomplete>

            <button class="primary-button full-width"
                    type="submit">
                <h6>{{ 'generic.save' | translate }}</h6>
            </button>

            <a (click)="goToLogin()">{{
                'user.auth.backToLogin' | translate
                }}</a>
        </form>
    </ng-container>

    <ng-container *ngIf="submitedForRole('pioneer')">
        <div class="padding-20 flex-column-center">
            <img src="../../assets/images/pioneer/email-verification/already-confirmed.svg"
                 [style.height.px]="160" />
            <h6 class="placeholder-text">
                {{ 'pioneer.auth.emailHasBeenSent' | translate }}
            </h6>
            <a (click)="goToLogin()">{{
                'user.auth.backToLogin' | translate
                }}</a>
        </div>
    </ng-container>

    <ng-container *ngIf="submitedForRole('admin')">
        <div class="padding-20 flex-column-center">
            <img src="../../assets/images/pioneer/email-verification/already-confirmed.svg"
                 [style.height.px]="80" />
            <h6 class="placeholder-text">
                {{ 'auth.registerSubmitted.congratulations' | translate }}
                {{ selectedMunicipality.clientName }}.
                {{ 'auth.registerSubmitted.adminSendEmail' | translate }}
            </h6>

            <a (click)="goToLogin()">{{
                'user.auth.backToLogin' | translate
                }}</a>
        </div>
    </ng-container>
</furban-login-wrapper>