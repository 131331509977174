import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {
    CustomToasterComponent,
    CustomToasterService,
    ExportFilesUtil,
    FurbanUtil,
    Group,
    ModalManager,
    routingConstants,
    StepperService,
    ToolingActionsEnum,
} from '@furban/utilities';
import { CreateGroupComponent } from '../create-group/create-group.component';
import { CodesService } from '../../shared/_services/codes.service';
import { GroupsService } from '../../municipality/admin/admin-codes/groups/groups.service';
import { Subscription } from 'rxjs';
import { ToolingService } from '../../shared/tooling/tooling.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'furban-project-groups',
    templateUrl: './project-groups.component.html',
    styleUrls: ['./project-groups.component.scss'],
})
export class ProjectGroupsComponent implements OnInit, OnDestroy {
    public projectId: string;
    public curentGroups: Group[];
    public totalExperts = 0;
    public totalCitizens = 0;
    public dataSource: MatTableDataSource<Group>;
    public displayedColumns: string[] = [
        'select',
        'groupName',
        'createdDate',
        'numberOfCitizens',
        'numberOfExperts',
        'linkOff',
    ];
    public selection = new SelectionModel<any>(true, []);
    public areGroupsLoaded = false;
    public isApp = FurbanUtil.isApp;

    private toolingActionsSubscription: Subscription;
    
    constructor(
        private groupService: GroupsService,
        public modalManager: ModalManager,
        private translateService: TranslateService,
        private containerRef: ViewContainerRef,
        private customToasterService: CustomToasterService,
        public dialog: MatDialog,
        private codesService: CodesService,
        private router: Router,
        private stepperService: StepperService,
        private toolingService: ToolingService
    ) { }

    ngOnInit() {
        this.projectId = this.stepperService.project.projectId;
        this.getGroupsByProject();
        this.stepperService.modifyCurrentStepId(false);
        this.subscribeToToolingActionObservable();
    }

    ngOnDestroy() {
        this.toolingActionsSubscription.unsubscribe();
    }

    getGroupsByProject() {
        this.totalCitizens = 0;
        this.totalExperts = 0;
        this.groupService
            .getGroupsByProjectId(this.projectId)
            .subscribe((data) => {
                this.curentGroups = data;
                this.dataSource = new MatTableDataSource(this.curentGroups);
                for (const group of this.curentGroups) {
                    this.totalCitizens += group.usersCount;
                    this.totalExperts += group.expertsCount;
                }
                this.areGroupsLoaded = true;
            });
    }

    removeGroup(groupId: string) {
        this.modalManager
            .showModal(
                this.containerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.groups.deleteWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.groupService
                        .unassignGroupToProject(this.projectId, groupId)
                        .subscribe((data) => {
                            this.selection.clear();
                            this.getGroupsByProject();
                            this.showSnackbar(
                                'success',
                                this.translateService.instant(
                                    'admin.groups.removedFromProj'
                                ),
                                'check_circle'
                            );
                        });
                }
            });
    }

    createNewGroup(type: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '40%';
        dialogConfig.data = {
            type: type,
            projectId: this.projectId,
            noOfExperts: this.totalExperts,
        };
        const userProfileDialogRef = this.dialog.open(
            CreateGroupComponent,
            dialogConfig
        );
        userProfileDialogRef.componentInstance.parentViewContainerRef =
            this.containerRef;

        userProfileDialogRef.afterClosed().subscribe((data) => {
            this.selection.clear();
            this.manageDataResponseFromDialog(data);
        });
    }

    manageDataResponseFromDialog(data: Group) {
        if (data) {
            if (this.curentGroups.find((x) => x.groupId === data.groupId)) {
                this.showSnackbar(
                    'info',
                    this.translateService.instant('admin.groups.alreadyExists'),
                    'info'
                );
            } else {
                this.curentGroups.unshift(data);
                this.totalExperts += data.expertsCount;
                this.totalCitizens += data.usersCount;
                this.dataSource = new MatTableDataSource(this.curentGroups);
                this.showSnackbar(
                    'success',
                    this.translateService.instant('admin.groups.addedToProj'),
                    'check_circle'
                );
            }
        }
    }

    showSnackbar(type: string, message: string, icon: string) {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            icon,
            type,
            message,
            2000
        );
    }

    hasGroups() {
        if (
            !this.curentGroups ||
            (this.curentGroups && this.curentGroups.length <= 0)
        ) {
            return false;
        } else {
            return true;
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.dataSource.data.forEach((row) => this.selection.select(row));
        }
    }

    createCSV(url: string, codesType: string, groupIds: string[]) {
        this.codesService.exportExpertOrCitizenCodes(url, groupIds).subscribe(
            (data) => {
                ExportFilesUtil.createCSV(data, codesType + '_codes_');
            }
        );
    }

    exportCodes(event) {
        const codesType = event.target.value;
        const groupIds = [];
        this.selection.selected.forEach((element) =>
            groupIds.push(element.groupId)
        );

        if (codesType === 'citizen') {
            this.createCSV(
                this.codesService.urls.citizenExportCsv,
                codesType,
                groupIds
            );
        } else if (codesType === 'expert') {
            this.createCSV(
                this.codesService.urls.expertExportCsv,
                codesType,
                groupIds
            );
        }
    }

    navigateToGroup(group: Group) {
        this.router.navigate([routingConstants.adminCodes, group.groupId]);
    }

    private subscribeToToolingActionObservable() {
        this.toolingActionsSubscription =
            this.toolingService.toolingActionsObservable
                .pipe(
                    filter(
                        (data) =>
                            data &&
                            (data === ToolingActionsEnum.createGroup ||
                                data === ToolingActionsEnum.selectGroup)
                    )
                )
                .subscribe((data) => {
                    if (data === ToolingActionsEnum.createGroup) {
                        this.createNewGroup('create');
                    } else {
                        this.createNewGroup('select');
                    }
                });
    }
}
