import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewContainerRef,
} from '@angular/core';

import { AuthService, ModalManager, PermitComment } from '@furban/utilities';

@Component({
    selector: 'furban-comment-actions',
    templateUrl: './comment-actions.component.html',
    styleUrls: ['./comment-actions.component.scss'],
})
export class CommentActionsComponent {
    @Input() public viewContainerRef: ViewContainerRef;
    @Input() public comment: PermitComment;
    @Output() public commentDeleted = new EventEmitter<PermitComment>();

    constructor(
        private authService: AuthService,
        private modal: ModalManager
    ) { }

    public deleteComment(comment: PermitComment) {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'comments.commentDeleteWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.commentDeleted.emit(comment);
                }
            });
    }

    public isAuthUserComment(comment: PermitComment): boolean {
        return (
            comment.userProfile.userProfileId ===
            this.authService.userProfile.userProfileId
        );
    }

    public isAdminOrPioneer() {
        return this.authService.isAdmin() || this.authService.isPioneer();
    }
}
