<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'winner.popupTitle' | translate }}</h2>
    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons"> close </span>
    </button>
</div>

<mat-dialog-content>
    <mat-form-field class="full-width" hintLabel="{{ 'winner.winnerMaxHint' | translate }}"
        [style.margin-bottom.px]="12" appearance="outline">
        <mat-label>
            {{ 'winner.label' | translate }}
        </mat-label>
        <textarea #input matInput placeholder="{{ 'winner.label' | translate }}" maxlength="2048"
            [formControl]="winnerMessage"></textarea>
        <mat-hint align="end">{{ input.value?.length || 0 }}/2048</mat-hint>

        <mat-error *ngIf="hasControlError(winnerMessage,'isHTML')">
            {{ 'comments.noHTMLContent' | translate }}
        </mat-error>
        <mat-error *ngIf="hasControlError(winnerMessage,'whitespace')">
            {{ 'winner.whitespace' | translate }}
        </mat-error>
    </mat-form-field>
    <mat-error *ngIf="hasControlError(winnerMessage,'maxlength')">
        {{ 'winner.maxLength' | translate }}
    </mat-error>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closeDialog()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="saveWinner()">
        <h6>{{ 'generic.save' | translate }}</h6>
    </button>
</div>