<div class="important-space-between" mat-dialog-title>
    <h2>{{ dialogTitle }}</h2>
    <button class="ghost-button" (click)="closePopup()">
        <span class="material-icons">close</span>
    </button>
</div>

<mat-dialog-content>
    <form #adminPollForm="ngForm" name="pollform" role="form" [formGroup]="adminPollCheckForm">
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>
                {{ 'admin.poll.question' | translate }}
            </mat-label>
            <textarea #question required matInput maxlength="256" rows=3 formControlName="pollQuestion"
                placeholder="{{ 'admin.poll.question' | translate }}"></textarea>
            <mat-hint>{{question.value.length}}/{{questionLimit}}</mat-hint>
            <mat-error *ngIf="hasControlRequiredError('pollQuestion',adminPollCheckForm)">
                {{ 'admin.poll.questionRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="
                        containSpaceErrorAndNotRequirredTriggeredFunction(
                            adminPollCheckForm,
                            'pollQuestion'
                        )
                    ">
                {{ 'generic.emptyError' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlMaxLengthError('pollQuestion',adminPollCheckForm)">
                {{ 'admin.poll.questionLength' | translate }}
            </mat-error>
        </mat-form-field>

        <div class="flex-row-spaced full-width date-field">
            <mat-form-field class="full-width" appearance="outline" (click)="picker1.open()">
                <mat-label>
                    {{ 'admin.poll.startDate' | translate }}
                </mat-label>
                <input required matInput placeholder="{{ 'admin.poll.startDate' | translate }}"
                    formControlName="startDate" [matDatepicker]="picker1" />
                <mat-error *ngIf="hasControlRequiredError('pollQuestion',adminPollCheckForm)">
                    {{ 'admin.poll.dateRequired' | translate }}
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline" (click)="picker2.open()">
                <mat-label>
                    {{ 'admin.poll.endDate' | translate }}
                </mat-label>
                <input required matInput placeholder="{{ 'admin.poll.endDate' | translate }}" formControlName="endDate"
                    [matDatepicker]="picker2" />
                <mat-error *ngIf="hasControlRequiredError('pollQuestion',adminPollCheckForm)">
                    {{ 'admin.poll.dateRequired' | translate }}
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
    </form>
    <form #adminPollForm="ngForm" name="polloptionform" role="form" [formGroup]="adminPollOptionCheckForm">
        <div class="flex-column-spaced full-width">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>
                    {{ 'admin.poll.option' | translate }}
                </mat-label>
                <input required matInput maxlength="64" formControlName="option1"
                    placeholder="{{ 'admin.poll.option' | translate }}" />
                <mat-error *ngIf="hasControlRequiredError('option1',adminPollOptionCheckForm)">
                    {{ 'admin.poll.optionRequired' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>
                    {{ 'admin.poll.option' | translate }}
                </mat-label>
                <input required matInput maxlength="64" formControlName="option2"
                    placeholder="{{ 'admin.poll.option' | translate }}" />
                <mat-error *ngIf="hasControlRequiredError('option2',adminPollOptionCheckForm)">
                    {{ 'admin.poll.optionRequired' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex-column-spaced full-width">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>
                    {{ 'admin.poll.option' | translate }}
                </mat-label>
                <input matInput maxlength="64" formControlName="option3"
                    placeholder="{{ 'admin.poll.option' | translate }}" />
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>
                    {{ 'admin.poll.option' | translate }}
                </mat-label>
                <input matInput maxlength="64" formControlName="option4"
                    placeholder="{{ 'admin.poll.option' | translate }}" />
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closePopup()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="savePoll()">
        <h6>{{ 'generic.save' | translate }}</h6>
    </button>
</div>