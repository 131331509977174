import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { routingConstants } from '../_constants/routing-constants';

@Injectable({
    providedIn: 'root',
})
export class PreviousRouteService {
    private currentUrl: string;
    private lastVisitedNodeURL: string;

    constructor(private router: Router) {
        this.currentUrl = this.router.url;
        this.lastVisitedNodeURL = null;

        this.router.events
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationEnd)
            )
            .subscribe((event: NavigationEnd) => {
                this.currentUrl = event.urlAfterRedirects;

                if (this.currentUrl === this.lastVisitedNodeURL) {
                    return;
                }

                if (this.isNodeURL(this.currentUrl)) {
                    this.lastVisitedNodeURL = this.currentUrl;
                }
            });
    }

    private isNodeURL(url: string) {
        return (
            url === routingConstants.info ||
            url === `/${routingConstants.takingPart}`
        );
    }

    public getLastVisitedNodeURL() {
        return this.lastVisitedNodeURL;
    }
}
