<div class="main-container" infiniteScroll [infiniteScrollThrottle]="300" (scrolled)="onScrollDown()"
    [scrollWindow]="false">
    <div class="header-container">
        <h3 class="page-title">{{ 'notifications.title' | translate }}</h3>
        <div class="buttons-container">
            <button class="primary-button" (click)="openNotificationDialog()">
                <h6>{{ 'notifications.create' | translate }}</h6>
            </button>
        </div>
    </div>

    <div class="bottom-container padding-40-32">
        <table *ngIf="notificationList && notificationList.length > 0" mat-table class="full-width"
            [dataSource]="dataSource">
            <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'notifications.message' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.message }}
                </td>
            </ng-container>
            <ng-container matColumnDef="notificationType">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'notifications.notificationType' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ returnNotificationTypeAsString(row) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="targetName">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'notifications.notificationTargetName' | translate }}
                </th>
                <td class="elipsis-on-exceeding-width-95" mat-cell *matCellDef="let row">
                    {{ row.targetName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'notifications.createdDate' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.createdDate | date : 'mediumDate' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="expiringDate">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'notifications.expiringDateText' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.expiringDate | date : 'mediumDate' }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <ng-container *ngIf="
                !notificationList ||
                (notificationList && notificationList.length === 0)
            ">
            <div class="flex-column-center" [style.margin-top.px]="40">
                <img src="../../../assets/images/no_notifications.svg" [style.height.px]="240" />
                <h4 class="placeholder-text">
                    {{ 'notifications.emptyNotifications' | translate }}
                </h4>
            </div>
        </ng-container>
    </div>
</div>