export const CATEGORIES_COLOR: Map<string, string[]> = new Map()
    .set('user.projectMenu.groundMaterials', [
        'rgba(243, 186, 77, 0.7)',
        'rgba(243, 186, 77, 1)',
    ])
    .set('user.projectMenu.defaultStructures', [
        'rgba(0, 87, 138, 0.7)',
        'rgba(0, 87, 138, 1)',
    ])
    .set('user.projectMenu.trees', [
        'rgba(107, 61, 51, 0.7)',
        'rgba(107, 61, 51, 1)',
    ])
    .set('user.projectMenu.plants', [
        'rgba(97, 184, 97, 0.7)',
        'rgba(97, 184, 97, 1)',
    ])
    .set('user.projectMenu.playground', [
        'rgba(116, 69, 178, 0.7)',
        'rgba(116, 69, 178, 1)',
    ])
    .set('user.projectMenu.streetFurniture', [
        'rgba(112, 170, 230, 0.7)',
        'rgba(112, 170, 230, 1)',
    ])
    .set('user.projectMenu.traficAndLighting', [
        'rgba(112, 112, 112, 0.7)',
        'rgba(112, 112, 112, 1)',
    ])
    .set('user.projectMenu.walls', [
        'rgba(255, 243, 165, 0.7)',
        'rgba(255, 243, 165, 1)',
    ])
    .set('user.projectMenu.surroundings', [
        'rgba(181, 27, 27, 0.7)',
        'rgba(181, 27, 27, 1)',
    ])
    .set('user.projectMenu.customObjects', [
        'rgba(204, 0, 102, 0.7)',
        'rgba(204, 0, 102, 1)',
    ]);
