import { AbstractControl } from '@angular/forms';

export function validatePassword(control: AbstractControl) {
    const text = control.value;

    if (
        text != null &&
        (text.length < 8 || text.toLowerCase() === text || !/\d/.test(text) || !text.match(/[a-z]/) || !text.match(/[\p{P}]/u))
    ) {
        return { validPassword: true };
    }

    return null;
}
