export class Opened3DSections {
    openedMenu: boolean;
    openedNavigation: boolean;
    viewMode: boolean;
    fullScreenMode: boolean;

    constructor(
        openedMenu: boolean,
        openedNavigation: boolean,
        viewMode: boolean,
        fullScreen: boolean
    ) {
        this.openedMenu = openedMenu;
        this.openedNavigation = openedNavigation;
        this.viewMode = viewMode;
        this.fullScreenMode = fullScreen;
    }
}
