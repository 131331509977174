import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Like } from '../_models/like';
import { map } from 'rxjs/operators';
import { Comment } from '../_models/comment';
import { DesignProposal } from '../_models/design-proposal';
import { LoaderHttpParams } from '../_models/loader-http-param';
import { APP_CONFIG } from '@furban/app-config';
import { DesignProposalRequestParam } from '../_models/design-proposal-request-param';
import { DesignProposalEnum } from '../_enum/dp-filter.enum';
import { DesignProposalIsAdminFilterEnum } from '../_enum/dp-is-admin-filter.enum';
import { NavigationStart, RouterEvent } from '@angular/router';
import { routingConstants } from '../_constants/routing-constants';
import { DesignProposalStatus } from '../_models/design-proposal-status';
import { DesignProposalStatusEnum } from '../_enum/design-proposal-status.enum';
import { DesignProposalLight } from '../_models/design-proposal-light';
import { Environment } from '../_models/environment-model';
import { DpCitizenViewEnum } from '../_enum/dp-citizen-view.enum';

@Injectable({
    providedIn: 'root',
})
export class DesignProposalService {
    public citizenView: DpCitizenViewEnum;

    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );
    private perPageParam = '20';

    public selectedPriceValues = [
        DesignProposalEnum.onBudgetDesigns,
        DesignProposalEnum.overBudgetDesign,
    ];
    public selectedDPTypeValues = [
        DesignProposalIsAdminFilterEnum.adminDesigns,
        DesignProposalIsAdminFilterEnum.citizenDesigns,
    ];

    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        private http: HttpClient
    ) { }

    public getDesignProposals(
        designProposalRequestParam: DesignProposalRequestParam
    ): Observable<any> {
        const params = new HttpParams()
            .set('pageIndex', designProposalRequestParam.page.toString())
            .set('perPage', this.perPageParam)
            .set('priceFilterType', designProposalRequestParam.priceFilterType)
            .set(
                'filterDesignType',
                designProposalRequestParam.isAdminDesignFilter
            )
            .set('sortType', designProposalRequestParam.criteriaPath);
        return this.http
            .get(
                `${this.environment.apiPath}/design-proposal/${designProposalRequestParam.id}`,
                {
                    params: params,
                }
            )
            .pipe(map((result) => result as DesignProposal[]));
    }

    public getPublicDesignProposals(
        designProposalRequestParam: DesignProposalRequestParam
    ): Observable<DesignProposalLight[]> {
        const params = new HttpParams()
            .set('pageIndex', designProposalRequestParam.page.toString())
            .set('perPage', this.perPageParam)
            .set('priceFilterType', designProposalRequestParam.priceFilterType)
            .set(
                'filterDesignType',
                designProposalRequestParam.isAdminDesignFilter
            )
            .set('sortType', designProposalRequestParam.criteriaPath);
        return this.http
            .get(
                `${this.environment.apiPath}/public-design-proposal/${designProposalRequestParam.id}`,
                {
                    params: params,
                }
            )
            .pipe(map((result) => result as DesignProposalLight[]));
    }

    public getMunicipalityDesignProposals(
        designProosalRequestParam: DesignProposalRequestParam
    ): Observable<DesignProposal[]> {
        const params = new HttpParams()
            .set('pageIndex', designProosalRequestParam.page.toString())
            .set('perPage', this.perPageParam);
        return this.http
            .get(
                `${this.environment.apiPath}/design-proposal/municipality/${designProosalRequestParam.id}`,
                {
                    params: params,
                }
            )
            .pipe(map((result) => result as DesignProposal[]));
    }

    public getCollaborativeLiveDesignProposals(
        designProosalRequestParam: DesignProposalRequestParam
    ): Observable<DesignProposal[]> {
        const params = new HttpParams()
            .set('pageIndex', designProosalRequestParam.page.toString())
            .set('perPage', this.perPageParam);
        return this.http
            .get(
                `${this.environment.apiPath}/design-proposal/collaborative/${designProosalRequestParam.id}`,
                {
                    params: params,
                }
            )
            .pipe(map((result) => result as DesignProposal[]));
    }

    public getDesignProposalCount(id: string): Observable<number> {
        return this.http
            .get(`${this.environment.apiPath}/design-proposal/count/${id}`)
            .pipe(map((result) => result as number));
    }

    public getCitizenDesignProposalCount(id: string): Observable<number> {
        return this.http
            .get(
                `${this.environment.apiPath}/design-proposal/citizen/count/${id}`
            )
            .pipe(map((result) => result as number));
    }

    public getMunicipalityDesignProposalCount(id: string): Observable<number> {
        return this.http
            .get(
                `${this.environment.apiPath}/design-proposal/default/count/${id}`
            )
            .pipe(map((result) => result as number));
    }

    public getCollaborativeDesignProposalCount(id: string): Observable<number> {
        return this.http
            .get(
                `${this.environment.apiPath}/design-proposal/collaborative/count/${id}`
            )
            .pipe(map((result) => result as number));
    }

    public getDetails(id: string): Observable<DesignProposal> {
        return this.http
            .get(`${this.environment.apiPath}/design-proposal/details/${id}`)
            .pipe(map((result) => result as DesignProposal));
    }

    public likeDesignProposal(like: Like): Observable<Like> {
        return this.http
            .post(this.environment.apiPath + '/like', like, {
                headers: this._headers,
                params: new LoaderHttpParams(true),
            })
            .pipe(map((result) => result as Like));
    }

    // REFACTOR - this method should be on comment service
    public commentDesignProposal(comment: Comment): Observable<Comment> {
        return this.http
            .post(this.environment.apiPath + '/comment', comment, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Comment));
    }

    public saveAdminDesignProposal(
        dp: DesignProposal
    ): Observable<DesignProposal> {
        return this.http
            .post(this.environment.apiPath + '/design-proposal/admin', dp, {
                headers: this._headers,
            })
            .pipe(map((result) => result as DesignProposal));
    }

    public saveCollaborativeDesignProposal(
        dp: DesignProposal
    ): Observable<DesignProposal> {
        return this.http
            .post(
                this.environment.apiPath + '/design-proposal/collaborative',
                dp,
                { headers: this._headers }
            )
            .pipe(map((result) => result as DesignProposal));
    }

    public updateCollaborativeDesignProposal(
        dp: DesignProposal
    ): Observable<DesignProposal> {
        return this.http
            .put(
                this.environment.apiPath + '/design-proposal/collaborative',
                dp,
                { headers: this._headers }
            )
            .pipe(map((result) => result as DesignProposal));
    }

    public getCollaborativeDesignProposal(): Observable<DesignProposal> {
        return this.http
            .get(`${this.environment.apiPath}/design-proposal/collaborative`)
            .pipe(map((result) => result as DesignProposal));
    }

    public saveDesignProposalAsWinner(
        dp: DesignProposal
    ): Observable<DesignProposal> {
        return this.http
            .post(this.environment.apiPath + '/design-proposal/winner', dp, {
                headers: this._headers,
            })
            .pipe(map((result) => result as DesignProposal));
    }

    public findIfWinnerProject(id: string): Observable<boolean> {
        return this.http
            .get(
                `${this.environment.apiPath}/design-proposal/winner/check/${id}`
            )
            .pipe(map((result) => result as boolean));
    }

    public getWinnerDP(id: string): Observable<DesignProposal> {
        return this.http
            .get(`${this.environment.apiPath}/design-proposal/winner/${id}`)
            .pipe(map((result) => result as DesignProposal));
    }

    public deleteDP(designProposalId: string): Observable<void> {
        return this.http.delete<void>(
            `${this.environment.apiPath}/design-proposal/${designProposalId}`
        );
    }

    public resetDPFiltersForProject() {
        this.selectedPriceValues = [
            DesignProposalEnum.onBudgetDesigns,
            DesignProposalEnum.overBudgetDesign,
        ];
        this.selectedDPTypeValues = [
            DesignProposalIsAdminFilterEnum.adminDesigns,
            DesignProposalIsAdminFilterEnum.citizenDesigns,
        ];
    }

    public checkIfShouldReset(event: RouterEvent) {
        if (event instanceof NavigationStart) {
            if (
                event.url.indexOf(`${routingConstants.adminDesignProposals}/`) <
                0 &&
                event.url.indexOf('/(project:project-designs)') < 0 &&
                event.url.indexOf(`${routingConstants.designs}`) < 0 &&
                event.url.indexOf(
                    `${routingConstants.pioneerDesignProposals}`
                ) < 0 &&
                event.url.indexOf(`${routingConstants.designProposals}/`) < 0 &&
                event.url.indexOf(`/${routingConstants.takingPart}/`) < 0
            ) {
                this.resetDPFiltersForProject();
            }
        }
    }

    public changeLiveSessionStatus(
        dp: DesignProposal,
        status: DesignProposalStatusEnum
    ): Observable<DesignProposal> {
        dp.designProposalStatus = new DesignProposalStatus(status);
        return this.http
            .post(
                this.environment.apiPath + '/design-proposal/update-status',
                dp,
                { headers: this._headers }
            )
            .pipe(map((result) => result as DesignProposal));
    }
}
