import { Furban3DModel } from './furban-3d-model';
import { Project3dModel } from './project-3d-model';

export class Emitted3DObject {
    public project3DModel: Project3dModel;
    public isDragged: boolean;

    constructor(project3DModel: Project3dModel, isDragged: boolean) {
        this.project3DModel = project3DModel;
        this.isDragged = isDragged;
    }
}
