import { DashboardItem } from '../_models/dashboard-item';
import { routingConstants } from './routing-constants';

export class AdminDefaultDashboardItemsArray {
    public static createDashboardItems(projectId: string): DashboardItem[] {
        return [
            {
                id: 0,
                title: 'admin.cardsLabels.projectDetailsPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/proj-details.png')",
                path: `${routingConstants.process}/${projectId}/(project:details)`,
            },
            {
                id: 1,
                title: 'admin.cardsLabels.projectAreaPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/proj-area.png')",
                path: `${routingConstants.process}/${projectId}/(project:area-definition)`,
            },
            {
                id: 2,
                title: 'admin.cardsLabels.setupDefaultObjHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/default-obj.png')",
                path: `${routingConstants.process}/${projectId}/(project:setup-default-objects)`,
            },
            {
                id: 3,
                title: 'admin.cardsLabels.manageObjectsPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/manage-obj.png')",
                path: `${routingConstants.process}/${projectId}/(project:objects)`,
            },
            {
                id: 4,
                title: 'admin.cardsLabels.createMunicipalityDesignsPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/manage-obj.png')",
                path: `${routingConstants.process}/${projectId}/(project:create-default-designs)`,
            },
            {
                id: 5,
                title: 'admin.cardsLabels.manageCitizenAndExpertsPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/manage-users.png')",
                path: `${routingConstants.process}/${projectId}/(project:manage-users)`,
            },
            {
                id: 6,
                title: 'admin.cardsLabels.designProposalsPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/design-prop.png')",
                path: `${routingConstants.process}/${projectId}/(project:project-designs)`,
            },
        ];
    }
}
