import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import { from, Observable, of } from 'rxjs';
import { concatMap, delay, map } from 'rxjs/operators';
import { HouseSceneConfig } from '../_models/house-config';
import { ThreeGridHelperBuilder } from '../_three-helpers/three-grid-helper-builder';
import { ThreeRoofBuilder } from './three-roof-builder';
import { House } from '../_models/house';
import { PermitDefaultValues } from '../_enum/permit-default-values.enum';

export class PermitActions {
    /** This is how Tween works, they expect object of type any  */
    public static makeAnimationTransition(
        oldPosition: any,
        newPosition: any,
        time: number,
        onStart?: any,
        onUpdate?: any,
        onComplete?: any
    ) {
        new TWEEN.Tween(oldPosition)
            .to(newPosition, time)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onUpdate(onUpdate)
            .onStart(onStart)
            .onComplete(onComplete)
            .start(TWEEN.now());
    }

    public static addGridHelper(instance: HouseSceneConfig) {
        const gridHelper = ThreeGridHelperBuilder.createGridHelper(
            instance.gridValues.size,
            instance.gridValues.divisions
        );
        gridHelper.position.set(instance.centroid.x, 0, instance.centroid.z);
        instance.groundGroup.add(gridHelper);
    }

    public static createHouseFlatRoof(
        house: House,
        position: number,
        scene: THREE.Scene
    ): THREE.Group {
        const roofBuilder = new ThreeRoofBuilder(
            house.roof,
            house.processedCoordinatesForThree
        );
        const roof = roofBuilder.createFlatRoof(position);
        roof.visible = false;
        scene.add(roof);
        return roof;
    }

    public static createHousePointyRoof(
        house: House,
        position: number,
        scene: THREE.Scene
    ): THREE.Group {
        const roofBuilder = new ThreeRoofBuilder(
            house.roof,
            house.processedCoordinatesForThree
        );
        const roof = roofBuilder.createPointyRoof(position, scene, house);
        roof.visible = false;
        scene.add(roof);
        return roof;
    }

    public static getChildrenFromGroup(
        groupChildren: THREE.Object3D[]
    ): Observable<THREE.Object3D> {
        const groupObservable$ = from(groupChildren);

        return groupObservable$.pipe(
            concatMap((groupStream) =>
                of(groupStream).pipe(
                    delay(PermitDefaultValues.animationTime),
                    map((object) => {
                        object.position.setY(
                            object.position.y +
                                PermitDefaultValues.animationHeight
                        );
                        return object;
                    })
                )
            )
        );
    }

    public static getChildrenFromGroupWithoutAnimation(
        groupChildren: THREE.Object3D[]
    ): any {
        groupChildren.map((group) => (group.visible = true));
        return groupChildren;
    }

    public static setupPositionAndMakeAnimation(division: THREE.Object3D) {
        division.visible = true;
        const newPosition = new THREE.Vector3(
            division.position.x,
            division.position.y,
            division.position.z
        );
        newPosition.setY(
            division.position.y - PermitDefaultValues.animationHeight
        );
        this.makeAnimationTransition(
            division.position,
            newPosition,
            PermitDefaultValues.animationTime
        );
    }
}
