<mat-form-field appearance="fill">
    <mat-label>{{ 'collaborativeDesign.emails' | translate }}</mat-label>
    <mat-chip-list #chipList>
        <mat-chip *ngFor="let email of emailList" (removed)="remove(email)">
            {{ email }}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
        <input
            placeholder="{{ 'collaborativeDesign.addEmailList' | translate }}"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
        />
        <mat-error *ngIf="noEmailError">
            {{ 'admin.auth.validEmail' | translate }}
        </mat-error>
    </mat-chip-list>
</mat-form-field>

<button
    class="primary-button"
    (click)="addUsers()"
    [class.disabled]="!this.emailList.length"
>
    <h6>{{ 'collaborativeDesign.invite' | translate }}</h6>
</button>
