import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '../_models/user';
import { UserProfile } from '../_models/user-profile';
import { Observable } from 'rxjs';
import { Router, RouterStateSnapshot } from '@angular/router';
import { FurbanUtil } from '../helpers/furbanUtil';
import { Tuple } from '../_models/tuple';
import { LoaderHttpParams } from '../_models/loader-http-param';
import { Client } from '../_models/client';
import { routingConstants } from '../_constants/routing-constants';
import { UserSettings } from '../_models/user-settings';
import { Environment } from '../_models/environment-model';
import { APP_CONFIG } from '@furban/app-config';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _codeUser: string;
    private _user: User;
    private _userProfile: UserProfile;
    private _redirectURL: string;
    private _redirectUserURL: string;
    private _previousUrl: string;
    private _client: Client;
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );
    private _options = {
        headers: new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
        ),
    };
    public isFirstTimeLogin?: boolean;

    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        private router: Router,
        private http: HttpClient
    ) { }

    public get codeUser(): string {
        return this._codeUser;
    }

    public set codeUser(value: string) {
        this._codeUser = value;
    }

    public get user(): User {
        return this._user;
    }

    public set user(value: User) {
        this._user = value;
    }

    public get userProfile(): UserProfile {
        return this._userProfile;
    }

    public set userProfile(value: UserProfile) {
        this._userProfile = value;
    }

    public get redirectURL(): string {
        return this._redirectURL;
    }

    public set redirectURL(value: string) {
        this._redirectURL = value;
    }

    public get redirectUserURL(): string {
        return this._redirectUserURL;
    }

    public set redirectUserURL(value: string) {
        this._redirectUserURL = value;
    }

    public get previousUrl(): string {
        return this._previousUrl;
    }

    public set previousUrl(value: string) {
        this._previousUrl = value;
    }

    public get client(): Client {
        return this._client;
    }

    public set client(value: Client) {
        this._client = value;
    }
    /**
     * loads a
     * @param oldCaptcha optional, the old captcha id
     */
    public getCaptcha(oldCaptcha?: string): Observable<Tuple> {
        return this.http.get(
            `${this.environment.apiPath}/captcha${oldCaptcha ? '/oldCaptcha' : ''
            }`
        ) as Observable<Tuple>;
    }

    public login(username: string, password: string, rememberMe: boolean, captchaId?: string, captchaAnswer?: string): Observable<any> {
        const REQUEST_DATA: any = {
            username: username,
            password: password,
            'remember-me': rememberMe ? '1' : '0',
        };
        if (captchaId && captchaAnswer) {
            REQUEST_DATA.captchaId = captchaId;
            REQUEST_DATA.captchaAnswer = captchaAnswer;
        }

        this.user = null;
        this.userProfile = null;
        return this.http
            .post(
                `${this.environment.apiPath}/login`,
                FurbanUtil.encodeObjectURIComponents(REQUEST_DATA),
                this._options
            )
            .pipe(map((user) => user));
    }

    public getClient(): Observable<Client> {
        return this.http
            .get(`${this.environment.apiPath}/client`, {
                headers: this._headers,
            })
            .pipe(
                map((result) => {
                    this._client = result as Client;
                    return this._client;
                })
            );
    }

    public getUserProfile(): Observable<UserProfile> {
        return this.http
            .get(`${this.environment.apiPath}/userprofile`, {
                headers: this._headers,
            })
            .pipe(
                map((result) => {
                    this._userProfile = result as UserProfile;
                    return result as UserProfile;
                })
            );
    }

    public getUser(): Observable<any> {
        return this.http.get(`${this.environment.apiPath}/user`, { headers: this._headers }).pipe(
            map(result => {
                this._user = result as User;
                return result;
            }));
    }

    public updateUserSettings(userSettings: UserSettings): Observable<any> {
        return this.http.post(`${this.environment.apiPath}/userprofile/settings`, userSettings, { headers: this._headers }).pipe(
            map(result => {
                this._userProfile.userSettings = result as UserSettings;
                return result;
            }));
    }
    public getUserByUserProfileId(userProfileId): Observable<any> {
        return this.http.get(`${this.environment.apiPath}/user/${userProfileId}`,
            {
                headers: this._headers,
                params: new LoaderHttpParams(true),
            })
            .pipe(map((result) => result));
    }

    // for admins only
    public removeUserByUserProfileId(userProfileId): Observable<any> {
        return this.http.post(`${this.environment.apiPath}/user/remove/${userProfileId}`, { headers: this._headers }).pipe(
            map(result => result));
    }

    // for citizens only
    public removeUser(): Observable<any> {
        return this.http.post(`${this.environment.apiPath}/user/remove/self/`, { headers: this._headers }).pipe(
            map(result => result));
    }

    public suspendUser(user: string): Observable<any> {
        return this.http
            .post(`${this.environment.apiPath}/user/suspend`, user, {
                headers: this._headers,
            })
            .pipe(map((result) => result));
    }

    public logout(): Observable<any> {
        return this.http.get(`${this.environment.apiPath}/logout`, { headers: this._headers }).pipe(
            map(result => {
                this.user = null;
                this.userProfile = null;
                this.client = null;
                return result;
            }));
    }

    public allInfoInPlace(authority, state): boolean {
        if (
            this.userProfile &&
            this.userProfile.userProfileId &&
            this.user &&
            this.user.userId &&
            this.client
        ) {
            return this.checkUser(authority, this.user, state);
        }

        return false;
    }

    public checkUser(authority: string, user: User, state: RouterStateSnapshot): boolean {
        if (user && !user.locked && !user.expired) {
            if (this.checkAuthority(authority)) {
                return true;
            }
        }

        return this.goBackToLogin(authority, state);
    }

    public goBackToLogin(authority: string, state: RouterStateSnapshot): boolean {
        if (authority === 'user') {
            this.redirectUserURL = state.url;
            this.router.navigate([routingConstants.app]);
        } else if (authority === 'admin') {
            if (state.url !== routingConstants.admin) {
                this.redirectURL = state.url;
            }
            this.router.navigate([routingConstants.admin]);
        } else if (authority === 'super-admin') {
            this.redirectURL = state.url;
            this.router.navigate([routingConstants.admin]);
        } else if (authority === 'pioneer') {
            if (state.url !== routingConstants.admin) {
                this.redirectURL = state.url;
            }
            this.router.navigate([routingConstants.admin]);
        }

        return false;
    }

    public redirectAdmin(): void {
        if (this.user && this.user.userId) {
            if (this.isSuperAdmin()) {
                this.redirectSuperAdmin();
                return;
            }
            if (
                this.redirectURL &&
                this.redirectURL !== routingConstants.admin
            ) {
                // login successful so redirect to return url
                this.router.navigateByUrl(this.redirectURL);
                this.redirectURL = routingConstants.admin;
            } else {
                this.router.navigateByUrl(routingConstants.adminDashboard);
            }
        }
    }

    public redirectUser(): void {
        if (this.user && this.user.userId) {
            if (
                this.redirectUserURL &&
                this.redirectUserURL !== routingConstants.app
            ) {
                // login successful so redirect to return url
                this.router.navigateByUrl(this.redirectUserURL);
                this.redirectUserURL = routingConstants.app;
            } else {
                const pathByRole = this.isCollaborator()
                    ? routingConstants.collaboratorDashboard
                    : this.isNeighbor()
                        ? routingConstants.neighborDashboard
                        : routingConstants.dashboard;
                this.router.navigateByUrl(pathByRole);
            }
        }
    }

    public redirectPioneer(): void {
        if (this.user && this.user.userId) {
            if (
                this.redirectURL &&
                this.redirectURL !== routingConstants.admin
            ) {
                // login successful so redirect to return url
                this.router.navigateByUrl(this.redirectURL);
                this.redirectURL = routingConstants.admin;
            } else {
                this.router.navigateByUrl(routingConstants.initiatives);
            }
        }
    }

    public redirectSuperAdmin(): void {
        if (this.user && this.user.userId) {
            if (
                this.redirectURL &&
                this.redirectURL !== routingConstants.admin
            ) {
                // login successful so redirect to return url
                this.router.navigateByUrl(this.redirectURL);
                this.redirectURL = routingConstants.admin;
            } else {
                this.router.navigateByUrl(routingConstants.adminNew);
            }
        }
    }

    public redirectToAppLandingPage(): void {
        this.router.navigate([routingConstants.landing]);
    }

    public redirectToInfoPage(): void {
        this.router.navigate([routingConstants.info]);
    }

    public redirectByRole(): void {
        if (this.isSuperAdmin()) {
            this.redirectSuperAdmin();
        } else if (this.isAdmin()) {
            this.redirectAdmin();
        } else if (this.isPioneer()) {
            this.redirectPioneer();
        } else {
            this.redirectUser();
        }
    }

    public logoutRedirect(): void {
        this.logout().subscribe((data) => {
            this.redirectUserToLogin();
            localStorage.clear();
        });
    }

    public redirectUserToLogin(): void {
        if (this.router.url.indexOf('partner') > -1) {
            this.router.navigateByUrl(routingConstants.admin);
        } else if (this.router.url.indexOf('app') > -1) {
            this.router.navigateByUrl(routingConstants.app);
        }
    }

    public isAdmin(): boolean {
        return this.user && this.user.role && this.user.role.roleWeight === 1;
    }

    public isSuperAdmin(): boolean {
        return this.user && this.user.role && this.user.role.roleWeight === 0;
    }

    public isCitizen(): boolean {
        return this.user && this.user.role && this.user.role.roleWeight === 3;
    }

    public isExpert(): boolean {
        return this.user && this.user.role && this.user.role.roleWeight === 2;
    }

    public isPioneer(): boolean {
        return this.user && this.user.role && this.user.role.roleWeight === 4;
    }

    public isCollaborator(): boolean {
        return this.user && this.user.role && this.user.role.roleWeight === 6;
    }

    public isNeighbor(): boolean {
        return this.user && this.user.role && this.user.role.roleWeight === 5;
    }

    public get isCitizenOrExpert(): boolean {
        return this.isCitizen() || this.isExpert();
    }

    public get isCitizenOrExpertOrCollaborator(): boolean {
        return this.isCitizen() || this.isCollaborator() || this.isExpert();
    }

    public isLoggedIn(): boolean {
        return this.user ? true : false;
    }

    public hasAdministrativeRole() {
        return (
            this.user &&
            this.user.role &&
            (this.user.role.roleWeight === 4 || this.user.role.roleWeight === 1)
        );
    }

    public getUserRoleAsString(): string {
        if (this.isSuperAdmin()) {
            return 'SuperAdmin';
        } else if (this.isAdmin()) {
            return 'Admin';
        } else if (this.isCitizen()) {
            return 'Citizen';
        } else if (this.isExpert()) {
            return 'Expert';
        } else if (this.isPioneer()) {
            return 'Pioneer';
        } else if (this.isCollaborator()) {
            return 'Collaborator';
        } else if (this.isNeighbor()) {
            return 'Neighbor';
        }
        return 'Other';
    }

    public hasPrivilegesOnRouteByUserAuthority(routeToCheck: string): boolean {
        if (
            (routeToCheck === 'super-admin' && !this.isSuperAdmin()) ||
            (routeToCheck === 'admin' && !this.isAdmin()) ||
            this.checkPrivilegesForUser(routeToCheck) ||
            (routeToCheck === 'pioneer' && !this.isPioneer())
        ) {
            return false;
        }

        return true;
    }

    public getUserSettings(): UserSettings {
        return this._userProfile.userSettings;
    }

    public isAdminDesignRouteOrNotAdministrativeRole(): boolean {
        return (
            !this.hasAdministrativeRole() ||
            this.router.url.indexOf('/(project:create-design/') > -1
        );
    }

    private checkPrivilegesForUser(routeToCheck: string): void {
        routeToCheck === 'user' &&
            !this.isCitizen() &&
            !this.isExpert() &&
            !this.isCollaborator();
    }

    private isAuthorityUser(authority: string): boolean {
        return (
            authority === 'user' &&
            (this.isCitizen() || this.isExpert() || this.isCollaborator())
        );
    }

    private isAuthorityAdmin(authority: string): boolean {
        return authority === 'admin' && this.isAdmin();
    }

    private isAuthoritySuperAdmin(authority: string): boolean {
        return authority === 'super-admin' && this.isSuperAdmin();
    }

    private isAuthorityPioneer(authority: string): boolean {
        return authority === 'pioneer' && this.isPioneer();
    }

    private checkAuthority(authority: string): boolean {
        return (
            this.isAuthorityUser(authority) ||
            this.isAuthorityAdmin(authority) ||
            this.isAuthorityPioneer(authority) ||
            this.isAuthoritySuperAdmin(authority)
        );
    }
}
