import {
    Directive,
    EventEmitter,
    Output,
    Input,
    OnInit,
    ElementRef,
    OnDestroy,
    Inject,
} from '@angular/core';
import { Observable, Subject, interval } from 'rxjs';
import { takeUntil, tap, filter } from 'rxjs/operators';
import { LongpressStatesEnum } from '../_enum/longpress-states.enum';
import { Menu3DEventsEnum } from '../_enum/menu-3D-events.enum';
import { DOCUMENT } from '@angular/common';
import { FurbanUtil } from '../helpers/furbanUtil';

@Directive({
    selector: '[appHoldable]',
})
export class HoldableDirective implements OnInit, OnDestroy {
    @Input() appHoldable: string;
    @Input() longpressTimer: number;
    @Input() isLoadingComputed?: boolean;
    @Input() elementLookId?: string;
    @Output() eventHandler: EventEmitter<string> = new EventEmitter();

    public state: Subject<string> = new Subject();
    public cancel: Observable<string>;
    public menuEventType: string = Menu3DEventsEnum.none;
    public time = 0;
    public percentage: number;
    public menuDiv: HTMLElement;
    public bindedMouseTouchEnd = this.mouseOrTouchEnd.bind(this);
    public bindedMouseTouchExit = this.mouseOrTouchExit.bind(this);
    public bindedMouseTouchStart = this.mouseOrTouchStart.bind(this);
    public bindedMouseMove = this.mouseOrTouchMove.bind(this);

    constructor(
        private el: ElementRef,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.cancel = this.state.pipe(
            filter((v) => v === LongpressStatesEnum.cancel),
            tap((v) => {
                this.eventHandler.emit(this.menuEventType);
            })
        );
        this.menuDiv = this.document.getElementById('menu-div');
    }

    ngOnInit() {
        if (FurbanUtil.isMobile()) {
            this.el.nativeElement.addEventListener(
                'touchend',
                this.bindedMouseTouchEnd,
                { passive: false }
            );
            this.el.nativeElement.addEventListener(
                'touchmove',
                this.bindedMouseTouchExit,
                { passive: false }
            );
            this.el.nativeElement.addEventListener(
                'touchstart',
                this.bindedMouseTouchStart,
                { passive: false }
            );
            this.el.nativeElement.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            });
        } else {
            this.el.nativeElement.addEventListener(
                'mouseup',
                this.bindedMouseTouchEnd,
                { passive: false }
            );
            this.el.nativeElement.addEventListener(
                'mousedown',
                this.bindedMouseTouchStart,
                { passive: false }
            );
        }
    }

    ngOnDestroy() {
        if (FurbanUtil.isMobile()) {
            this.el.nativeElement.removeEventListener(
                'touchend',
                this.bindedMouseTouchEnd
            );
            this.el.nativeElement.removeEventListener(
                'touchmove',
                this.bindedMouseTouchExit
            );
            this.el.nativeElement.removeEventListener(
                'touchstart',
                this.bindedMouseTouchStart
            );
            this.el.nativeElement.removeEventListener('contextmenu', (e) => {
                e.preventDefault();
            });
        } else {
            this.el.nativeElement.removeEventListener(
                'mouseup',
                this.bindedMouseTouchEnd
            );
            this.el.nativeElement.removeEventListener(
                'mousedown',
                this.bindedMouseTouchStart
            );
        }
    }

    public mouseOrTouchEnd() {
        this.menuEventType = Menu3DEventsEnum.click;
        this.state.next(LongpressStatesEnum.cancel);
        this.el.nativeElement.removeEventListener(
            'mouseleave',
            this.bindedMouseTouchExit
        );
    }

    public mouseOrTouchExit() {
        this.menuEventType = Menu3DEventsEnum.none;
        this.state.next(LongpressStatesEnum.cancel);
    }

    mouseOrTouchMove(event) {
        if (event.srcElement?.id.toString() !== this.elementLookId.toString()) {
            this.mouseOrTouchExit();
        }
    }

    public mouseOrTouchStart() {
        this.state.next(LongpressStatesEnum.start);
        this.addMouseMoveEvent(this.menuDiv);
        this.el.nativeElement.addEventListener(
            'mouseleave',
            this.bindedMouseTouchExit,
            { passive: false }
        );
        const n = 100;
        interval(n)
            .pipe(
                takeUntil(this.cancel),
                tap((v) => {
                    this.time = v * n;
                    if (this.time >= this.longpressTimer) {
                        this.menuEventType = Menu3DEventsEnum.longPress;
                        this.state.next(LongpressStatesEnum.cancel);
                        this.el.nativeElement.removeEventListener(
                            'mouseleave',
                            this.bindedMouseTouchExit
                        );
                        this.removeMouseMoveEvent(this.menuDiv);
                        return;
                    }
                    if (this.isLoadingComputed) {
                        this.percentage =
                            (this.time * 100) / this.longpressTimer;
                        this.eventHandler.emit(this.percentage.toString());
                    }
                })
            )
            .subscribe();
    }

    public addMouseMoveEvent(element: HTMLElement) {
        if (
            this.elementLookId !== null &&
            FurbanUtil.checkIfIsFirefoxBrowser()
        ) {
            element.addEventListener('mousemove', this.bindedMouseMove, {
                passive: false,
            });
        }
    }

    public removeMouseMoveEvent(element: HTMLElement) {
        if (
            this.elementLookId !== null &&
            FurbanUtil.checkIfIsFirefoxBrowser()
        ) {
            element.removeEventListener('mousemove', this.bindedMouseMove);
        }
    }
}
