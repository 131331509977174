import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Client } from '../_models/client';
import { APP_CONFIG } from '@furban/app-config';
import { Environment } from '../_models/environment-model';

@Injectable()
export class MunicipalityService {
    private _options = {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        private http: HttpClient
    ) { }

    getAllMunicipalities() {
        return this.http
            .get(this.environment.apiPath + '/client/all')
            .pipe(map((result) => result as Client[]));
    }
    /**
     * only super admin will get an answers
     */
    getAllClients() {
        return this.http
            .get(this.environment.apiPath + '/client/full')
            .pipe(map((result) => result as Client[]));
    }

    addNewMunicipality(
        name: string,
        address: string,
        phonenum: string
    ): Observable<Client> {
        const REQUEST_DATA = {
            clientName: name,
            address: address,
            phone: phonenum,
        };
        return this.http
            .put(
                `${this.environment.apiPath}/client`,
                REQUEST_DATA,
                this._options
            )
            .pipe(map((user) => <Client>user));
    }
}
