<div
    class="important-space-between"
    mat-dialog-title
    [style.margin-bottom.px]="24"
>
    <h2>{{ 'user.projectMenu.generateObjectsInLine' | translate }}</h2>
    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons">close</span>
    </button>
</div>

<mat-form-field appearance="outline" class="grey">
    <mat-label>{{ 'user.projectMenu.selectCategory' | translate }}</mat-label>
    <mat-select
        disableOptionCentering
        placeholder="{{ 'admin.map.defaultMaterialPlaceholder' | translate }}"
        (selectionChange)="onSelectCategoryChange($event)"
        [(value)]="selectedCategory"
    >
        <mat-option
            *ngFor="let categoryObject of categoryObjects"
            [value]="categoryObject"
        >
            {{ categoryObject.category.categoryName | translate }}
        </mat-option>
    </mat-select>
</mat-form-field>

<div class="objects-container">
    <div
        *ngFor="let objectModel of selectedCategory?.models"
        (click)="changeSelectedObject(objectModel)"
        class="object-div"
        [class.selected]="isSelectedObject(objectModel)"
    >
        <ng-container [ngSwitch]="projectType">
            <ng-container *ngSwitchCase="projectTypeEnum.adminProject">
                <img
                    alt="objectPicture"
                    [src]="
                        composeImageString(
                            objectModel.furban3DModel.objectLookId
                        )
                    "
                />
            </ng-container>

            <ng-container *ngSwitchCase="projectTypeEnum.pioneerPermitProject">
                <img alt="objectPicture" [src]="objectModel.imagePath" />
            </ng-container>
        </ng-container>
    </div>
</div>

<div class="buttons-right-container" [style.margin-top.px]="24">
    <button class="secondary-button" (click)="closeDialog()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="saveObject()">
        <h6>{{ 'user.projectMenu.addRow' | translate }}</h6>
    </button>
</div>
