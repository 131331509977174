<ng-container *ngIf="permitReview">
    <div class="permit-card">
        <div class="gradient" (click)="openDetails()"></div>
        <div
            class="card-img background-center"
            [style.background-image]="
                'url(' + getMedia(permitReview.project.media) + ')'
            "
        >
            <label [style.color]="labelColorByStatus">
                {{
                    'pioneer.permitInvolvement.' +
                        municipalityStatusTranslationLabel | translate
                }}
            </label>

            <div class="flex-row-spaced bottom-content">
                <section class="avatar flex-row-start card-type">
                    <furban-avatar-picture
                        [name]="
                            permitReview.project.createdBy.userProfile
                                .screenName
                        "
                        [size]="28"
                        [mediaId]="
                            getUserMedia(
                                permitReview.project.createdBy.userProfile
                                    .mediaId
                            )
                        "
                    >
                    </furban-avatar-picture>

                    <div class="flex-col-center">
                        <h6 class="name-label">
                            {{
                                permitReview.project.createdBy.userProfile
                                    .screenName
                            }}
                        </h6>
                    </div>
                </section>
                <div class="flex-row-start">
                    <div class="comments" (click)="openCommentDialog()">
                        <span class="material-icons">comment</span>
                        <h6>{{ permitReview.project.commentsCount }}</h6>
                    </div>
                    <span
                        class="material-icons padding-0-12"
                        [matMenuTriggerFor]="optionsMenu"
                    >
                        more_vert
                    </span>
                    <mat-menu #optionsMenu="matMenu" overlapTrigger="false">
                        <button mat-menu-item (click)="openDetails()">
                            {{ 'winner.openDetails' | translate }}
                        </button>

                        <button mat-menu-item (click)="reviewPermit()">
                            {{
                                'admin.permitRequests.reviewPermit' | translate
                            }}
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</ng-container>
