<mat-form-field
    class="language-drop-down"
    *ngIf="multilanguageService.translations && !hideButtons"
>
    <mat-select
        [(value)]="multilanguageService.usedLang"
        [ngClass]="multilanguageService.usedLang"
        #customLanguageSelect
    >
        <mat-option (click)="onSelectChange()" value="nl-NL">
            <img
                with="20"
                height="20"
                src="../../../assets/icons/flag-nl.svg"
            />
        </mat-option>
        <mat-option (click)="onSelectChange()" value="en-US">
            <img
                with="20"
                height="20"
                src="../../../assets/icons/flag-gb.svg"
            />
        </mat-option>
        <mat-option (click)="onSelectChange()" value="ro-RO">
            <img
                with="20"
                height="20"
                src="../../../assets/icons/flag-ro.svg"
            />
        </mat-option>
    </mat-select>
</mat-form-field>
