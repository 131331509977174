<ul class="options">
    <li *ngFor="let option of options">
        <div class="break">
            {{ option.option }}
        </div>

        <div class="container-progress-bar">
            <mat-progress-bar mode="determinate" [value]="calculatePercentage(option)">
            </mat-progress-bar>
            <h6>{{ calculatePercentage(option) }}%</h6>
        </div>
    </li>
</ul>