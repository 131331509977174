<ng-container *ngIf="house">
    <div *ngIf="isMaterialLoaded" id="three-cont" class="three-container">
        <furban-ngrx-permit-editor
            #permitThreeJs
            [shouldDisableActions]="isViewMode()"
            [parentView]="viewContainerRef"
            (discardSelectionFromMenu)="onDiscardSelectionFromMenu()"
        >
        </furban-ngrx-permit-editor>
    </div>

    <ng-container *ngIf="!isViewMode()">
        <furban-obj-categories-menu
            #permitMenu
            class="menu"
            [objects]="objectsCategory">
            <furban-house-drawing-form
                #permitForm
                [house]="house"
                (changedHouse)="onHouseFormChanged($event)">
            </furban-house-drawing-form>
        </furban-obj-categories-menu>
    </ng-container>
</ng-container>
