import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APP_CONFIG } from '@furban/app-config';
import { PermitSparqlQuery } from '../_constants/permit-sparql-query';
import { ProjectService } from './project.service';
import { StringifiedPath } from '../_models/stringify-paths';
import { FurbanUtil } from '../helpers/furbanUtil';
import { Environment } from '../_models/environment-model';

@Injectable({
    providedIn: 'root',
})
export class MapService {
    private httpClient: HttpClient;
    private _headers = new HttpHeaders()
        .set('Content-Type', 'application/json');

    private _headersNoCache = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .append(
            'Cache-Control',
            'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
        )
        .append('Pragma', 'no-cache')
        .append('Vary', '*')
        .append('Expires', '0');
    private _headersUrlEncoded = new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .append('Accept', 'application/sparql-results+json');

    // REFACTOR -  projectService shoul not be here
    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        handler: HttpBackend,
        private projectService: ProjectService
    ) {
        this.httpClient = new HttpClient(handler);
    }

    public getPolygon(projectId) {
        return this.httpClient
            .get(`${this.environment.apiPath}/project/path/${projectId}`, {
                headers: this._headersNoCache,
            })
            .pipe(
                map((result) => {
                    this.projectService.assignPathValues(
                        result as StringifiedPath
                    );
                    return result;
                })
            );
    }

    // We revemoved the content-type / application/json to skip preflight request
    public getSearchAdresses(adress: string) {
        return this.httpClient
            .get(
                `${this.environment.geoApi}/suggest?q=${adress}`
            )
            .pipe(map((result) => result as any));
    }

    public getSearchAdressesForOpenMap(address: string) {
        return this.httpClient.get(`${this.environment.geoApiOpenMap}?format=json&q=${encodeURIComponent(address)}`)
            .pipe(map((result) => result as any));
    }

    // We revemoved the content-type / application/json to skip preflight request
    public getAdressCoordinates(adress: string) {
        return this.httpClient
            .get(
                `${this.environment.geoApi}/lookup?id=${adress}`
            )
            .pipe(map((result) => result as any));
    }

    public getPolygonByBuildingId(buildingId: string) {
        const reqObj = PermitSparqlQuery.createQuery(buildingId);
        return this.httpClient.post(
            'https://api.labs.kadaster.nl/datasets/kadaster/bag2/services/default/sparql',
            FurbanUtil.encodeObjectURIComponents(reqObj),
            { headers: this._headersUrlEncoded }
        );
    }
}
