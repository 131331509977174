<div class="flex-row-spaced" [style.margin-bottom.px]="20">
    <h2>{{ 'pioneer.permitProcess.createHouse' | translate }}</h2>
</div>

<form [formGroup]="permitBasicStepsForm">
    <div class="flex-row-start number-inputs" [style.padding-bottom.px]="20">
        <mat-form-field appearance="outline">
            <mat-label>
                {{ 'pioneer.permitProcess.numberOfFloors' | translate }}
            </mat-label>
            <input type="number" formControlName="numberOfFloors" matInput placeholder="{{
                    'pioneer.permitProcess.numberOfFloors' | translate
                }}" />
            <mat-error *ngIf="hasControlRequiredError('numberOfFloors',permitBasicStepsForm)">
                {{ 'pioneer.permitProcess.requiredFloorsError' | translate }}
            </mat-error>

            <mat-error *ngIf="hasControlMaxError('numberOfFloors',permitBasicStepsForm)">
                {{ 'pioneer.permitProcess.maxFloorsError' | translate }}
            </mat-error>

            <mat-error *ngIf="hasControlMinError('numberOfFloors',permitBasicStepsForm)">
                {{ 'pioneer.permitProcess.minFloorsError' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>
                {{ 'pioneer.permitProcess.floorHeight' | translate }}
            </mat-label>
            <input type="number" formControlName="floorHeight" matInput placeholder="{{
                    'pioneer.permitProcess.floorHeight' | translate
                }}" />
            <mat-error *ngIf="hasControlRequiredError('floorHeight',permitBasicStepsForm)">
                {{
                'pioneer.permitProcess.requiredFloorHeightError' | translate
                }}
            </mat-error>
            <mat-error *ngIf="hasControlMaxError('floorHeight',permitBasicStepsForm)">
                {{ 'pioneer.permitProcess.maxFloorHeightError' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlMinError('floorHeight',permitBasicStepsForm)">
                {{ 'pioneer.permitProcess.minFloorHeightError' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="flex-row-start" [style.padding-bottom.px]="20">
        <mat-form-field *ngIf="checkIfHouseMaterials()" appearance="outline">
            <mat-label>{{ 'pioneer.permitProcess.houseTexture' | translate }}
            </mat-label>
            <div class="color-container">
                <mat-select disableOptionCentering class="select-container" panelClass="texture-select-dropdown"
                    [style.background]="getUrl(selectedMaterial)" [compareWith]="objectComparisonFunction"
                    (selectionChange)="houseMaterialUpdated()" [(value)]="selectedMaterial">
                    <mat-option class="texture-select" [value]="houseMaterial"
                        *ngFor="let houseMaterial of houseMaterials">
                        <img alt="objectPicture" [src]="houseMaterial.materialIcon" />
                        <p>{{ houseMaterial.materialName | translate }}</p>
                    </mat-option>
                </mat-select>
            </div>
        </mat-form-field>

        <mat-form-field class="flex-row-start" [style.margin-left.px]="12" appearance="outline">
            <mat-label>{{ 'pioneer.permitProcess.houseColor' | translate }}
            </mat-label>
            <div class="color-container">
                <input type="color" formControlName="houseColorControl" matInput />
            </div>
        </mat-form-field>
    </div>

    <div class="flex-row-start full-width" [style.padding-bottom.px]="20">
        <mat-form-field *ngIf="checkIfRoofMaterials()" appearance="outline">
            <mat-label>{{ 'pioneer.permitProcess.roofTexture' | translate }}
            </mat-label>
            <div class="color-container">
                <mat-select disableOptionCentering class="select-container" panelClass="texture-select-dropdown"
                    [style.background]="getUrl(selectedRoofMaterial)" [compareWith]="objectComparisonFunction"
                    (selectionChange)="roofMaterialUpdated()" [(value)]="selectedRoofMaterial">
                    <mat-option class="texture-select" [value]="roofMaterial"
                        *ngFor="let roofMaterial of roofMaterials">
                        <img alt="objectPicture" [src]="roofMaterial.materialIcon" />
                        <p>{{ roofMaterial.materialName | translate }}</p>
                    </mat-option>
                </mat-select>
            </div>
        </mat-form-field>

        <mat-form-field class="flex-row-start" [style.margin-left.px]="12" appearance="outline">
            <mat-label>{{ 'pioneer.permitProcess.roofColor' | translate }}
            </mat-label>
            <div class="color-container">
                <input type="color" formControlName="roofColorControl" matInput />
            </div>
        </mat-form-field>
    </div>
</form>