<div [ngClass]="checkHeaderType()">
    <ng-container *ngIf="showStepperAndTooling()">
        <ng-container *ngIf="showMapTooling()">
            <furban-tooling>
                <ng-container buttons-type="main">
                    <button class="round-button" matTooltip="{{'buttonsTooltips.drawArea' | translate}}"
                        [class.active]="isDrawingEnabled()" (click)="callToolingAction(toolingActions.drawPolygon)">
                        <span class="material-icons">create</span>
                    </button>
                    <button class="round-button" matTooltip="{{'buttonsTooltips.resetMapDrawing' | translate}}"
                        (click)="callToolingAction(toolingActions.resetMap)">
                        <span class="material-icons">replay</span>
                    </button>

                    <button *ngIf="showButtonForGroundMaterial() else selectMaterial" mat-icon-button
                        class="round-button" (click)=select.open()>
                        <span class="material-icons">tonality</span>

                        <mat-select #select class="ground-select" (selectionChange)="onMaterialChange()"
                            [(value)]="toolingService.defaultMaterial">
                            <mat-option *ngFor="let material of materials"
                                [value]="material.furban3DModel.objectLookId">
                                {{material.furban3DModel.translationsLabel | translate}}
                            </mat-option>
                        </mat-select>
                    </button>

                    <ng-template #selectMaterial>
                        <mat-form-field class="material-selection no-padding grey" floatLabel="never"
                            appearance="outline">
                            <mat-label>
                                {{'admin.map.defaultMaterialPlaceholder' | translate}}"
                            </mat-label>
                            <mat-select disableOptionCentering
                                placeholder="{{'admin.map.defaultMaterialPlaceholder'  | translate}}"
                                (selectionChange)="onMaterialChange()" [(value)]="toolingService.defaultMaterial">
                                <mat-option *ngFor="let material of materials"
                                    [value]="material.furban3DModel.objectLookId">
                                    {{material.furban3DModel.translationsLabel | translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-template>
                </ng-container>
            </furban-tooling>
        </ng-container>

        <ng-container *ngIf="showTooling() && toolingVisibility.editMode">
            <furban-tooling>
                <ng-container buttons-type="main">
                    <ng-container *ngIf="shouldDisplayBackButton()">
                        <button class="tooling-button" matTooltip="{{ 'generic.back' | translate }}"
                            (click)="onButtonClick(toolingActions.navigateBack)">
                            <span class="material-icons">arrow_back</span>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="shouldShowIcons()">
                        <button class="tooling-button" [class.disabled]="disableBtnDefaultDesignIfPublished"
                            matTooltip="{{ 'buttonsTooltips.addObjects' | translate }}"
                            (click)="onButtonClick(toolingActions.addObjects)">
                            <mat-icon svgIcon="add-objects-icon"
                                [class.active]="checkActiveButton(toolingActions.addObjects)"></mat-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="showTooling()">
                        <button class="tooling-button" [class.disabled]="disableBtnDefaultDesignIfPublished"
                            matTooltip="{{ 'buttonsTooltips.generateObjects' | translate }}"
                            [class.active]="checkActiveButton(toolingActions.generateObjects)"
                            (click)="onButtonClick(toolingActions.generateObjects)">
                            <span class="material-icons">category</span>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="shouldShowIcons()">
                        <button class="tooling-button" [class.disabled]="disableBtnDefaultDesignIfPublished"
                            matTooltip="{{ 'buttonsTooltips.objectsInLine' | translate }}"
                            [class.active]="checkActiveButton(toolingActions.objectsInLine)"
                            (click)="onButtonClick(toolingActions.objectsInLine)">
                            <span class="material-icons-outlined">auto_graph</span>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="checkInitiativeRoute()">
                        <button class="tooling-button" [class.disabled]="disableBtnDefaultDesignIfPublished"
                            matTooltip="{{ 'buttonsTooltips.upload' | translate }}"
                            [class.active]="checkActiveButton(toolingActions.upload)"
                            (click)="onButtonClick(toolingActions.upload)">
                            <span class="material-icons">cloud_upload</span>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="shouldShowIcons()">
                        <button class="tooling-button" [class.disabled]="disableBtnDefaultDesignIfPublished"
                            matTooltip="{{ 'buttonsTooltips.multiselect' | translate }}"
                            [class.active]="checkActiveButton(toolingActions.multiselect)"
                            (click)="onButtonClick(toolingActions.multiselect)">
                            <span class="material-icons">select_all</span>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="showTooling()">
                        <button class="tooling-button" [class.disabled]="disableBtnDefaultDesignIfPublished "
                            matTooltip="{{ 'buttonsTooltips.freeshape' | translate }}"
                            [class.active]="checkActiveButton(toolingActions.freeshape)"
                            (click)="onButtonClick(toolingActions.freeshape)">
                            <span class="material-icons">gesture</span>
                        </button>
                    </ng-container>
                </ng-container>
            </furban-tooling>
        </ng-container>


        <div class="custom-project-header-stepper">
            <furban-stepper [homeRoute]="homeRoute" [parentContainerRef]="containerRef">
            </furban-stepper>
        </div>
        <ng-container *ngIf="showTooling() && toolingVisibility.editMode">
            <furban-tooling>
                <ng-container buttons-type="main">

                    <ng-container *ngIf="!shouldDisplayContextMenu()">
                        <ng-container *ngIf="toolingVisibility.editMode &&
                                       toolingVisibility.state !== threeStateEnum.screenshot">
                            <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.safetyArea' | translate }}"
                                (click)="callToolingAction(toolingActions.switchSafetyArea)">
                                <span class="material-icons">
                                    {{toolingVisibility.isSafetyEnabled? 'leak_remove': 'leak_add'}}
                                </span>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="toolingVisibility.editMode && !isIOS">
                            <button class="tooling-button"
                                matTooltip="{{ 'buttonsTooltips.changeGridView' | translate }}"
                                (click)="callToolingAction(toolingActions.toggleGridView)">
                                <span class="material-icons">grid_on</span>
                            </button>
                        </ng-container>

                        <ng-container *ngIf="!isApp()">
                            <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.exportDesign' | translate }}"
                                (click)="callToolingAction(toolingActions.exportSceneToGLTF)">
                                <span class="material-icons">cloud_download</span>
                            </button>
                        </ng-container>


                        <button class="tooling-button"
                            matTooltip="{{ 'buttonsTooltips.toggleTransparency' | translate }}"
                            (click)="callToolingAction(toolingActions.toggleTransparency)">
                            <span class="material-icons">{{toolingVisibility.isTransparencyEnabled ? 'layers_clear':
                                'layers'}}
                            </span>
                        </button>
                        <ng-container *ngIf="checkIfShouldDisplayScreenshot()">
                            <button class="tooling-button"
                                matTooltip="{{ 'buttonsTooltips.takeScreenshot' | translate }}"
                                (click)="callToolingAction(toolingActions.takeDefaultDesignScreenshot)">
                                <span class="material-icons">add_a_photo</span>
                            </button>
                        </ng-container>
                        <button class="tooling-button"
                            matTooltip="{{ 'buttonsTooltips.change3Dperspective' | translate }}"
                            (click)="callToolingAction(toolingActions.changeCameraPerspective)">
                            <furban-svg-container [svgImage]='svgImageType.changePerspective'>
                            </furban-svg-container>

                        </button>
                        <ng-container *ngIf="!shouldDisplayViewFromTop()">
                            <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.viewFromTop' | translate }}"
                                (click)="callToolingAction(toolingActions.viewFromTheTop)">
                                <span class="material-icons">
                                    vertical_align_top
                                </span>
                            </button>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!hideUndoRedo">
                        <button class="tooling-button" matTooltip="{{'buttonsTooltips.undo' | translate}}"
                            [class.disabled]="!isUndoEnabled" (click)="callToolingAction(toolingActions.undo)">
                            <span class="material-icons">undo</span>
                        </button>

                        <button class="tooling-button" matTooltip="{{'buttonsTooltips.redo' | translate}}"
                            [class.disabled]="!isRedoEnabled" (click)="callToolingAction(toolingActions.redo)">
                            <span class="material-icons">redo</span>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="shouldDisplayContextMenu()">

                        <span class="material-icons pointer" [matMenuTriggerFor]="menu"
                            #clickMenuTrigger="matMenuTrigger">more_vert</span>
                        <mat-menu #menu="matMenu">
                            <ng-container *ngIf="toolingVisibility.editMode &&
                                           toolingVisibility.state !== threeStateEnum.screenshot">
                                <button mat-menu-item (click)="callToolingAction(toolingActions.switchSafetyArea)">
                                    <span class="material-icons">
                                        {{toolingVisibility.isSafetyEnabled? 'leak_remove': 'leak_add'}}
                                    </span>
                                    <h6>{{ 'buttonsTooltips.safetyArea' | translate }}</h6>

                                </button>
                            </ng-container>

                            <ng-container *ngIf="toolingVisibility.editMode && !isIOS">
                                <button mat-menu-item (click)="callToolingAction(toolingActions.toggleGridView)">
                                    <span class="material-icons">grid_on</span>
                                    <h6>{{ 'buttonsTooltips.changeGridView' | translate }}</h6>

                                </button>
                            </ng-container>

                            <ng-container *ngIf="!isApp()">
                                <button mat-menu-item (click)="callToolingAction(toolingActions.exportSceneToGLTF)">
                                    <span class="material-icons">cloud_download</span>
                                    <h6>{{ 'buttonsTooltips.exportDesign' | translate }}</h6>
                                </button>
                            </ng-container>
                            <button mat-menu-item (click)="callToolingAction(toolingActions.toggleTransparency)">
                                <span class="material-icons">{{toolingVisibility.isTransparencyEnabled ? 'layers_clear':
                                    'layers'}}
                                </span>
                                <h6>{{ 'buttonsTooltips.toggleTransparency' | translate }}</h6>
                            </button>

                            <ng-container mat-menu-item *ngIf="checkIfShouldDisplayScreenshot()">
                                <button (click)="callToolingAction(toolingActions.takeDefaultDesignScreenshot)">
                                    <span class="material-icons">add_a_photo</span>
                                    <h6>{{ 'buttonsTooltips.takeScreenshot' | translate }}</h6>
                                </button>
                            </ng-container>

                            <button mat-menu-item (click)="callToolingAction(toolingActions.changeCameraPerspective)">
                                <furban-svg-container [svgImage]='svgImageType.changePerspective'>
                                </furban-svg-container>
                                <h6 [style.margin-left.px]="12">{{ 'buttonsTooltips.change3Dperspective' | translate }}
                                </h6>
                            </button>

                            <button mat-menu-item (click)="callToolingAction(toolingActions.viewFromTheTop)">
                                <span class="material-icons">
                                    vertical_align_top
                                </span>
                                <h6>{{ 'buttonsTooltips.viewFromTop' | translate }}
                                </h6>
                            </button>
                        </mat-menu>

                    </ng-container>
                </ng-container>
            </furban-tooling>
        </ng-container>

        <ng-container *ngIf="showMapTooling()">
            <furban-tooling>
                <ng-container buttons-type="main">
                    <button class="primary-button" (click)="callToolingAction(toolingActions.savePolygon)">
                        <h6>{{'generic.save' | translate}}</h6>
                    </button>
                </ng-container>
            </furban-tooling>
        </ng-container>

        <ng-container *ngIf="showDetailsTooling()">
            <furban-tooling>
                <ng-container buttons-type="main">
                    <button class="primary-button custom" matTooltip="{{ 'buttonsTooltips.save' | translate }}"
                        [class.inactive]="stepperService?.project?.hasWinner" [style.margin-top.px]="4"
                        (click)="callToolingAction(toolingActions.saveProjectDetails)">
                        <h6>{{ "generic.save" | translate }}</h6>
                    </button>
                </ng-container>
            </furban-tooling>
        </ng-container>

        <ng-container *ngIf="showObjectsTooling()">
            <furban-tooling>
                <ng-container buttons-type="main">
                    <button class="primary-button custom"
                        matTooltip="{{'admin.setupDefaultObjects.applyChangesButtonText' | translate}}"
                        [class.disabled]="!stepperService.isModified"
                        (click)="callToolingAction(toolingActions.saveObjects)">
                        <h6>{{ "generic.save" | translate }}</h6>
                    </button>
                </ng-container>
            </furban-tooling>
        </ng-container>

        <ng-container *ngIf="showGroupTooling()">
            <furban-tooling>
                <ng-container buttons-type="main">
                    <button class="secondary-button custom" [style.margin-right.px]="12" [style.margin-top.px]="2"
                        (click)="callToolingAction(toolingActions.createGroup)">
                        <h6>{{ "admin.groups.create" | translate }}</h6>
                    </button>

                    <button class="primary-button custom" [style.margin-top.px]="2"
                        (click)="callToolingAction(toolingActions.selectGroup)">
                        <h6>{{ "admin.groups.addExisting" | translate }}</h6>
                    </button>
                </ng-container>
            </furban-tooling>
        </ng-container>

        <ng-container *ngIf="showInitiativeTooling()">
            <furban-tooling>
                <ng-container buttons-type="main">
                    <button class="secondary-button custom" [style.margin-right.px]="12" [style.margin-top.px]="2"
                        (click)="callToolingAction(toolingActions.generateGroup)">
                        <h6>{{ "admin.groups.generate" | translate }}</h6>
                    </button>
                </ng-container>
            </furban-tooling>
        </ng-container>
    </ng-container>
</div>
<ng-container *ngIf="isProjectLoaded()">
    <router-outlet name="project"></router-outlet>
</ng-container>