<div class="login {{ stylingClass }}">
    <section class="login-panel">
        <div class="logo-container">
            <img src="../../../assets/images/furban_logo.png" [style.width.px]="300" [style.margin-bottom.px]="32" />
        </div>
        <ng-content></ng-content>
    </section>
    <a class="hidden-link" (click)="navigateToWebsite()">{{
        'auth.buttonText' | translate
        }}</a>

    <ng-container *ngIf="stylingClass === 'admin'">
        <div class="register-text">
            <p>{{ 'auth.registerMessage' | translate }}
                <a (click)="goToAdminRegister()">{{'auth.registerButton' | translate}}</a>
            </p>
        </div>
    </ng-container>
</div>
<furban-app-multilanguage [hideButtons]="true"></furban-app-multilanguage>