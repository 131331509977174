import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FurbanInfoComponent } from './furban-info/furban-info.component';
import { DesignProposalDetailsComponent } from './shared/design-proposal-details/design-proposal-details.component';
import { PublicEntryPageComponent } from './public-entry-page/public-entry-page.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';

import { NotLoggedInGuard, AppLoginGuard, routingConstants } from '@furban/utilities';
import { ProjectsOnAreaComponent } from './projects-on-area/projects-on-area.component';
import { PublicProjectDetailsComponent } from './public-project-details/public-project-details.component';
import { FurbanCityComponent } from './furban-city/furban-city.component';
import { PublicEmbededDesignComponent } from './public-embeded-design/public-embeded-design.component';
import { PublicEmbededProjectComponent } from './public-embeded-project/public-embeded-project.component';
import { PermitHouseParallelComponent } from './permit/permit-house-parallel/permit-house-parallel.component';
import { FurbanAppLandingComponent } from './furban-app-landing/furban-app-landing.component';

const appRoutes: Routes = [
    {
        path: '',
        component: FurbanInfoComponent,
        canActivate: [NotLoggedInGuard],
    },

    {
        path: routingConstants.landing,
        component: FurbanAppLandingComponent,
        canActivate: [AppLoginGuard],
    },
    {
        path: routingConstants.publicFurbanCity,
        component: FurbanCityComponent,
        canActivate: [],
    },
    {
        path: routingConstants.takingPart,
        component: ProjectsOnAreaComponent,
        canActivate: [],
    },
    {
        path: routingConstants.disclaimer,
        component: DisclaimerComponent,
        canActivate: [],
    },
    {
        path: `${routingConstants.publicEntryPage}/:id`,
        component: PublicEntryPageComponent,
        canActivate: [],
    },
    {
        path: `${routingConstants.takingPart}/:id`,
        component: PublicProjectDetailsComponent,
        canActivate: [],
    },
    {
        path: `${routingConstants.takingPartDesign}/:id`,
        component: DesignProposalDetailsComponent,
        canActivate: [],
    },
    {
        path: `${routingConstants.publicProject}/:id`,
        component: PublicProjectDetailsComponent,
        canActivate: [],
        data: { isViewerOnly: true },
    },
    {
        path: `${routingConstants.publicPermitDetails}/:id`,
        component: PermitHouseParallelComponent,
        canActivate: [],
        data: { isViewerOnly: true },
    },
    {
        path: `${routingConstants.publicDesignProposal}/:id`,
        component: DesignProposalDetailsComponent,
        canActivate: [],
        data: { isViewerOnly: true },
    },
    {
        path: `${routingConstants.publicPageDesign}/:id`,
        component: PublicEmbededDesignComponent,
        canActivate: [],
        data: { isViewerOnly: true },
    },
    {
        path: `${routingConstants.publicPageProject}/:id`,
        component: PublicEmbededProjectComponent,
        canActivate: [],
        data: { isViewerOnly: true },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            useHash: true,
            anchorScrolling: 'enabled',
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
