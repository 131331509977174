<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'admin.selectProjectType.question' | translate }}</h2>
    <button class="ghost-button" (click)="closePopup()">
        <span class="material-icons">close</span>
    </button>
</div>

<mat-dialog-content class="flex-row-center">
    <div
        class="card default card-container furban-card hoverable"
        (click)="setProjectType(projectInvolvementType.admin)"
    >
        <section>
            <h4 [style.margin-bottom.px]="12">
                {{ 'admin.selectProjectType.admin.title' | translate }}
            </h4>
            <p>
                {{ 'admin.selectProjectType.admin.description' | translate }}
            </p>
        </section>
        <img src="../../../assets/images/default_designs.svg" />
    </div>
    <div
        class="card citizens card-container furban-card hoverable"
        (click)="setProjectType(projectInvolvementType.citizen)"
    >
        <section>
            <h4 [style.margin-bottom.px]="12">
                {{ 'admin.selectProjectType.citizen.title' | translate }}
            </h4>
            <p>
                {{ 'admin.selectProjectType.citizen.description' | translate }}
            </p>
        </section>
        <img src="../../../assets/images/citizens_designs.svg" />
    </div>
</mat-dialog-content>
