<div class="container-btn-back">
    <mat-icon (click)="goBack()">arrow_back_ios</mat-icon>
    <h3 class="page-title">
        {{ 'user.statistics.statisticsOverview' | translate }}
    </h3>
</div>
<ng-container *ngIf="curentProject; else NotCurrentProject">
    <ng-container *ngIf="displayCategoryStatistics()">
        <furban-categories-statistics
            [objectsPerCategoryCount]="objectsPerCategoryCount"
            [objectsTotalCount]="objectsTotalCount"
        >
        </furban-categories-statistics>
    </ng-container>

    <furban-user-charts> </furban-user-charts>
</ng-container>

<ng-template #NotCurrentProject>
    <div class="flex-column-center padding-40-20">
        <img
            src="../../../assets/images/no_statistics.svg"
            [style.height.px]="300"
            [style.margin-top.px]="20"
        />
        <h4 class="placeholder-text">
            {{ 'user.statistics.noStatistics' | translate }}
        </h4>
    </div>
</ng-template>
