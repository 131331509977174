import {
    Component,
    ElementRef,
    ViewChild,
    ChangeDetectionStrategy,
    OnInit
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { TourEvent, ShepherdTourService } from '@furban/utilities';

@Component({
    selector: 'furban-tooling',
    templateUrl: './tooling.component.html',
    styleUrls: ['./tooling.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolingComponent implements OnInit {
    @ViewChild('menu', { static: true }) content: ElementRef;
    @ViewChild('clickMenuTrigger', { static: true })
    clickMenuTrigger: MatMenuTrigger;

    constructor(public tourService: ShepherdTourService) { }

    ngOnInit(): void {
        this.subscribeTourStartingEvents();
    }

    public subscribeTourStartingEvents(): void {
        this.tourService.tourStepChangedEmitter.subscribe((data) => {
            if (data === TourEvent.objectPlacementEvent) {
                this.clickMenuTrigger?.openMenu();
            } else if (data === TourEvent.endEvent) {
                this.clickMenuTrigger?.closeMenu();
            }
        });
    }
}
