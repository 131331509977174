<div class="important-space-between" mat-dialog-title>
    <h1>{{ 'termsAndConditions.title' | translate }}</h1>
    <button mat-dialog-close class="ghost-button" *ngIf="!showButtons">
        <span class="material-icons"> close </span>
    </button>
</div>
<div mat-dialog-content>
    <ng-container
        *ngIf="multilanguageService && multilanguageService.usedLang === 'nl-NL'"
    >
        <p>
            Wij zien het beschermen van persoonsgegevens als een zeer belangrijk
            onderdeel van onze bedrijfsvoering en dienstverlening. Het
            beschermen van deze gegevens ligt aan de basis van onze
            betrouwbaarheid als leverancier en partner. Met persoonsgegevens
            bedoelen we in dit geval: alle informatie over een
            ge&iuml;dentificeerde en/of identificeerbare natuurlijke
            persoon.&nbsp;
        </p>
        <p>
            In deze privacyverklaring leest u welke persoonsgegevens wij van u
            verzamelen. Wij verzamelen deze gegevens omdat we met u een
            overeenkomst hebben of in contact zijn over onze diensten en
            producten.
        </p>
        <p>Via deze verklaring krijgt u informatie over:</p>
        <ul>
            <li>waarom wij persoonsgegevens verwerken</li>
            <li>hoelang wij persoonsgegevens bewaren</li>
            <li>
                hoe u de door ons bewaarde persoonsgegevens kunt inzien en
                aanpassen
            </li>
            <li>hoe uw persoonsgegevens bij ons zijn beveiligd</li>
        </ul>
        <p><strong>Onze privacybeloften</strong></p>
        <p>Onze privacybeloften zijn te vangen in de volgende vuistregels:</p>
        <ul>
            <li>
                Doelbepaling en doelbinding: Wij gebruiken alleen
                persoonsgegevens voor uitdrukkelijk omschreven en
                gerechtvaardigde doeleinden.
            </li>
            <li>
                Grondslag: Wij baseren de verwerking van persoonsgegevens op een
                van de wettelijke grondslagen.
            </li>
            <li>
                Dataminimalisatie: Wij zorgen dat bij de verwerking van
                persoonsgegevens dat de hoeveelheid gegevens beperkt blijft.
            </li>
            <li>
                Transparantie: Wij leggen aan betrokkenen, zoals klanten,
                prospects, medewerkers, sollicitanten, gedetacheerden en
                leveranciers, transparant verantwoording af over het gebruik van
                hun persoonsgegevens.
            </li>
            <li>
                Veiligheid: Wij nemen privacy serieus. Bij iedere keuze die wij
                maken, nemen wij maatregelen om de privacy zo goed mogelijk te
                beschermen.
            </li>
        </ul>
        <p><strong>Beveiliging persoonsgegevens</strong></p>
        <p>
            Wij doen ons uiterste best om zorgvuldig met persoonsgegevens om te
            gaan. Ook doen wij alles om deze gegevens te beveiligen tegen
            verlies en/of enige vorm van onrechtmatig gebruik of onrechtmatige
            verwerking. Daarbij gebruiken wij passende technische en
            organisatorische maatregelen. Denk bijvoorbeeld aan de laatste
            innovaties op het gebied van security. Als wij uw persoonsgegevens
            met derden moet delen om haar taken uit te voeren, sluiten wij
            hiervoor verwerkersovereenkomsten af.
        </p>
        <p><strong>Inbreuk op de beveiliging</strong></p>
        <p>
            Is er ondanks al onze inspanningen sprake van een inbreuk op de
            beoogde beveiliging? Dan handelen we dit incident af volgens de
            hiervoor opgestelde interne procedure. Uiteraard doen wij er in dat
            geval alles aan om de gevolgen van de inbreuk zo beperkt mogelijk te
            houden.
        </p>
        <p>
            Vormt de inbreuk een privacyrisico, dan melden wij dit bij de
            Autoriteit Persoonsgegevens. Als het wenselijk is - en in ieder
            geval wanneer de wet dit vereist &ndash; informeren wij ook de
            betrokkenen over de inbreuk.
        </p>
        <p><strong>Wat zijn uw rechten?</strong></p>
        <p>
            Conform de Algemene Verordening Gegevensbescherming heeft u
            onderstaande rechten als het gaat om het gebruik van uw
            persoonsgegevens:
        </p>
        <ul>
            <li>
                <strong> Inzage</strong><br />
                U hebt het recht om de persoonsgegevens die wij van u verwerkt
                in te zien.
            </li>
            <li>
                <strong> Rectificatie en aanvulling</strong><br />
                U hebt het recht om persoonsgegevens die wij van u verwerkt te
                laten wijzigen.
            </li>
            <li>
                <strong> Vergetelheid</strong><br />
                U hebt het recht om uw persoonsgegevens door ons te laten
                verwijderen.
            </li>
            <li>
                <strong> Dataportabiliteit</strong><br />
                U hebt het recht om persoonsgegevens over te laten dragen van
                ons naar een andere partij.
            </li>
            <li>
                <strong> Beperking van de verwerking</strong><br />
                U hebt het recht om minder gegevens te laten verwerken door ons.
            </li>
            <li>
                <strong> Geautomatiseerde besluitvorming en profilering</strong
                ><br />
                U hebt het recht op een menselijke blik bij besluiten die wij
                nemen (in tegenstelling tot geautomatiseerde besluitvorming).
            </li>
            <li>
                <strong> Bezwaar</strong><br />
                U hebt het recht bij ons bezwaar te maken tegen een
                gegevensverwerking.
            </li>
        </ul>
        <p><strong>Hoe oefent u uw rechten uit?</strong></p>
        <p>
            Wij neemt de privacy van uw persoonsgegevens heel serieus. Daarom
            hebben wij een privacy-officer aangesteld, die al uw vragen over de
            privacyrechten behandelt. U kunt contact opnemen met de
            privacy-officer via&nbsp;<a
                tabindex="-1"
                href="mailto://info@furban.nl"
                >info@furban.nl</a
            >.
        </p>
        <p>
            <strong
                >Het recht om een klacht in te dienen bij de toezichthoudende
                autoriteit</strong
            >
        </p>
        <p>
            Wij gaan zo transparant en zorgvuldig mogelijk met uw gegevens om.
            Als u toch een klacht heeft over het verwerken van uw gegevens, dan
            verzoeken wij u eerst contact op te nemen met onze privacy-officer.
            Dit kan door het sturen van een bericht naar&nbsp;<a
                tabindex="-1"
                href="mailto://info@furban.nl"
                >info@furban.nl</a
            >. Wij doen vervolgens ons uiterste best om uw klacht op te lossen.
            Mocht u het onverhoopt niet eens zijn met de genomen maatregel, dan
            kunt u terecht bij de Autoriteit Persoonsgegevens.
        </p>
        <p><strong>Informatieblad per verwerking</strong></p>
        <p>
            Wij kiezen ervoor om naast de privacyverklaring voor specifieke
            verwerkingen/onderdelen een informatieblad op te stellen. Dit
            informatieblad gaat voor een verwerking specifiek in op de volgende
            deelonderwerpen:
        </p>
        <ul>
            <li>Waarom gebruiken wij uw persoonsgegevens?</li>
            <li>Welke soorten persoonsgegevens verwerken wij?</li>
            <li>Wie heeft toegang tot uw persoonsgegevens?</li>
            <li>
                Wat is de juridische grondslag voor de verwerking van uw
                persoonsgegevens?
            </li>
            <li>Hoelang bewaren wij uw persoonsgegevens?</li>
            <li>Welke beveiligingsmaatregelen zijn er getroffen?</li>
        </ul>
    </ng-container>

    <ng-container
        *ngIf="multilanguageService && multilanguageService.usedLang === 'en-US'"
    >
        <p>
            We see the protection of personal data as a very important part of
            our business operations and services. Protecting this data is the
            foundation of our reliability as a supplier and partner. By personal
            data we mean in this case: all information about an identified
            and/or identifiable natural person. ;
        </p>
        <p>
            In this privacy statement you can read about the personal data that
            we collect from you. We collect this information because we have an
            agreement with you or are in contact with you about our services and
            products.
        </p>
        <p>In this statement you can read:</p>
        <ul>
            <li>why we process personal data;</li>
            <li>how long we keep personal data;</li>
            <li>
                how you can view and modify the personal data we hold on you
            </li>
            <li>how your personal data is protected with us.</li>
        </ul>
        <p><strong>Our privacy promises</strong></p>
        <p>Our privacy promises are defined by the following rules of thumb:</p>
        <ul>
            <li>
                Purpose determination and purpose limitation: we only uses
                personal data for explicitly defined and justified purposes.
            </li>
            <li>
                Basis: we base the processing of personal data on a legal base.
            </li>
            <li>
                Data minimization: When processing personal data, we ensures
                that the amount of data remains limited.
            </li>
            <li>
                Transparency: We account transparently to those involved, such
                as customers, prospects, employees, applicants, secondees and
                suppliers, about the use of their personal data.
            </li>
            <li>
                Veiligheid: We take privacy seriously. In every choice we make,
                we take measures to protect privacy as much as possible.
            </li>
        </ul>
        <p>&nbsp;</p>
        <p><strong>Personal data security</strong></p>
        <p>
            We do our utmost best to handle personal data with the utmost care.
            We also do everything in our power to protect these data against
            loss and/or any form of unlawful use or processing. We use
            appropriate technical and organizational measures. We use
            appropriate technical and organizational measures, for example of
            the latest innovations in security. If we have to share your
            personal data with third parties in order to carry out its tasks, we
            make agreements for this purpose.
        </p>
        <p><strong>Security breach</strong></p>
        <p>
            Despite all our efforts, if a security breach happens, we will deal
            with such an incident in accordance with an internal procedure drawn
            up for this purpose. In the case of a security breach we will do
            everything in our power to minimize the consequences of the breach.
        </p>
        <p>
            If the breach constitutes a privacy risk, we will report this to the
            Dutch Authority of Personal Data (Autoriteit Persoonsgegevens). If
            it is desirable - and in any case the law requires it - we will also
            inform those concerned about the breach.
        </p>
        <p><strong>What are your rights?</strong></p>
        <p>
            In accordance with the General Data Protection Regulation (GDPR, in
            Dutch: Algemene Verordening Gegevensbescherming or AGV), you have
            the following rights when it comes to the use of your personal data:
        </p>
        <ul>
            <li>
                <strong> Acces</strong><br />
                You have the right to view the personal data that we process
                about you.
            </li>
            <li>
                <strong> Correction and supplementation</strong><br />
                You have the right to have your personal data processed by us to
                be changed.
            </li>
            <li>
                <strong> Be forgotten</strong><br />
                You have the right to have your personal data deleted.
            </li>
            <li>
                <strong> Data portability</strong><br />
                You have the right to have personal data transferred from us to
                another party.
            </li>
            <li>
                <strong> Restriction of processing</strong><br />
                You have the right to have less data processed by us.
            </li>
            <li>
                <strong> Automated decision-making and profiling</strong><br />
                You have the right to a human opinion when making decisions that
                we make (as opposed to automated decision-making).
            </li>
            <li>
                <strong> Objection</strong><br />
                You have the right to object to data processing with us.
            </li>
        </ul>
        <p>&nbsp;</p>
        <p><strong>How do you exercise your rights?</strong></p>
        <p>
            We take the privacy of your personal data very seriously. That's why
            we have appointed a privacy officer who handles all your questions
            about privacy rights. You can contact our privacy officer at&nbsp;<a
                tabindex="-1"
                href="mailto://info@furban.nl"
                >info@furban.nl</a
            >.
        </p>
        <p>
            <strong
                >The right to file a complaint with the supervisory
                authority</strong
            >
        </p>
        <p>
            We handle your data as transparently and carefully as possible. If
            you do have a complaint about the processing of your data, please
            contact our privacy officer first. This can be done by sending a
            message to&nbsp;<a tabindex="-1" href="mailto://info@furban.nl"
                >info@furban.nl</a
            >. We will then do our utmost to resolve your complaint. If you
            unexpectedly do not agree with the measure taken, please contact the
            Dutch Authority of Personal Data (Autoriteit Persoonsgegevens).
        </p>
        <p><strong>Information sheet per data processing</strong></p>
        <p>
            In addition to the privacy statement, we have opted to draw up an
            information sheet for specific processing/parts. This information
            sheet specifically addresses the following sub-topics for
            processing:
        </p>
        <ul>
            <li>Why do we use your personal data?</li>
            <li>What types of personal data do we process?</li>
            <li>Who has access to your personal data?</li>
            <li>
                What is the legal basis for the processing of your personal
                data?
            </li>
            <li>How long do we keep your data?</li>
            <li>Which security measures are in place?</li>
        </ul>
    </ng-container>

    <ng-container
        *ngIf="multilanguageService && multilanguageService.usedLang === 'ro-RO'"
    >
        <p>
            We see the protection of personal data as a very important part of
            our business operations and services. Protecting this data is the
            foundation of our reliability as a supplier and partner. By personal
            data we mean in this case: all information about an identified
            and/or identifiable natural person. ;
        </p>
        <p>
            In this privacy statement you can read about the personal data that
            we collect from you. We collect this information because we have an
            agreement with you or are in contact with you about our services and
            products.
        </p>
        <p>In this statement you can read:</p>
        <ul>
            <li>why we process personal data;</li>
            <li>how long we keep personal data;</li>
            <li>
                how you can view and modify the personal data we hold on you
            </li>
            <li>how your personal data is protected with us.</li>
        </ul>
        <p><strong>Our privacy promises</strong></p>
        <p>Our privacy promises are defined by the following rules of thumb:</p>
        <ul>
            <li>
                Purpose determination and purpose limitation: we only uses
                personal data for explicitly defined and justified purposes.
            </li>
            <li>
                Basis: we base the processing of personal data on a legal base.
            </li>
            <li>
                Data minimization: When processing personal data, we ensures
                that the amount of data remains limited.
            </li>
            <li>
                Transparency: We account transparently to those involved, such
                as customers, prospects, employees, applicants, secondees and
                suppliers, about the use of their personal data.
            </li>
            <li>
                Veiligheid: We take privacy seriously. In every choice we make,
                we take measures to protect privacy as much as possible.
            </li>
        </ul>
        <p>&nbsp;</p>
        <p><strong>Personal data security</strong></p>
        <p>
            We do our utmost best to handle personal data with the utmost care.
            We also do everything in our power to protect these data against
            loss and/or any form of unlawful use or processing. We use
            appropriate technical and organizational measures. We use
            appropriate technical and organizational measures, for example of
            the latest innovations in security. If we have to share your
            personal data with third parties in order to carry out its tasks, we
            make agreements for this purpose.
        </p>
        <p><strong>Security breach</strong></p>
        <p>
            Despite all our efforts, if a security breach happens, we will deal
            with such an incident in accordance with an internal procedure drawn
            up for this purpose. In the case of a security breach we will do
            everything in our power to minimize the consequences of the breach.
        </p>
        <p>
            If the breach constitutes a privacy risk, we will report this to the
            Dutch Authority of Personal Data (Autoriteit Persoonsgegevens). If
            it is desirable - and in any case the law requires it - we will also
            inform those concerned about the breach.
        </p>
        <p><strong>What are your rights?</strong></p>
        <p>
            In accordance with the General Data Protection Regulation (GDPR, in
            Dutch: Algemene Verordening Gegevensbescherming or AGV), you have
            the following rights when it comes to the use of your personal data:
        </p>
        <ul>
            <li>
                <strong> Acces</strong><br />
                You have the right to view the personal data that we process
                about you.
            </li>
            <li>
                <strong> Correction and supplementation</strong><br />
                You have the right to have your personal data processed by us to
                be changed.
            </li>
            <li>
                <strong> Be forgotten</strong><br />
                You have the right to have your personal data deleted.
            </li>
            <li>
                <strong> Data portability</strong><br />
                You have the right to have personal data transferred from us to
                another party.
            </li>
            <li>
                <strong> Restriction of processing</strong><br />
                You have the right to have less data processed by us.
            </li>
            <li>
                <strong> Automated decision-making and profiling</strong><br />
                You have the right to a human opinion when making decisions that
                we make (as opposed to automated decision-making).
            </li>
            <li>
                <strong> Objection</strong><br />
                You have the right to object to data processing with us.
            </li>
        </ul>
        <p>&nbsp;</p>
        <p><strong>How do you exercise your rights?</strong></p>
        <p>
            We take the privacy of your personal data very seriously. That's why
            we have appointed a privacy officer who handles all your questions
            about privacy rights. You can contact our privacy officer at&nbsp;<a
                tabindex="-1"
                href="mailto://info@furban.nl"
                >info@furban.nl</a
            >.
        </p>
        <p>
            <strong
                >The right to file a complaint with the supervisory
                authority</strong
            >
        </p>
        <p>
            We handle your data as transparently and carefully as possible. If
            you do have a complaint about the processing of your data, please
            contact our privacy officer first. This can be done by sending a
            message to&nbsp;<a tabindex="-1" href="mailto://info@furban.nl"
                >info@furban.nl</a
            >. We will then do our utmost to resolve your complaint. If you
            unexpectedly do not agree with the measure taken, please contact the
            Dutch Authority of Personal Data (Autoriteit Persoonsgegevens).
        </p>
        <p><strong>Information sheet per data processing</strong></p>
        <p>
            In addition to the privacy statement, we have opted to draw up an
            information sheet for specific processing/parts. This information
            sheet specifically addresses the following sub-topics for
            processing:
        </p>
        <ul>
            <li>Why do we use your personal data?</li>
            <li>What types of personal data do we process?</li>
            <li>Who has access to your personal data?</li>
            <li>
                What is the legal basis for the processing of your personal
                data?
            </li>
            <li>How long do we keep your data?</li>
            <li>Which security measures are in place?</li>
        </ul>
    </ng-container>
</div>

<section class="buttons-container" *ngIf="showButtons">
    <button class="secondary-button" (click)="onNoClick()">
        <h6>{{ 'generic.decline' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="onYesClick()">
        <h6>{{ 'generic.accept' | translate }}</h6>
    </button>
</section>
