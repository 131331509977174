import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FurbanUtil } from '../helpers/furbanUtil';
import { ProjectStatusEnum } from '../_enum/project-status.enum';
import { Environment } from '../_models/environment-model';
import { Project } from '../_models/project';
import { UserProfile } from '../_models/user-profile';
import { MediaService } from '../_services/media.service';

@Component({
    selector: 'furban-admin-card',
    templateUrl: './admin-card.component.html',
    styleUrls: ['./admin-card.component.scss'],
})
export class AdminCardComponent {
    @Input() userProfile: UserProfile;
    @Input() title: string;
    @Input() cardMedia: string;
    @Input() project?: Project;
    @Input() isLiveDesign?: boolean;
    @Output() cardClickedEmitter: EventEmitter<void> = new EventEmitter<void>();
    @Output() deleteClickedEmitter: EventEmitter<void> =
        new EventEmitter<void>();

    public projectStatus = ProjectStatusEnum;
    public getClassForProject = FurbanUtil.getClassForProjectStatus;
    public getColorBasedOnProjectType = FurbanUtil.getColorBasedOnProjectType;
    public getProjectTypeText = FurbanUtil.getProjectTypeText;

    constructor(private translateService: TranslateService,
        private mediaService: MediaService,
        @Inject('env') private environment: Environment) { }

    public cardClicked(): void {
        this.cardClickedEmitter.emit();
    }

    public getUserMedia(mediaId: string): string {
        return this.mediaService.getUserMedia(mediaId);
    }

    public buildURL(type: string, projectId: string): void {
        if (type === 'linkedin') {
            const auxURL = this.environment.shareLinks[type].furbanLink + projectId;
            const url =
                this.environment.shareLinks[type].linkedingSharer +
                encodeURIComponent(auxURL);
            window.open(url, '_blank');
        } else {
            const url = this.environment.shareLinks[type] + projectId;
            window.open(url, '_blank');
        }
    }

    public getMedia(mediaId): string {
        return mediaId
            ? `${this.environment.apiPath}/media/public/${mediaId}`
            : '../../../assets/images/default-project.png';
    }

    public getStatusTooltipText(project: Project): void {
        const status = this.getClassForProject(project);
        return this.translateService.instant(
            `buttonsTooltips.projectStatus.${status}`
        );
    }

    public deleteClicked(): void {
        this.deleteClickedEmitter.emit();
    }
}
