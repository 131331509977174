import { Component, OnInit, Inject } from '@angular/core';

import {
    DesignProposalService,
    DesignProposal,
    Project,
    routingConstants,
    FurbanUtil,
    MediaService
} from '@furban/utilities';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'furban-app-next-steps',
    templateUrl: './next-steps.component.html',
    styleUrls: ['./next-steps.component.scss'],
})
export class NextStepsComponent implements OnInit {
    public dp: DesignProposal;
    public project: Project;
    public isVotingDateExpired = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<NextStepsComponent>,
        private designProposalService: DesignProposalService,
        private router: Router,
        private mediaService: MediaService,
        private translateService: TranslateService
    ) {
        this.project = data.project;
        this.isVotingDateExpired = FurbanUtil.isDateExpiredForIOS(
            this.project.votingExpiringDate
        );
    }

    ngOnInit() {
        if (this.project.hasWinner) {
            this.designProposalService
                .getWinnerDP(this.project.projectId)
                .subscribe((data) => {
                    this.dp = data;
                });
        }
    }

    public getMedia(mediaId): string {
        return this.mediaService.getMedia(mediaId);
    }

    closeDialog() {
        this.dialogRef.close();
    }

    openDPDetails() {
        this.closeDialog();
        this.router.navigate([
            routingConstants.designProposals,
            this.dp.designProposalId,
        ]);
    }

    get noMessageYetText(): string {
        const datePipe = new DatePipe('en-US');
        return this.project.isCitizenDesigns
            ? this.translateService.instant('winner.noMessageYetGeneral')
            : this.isVotingDateExpired
                ? this.translateService.instant('winner.noMessageYetGeneral')
                : this.translateService
                    .instant('winner.noMessageYetBeforeVotingDateExpired')
                    .replace(
                        '{votingExpiringDate}',
                        datePipe.transform(
                            this.project?.votingExpiringDate,
                            'dd/MM/yyyy'
                        )
                    );
    }
}
