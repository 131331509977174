import { DocumentModel } from './document';
import { User } from './user';
import { Group } from './group';

export class PermitProject {
    public projectId?: string;
    public clientId: number;
    public name: string;
    public description: string;
    public startDate: string;
    public endDate: string;
    public createdBy: User;
    public currentStepId: number;
    public media: string;
    public isActive = true;
    public ended: boolean;
    public documents: DocumentModel[];
    public groups: Group[];
    public neighborInvolved: boolean;
    public municipalityInvolved: boolean;
    public commentsCount: number;
    public publicLinkGenerated: boolean;
}
