import {
    Component,
    OnInit,
    ViewContainerRef,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserProfileService } from '../user-profile/user-profile.service';

import {
    AuthService,
    Client,
    ModalManager,
    MultilanguageService,
    MunicipalityService,
    UserProfile,
    User,
    CaptchaUtil,
    LoginState,
    ControlsUtil,
    FurbanUtil,
    routingConstants,
} from '@furban/utilities';
import { LoginForm } from '../../../shared/prototypes/loginform';
import { ProjectDetailsService } from '../../../project-shared/project-details/project-details.service';

@Component({
    selector: 'furban-app-user-auth',
    templateUrl: './user-auth.component.html',
    styleUrls: ['./user-auth.component.scss'],
})
export class UserAuthComponent implements OnInit, LoginForm {
    @ViewChild('loginForm') loginForm: ElementRef;
    public loginCheckForm: FormGroup;
    public profile: UserProfile;
    public selectedMunicipality: string;

    /**
     * initialize with something so we wong get a request to /null
     */
    public captchaData = 'data:image/jpg;base64,VFa';
    /**
     * this is the id it has to be sent to the server along with the answer
     */
    public captchaId: string;
    /**
     * true if the IP addres orgiginating the request is blocked
     */
    public isBlocked: boolean;

    public municipalities: Client[];
    public state: string = LoginState.login;
    public selectedClient: string;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;
    public isApp = FurbanUtil.isApp;

    constructor(
        private formBuilder: FormBuilder,
        public authService: AuthService,
        public router: Router,
        public userProfileService: UserProfileService,
        private modal: ModalManager,
        private viewContainerRef: ViewContainerRef,
        private municipalityService: MunicipalityService,
        private multilanguageService: MultilanguageService,
        private projectDetailsService: ProjectDetailsService
    ) { }

    ngOnInit() {
        this.multilanguageService.setupLanguage();
        this.loginCheckForm = this.formBuilder.group({
            userCode: ['', Validators.required],
            remberMeFormControl: [],
            captchaAnswer: [],
        });
        this.authService.redirectUser();
    }

    login() {
        if (this.loginCheckForm.invalid) {
            return;
        }
        this.authService.codeUser = this.loginCheckForm.get('userCode').value;
        this.authService.redirectURL = '';
        this.authService
            .login(
                this.loginCheckForm.get('userCode').value.trim(),
                this.loginCheckForm.get('userCode').value.trim(),
                this.loginCheckForm.get('remberMeFormControl').value,
                this.captchaId,
                this.loginCheckForm.get('captchaAnswer').value
            )
            .subscribe(
                {
                    next: (data) => {
                        if (data && data['userId']) {
                            if (data['credentialsExpired']) {
                                this.state = LoginState.changePassword;
                            } else {
                                this.getUserProfile(data as User);
                            }

                            this.initializeProjectList();
                        } else {
                            console.error('something went wrong');
                        }
                    },
                    error: (error) => {
                        if (error.status === 403 && error.error['auth-blocked']) {
                            this.showCaptcha();
                        }
                        if (
                            error.status === 403 &&
                            (error.error['user-suspended'] ||
                                error.error['auth-not-confirmed'])
                        ) {
                            return;
                        } else {
                            this.modal
                                .showModal(
                                    this.viewContainerRef,
                                    ModalManager.createConfiguration(
                                        'Error',
                                        'Invalid login code.'
                                    )
                                )
                                .subscribe((res) => {
                                    this.loginCheckForm.get('userCode').reset();
                                    this.loginCheckForm
                                        .get('captchaAnswer')
                                        .reset();
                                });
                        }
                    }
                }
            );
    }
    /**
     * call this when the IP address is blocked and have to display a new captcha
     * @param event optional, sent from the view
     */
    public showCaptcha(event?: any) {
        if (event) {
            event.stopPropagation();
        }
        CaptchaUtil.loadCaptcha(this);
    }

    getUserProfile(data: User) {
        this.authService.user = data;

        this.authService.getUserProfile().subscribe((userProfile) => {
            this.profile = userProfile;
            if (this.profile) {
                if (this.profile.acceptedTOS) {
                    this.authService.redirectUser();
                } else {
                    this.state = LoginState.changeName;
                }
            }
        });
    }

    public forgotCode() {
        this.state = LoginState.changePassword;
        this.getMunicipalities();
    }

    public navigateToLandscape(): void {
        this.router.navigateByUrl(`/${routingConstants.landing}`);
    }

    private initializeProjectList(): void {
        this.projectDetailsService.getProjects().subscribe();
    }

    private getMunicipalities() {
        this.municipalityService.getAllMunicipalities().subscribe((data) => {
            this.municipalities = data;
        });
    }
}
