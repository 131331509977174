<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'user.location.title' | translate }}</h2>
    <button class="ghost-button" (click)="closePopup()">
        <span class="material-icons">close</span>
    </button>
</div>

<mat-dialog-content>
    <div class="dialog-content">
        <ng-container *ngIf="imageData">
            <img
                #projectImage
                alt="Project Image"
                class="project-image"
                [src]="imageData"
            />
        </ng-container>

        <h5>{{ project.description }}</h5>

        <h5>{{ getProjectTypeDescription | translate }}</h5>

        <ng-container *ngIf="project.documents">
            <div
                *ngFor="let document of project.documents"
                class="list-element flex-row-spaced"
            >
                <ng-container *ngIf="document.isActive">
                    <span class="material-icons green-icon">attach_file</span>
                    <a (click)="downloadDocument(document)">{{
                        document.fileName
                    }}</a>
                </ng-container>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="primary-button" (click)="closePopup()">
        <h6>{{ 'generic.okBtn' | translate }}</h6>
    </button>
</div>
