<ng-container *ngIf="designProposal">


    <div class="design-card" [class.winner]="designProposal.winner">
        <ng-container *ngIf="hasEnoughReportsToBlur">
            <div class="reported-content-dp">
                <p>{{ 'report.reportInfoMessage' | translate }}</p>
                <button class="primary-button" (click)="designProposal.reportsCount = 0">
                    {{ 'report.reportAgreeMessage' | translate }}
                </button>
            </div>
        </ng-container>

        <section class="card-overlay pointer" (click)="cardSectionnClick()">
            <img src="../../../assets/images/winner.svg" [style.height.%]="50" />
            <h4>{{ 'winner.check' | translate }}</h4>
        </section>

        <ng-container *ngIf="!hasEnoughReportsToBlur">
            <div class="gradient" [class.card-border]="isClicked" (click)="cardSectionnClick()">
            </div>
        </ng-container>

        <div class="design-img background-center"
            [style.background-image]="'url(' + getMedia(designProposal.media) + ')'">
            <ng-container *ngIf="showTop()">
                <label class="points-number">
                    <span class="material-icons"> emoji_events </span>
                    {{ getPointsText(designProposal) }}
                </label>
            </ng-container>
            <ng-container *ngIf="checkDesignProposalReactionId()">
                <div class="emoji">
                    <img src="{{getImageURL(designProposalReactionId)}}" />
                </div>
            </ng-container>

            <ng-container *ngIf="checkIfLiveDesign()">
                <label class="live-design">
                    {{ 'designProposals.liveDesign' | translate }}
                </label>
            </ng-container>

            <ng-container *ngIf="!isEmbeded">
                <div class="flex-row-spaced bottom-content">
                    <section class="avatar flex-row-start card-type">
                        <ng-container *ngIf="designProposal.userProfile">
                            <furban-avatar-picture [name]="designProposal.userProfile.screenName" [size]="28" [mediaId]="
                                    getUserMedia(
                                        designProposal.userProfile.mediaId
                                    )
                                ">
                            </furban-avatar-picture>

                            <furban-app-user-action-menu class="user-description flex-row-spaced"
                                [userProfile]="designProposal.userProfile" [parentViewContainerRef]="viewContainerRef"
                                [reportedDesignProposal]="
                                    designProposal.reportsCount > 0
                                        ? designProposal
                                        : null
                                " [projectName]="project.name" (userDelete)="userDelete($event)">
                            </furban-app-user-action-menu>
                        </ng-container>
                    </section>
                    <div class="reactions" [class.disabled]="disabledActions">
                        <furban-like [dp]="designProposal" [isForAdminCard]="true" class="likes">
                        </furban-like>
                        <ng-container *ngIf="!isPublicPage">
                            <div class="comments" (click)="openCommentDialog()">
                                <span class="material-icons">comment</span>
                                <h6>{{ designProposal.comments['length'] }}</h6>
                            </div>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="shouldDisplayDpReports()">
                        <span class="material-icons reported-icon" matTooltip="{{
                                'report.reportAdminInfoTooltip'
                                    | translate
                                        : {
                                              noOfReporters:
                                                  designProposal.reportsCount
                                          }
                            }}">
                            warning
                        </span>
                    </ng-container>

                    <span class="material-icons padding-0-12" [matMenuTriggerFor]="menuAdd">
                        more_vert
                    </span>
                    <mat-menu #menuAdd="matMenu" overlapTrigger="false">
                        <button mat-menu-item (click)="openDPDetails()" value="openDetails">
                            {{ 'winner.openDetails' | translate }}
                        </button>
                        <ng-container *ngIf="checkIfHasMarkWinnerOption()">
                            <button mat-menu-item (click)="markAsWinner()" value="expertExportCsv">
                                {{ 'winner.mark' | translate }}
                            </button>
                        </ng-container>
                        <ng-container *ngIf="isLoggedUserNotWithAdminRole()">
                            <button mat-menu-item (click)="report()" value="report">
                                {{ 'report.reportBtn' | translate }}
                            </button>
                        </ng-container>
                        <ng-container *ngIf="showReport()">
                            <button mat-menu-item (click)="deleteReporting()">
                                {{ 'report.deleteReportBtn' | translate }}
                            </button>
                        </ng-container>
                        <ng-container *ngIf="isAdmin()">
                            <button mat-menu-item (click)="createEmbededLink()">
                                {{ 'generic.embeded' | translate }}
                            </button>
                        </ng-container>

                    </mat-menu>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>