export class PathObject {
    public pathObjsId?: number;
    public projectId: string;
    public userId: number;
    public objId: number;
    public pathId: number;
    public position: string;
    public angle: number;
    public default: boolean;
    public published: boolean;
    public freeshapePoints?: string;
    public freeshapeGroundMaterial?: number;
    public name?: string;
    public safetyArea: number;
    public scale?: number;
    public placeOutside: boolean;
    public designProposalId?: string;
    public isLocked: boolean;
    public verticalCollision: boolean;
}
