<ng-container *ngIf="isTutorialsOpened">
    <div class="overlay"></div>
</ng-container>
<nav class="furban-navigation">
    <div>
        <img src="../../../assets/images/logo_F.svg"
             class="pointer logo"
             id="welcome-step"
             (click)="navigateToHomepage()" />

        <ng-container *ngFor="let item of items">
            <section class="navigation-item"
                     [class.active]="checkForActiveTab(item)"
                     [class.disabled]="checkIfDisabled(item)"
                     matBadge="0"
                     [matBadgeHidden]="checkMatBadgeHidden(item)"
                     (click)="navigateToItem(item)">
                <div class="navigation-icon"
                     matTooltip="{{ item.title | translate }}"
                     [matTooltipDisabled]="isAppOrMobile()">
                    <span class="material-icons">{{ item.icon }}</span>
                </div>

            </section>
        </ng-container>
    </div>

    <div>
        <ng-container *ngIf="shouldDisplayTutorials()">
            <section class="navigation-item"
                     [class.active]="isTutorialsOpened"
                     (click)="toggleTutorials()">
                <div class="navigation-icon"
                     matTooltip="{{ 'navigation.tutorials.tooltip' | translate }}"
                     [matTooltipDisabled]="isAppOrMobile()">
                    <span class="material-icons">menu_book</span>
                </div>

            </section>
        </ng-container>

        <ng-container *ngIf="checkIfShouldDisplayNotifications()">
            <section class="navigation-item">
                <div class="navigation-icon"
                     matTooltip="{{ 'navigation.superadmin.notifications' | translate }}"
                     [matTooltipDisabled]="isAppOrMobile()">
                    <furban-notifications id="notifications-step">
                    </furban-notifications>
                </div>
            </section>
        </ng-container>

        <furban-manage-profile [containerRef]="containerRef"
                               id="profile-step"></furban-manage-profile>
    </div>
</nav>

<furban-tutorials-menu *ngIf="isTutorialsOpened"></furban-tutorials-menu>