<div class="important-space-between" mat-dialog-title>
    <h2>{{'admin.groups.generate' | translate}}</h2>
    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons">
            close
        </span>
    </button>
</div>

<mat-dialog-content>
    <form [formGroup]="groupForm">

        <mat-form-field class="full-width grey" appearance="outline" [style.margin-bottom.px]="20">
            <mat-label>
                {{'admin.groups.noOfCitizens' | translate}}
            </mat-label>
            <input type="number" name="inputNumber" class="small-input" formControlName="initiativeCitizensCount"
                matInput placeholder="{{'admin.groups.noOfCitizens' | translate}}">
            <mat-error *ngIf="hasControlMaxError('initiativeCitizensCount',groupForm)">
                {{'admin.groups.maxCitizen' | translate}}
            </mat-error>
            <mat-error *ngIf="hasControlMaxError('initiativeCitizensCount',groupForm)">
                {{'admin.groups.negativaValidation' | translate}}
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closeDialog()">
        <h6>{{'generic.cancelBtn' | translate}}</h6>
    </button>
    <button class="primary-button" (click)="saveGroup()">
        <h6>{{'generic.save' | translate}}</h6>
    </button>
</div>