import {
    Component,
    OnInit,
    Input,
    ViewContainerRef,
    EventEmitter,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators, FormControl } from '@angular/forms';
import { AddingCodesService } from './adding-codes.service';
import { TranslateService } from '@ngx-translate/core';

import {
    ModalManager,
    CustomToasterComponent,
    FurbanUtil,
    CustomToasterService,
    ControlsUtil,
} from '@furban/utilities';

@Component({
    selector: 'furban-app-adding-codes',
    templateUrl: './adding-codes.component.html',
    styleUrls: ['./adding-codes.component.scss'],
})
export class AddingCodesComponent implements OnInit {
    @Input() public parentRef: ViewContainerRef;
    @Input() public groupId: string;
    @Input() public expertsCount: number;
    public onModalClose = new EventEmitter();
    public noWhiteSpacesValidator = FurbanUtil.noWhiteSpacesValidator;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public addCodeFormControl: FormControl;
    public hasControlError = ControlsUtil.hasControlError;

    constructor(
        private dialogRef: MatDialogRef<AddingCodesComponent>,
        private addingCodesService: AddingCodesService,
        private customToasterService: CustomToasterService,
        private translateService: TranslateService,
        private modal: ModalManager
    ) { }

    ngOnInit() {
        this.addCodeFormControl = new FormControl('', [
            Validators.required,
            this.noWhiteSpacesValidator,
            Validators.maxLength(12),
            this.htmlValidator,
        ]);
    }

    closePopup() {
        if (this.addCodeFormControl.dirty) {
            this.modal
                .showModal(
                    this.parentRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'errors.discardMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (res) {
                        this.dialogRef.close();
                        this.onModalClose.emit('closeModal');
                    }
                });
        } else {
            this.dialogRef.close();
            this.onModalClose.emit('closeModal');
        }
    }

    public assignUserToGroupAndCloseModal() {
        this.addingCodesService
            .assignUserToGroup(
                this.addCodeFormControl.value.trim(),
                this.groupId
            )
            .subscribe((result) => {
                this.dialogRef.close();
                this.onModalClose.emit('closeModal');
                this.customToasterService.openCustomToaster(
                    CustomToasterComponent,
                    'check_circle',
                    'success',
                    'admin.projectCodes.addCodeSuccessMessage',
                    200
                );
            });
    }

    public addCodeManually() {
        if (this.addCodeFormControl.invalid) {
            this.addCodeFormControl.markAsDirty();
            return;
        }

        if (
            this.expertsCount >= 5 &&
            this.addCodeFormControl.value.startsWith('EX')
        ) {
            this.dialogRef.close();
            this.customToasterService.openCustomToaster(
                CustomToasterComponent,
                'info',
                'info',
                'admin.projectGenerate.expertMaxNumber',
                200
            );
        } else {
            this.assignUserToGroupAndCloseModal();
        }
    }
}
