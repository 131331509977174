import { createAction, props } from '@ngrx/store';
import { House } from '../_models/house';
import { HouseAsset } from '../_models/house-asset';

export const resetHouse = createAction(
    '[permit-editor] reset house',
    props<{
        house: House;
    }>()
);

export const resetHouseRequest = createAction(
    '[permit-editor] reset house request',
    props<{
        house: House;
    }>()
);

export const getHouseAssets = createAction(
    '[permit-editor] get house assets',
    props<{
        houseId: string;
    }>()
);

export const loadHouseAssets = createAction(
    '[permit-editor] load house assets',
    props<{ objects: HouseAsset[] }>()
);

export const addAssetObject = createAction(
    '[permit-editor] add house asset',
    props<{ object: HouseAsset }>()
);

export const addAssetObjectRequest = createAction(
    '[permit-editor] add house asset request',
    props<{ object: HouseAsset }>()
);

export const addMultipleAssetObjects = createAction(
    '[permit-editor] add multipe house assets',
    props<{ objects: HouseAsset[] }>()
);

export const addMultipleAssetObjectsRequest = createAction(
    '[permit-editor] add multiple house assets request',
    props<{ objects: HouseAsset[] }>()
);

export const updateMultipleAssetObjects = createAction(
    '[permit-editor] update multipe house assets',
    props<{ objects: HouseAsset[] }>()
);

export const updateMultipleAssetObjectsRequest = createAction(
    '[permit-editor] update multiple house assets request',
    props<{ objects: HouseAsset[] }>()
);

export const updateMultipleRoofAssetsWhenHouseChanges = createAction(
    '[permit-editor] update multipe roof assets when house changed',
    props<{ objects: HouseAsset[] }>()
);

export const updateMultipleRoofAssetsWhenHouseChangesRequest = createAction(
    '[permit-editor] update multiple roof assets when house changed request',
    props<{ objects: HouseAsset[] }>()
);

export const updateAsset = createAction(
    '[permit-editor] update asset',
    props<{ object: HouseAsset, shouldUpdateStore: boolean }>()
);

export const updateAssetRequest = createAction(
    '[permit-editor] update asset request',
    props<{ object: HouseAsset }>()
);

export const deleteAsset = createAction(
    '[permit-editor] delete asset',
    props<{ object: HouseAsset }>()
);

export const deleteAssetRequest = createAction(
    '[permit-editor] delete asset request',
    props<{ object: HouseAsset }>()
);

export const deleteMultipleAssets = createAction(
    '[permit-editor] delete multiple assets',
    props<{ objects: HouseAsset[] }>()
);

export const deleteMultipleAssetsRequest = createAction(
    '[permit-editor] delete multiple assets request',
    props<{ objects: HouseAsset[] }>()
);

export const getHouseInformations = createAction(
    '[permit-editor] get house informations',
    props<{ house: House }>()
);

export const getHouseInformationsRequest = createAction(
    '[permit-editor] get house informations request',
    props<{ projectId: string; isClone: boolean }>()
);

export const updateHouseInformations = createAction(
    '[permit-editor] update house informations',
    props<{ house: House; shouldUpdatePrevious: boolean }>()
);

export const updateHouseInformationsRequest = createAction(
    '[permit-editor] update house informations request',
    props<{ house: House; shouldUpdatePrevious: boolean }>()
);

export const restoreInitialStatePermit = createAction(
    '[permit-editor] restore initial state'
);

export const undoRequestPermit = createAction(
    '[permit-editor] undo request',
    props<{
        objectsToSave: HouseAsset[];
        objectsToRemove: HouseAsset[];
        houseToSave: House;
    }>()
);

export const undoPermit = createAction(
    '[permit-editor] undo',
    props<{
        objectsToReplace: HouseAsset[];
        newObjects: HouseAsset[];
        objectsToReMove: HouseAsset[];
        houseToSave: House;
    }>()
);

export const redoRequestPermit = createAction(
    '[permit-editor] redo request',
    props<{
        objectsToSave: HouseAsset[];
        objectsToRemove: HouseAsset[];
        houseToSave: House;
    }>()
);

export const redoPermit = createAction(
    '[permit-editor] redo',
    props<{
        objectsToReplace: HouseAsset[];
        newObjects: HouseAsset[];
        objectsToReMove: HouseAsset[];
        houseToSave: House;
    }>()
);
