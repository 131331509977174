// REFACTOR - we use only one of this 'admin'
export enum RoleEnum {
    superadmin = 1,
    admin = 2,
    expert = 3,
    citizen = 4,
    pioneer = 5,
    neighbor = 6,
    collaborator = 7,
}
