import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DocumentModel } from '../_models/document';
import { APP_CONFIG } from '@furban/app-config';
import { Project3DUpload } from '../_models/project-3d-upload';
import { SilenceHttpParams } from '../_models/silence-http-param';
import { ObjAndTextureFiles } from '../_models/obj-and-texture-file';
import { Environment } from '../_models/environment-model';
import { UploadedObjectNameEnum, UploadedObjectTypeEnum } from '../_enum/uploaded-object-type.enum';
import { UploadFile } from '../_models/upload-file';

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {


    public objectFileChangeSubject = new Subject<UploadFile>();
    public objectFileChangeSubjectObserable = this.objectFileChangeSubject.asObservable();

    public uploadedObjectUpdate = new Subject<UploadedObjectNameEnum>();
    public uploadedObjectUpdateObserable = this.uploadedObjectUpdate.asObservable();

    public customObjectProject3DUpload: Project3DUpload;
    public fixedObjectProject3DUpload: Project3DUpload;
    public undergroundProject3DUpload: Project3DUpload;

    public currentCustomObjectFile: File;
    public currentFixedObjectFile: File;
    public currentUndergroundFile: File;

    public isCustomObjectUploaded: boolean;
    public isFixedObjectUploaded: boolean;
    public isUndergroundUploaded: boolean;

    public currentFixedObjectTextureFile: ObjAndTextureFiles;
    public currentCustomObjectTextureFile: ObjAndTextureFiles;
    public currentUndergroundTextureFile: ObjAndTextureFiles;

    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );
    private _options = new HttpHeaders().set('Content-Type', 'application/pdf');
    private _headersNoCache = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .append(
            'Cache-Control',
            'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
        )
        .append('Pragma', 'no-cache')
        .append('Vary', '*')
        .append('Expires', '0');

    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        private http: HttpClient
    ) { }

    public uploadFiles(formData: FormData): Observable<DocumentModel[]> {
        return this.http
            .put(this.environment.apiPath + '/media/documents', formData)
            .pipe(map((result) => result as DocumentModel[]));
    }

    public deleteFile(document: DocumentModel): Observable<DocumentModel> {
        return this.http
            .post(this.environment.apiPath + '/media/document', document, {
                headers: this._headers,
            })
            .pipe(map((result) => result as DocumentModel));
    }

    public downloadFile(documentId: string): Observable<ArrayBuffer> {
        return this.http
            .get(`${this.environment.apiPath}/media/document/${documentId}`, {
                headers: this._options,
                responseType: 'arraybuffer',
            })
            .pipe(map((result) => result));
    }

    public openDocumentInNewTab(documentId: string) {
        const fileUrl = `${this.environment.apiPath}/media/document/${documentId}`;
        window.open(fileUrl);
    }

    public getFiles(documents: DocumentModel[]): Observable<DocumentModel[]> {
        let stringArray = '';
        for (let i = 0; i < documents.length; i++) {
            stringArray = stringArray.concat(documents[i].documentId + ',');
        }
        const params = new HttpParams().set('files', stringArray);
        return this.http
            .get(this.environment.apiPath + '/media/documents', {
                headers: this._headers,
                params,
            })
            .pipe(map((result) => result as DocumentModel[]));
    }

    public uploadProject3DUpload(
        file: File,
        customObject: Project3DUpload,
        textureFile?: File
    ): Observable<Project3DUpload> {
        const formData: FormData = new FormData();

        formData.append('file', file, file.name);
        formData.append('projectId', customObject.projectId);
        formData.append('position', customObject.position);
        formData.append('rotation', customObject.rotation);
        formData.append('objectType', customObject.objectType.toString());
        formData.append('objectS3Key', customObject.objectS3Key);
        formData.append('extension', customObject.extension);
        if (textureFile) {
            formData.append('textureS3Key', customObject.textureS3Key);
            formData.append('textureFile', textureFile, textureFile.name);
        }

        return this.http
            .put(this.environment.apiPath + '/project-3d-upload', formData)
            .pipe(map((result) => result as Project3DUpload));
    }

    public updateProject3DUpload(
        customObject: Project3DUpload,
    ): Observable<Project3DUpload> {
        
        return this.http
            .put(this.environment.apiPath + '/project-3d-upload/update', customObject)
            .pipe(map((result) => result as Project3DUpload));
    }

    public getProject3DUpload(
        projectId: string,
        objectType: number
    ): Observable<Project3DUpload> {
        return this.http
            .get(
                `${this.environment.apiPath}/project-3d-upload/${projectId}/${objectType}`,
                {
                    headers: this._headersNoCache,
                    params: new SilenceHttpParams(true, true, false)
                }
            )
            .pipe(map((result) => result as Project3DUpload));
    }

    public getProject3DUploads(ids: string[]): Observable<Project3DUpload[]> {

        const idsQueryParam = ids.join(',');

        return this.http
            .get(`${this.environment.apiPath}/project-3d-upload?ids=${idsQueryParam}`, {
                headers: this._headersNoCache,
                params: new SilenceHttpParams(true, true, false)
            })
            .pipe(map((result) => result as Project3DUpload[]));
    }

    public getProject3DUploadFile(objectS3Key: string): Observable<File> {
        return this.http
            .get(
                `${this.environment.apiPath}/project-3d-upload/file/${objectS3Key}`,
                {
                    headers: this._headers,
                    responseType: 'blob' as 'json',
                }
            )
            .pipe(map((result) => result as File));
    }

    public removeProject3DUpload(project3DUpload: Project3DUpload): Observable<void> {
        const httpOptions = {
            headers: this._headers,
            body: project3DUpload,
        };

        return this.http.delete<void>(
            `${this.environment.apiPath}/project-3d-upload`,
            httpOptions
        );
    }

    public changeObjectFile(file: File, type: UploadedObjectTypeEnum): void {

        switch (type) {
            case UploadedObjectTypeEnum.customObject:
                this.currentCustomObjectFile = file;
                break;

            case UploadedObjectTypeEnum.underground:
                this.currentUndergroundFile = file;
                break;

            case UploadedObjectTypeEnum.fixedDesign:
                this.currentFixedObjectFile = file;
                break;
            default:
                break;
        }

        this.objectFileChangeSubject.next({ file: file, type: type });
    }

    public changeObjectTextureFile(files: ObjAndTextureFiles, type: UploadedObjectTypeEnum): void {

        switch (type) {
            case UploadedObjectTypeEnum.customObject:
                this.currentCustomObjectTextureFile = files;
                break;

            case UploadedObjectTypeEnum.fixedDesign:
                this.currentFixedObjectTextureFile = files;
                break;

            case UploadedObjectTypeEnum.underground:
                this.currentUndergroundTextureFile = files;
                break;
            default:
                break;
        }

        this.objectFileChangeSubject.next({ file: files, type: type });


    }

    public changeProject3DUpload(project3DUpload: Project3DUpload, type: UploadedObjectTypeEnum): void {
        switch (type) {
            case UploadedObjectTypeEnum.customObject:
                this.customObjectProject3DUpload = project3DUpload
                break;
            case UploadedObjectTypeEnum.underground:
                this.undergroundProject3DUpload = project3DUpload
                break;

            case UploadedObjectTypeEnum.fixedDesign:
                this.fixedObjectProject3DUpload = project3DUpload
                break;
            default:
                break;
        }
    }

    public resetFilesOnProjectChange(): void {
        this.currentCustomObjectFile = null;
        this.currentFixedObjectFile = null;
        this.currentUndergroundFile = null;
        this.customObjectProject3DUpload = null;
        this.undergroundProject3DUpload = null;
        this.fixedObjectProject3DUpload = null;
    }
}
