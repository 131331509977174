import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import {
    Project,
    routingConstants,
} from '@furban/utilities';
import { ProjectDetailsService } from '../project-shared/project-details/project-details.service';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { DesignProposalsComponent } from '../project-shared/design-proposals/design-proposals.component';

@Component({
    selector: 'furban-public-project-details',
    templateUrl: './public-project-details.component.html',
    styleUrls: ['./public-project-details.component.scss'],
})
export class PublicProjectDetailsComponent implements OnInit {
    @ViewChild('designProposals') designProposals: DesignProposalsComponent;

    public projectId: string;
    public project: Project;
    public isViewerOnly: boolean;
    public headerBackRoute: string;
    constructor(
        public viewContainerRef: ViewContainerRef,
        private projectDetailsService: ProjectDetailsService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.subscribeToRoute();

        this.projectDetailsService
            .getPublicProject(this.projectId)
            .subscribe((data) => {
                this.project = data;
            });
    }

    public onScrollDown(): void {
        this.designProposals.onParentScrollDown();
    }

    private subscribeToRoute(): void {
        combineLatest([this.route.params, this.route.data]).subscribe(
            (data: any) => {
                this.projectId = data[0]['id'];
                this.isViewerOnly = data[1]['isViewerOnly'] as boolean;
                if (this.isViewerOnly) {
                    this.headerBackRoute =
                        routingConstants.publicEntryPage + '/' + this.projectId;
                }
            }
        );
    }
}
