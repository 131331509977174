import {
    Component,
    Input,
    ViewContainerRef,
    EventEmitter,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ModalManager } from '@furban/utilities';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CollaborativeDesignService } from '../collaborative-design/collaborative-design.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-generate-collaborative-codes',
    templateUrl: './generate-collaborative-codes.component.html',
    styleUrls: ['./generate-collaborative-codes.component.scss'],
})
export class GenerateCollaborativeCodesComponent {
    @Input() public parentRef: ViewContainerRef;
    @Input() public isCollaborativeUser = false;
    @Input() public groupId: string;

    public onModalClose = new EventEmitter();

    public noEmailError = false;
    public emailList = [];
    public addOnBlur = true;

    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    constructor(
        private dialogRef: MatDialogRef<GenerateCollaborativeCodesComponent>,
        private collaborativeDesignService: CollaborativeDesignService,
        private translateService: TranslateService,
        private modal: ModalManager
    ) { }

    public addUsers(emailList: string[]): void {
        this.collaborativeDesignService
            .inviteUsers(emailList, this.groupId, this.isCollaborativeUser)
            .subscribe(() => {
                this.dialogRef.close();
                this.onModalClose.emit(emailList);
            });
    }

    public closePopup(): void {
        if (this.emailList?.length > 0) {
            this.modal
                .showModal(
                    this.parentRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'errors.discardMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (res) {
                        this.dialogRef.close();
                        this.onModalClose.emit('closeModal');
                    }
                });
        } else {
            this.dialogRef.close();
            this.onModalClose.emit('closeModal');
        }
    }

    public getTitleText(): string {
        const code = this.isCollaborativeUser
            ? 'collaborativeDesign.inviteContributors'
            : 'admin.codes.inviteUsers';
        return this.translateService.instant(code);
    }
}
