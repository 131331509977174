import { Inject, Injectable } from '@angular/core';
import { Environment } from '../_models/environment-model';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(@Inject('env') private environment: Environment) { }

  public getMedia(mediaId: string): string {
    return mediaId
      ? `${this.environment.apiPath}/media/public/${mediaId}`
      : '../../../assets/images/default-project.png';
  }

  public getMapImage(mediaId: string): string {
    return mediaId
      ? `${this.environment.apiPath}/media/public/${mediaId}`
      : '../../../assets/images/project-dashboard-cards-images/proj-area.png';
  }

  public getUserMedia(mediaId: string): string | null {
    return mediaId
      ? `${this.environment.apiPath}/media/public/${mediaId}`
      : null;
  }
}
