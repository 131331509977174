import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { PermitProject } from '@furban/utilities';
import { PermitApprovalDialogComponent } from '../../permit/_components/approval-dialog/approval-dialog.component';
import { PermitReview } from '../../permit/_models/permit-review';
import { PermitReviewService } from '../../permit/_services/permit-review.service';
import { PermitCommentsDialogComponent } from '../../shared/permit-comments-dialog/permit-comments-dialog.component';

@Component({
    selector: 'furban-permit-requests',
    templateUrl: './permit-requests.component.html',
    styleUrls: ['./permit-requests.component.scss'],
})
export class PermitRequestsComponent implements OnInit {
    public permitRequests: PermitReview[] = [];
    public permitRequestsCount: number;

    private pageIndex = 0;
    private perPage = 12;

    constructor(
        private permitReviewService: PermitReviewService,
        public dialog: MatDialog,
        public viewContainerRef: ViewContainerRef
    ) { }

    ngOnInit(): void {
        this.getAllPermitRequestsCount();
        this.getAllPermitRequests();
    }

    private getAllPermitRequests(): void {
        this.permitReviewService
            .getAllMunicipalityPermitRequests(this.pageIndex, this.perPage)
            .subscribe((data) => {
                this.permitRequests = this.permitRequests.concat(...data);
            });
    }

    public onScrollDown(): void {
        if (this.permitRequests.length < this.permitRequestsCount) {
            this.pageIndex += 1;
            this.getAllPermitRequests();
        }
    }

    private getAllPermitRequestsCount(): void {
        this.permitReviewService
            .getAllPermitRequestsCount()
            .subscribe((data) => {
                this.permitRequestsCount = data;
            });
    }

    public openCommentDialog(permitProject: PermitProject): void {
        const userProfileDialogRef = this.dialog.open(
            PermitCommentsDialogComponent,
            {
                width: '40%',
                data: {
                    project: permitProject,
                },
            }
        );

        userProfileDialogRef.disableClose = true;
        userProfileDialogRef.componentInstance.parentViewContainerRef =
            this.viewContainerRef;
        userProfileDialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.resetPermitRequests();
            }
        });
    }

    public openReviewDialog(permitReview: PermitReview): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '632px';
        dialogConfig.data = {
            permitReview: permitReview,
        };
        const approvalDialog = this.dialog.open(
            PermitApprovalDialogComponent,
            dialogConfig
        );
        approvalDialog.afterClosed().subscribe((data) => {
            if (data) {
                this.permitReviewService
                    .updatePermitStatus(data)
                    .subscribe((response) => {
                        this.resetPermitRequests();
                    });
            }
        });
    }

    private resetPermitRequests(): void {
        this.pageIndex = 0;
        this.permitRequests = [];
        this.getAllPermitRequests();
    }
}
