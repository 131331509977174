<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'comments.title' | translate }}</h2>
    <button mat-dialog-close class="ghost-button">
        <span class="material-icons"> close </span>
    </button>
</div>

<furban-comments
    *ngIf="dp && isLoggedIn()"
    #comments
    [isDialog]="true"
    [dp]="dp"
    [type]="0"
    [project]="project"
    [parentViewContainerRef]="parentViewContainerRef"
    [preventSelectComment]="true"
    (reportOutput)="report($event)"
>
</furban-comments>

<ng-container *ngIf="!isLoggedIn()">
    <div class="flex-column-center padding-20">
        <img
            src="../../../assets/images/no_comm.svg"
            [style.height.px]="200"
            [style.margin-bottom.px]="20"
        />
        <h4 [style.font-size.px]="18">
            {{ 'comments.commentsNotAvailable' | translate }}
        </h4>
    </div>
</ng-container>
