import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnChanges } from '@angular/core';
import {
    trigger,
    style,
    animate,
    transition,
    state,
    keyframes,
} from '@angular/animations';

import {
    AuthService,
    DesignProposal,
    DesignProposalService,
    Like,
} from '@furban/utilities';

@Component({
    selector: 'furban-like',
    templateUrl: './like.component.html',
    styleUrls: ['./like.component.scss'],
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    stroke: '{{ color }}',
                }),
                { params: { color: 'var(--neutral-1)' } }
            ),
            state(
                'closed',
                style({
                    fill: 'var(--alert-1)',
                    stroke: 'var(--alert-1)',
                }),
                { params: { color: 'var(--neutral-1)' } }
            ),
            transition('open => closed', [
                animate(
                    '0.6s',
                    keyframes([
                        style({
                            opacity: 1,
                            fill: 'var(--alert-1)',
                            stroke: 'var(--alert-1)',
                            offset: 0,
                        }),
                        style({
                            transform:
                                'scale(1.3) rotate(45deg) translateY(-40px)',
                            opacity: 0,
                            offset: 1,
                        }),
                    ])
                ),
            ]),
            transition('closed => open', [
                animate(
                    '0.6s',
                    keyframes([
                        style({
                            fill: 'var(--alert-1)',
                            stroke: 'var(--alert-1)',
                            offset: 0,
                        }),
                        style({
                            fill: 'transparent',
                            stroke: '{{ color }}',
                            offset: 1,
                        }),
                    ])
                ),
            ]),
        ]),
    ],
})
export class LikeComponent implements OnChanges {
    @Input() public dp: DesignProposal;
    @Input() public disabled?: boolean;
    @Input() public isForAdminCard?: boolean = false;
    @Input() public hasCircle?: boolean;
    public heartColor: string;

    constructor(
        private dpService: DesignProposalService,
        private authService: AuthService,
        private translateService: TranslateService
    ) { }

    ngOnChanges(): void {
        this.heartColor = this.isForAdminCard
            ? 'var(--neutral-3)'
            : 'var(--neutral-1)';
    }

    likeDesign(dp: DesignProposal) {
        if (this.disabled) {
            return;
        }
        let like;
        /* If curent_user has already liked the design proposal, get that like and set its flag to false
     Else create new Like and assign the 2 needed values*/
        if (this.checkIfLiked(dp)) {
            like = dp.likes.find(
                (existingLike) =>
                    existingLike.userprofileId ===
                    this.authService.userProfile.userProfileId
            );
            like.active = false;
        } else {
            like = new Like();
            like.designProposalId = dp.designProposalId;
            like.userprofileId = this.authService.userProfile.userProfileId;
        }

        this.dpService.likeDesignProposal(like).subscribe(
            (data) => {
                if (data.active) {
                    dp.likes.push(data);
                } else {
                    const index = dp.likes.findIndex(
                        (existingLike) =>
                            existingLike.userprofileId ===
                            this.authService.userProfile.userProfileId
                    );
                    dp.likes.splice(index, 1);
                }
            }
        );
    }

    // NEEDS REFACTOR
    checkIfLiked(curentDp: DesignProposal): boolean {
        const likes = curentDp.likes;
        if (likes && this.authService.userProfile) {
            if (
                likes.find(
                    (like) =>
                        like.userprofileId ===
                        this.authService.userProfile.userProfileId
                )
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getLikesText(): string {
        return !this.isForAdminCard
            ? `${this.dp.likes.length} ${this.translateService.instant(
                'designProposals.likes'
            )}`
            : this.dp.likes.length.toString();
    }
}
