import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { PermitProjectService } from '../permit-project/permit-project.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PermitInvolvementComponent } from '../permit-involvement/permit-involvement.component';

import {
    PermitInvolvementEnum,
    PermitProject,
    routingConstants,
    StepperService,
    User,
    Group,
    ModalManager,
    CopyToClipboardUtil,
    CustomToasterComponent,
    CustomToasterService,
    MediaService,
} from '@furban/utilities';

import { Step } from '@furban/utilities';
import { CodesStatusComponent } from '../codes-status/codes-status.component';
import { PermitReviewService } from '../_services/permit-review.service';

import { ReviewsCount } from '../_models/reviews-count.model';
import { PermitProjectStatus } from '../_models/permit-project-status';
import { PermitApprovalDialogComponent } from '../_components/approval-dialog/approval-dialog.component';

import { environment } from '../../../environments/environment';

@Component({
    selector: 'furban-permit-process-dashboard',
    templateUrl: './permit-process-dashboard.component.html',
    styleUrls: ['./permit-process-dashboard.component.scss'],
})
export class PermitProcessDashboardComponent implements OnInit {
    public project: PermitProject;
    public steps: Step[] = [];
    public selectInvolvement: string;
    public expandedMenu: string;
    public neighborCodes: User[] = [];
    public permitGroup: Group;
    public reviewCounts: ReviewsCount;
    public municipalityPermitStatus: PermitProjectStatus;
    public publicUrl: string;

    constructor(
        private permitProjectService: PermitProjectService,
        private router: Router,
        private stepperService: StepperService,
        private dialog: MatDialog,
        private viewContainerRef: ViewContainerRef,
        private permitReviewService: PermitReviewService,
        private customToasterService: CustomToasterService,
        private mediaService: MediaService,
        private manager: ModalManager
    ) {
        this.redirectIfNewProject();

        stepperService.stepsChanged.subscribe((data) => {
            this.steps = data;
        });
    }

    ngOnInit(): void {
        this.initialSetup();
    }

    public getMedia(mediaId): string {
        return this.mediaService.getMedia(mediaId);
    }

    private initialSetup(): void {
        this.steps = this.stepperService.steps;
        this.getProject();
    }

    private getNeighborForProcess(): void {
        if (!this.hasAllStepsCompleted) {
            return;
        }
        if (!this.respondedToInvolvementPopup) {
            this.involveMunicipalityPopup();
            return;
        }
        this.getNeighborCodes();
    }

    private getNeighborCodes(): void {
        this.permitReviewService
            .getApprovalAndRejectsCount(this.project.projectId)
            .subscribe((data) => {
                if (!data) {
                    return;
                }
                this.reviewCounts = data;
            });
    }

    private get respondedToInvolvementPopup(): boolean {
        return (
            this.project.neighborInvolved || this.project.municipalityInvolved
        );
    }

    public goToStep(step: Step): void {
        if (step.isUntouched()) {
            return;
        }
        this.stepperService.currentStep = this.steps.find(
            (value) => value.id === step.id
        );
        this.router.navigateByUrl(step.path);
    }

    public goBack(): void {
        this.router.navigateByUrl(routingConstants.initiativesProjectList);
    }

    public get hasAllStepsCompleted(): boolean {
        return this.project.currentStepId === 4;
    }

    public get isNeighborInvolved(): boolean {
        return this.project.neighborInvolved;
    }

    public get shouldExpandMunicipalityMenu(): boolean {
        return (
            this.expandedMenu &&
            this.expandedMenu === PermitInvolvementEnum.municipality
        );
    }

    public get shouldExpandNeighborMenu(): boolean {
        return (
            this.expandedMenu &&
            this.expandedMenu === PermitInvolvementEnum.neighbor
        );
    }

    public get numberOfNeighbors(): number {
        return this.neighborCodes.length;
    }

    public involveNeighbors(): void {
        this.project.neighborInvolved = true;
        this.saveProject(this.project);
    }

    public involveMunicipality(): void {
        this.permitProjectService
            .involveMunicipality(this.project)
            .subscribe((data) => {
                this.project.municipalityInvolved = true;
                this.stepperService.project = this.project;
                this.getMunicipalityInvolvementStatus();
            });
    }

    public involveMunicipalityPopup(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '55%';
        dialogConfig.minWidth = '520px';
        dialogConfig.data = {
            parentRef: this.viewContainerRef,
        };

        const involvementPopup = this.dialog.open(
            PermitInvolvementComponent,
            dialogConfig
        );
        involvementPopup.afterClosed().subscribe((data) => {
            if (!data) {
                return;
            }

            this.expandedMenu = data;

            if (data === PermitInvolvementEnum.municipality) {
                this.involveMunicipality();
            }
        });
    }

    public openCodesStatus(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.minWidth = '400px';
        dialogConfig.width = '40%';
        dialogConfig.data = {
            groupId: this.permitGroup.groupId,
            projectId: this.project.projectId,
        };
        const codesStatusPopup = this.dialog.open(
            CodesStatusComponent,
            dialogConfig
        );
        codesStatusPopup.afterClosed().subscribe((data) => {
            if (!data) {
                return;
            }
            this.project.neighborInvolved = true;
            this.getNeighborCodes();
        });
    }

    public openApprovePopup(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '520px';
        dialogConfig.data = {
            users: this.neighborCodes,
        };
        const codesStatusPopup = this.dialog.open(
            PermitApprovalDialogComponent,
            dialogConfig
        );
        codesStatusPopup.afterClosed().subscribe();
    }

    public showRemoveProjectWarning(): void {
        this.manager
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.projectActivate.projectDeleteMessage',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.removePermitProject();
                }
            });
    }

    public copyLink(): void {
        CopyToClipboardUtil.copyText(
            this.publicUrl,
            this.showSuccessMessage,
            this.showFailMessage,
            this.customToasterService
        );
    }

    public generateLink(): void {
        this.project.publicLinkGenerated = true;
        this.saveProject(this.project);
    }

    public removePermitProject(): void {
        this.permitProjectService
            .deletePermitProject(this.project.projectId)
            .subscribe(() => {
                this.router.navigateByUrl(routingConstants.initiatives);
            });
    }

    private saveProject(project: PermitProject): void {
        this.permitProjectService.saveProject(project).subscribe((data) => {
            this.project = data;
            this.stepperService.project = data;
        });
    }

    private getProject(): void {
        this.permitProjectService
            .getProject(this.stepperService.projectId)
            .subscribe((data) => {
                this.project = data;
                this.permitGroup = this.project.groups[0];
                this.getMunicipalityInvolvementStatus();
                this.getNeighborForProcess();
                this.generateLinkForPublicPage();
            });
    }

    private getMunicipalityInvolvementStatus(): void {
        if (this.project.municipalityInvolved) {
            this.permitReviewService
                .getMunicipalityPermitStatusByProjectId(this.project.projectId)
                .subscribe((data) => {
                    this.municipalityPermitStatus = data;
                });
        }
    }

    private redirectIfNewProject(): void {
        if (this.stepperService.projectId !== 'new') {
            return;
        }

        this.router.navigateByUrl(`${routingConstants.initiativesProjectList}`);
    }

    private generateLinkForPublicPage(): void {
        this.publicUrl = `${environment.furbanHome}${routingConstants.publicPermitDetails}/${this.project.projectId}`;
    }

    private showSuccessMessage(customToasterService: CustomToasterService) {
        customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            'pioneer.permitProcessDashboard.linkCopied',
            3000
        );
    }

    private showFailMessage(customToasterService: CustomToasterService) {
        customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'error',
            'pioneer.permitProcessDashboard.errorOnCopy',
            60000
        );
    }
}
