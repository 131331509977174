import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat/chat.component';
import { WinnerPopupComponent } from './winner-popup/winner-popup.component';
import { NextStepsComponent } from './next-steps/next-steps.component';
import { FilterPanelComponent } from './filter-panel/filter-panel.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { NotificationsComponent } from './notifications/notifications.component';

import { UtilitiesModule, FurbanMaterialModule } from '@furban/utilities';
import { ChatToasterComponent } from './chat-toaster/chat-toaster.component';
import { DesignProposalDetailsComponent } from './design-proposal-details/design-proposal-details.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { LikeComponent } from './like/like.component';
import { DaysProgressComponent } from './days-progress/days-progress.component';
import { ImageUploadService } from './image-upload/image-upload.service';
import { ModifiedGuard } from './modifyStatus/modified.guard';
import { ModifyStatusService } from './modifyStatus/modifyStatus.service';
import { ChatService } from './chat/chat.service';
import { NavigationService } from './navigation/navigation.service';
import { FaqService } from './faq/faq.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-img-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ProjectsDropdownComponent } from './projects-dropdown/projects-dropdown.component';
import { RouterModule } from '@angular/router';
import { ChangeNameComponent } from './change-name/change-name.component';
import { HomepageCardComponent } from './homepage-card/homepage-card.component';
import { PrivacyModalComponent } from '../furban-info/privacy-modal/privacy-modal.component';
import { FurbanHomepageComponent } from '../furban-homepage/furban-homepage.component';
import { HomepageNavigationComponent } from '../furban-homepage/homepage-navigation/homepage-navigation.component';
import { CookiePolicyDialogComponent } from './cookie-policy-dialog/cookie-policy-dialog.component';
import { PublicThreeJsEditorComponent } from './public-three-js-editor/public-three-js-editor.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NotificationToasterComponent } from './notification-toaster/notification-toaster.component';
import { LoginWrapperComponent } from './login-wrapper/login-wrapper.component';
import { ObjCategoriesMenuComponent } from './obj-categories-menu/obj-categories-menu.component';
import { ToolingComponent } from './tooling/tooling.component';
import { RegisterComponent } from './register/register.component';
import { RegisterAuthService } from './register/register-auth.service';
import { CitizenRegisterComponent } from './citizen-register/citizen-register.component';
import { CitizenRegisterService } from './citizen-register/citizen-register.service';
import {
    RecaptchaModule,
    RecaptchaSettings,
    RECAPTCHA_SETTINGS,
} from 'ng-recaptcha';
import { GdprComponent } from './gdpr/gdpr.component';
import { PermitCommentsComponent } from './permit-comments/permit-comments.component';

import { CommonComponentsModule } from '@furban/common-components';
import { NgrxThreeEditorComponent } from './ngrx-three-editor/ngrx-three-editor.component';
import { NgrxThreeLiveCollaborationComponent } from './ngrx-three-live-collaboration/ngrx-three-live-collaboration.component';
import { ReconnectModalComponent } from './reconnect-modal/reconnect-modal.component';
import { PermitCommentsDialogComponent } from './permit-comments-dialog/permit-comments-dialog.component';
import { FaqComponent } from './faq/faq.component';
import { FurbanHeaderComponent } from './header/header.component';
import { FurbanNavigationComponent } from './navigation/navigation.component';
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { ManageProfileComponent } from './manage-profile/manage-profile.component';
import { environment } from '../../environments/environment';

@NgModule({
    declarations: [
        ChatComponent,
        WinnerPopupComponent,
        NextStepsComponent,
        FilterPanelComponent,
        FaqComponent,
        FurbanHeaderComponent,
        TableFilterComponent,
        NotificationsComponent,
        FurbanNavigationComponent,
        TermsAndConditionsComponent,
        ImageUploadComponent,
        CookieBannerComponent,
        ChatToasterComponent,
        DesignProposalDetailsComponent,
        LikeComponent,
        DaysProgressComponent,
        ProjectsDropdownComponent,
        LoginWrapperComponent,
        ChangeNameComponent,
        HomepageCardComponent,
        PrivacyModalComponent,
        FurbanHomepageComponent,
        HomepageNavigationComponent,
        CookiePolicyDialogComponent,
        PublicThreeJsEditorComponent,
        NotificationToasterComponent,
        ObjCategoriesMenuComponent,
        ToolingComponent,
        RegisterComponent,
        CitizenRegisterComponent,
        GdprComponent,
        PermitCommentsComponent,
        NgrxThreeEditorComponent,
        NgrxThreeLiveCollaborationComponent,
        ReconnectModalComponent,
        PermitCommentsDialogComponent,
        ManageProfileComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        FurbanMaterialModule,
        ImageCropperModule,
        InfiniteScrollModule,
        PickerModule,
        RouterModule,
        UtilitiesModule.forRoot(environment),
        RecaptchaModule,
        CommonComponentsModule,
    ],
    exports: [
        ChatComponent,
        WinnerPopupComponent,
        NextStepsComponent,
        FilterPanelComponent,
        FaqComponent,
        FurbanHeaderComponent,
        TableFilterComponent,
        NotificationsComponent,
        FurbanNavigationComponent,
        TermsAndConditionsComponent,
        ImageUploadComponent,
        CookieBannerComponent,
        ChatToasterComponent,
        DesignProposalDetailsComponent,
        LikeComponent,
        DaysProgressComponent,
        ProjectsDropdownComponent,
        LoginWrapperComponent,
        ChangeNameComponent,
        HomepageCardComponent,
        PrivacyModalComponent,
        FurbanHomepageComponent,
        HomepageNavigationComponent,
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        FurbanMaterialModule,
        ImageCropperModule,
        InfiniteScrollModule,
        PickerModule,
        RouterModule,
        DragDropModule,
        PublicThreeJsEditorComponent,
        ObjCategoriesMenuComponent,
        ToolingComponent,
        RegisterComponent,
        PermitCommentsComponent,
        NgrxThreeEditorComponent,
        NgrxThreeLiveCollaborationComponent,
        PermitCommentsDialogComponent,
        ManageProfileComponent
    ],
    providers: [
        ImageUploadService,
        ModifiedGuard,
        ModifyStatusService,
        ChatService,
        NavigationService,
        FaqService,
        RegisterAuthService,
        CitizenRegisterService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: '6Lc2TLMaAAAAAGmn3B5AuL2nOywg5EqoS7tZTeQP',
            } as RecaptchaSettings,
        },
    ],
    entryComponents: [
        TermsAndConditionsComponent,
        ImageUploadComponent,
        ChatToasterComponent,
        WinnerPopupComponent,
        NextStepsComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
