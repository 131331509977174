import { Component, Input, ViewContainerRef } from '@angular/core';

import { MediaService, PermitComment, PermitProject } from '@furban/utilities';

@Component({
    selector: 'furban-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
})
export class CommentComponent {
    @Input() public comment: PermitComment;
    @Input() public parentViewContainerRef: ViewContainerRef;
    @Input() public project: PermitProject;

    public noOfReportsToPrevent = 3;

    constructor(private mediaService: MediaService) { }

    public highlight() {
        return this.comment.highlighted;
    }

    public getUserMedia(mediaId: string): string {
        return this.mediaService.getUserMedia(mediaId);
    }
}
