import { EventEmitter } from '@angular/core';
import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomToasterComponent } from '../custom-toaster/custom-toaster.component';
import { CustomToasterService } from '../_services/custom-toaster.service';

export type Language = 'en-US' | 'nl-NL' | 'ro-RO';

export const BROWSER_LANGUAGE = 'Browser_Language';

@Injectable({
    providedIn: 'root',
})
export class MultilanguageService {
    translations: string;
    defLang = 'nl-NL';
    usedLang: string;
    public languageChanged: EventEmitter<string>;

    constructor(
        private _translateService: TranslateService,
        @Inject(BROWSER_LANGUAGE)
        private _browserLocale: string,
        private customToasterService: CustomToasterService
    ) {
        this.languageChanged = new EventEmitter();
    }

    public setupLanguage(): void {
        if (
            window &&
            window.location &&
            window.location.hostname.toLowerCase().includes('.ro')
        ) {
            this.defLang = 'ro-RO';
        }

        this._translateService.addLangs(['en-US', 'nl-NL', 'ro-RO']);
        this._translateService.setDefaultLang(this.defLang);

        const recordLanguage: string = localStorage.getItem('language');

        if (recordLanguage) {
            this.usedLang = recordLanguage;
            this._translateService.use(recordLanguage).subscribe((value) => {
                this.translations = value;
            });
        } else {
            if (this._browserLocale.match(/en-US|nl-NL|ro-RO/)) {
                this.usedLang = this._browserLocale;
                this._translateService
                    .use(this._browserLocale)
                    .subscribe((value) => {
                        this.translations = value;
                    });

                localStorage.setItem('language', this._browserLocale);
            } else {
                this.usedLang = this.defLang;
                this._translateService.use(this.defLang).subscribe((value) => {
                    this.translations = value;
                });

                localStorage.setItem('language', this.defLang);
            }
        }
    }

    public switchLanguage(lang: Language): void {
        this._translateService.setDefaultLang(lang);
        this._translateService.use(lang).subscribe((value) => {
            this.languageChanged.emit(lang);
            this.translations = value;
        });

        localStorage.setItem('language', lang);

        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            'generic.success',
            2000
        );
    }

    public updateUsedLanguage(): void {
        this.switchLanguage(this.usedLang as Language);
    }
}
