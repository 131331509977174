import { Injectable } from '@angular/core';

@Injectable()
export class ModifyStatusService {
    private _modify: any = {};

    public setModifyKey(key: string, value: boolean) {
        this._modify[key] = value;
    }

    public getModifyKey(key: string) {
        return this._modify[key];
    }

    /**
     * When there is a modal that contains another modal
     * Ex: 1st modal is the 'parent-modal' and than you can open the 2nd modal 'parent-modal-detail';
     * if you try to navigate from the second modal to a third modal(or other place), you will need this
     * method to validate if there are changes
     * @param key this must have a common part for the child and parent; like 'parent-modal' - 'parent-modal-detail'
     */
    public getParentAndChildModifyKey(key: string) {
        for (const property in this._modify) {
            if (property.indexOf(key) > -1 && this._modify[property]) {
                return true;
            }
        }
        return false;
    }

    /**
     * When there is a modal that contains another modal
     * Ex: 1st modal is the 'parent-modal' and than you can open the 2nd modal 'parent-modal-detail';
     * if you try to navigate from the second modal to a third modal(or other place), you will need this
     * method to validate if there are changes
     * @param key this must have a common part for the child and parent; like 'parent-modal' - 'parent-modal-detail'
     */
    public setParentAndChildModifyKey(key: string, value: boolean) {
        for (const property in this._modify) {
            if (property.indexOf(key) > -1) {
                this._modify[property] = value;
            }
        }
    }

    public clear() {
        this._modify = {};
    }

    public getAnyModified(): boolean {
        for (const o in this._modify) {
            if (this._modify[o]) {
                return true;
            }
        }

        return false;
    }
}
