import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    DesignProposalService,
    routingConstants,
    AuthService,
    DesignProposalEnum,
    DesignProposal,
    SortTypeEnum,
    DesignProposalRequestParam,
    ModalManager,
    StepperService,
    CustomToasterService,
} from '@furban/utilities';
import { GenericMunicipalityCardsComponent } from '../generic-municipality-cards/generic-municipality-cards.component';
import { PublishService } from '../publish-popup/publish.service';

@Component({
    selector: 'furban-create-default-designs',
    templateUrl: './create-default-designs.component.html',
    styleUrls: ['./create-default-designs.component.scss'],
})
export class CreateDefaultDesignsComponent
    extends GenericMunicipalityCardsComponent
    implements OnInit {
    constructor(
        protected override stepperService: StepperService,
        protected router: Router,
        protected authService: AuthService,
        protected override designProposalService: DesignProposalService,
        protected override modal: ModalManager,
        protected override viewContainerRef: ViewContainerRef,
        protected override customToasterService: CustomToasterService,
        private publishService: PublishService,
        protected override route: ActivatedRoute
    ) {
        super(
            stepperService,
            modal,
            customToasterService,
            designProposalService,
            viewContainerRef,
            route
        );
    }

    public override ngOnInit(): void {
        super.ngOnInit();
        this.stepperService.modifyCurrentStepId(false);
        this.countDesignProposals();
        this.getDesignProposals(SortTypeEnum.SORT_BY_DATE);
    }

    public createMunicipalityDesign() {
        const newMunicipalityDesignProposal =
            DesignProposal.createMunicipalityDesignProposal(
                this.authService.userProfile,
                this.projectId
            );
        this.designProposalService
            .saveAdminDesignProposal(newMunicipalityDesignProposal)
            .subscribe((data) => {
                this.publishService.designProposal = data as DesignProposal;
                this.router.navigateByUrl(
                    `${routingConstants.process}/${this.projectId}/(project:create-design/${data.designProposalId})`
                );
            });
    }

    public goToDP(designProposal: DesignProposal) {
        this.publishService.designProposal = designProposal;
        if (!designProposal.groupId) {
            this.router.navigateByUrl(
                `${routingConstants.process}/${this.projectId}/(project:create-design/${designProposal.designProposalId}`
            );
        } else {
            this.router.navigateByUrl(
                `${routingConstants.process}/${this.projectId}/${routingConstants.collaborativeSessions}/${designProposal.designProposalId}`
            );
        }
    }

    public override getPaginatedDesignProposals(
        dpRequestParam: DesignProposalRequestParam
    ) {
        this.designProposalService
            .getMunicipalityDesignProposals(dpRequestParam)
            .subscribe((data) => {
                this.curentDPs = this.curentDPs.concat(data);
            });
    }

    public override getDesignProposals(sortingCriteria: string) {
        const dpRequestParam = new DesignProposalRequestParam(
            this.projectId,
            this.curentPage,
            sortingCriteria,
            DesignProposalEnum.allDesignProposals
        );
        this.designProposalService
            .getMunicipalityDesignProposals(dpRequestParam)
            .subscribe((data) => {
                if (!data || data.length === 0) {
                    this.createMunicipalityDesign();
                }
                this.curentDPs = data;
                this.loaded = true;
            });
    }

    private countDesignProposals() {
        this.designProposalService
            .getMunicipalityDesignProposalCount(this.projectId)
            .subscribe((data) => {
                this.designProposalsCount = data;
            });
    }

    public override scrollToTop(): void {
        const element = document.getElementsByClassName('info-message')[0];
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
}
