import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermitProjectService } from '../../../permit/permit-project/permit-project.service';

import {
    House,
    HouseService,
    PermitProject,
    ToolingButtonsEnum,
    PermitThreeService,
    SvgImageEnum,
    MediaService
} from '@furban/utilities';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PermitApprovalDialogComponent } from '../../../permit/_components/approval-dialog/approval-dialog.component';
import { PermitReviewService } from '../../../permit/_services/permit-review.service';
import { PermitReview } from '../../../permit/_models/permit-review';

@Component({
    selector: 'furban-admin-view-permit',
    templateUrl: './admin-view-permit.component.html',
    styleUrls: ['./admin-view-permit.component.scss'],
})
export class AdminViewPermitComponent implements OnInit, OnDestroy {
    public projectId: string;
    public house: House;
    public project: PermitProject;
    public addingCommentsEnabled = false;

    public showLess = true;

    private routeSubscription: Subscription;
    private permitReview: PermitReview;

    constructor(
        public viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private houseService: HouseService,
        private permitThreeService: PermitThreeService,
        private dialog: MatDialog,
        private permitReviewService: PermitReviewService,
        private mediaService: MediaService,
        private permitProjectService: PermitProjectService
    ) { }

    public get svgImageType(): typeof SvgImageEnum {
        return SvgImageEnum;
    }

    public ngOnInit(): void {
        this.routeSubscription = this.route.params.subscribe((params) => {
            this.projectId = params['id'];
            this.getHouse();
            this.getProject();
            this.getPermitReview();
        });
    }

    public getMedia(mediaId: string): string {
        return this.mediaService.getMedia(mediaId);
    }

    public ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
    }

    public buttonPressed(buttonType: string): void {
        this.permitThreeService.buttonPressed.next(
            ToolingButtonsEnum[buttonType]
        );
    }

    public toggleShowDescription(): void {
        this.showLess = !this.showLess;
    }

    public openReviewDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '632px';
        dialogConfig.data = {
            permitReview: this.permitReview,
        };
        const approvalDialog = this.dialog.open(
            PermitApprovalDialogComponent,
            dialogConfig
        );
        approvalDialog.afterClosed().subscribe((data) => {
            if (data) {
                this.permitReviewService
                    .updatePermitStatus(data)
                    .subscribe((response) => {
                        this.permitReview = response;
                    });
            }
        });
    }

    private getHouse(): void {
        this.houseService
            .getModifiedHousePolygon(this.projectId)
            .subscribe((data: House) => {
                this.setupHouse(data);
            });
    }

    private getProject(): void {
        this.permitProjectService
            .getProject(this.projectId)
            .subscribe((data) => {
                this.project = data;
            });
    }

    private getPermitReview(): void {
        this.permitReviewService
            .getMunicipalityPermitReviewForProject(this.projectId)
            .subscribe((data) => {
                this.permitReview = data;
            });
    }

    public addPinComments(): void {
        this.addingCommentsEnabled = !this.addingCommentsEnabled;
        this.permitThreeService.addPinEvents.next(this.addingCommentsEnabled);
    }

    private setupHouse(data: House): void {
        this.house = data;
        this.permitThreeService.house = House.copyHouse(data);
        this.permitThreeService.house.processedCoordinatesForThree.pop();
    }
}
