import * as THREE from 'three';

export class FurbanMeshPosition {
    public position: THREE.Vector3;
    public angle: number;
    public constructor(position?: THREE.Vector3, angle?: number) {
        this.position = position;
        this.angle = angle;
    }
}
