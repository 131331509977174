import { DashboardItem } from '../_models/dashboard-item';
import { routingConstants } from './routing-constants';

export class PermitDashboardItemsArray {
    public static createDashboardItems(projectId: string): DashboardItem[] {
        return [
            {
                id: 0,
                title: 'admin.cardsLabels.projectDetailsPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/proj-details.png')",
                path: `${routingConstants.permitProject}/${projectId}/details`,
            },
            {
                id: 1,
                title: 'admin.cardsLabels.projectAreaPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/proj-area.png')",
                path: `${routingConstants.permitProject}/${projectId}/area-definition`,
            },
            {
                id: 2,
                title: 'admin.cardsLabels.houseAreaPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/proj-area.png')",
                path: `${routingConstants.permitProject}/${projectId}/house-selection`,
            },
            {
                id: 3,
                title: 'admin.cardsLabels.houseDisplayPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/proj-area.png')",
                path: `${routingConstants.permitProject}/${projectId}/preview`,
            },
        ];
    }
}
