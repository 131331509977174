<div class="full-width full-height flex-row-center">
    <div class="new-admin-container flex-column-center">
        <form
            #uploadMediaForm="ngForm"
            name="uploadForm"
            role="form"
            [formGroup]="uploadMediaCheckForm"
            method="post"
        >
            <h4>{{ 'background.title' | translate }}</h4>

            <furban-dropdown-autocomplete
                [municipalities]="municipalities"
                [formGroup]="uploadMediaCheckForm"
                (clientSelect)="onSelectClient($event)"
            >
            </furban-dropdown-autocomplete>

            <mat-form-field
                *ngIf="projects && projects.length > 0"
                class="full-width"
                appearance="outline"
            >
                <mat-select
                    disableOptionCentering
                    name="projectDropDown"
                    placeholder="{{ 'generic.project' | translate }}"
                    formControlName="projectFormControl"
                    (valueChange)="onChangeProject($event)"
                >
                    <mat-option
                        *ngFor="let project of projects"
                        value="{{ project.projectId }}"
                    >
                        {{ project.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="isProjectSelected" class="flex-row-spaced full-width">
                <mat-form-field class="full-width" appearance="outline">
                    <input
                        matInput
                        max="100"
                        type="number"
                        placeholder="{{ 'background.xCoordinate' | translate }}"
                        formControlName="xCoordinateFormControl"
                    />
                    <mat-error
                        *ngIf="
                            uploadMediaCheckForm.get('xCoordinateFormControl')
                                .errors
                        "
                    >
                        {{ 'background.inputError' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field
                    [style.padding-left.px]="20"
                    class="full-width"
                    appearance="outline"
                >
                    <input
                        matInput
                        max="100"
                        type="number"
                        placeholder="{{ 'background.yCoordinate' | translate }}"
                        formControlName="yCoordinateFormControl"
                    />
                    <mat-error
                        *ngIf="
                            uploadMediaCheckForm.get('yCoordinateFormControl')
                                .errors
                        "
                    >
                        {{ 'background.inputError' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </form>

        <section
            *ngIf="isProjectSelected"
            class="padding-20-0 flex-column-start full-width"
        >
            <div class="list">
                <div *ngIf="!document" class="list-element">
                    <span class="material-icons green-icon">play_for_work</span>
                    <a class="upload-link" (click)="clickUploadFileLink()">{{
                        'background.uploadFile' | translate
                    }}</a>
                    <input
                        type="file"
                        [hidden]="!document"
                        id="file-upload-input"
                        (change)="fileChange($event)"
                        accept=".glb"
                    />
                </div>

                <ng-container *ngIf="document">
                    <div class="list-element flex-row-spaced">
                        <ng-container>
                            <span class="material-icons green-icon"
                                >attach_file</span
                            >
                            <a (click)="downloadDocument(document)">{{
                                document.fileName
                            }}</a>
                            <mat-slide-toggle
                                [style.margin-right.px]="16"
                                class="material-icons"
                                [checked]="checked"
                                (click)="onToggleClick(document)"
                            >
                            </mat-slide-toggle>
                            <span
                                [style.margin-right.px]="16"
                                (click)="
                                    updateBackgroundMediaCoordinates(document)
                                "
                                class="material-icons"
                                >save</span
                            >
                            <span
                                (click)="deleteWithWarning(document)"
                                class="material-icons remove"
                                >delete</span
                            >
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </section>
    </div>
</div>
