<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'user.profile.profileTitle' | translate }}</h2>
    <button class="ghost-button" (click)="closeProfileModal()">
        <i class="material-icons">close</i>
    </button>
</div>

<mat-dialog-content class="no-overflow">
    <div class="flex-row-spaced wrap" *ngIf="state === 'profile'">
        <div class="profile-image custom-image background-center">
            <furban-avatar-picture [name]="userProfile.screenName" [size]="160" [mediaId]="data.image">
            </furban-avatar-picture>
            <div class="overlay-image" (click)="uploadImage()">
                <i class="material-icons"> add_a_photo </i>
                <a>{{ 'user.profile.uploadProfilePicture' | translate }}</a>
                <input id="photoInput" class="hide custom-photo-input" type="file" (change)="fileChangeListener($event)"
                    accept="{{ fileExtentionFormat }}" />
            </div>
        </div>
        <form [formGroup]="userProfileCheckForm" class="user-profile">
            <mat-form-field class="full-width grey" appearance="outline">
                <mat-label>
                    {{ 'user.profile.displayName' | translate }}
                </mat-label>
                <input required matInput #displayName maxlength="{{ userDisplayNameMaxLength }}"
                    placeholder="{{ 'user.profile.displayName' | translate }}"
                    formControlName="displayNameFormControl" />
                <mat-hint align="end">{{ displayName.value.length }} /
                    {{ userDisplayNameMaxLength }}</mat-hint>
                <mat-error *ngIf="hasControlRequiredError('displayNameFormControl',userProfileCheckForm)">
                    {{ 'user.profile.displayNameRequiredText' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlIsHtmlError('displayNameFormControl',userProfileCheckForm)">
                    {{ 'validators.noHTML' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlWhitespacesError('displayNameFormControl',userProfileCheckForm)">
                    {{ 'validators.noWhiteSpaces' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlIsCodeNamedError('displayNameFormControl',userProfileCheckForm)">
                    {{ 'validators.noCodeAsUsername' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlMaxLengthError('displayNameFormControl',userProfileCheckForm)">
                    {{ 'user.profile.displayNamemaxLengthText' | translate }}
                </mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="flex-row-start padding-20-0" *ngIf="state === 'profile'">
        <furban-user-settings class="full-width" #userSettingsComponent [userProfile]="userProfile">
        </furban-user-settings>
    </div>
    <img-cropper *ngIf="state === 'cropImage'" #cropperUserProfile class="image-cropper"
        [class.image-cropper-visible]="state === 'cropImage'" [image]="data" [settings]="cropperSettingsUserProfile">
    </img-cropper>
</mat-dialog-content>

<div class="buttons-right-container">
    <ng-container *ngIf="state === 'cropImage'">
        <button class="secondary-button" (click)="cancelImageCrop()">
            <h6>{{ 'generic.cancelBtn' | translate }}</h6>
        </button>
        <button class="primary-button" (click)="cropImage()">
            <h6>{{ 'generic.okBtn' | translate }}</h6>
        </button>
    </ng-container>

    <ng-container *ngIf="state === 'profile'">
        <button class="secondary-button" (click)="closeProfileModal()">
            <h6>{{ 'generic.cancelBtn' | translate }}</h6>
        </button>
        <button class="primary-button" (click)="saveProfile()">
            <h6>{{ 'user.profile.okBtn' | translate }}</h6>
        </button>
    </ng-container>
</div>