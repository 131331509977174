import { Component, OnInit, ViewContainerRef, ViewChild, Input } from '@angular/core';

import {
    Project,
    ProjectStorageService,
    routingConstants,
} from '@furban/utilities';
import { Router } from '@angular/router';
import { DesignProposalsComponent } from '../../../project-shared/design-proposals/design-proposals.component';

@Component({
    selector: 'furban-app-citizen-proposals',
    templateUrl: './citizen-proposals.component.html',
    styleUrls: ['./citizen-proposals.component.scss'],
})
export class CitizenProposalsComponent implements OnInit {
    @ViewChild('designProposalsComponent') designProposalsComponent: DesignProposalsComponent;

    public project: Project;

    constructor(
        public viewContainerRef: ViewContainerRef,
        private projectStorageService: ProjectStorageService,
        private router: Router
    ) {
        projectStorageService.projectChanged.subscribe((item) => {
            this.project = item;
        });
    }

    ngOnInit() {
        this.project = this.projectStorageService.getCurentProject();
    }

    onScrollDown() {
        this.designProposalsComponent.onParentScrollDown();
    }

    goToDashboard() {
        this.router.navigate([routingConstants.dashboard]);
    }
}
