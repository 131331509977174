import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { XY } from '../_models/xy';
import { MapService } from '../_services/map.service';
import { GeocodingProvider } from '../_enum/geocoding-provider.enum';
import { PdokOption } from '../_models/pdok-option.model';
import { NominatimOption } from '../_models/nominatim-option.model';

@Component({
    selector: 'furban-address-search',
    templateUrl: './address-search.component.html',
    styleUrls: ['./address-search.component.scss'],
})
export class AddressSearchComponent implements OnInit {
    @Input()
    public geocodingProvider = GeocodingProvider.pdok;
    @Output()
    public changeAddress: EventEmitter<XY> = new EventEmitter();
    public options: any[];
    public adressFormGroup: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private mapService: MapService
    ) { }

    public ngOnInit(): void {
        this.adressFormGroup = this.formBuilder.group({
            location: [''],
        });
        this.adressFormGroup.controls['location'].valueChanges.subscribe(
            (value) => {
                this.getSearchAdresses(value);
            }
        );
    }

    public getDisplayName(option: PdokOption | NominatimOption): string {
        return this.geocodingProvider === GeocodingProvider.nominatim ? (option as NominatimOption).display_name : (option as PdokOption).weergavenaam;
    }

    public selectMapLocation(option: PdokOption | NominatimOption): void {
        if (this.geocodingProvider === GeocodingProvider.nominatim) {
            this.selectMapLocationForOpenMap(option as NominatimOption);
            return;
        }
        this.selectMapLocationForNLMap(option as PdokOption);
    }

    private selectMapLocationForNLMap(option: PdokOption): void {
        if (!option || (option && !option.id)) {
            return;
        }

        this.mapService.getAdressCoordinates(option.id).subscribe((data) => {
            const centroid = data.response.docs[0].centroide_rd;
            const sub1 = centroid.substring(6);
            const sub2 = sub1.substring(0, sub1.length - 1);

            const xy = sub2.split(' ');
            const coordinate = new XY(xy[0], xy[1]);

            this.changeAddress.emit(coordinate);
        });
    }

    private selectMapLocationForOpenMap(option: NominatimOption): void {
        if (!option || (option && !option.place_id)) {
            return;
        }
        const coordinates = new XY(Number(option.lat), Number(option.lon));
        this.changeAddress.emit(coordinates);
    }

    private getSearchAdresses(value: string): void {
        if (this.geocodingProvider === GeocodingProvider.nominatim) {
            this.getSearchAddressesForOpenMap(value);
            return;
        }
        this.getSearchAddressesForNLMap(value);
    }

    private getSearchAddressesForNLMap(value: string): void {
        this.mapService.getSearchAdresses(value).subscribe((data) => {
            if (!data?.response?.docs) {
                this.options = [];
                return;
            }
            this.options = data?.response?.docs;
        });
    }

    private getSearchAddressesForOpenMap(value: string): void {
        this.mapService.getSearchAdressesForOpenMap(value).subscribe((data) => {
            this.options = data;
        });
    }


}
