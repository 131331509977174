import { FurbanUtil } from '../helpers/furbanUtil';
import { ThreeUtils } from '../_three-helpers/three.utils';
import {
    ASSET_WIDTH,
    OBJECTS_WIDTHS,
} from '../_constants/three-objects-widths';
import { PathObject } from '../_models/path-object';
import { Project3dModel } from '../_models/project-3d-model';
import { FurbanMeshPosition } from '../_models/furban-mesh-position';
import { MathUtil } from './math.util';
import { ProjectAndPathIds } from '../_models/project-and-path-id';
import { Vector3, Quaternion, Euler } from 'three';
import { ThreeInstance } from '../_three-helpers/three-instance';
import { HouseSceneConfig } from '../_models/house-config';
import { PermitAsset } from '../_models/permit-asset';
import { HouseAsset } from '../_models/house-asset';

export class RowOfObjects {
    piecesPathObjects: PathObject[];
    totalPrice: number;
    objectsSkipped: boolean;

    public constructor(
        piecesPathObjects: PathObject[],
        totalPrice: number,
        skipped: boolean
    ) {
        this.piecesPathObjects = piecesPathObjects;
        this.totalPrice = totalPrice;
        this.objectsSkipped = skipped;
    }

    //refactor
    public static getPathObjectsPlacedInARow(
        threeInstance: ThreeInstance,
        wholeObjectModel: Project3dModel,
        neededIds: ProjectAndPathIds,
        isAdminOrPioneer: boolean,
        designProposalId?: string
    ): RowOfObjects {
        const pieceCoordinates = JSON.parse(wholeObjectModel.freeshapePoints);
        const pieceWidth = OBJECTS_WIDTHS.get(
            wholeObjectModel.furban3DModel.objectLookId
        );

        const repeatPiecesNumber = this.computeObjectsNumber(
            pieceCoordinates,
            pieceWidth
        );

        const pieces: PathObject[] = [];
        const angleRelativeToHorizontalAxisInDegrees =
            FurbanUtil.getRotationAngleInDegreesRelativeToHorizontalAxis(
                pieceCoordinates[0],
                pieceCoordinates[1]
            );

        const pieceFurbanPosition = new FurbanMeshPosition();
        pieceFurbanPosition.angle = angleRelativeToHorizontalAxisInDegrees;

        const angleRelativeToHorizontalAxisInRadians = MathUtil.deg2rad(
            angleRelativeToHorizontalAxisInDegrees
        );
        const unitToAddX =
            Math.cos(angleRelativeToHorizontalAxisInRadians) * (pieceWidth / 2);
        const unitToAddZ =
            Math.sin(angleRelativeToHorizontalAxisInRadians) * (pieceWidth / 2);

        let xCoordinate = pieceCoordinates[0].x + unitToAddX;
        let zCoordinate = pieceCoordinates[0].z + unitToAddZ;
        let yCoordinate = pieceCoordinates[0].y;

        let coordsWithDefaultY = new Vector3(
            xCoordinate,
            yCoordinate,
            zCoordinate
        );
        let coordsWithIntersectedY =
            ThreeUtils.getPositionConsideringCustomDesign(
                coordsWithDefaultY,
                threeInstance.customDesign
            );
        let skipped = false;
        const canvasCoordinates = ThreeUtils.convertFromPointXYTo3D(
            threeInstance.currentCoordinates
        );
        let addedObjects = 0;
        for (
            let pieceIndex = 0;
            pieceIndex < repeatPiecesNumber;
            ++pieceIndex
        ) {
            const pointToCheck = new Vector3(
                coordsWithIntersectedY.x,
                0,
                -coordsWithIntersectedY.z
            );

            if (
                !wholeObjectModel.furban3DModel?.placeOutside &&
                !ThreeUtils.isPointInsideThePolygon(
                    pointToCheck,
                    canvasCoordinates
                )
            ) {
                skipped = true;
                continue;
            }
            addedObjects++;
            pieceFurbanPosition.position = coordsWithIntersectedY;
            const pathObject = ThreeUtils.convertProject3DModelToPathObject(
                wholeObjectModel,
                pieceFurbanPosition,
                neededIds,
                isAdminOrPioneer,
                designProposalId
            );
            pieces.push(pathObject);
            xCoordinate = xCoordinate + 2 * unitToAddX;
            zCoordinate = zCoordinate + 2 * unitToAddZ;
            yCoordinate = yCoordinate;
            coordsWithDefaultY = new Vector3(
                xCoordinate,
                yCoordinate,
                zCoordinate
            );
            coordsWithIntersectedY =
                ThreeUtils.getPositionConsideringCustomDesign(
                    coordsWithDefaultY,
                    threeInstance.customDesign
                );
        }
        const totalPrice = addedObjects * wholeObjectModel.price;

        return new RowOfObjects(pieces, totalPrice, skipped);
    }

    public static getAssetsPlacedInARow(
        threeInstance: HouseSceneConfig,
        asset: PermitAsset,
        pieceCoordinates: Vector3[],
        houseId: string
    ): HouseAsset[] {
        const pieceWidth = ASSET_WIDTH.get(asset.objectLookId);
        const repeatPiecesNumber = this.computeObjectsNumber(
            pieceCoordinates,
            pieceWidth
        );

        const pieces: HouseAsset[] = [];
        const angleRelativeToHorizontalAxisInDegrees =
            FurbanUtil.getRotationAngleInDegreesRelativeToHorizontalAxis(
                pieceCoordinates[0],
                pieceCoordinates[1]
            );

        const pieceFurbanPosition = new FurbanMeshPosition();
        pieceFurbanPosition.angle = angleRelativeToHorizontalAxisInDegrees;

        const angleRelativeToHorizontalAxisInRadians = MathUtil.deg2rad(
            angleRelativeToHorizontalAxisInDegrees
        );

        const quaternionForObjects = new Quaternion();
        quaternionForObjects.setFromAxisAngle(
            new Vector3(0, 1, 0),
            -angleRelativeToHorizontalAxisInRadians
        );
        const objectsEuler = new Euler().setFromQuaternion(
            quaternionForObjects,
            'XYZ'
        );

        const unitToAddX =
            Math.cos(angleRelativeToHorizontalAxisInRadians) * (pieceWidth / 2);
        const unitToAddZ =
            Math.sin(angleRelativeToHorizontalAxisInRadians) * (pieceWidth / 2);

        let xCoordinate = pieceCoordinates[0].x + unitToAddX;
        let zCoordinate = pieceCoordinates[0].z + unitToAddZ;
        let yCoordinate = pieceCoordinates[0].y;

        let coordsWithDefaultY = new Vector3(
            xCoordinate,
            yCoordinate,
            zCoordinate
        );
        let skipped = false;
        const canvasCoordinates = ThreeUtils.convertFromPointXYTo3D(
            threeInstance.currentCoordinates
        );

        for (
            let pieceIndex = 0;
            pieceIndex < repeatPiecesNumber;
            ++pieceIndex
        ) {
            const pointToCheck = new Vector3(
                coordsWithDefaultY.x,
                0,
                -coordsWithDefaultY.z
            );

            if (
                !ThreeUtils.isPointInsideThePolygon(
                    pointToCheck,
                    canvasCoordinates
                )
            ) {
                skipped = true;
                continue;
            }

            pieceFurbanPosition.position = coordsWithDefaultY;
            const defaultScale = new Vector3(1, 1, 1);

            const houseAsset = ThreeUtils.convertPermitAssetToHouseAsset(
                asset,
                JSON.stringify(pieceFurbanPosition.position),
                houseId,
                JSON.stringify(objectsEuler),
                JSON.stringify(defaultScale)
            );

            pieces.push(houseAsset);
            xCoordinate = xCoordinate + 2 * unitToAddX;
            zCoordinate = zCoordinate + 2 * unitToAddZ;
            yCoordinate = yCoordinate;
            coordsWithDefaultY = new Vector3(
                xCoordinate,
                yCoordinate,
                zCoordinate
            );
        }
        return pieces;
    }

    private static computeObjectsNumber(
        pieceCoordinates: Vector3[],
        pieceWidth: number
    ) {
        const wholeObjectWidth = Math.sqrt(
            (pieceCoordinates[0].x - pieceCoordinates[1].x) *
                (pieceCoordinates[0].x - pieceCoordinates[1].x) +
                (pieceCoordinates[0].z - pieceCoordinates[1].z) *
                    (pieceCoordinates[0].z - pieceCoordinates[1].z)
        );
        const repeatPiecesNumber =
            Math.trunc(wholeObjectWidth / pieceWidth) + 1;
        return repeatPiecesNumber;
    }
}
