<furban-avatar-picture #trigger="matMenuTrigger"
                       matTooltip="{{ 'navigation.admin.profile' | translate }}"
                       [matMenuTriggerFor]="appMenu"
                       [name]="userProfile.screenName"
                       [size]="40"
                       [mediaId]="getUserMedia(userProfile.mediaId)">
</furban-avatar-picture>

<mat-menu #appMenu="matMenu"
          yPosition="above">

    <ng-container *ngIf="hasAdministrativeRole">
        <button mat-menu-item
                (click)="openAdminProfileDialog('profile')">
            <h6>
                {{'admin.profile.displayNameAndPhotoLabel'| translate }}
            </h6>
        </button>
        <button mat-menu-item
                (click)="openAdminProfileDialog('password')">
            <h6>{{ 'admin.profile.passwordLabel' | translate }}</h6>
        </button>
    </ng-container>

    <ng-container *ngIf="isCitizenOrExpertOrCollaborator">
        <button mat-menu-item
                (click)="openUserProfileDialog()">
            <h6>
                {{'admin.profile.displayNameAndPhotoLabel'| translate}}
            </h6>
        </button>
        <button mat-menu-item
                (click)="deleteUserProfile()">
            <h6>{{ 'user.profile.deleteProfile' | translate }}</h6>
        </button>
    </ng-container>

    <button mat-menu-item
            [matMenuTriggerFor]="multilanguage">
        <h6>
            {{'generic.changeLanguage'| translate }}
        </h6>
    </button>
    <mat-menu #multilanguage="matMenu">
        <button mat-menu-item
                (click)="onLanguageChanged('nl-NL')">
            <img with="20"
                 height="20"
                 src="../../../assets/icons/flag-nl.svg" />
            <span>
                {{'navigation.language.dutch'|translate}}
            </span>
        </button>
        <button mat-menu-item
                (click)="onLanguageChanged('en-US')">
            <img with="20"
                 height="20"
                 src="../../../assets/icons/flag-gb.svg" />
            <span>
                {{'navigation.language.english'|translate}}
            </span>
        </button>
        <button mat-menu-item
                (click)="onLanguageChanged('ro-RO')">
            <img with="20"
                 height="20"
                 src="../../../assets/icons/flag-ro.svg" />
            <span>
                {{'navigation.language.romanian'|translate}}
            </span>
        </button>
    </mat-menu>

    <button mat-menu-item
            (click)="logout()">
        <h6>{{ 'navigation.logout' | translate }}</h6>
    </button>
</mat-menu>