<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'user.projectMenu.generateGreenObjects' | translate }}</h2>
    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons">close</span>
    </button>
</div>

<mat-dialog-content *ngIf="availableGreenObjects">
    <form
        class="padding-20-0"
        name="greenForm"
        [formGroup]="greenObjectCheckForm"
    >
        <div
            class="full-width"
            *ngFor="let greenObject of availableGreenObjects"
        >
            <div class="flex-row-start">
                <img
                    class="picture-size"
                    [src]="
                        composeImageString(
                            greenObject.furban3DModel.objectLookId
                        )
                    "
                    alt="ProjPic"
                />
                <div class="flex-column-start custom-width-90">
                    <h6>{{ greenObject.furban3DModel.label }}</h6>
                    <div class="flex-default full-width">
                        <mat-slider
                            class="full-width"
                            [max]="10"
                            [min]="0"
                            [formControlName]="
                                greenObject.furban3DModel.objectLookId
                            "
                            [(ngModel)]="
                                greenObject.furban3DModel.greenObjectsToGenerate
                            "
                        >
                        </mat-slider>
                        <mat-form-field
                            class="grey"
                            floatLabel="always"
                            appearance="outline"
                            [style.width.%]="15"
                            [style.margin-left.px]="12"
                        >
                            <mat-label>{{
                                'user.projectMenu.inputLabel' | translate
                            }}</mat-label>
                            <input
                                matInput
                                type="number"
                                [formControlName]="
                                    greenObject.furban3DModel.objectLookId
                                "
                                [(ngModel)]="
                                    greenObject.furban3DModel
                                        .greenObjectsToGenerate
                                "
                            />
                            <mat-error>
                                {{
                                    'user.projectMenu.generateGreenObjectsValidation'
                                        | translate
                                }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closeDialog()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="saveCustomObject()">
        <h6>{{ 'generic.save' | translate }}</h6>
    </button>
</div>
