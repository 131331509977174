<header>
    <img
        src="../../assets/images/furban_logo.png"
        (click)="navigateToHomepage()"
    />
    <section class="flex-row-end">
        <button
            class="secondary-button"
            [style.margin-left.px]="20"
            (click)="loginWithCredentials()"
        >
            <h6>{{ 'homepage.iHaveProject' | translate }}</h6>
        </button>
        <button
            class="primary-button green"
            [style.margin-left.px]="20"
            (click)="loginWithCode()"
        >
            <h6>{{ 'homepage.iHaveCode' | translate }}</h6>
        </button>
    </section>
</header>
