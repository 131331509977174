import {
    Component,
    ViewChild,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ElementRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ACCEPTED_FORMATS_POINT } from '../_constants/file-upload-constants';
import { FileExtensionEnum } from '../_enum/file-extension.enum';
import { UploadedObjectTypeEnum } from '../_enum/uploaded-object-type.enum';
import { ObjAndTextureFiles } from '../_models/obj-and-texture-file';
import { Project3DUpload } from '../_models/project-3d-upload';
import { FileUploadService } from '../_services/file-upload.service';
import { convertUploadedObjectTypeEnumToNameEnum } from '../_utils/enum-converter.util';

@Component({
    selector: 'furban-drag-upload',
    templateUrl: './drag-upload.component.html',
    styleUrls: ['./drag-upload.component.scss'],
})
export class DragUploadComponent {
    public errorCode: string;
    public uploadedFile: File;

    public objFile: File;
    public textureFile: File;
    public glbFile: File;
    public isSingleFile: boolean;
    public acceptedFormatsPoint = ACCEPTED_FORMATS_POINT;

    @Input() project3DUpload?: Project3DUpload;
    @Input() maxSize?: number;
    @Input() acceptedFormats?: string[];
    @Output() uploadFile: EventEmitter<File> = new EventEmitter();
    @Output() uploadTextureFile: EventEmitter<ObjAndTextureFiles> = new EventEmitter();
    @Output() project3DUploadDelete: EventEmitter<Project3DUpload> = new EventEmitter();

    @ViewChild('file') file: ElementRef;

    constructor(private translateService: TranslateService, private fileUploadService: FileUploadService) { }

    onFileChange(event: any) {
        const files: FileList = event.target.files;
        this.prepareFiles(files);
    }

    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
        event.preventDefault();
    }

    @HostListener('dragenter', ['$event']) onDragEnter(event: DragEvent) {
        event.preventDefault();
    }

    @HostListener('dragend', ['$event']) onDragEnd(event: DragEvent) {
        event.preventDefault();
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
        event.preventDefault();
    }

    @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files && this.isUploadEnabled()) {
            this.prepareFiles(event.dataTransfer.files);
        }
    }

    public get disableDrop(): boolean {
        return !!this.uploadedFile;
    }

    public get isUndergroundUploaded(): boolean {
        return this.fileUploadService.isUndergroundUploaded;
    }

    public get isCustomObjectUploaded(): boolean {
        return this.fileUploadService.isCustomObjectUploaded;
    }

    public get isFixedObjectUploaded(): boolean {
        return this.fileUploadService.isFixedObjectUploaded;
    }

    public prepareFiles(files: FileList): void {
        this.uploadedFile = files[0];
        this.errorCode = '';
        Array.from(files).forEach((file) => {
            if (!this.isAcceptedFormat(file)) {
                this.errorCode = 'errors.extensionNotAllowed';
                this.uploadedFile = null;
                return;
            }
            if (!this.isBelowMaxSize(file)) {
                this.errorCode = 'errors.exceedMaxSize';
                this.uploadedFile = null;
                return;
            }
        });

        if (this.errorCode !== '') {
            return;
        }

        this.checkFiles(files);
    }

    public removeFile(): void {
        if (this.project3DUpload) {
            this.project3DUploadDelete.emit(this.project3DUpload);
            this.project3DUpload = null;
        }

        if (this.uploadedFile) {
            this.uploadedFile = null;
            this.uploadFile.emit(this.uploadedFile);
        }
    }

    public updateCustomObject(): void {
        const uploadedObjectType = convertUploadedObjectTypeEnumToNameEnum(this.project3DUpload.objectType);
        this.fileUploadService.uploadedObjectUpdate.next(uploadedObjectType);
    }

    public onFileUploadClick(): void {
        if (this.disableDrop) {
            return;
        }

        this.file.nativeElement.click();
    }

    public isUploadEnabled(): boolean {
        return !this.uploadedFile && !this.project3DUpload;
    }

    public displayName(): string {
        const translationCode = this.project3DUpload.objectType === UploadedObjectTypeEnum.customObject ? 'dragUpload.customObjectUpload'
            : this.project3DUpload.objectType === UploadedObjectTypeEnum.underground ? 'dragUpload.undergroundObjectUpload' : 'dragUpload.fixedObjectUpload';
        return this.translateService.instant(translationCode);
    }

    private isBelowMaxSize(file: File): boolean {
        return !(this.maxSize && this.maxSize < file.size);
    }

    private isAcceptedFormat(file: File): boolean {
        if (!this.acceptedFormats || this.acceptedFormats.length === 0) {
            return true;
        }

        const fileExtension = file?.name?.substr(
            file?.name?.lastIndexOf('.') + 1
        );

        return this.acceptedFormats.includes(fileExtension);
    }

    private checkFiles(files: FileList): void {
        this.glbFile = null;
        this.objFile = null;
        this.textureFile = null;

        switch (files.length) {
            case 0: {
                this.throwError('errors.noFileAdded');
                return;
            }
            case 1: {
                this.checkOneFile(files[0]);
                return;
            }
            case 2: {
                this.checkTwoFiles(files[0], files[1]);
                return;
            }
            default: {
                this.throwError('errors.maximumTwoFiles');
                return;
            }
        }
    }

    private throwError(errorCode: string): void {
        this.errorCode = errorCode;
        this.uploadedFile = null;
    }

    private checkOneFile(file: File): void {
        this.setFileType(file);
        if (!this.glbFile) {
            this.throwError('errors.noGlbFileOrObjWithTexture');
            return;
        }
        this.isSingleFile = true;
        this.uploadFile.emit(this.glbFile);
    }

    private checkTwoFiles(file1: File, file2: File): void {
        this.setFileType(file1);
        this.setFileType(file2);
        if (!this.objFile || !this.textureFile) {
            this.throwError('errors.noGlbFileOrObjWithTexture');
            return;
        }
        this.isSingleFile = false;
        const uploadedFiles = new ObjAndTextureFiles(
            this.objFile,
            this.textureFile
        );
        this.uploadTextureFile.emit(uploadedFiles);
    }

    private setFileType(file: File): void {
        const extension = file.name?.split('.')?.pop().trim().toLowerCase();
        switch (extension) {
            case FileExtensionEnum.glb:
                this.glbFile = file;
                break;
            case FileExtensionEnum.obj:
                this.objFile = file;
                break;
            case FileExtensionEnum.mtl:
                this.textureFile = file;
                break;
            default:
                return null;
        }
    }
}
