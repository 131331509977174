import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { FurbanLoaderService } from './furban-loader.service';
import { FurbanLoaderState } from './furban-loaderState';

@Component({
    selector: 'furban-loader',
    templateUrl: './furban-loader.component.html',
    styleUrls: ['./furban-loader.component.scss'],
})
export class FurbanLoaderComponent implements OnInit, OnDestroy {
    public show = false;
    public half = false;
    private subscription: Subscription;

    constructor(
        private furbanLoaderService: FurbanLoaderService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.subscription =
            this.furbanLoaderService.furbanLoaderState.subscribe(
                (state: FurbanLoaderState) => {
                    this.show = state.show;
                    this.half = state.half;
                    // ExpressionChangedAfterItHasBeenCheckedError:
                    // Expression has changed after it was checked. Previous value: 'loader-hidden: true'.
                    // Current value: 'loader-hidden: false'.
                    // at FurbanLoaderComponent.html:1
                    this.cdr.detectChanges();
                }
            );
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
