import {
    Component,
    Input,
    OnInit,
    ViewContainerRef,
    Output,
    EventEmitter,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from './comments.service';
import { DesignProposal } from '../_models/design-proposal';
import { Project } from '../_models/project';
import { FurbanUtil } from '../helpers/furbanUtil';
import { AuthService } from '../_services/auth.service';
import { ModalManager } from '../furban-modal/furban-modal.manager';
import { ReportService } from '../_services/report.service';
import { CustomToasterComponent } from '../custom-toaster/custom-toaster.component';
import { Comment } from '../_models/comment';
import { Report } from '../_models/report';
import { CustomToasterService } from '../_services/custom-toaster.service';
import { Router } from '@angular/router';
import { routingConstants } from '../_constants/routing-constants';
import { CommentFormComponent } from '../comment/comment-form/comment-form.component';
import { MediaService } from '../_services/media.service';

@Component({
    selector: 'furban-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {
    @ViewChild('commentForm') commentFormComponent: CommentFormComponent;

    @Input() public project?: Project;
    @Input() public commentId?: number;
    @Input() public isDialog: boolean;
    @Input() public parentViewContainerRef: ViewContainerRef;
    @Input() public preventSelectComment?: boolean;
    /* This input has value 0 if comments are added into the modal and 1 if comments are added in the sidebar*/
    @Input() public type: number;
    @Output() public reportOutput = new EventEmitter<Comment>();

    public sortedComments: Comment[] = [];
    public noOfReportsToPrevent = 3;
    public emojiOpened = false;

    private _dp: DesignProposal;
    private isMobile = FurbanUtil.isMobile();

    @Input()
    set dp(designProposal: DesignProposal) {
        this._dp = designProposal;
        this.sortedComments = this.sortComments(this._dp.comments);
    }

    get dp(): DesignProposal {
        return this._dp;
    }

    constructor(
        public viewContainerRef: ViewContainerRef,
        public commentsService: CommentsService,
        public authService: AuthService,
        private translateService: TranslateService,
        private modal: ModalManager,
        private customToasterService: CustomToasterService,
        private reportService: ReportService,
        private mediaService: MediaService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.scrollCommentsContainer();
        this.sortedComments = this.sortComments(this.dp.comments);
    }

    public getUserMedia(mediaId: string): string {
        return this.mediaService.getUserMedia(mediaId);
    }

    public onSaveComment(comment: Comment): void {
        if (comment.parentCommentId) {
            const parentComment = this.sortedComments.find(
                (comm) => comm.commentId === comment.parentCommentId
            );
            parentComment.childComments.push(comment);
        } else {
            comment.childComments = [];
            this.sortedComments.push(comment);
        }
        this.dp.comments.push(comment);
        this.scrollCommentsContainer();
    }

    public onToggleEmoji(emojiState: boolean): void {
        this.emojiOpened = emojiState;
    }

    public scrollCommentsContainer(): void {
        setTimeout(function () {
            const highlightedComment = document.getElementsByClassName(
                'reported-comment-highlight'
            );
            if (highlightedComment && highlightedComment.length > 0) {
                highlightedComment[0].scrollIntoView();
            } else {
                let container =
                    document.getElementsByClassName('mat-dialog-content')[0];
                if (!container) {
                    container = document.getElementsByClassName(
                        'vertical-scrollable'
                    )[0];
                }
                container.scrollTop = container.scrollHeight;
            }
        }, 100);
    }

    public report(comment: Comment): void {
        this.reportOutput.emit(comment);
    }

    public userDelete(userProfileId: string) {
        for (let i = this.sortedComments.length; i--;) {
            if (
                this.sortedComments[i].userProfile.userProfileId !==
                userProfileId
            ) {
                this.removeChildComments(this.sortedComments[i], userProfileId);
            } else {
                this.sortedComments.splice(i, 1);
            }
        }
    }

    public get isCitizenDesign(): boolean {
        return this.project.isCitizenDesigns;
    }

    public deleteComment(comment: Comment): void {
        this.modal
            .showModal(
                this.parentViewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'comments.commentDeleteWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.deleteCommentConfirmation(comment);
                }
            });
    }

    public deleteCommentConfirmation(comment: Comment): void {
        this.commentsService.deleteComment(comment).subscribe((data) => {
            if (this.authService.isAdmin() && comment.reportsCount > 0) {
                this.reportService
                    .getUserProfilesForComment(comment.commentId)
                    .subscribe((userProfiles) => {
                        if (userProfiles && userProfiles.length > 0) {
                            /* TO-DO chat sendAdministrationMessageFromChat  - use translation key: "user.chat.unproperDPComment" */
                        }
                    });
            }
            this.commentsService.removeCommentEmitter.emit(comment);
            this.spliceCommentAfterDelete(comment);
            this.commentsService.highlightedComment = null;
            this.commentsService.highlightedCommentEmitter.emit(null);
            this.showSuccessToaster('comments.deleteCommentSuccess');
        });
    }

    public isAuthUserComment(comment: Comment): boolean {
        return (
            comment.userProfile.userProfileId ===
            this.authService.userProfile.userProfileId
        );
    }

    public deleteReporting(comment: Comment): void {
        this.modal
            .showModal(
                this.parentViewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'report.reportResetMessage',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.deleteReportCallback(comment);
                }
            });
    }

    public deleteReportCallback(comment: Comment): void {
        const report = new Report();
        report.reportType = 1;
        report.reportCommentId = comment.commentId;
        this.reportService
            .getUserProfilesForComment(comment.commentId)
            .subscribe((data) => {
                const userProfiles = data;
                this.reportService.deleteReport(report).subscribe(() => {
                    comment.reportsCount = 0;
                    /* TO-DO chat sendAdministrationMessageFromChat - use translation key: "user.chat.unproperDPCommentWasNot"  */
                    this.successDeletedReportMessage();
                });
            });
    }

    public successDeletedReportMessage(): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            this.translateService.instant('report.reportResetSuccess'),
            1000
        );
    }

    private highlightComment(comment: Comment): void {
        this.commentsService.highlightedComment = comment.position
            ? comment
            : null;
        this.commentsService.highlightedCommentEmitter.emit(
            this.commentsService.highlightedComment
        );
    }

    public selectComment(comment: Comment): void {
        if (this.preventSelectComment) {
            return;
        }
        this.highlightComment(comment);

        if (!this.isMobile || !comment?.position) {
            return;
        }
        this.commentsService.selectCommentEmitter.emit(true);
    }

    public preventQuickViewClose(event: MouseEvent, comment: Comment): void {
        let parentComment = comment;
        if (!event) {
            return;
        }
        if (comment.parentCommentId) {
            parentComment = this.sortedComments.find(
                (comm) => comm.commentId === comment.parentCommentId
            );
        }
        this.highlightComment(parentComment);
        event.stopPropagation();
    }

    public shouldDisplayReportInfo(comment: Comment): boolean {
        return (
            comment &&
            comment.reportsCount > this.noOfReportsToPrevent - 1 &&
            !this.authService.isAdmin()
        );
    }

    public shouldDisplayForm(): boolean {
        const currentRouteIsPublic =
            this.router.url === routingConstants.publicDesignProposal;
        return !this.project.ended || !currentRouteIsPublic;
    }

    public reply(comment: Comment): void {
        this.commentFormComponent.parentComment = comment;
        this.commentFormComponent.commentForm
            .get('commentText')
            .setValue(`@${comment.userProfile.screenName} `);
        this.commentFormComponent.commentRef.nativeElement.focus();
    }

    public checkIfNoComments(): boolean {
        return (
            !this.sortedComments ||
            (this.sortedComments && this.sortedComments.length === 0)
        );
    }

    private removeChildComments(
        parentComment: Comment,
        userProfileId: string
    ): void {
        if (
            !parentComment.childComments ||
            parentComment.childComments.length === 0
        ) {
            return;
        }

        for (let i = parentComment.childComments.length; i--;) {
            if (
                parentComment.childComments[i].userProfile.userProfileId ===
                userProfileId
            ) {
                parentComment.childComments.splice(i, 1);
            }
        }
    }

    private spliceCommentAfterDelete(comment: Comment) {
        const parentIndex = this.sortedComments.indexOf(comment);

        if (parentIndex > -1) {
            this.sortedComments.splice(this.sortedComments.indexOf(comment), 1);
        } else {
            this.getChildCommentAndSplice(comment);
        }
        const indexToSplice = this.dp.comments.indexOf(comment);
        if (indexToSplice > -1) {
            this.dp.comments.splice(indexToSplice, 1);
        }
    }

    private getChildCommentAndSplice(commentToDelete: Comment): void {
        for (const comment of this.sortedComments) {
            if (!comment.childComments || comment.childComments.length === 0) {
                continue;
            }
            const index = comment.childComments.indexOf(commentToDelete);
            if (index > -1) {
                comment.childComments.splice(index, 1);
                break;
            }
        }
    }

    private showSuccessToaster(text: string): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            text,
            2000
        );
    }

    private splitComments(
        allComments: Comment[],
        isParentComment: any
    ): Comment[][] {
        return allComments.reduce(
            ([parentComments, childComments], elem) => {
                return isParentComment(elem)
                    ? [[...parentComments, elem], childComments]
                    : [parentComments, [...childComments, elem]];
            },
            [[], []]
        );
    }

    private sortComments(allComments: Comment[]): Comment[] {
        if (allComments.length === 0) {
            return [];
        }

        const [parentComments, childComments] = this.splitComments(
            allComments,
            (e) => e.parentCommentId === null
        );

        const sortedComments: Comment[] =
            this.setParentBaseSortingComments(parentComments);

        childComments.forEach((childComment) => {
            const parentComment = sortedComments.find(
                (comm) => comm.commentId === childComment.parentCommentId
            );
            parentComment.childComments.push(childComment);
        });

        return sortedComments;
    }

    private setParentBaseSortingComments(parentComments: Comment[]): Comment[] {
        const sortedComments: Comment[] = [];

        parentComments.forEach((element) => {
            element.childComments = [];
            sortedComments.push(element);
        });

        return sortedComments;
    }
}
