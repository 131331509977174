<form class="padding-0-32 flex-column-start" [style.margin-top.px]="110" [formGroup]="projectCheckForm"
    (ngSubmit)="save()">
    <div class="project-details-container">
        <div class="image-alignment-container">
            <div class="project-image-container">
                <img #projectImage *ngIf="imageData" alt="Project Image" class="project-image custom-image"
                    [src]="imageData" />
                <div class="overlay-image" (click)="openImageUploadDialog()">
                    <span class="material-icons"> add_a_photo </span>
                    <a>{{ 'admin.projectDetails.uploadPic' | translate }}</a>
                </div>
            </div>
        </div>

        <div class="form-details-container">
            <div class="flex-row-spaced full-width">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>
                        {{ 'admin.projectDetails.projectName' | translate }}
                    </mat-label>
                    <input matInput required #projectName maxlength="{{
                            projectConstantValues.displayNameMaxLength
                        }}" placeholder="{{
                            'admin.projectDetails.projectName' | translate
                        }}" formControlName="projectNameFormControl" />
                    <mat-hint align="end">{{ projectName.value.length }} /
                        {{ projectConstantValues.displayNameMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="hasControlIsHtmlError('projectNameFormControl',projectCheckForm)">
                        {{ 'validators.noHTML' | translate }}
                    </mat-error>
                    <mat-error *ngIf="
                            containSpaceErrorAndNotRequirredTriggeredFunction(
                                projectCheckForm,
                                'projectNameFormControl'
                            )
                        ">
                        {{ 'validators.noWhiteSpaces' | translate }}
                    </mat-error>
                    <mat-error *ngIf="hasControlRequiredError('projectNameFormControl',projectCheckForm)">
                        {{
                        'admin.projectDetails.projectNameRequiredText'
                        | translate
                        }}
                    </mat-error>
                    <mat-error *ngIf="hasControlMaxLengthError('projectNameFormControl',projectCheckForm)">
                        {{
                        'admin.projectDetails.projectNamemaxLengthText'
                        | translate
                        }}
                    </mat-error>
                </mat-form-field>
            </div>
            <mat-form-field class="full-width project-desc" appearance="outline">
                <mat-label>
                    {{ 'admin.projectDetails.projectDescr' | translate }}
                </mat-label>
                <textarea matInput required maxlength="{{ projectConstantValues.descriptionMaxLength }}"
                    matTextareaAutosize matAutosizeMinRows="2" #projectDescription placeholder="{{
                        'admin.projectDetails.projectDescr' | translate
                    }}" formControlName="descriptionFormControl"></textarea>
                <mat-hint align="end">{{ projectDescription.value.length }} /
                    {{ projectConstantValues.descriptionMaxLength }}</mat-hint>
                <mat-error *ngIf="hasControlIsHtmlError('descriptionFormControl',projectCheckForm)">
                    {{ 'validators.noHTML' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlWhitespacesError('descriptionFormControl',projectCheckForm)">
                    {{ 'validators.noWhiteSpaces' | translate }}
                </mat-error>
            </mat-form-field>
            <div class="flex-row-spaced full-width">
                <mat-form-field class="full-width date-field" (click)="pickerStart.open()" appearance="outline">
                    <mat-label>
                        {{ 'admin.projectDetails.chooseStartDate' | translate }}
                    </mat-label>
                    <input matInput required autocomplete="off" formControlName="projectStartDateFormControl"
                        placeholder="{{
                            'admin.projectDetails.chooseStartDate' | translate
                        }}" [min]="minStartDate" [matDatepicker]="pickerStart" />
                    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStart required> </mat-datepicker>
                </mat-form-field>

                <mat-form-field class="full-width date-field" (click)="pickerEnd.open()" appearance="outline">
                    <mat-label>
                        {{ 'admin.projectDetails.chooseEndDate' | translate }}
                    </mat-label>
                    <input matInput required autocomplete="off" formControlName="projectEndDateFormControl" placeholder="{{
                            'admin.projectDetails.chooseEndDate' | translate
                        }}" [min]="minEndDate" [matDatepicker]="pickerEnd" />
                    <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEnd> </mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>

    <section class="padding-20-0 flex-column-start full-width">
        <h5 [style.margin-bottom.px]="12">
            {{ 'admin.projectDetails.projectFiles' | translate }}
        </h5>
        <div class="list">
            <div class="list-element">
                <span class="material-icons green-icon">play_for_work</span>
                <a class="upload-link" (click)="clickUploadFileLink()">{{
                    'admin.projectDetails.uploadPdf' | translate
                    }}</a>
                <input type="file" multiple class="file-upload-input" (change)="fileChange($event)" />
            </div>

            <ng-container *ngIf="documents">
                <div *ngFor="let permitDocument of documents" class="list-element flex-row-spaced">
                    <ng-container *ngIf="permitDocument.isActive">
                        <span class="material-icons green-icon">attach_file</span>
                        <a (click)="downloadDocument(permitDocument)">{{
                            permitDocument.fileName
                            }}</a>
                        <span (click)="deleteDocumentLabel(permitDocument)" class="material-icons remove">delete</span>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </section>
</form>