<div
    class="main-container flex-row-start full-width"
    [class.public-page-container]="!isLoggedInOrIsNotViewOnly()"
>
    <ng-container *ngIf="threeIsPrepared">
        <div class="three-container">
            <furban-public-three-js-editor
                [userObjects]="userObjects"
                [state]="threeState"
                [showButtons]="!isViewerOnly"
                [userProfileId]="designProposal.userProfile.userProfileId"
                [project]="project"
                [designProposal]="designProposal"
                (threeJSCommentSave)="onThreeJSCommentSave($event)"
                (rightMenuVisibiltyChanged)="
                    updateRightSideMenuVisibilty($event)
                "
            >
            </furban-public-three-js-editor>
            <ng-container *ngIf="showBack">
                <div class="floating-buttons left" (click)="goBack()">
                    <span class="material-icons"> arrow_back </span>
                    <h6>{{ 'generic.back' | translate }}</h6>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
