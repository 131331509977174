export const DefaultStepOptions = {
    canClickTarget: false,
    scrollTo: true,
    cancelIcon: {
        enabled: true,
    },
    tippyOptions: {
        duration: 500,
    },
    popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
    },
};
