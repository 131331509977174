import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewContainerRef,
    OnDestroy,
} from '@angular/core';

import {
    PermitProject,
    PermitComment,
    PermitCommentsService,
    PermitThreeService,
} from '@furban/utilities';
import { Subscription } from 'rxjs';

@Component({
    selector: 'furban-permit-comments',
    templateUrl: './permit-comments.component.html',
    styleUrls: ['./permit-comments.component.scss'],
})
export class PermitCommentsComponent implements OnInit, OnDestroy {
    @Input() public viewContainerRef: ViewContainerRef;
    @Input() public project: PermitProject;
    @Input() public isDialog: boolean;
    @Input() public isViewOnly: boolean;
    @Output() public commentModified = new EventEmitter();

    public permitComments: PermitComment[] = [];
    public addingCommentsEnabled = false;
    public highlightedComment: PermitComment;
    public isEmojiOpened = false;

    private pinSavedSubscription: Subscription;
    private addPinSubscription: Subscription;
    private highlightCommentSubscription: Subscription;

    constructor(
        private permitCommentService: PermitCommentsService,
        private permitThreeService: PermitThreeService
    ) { }

    ngOnDestroy(): void {
        this.pinSavedSubscription.unsubscribe();
        this.addPinSubscription.unsubscribe();
        this.highlightCommentSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.getPermitComments();
        this.subscribeToPinCommentSave();
        this.subscribeToPinCommentsDisabled();
        this.subscribeToHighlightComment();
    }

    public getPermitComments(): void {
        this.permitCommentService
            .getAllPermitComments(this.project.projectId)
            .subscribe((data) => {
                this.permitComments = data;
            });
    }

    public onCommentSave(comment: PermitComment): void {
        this.permitComments.push(comment);
        this.commentModified.emit();
    }

    public onEmojiToggled(isEmojiOpened: boolean): void {
        this.isEmojiOpened = isEmojiOpened;
    }

    public get hasComments(): boolean {
        return this.permitComments?.length > 0;
    }

    public selectComment(comment: PermitComment): void {
        if (!comment.position || this.isDialog) {
            return;
        }
        if (this.highlightedComment) {
            this.highlightedComment.highlighted = false;
        }
        comment.highlighted = true;
        this.permitCommentService.highlightedPermitCommentEmitter.emit(comment);
    }

    deleteCommentConfirmation(comment: PermitComment): void {
        this.permitCommentService
            .deletePermitComment(comment)
            .subscribe((data) => {
                this.permitComments.splice(
                    this.permitComments.indexOf(comment),
                    1
                );
                this.commentModified.emit();
                if (!comment.position) {
                    return;
                }
                this.permitCommentService.deletedPermitCommentEmitter.emit(
                    comment.commentId
                );
            });
    }

    private subscribeToPinCommentSave(): void {
        this.pinSavedSubscription =
            this.permitCommentService.pinCommentSavedEmitter.subscribe(
                (data) => {
                    this.onCommentSave(data);
                }
            );
    }

    private subscribeToPinCommentsDisabled(): void {
        this.addPinSubscription =
            this.permitThreeService.addPinEventsObservable.subscribe((data) => {
                this.addingCommentsEnabled = data;
            });
    }

    private subscribeToHighlightComment(): void {
        this.highlightCommentSubscription =
            this.permitCommentService.highlightedPermitCommentEmitter.subscribe(
                (data) => {
                    this.highlightPinComment(data);
                }
            );
    }

    private highlightPinComment(comment: PermitComment): void {
        this.highlightedComment = comment;
    }
}
