import { Component, OnInit, Inject, ViewContainerRef } from '@angular/core';
import {
    FormGroup,
    Validators,
    FormBuilder,
    FormControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
    ControlsUtil,
    FurbanUtil,
    Group,
    ModalManager,
    validateWhitespace,
} from '@furban/utilities';
import { GroupsService } from '../../municipality/admin/admin-codes/groups/groups.service';
import { CodesService } from '../../shared/_services/codes.service';

@Component({
    selector: 'furban-create-group',
    templateUrl: './create-group.component.html',
    styleUrls: ['./create-group.component.scss'],
})
export class CreateGroupComponent implements OnInit {
    public groupForm: FormGroup;
    public allGroups: Group[];

    //REFACTOR - create enum
    /* type can be : 'select' or 'create'*/
    public type: string;
    public projectId: string;
    public noOfExperts: number;
    public selectedGroup = new FormControl(null, Validators.required);
    public parentViewContainerRef: ViewContainerRef;
    public htmlValidator = FurbanUtil.htmlContentValidator;

    public hasControlMaxError = ControlsUtil.hasControlMaxError;
    public hasControlMinError = ControlsUtil.hasControlMinError;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;
    public hasControlMaxLengthError = ControlsUtil.hasControlMaxLengthError;
    public hasControlMinLengthError = ControlsUtil.hasControlMinLengthError;
    public hasControlIsHtmlError = ControlsUtil.hasControlIsHtmlError;
    public hasControlWhitespacesError = ControlsUtil.hasControlWhitespacesError;
    public hasControlError = ControlsUtil.hasControlError;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<CreateGroupComponent>,
        private formBuilder: FormBuilder,
        private groupService: GroupsService,
        private modal: ModalManager,
        private codesService: CodesService
    ) {
        this.type = data.type;
        this.projectId = data.projectId;
        this.noOfExperts = data.noOfExperts;
    }

    ngOnInit() {
        if (this.type === 'create') {
            this.initialiseCreateTypeModal();
        } else {
            this.getAllGroups();
        }
    }

    private initialiseCreateTypeModal(): void {
        const maxExperts = 5 - this.noOfExperts;
        this.groupForm = this.formBuilder.group({
            groupName: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(32),
                    this.htmlValidator,
                    validateWhitespace,
                ],
            ],
            citizensCount: ['', [Validators.max(250), Validators.min(0)]],
            expertsCount: ['', [Validators.max(maxExperts), Validators.min(0)]],
        });
    }

    private getAllGroups(): void {
        this.groupService.getGroupsByClientId().subscribe((data) => {
            this.allGroups = data;
        });
    }

    public saveGroup(): void {
        if (
            this.type === 'create' &&
            this.groupForm.get('groupName').value === 0
        ) {
            this.groupForm.get('groupName').markAsDirty();
            this.groupForm
                .get('groupName')
                .setErrors({ whitespace: false, required: true });
        }

        if (
            (this.type === 'create' && this.groupForm.invalid) ||
            (this.type === 'select' && this.selectedGroup.invalid)
        ) {
            return;
        }

        if (this.type === 'create') {
            this.saveGroupWithUsers();
        } else {
            this.assignGroupToProject();
        }
    }

    private saveGroupWithUsers(): void {
        const group = new Group();
        group.groupName = this.groupForm.get('groupName').value.toLowerCase();
        group.createdDate = new Date(Date.now());
        group.usersCount = this.groupForm.get('citizensCount').value;
        group.expertsCount = this.groupForm.get('expertsCount').value;

        this.groupService
            .createGroupWithUsers(group, this.projectId)
            .subscribe((data) => {
                if (data) {
                    const paramObject: any = {};
                    paramObject.groupId = data.groupId;
                    paramObject.expertsCount = this.groupForm.get(
                        'expertsCount'
                    ).value
                        ? this.groupForm.get('expertsCount').value
                        : 0;
                    paramObject.citizensCount = this.groupForm.get(
                        'citizensCount'
                    ).value
                        ? this.groupForm.get('citizensCount').value
                        : 0;
                    this.codesService.generateCodesInMultipleRequests(
                        paramObject,
                        this.dialogRef,
                        data
                    );
                }
            });
    }

    private assignGroupToProject(): void {
        if (this.selectedGroup.invalid) {
            return;
        }
        this.groupService.assignGroupToProject(this.projectId, this.selectedGroup.value.groupId).subscribe(
            () => {
                this.dialogRef.close(this.selectedGroup.value);
            });
    }

    public closeDialog(): void {
        if (this.isModified()) {
            this.modal.showModal(this.parentViewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'formChangesDialog.message',
                    'generic.yesBtn',
                    'generic.noBtn')).subscribe(
                        (res: any) => {
                            if (res) {
                                this.dialogRef.close();
                            }
                        });
        } else {
            this.dialogRef.close();
        }
    }

    private isModified(): boolean {
        if (this.type === 'create' && this.groupForm.dirty) {
            return true;
        }
        if (this.type === 'select' && this.selectedGroup.dirty) {
            return true;
        }
        return false;
    }
}
