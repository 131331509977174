<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'comments.title' | translate }}</h2>
    <button (click)="close()" class="ghost-button">
        <span class="material-icons"> close </span>
    </button>
</div>

<ng-container *ngIf="project">
    <furban-permit-comments
        class="full-width"
        [project]="project"
        [viewContainerRef]="parentViewContainerRef"
        [isDialog]="true"
        (commentModified)="onCommentModified()"
    >
    </furban-permit-comments>
</ng-container>

<ng-container *ngIf="!isLoggedIn()">
    <div class="flex-column-center padding-20">
        <img
            src="../../../assets/images/no_comm.svg"
            [style.height.px]="200"
            [style.margin-bottom.px]="20"
        />
        <h4 [style.font-size.px]="18">
            {{ 'comments.commentsNotAvailable' | translate }}
        </h4>
    </div>
</ng-container>
