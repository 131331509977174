import {
    Component,
    OnInit,
    Output,
    Input,
    EventEmitter,
    HostListener,
} from '@angular/core';

@Component({
    selector: 'furban-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent implements OnInit {
    @Input() title: string;
    @Input() buttonText: string;
    @Input() filterId: string;
    @Input() disableAction?: boolean;

    @Output() cancelCallback = new EventEmitter<boolean>();
    @Output() okCallback = new EventEmitter<boolean>();

    public left: number;
    public top: number;

    ngOnInit(): void {
        this.calculateCoordinates();
    }

    public closePanel(): void {
        this.cancelCallback.emit(true);
    }

    public filter(): void {
        this.okCallback.emit(true);
    }

    @HostListener('window:resize')
    private calculateCoordinates(): void {
        const clientRect = document
            .getElementById(this.filterId)
            .getBoundingClientRect();
        const difference = window.innerWidth - clientRect.left - 400;
        this.left =
            difference < 0 ? clientRect.left + difference - 32 : clientRect.left;
        this.top = clientRect.top + 32;
    }
}
