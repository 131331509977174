import { SelectionBox } from 'three/examples/jsm/interactive/SelectionBox.js';
import { Component, Input, OnChanges } from '@angular/core';

import {
    BubbleChartInstance,
    BubbleChartStructure,
    BubbleChartUtils,
    BubbleStyle,
    CATEGORIES_COLOR,
    ChartBubble,
    ObjectCategoryStatistics,
} from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';
import { tip as d3tip } from 'd3-v6-tip';

@Component({
    selector: 'furban-categories-statistics',
    templateUrl: './categories-statistics.component.html',
    styleUrls: ['./categories-statistics.component.scss'],
})
export class CategoriesStatisticsComponent implements OnChanges {
    @Input() public objectsPerCategoryCount: ObjectCategoryStatistics[];
    @Input() public objectsTotalCount = 1;
    public bubbleChart: BubbleChartInstance = new BubbleChartInstance();
    public chartData: BubbleChartStructure;

    constructor(private translateService: TranslateService) { }

    ngOnChanges(): void {
        this.setChartData();
        this.setLegendDetails();
        this.render();
    }

    private setChartData(): void {
        const categoriesData = [];
        const values = this.objectsPerCategoryCount.map(
            (categoryCount) => categoryCount.count
        );
        this.bubbleChart.min = Math.min(...values);
        this.bubbleChart.max = Math.max(...values);
        this.objectsPerCategoryCount.forEach((objectCategory) => {
            const percentage = (
                (objectCategory.count * 100) /
                this.objectsTotalCount
            ).toFixed(2);
            const fontSize = BubbleChartUtils.getFontSize(
                objectCategory.count,
                this.bubbleChart.min,
                this.bubbleChart.max
            );
            const colorValue = CATEGORIES_COLOR.get(objectCategory.category.categoryName);
            const color = colorValue ? colorValue[0] : 'var(--primary-7)';
            const borderColorValue = CATEGORIES_COLOR.get(objectCategory.category.categoryName);
            const borderColor = borderColorValue ? borderColorValue[1] : 'var(--primary-10)';
            const style = new BubbleStyle(fontSize, color, borderColor);
            categoriesData.push(
                new ChartBubble(
                    objectCategory.category.categoryName,
                    objectCategory.count,
                    percentage,
                    style
                )
            );
        });
        this.chartData = new BubbleChartStructure(categoriesData);
    }

    private render() {
        document.querySelector('#chart').innerHTML = '';
        const bubblePack = this.getBubblePackFunction();
        this.bubbleChart.tip = this.getTooltipDetails();
        this.bubbleChart.svg = this.getSvgDetails();
        this.bubbleChart.root = d3
            .hierarchy(this.chartData)
            .sum((node: d3.Node) => {
                return node.value;
            });
        bubblePack(this.bubbleChart.root);
        this.bubbleChart.nodeStructure = this.getNodesStructureWithData();
        this.setCircleNode();
        this.addTextToNode(this.getLabel);
        this.setNodeColor();
        this.addMouseEvents();
    }

    private setCircleNode(): void {
        this.bubbleChart.nodeStructure
            .append('circle')
            .attr('r', (node: d3.Node) => {
                return node.r;
            })
            .attr('class', 'category-bubble');
    }

    private setLegendDetails(): void {
        const legendDiv = document.getElementById('legend-content');
        let text: string, paragraph: HTMLElement, span: HTMLSpanElement;

        if (legendDiv?.childElementCount) {
            legendDiv.innerHTML = '';
        }
        this.chartData?.children.forEach((node) => {
            paragraph = document.createElement('p');

            span = document.createElement('span');
            span.setAttribute('class', 'dot');
            span.style.backgroundColor = node.style.bubbleColor;

            paragraph.appendChild(span);
            text = `${this.translateService.instant(node.translationLabel)}: ${node.value
                }`;
            paragraph.appendChild(document.createTextNode(text));
            legendDiv.appendChild(paragraph);
        });
    }

    private setNodeColor(): void {
        this.bubbleChart.nodeStructure
            .style('fill', (node: d3.Node) => {
                return node.data.style.bubbleColor;
            })
            .style('stroke', (node: d3.Node) => {
                return node.data.style.borderColor;
            });
    }

    private addMouseEvents(): void {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        that.bubbleChart.nodeStructure
            .on('mouseover', (node: d3.Node) => {
                that.bubbleChart.tip.show(node, this);
            })
            .on('mouseout', (node: d3.Node) => {
                that.bubbleChart.tip.hide(node, this);
            });
        that.bubbleChart.nodeStructure.call(that.bubbleChart.tip);
    }

    private addTextToNode(textFunction: any): void {
        this.bubbleChart.nodeStructure
            .append('text')
            .attr('dy', '0.3em')
            .attr('class', 'bubble-label')
            .style('font-size', (node: d3.Node) => {
                return node.data.style.fontSize;
            })
            .text(textFunction);
    }

    private getNodesStructureWithData(): SelectionBox {
        return this.bubbleChart.svg
            .selectAll('.node')
            .data(this.bubbleChart.root.children)
            .enter()
            .append('g')
            .attr('class', 'node')
            .attr('transform', (node: d3.Node) => {
                return 'translate(' + node.x + ' ' + node.y + ')';
            })
            .append('g')
            .attr('class', 'graph');
    }

    private getBubblePackFunction(): any {
        return d3
            .pack()
            .size([this.bubbleChart.diameter, this.bubbleChart.diameter])
            .padding(this.bubbleChart.nodePadding);
    }

    private getTooltipDetails(): d3tip {
        return d3tip()
            .attr('class', 'd3-tip-outer')
            .offset(this.bubbleChart.tooltipPosition)
            .html((event: d3.MouseEvent) => {
                const node = event.toElement.__data__.data;
                return `<div  style="border-color: ${node.style.borderColor
                    }" class="d3-tip">
                <span class="dot" style="background-color:${node.style.bubbleColor
                    }"></span>
                ${this.translateService.instant(node.translationLabel)}: ${node.percentage
                    } %</div>`;
            });
    }

    private getSvgDetails(): d3.SelectionBox {
        return d3
            .select('#chart')
            .append('svg')
            .attr(
                'viewBox',
                `0 0  ${this.bubbleChart.diameter + this.bubbleChart.margin.right
                } ${this.bubbleChart.diameter}`
            )
            .attr(
                'width',
                this.bubbleChart.diameter + this.bubbleChart.margin.right
            )
            .attr(
                'height',
                this.bubbleChart.diameter + this.bubbleChart.margin.top
            )
            .attr('class', 'chart-svg');
    }

    private getLabel = (node: d3.Node) => {
        const labelTranslated = this.translateService.instant(
            node.data.translationLabel
        );
        return BubbleChartUtils.truncateBubbleLabel(labelTranslated, node);
    };
}
