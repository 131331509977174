import * as THREE from 'three';

export class ThreeCameraBuilder {
    public static createPerspectiveCamera(
        aspectRatio: any,
        position: THREE.Vector3
    ): THREE.PerspectiveCamera {
        const cameraProperties = {
            fieldOfView: 45,
            nearClippingPane: 1,
            farClippingPane: 1000,
        };

        const camera = new THREE.PerspectiveCamera(
            cameraProperties.fieldOfView,
            aspectRatio,
            cameraProperties.nearClippingPane,
            cameraProperties.farClippingPane
        );

        camera.position.set(position.x, position.y, position.z);
        camera.updateProjectionMatrix();
        return camera;
    }
}
