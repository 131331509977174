
export class ViewPortDimension {
    public left: number;
    public bottom: number;
    public width: number;
    public height: number;
  
    constructor(left: number, bottom: number, width: number, height: number) {
        this.left = left;
        this.bottom = bottom;
        this.width = width;
        this.height = height;
    }


}