<div class="buttons-container">
    <ng-container *ngIf="!(project && project.ended && project.hasWinner)">
        <button class="primary-button"
                *ngIf="getProjectStatusWeight() === projectStatus.published"
                (click)="showChangeStatusProjectWarning('unpublished')">
            <h6>{{ 'admin.projectActivate.deactivateBtn' | translate }}</h6>
        </button>
        <button class="primary-button"
                *ngIf="
                getProjectStatusWeight() === projectStatus.unpublished ||
                getProjectStatusWeight() === projectStatus.created
            "
                (click)="showChangeStatusProjectWarning('published')">
            <h6>{{ 'admin.projectActivate.activateBtn' | translate }}</h6>
        </button>
    </ng-container>

    <span class="material-icons pointer "
          [matMenuTriggerFor]="menu"
          #clickMenuTrigger="matMenuTrigger" >more_vert </span>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
                (click)="showRemoveProjectWarning()">
            <h6>{{ 'admin.projectActivate.removeBtn' | translate }}</h6>
        </button>
        <button mat-menu-item
                *ngIf="project && !project.ended"
                (click)="endProjectNow()">
            <h6>{{ 'admin.projectActivate.endNowBtn' | translate }}</h6>
        </button>

        <ng-container *ngIf="checkIfNotArchived()">
            <button mat-menu-item
                    (click)="archiveProject()">
                <h6>{{ 'admin.projectActivate.archiveBtn' | translate }}</h6>
            </button>
            <button mat-menu-item
                    (click)="getParticipationDoc()">
                <h6>{{ 'fileGenerate.generateFile' | translate }}</h6>
            </button>
        </ng-container>

        <button mat-menu-item
                (click)="createEmbededLink()">
            {{ 'generic.embeded' | translate }}
        </button>
    </mat-menu>
</div>