import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
    MediaService,
    PermitProject,
    PermitUtils,
    routingConstants,
} from '@furban/utilities';
import { environment } from '../../../../../src/environments/environment';
import { PermitReview } from '../../../permit/_models/permit-review';

@Component({
    selector: 'furban-permit-request-card',
    templateUrl: './permit-request-card.component.html',
    styleUrls: ['./permit-request-card.component.scss'],
})
export class PermitRequestCardComponent implements OnInit {
    @Input() permitReview: PermitReview;
    @Output() commentDialogOpened?: EventEmitter<PermitProject> =
        new EventEmitter();
    @Output() reviewDialogOpened?: EventEmitter<PermitReview> =
        new EventEmitter();
    public labelColorByStatus: string;

    public constructor(private mediaService: MediaService,
        private router: Router) { }

    public ngOnInit(): void {
        this.labelColorByStatus = PermitUtils.getPermitLabelColor(
            this.municipalityStatusTranslationLabel
        );
    }

    public getMedia(mediaId): string {
        return mediaId
            ? `${environment.apiPath}/media/public/${mediaId}`
            : '../../../assets/images/default-project.png';
    }

    public get municipalityStatusTranslationLabel(): string {
        return this.permitReview.projectStatus.statusLabel;
    }

    public openDetails(): void {
        this.router.navigateByUrl(
            `${routingConstants.adminViewPermit}/${this.permitReview.project.projectId}`
        );
    }

    public getUserMedia(mediaId: string): string {
        return this.mediaService.getUserMedia(mediaId);
    }

    public reviewPermit(): void {
        this.reviewDialogOpened.emit(this.permitReview);
    }

    public openCommentDialog(): void {
        this.commentDialogOpened.emit(this.permitReview.project);
    }
}
