export const OBJECTS_MENU_TOUR_ITEMS = [
    ['uiTour.designMode.dragAndDropTitle', 'uiTour.designMode.dragAndDrop'],
    ['uiTour.designMode.longPressTitle', 'uiTour.designMode.longPress'],
    ['uiTour.designMode.simpleClickTitle', 'uiTour.designMode.simpleClick'],
    ['uiTour.designMode.copyPasteTitle', 'uiTour.designMode.copyPaste'],
];

export const LEFT_TOOLING_TOUR_ITEMS = [
    ['uiTour.designMode.multipleObjectsTitle', 'uiTour.designMode.multipleObjectsDesktop'],
    ['uiTour.designMode.objectsInLineTitle', 'uiTour.designMode.objectsInLineDesktop'],
    ['uiTour.designMode.selectMultipleTitle', 'uiTour.designMode.selectMultipleDesktop'],
    ['uiTour.designMode.shapesTitle', 'uiTour.designMode.shapes'],
]

export const LEFT_TOOLING_TOUR_ITEMS_MOBILE = [
    ['uiTour.designMode.multipleObjectsTitle', 'uiTour.designMode.multipleObjectsTablet'],
    ['uiTour.designMode.objectsInLineTitle', 'uiTour.designMode.objectsInLineTablet'],
    ['uiTour.designMode.selectMultipleTitle', 'uiTour.designMode.selectMultipleTablet'],
    ['uiTour.designMode.shapesTitle', 'uiTour.designMode.shapes'],
]

export const RIGHT_MENU_TOUR_ITEMS = [
    ['uiTour.designMode.resetDesignTitle', 'uiTour.designMode.resetDesign'],
    [
        'uiTour.designMode.fullScreenSwitchTitle',
        'uiTour.designMode.fullScreenSwitch',
    ],
    ['uiTour.designMode.safetyAreaTitle', 'uiTour.designMode.safetyArea'],
    ['uiTour.designMode.toggleGridTitle', 'uiTour.designMode.toggleGrid'],
    ['uiTour.designMode.exportDesignTitle', 'uiTour.designMode.exportDesign'],
    ['uiTour.designMode.transparencyDesignTitle', 'uiTour.designMode.transparencyDesign'],
    ['uiTour.designMode.changePerspectiveTitle', 'uiTour.designMode.changePerspective'],
    ['uiTour.designMode.undoTitle', 'uiTour.designMode.undo'],
    ['uiTour.designMode.redoTitle', 'uiTour.designMode.redo'],
];

export const RIGHT_MENU_TOUR_ITEMS_IPAD = [
    ['uiTour.designMode.resetDesignTitle', 'uiTour.designMode.resetDesign'],
    [
        'uiTour.designMode.fullScreenSwitchTitle',
        'uiTour.designMode.fullScreenSwitch',
    ],
    ['uiTour.designMode.safetyAreaTitle', 'uiTour.designMode.safetyArea'],
    // ['uiTour.designMode.exportDesignTitle', 'uiTour.designMode.exportDesign'],
];
