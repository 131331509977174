<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'suppliers.addSupplier' | translate }}</h2>

    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons"> close </span>
    </button>
</div>

<mat-dialog-content>
    <form class="flex-column-end" [formGroup]="supplierForm">
        <mat-form-field class="full-width" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'suppliers.addSupplierName' | translate }}
            </mat-label>
            <input required matInput placeholder="{{ 'suppliers.addSupplierName' | translate }}"
                formControlName="supplierName" />
            <mat-error *ngIf="hasControlRequiredError('supplierName',supplierForm)">
                {{ 'suppliers.requiredNamefiled' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlIsHtmlError('supplierName',supplierForm)">
                {{ 'validators.noHTML' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlWhitespacesError('supplierName',supplierForm)">
                {{ 'validators.noWhiteSpaces' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlMaxLengthError('supplierName',supplierForm)">
                {{ 'suppliers.supplierNameMaxLength' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'suppliers.addToClient' | translate }}
            </mat-label>
            <mat-chip-list #chipList formControlName="chipListControl">
                <mat-chip *ngFor="let data of selectedData" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(data)">
                    {{ data.clientName }}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input matInput placeholder="{{ 'suppliers.addToClient' | translate }}" #dropDownInput
                    formControlName="dropdownControl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let municipality of municipalities" [value]="municipality">
                    {{ municipality.clientName }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closeDialog()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="saveSupplier()">
        <h6>{{ 'generic.save' | translate }}</h6>
    </button>
</div>