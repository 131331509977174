export class Card {
    /* Card type can be either white or colored
     * White - reffers to admin project dashboard tiles and also right side of the user dashboard tiles
     * Colored - tiles that are used in the user dashboard
     */
    cardType = 'white';
    hasHeader: boolean;
    height?: number;
    background?: string;
    contentAlignmentVertical?: string;
    contentAlignmentHorizontal?: string;
    headerBackground?: string;
    hoverable?: boolean;
    backgroundPosition?: string;
}
