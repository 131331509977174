import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from 'apps/furban-client/src/environments/environment';
import { PioneerLoginGuard } from '../pioneer/guards/pioneer-login.guard';
import { PermitProcessDashboardComponent } from './permit-process-dashboard/permit-process-dashboard.component';
import { PermitSelectHouseMapComponent } from './permit-select-house-map/permit-select-house-map.component';
import { HousePreviewComponent } from './house-preview/house-preview.component';
import { PermitContainerComponent } from './permit-container/permit-container.component';
import { PermitProjectComponent } from './permit-project/permit-project.component';

import { routingConstants, ProjectTypeNameEnum } from '@furban/utilities';

const pioneerRoutes: Routes = [
    {
        path: `${routingConstants.justApp}/partner/permit/:projectId`,
        component: PermitContainerComponent,
        canActivate: [PioneerLoginGuard],
        data: {
            env: environment,
            role: 'pioneer',
            type: ProjectTypeNameEnum.pioneerPermitProject,
        },

        children: [
            {
                path: 'details',
                component: PermitProjectComponent,
                canActivate: [PioneerLoginGuard],
                outlet: 'permit-project',
                data: {
                    env: environment,
                    role: 'pioneer',
                    type: ProjectTypeNameEnum.pioneerPermitProject,
                },
            },
            {
                path: 'preview',
                component: HousePreviewComponent,
                canActivate: [PioneerLoginGuard],
                outlet: 'permit-project',
                data: {
                    env: environment,
                    role: 'pioneer',
                    type: ProjectTypeNameEnum.pioneerPermitProject,
                    isClone: false,
                },
            },
            {
                path: 'update',
                component: HousePreviewComponent,
                canActivate: [PioneerLoginGuard],
                outlet: 'permit-project',
                data: {
                    env: environment,
                    role: 'pioneer',
                    type: ProjectTypeNameEnum.pioneerPermitProject,
                    isClone: true,
                },
            },
            {
                path: 'overview',
                component: PermitProcessDashboardComponent,
                canActivate: [PioneerLoginGuard],
                outlet: 'permit-project',
                data: {
                    env: environment,
                    role: 'pioneer',
                    type: ProjectTypeNameEnum.pioneerPermitProject,
                },
            },
            {
                path: 'map',
                component: PermitSelectHouseMapComponent,
                canActivate: [PioneerLoginGuard],
                outlet: 'permit-project',
                data: {
                    env: environment,
                    role: 'pioneer',
                    type: ProjectTypeNameEnum.pioneerPermitProject,
                },
            },
            {
                path: 'process',
                component: PermitProcessDashboardComponent,
                canActivate: [PioneerLoginGuard],
                outlet: 'permit-project',
                data: {
                    env: environment,
                    role: 'pioneer',
                    type: ProjectTypeNameEnum.pioneerPermitProject,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(pioneerRoutes)],
    exports: [RouterModule],
})
export class PermitRoutingModule { }
