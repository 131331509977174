import { Component, OnInit, Inject, ViewContainerRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ControlsUtil, CustomToasterComponent, CustomToasterService, FurbanUtil, Group, ModalManager } from '@furban/utilities';
import { CodesService } from '../../../shared/_services/codes.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';

@Component({
	selector: 'furban-initiative-users',
	templateUrl: './initiative-users.component.html',
	styleUrls: ['./initiative-users.component.scss']
})
export class InitiativeUsersComponent implements OnInit {
	public groupForm: FormGroup;
	public allGroups: Group[];

	public projectId: string;
	public noOfExperts: number;
	public selectedGroup = new FormControl('', Validators.required);
	public parentViewContainerRef: ViewContainerRef;
	public htmlValidator = FurbanUtil.htmlContentValidator;


	public hasControlMaxError = ControlsUtil.hasControlMaxError;
	public hasControlMinError = ControlsUtil.hasControlMinError;

	constructor(@Inject(MAT_DIALOG_DATA) data,
		private dialogRef: MatDialogRef<InitiativeUsersComponent>,
		private formBuilder: FormBuilder,
		private modal: ModalManager,
		private translateService: TranslateService,
		private customToasterService: CustomToasterService,
		private codesService: CodesService
	) {

		this.projectId = data.projectId;
		this.noOfExperts = data.noOfExperts;
	}

	ngOnInit(): void {
		this.initialiseCreateTypeModal();
	}

	private initialiseCreateTypeModal(): void {
		this.groupForm = this.formBuilder.group({
			'initiativeCitizensCount': ['', [Validators.max(250), Validators.min(0)]]
		});
	}

	public closeDialog(): void {
		if (!this.isModified()) {
			this.dialogRef.close();
		}

		this.modal.showModal(this.parentViewContainerRef,
			ModalManager.createConfiguration(
				'errors.warning',
				'formChangesDialog.message',
				'generic.yesBtn',
				'generic.noBtn')).subscribe(
					(res: any) => {
						if (res) {
							this.dialogRef.close();
						}
					});
	}
	public saveGroup(): void {
		if (this.groupForm.invalid) {
			this.groupForm.markAsDirty();
			return;
		}

		const citizenCountValue = this.groupForm.get('initiativeCitizensCount').value ? this.groupForm.get('initiativeCitizensCount').value : 0;
		if (!citizenCountValue) {
			this.dialogRef.close();
		}

		const paramObject = new HttpParams().set('expertsCount', '0').set('citizensCount', citizenCountValue.toString());
		this.codesService.generatePioneerCodes(paramObject).subscribe(
			(data) => {
				this.groupForm.reset();
				this.customToasterService.openCustomToaster(
					CustomToasterComponent,
					'check_circle',
					'success',
					'admin.projectCodes.addCodeSuccessMessage',
					2000);

				this.dialogRef.close(data);
			}
		);


	}

	private isModified(): boolean {
		return this.groupForm.dirty
	}
}
