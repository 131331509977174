import { Component, OnInit, ViewContainerRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ControlsUtil, ModalManager } from '@furban/utilities';
import { CodesService } from '../../../../shared/_services/codes.service';

@Component({
    selector: 'furban-app-generate-codes',
    templateUrl: './generate-codes.component.html',
    styleUrls: ['./generate-codes.component.scss'],
})
export class GenerateCodesComponent implements OnInit {
    public parentRef: ViewContainerRef;
    public groupId: string;
    public codesForm: FormGroup;
    public expertsCountOnAllProjects = 0;
    public hasControlMaxError = ControlsUtil.hasControlMaxError;
    public hasControlMinError = ControlsUtil.hasControlMinError;
    public hasControlBothEmptyError = ControlsUtil.hasControlBothEmptyError;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<GenerateCodesComponent>,
        private formBuilder: FormBuilder,
        private modal: ModalManager,
        private codesService: CodesService
    ) {
        this.groupId = data.groupId;
        this.parentRef = data.parentRef;
        this.expertsCountOnAllProjects = data.expertsCountOnAllProjects;
    }

    ngOnInit() {
        this.codesForm = this.formBuilder.group({
            citizensCount: ['', [Validators.max(250), Validators.min(0)]],
            expertsCount: [
                '',
                [
                    Validators.max(5 - this.expertsCountOnAllProjects),
                    Validators.min(0),
                ],
            ],
        });
    }

    closePopup() {
        // check if modified to save before close
        if (this.codesForm.dirty) {
            this.modal
                .showModal(
                    this.parentRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'errors.discardMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (res) {
                        this.dialogRef.close();
                    }
                });
        } else {
            this.dialogRef.close();
        }
    }

    save() {
        if (this.codesForm.invalid) {
            this.codesForm.markAsDirty();
            return;
        }

        if (this.isEmpty('expertsCount') && this.isEmpty('citizensCount')) {
            this.codesForm.setErrors({ bothEmpty: true });
            return;
        }

        const paramObject: any = {};
        paramObject.groupId = this.groupId;
        paramObject.expertsCount = this.codesForm.get('expertsCount').value
            ? this.codesForm.get('expertsCount').value
            : 0;
        paramObject.citizensCount = this.codesForm.get('citizensCount').value
            ? this.codesForm.get('citizensCount').value
            : 0;
        this.codesService.generateCodesInMultipleRequests(
            paramObject,
            this.dialogRef
        );
    }

    isEmpty(label: string): boolean {
        if (
            this.codesForm.get(label).value === '' ||
            this.codesForm.get(label).value == null
        ) {
            return true;
        }
        return false;
    }
}
