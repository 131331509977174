<div class="filter-panel" [style.top.px]="top" [style.left.px]="left">
    <div class="title-container">
        <h5>{{ title | translate }}</h5>
        <span class="material-icons pointer" (click)="closePanel()">close</span>
    </div>
    <div class="full-width">
        <ng-content></ng-content>
    </div>
    <div class="buttons-right-container">
        <button class="secondary-button" (click)="closePanel()">
            <h6>{{ 'generic.cancelBtn' | translate }}</h6>
        </button>
        <button class="primary-button" [class.disabled]="disableAction" (click)="filter()">
            <h6>{{ buttonText | translate }}</h6>
        </button>
    </div>
</div>