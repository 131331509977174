import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';

import { CodesFilter, CustomToasterComponent, Group, ModalManager, FurbanUtil, StepperService, CustomToasterService, CodesRequestDTO, ExportFilesUtil, ToolingActionsEnum, ControlsUtil, ClipboardService } from '@furban/utilities';
import { CodesService } from '../../shared/_services/codes.service';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToolingService } from '../../shared/tooling/tooling.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { InitiativeUsersComponent } from './initiative-users/initiative-users.component';

@Component({
    selector: 'furban-project-pioneer-group',
    templateUrl: './project-pioneer-group.component.html',
    styleUrls: ['./project-pioneer-group.component.scss'],
})
export class ProjectPioneerGroupComponent implements OnInit, OnDestroy {
    public projectId: string;

    public dataSource: MatTableDataSource<Group>;
    public selection = new SelectionModel<any>(true, []);
    public codesForm: FormGroup;

    public codes = [];
    public startDate = new FormControl();
    public endDate = new FormControl();

    public displayedColumns: string[] = ['select', 'username', 'createdDate'];
    public codesFilter: CodesFilter = new CodesFilter();
    public codesLength = 0;

    public pageIndex = 0;
    public pageSize = 20;

    public htmlValidator = FurbanUtil.htmlContentValidator;
    public userCode = new FormControl('', this.htmlValidator);
    public hasControlMaxError = ControlsUtil.hasControlMaxError;
    public hasControlMinError = ControlsUtil.hasControlMinError;
    public hasControlError = ControlsUtil.hasControlError;

    private toolingActionsSubscription: Subscription;

    public isApp = FurbanUtil.isApp;

    constructor(
        public modalManager: ModalManager,
        private clipboardService: ClipboardService,
        private translateService: TranslateService,
        private containerRef: ViewContainerRef,
        private customToasterService: CustomToasterService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private codesService: CodesService,
        private modal: ModalManager,
        private stepperService: StepperService,
        private toolingService: ToolingService) { }

    ngOnInit() {
        this.initiatlizeFormControl();
        this.projectId = this.stepperService.projectId;
        this.getCodesLength();
        this.getCodes();
        this.stepperService.modifyCurrentStepId(false);
        this.subscribeToToolingActionObservable();
    }

    ngOnDestroy() {
        this.toolingActionsSubscription.unsubscribe();
    }

    public onCodeCopy(clipboadTextToCopy: string): void {
        this.clipboardService.copyToClipboard(clipboadTextToCopy, this.translateService, this.customToasterService);
    }

    private subscribeToToolingActionObservable(): void {
        this.toolingActionsSubscription = this.toolingService.toolingActionsObservable
            .pipe(
                filter(data => data && data === ToolingActionsEnum.generateGroup))
            .subscribe(
                (data) => {
                    if (data === ToolingActionsEnum.generateGroup) {
                        this.createGenerateGroup();
                    }
                }
            );
    }

    public createGenerateGroup(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '40%';
        dialogConfig.data = {
            projectId: this.projectId
        };
        const userProfileDialogRef = this.dialog.open(InitiativeUsersComponent, dialogConfig);
        userProfileDialogRef.componentInstance.parentViewContainerRef = this.containerRef;
        userProfileDialogRef.afterClosed().subscribe(
            data => {
                this.selection.clear();
                if (data) {
                    this.refreshParentFormWithNewData();
                }
            }
        );
    }

    private refreshParentFormWithNewData(): void {
        this.resetTable();
        this.resetDateVariables();
        this.getCodesLength();
        this.getCodes();
    }

    public getCodes(): void {
        const requestParam = new CodesRequestDTO(this.pageIndex,
            this.pageSize,
            this.startDate.value,
            this.endDate.value
        );

        this.codesService.getAllCodes(requestParam).subscribe((data) => {
            this.assingCodesToDataSource(data);
        });
    }

    getCodesLength() {
        this.codesService
            .getCodesCount(null, null, this.startDate.value, this.endDate.value)
            .subscribe((data) => {
                this.codesLength = data as number;
                if (this.codesLength) {
                    this.codesLength--;
                }
            });
    }

    assingCodesToDataSource(codes: any) {
        this.codes = this.codes.concat(FurbanUtil.deepCopy(codes));
        this.dataSource = new MatTableDataSource(this.codes);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.dataSource.data.forEach((row) => this.selection.select(row));
        }
    }

    createCSV() {
        this.codesService
            .exportExpertOrCitizenCodes(
                this.codesService.urls.citizenExportCsv,
                null
            )
            .subscribe(
                (data) => {
                    ExportFilesUtil.createCSV(data, 'pioneer_codes_');
                }
            );
    }

    /* Methods for filtering */
    public findByCode() {
        this.selection.clear();
        this.codesService
            .getUserByCodeAndGroupId(this.userCode.value)
            .subscribe((data) => {
                this.resetDateVariables();
                this.resetTable();
                const codes = [];
                if (data != null) {
                    codes.push(data);
                }
                this.assingCodesToDataSource(codes);
                this.codesLength = codes.length;
                this.codesFilter.isCodeSearch = true;
                this.codesFilter.filterToBeShown = 0;
            });
    }

    filter(filter: string) {
        this.selection.clear();
        this.resetTable();
        this.getCodes();
        this.getCodesLength();
        this.showFilter(0);
        this.codesFilter.isCodeSearch = false;
        this.codesFilter[filter] = true;
    }

    public onParentScrollDown() {
        if (this.codesLength > this.dataSource.data.length) {
            this.pageIndex = this.pageIndex + 1;
            this.getCodes();
        }
    }

    resetDateVariables() {
        this.startDate.reset();
        this.endDate.reset();
    }

    resetCodeVariables() {
        this.userCode.reset();
    }

    resetFilters() {
        this.codesFilter = new CodesFilter();
        this.resetTable();
        this.resetDateVariables();
        this.resetCodeVariables();
        this.selection.clear();
        this.getCodes();
        this.getCodesLength();
    }

    resetTable() {
        this.codes = [];
        this.pageIndex = 0;
        this.codesLength = 0;
    }

    deleteCodes() {
        this.modal
            .showModal(
                this.containerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.projectCodes.codesDeleteWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.deleteCodesResultReset();
                } else {
                    this.selection.clear();
                }
            });
    }

    deleteCodesResultReset() {
        this.codesService
            .deletePioneerUserCodes(this.selection.selected)
            .subscribe(() => {
                this.resetFilters();
                this.showSuccessToaster(
                    this.translateService.instant(
                        'admin.projectCodes.codesDeleteSuccess'
                    )
                );
                this.getCodesLength();
            });
    }

    showSuccessToaster(text: string) {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            text,
            2000
        );
    }

    showFilter(filter: number) {
        this.codesFilter.filterToBeShown = filter;
    }

    checkAppliedFilter(filter: number): boolean {
        return this.codesFilter.filterToBeShown === filter ? true : false;
    }

    showResetButton(): boolean {
        if (this.codesFilter.isCodeSearch || this.codesFilter.isDateFiltering) {
            return true;
        }
        return false;
    }

    private initiatlizeFormControl() {
        this.codesForm = this.formBuilder.group({
            codeCount: ['', [Validators.max(20), Validators.min(0)]],
        });
    }
}
