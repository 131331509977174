import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';

import { environment } from 'apps/furban-client/src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class StatisticsService {
    constructor(private http: HttpClient) { }

    public getDataForCharts(projectId: string): Observable<any[]> {
        const getAllUsersResponse = this.http.get(
            `${environment.apiPath}/statistics/all/${projectId}`
        );
        const getAllActiveUsersResponse = this.http.get(
            `${environment.apiPath}/statistics/active/count/${projectId}`
        );
        const getAllDesignProposals = this.http.get(
            `${environment.apiPath}/design-proposal/citizen/count/${projectId}`
        );
        const getLikesCount = this.http.get(
            `${environment.apiPath}/statistics/likes/${projectId}`
        );
        const getCommentsCount = this.http.get(
            `${environment.apiPath}/statistics/comments/${projectId}`
        );

        return forkJoin([
            getAllUsersResponse,
            getAllActiveUsersResponse,
            getAllDesignProposals,
            getLikesCount,
            getCommentsCount,
        ]);
    }

    getDataForAdminCharts(projectId: string): Observable<any[]> {
        const getObjectsPerCategoryCount = this.http.get(
            `${environment.apiPath}/statistics/categories/${projectId}`
        );
        const getObjectsTotalCount = this.http.get(
            `${environment.apiPath}/statistics/path-objects/count/${projectId}`
        );
        const requests = [getObjectsPerCategoryCount, getObjectsTotalCount];
        return forkJoin(requests);
    }
}
