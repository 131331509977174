<div class="cards-container" (scroll)="onScroll($event)">
    <section class="title-section flex-row-start">
        <span
            class="material-icons pointer"
            (click)="goBack()"
            [style.margin-right.px]="12"
            >arrow_back</span
        >
        <h3>{{ 'admin.dashboard.collaborativeLiveDesigns' | translate }}</h3>
    </section>

    <div class="cards">
        <ng-container *ngIf="isCreatingLiveSessionDisplayed()">
            <furban-app-card
                class="admin-card collaborative-sessions"
                [style.height]="cardConfig.height + 'px'"
                [card]="cardConfig"
                [backgroundImage]="
                    '../../../assets/images/create_new_design.png'
                "
                (click)="createCollaborativeDesign()"
            >
                <div card-type="content">
                    <span
                        class="material-icons large-icon"
                        [style.padding-bottom.px]="56"
                        >library_add</span
                    >
                </div>
                <div card-type="footer">
                    {{ 'admin.dashboard.newCollaborativeDP' | translate }}
                </div>
            </furban-app-card>
        </ng-container>

        <div *ngFor="let dp of curentDPs">
            <furban-admin-card
                [userProfile]="dp.userProfile"
                [title]="dp.title"
                [cardMedia]="dp.media"
                (cardClickedEmitter)="openLiveSession(dp)"
                (deleteClickedEmitter)="
                    showDeleteWarningPopup(dp.designProposalId)
                "
            >
            </furban-admin-card>
        </div>
    </div>
</div>
