<div class="comm-container">
    <!-- Comment side-->
    <ng-content select="[area-type=side]"></ng-content>

    <div class="flex-column-start">
        <!-- Comment header-->
        <section class="comm-header">
            <ng-content select="[area-type=header]"></ng-content>
        </section>

        <!-- Comment body-->
        <section class="comm-body">
            <ng-content select="[area-type=body]"></ng-content>
        </section>

        <!-- Comment footer-->
        <section class="comm-footer">
            <ng-content select="[area-type=footer]"></ng-content>
        </section>
    </div>
</div>
