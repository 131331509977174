import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'apps/furban-client/src/environments/environment';
import { map } from 'rxjs/operators';

import { Question } from '@furban/utilities';

@Injectable({
    providedIn: 'root',
})
export class FaqService {
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(private http: HttpClient) { }

    saveQuestion(question: Question): Observable<Question> {
        if (question.videoUrl) {
            question.videoUrl = btoa(question.videoUrl);
        }
        return this.http
            .post(environment.apiPath + '/questions', question, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Question));
    }

    getQuestions(): Observable<Question[]> {
        return this.http
            .get(environment.apiPath + '/questions', { headers: this._headers })
            .pipe(map((result) => result as Question[]));
    }
}
