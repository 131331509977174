import { EventEmitter, Injectable } from '@angular/core';
import { Project } from '../_models/project';

@Injectable({
    providedIn: 'root',
})
export class ProjectStorageService {
    public curentProject: Project;
    public projects: Project[];
    public projectChanged: EventEmitter<Project>;
    public onChatLogin: EventEmitter<Project>;
    public onProjectListModified: EventEmitter<boolean>;

    constructor() {
        this.projectChanged = new EventEmitter();
        this.onChatLogin = new EventEmitter();
        this.onProjectListModified = new EventEmitter();
    }

    public emitCurentProject(): void {
        if (!this.curentProject) {
            return;
        }
        this.projectChanged.emit(this.curentProject);
    }

    public changeProject(project: Project): void {
        this.curentProject = project;
        this.projectChanged.emit(project);
    }

    public emitLoginOnChat(): void {
        this.onChatLogin.emit();
    }

    public getCurentProject(): Project {
        return this.curentProject;
    }

    public emitOnProjectListModified(): void {
        this.onProjectListModified.emit(true);
    }
}
