<span
    [class.disable-menu]="!isAuthUserComment(comment)"
    class="material-icons"
    [matMenuTriggerFor]="menuAdd"
>
    more_vert
</span>
<mat-menu #menuAdd="matMenu" overlapTrigger="false">
    <button
        mat-menu-item
        (click)="deleteComment(comment)"
        value="delete"
        *ngIf="isAuthUserComment(comment)"
    >
        {{ 'generic.remove' | translate }}
    </button>
</mat-menu>
