import { CustomToasterService } from '../_services/custom-toaster.service';

export class CopyToClipboardUtil {
    public static copyText(
        textToBeCopied: string,
        callbackSuccess?: any,
        callbackFail?: any,
        customToasterService?: CustomToasterService
    ): void {
        if (!navigator || !navigator['clipboard']) {
            this.fallbackCopyTextToClipboard(textToBeCopied);
            return;
        }
        navigator['clipboard'].writeText(textToBeCopied).then(
            () => {
                callbackSuccess(customToasterService);
            },
            (err) => {
                callbackFail(customToasterService);
            }
        );
    }

    public static fallbackCopyTextToClipboard(
        text: string,
        callbackSuccess?: any,
        callbackFail?: any,
        customToasterService?: CustomToasterService
    ): void {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            if (successful) {
                callbackSuccess(customToasterService);
            } else {
                callbackFail(customToasterService);
            }
        } catch (err) {
            callbackFail();
        }

        document.body.removeChild(textArea);
    }
}
