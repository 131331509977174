<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'winner.title' | translate }}</h2>

    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons"> close </span>
    </button>
</div>

<mat-dialog-content>
    <ng-container *ngIf="project.hasWinner && dp">
        <div
            class="design-img background-center pointer"
            [style.background-image]="'url(' + getMedia(dp.media) + ')'"
            (click)="openDPDetails()"
        ></div>
        <p>{{ dp.winnerMessage }}</p>
    </ng-container>
    <ng-container *ngIf="!project.hasWinner">
        <div class="flex-column-center full-height">
            <img
                src="../../../assets/images/time.svg"
                [style.height.px]="270"
            />
            <h4 class="placeholder-text">{{ noMessageYetText }}</h4>
        </div>
    </ng-container>
</mat-dialog-content>

<div class="buttons-right-container">
    <button
        *ngIf="project.hasWinner"
        class="primary-button"
        (click)="openDPDetails()"
    >
        <h6>{{ 'winner.viewDesign' | translate }}</h6>
    </button>
    <button class="secondary-button" (click)="closeDialog()">
        <h6>{{ 'generic.closeBtn' | translate }}</h6>
    </button>
</div>
