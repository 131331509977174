import { Component, OnInit } from '@angular/core';
import { ProjectDetailsService } from '../../project-shared/project-details/project-details.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { PermitProjectService } from '../../permit/permit-project/permit-project.service';
import { forkJoin } from 'rxjs';

import {
    Card,
    FurbanUtil,
    MediaService,
    PioneerProject,
    Project,
    ProjectTypeEnum,
    routingConstants
} from '@furban/utilities';

@Component({
    selector: 'furban-pioneer-projects',
    templateUrl: './pioneer-projects.component.html',
    styleUrls: ['./pioneer-projects.component.scss'],
})
export class PioneerProjectsComponent implements OnInit {
    public cardConfig: Card = new Card();
    public projects: PioneerProject[] = [];
    public getClassForProject = FurbanUtil.getClassForProjectStatus;
    public getColorBasedOnProjectType = FurbanUtil.getColorBasedOnProjectType;
    public getProjectTypeText = FurbanUtil.getProjectTypeText;

    constructor(
        private projectDetailsService: ProjectDetailsService,
        private router: Router,
        private translateService: TranslateService,
        private mediaService: MediaService,
        private permitProjectService: PermitProjectService
    ) { }

    ngOnInit(): void {
        this.cardConfig.cardType = 'transparent';
        this.cardConfig.contentAlignmentHorizontal = 'center';
        this.cardConfig.contentAlignmentVertical = 'center';
        this.cardConfig.hasHeader = false;
        this.cardConfig.height = 224;
        this.cardConfig.hoverable = true;
        this.getAllPioneerProject();
    }

    public getUserMedia(mediaId: string): string {
        return this.mediaService.getUserMedia(mediaId);
    }

    public getMedia(mediaId): string {
        return this.mediaService.getMedia(mediaId);
    }

    private getAllPioneerProject(): void {
        const requests = [
            this.projectDetailsService.getAllInitiativesProjects(),
            this.permitProjectService.getAllPermitProjects(),
        ];

        forkJoin(requests).subscribe((data) => {
            if (data[0]) {
                this.projects = data[0] as PioneerProject[];
            }
            if (data[1]) {
                this.projects = this.projects.concat(
                    ...(data[1] as PioneerProject[])
                );
            }
        });
    }

    public goToProject(project: Project): void {
        let pathBeginning = routingConstants.permitProject;
        let outlet = 'permit-project';

        if (
            project.projectType &&
            project.projectType.projectTypeId ===
            ProjectTypeEnum.pioneerInitiativeProject
        ) {
            pathBeginning = routingConstants.initiativeProcess;
            outlet = 'project';
        }
        this.navigateToFirstProjectPage(project, pathBeginning, outlet);
    }

    public returnToDashboard(): void {
        this.router.navigate([routingConstants.initiatives]);
    }

    public getStatusTooltipText(project: Project): string {
        const status = this.getClassForProject(project);
        return this.translateService.instant(
            `buttonsTooltips.projectStatus.${status}`
        );
    }

    private navigateToFirstProjectPage(
        project: Project,
        pathBeginning: string,
        outlet: string
    ): void {
        if (project.projectId === 'new') {
            this.router.navigateByUrl(
                `${pathBeginning}/${project.projectId}/(${outlet}:details)`
            );
            return;
        }
        this.router.navigateByUrl(
            `${pathBeginning}/${project.projectId}/(${outlet}:overview)`
        );
    }
}
