<form class="login-form" [formGroup]="changeNameCheckForm" (ngSubmit)="submitChangeProfileForm()">
    <mat-form-field class="field-full-width" [style.margin-bottom.px]="12" appearance="outline">
        <mat-label>
            {{ 'auth.nameInputPlaceholder' | translate }}
        </mat-label>
        <input matInput required type="text" placeholder="{{ 'auth.nameInputPlaceholder' | translate }}"
            formControlName="displayName" maxlength="{{ userDisplayNameMaxLength }}" />
        <mat-error *ngIf="hasControlRequiredError('displayName',changeNameCheckForm)">
            {{ 'auth.nameRequired' | translate }}
        </mat-error>
        <mat-error *ngIf="hasControlIsHtmlError('displayName',changeNameCheckForm)L">
            {{ 'validators.noHTML' | translate }}
        </mat-error>
        <mat-error *ngIf="hasControlWhitespacesError('displayName',changeNameCheckForm)">
            {{ 'validators.noWhiteSpaces' | translate }}
        </mat-error>
        <mat-error *ngIf="hasControlIsCodeNamedError('displayName',changeNameCheckForm)">
            {{ 'validators.noCodeAsUsername' | translate }}
        </mat-error>
    </mat-form-field>
    <mat-checkbox formControlName="termsAndConditionsFormControl" class="termsOfConsition-checkbox full-width"
        [style.margin-bottom.px]="12" (change)="changeEvent($event)">
        {{ 'auth.termsAndConditionsPlaceHolder1' | translate }}
        <u><a (click)="openTermsModal($event)" class="pointer">{{
                'auth.termsAndConditionsPlaceHolder2' | translate
                }}</a></u>
        {{ 'auth.termsAndConditionsPlaceHolder3' | translate }}
    </mat-checkbox>
    <button class="primary-button full-width" type="submit" [class.disabled]="!saveIsEnabled"
        [disabled]="!saveIsEnabled">
        <h6>{{ 'generic.save' | translate }}</h6>
    </button>
</form>