import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserProfile } from '@furban/utilities';

import { environment } from 'apps/furban-client/src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CollaborativeDesignService {
    contributors: UserProfile[];
    constructor(private http: HttpClient) { }

    inviteUsers(
        emailList: string[],
        groupId: string,
        isCollaborator?: boolean
    ): Observable<void> {
        const stringArray = emailList.join();
        const formData: FormData = new FormData();

        formData.append('groupId', groupId);
        formData.append('emails', stringArray);

        const requestPath = isCollaborator ? 'collaborative' : 'citizens';

        return this.http.post<void>(
            `${environment.apiPath}/register/${requestPath}`,
            formData
        );
    }
}
