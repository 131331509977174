import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

import { environment } from 'apps/furban-client/src/environments/environment';

import { Project, PioneerProject, SilenceHttpParams } from '@furban/utilities';

import { ProjectStatus } from 'libs/utilities/src/lib/_models/project-status';

@Injectable()
export class ProjectDetailsService {
    public projectDetailsFormState = new BehaviorSubject(false);
    public projectDetailsFormStateObservable =
        this.projectDetailsFormState.asObservable();
    public projectListSubject = new Subject<Project[]>();
    public allProjectsListObservable: Observable<Project[]> =
        this.projectListSubject.asObservable();

    private _headersNoCache = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .append(
            'Cache-Control',
            'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
        )
        .append('Pragma', 'no-cache')
        .append('Vary', '*')
        .append('Expires', '0');

    private _myProjects: Project[] = [];
    private _allProjects: Project[] = [];
    private _userProjects: Project[] = [];
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(private http: HttpClient) { }

    public get myProjects(): Project[] {
        return this._myProjects;
    }

    public set myProjects(value: Project[]) {
        this._myProjects = value;
    }

    public get allProjects(): Project[] {
        return this._allProjects;
    }

    public set allProjects(value: Project[]) {
        this._allProjects = value;
    }

    public get userProjects(): Project[] {
        return this._userProjects;
    }

    public set userProjects(value: Project[]) {
        this._userProjects = value;
    }

    public getProject(projectId: string): Observable<Project> {
        return this.http
            .get(`${environment.apiPath}/project/${projectId}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Project));
    }

    public hasAccessOnProject(projectId): Observable<boolean> {
        return this.http
            .get(`${environment.apiPath}/project/hasAccess/${projectId}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as boolean));
    }

    public getPublicProject(projectId): Observable<Project> {
        return this.http
            .get(`${environment.apiPath}/project/public/${projectId}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Project));
    }

    public createProject(project: Project): Observable<Project> {
        return this.http
            .put(`${environment.apiPath}/project`, project, {
                headers: this._headers,
            })
            .pipe(
                map((result) => {
                    this.addProjectInList(result as Project);
                    return result as Project;
                })
            );
    }

    public saveProject(project: Project): Observable<Project> {
        return this.http
            .post(`${environment.apiPath}/project`, project, {
                headers: this._headers,
            })
            .pipe(
                map((result) => {
                    this.updateProjectInList(result as Project);
                    return result as Project;
                })
            );
    }

    public getAllStatus(): Observable<ProjectStatus[]> {
        return this.http
            .get<ProjectStatus[]>(`${environment.apiPath}/project/status`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as ProjectStatus[]));
    }

    public changeProjectDetailsFormState(newState: boolean) {
        this.projectDetailsFormState.next(newState);
    }

    public getAllInitiativesProjects(): Observable<PioneerProject[]> {
        return this.http
            .get(`${environment.apiPath}/project/pioneer`)
            .pipe(map((result) => result as PioneerProject[]));
    }

    public getObjectLookIdsOfObjectsSavedOnArea(projectId: string) {
        return this.http
            .get(`${environment.apiPath}/path-objects/${projectId}`)
            .pipe(map((result) => result as number[]));
    }

    public getProjects(): Observable<Project[]> {
        return this.http
            .get(environment.apiPath + '/project', {
                headers: this._headersNoCache,
                params: new SilenceHttpParams(true, true, false),
            })
            .pipe(
                map((result) => {
                    this._allProjects = result as Project[];
                    this.projectListSubject.next(this.allProjects);
                    return this.allProjects;
                })
            );
    }

    public getMyProjects(): Observable<Project[]> {
        return this.http.get(environment.apiPath + '/project/mine').pipe(
            map((result) => {
                this.myProjects = result as Project[];
                return result as Project[];
            })
        );
    }

    public getProjectsByClient(clientId: number): Observable<Project[]> {
        return this.http
            .get(environment.apiPath + '/project/client/' + clientId)
            .pipe(map((result) => result as Project[]));
    }

    public updateProjectStatus(project: Project): Observable<Project> {
        return this.http
            .post(environment.apiPath + '/project/status', project, {
                headers: this._headers,
            })
            .pipe(
                map((result) => {
                    if (!project.isActive) {
                        this.removeProjectFromList(project);
                    } else {
                        this.updateProjectInList(project);
                    }
                    return result as Project;
                })
            );
    }

    public updateProjectInList(project: Project): void {
        this._allProjects = this._allProjects.map((proj) =>
            proj.projectId === project.projectId ? project : proj
        );
        this.projectListSubject.next(this.allProjects);
    }

    private addProjectInList(project: Project): void {
        this._allProjects.unshift(project);
        this.projectListSubject.next(this.allProjects);
    }

    private removeProjectFromList(project: Project): void {
        this._allProjects = this._allProjects.filter(
            (proj) => proj.projectId !== project.projectId
        );
        this.projectListSubject.next(this.allProjects);
    }
}
