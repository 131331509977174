import { PointXY } from './point-xy';
import { PointLatLon } from './point-lat-lon';
import { HouseMaterial } from './house-material';

export class Roof {
    public roofId?: string;
    public roofMaterial?: HouseMaterial;
    public roofColor?: string;

    constructor(roofToCopy?: Roof) {
        if (roofToCopy != null) {
            this.roofId = roofToCopy.roofId;
            this.roofColor = roofToCopy.roofColor;
            this.roofMaterial = roofToCopy.roofMaterial;
        }
        return this;
    }
}
