import { on, createReducer } from '@ngrx/store';
import { HouseAsset } from '../_models/house-asset';
import {
    addAssetObject, addAssetObjectRequest, deleteAsset, deleteAssetRequest, getHouseAssets,
    getHouseInformations, getHouseInformationsRequest, loadHouseAssets, restoreInitialStatePermit, updateAsset, updateAssetRequest,
    updateHouseInformations, updateHouseInformationsRequest, undoPermit, redoPermit, undoRequestPermit, redoRequestPermit, resetHouseRequest, resetHouse, addMultipleAssetObjects, addMultipleAssetObjectsRequest, deleteMultipleAssets, deleteMultipleAssetsRequest, updateMultipleAssetObjects, updateMultipleAssetObjectsRequest, updateMultipleRoofAssetsWhenHouseChangesRequest, updateMultipleRoofAssetsWhenHouseChanges
} from './permit-actions';
import { PermitState } from './permit-state';
import { currentPermitStore } from './permit-store';

const filterAssets = (arrayToFilter: HouseAsset[], objectsToReplace: HouseAsset[]): HouseAsset[] => {
    return arrayToFilter.filter(obj => undefined === objectsToReplace.find(element => element.houseAssetsId === obj.houseAssetsId));
}

const changeHouseAssetIdForStateArray = (stateArray: PermitState[], newHouseAssets: HouseAsset[], oldHouseAssets: HouseAsset[]): PermitState[] => {
    return stateArray.map(
        (currentState) => {
            return { ...currentState, assets: replaceIdsInAssetList(currentState.assets, newHouseAssets, oldHouseAssets) };
        }
    );
}

const replaceIdsInAssetList = (assets: HouseAsset[], newHouseAssets: HouseAsset[], oldHouseAssets: HouseAsset[]): HouseAsset[] => {
    return assets.map(
        (assetFromList) => {
            const index = oldHouseAssets.map(asset => asset.houseAssetsId).indexOf(assetFromList.houseAssetsId);
            return index >= 0 ? { ...assetFromList, houseAssetsId: newHouseAssets[index].houseAssetsId } : assetFromList;
        }
    )


}

export const PermitReducer = createReducer(
    currentPermitStore,

    on(getHouseInformations, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            houseInfo: action.house,
            assetsToAdd: [],
            shouldUpdateHouse: true,
            areAssetsLoaded: false,
            isRequestAction: false
        }
    })),

    on(getHouseInformationsRequest, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true
        }
    })),

    on(loadHouseAssets, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            assets: action.objects,
            assetsToAdd: action.objects,
            shouldUpdateHouse: false,
            areAssetsLoaded: true,
            isRequestAction: false
        }
    })),


    on(getHouseAssets, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true

        }
    })),



    on(addAssetObject, (store, action) => ({
        ...store,
        previous: [...store.previous, store.actual],
        future: [],
        actual: {
            ...store.actual,
            assets: [...store.actual.assets, action.object],
            assetsToAdd: [action.object],
            assetsToRemove: [],
            shouldUpdateHouse: false,
            areAssetsLoaded: false,
            isRequestAction: false
        }
    })),

    on(addAssetObjectRequest, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true

        }
    })),

    on(addMultipleAssetObjects, (store, action) => ({
        ...store,
        previous: [...store.previous, store.actual],
        future: [],
        actual: {
            ...store.actual,
            assets: [...store.actual.assets, ...action.objects],
            assetsToAdd: action.objects,
            assetsToRemove: [],
            shouldUpdateHouse: false,
            areAssetsLoaded: false,
            isRequestAction: false
        }
    })),

    on(addMultipleAssetObjectsRequest, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true
        }
    })),

    on(updateHouseInformations, (store, action) => ({
        ...store,
        previous: action.shouldUpdatePrevious ? [...store.previous, store.actual] : [...store.previous],
        future: [],
        actual: {
            ...store.actual,
            assetsToAdd: [],
            assetsToRemove: [],
            houseInfo: action.house,
            shouldUpdateHouse: true,
            areAssetsLoaded: false,
            isRequestAction: false
        }
    })),

    on(updateHouseInformationsRequest, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true
        }
    })),

    on(deleteAsset, (store, action) => ({
        ...store,
        previous: [...store.previous, store.actual],
        future: [],
        actual: {
            ...store.actual,
            assets: store.actual.assets.filter(obj => obj.houseAssetsId !== action.object.houseAssetsId),
            assetsToAdd: [],
            assetsToRemove: [action.object],
            shouldUpdateHouse: false,
            areAssetsLoaded: false,
            isRequestAction: false
        }
    })),


    on(deleteAssetRequest, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true
        }
    })),

    on(deleteMultipleAssets, (store, action) => ({
        ...store,
        previous: [...store.previous, store.actual],
        future: [],
        actual: {
            ...store.actual,
            assets: filterAssets(store.actual.assets, action.objects),
            assetsToAdd: [],
            assetsToRemove: action.objects,
            shouldUpdateHouse: false,
            areAssetsLoaded: false,
            isRequestAction: false
        }
    })),


    on(deleteMultipleAssetsRequest, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true
        }
    })),

    on(updateMultipleAssetObjects, (store, action) => ({
        ...store,
        previous: [...store.previous, store.actual],
        future: [],
        actual: {
            ...store.actual,
            assets: [...filterAssets(store.actual.assets, action.objects), ...action.objects],
            assetsToAdd: [],
            assetsToRemove: action.objects,
            shouldUpdateHouse: false,
            areAssetsLoaded: false,
            isRequestAction: false
        }
    })),


    on(updateMultipleAssetObjectsRequest, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true
        }
    })),


    on(updateMultipleRoofAssetsWhenHouseChanges, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            assets: [...filterAssets(store.actual.assets, action.objects), ...action.objects],
            assetsToAdd: [],
            assetsToRemove: [],
            areAssetsLoaded: false,
            isRequestAction: false
        }
    })),


    on(updateMultipleRoofAssetsWhenHouseChangesRequest, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true
        }
    })),

    on(updateAsset, (store, action) => ({
        ...store,
        previous: [...store.previous, store.actual],
        future: [],
        actual: {
            ...store.actual,
            assets: store.actual.assets.map(obj =>
                (obj.houseAssetsId === action.object.houseAssetsId) ?
                    { ...obj, coordinates: action.object.coordinates, rotation: action.object.rotation, scale: action.object.scale, color: action.object.color, material: action.object.material } :
                    obj),
            assetsToAdd: [],
            assetsToRemove: [],
            shouldUpdateHouse: false,
            areAssetsLoaded: false,
            isRequestAction: false
        }
    })),

    on(updateAssetRequest, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true
        }
    })),

    on(undoPermit, (store, action) => (
        {
            future: changeHouseAssetIdForStateArray([...store.future, store.actual], action.newObjects, action.objectsToReplace),
            actual: {
                ...store.previous[store.previous.length - 1],
                assetsToRemove:
                    [...action.objectsToReMove, ...action.newObjects],
                assetsToAdd: [...action.newObjects],
                assets: [...filterAssets(store.previous[store.previous.length - 1].assets, action.objectsToReplace), ...action.newObjects],
                shouldUpdateHouse: !!action.houseToSave,
                areAssetsLoaded: false,
                isRequestAction: false
            },
            previous: changeHouseAssetIdForStateArray([...store.previous].slice(0, store.previous.length - 1), action.newObjects, action.objectsToReplace)
        })),

    on(undoRequestPermit, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true
        }
    })),

    on(redoPermit, (store, action) => ({
        previous: changeHouseAssetIdForStateArray([...store.previous, store.actual], action.newObjects, action.objectsToReplace),
        actual: {
            ...store.future[store.future.length - 1],
            assetsToRemove:
                [...action.objectsToReMove, ...action.newObjects],
            assetsToAdd: [...action.newObjects],
            shouldUpdateHouse: !!action.houseToSave,
            assets: [...filterAssets(store.future[store.future.length - 1].assets, action.objectsToReplace), ...action.newObjects],
            areAssetsLoaded: false,
            isRequestAction: false
        },
        future: changeHouseAssetIdForStateArray([...store.future].slice(0, store.future.length - 1), action.newObjects, action.objectsToReplace)
    })),

    on(redoRequestPermit, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true
        }
    })),


    on(resetHouse, (store, action) => ({
        previous: [],
        actual: {
            ...store.actual,
            assetsToRemove:
                store.actual.assets,
            assetsToAdd: [],
            shouldUpdateHouse: true,
            assets: [],
            areAssetsLoaded: false,
            isRequestAction: false,
            houseInfo: action.house
        },
        future: [],
    })),

    on(resetHouseRequest, (store) => ({
        ...store,
        actual: {
            ...store.actual,
            isRequestAction: true
        }
    })),


    on(restoreInitialStatePermit, () => ({
        actual: {
            assets: [],
            assetsToRemove: [],
            assetsToAdd: [],
        },
        previous: [],
        future: []
    })),
);

