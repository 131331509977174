import { DashboardItem } from '../_models/dashboard-item';
import { routingConstants } from './routing-constants';

export class PioneerDashboardItemsArray {
    public static createDashboardItems(projectId: string): DashboardItem[] {
        return [
            {
                id: 0,
                title: 'admin.cardsLabels.projectDetailsPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/proj-details.png')",
                path: `${routingConstants.initiativesProject}/${projectId}/details`,
            },
            {
                id: 1,
                title: 'admin.cardsLabels.projectAreaPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/proj-area.png')",
                path: `${routingConstants.initiativesProject}/${projectId}/area-definition`,
            },
            {
                id: 2,
                title: 'admin.cardsLabels.manageObjectsPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/manage-obj.png')",
                path: `${routingConstants.initiativesProject}/${projectId}/objects`,
            },
            {
                id: 3,
                title: 'admin.cardsLabels.manageCitizenAndExpertsPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/manage-users.png')",
                path: `${routingConstants.initiativesProject}/${projectId}/manage-users`,
            },
            {
                id: 4,
                title: 'admin.cardsLabels.designProposalsPageHeaderText',
                backgroundImage:
                    "url('../../../../assets/images/project-dashboard-cards-images/design-prop.png')",
                path: `${routingConstants.initiativesProject}/${projectId}/project-designs`,
            },
        ];
    }
}
