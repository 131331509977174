<ng-container *ngIf="type === 0">
    <mat-dialog-content
        [style.margin-bottom.px]="16"
        [class.open-emoji]="emojiOpened"
    >
        <ng-container *ngTemplateOutlet="viewComments"></ng-container>
    </mat-dialog-content>
</ng-container>

<ng-container *ngIf="type === 1">
    <div
        class="vertical-scrollable container-for-scroll"
        [class.open-emoji]="emojiOpened"
    >
        <ng-container *ngTemplateOutlet="viewComments"></ng-container>
    </div>
</ng-container>

<ng-template #viewComments>
    <ng-container *ngIf="checkIfNoComments(); else sortedCommentsAvailable">
        <div class="flex-column-center padding-20">
            <img
                src="../../../assets/images/no_comm.svg"
                [style.height.px]="200"
                [style.margin-bottom.px]="20"
            />
            <h4 class="placeholder-text word-break-normal">
                {{ 'comments.noComments' | translate }}
            </h4>
        </div>
    </ng-container>
    <ng-template #sortedCommentsAvailable>
        <div *ngFor="let comment of sortedComments">
            <section
                class="avatar flex-row-start-start card-type full-width"
                (click)="selectComment(comment)"
            >
                <ng-template
                    *ngTemplateOutlet="
                        commentShow;
                        context: { $implicit: comment }
                    "
                >
                </ng-template>
            </section>
            <ng-container *ngIf="comment.childComments?.length > 0">
                <section
                    *ngFor="let childComment of comment.childComments"
                    class="avatar flex-row-start-start card-type"
                    [style.margin-left.px]="24"
                    (click)="selectComment(comment)"
                >
                    <ng-template
                        *ngTemplateOutlet="
                            commentShow;
                            context: { $implicit: childComment }
                        "
                    >
                    </ng-template>
                </section>
            </ng-container>
        </div>
    </ng-template>
</ng-template>

<ng-container *ngIf="shouldDisplayForm()">
    <furban-comment-form
        #commentForm
        [designProposal]="dp"
        [isPinComment]="false"
        [hidePinBtn]="isCitizenDesign"
        (toggleEmojiEvent)="onToggleEmoji($event)"
        (saveCommentEvent)="onSaveComment($event)"
    >
    </furban-comment-form>
</ng-container>

<ng-template #commentShow let-comment>
    <furban-avatar-picture
        [name]="comment.userProfile.screenName"
        [size]="40"
        [mediaId]="getUserMedia(comment.userProfile.mediaId)"
    >
    </furban-avatar-picture>
    <div
        class="user-description"
        [class.highlighted]="
            comment.commentId === commentsService.highlightedComment?.commentId
        "
        [class.reported-comment-highlight]="comment.commentId === commentId"
    >
        <div class="flex-row-spaced">
            <furban-app-user-action-menu
                *ngIf="parentViewContainerRef"
                [userProfile]="comment.userProfile"
                [parentViewContainerRef]="parentViewContainerRef"
                [reportedDesignProposal]="comment.reportsCount > 0 ? dp : null"
                [reportedComment]="comment.reportsCount > 0 ? comment : null"
                [projectName]="project.name"
                (userDelete)="userDelete($event)"
            ></furban-app-user-action-menu>
            <span
                class="material-icons reported-icon"
                *ngIf="comment.reportsCount > 0 && authService.isAdmin()"
                matTooltip="{{
                    'report.reportAdminInfoTooltip'
                        | translate : { noOfReporters: comment.reportsCount }
                }}"
            >
                warning
            </span>
        </div>
        <div
            class="reported-content-custom"
            *ngIf="shouldDisplayReportInfo(comment)"
        >
            <p>{{ 'report.reportInfoMessage' | translate }}</p>
            <button class="primary-button" (click)="comment.reportsCount = 0">
                {{ 'report.reportAgreeMessage' | translate }}
            </button>
        </div>
        <p class="word-break-normal" *ngIf="!shouldDisplayReportInfo(comment)">
            {{ comment.commentText }}
        </p>
        <span *ngIf="comment.position" class="material-icons location-span">
            location_on
        </span>
    </div>
    <span
        class="material-icons"
        [matMenuTriggerFor]="menuAdd"
        (click)="preventQuickViewClose($event, comment)"
    >
        more_vert
    </span>
    <mat-menu #menuAdd="matMenu" overlapTrigger="false">
        <button
            mat-menu-item
            *ngIf="!comment.parentCommentId"
            (click)="reply(comment)"
            value="reply"
        >
            {{ 'generic.reply' | translate }}
        </button>
        <button
            mat-menu-item
            *ngIf="!authService.isAdmin()"
            (click)="report(comment)"
            value="report"
        >
            {{ 'report.reportBtn' | translate }}
        </button>
        <button
            mat-menu-item
            (click)="deleteComment(comment)"
            value="delete"
            *ngIf="isAuthUserComment(comment) || authService.isAdmin()"
        >
            {{ 'generic.remove' | translate }}
        </button>
        <button
            mat-menu-item
            *ngIf="authService.isAdmin() && comment.reportsCount > 0"
            (click)="deleteReporting(comment)"
        >
            {{ 'report.deleteReportBtn' | translate }}
        </button>
    </mat-menu>
</ng-template>
