import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FurbanUtil } from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';
import { Review } from '../../_enums/review.enum';
import { PermitReview } from '../../_models/permit-review';
import { UpdatePermitReview } from '../../_models/update-permit-review';

@Component({
    selector: 'furban-approval-popup',
    templateUrl: './approval-dialog.component.html',
    styleUrls: ['./approval-dialog.component.scss'],
})
export class PermitApprovalDialogComponent implements OnInit {
    public form: FormGroup;
    public review = Review;
    private permitReview: PermitReview;
    private htmlValidator = FurbanUtil.htmlContentValidator;
    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<PermitApprovalDialogComponent>,
        private formBuilder: FormBuilder,
        private translateService: TranslateService
    ) {
        this.permitReview = data.permitReview;
    }

    ngOnInit(): void {
        this.setupForm();
    }

    public closePopup(): void {
        this.dialogRef.close();
    }

    public isButtonDisabled(): boolean {
        return (
            this.form.controls['requestResponse'].value ===
            this.review.REQUIRED_CHANGED &&
            (!this.form.controls['message'].value ||
                this.form.controls['message'].value.trim() === '')
        );
    }

    public resolveResponse(): void {
        if (!this.form.valid) {
            return;
        }
        const formValue = this.form.value;
        const updatePermitReview = new UpdatePermitReview(
            this.permitReview.permitReviewId,
            formValue.requestResponse,
            this.permitReview.project.projectId,
            formValue.message
        );
        this.dialogRef.close(updatePermitReview);
    }

    private setupForm(): void {
        const responseInitialValue =
            this.permitReview.projectStatus?.projectStatusId !== 1
                ? this.permitReview.projectStatus?.projectStatusId
                : this.review.APPROVED;
        this.form = this.formBuilder.group({
            message: [this.permitReview.message, this.htmlValidator],
            requestResponse: [responseInitialValue.toString()],
        });
    }
}
