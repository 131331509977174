import {
    addMultipleObjects,
    addMultipleObjectsRequest,
    addObjectRequest,
    addObject,
    deleteMultipleObjects,
    deleteMultipleObjectsRequest,
    deleteObject,
    deleteObjectRequest,
    loadDefaultDesignObjects,
    loadDefaultObjects,
    loadUserObjects,
    redo,
    restoreInitialState,
    undo,
    updateObject,
    updateObjectRequest,
    updateMultipleObjects,
    updateMultipleObjectsRequest,
    undoRequest,
    redoRequest,
    restartDesignRequest,
    setInitialAvailableBudget,
    loadLiveObjects,
} from './three-actions';
import { on, createReducer } from '@ngrx/store';
import { currentStore } from './three-store';

export const ThreeReducer = createReducer(
    currentStore,

    on(loadDefaultObjects, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            userObjects: [...store.actual.userObjects, ...action.objects],
            objectsToAdd: action.objects,
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(loadLiveObjects, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            userObjects: action.objects,
            objectsToAdd: action.objects,
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(setInitialAvailableBudget, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            budget: action.budget,
        },
    })),

    on(loadUserObjects, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            userObjects: [...store.actual.userObjects, ...action.objects],
            objectsToAdd: action.objects,
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(loadDefaultDesignObjects, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            userObjects: action.objects,
            objectsToAdd: action.objects,
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(addObject, (store, action) => ({
        ...store,
        previous: [...store.previous, store.actual],
        future: [],
        actual: {
            ...store.actual,
            userObjects: [...store.actual.userObjects, action.object],
            objectsToAdd: [action.object],
            objectsToRemove: [],
            shouldGroup: false,
            shouldSelect: action.shouldSelect,
            budget:
                action.budget || action.budget === 0
                    ? action.budget
                    : store.actual.budget,
        },
    })),

    on(addMultipleObjects, (store, action) => ({
        ...store,
        previous: [...store.previous, store.actual],
        future: [],
        actual: {
            ...store.actual,
            userObjects: [...store.actual.userObjects, ...action.objects],
            objectsToRemove: [],
            objectsToAdd: [...action.objects],
            shouldGroup: action.shouldGroup,
            shouldSelect: action.shouldSelect,
            budget:
                action.budget || action.budget === 0
                    ? action.budget
                    : store.actual.budget,
        },
    })),

    on(deleteObject, (store, action) => ({
        ...store,
        previous: [...store.previous, store.actual],
        future: [],
        actual: {
            ...store.actual,
            defaultObjects: store.actual.defaultObjects.filter(
                (obj) => obj.pathObjsId !== action.object.pathObjsId
            ),
            userObjects: store.actual.userObjects.filter(
                (obj) => obj.pathObjsId !== action.object.pathObjsId
            ),
            objectsToRemove: [action.object],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
            budget:
                action.budget || action.budget === 0
                    ? action.budget
                    : store.actual.budget,
        },
    })),

    on(deleteMultipleObjects, (store, action) => ({
        ...store,
        previous: [...store.previous, store.actual],
        future: [],
        actual: {
            ...store.actual,
            defaultObjects: store.actual.defaultObjects.filter(
                (obj) =>
                    undefined ===
                    action.objects.find(
                        (element) => element.pathObjsId === obj.pathObjsId
                    )
            ),
            userObjects: store.actual.userObjects.filter(
                (obj) =>
                    undefined ===
                    action.objects.find(
                        (element) => element.pathObjsId === obj.pathObjsId
                    )
            ),
            objectsToRemove: [...action.objects],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
            budget:
                action.budget || action.budget === 0
                    ? action.budget
                    : store.actual.budget,
        },
    })),

    on(updateObject, (store, action) => ({
        ...store,
        previous: [...store.previous, store.actual],
        future: [],
        actual: {
            ...store.actual,
            userObjects: store.actual.userObjects.map((obj) =>
                obj.pathObjsId === action.object.pathObjsId
                    ? {
                          ...obj,
                          position: action.object.position,
                          angle: action.object.angle,
                          freeshapePoints: action.object.freeshapePoints,
                      }
                    : obj
            ),
            objectsToAdd: [],
            objectsToRemove: [],
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(updateMultipleObjects, (store, action) => ({
        ...store,
        previous: [...store.previous, store.actual],
        future: [],
        actual: {
            ...store.actual,
            userObjects: [
                ...store.actual.userObjects.filter(
                    (obj) =>
                        undefined ===
                        action.objects.find(
                            (element) => element.pathObjsId === obj.pathObjsId
                        )
                ),
                ...action.objects,
            ],
            defaultObjects: [
                ...store.actual.defaultObjects.filter(
                    (obj) =>
                        undefined ===
                        action.objects.find(
                            (element) => element.pathObjsId === obj.pathObjsId
                        )
                ),
            ],

            objectsToAdd: [],
            objectsToRemove: [],
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(restoreInitialState, (store, action) => ({
        actual: {
            defaultObjects: [],
            userObjects: [],
            objectsToRemove: [],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
        },
        previous: [],
        future: [],
    })),

    on(undo, (store, action) => ({
        future: [...store.future, store.actual],
        actual: {
            ...store.previous[store.previous.length - 1],
            objectsToRemove: [
                ...store.actual.defaultObjects,
                ...store.actual.userObjects,
            ],
            objectsToAdd: [
                ...store.previous[
                    store.previous.length - 1
                ].defaultObjects.filter(
                    (obj) =>
                        undefined ===
                        action.objectsToReplace.find(
                            (element) => element.pathObjsId === obj.pathObjsId
                        )
                ),
                ...store.previous[store.previous.length - 1].userObjects.filter(
                    (obj) =>
                        undefined ===
                        action.objectsToReplace.find(
                            (element) => element.pathObjsId === obj.pathObjsId
                        )
                ),
                ...action.newObjects,
            ],
            shouldGroup: false,
            shouldSelect: false,
            userObjects: [
                ...store.previous[store.previous.length - 1].userObjects.filter(
                    (obj) =>
                        undefined ===
                        action.objectsToReplace.find(
                            (element) => element.pathObjsId === obj.pathObjsId
                        )
                ),
                ...action.newObjects,
            ],
        },
        previous: [...store.previous].slice(0, store.previous.length - 1),
    })),

    on(redo, (store, action) => ({
        previous: [...store.previous, store.actual],
        actual: {
            ...store.future[store.future.length - 1],
            objectsToRemove: [
                ...store.actual.defaultObjects,
                ...store.actual.userObjects,
            ],
            objectsToAdd: [
                ...store.future[store.future.length - 1].defaultObjects.filter(
                    (obj) =>
                        undefined ===
                        action.objectsToReplace.find(
                            (element) => element.pathObjsId === obj.pathObjsId
                        )
                ),
                ...store.future[store.future.length - 1].userObjects.filter(
                    (obj) =>
                        undefined ===
                        action.objectsToReplace.find(
                            (element) => element.pathObjsId === obj.pathObjsId
                        )
                ),
                ...action.newObjects,
            ],
            userObjects: [
                ...store.future[store.future.length - 1].userObjects.filter(
                    (obj) =>
                        undefined ===
                        action.objectsToReplace.find(
                            (element) => element.pathObjsId === obj.pathObjsId
                        )
                ),
                ...action.newObjects,
            ],
            shouldGroup: false,
            shouldSelect: false,
        },
        future: [...store.future].slice(0, store.future.length - 1),
    })),

    on(deleteObjectRequest, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            objectsToRemove: [],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(updateObjectRequest, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            objectsToRemove: [],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(addObjectRequest, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            objectsToRemove: [],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(deleteMultipleObjectsRequest, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            objectsToRemove: [],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(addMultipleObjectsRequest, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            objectsToRemove: [],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(addMultipleObjectsRequest, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            objectsToRemove: [],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(updateMultipleObjectsRequest, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            objectsToRemove: [],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(undoRequest, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            objectsToRemove: [],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(redoRequest, (store, action) => ({
        ...store,
        actual: {
            ...store.actual,
            objectsToRemove: [],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
        },
    })),

    on(restartDesignRequest, (store, action) => ({
        previous: [],
        actual: {
            ...store.actual,
            userObjects: [],
            objectsToRemove: [],
            objectsToAdd: [],
            shouldGroup: false,
            shouldSelect: false,
            budget: action.budget,
        },
        future: [],
    }))
);
