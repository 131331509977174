<div *ngIf="showButtons" class="floating-buttons right">
    <button *ngIf="canExportDesign && !isApp()" matTooltip="{{ 'buttonsTooltips.exportDesign' | translate }}"
        class="round-button" (click)="exportSceneToGLTF()">
        <span class="material-icons">cloud_download</span>
    </button>
    <button class="round-button" matTooltip="{{ 'buttonsTooltips.change3Dperspective' | translate }}"
        (click)="changeCameraPerspective()">
        <furban-svg-container [svgImage]="svgImageType.changePerspective"></furban-svg-container>
    </button>

    <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.viewFromTop' | translate }}" (click)="viewFromTop()">
        <span class="material-icons">
            vertical_align_top
        </span>
    </button>

    <button *ngIf="isLoggedIn" class="round-button" matTooltip="{{ 'buttonsTooltips.addComments' | translate }}"
        (click)="toggleAddingCommentsOnClick()">
        <span class="material-icons" [class.active]="addingCommentsEnabled">add_location</span>
    </button>
    <ng-container *ngIf="isRightMenuVisible; else noMenuButton">
        <button class="round-button" matTooltip="{{ 'buttonsTooltips.hideComments' | translate }}"
            (click)="toggleRightMenuVisibiltiy()">
            <span class="material-icons">speaker_notes_off</span>
        </button>
    </ng-container>
    <ng-template #noMenuButton>
        <button class="round-button" matTooltip="{{ 'buttonsTooltips.showComments' | translate }}"
            (click)="toggleRightMenuVisibiltiy()">
            <span class="material-icons"> comment </span>
        </button>
    </ng-template>
</div>

<div class="remove-events" id="htmlRenderer"></div>
<canvas id="canvasThreeJS" class="gradient-background" #canvas3d></canvas>

<div *ngIf="isLoggedIn" id="pinPopupHTML" class="flex-column-center" [hidden]="!isThreeLoaded">
    <furban-three-comment-popup #commentPopup [dp]="designProposal" [position]="pinPosition"
        [comment]="currentPinComment" (closePopUp)="onPinPopupClose($event)" (savedComment)="onPinCommentSaved($event)">
    </furban-three-comment-popup>
</div>