<ng-container *ngIf="!cookiesAccepted && !isEmbededComponent">
    <section class="cookies-toaster">
        <img src="assets/icons/cookies.png" />
        <div class="text">
            <p>
                {{ 'cookies.textCookie.0' | translate }}
                <a (click)="openCookiePolicyDialog()">{{
                    'cookies.textCookie.1' | translate
                }}</a>
                {{ 'cookies.textCookie.2' | translate }}
            </p>
        </div>
        <button class="secondary-button white" (click)="acceptCookies()">
            <h6>{{ 'generic.accept' | translate }}</h6>
        </button>
    </section>
</ng-container>
