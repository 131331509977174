import { Injectable, ViewContainerRef } from '@angular/core';
import {
    CanDeactivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Modifiable } from './modifiable.interface';

import { ModalManager, routingConstants } from '@furban/utilities';
import { tap } from 'rxjs/operators';

@Injectable()
export class ModifiedGuard<T> implements CanDeactivate<T> {
    constructor(private modal: ModalManager) { }

    canDeactivate(
        component: T,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        // there is another message for logout, so there is no reaseon to ask for discarding changes one more time
        if (
            nextState.url === routingConstants.admin ||
            nextState.url === routingConstants.app ||
            nextState.url === routingConstants.info
        ) {
            return true;
        }

        const modifiable: Modifiable = (<any>component) as Modifiable;
        if (modifiable && modifiable.modified) {
            if (modifiable.promptForChanges) {
                return modifiable.promptForChanges();
            } else {
                return this.promptForChanges(modifiable.containerRef);
            }
        }
        return true;
    }

    public promptForChanges(
        viewContainerRef: ViewContainerRef
    ): Observable<any> {
        return this.modal
            .showModal(
                viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'formChangesDialog.message',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .pipe(tap());
    }
}
