import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomToasterService {
    constructor(
        private snackbar: MatSnackBar,
        private translateService: TranslateService
    ) {}

    openCustomToaster(
        component: ComponentType<unknown>,
        icon: string,
        type: string,
        text: string,
        duration = 2000
    ) {
        return this.snackbar.openFromComponent(component, {
            verticalPosition: 'bottom' as MatSnackBarVerticalPosition,
            horizontalPosition: 'right' as MatSnackBarHorizontalPosition,
            duration: duration,
            data: {
                icon: icon,
                message: this.translateService.instant(text),
                action: this.translateService.instant('generic.okBtn'),
                type: type,
            },
        });
    }
}
