import {
    Component,
    OnInit,
    ViewContainerRef,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { ProjectDetailsService } from './project-details.service';
import {
    FormBuilder,
    Validators,
    FormGroup,
    FormControl,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ImageUploadComponent } from '../../shared/image-upload/image-upload.component';
import { ProjectDashboardHeaderComponent } from '../project-dashboard-header/project-dashboard-header.component';

import {
    ProjectInvolvementTypeEnum,
    ModalManager,
    AuthService,
    containSpaceErrorAndNotRequirredTriggered,
    CustomToasterComponent,
    DocumentModel,
    FileUploadService,
    FurbanUtil,
    Project,
    projectConstants,
    ProjectStatusEnum,
    ProjectStorageService,
    ProjectType,
    ProjectTypeEnum,
    ProjectTypeNameEnum,
    routingConstants,
    ProjectService,
    StepperService,
    CustomToasterService,
    ToolingActionsEnum,
    ControlsUtil,
} from '@furban/utilities';
import { ToolingService } from '../../shared/tooling/tooling.service';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'furban-app-project-details',
    templateUrl: './project-details.component.html',
    styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
    @ViewChild('projectDashboardHeader')
    projectDashboardHeader: ProjectDashboardHeaderComponent;

    public projectConstantValues = projectConstants;
    public projectId: string;
    public projectCheckForm: FormGroup;
    public today = new Date(Date.now());
    public project: Project;
    public imageData: string;
    public documents: DocumentModel[];
    public minEndDate = new Date(Date.now());
    public minStartDate = new Date(
        this.today.setMonth(
            this.today.getMonth() -
            this.projectConstantValues
                .numberOfMonthsInPastForProrjectStartDate
        )
    );
    public minVotingExipringDate = new Date(Date.now());
    public redirectAfterSave: string = routingConstants.adminProject;
    public isPioneerProject = false;
    public noWhiteSpacesValidator = FurbanUtil.noWhiteSpacesValidator;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public projectType: string;
    public containSpaceErrorAndNotRequirredTriggeredFunction =
        containSpaceErrorAndNotRequirredTriggered;

    private fileList: FileList;
    private formChangesSubscription: Subscription;
    private toolingActionsSubscription: Subscription;
    private endDateChangeSubscription: Subscription;
    private datePipe = new DatePipe('en-US');
    public hasControlMaxError = ControlsUtil.hasControlMaxError;
    public hasControlMinError = ControlsUtil.hasControlMinError;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;
    public hasControlMaxLengthError = ControlsUtil.hasControlMaxLengthError;
    public hasControlMinLengthError = ControlsUtil.hasControlMinLengthError;
    public hasControlIsHtmlError = ControlsUtil.hasControlIsHtmlError;
    public hasControlWhitespacesError = ControlsUtil.hasControlWhitespacesError;
    public isApp = FurbanUtil.isApp;

    constructor(
        private projectDetailsService: ProjectDetailsService,
        private projectService: ProjectService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        public containerRef: ViewContainerRef,
        private customToasterService: CustomToasterService,
        private modal: ModalManager,
        private translateService: TranslateService,
        private fileUploadService: FileUploadService,
        private projectStorageService: ProjectStorageService,
        private authService: AuthService,
        private stepperService: StepperService,
        private toolingService: ToolingService
    ) {
        this.initForm();
    }

    ngOnInit(): void {
        this.project = this.stepperService.project as Project;
        this.projectId = this.stepperService.projectId;
        this.projectType = this.stepperService.projectType;

        if (this.stepperService.projectId !== 'new') {
            this.getProjectDataFromServer(this.project);
        } else {
            this.initializeNewProject();
        }

        if (this.authService.isPioneer()) {
            this.initializeDataForPioneer();
        }
        this.createFormSubscription();
        this.subscribeToToolingActionObservable();
        this.subscribeToEndDateChangeEvent();
    }

    ngOnDestroy(): void {
        this.formChangesSubscription.unsubscribe();
        this.stepperService.changeProjectModifiedState(false);
        this.toolingActionsSubscription.unsubscribe();
        this.endDateChangeSubscription.unsubscribe();
    }

    initForm(): void {
        this.projectCheckForm = this.formBuilder.group({
            projectNameFormControl: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(
                        this.projectConstantValues.displayNameMaxLength
                    ),
                    this.noWhiteSpacesValidator,
                    this.htmlValidator,
                ],
            ],
            projectPriceFormControl: [
                '',
                [
                    Validators.maxLength(9),
                    Validators.min(1),
                    Validators.max(999999999),
                ],
            ],
            descriptionFormControl: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(
                        this.projectConstantValues.descriptionMaxLength
                    ),
                    this.noWhiteSpacesValidator,
                    this.htmlValidator,
                ],
            ],
            projectStartDateFormControl: ['', [Validators.required]],
            projectEndDateFormControl: ['', [Validators.required]],
            projectVotingExpiringDateFormControl: ['', [Validators.required]],
            priceRestrictControl: false,
            allowCitizenDesignsControl: true,
        });
    }

    getProjectDataFromServer(data: Project): void {
        this.project = data;
        this.documents = this.project.documents;
        this.projectCheckForm
            .get('projectNameFormControl')
            .setValue(this.project.name, { emitEvent: false });
        this.projectCheckForm
            .get('descriptionFormControl')
            .setValue(this.project.description, { emitEvent: false });

        this.projectCheckForm
            .get('projectStartDateFormControl')
            .setValue(
                new Date(
                    this.datePipe.transform(
                        this.project.startDate,
                        'yyyy-MM-dd'
                    )
                ),
                { emitEvent: false }
            );
        this.projectCheckForm
            .get('projectEndDateFormControl')
            .setValue(
                new Date(
                    this.datePipe.transform(this.project.endDate, 'yyyy-MM-dd')
                ),
                { emitEvent: false }
            );
        this.projectCheckForm
            .get('projectVotingExpiringDateFormControl')
            .setValue(
                new Date(
                    this.datePipe.transform(
                        this.project.votingExpiringDate,
                        'yyyy-MM-dd'
                    )
                ),
                { emitEvent: false }
            );

        this.projectCheckForm
            .get('priceRestrictControl')
            .setValue(this.project.isPriceRestrictionEnabled, {
                emitEvent: false,
            });
        this.projectCheckForm
            .get('allowCitizenDesignsControl')
            .setValue(this.project.allowCitizenDesigns, { emitEvent: false });
        if (this.project.price) {
            this.projectCheckForm
                .get('projectPriceFormControl')
                .setValue(this.project.price, { emitEvent: false });
        }

        if (this.project.media) {
            this.imageData = `${environment.apiPath}/media/public/${this.project.media}`;
        }
        if (this.project?.hasWinner) {
            this.projectCheckForm.disable({ emitEvent: false });
        }
    }

    openImageUploadDialog(): void {
        const imageDialogRef = this.dialog.open(ImageUploadComponent);
        imageDialogRef.disableClose = true;
        imageDialogRef.componentInstance.parentRef = this.containerRef;
        imageDialogRef.componentInstance.imageWidth = 600;
        imageDialogRef.componentInstance.imageHeight = 336;
        imageDialogRef.componentInstance.imageUploadPath = '/project';

        const closeProfileSub =
            imageDialogRef.componentInstance.onImageUploadClose.subscribe(
                (data) => {
                    if (data) {
                        this.imageData = `${environment.apiPath}/media/public/${data}`;
                        this.project.media = data;
                        this.stepperService.changeProjectModifiedState(true);
                    }

                    imageDialogRef.close();
                }
            );

        imageDialogRef.afterClosed().subscribe(() => {
            closeProfileSub.unsubscribe();
        });

        imageDialogRef.backdropClick().subscribe(() => {
            imageDialogRef.componentInstance.closeImageUploadModal();
        });
    }

    fileChange(event): void {
        this.fileList = event.target.files;
        const numberOfDocsInserted = this.fileList.length;
        let numberOfDocsOnProject = this.documents ? this.documents.length : 0;

        if (this.isNumberOfDocsInvalid(numberOfDocsOnProject, numberOfDocsInserted)) {
            this.displayUploadError(numberOfDocsInserted);
            return;
        }

        const formData: FormData = new FormData();
        for (let i = 0; i < numberOfDocsInserted; i++) {
            if (this.isNumberOfDocsInvalid(numberOfDocsOnProject)) {
                this.showUploadingFileErrorSnackbar('admin.projectDetails.exceedingNoDocs');
                break;
            }
            if (this.fileList[i].size > this.projectConstantValues.maxFileSizeLimit) {
                this.showUploadingFileErrorSnackbar(
                    'admin.projectDetails.exceedingFileSize'
                );
                continue;
            }
            numberOfDocsOnProject++;

            formData.append(
                'files',
                this.fileList[i],
                FurbanUtil.reduceFileNameLength(this.fileList[i].name, 30)
            );
        }

        if (numberOfDocsOnProject === 0) {
            return;
        }

        this.uploadFiles(formData);
        this.stepperService.changeProjectModifiedState(true);
    }

    // REFACTOR
    public saveProject(): void {
        if (
            this.project &&
            this.project.projectStatus &&
            this.project.projectStatus.statusWeight ===
            ProjectStatusEnum.archived
        ) {
            this.snackbarMessage(
                'admin.projectActivate.saveArchiveWarning',
                2000,
                this.translateService.instant('generic.okBtn'),
                'info'
            );
            this.projectCheckForm
                .get('projectEndDateFormControl')
                .setErrors(null);
            return;
        }

        if (this.projectCheckForm.invalid) {
            for (const key in this.projectCheckForm.controls) {
                if (Object.prototype.hasOwnProperty.call(this.projectCheckForm.controls, key)) {
                    const control: FormControl = <FormControl>(
                        this.projectCheckForm.controls[key]
                    );
                    control.markAsDirty();
                }
            }
            return;
        }

        this.addFormData();

        this.setProjectType();

        this.setProjectPrice();

        if (this.projectId !== 'new') {
            this.updateExistingProject();
        } else {
            this.createNewProject();
        }

        this.stepperService.changeProjectModifiedState(false);
    }

    createProject(project: Project): void {
        this.projectDetailsService.createProject(project).subscribe((data) => {
            if (this.projectType === ProjectTypeNameEnum.adminProject) {
                this.projectDetailsService.getMyProjects().subscribe();
                this.projectStorageService.emitOnProjectListModified();
            }

            this.showConfirmationOnSave(data);

            if (this.projectType === ProjectTypeNameEnum.adminProject) {
                this.stepperService.regenerateMunicipalitySteps(data, true);
            } else if (this.projectType === ProjectTypeNameEnum.pioneerInitiativeProject) {
                this.stepperService.regenerateInitiativeSteps(data, true);
            } else {
                console.error('Something went wrong when generating steps');
            }
        });
    }

    showConfirmationOnSave(data: Project): void {
        this.snackbarMessage(
            'admin.projectDetails.projectSaved',
            2000,
            this.translateService.instant('generic.okBtn'),
            'success'
        );
        this.stepperService.project = this.project = data;
        this.projectCheckForm.markAsUntouched();
    }

    clickUploadFileLink(): void {
        (<HTMLElement>(
            document.getElementsByClassName('file-upload-input')[0]
        )).click();
    }

    deleteDocumentLabel(document: DocumentModel): void {
        this.showDeleteWarningSnackBar(this.deleteFile, document);
    }

    showUploadingFileErrorSnackbar(errorMessage: string): void {
        this.modal
            .showModal(
                this.containerRef,
                ModalManager.createConfiguration('errors.error', errorMessage)
            )
            .subscribe();
    }

    showDeleteWarningSnackBar(
        callbackFunction: (document: DocumentModel) => void,
        document: DocumentModel
    ) {
        this.modal
            .showModal(
                this.containerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.projectDetails.projectDeleteWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    if (document) {
                        callbackFunction.apply(this, [document]);
                        document.isActive = false;
                    } else {
                        callbackFunction.apply(this);
                    }
                }
            });
    }

    deleteFile(document: DocumentModel): void {
        document.isActive = false;
        this.fileUploadService.deleteFile(document).subscribe();
        const indexOfDocument = FurbanUtil.findIndexWithAttr(
            this.documents,
            'documentId',
            document.documentId
        );
        this.documents.splice(indexOfDocument, 1);
    }

    snackbarMessage(
        message: string,
        duration: number,
        action?: string,
        type?: string
    ): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            type === 'info' ? 'info' : '',
            type,
            message,
            duration
        );
    }

    uploadFiles(formData: FormData): void {
        this.fileUploadService.uploadFiles(formData).subscribe(
            (data) => {
                if (!this.documents) {
                    this.documents = data;
                } else {
                    this.documents = this.documents.concat(data);
                }
            }
        );
    }

    downloadDocument(document: DocumentModel): void {
        if (this.isApp()) {
            return;
        }
        this.fileUploadService.openDocumentInNewTab(document.documentId);
    }

    displayUploadError(numberOfDocsInserted: number) {
        if (numberOfDocsInserted === 0) {
            this.showUploadingFileErrorSnackbar('admin.projectDetails.noDocs');
        } else {
            this.showUploadingFileErrorSnackbar(
                'admin.projectDetails.exceedingNoDocs'
            );
        }
    }

    getProjectDescriptionPatternErrorMessage(): string {
        return this.projectCheckForm
            .get('descriptionFormControl')
            .value.trim() === ''
            ? this.translateService.instant(
                'admin.projectDetails.projectDescriptionBlankSpaces'
            )
            : this.translateService.instant(
                'admin.projectDetails.projectDescriptionInvalidCharacters'
            );
    }

    hasDocumentsOrNoWinner(): boolean {
        return !this.project?.hasWinner || this.documents.length !== 0;
    }

    subscribeToEndDateChangeEvent(): void {
        this.endDateChangeSubscription = this.projectCheckForm
            .get('projectEndDateFormControl')
            .valueChanges.subscribe((updatedEndDate) => {
                if (
                    this.isCurrentVotingDateInvalidAccordingToEndDate(
                        updatedEndDate
                    )
                ) {
                    const updatedEndDateValue = this.datePipe.transform(
                        updatedEndDate,
                        'yyyy-MM-dd'
                    );
                    this.projectCheckForm
                        .get('projectVotingExpiringDateFormControl')
                        .setValue(
                            new Date(
                                this.datePipe.transform(
                                    updatedEndDateValue,
                                    'yyyy-MM-dd'
                                )
                            ),
                            { emitEvent: false }
                        );
                }
            });
    }

    private isCurrentVotingDateInvalidAccordingToEndDate(
        updatedEndDate: Date
    ): boolean {
        const votingExpiringDate = this.projectCheckForm.get(
            'projectVotingExpiringDateFormControl'
        ).value;
        return votingExpiringDate === '' || votingExpiringDate < updatedEndDate;
    }

    private addFormData(): void {
        let startDateValue = this.projectCheckForm.get(
            'projectStartDateFormControl'
        ).value;
        let endDateValue = this.projectCheckForm.get(
            'projectEndDateFormControl'
        ).value;
        let votingExpiringDateValue = this.projectCheckForm.get(
            'projectVotingExpiringDateFormControl'
        ).value;

        startDateValue = this.datePipe.transform(startDateValue, 'yyyy-MM-dd');
        endDateValue = this.datePipe.transform(endDateValue, 'yyyy-MM-dd');
        votingExpiringDateValue = this.datePipe.transform(
            votingExpiringDateValue,
            'yyyy-MM-dd'
        );

        this.project.name = this.projectCheckForm
            .get('projectNameFormControl')
            .value.trim();
        this.project.description = this.projectCheckForm
            .get('descriptionFormControl')
            .value.trim();
        this.project.isPriceRestrictionEnabled = this.projectCheckForm.get(
            'priceRestrictControl'
        ).value;
        this.project.allowCitizenDesigns = this.projectCheckForm.get(
            'allowCitizenDesignsControl'
        ).value;
        this.project.startDate = startDateValue;
        this.project.endDate = endDateValue;
        this.project.votingExpiringDate = votingExpiringDateValue;
        this.project.documents = this.documents;
        this.project.ended = null;
    }

    private isNumberOfDocsInvalid(numberOfDocsOnProject: number, numberOfDocsInserted?: number): boolean {
        return (numberOfDocsOnProject >= this.projectConstantValues.maxNumberOfDocsAccepted) ||
            (numberOfDocsInserted && numberOfDocsInserted === 0);
    }

    private setProjectType(): void {
        if (this.projectType === ProjectTypeNameEnum.pioneerInitiativeProject) {
            this.project.projectType = new ProjectType(
                ProjectTypeEnum.pioneerInitiativeProject
            );
        } else {
            if (this.projectType === ProjectTypeNameEnum.pioneerPermitProject) {
                this.project.projectType = new ProjectType(
                    ProjectTypeEnum.pioneerPermitProject
                );
            } else {
                this.project.projectType = new ProjectType(
                    ProjectTypeEnum.adminProject
                );
            }
        }
    }

    private setProjectPrice(): void {
        const projectPriceValue = this.projectCheckForm.get(
            'projectPriceFormControl'
        ).value;
        if (projectPriceValue) {
            this.project.price = projectPriceValue;
        } else {
            this.project.price = null;
            this.project.isPriceRestrictionEnabled = false;
            this.projectCheckForm
                .get('priceRestrictControl')
                .setValue(this.project.isPriceRestrictionEnabled, {
                    emitEvent: false,
                });
        }
    }

    private updateExistingProject(): void {
        this.projectDetailsService
            .saveProject(this.project)
            .subscribe((data) => {
                this.showConfirmationOnSave(data);

                if (this.project.projectType.projectTypeName === ProjectTypeNameEnum.adminProject) {
                    this.stepperService.regenerateMunicipalitySteps(data, true);
                } else if (this.project.projectType.projectTypeName === ProjectTypeNameEnum.pioneerInitiativeProject) {
                    this.stepperService.regenerateInitiativeSteps(data, true);
                } else {
                    console.error('Something went wrong when generating steps');
                }
            });
    }

    private createNewProject(): void {
        if (this.project.documents) {
            this.fileUploadService
                .getFiles(this.project.documents)
                .subscribe((data) => {
                    this.project.documents = data;
                    this.createProject(this.project);
                });
        } else {
            this.createProject(this.project);
        }
    }

    private initializeDataForPioneer(): void {
        this.isPioneerProject = true;
        if (this.projectType === ProjectTypeNameEnum.pioneerInitiativeProject) {
            this.redirectAfterSave = routingConstants.initiativesProject;
        } else {
            this.redirectAfterSave = routingConstants.permitProject;
        }
    }

    private initializeNewProject(): void {
        this.project = new Project();
        this.project.isCitizenDesigns =
            this.projectService.projectInvolvementType ===
            ProjectInvolvementTypeEnum.citizen;
        this.project.allowCitizenDesigns = true;
    }

    private createFormSubscription(): void {
        this.formChangesSubscription =
            this.projectCheckForm.valueChanges.subscribe(() => {
                this.stepperService.changeProjectModifiedState(true);
            });
    }

    private subscribeToToolingActionObservable(): void {
        this.toolingActionsSubscription =
            this.toolingService.toolingActionsObservable
                .pipe(
                    filter(
                        (data) =>
                            data &&
                            data === ToolingActionsEnum.saveProjectDetails
                    )
                )
                .subscribe(() => {
                    this.saveProject();
                });
    }
}
