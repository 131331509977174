import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AdminNewService } from './admin-new.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
    ModalManager,
    AuthService,
    Client,
    MunicipalityService,
    routingConstants,
} from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-app-admin-new',
    templateUrl: './admin-new.component.html',
    styleUrls: ['./admin-new.component.scss'],
})
export class AdminNewComponent implements OnInit {
    public selectedMunicipality: Client;
    public adminCreateFormGroup: FormGroup;
    public isSuperAdmin: boolean;
    public municipalities: Client[];

    constructor(
        private adminNewService: AdminNewService,
        private authService: AuthService,
        private municipalityService: MunicipalityService,
        private modal: ModalManager,
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private translateService: TranslateService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.initializeForm();
        this.isSuperAdmin = this.authService.isSuperAdmin();
        if (!this.isSuperAdmin) {
            this.router.navigateByUrl(routingConstants.adminDashboard);
        } else {
            this.getMunicipalities();
        }
    }

    public addNewAdmin(): void {
        this.adminNewService
            .addNewAdmin(
                this.adminCreateFormGroup
                    .get('emailFormControl')
                    .value.trim()
                    .toLowerCase(),
                this.adminCreateFormGroup.get('passwordFormControl').value,
                this.adminCreateFormGroup.get('clientFormControl').value
                    .clientId
            )
            .subscribe(
                {
                    next: (data) => {
                        this.modal
                            .showModal(
                                this.viewContainerRef,
                                ModalManager.createConfiguration(
                                    this.translateService.instant(
                                        'generic.successTitle'
                                    ),
                                    this.translateService.instant(
                                        'superadmin.newAdmin.createdUserMessage'
                                    )
                                )
                            )
                            .subscribe((res) => console.log('succes'));
                    },
                    error: (error) => {
                        // login failed so display error
                        if (error && error.status === 401) {
                            this.modal
                                .showModal(
                                    this.viewContainerRef,
                                    ModalManager.createConfiguration(
                                        this.translateService.instant(
                                            'errors.error'
                                        ),
                                        this.translateService.instant(
                                            'superadmin.newAdmin.notCreatedUserMessage'
                                        )
                                    )
                                )
                                .subscribe((res) =>
                                    console.log('dismissed: ' + res)
                                );
                        }
                    }
                }
            );
    }

    public showPasswordError(): boolean {
        return (
            this.adminCreateFormGroup
                .get('passwordFormControl')
                ?.hasError('password') &&
            !this.adminCreateFormGroup
                .get('passwordFormControl')
                ?.hasError('required')
        );
    }

    public showEmailError(): boolean {
        return (
            this.adminCreateFormGroup
                .get('emailFormControl')
                ?.hasError('email') &&
            !this.adminCreateFormGroup
                .get('emailFormControl')
                ?.hasError('required')
        );
    }

    private initializeForm(): void {
        this.adminCreateFormGroup = this.formBuilder.group({
            emailFormControl: ['', [Validators.required, Validators.email]],
            passwordFormControl: ['', [Validators.required]],
            clientFormControl: ['', Validators.required],
        });
    }

    protected getMunicipalities(): void {
        this.municipalityService.getAllClients().subscribe((data) => {
            this.municipalities = data as Client[];
        });
    }
}
