import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
    Input,
    HostListener,
    ViewChild
} from '@angular/core';
import { BaseProjectMenuComponent } from '../base-project-menu/base-project-menu.component';

import {
    AuthService,
    CategoryObject,
    CustomToasterService,
    DragUploadComponent,
    Emitted3DObject,
    FurbanUtil,
    Menu3DEventsEnum,
    MenuCustomTabEnum,
    MenuService,
    navigationAndHeaderDimensions,
    ShepherdTourService,
    OBJECTS_MENU_TOUR_ITEMS,
    ObjectTypeEnum,
    ObjectUtil,
    Project3dModel,
    ThreeUtils,
    Tool,
    ToolNameEnum,
    TourEvent
} from '@furban/utilities';
import { PublishService } from '../../publish-popup/publish.service';
import { Router } from '@angular/router';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { NotificationToasterComponent } from '../../../shared/notification-toaster/notification-toaster.component';

import { CustomToasterComponent } from '@furban/utilities';

// REFACTOR - move animation to his file
@Component({
    selector: 'furban-project-menu-three-d',
    templateUrl: './project-menu-three-d.component.html',
    styleUrls: ['./project-menu-three-d.component.scss'],
    animations: [
        trigger('hoverInOut', [
            transition('void => *', [
                style({ transform: 'translateX(50%)', opacity: 0 }),
                animate('100ms 100ms'),
            ]),
            transition('* => void', [
                animate(
                    100,
                    style({ transform: 'translateX(50%)', opacity: 0 })
                ),
            ]),
        ]),
    ],
})
export class ProjectMenuThreeDComponent extends BaseProjectMenuComponent implements OnInit, OnDestroy {
    @Input() currentlyEnabledTool: Tool;
    @Output() override removeLastUnfinishedFreeShape: EventEmitter<any> = new EventEmitter();
    @Output() override addedObject: EventEmitter<any> = new EventEmitter();
    @Output() added3DObject: EventEmitter<Emitted3DObject> = new EventEmitter();
    @Output() addMultiple3DObject: EventEmitter<Emitted3DObject> = new EventEmitter();
    @Output() addRowOfObjects: EventEmitter<Emitted3DObject> = new EventEmitter();
    @Output() changedTool: EventEmitter<Tool> = new EventEmitter();
    @Output() addGreenObjects: EventEmitter<boolean> = new EventEmitter();
    @Output() highlightDropingArea: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('customObjectUpload') customObjectUpload: DragUploadComponent;
    @ViewChild('undergroundUpload') undergroundUpload: DragUploadComponent;

    public isAppOrMobile = FurbanUtil.isAppOrMobile;

    public isMenuExpanded: boolean = this.menuService.menuOpened;
    public objectToAddOnClick: Project3dModel;
    public toolName = ToolNameEnum;
    public currentCategory: string;
    public activeItem: CategoryObject;
    public customTab = MenuCustomTabEnum.tooling;
    public objectsMenuTourItems = OBJECTS_MENU_TOUR_ITEMS;
    public isMobile = FurbanUtil.isMobile;
    public hoverImagePath: string;
    public isToolsHover: boolean;
    public hoverActionDescription: string;
    public hoverObjectName: string;
    public hoverContainerTop: number;
    public minTopForHover: number;
    public heightOfHoverDiv = 200;
    public headerHeight: number;
    public exceedTopPosition: boolean;
    public scrollHidden: boolean;
    public customTabNames = MenuCustomTabEnum;

    constructor(
        public override authService: AuthService,
        public override publishService: PublishService,
        public override shepherdTourService: ShepherdTourService,
        public override router: Router,
        public menuService: MenuService,
        public snackBar: MatSnackBar,
        public translateService: TranslateService,
        public customToasterService: CustomToasterService,
        @Inject(DOCUMENT) private document: Document
    ) {
        super(authService, publishService, shepherdTourService, router);
        this.onResize();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.minTopForHover = window.innerHeight - this.heightOfHoverDiv;
    }

    ngOnInit() {
        this.preventContextEventOnMobile();
        this.subscribeTourStartingEvents();
        this.headerHeight = this.hasAdministrativeRole()
            ? navigationAndHeaderDimensions.headerAndDashboard
            : navigationAndHeaderDimensions.header;
    }

    ngOnDestroy() {
        this.removeEventsOnDocument();
        this.menuService.objectToAddOnClick = null;
    }

    public get enableHoverOnMenuItem(): boolean {
        return this.hoverImagePath && !this.isMobile();
    }

    public get shouldShowMenu(): boolean {
        return this.menuService.shouldShowMenu;
    }

    public hasAdministrativeRole() {
        return this.authService.hasAdministrativeRole();
    }

    public shouldDisplayPrice(): boolean {
        return (
            this.authService.isAdminDesignRouteOrNotAdministrativeRole() &&
            !this.authService.isCollaborator() &&
            !!this.project.price
        );
    }

    checkForActiveTab(categoryObject: CategoryObject): boolean {
        return categoryObject === this.activeItem;
    }

    public navigateToTab(item: CategoryObject): void {
        this.toggleMenu(item);
        this.activeItem = item;
        this.currentCategory = item.category.categoryName;
    }

    public showToaster(icon: string, type: string, message: string, duration: number): void {
        this.customToasterService.openCustomToaster(CustomToasterComponent, icon, type, message, duration);
    }

    checkToolsTabIsActive(): boolean {
        return (
            this.activeItem === undefined &&
            this.customTab === MenuCustomTabEnum.tooling
        );
    }

    isObjectSelected(object: Project3dModel): boolean {
        return this.objectToAddOnClick === object;
    }

    public toggleMenu(item?: CategoryObject): void {
        if (item && this.checkForActiveTab(item)) {
            this.isMenuExpanded = !this.isMenuExpanded;
            this.changeMenuState();
        } else if (!this.isMenuExpanded) {
            this.isMenuExpanded = true;
            this.changeMenuState();
        }
    }

    public isDropEventInsideMenu(event: CdkDragDrop<string[]>): boolean {
        const offsetLeft = event.item.element.nativeElement.offsetLeft;
        return event.distance.x + offsetLeft < 300;
    }

    public drop(event: CdkDragDrop<string[]>, models: Project3dModel[]): void {
        this.highlightDropingArea.emit(false);
        this.currentlyEnabledTool = null;
        if (this.isDropEventInsideMenu(event)) {
            return;
        }
        const id = event.item.element.nativeElement.id;
        if (this.freeShapeDrawing) {
            this.removeLastUnfinishedFreeShape.emit();
        }

        const objectToAdd: Project3dModel = models.find(
            (obj) => obj.project3DModelId.toString() === id
        );

        this.setCustomHouseType(objectToAdd);
        this.emitLastAddedObject(objectToAdd, true);
    }

    public dragStart(object: Project3dModel): void {
        if (object.furban3DModel?.placeOutside) {
            return;
        }
        this.highlightDropingArea.emit(true);
    }

    public onRegularObjectSelection(selectedObject: Project3dModel): void {
        this.currentlyEnabledTool = null;
        if (this.freeShapeDrawing) {
            this.removeLastUnfinishedFreeShape.emit();
        }
        this.emitLastAddedObject(selectedObject, false);
    }

    public removeContext(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }

    public preventContextEventOnMobile() {
        if (FurbanUtil.isMobile()) {
            this.document.addEventListener('contextmenu', this.removeContext);
        }
    }

    public holdHandler(event: string, selectedObject: Project3dModel) {
        this.currentlyEnabledTool = null;
        this.removeObjectToAdd();

        if (this.freeShapeDrawing) {
            this.removeLastUnfinishedFreeShape.emit();
        }

        if (event === Menu3DEventsEnum.none) {
            return;
        }

        if (event === Menu3DEventsEnum.click) {
            this.onObjectClick(selectedObject);
            return;
        }

        if (event === Menu3DEventsEnum.longPress) {
            this.menuService.objectToAddOnClick =
                selectedObject.furban3DModel.objectLookId;

            const emitted3DObject: Emitted3DObject = new Emitted3DObject(
                selectedObject,
                false
            );

            if (
                ObjectUtil.isCustomHouse(
                    emitted3DObject.project3DModel.furban3DModel.objectLookId
                )
            ) {
                emitted3DObject.project3DModel.freeshapePoints = JSON.stringify(
                    ThreeUtils.customHouseDefaultDim
                );
                emitted3DObject.project3DModel.objectType =
                    ObjectTypeEnum.custom;
            }
            this.addMultiple3DObject.emit(emitted3DObject);
            this.open3DNotificationSnackbar(
                this.authService,
                this.translateService,
                'userSettings.multipleMessage'
            );
        }
    }

    public addObjectsInRow(): void {
        if (this.freeShapeDrawing) {
            this.removeLastUnfinishedFreeShape.emit();
        }

        this.changeStateForCurrentlyEnabledTool(ToolNameEnum.rowOfObjects);

        if (this.isToolEnabled(ToolNameEnum.rowOfObjects)) {
            return;
        }

        this.addRowOfObjects.emit();
    }

    public onMultipleSelectionStateChange(): void {
        if (this.freeShapeDrawing) {
            this.removeLastUnfinishedFreeShape.emit();
        }
        this.changeStateForCurrentlyEnabledTool(ToolNameEnum.multipleSelection);
        this.open3DNotificationSnackbar(
            this.authService,
            this.translateService,
            'userSettings.dragMessage'
        );
    }

    public removeObjectToAdd() {
        this.menuService.objectToAddOnClick = null;
        this.addMultiple3DObject.emit(null);
        this.snackBar.dismiss();
    }

    public removeEventsOnDocument() {
        this.document.removeEventListener('contextmenu', this.removeContext);
    }

    public generateGreenObjects(): void {
        if (this.freeShapeDrawing) {
            this.removeLastUnfinishedFreeShape.emit();
        }

        this.changeStateForCurrentlyEnabledTool(ToolNameEnum.greenObjects);

        if (this.isToolEnabled(ToolNameEnum.greenObjects)) {
            return;
        }
        this.addGreenObjects.emit();
    }

    public isToolEnabled(toolName: string): boolean {
        return (
            this.currentlyEnabledTool?.toolName === toolName &&
            this.currentlyEnabledTool?.enabled
        );
    }

    public getDragDelay() {
        return FurbanUtil.isMobile() ? 70 : 0;
    }

    public mouseEnterObject(object: Project3dModel, event) {
        const srcValue = this.composeImageString(
            object.furban3DModel.objectLookId
        );
        this.hoverObjectName = this.translateService.instant(
            object.furban3DModel.translationsLabel
        );
        this.mouseEnterWithSrc(srcValue, event);
    }

    public mouseEnterWithSrc(imagePath: string, event) {
        if (this.displayHoverFunctionality()) {
            this.hoverImagePath = imagePath;
            this.hoverContainerTop = this.getTopPosition(event);
        }
    }

    public mouseLeave() {
        this.hoverImagePath = null;
        this.isToolsHover = false;
        this.hoverActionDescription = null;
        this.hoverObjectName = null;
    }

    public getTopPosition(event) {
        const mouseHoverPosition: number =
            event.clientY - this.heightOfHoverDiv;
        let computedPosition = Math.min(
            mouseHoverPosition,
            this.minTopForHover - 8
        );
        if (computedPosition < this.headerHeight) {
            this.exceedTopPosition = true;
            computedPosition = 8;
        } else {
            this.exceedTopPosition = false;
        }
        return computedPosition;
    }

    public displayHoverFunctionality(): boolean {
        if (
            this.authService != null &&
            this.authService.userProfile != null &&
            this.authService.userProfile.userSettings != null
        ) {
            return this.authService.userProfile.userSettings
                .isHoverHelperActive;
        }
        return true;
    }

    public checkIfShouldDisplayTooltip(messageCode: string) {
        if (!this.displayHoverFunctionality()) {
            return this.translateService.instant(messageCode);
        }
        return null;
    }

    public canUpdateDesign() {
        return (
            (!this.publishService.designProposal.isActive ||
                this.publishService.designProposal.isAdminDesign) &&
            !this.project.ended
        );
    }

    private changeStateForCurrentlyEnabledTool(toolName: string): void {
        const selectedTool = new Tool(toolName, true);
        if (this.currentlyEnabledTool?.toolName === toolName) {
            selectedTool.enabled = !this.currentlyEnabledTool.enabled;
        }
        this.changedTool.emit(selectedTool);
    }

    private onObjectClick(selectedObject: Project3dModel): void {
        this.setCustomHouseType(selectedObject);
        this.onRegularObjectSelection(selectedObject);
    }

    private setCustomHouseType(customObject: Project3dModel): void {
        if (ObjectUtil.isCustomHouse(customObject.furban3DModel.objectLookId)) {
            customObject.objectType = ObjectTypeEnum.custom;
        }
    }
    private emitLastAddedObject(
        addedObject: Project3dModel,
        isDragged: boolean
    ) {
        const emitted3DObject: Emitted3DObject = new Emitted3DObject(
            addedObject,
            isDragged
        );
        this.added3DObject.emit(emitted3DObject);
    }

    protected open3DNotificationSnackbar(
        authService: AuthService,
        translateService: TranslateService,
        message: string
    ) {
        if (authService.getUserSettings().show3DNotifications) {
            this.customToasterService.openCustomToaster(
                NotificationToasterComponent,
                '3d_rotation',
                'notification',
                translateService.instant(message),
                0
            );
        }
    }

    private changeMenuState() {
        this.menuService.toggleNav(this.isMenuExpanded);
        this.removeObjectToAdd();
    }

    private subscribeTourStartingEvents() {
        this.shepherdTourService.tourStepChangedEmitter.subscribe((data) => {
            switch (data) {
                case TourEvent.noScrollEvent:
                    this.scrollHidden = true;
                    break;
                case TourEvent.objectPlacementEvent:
                    this.isMenuExpanded = !this.isMenuExpanded;
                    this.changeMenuState();
                    break;
                case TourEvent.showMenuEvent:
                    this.isMenuExpanded = true;
                    this.scrollHidden = true;
                    this.changeMenuState();
                    break;
                case TourEvent.endEvent:
                    this.isMenuExpanded = true;
                    this.scrollHidden = false;
                    this.changeMenuState();
                    break;
                default:
                    break;
            }
        });
    }
}
