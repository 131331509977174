import { Component, Input, ViewContainerRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'furban-privacy-modal-component',
    templateUrl: './privacy-modal.component.html',
    styleUrls: ['./privacy-modal.component.scss'],
})
export class PrivacyModalComponent {
    @Input() public parentRef: ViewContainerRef;

    constructor(private dialogRef: MatDialogRef<PrivacyModalComponent>) {}

    closeDialog(): void {
        this.dialogRef.close(false);
    }
}
