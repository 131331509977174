import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserProfileService } from '../user-profile/user-profile.service';
import { UserProfile } from '@furban/utilities';

@Component({
    selector: 'furban-winner-congratulations',
    templateUrl: './winner-congratulations.component.html',
    styleUrls: ['./winner-congratulations.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WinnerCongratulationsComponent implements OnInit {
    public confetti: any;
    public userProfile: UserProfile;
    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<WinnerCongratulationsComponent>,
        private userProfileService: UserProfileService
    ) {
        this.userProfile = data.userProfile;
    }

    ngOnInit() {
        this.confetti = Array.from(Array(80).keys());
        this.startConfetti();
    }

    closeDialog() {
        this.userProfile.winner = false;
        this.userProfileService
            .updateProfile(this.userProfile)
            .subscribe((data) => {
                this.dialogRef.close();
            });
    }

    startConfetti() {
        const confettiContainer = document.getElementById('confetti-container');
        const speed = ['slow', 'medium', 'fast'];
        const colors = ['#d13447', '#ffbf00', '#263672'];

        setInterval(() => {
            const confetti = document.createElement('div');
            const left = Math.floor(Math.random() * 90) + 1;
            const backgroundColor =
                colors[Math.floor(Math.random() * colors.length)];
            const animationSpeed =
                speed[Math.floor(Math.random() * speed.length)];

            confetti.classList.add('confetti', 'animation-' + animationSpeed);
            confetti.style.left = left + '%';
            confetti.style.width = Math.floor(Math.random() * 3) + 7 + 'px';
            confetti.style.height = Math.floor(Math.random() * 3) + 7 + 'px';
            confetti.style.backgroundColor = backgroundColor;
            setTimeout(() => {
                confetti.parentNode.removeChild(confetti);
            }, 1500);

            confettiContainer.appendChild(confetti);
        }, 25);
    }
}
