import { Vector3 } from 'three/';

export class ReferenceVectors {
    public first: Vector3;
    public second: Vector3;

    constructor(first: Vector3, second: Vector3) {
        this.first = first;
        this.second = second;
    }
}
