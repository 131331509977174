import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ImageUploadService {
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );
    constructor(private http: HttpClient) {}

    uploadPhoto(
        size: number,
        title: string,
        media: string,
        contentType: string,
        imageUploadPath: string
    ) {
        const REQUEST_DATA = {
            title: title.trim().toLowerCase(), // optional
            contentType: contentType,
            content: media,
            fileName: title.trim().toLowerCase(),
        };

        return this.http
            .put(
                environment.apiPath + '/media' + imageUploadPath,
                REQUEST_DATA,
                {
                    headers: this._headers,
                    responseType: 'text',
                }
            )
            .pipe(map((result) => result));
    }

    getContentType(content: string): string {
        if ('' === content) {
            return '';
        }
        if (content.indexOf('data:') < 0) {
            return '';
        }
        const endIndex = content.indexOf(';');
        if (endIndex < 0) {
            return '';
        }
        return content.substring(5, endIndex);
    }
}
