import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'furban-configurable-header',
    templateUrl: './configurable-header.component.html',
    styleUrls: ['./configurable-header.component.scss'],
})
export class ConfigurableHeaderComponent {
    @Input() backRoute?: string;
    @Input() isSticky?: boolean;

    constructor(private router: Router) { }

    goBack() {
        this.router.navigateByUrl(this.backRoute);
    }
}
