import {
    Component,
    OnInit,
    ViewChild,
    ViewContainerRef,
    Input,
    EventEmitter,
    ChangeDetectorRef,
    AfterViewChecked,
} from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    Validators,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AdminAuthService } from '../admin-auth/admin-auth.service';
import {
    AuthService,
    Card,
    CustomToasterComponent,
    FurbanUtil,
    ModalManager,
    MultilanguageService,
    MyErrorStateMatcher,
    UserProfile,
    User,
    CustomToasterService,
    validatePassword,
    ControlsUtil,
} from '@furban/utilities';
import { UserProfileService } from '../../user/user-profile/user-profile.service';
import { UserSettingsComponent } from '../../user/user-settings/user-settings.component';
import { forkJoin, Observable } from 'rxjs';
import { Modifiable } from '../../../shared/modifyStatus/modifiable.interface';
import { ImageUploadComponent } from '../../../shared/image-upload/image-upload.component';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'furban-app-admin-profile',
    templateUrl: './admin-profile.component.html',
    styleUrls: ['./admin-profile.component.scss'],
})
export class AdminProfileComponent
    implements OnInit, Modifiable, AfterViewChecked {
    @ViewChild('changePassForm') changePassForm;
    @ViewChild('changeNameForm') changeNameForm;
    @ViewChild('userSettingsComponent')
    userSettingsComponent: UserSettingsComponent;
    @Input() public parentRef: ViewContainerRef;
    @Input() public profileContent: string;
    onProfileClose = new EventEmitter();

    public userProfile: UserProfile = new UserProfile();
    public languageModified = false;
    public errorMatcher = new MyErrorStateMatcher();
    public changePassCheckForm: FormGroup;
    public changeNameCheckForm: FormGroup;
    public cardConfig: Card = new Card();
    public selectedRadio: string;
    public imageData;
    public passwordConfirmValidator = FurbanUtil.passwordConfirmValidator;
    public noWhiteSpacesValidator = FurbanUtil.noWhiteSpacesValidator;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public getPasswordErrorMessage = FurbanUtil.getPasswordErrorMessage;

    public hasControlMaxError = ControlsUtil.hasControlMaxError;
    public hasControlMinError = ControlsUtil.hasControlMinError;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;
    public hasControlMaxLengthError = ControlsUtil.hasControlMaxLengthError;
    public hasControlMinLengthError = ControlsUtil.hasControlMinLengthError;
    public hasControlIsHtmlError = ControlsUtil.hasControlIsHtmlError;
    public hasControlWhitespacesError = ControlsUtil.hasControlWhitespacesError;
    public hasControlEmailError = ControlsUtil.hasControlEmailError;
    private imageId;

    constructor(
        public translateService: TranslateService,
        public dialog: MatDialog,
        private adminAuthService: AdminAuthService,
        private userProfileService: UserProfileService,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private _multilanguageService: MultilanguageService,
        private modal: ModalManager,
        private viewContainerRef: ViewContainerRef,
        private customToasterService: CustomToasterService,
        private dialogRef: MatDialogRef<AdminProfileComponent>,
        private cdRef: ChangeDetectorRef
    ) { }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    ngOnInit() {
        this.userProfile = this.authService.userProfile;
        if (this.userProfile.mediaId) {
            this.imageData = `${environment.apiPath}/media/public/${this.userProfile.mediaId}`;
        }
        this.changePassCheckForm = this.formBuilder.group(
            {
                oldPasswordFormControl: ['', Validators.required],
                passwordFormControl: [
                    '',
                    [Validators.required, validatePassword],
                ],
                passwordConfirmFormControl: ['', Validators.required],
            },
            { validator: this.passwordConfirmValidator }
        );

        this.changeNameCheckForm = this.formBuilder.group({
            name: [
                '',
                [
                    Validators.required,
                    this.noWhiteSpacesValidator,
                    this.htmlValidator,
                    Validators.maxLength(60),
                ],
            ],
        });
        this.changeNameCheckForm
            .get('name')
            .setValue(this.userProfile.screenName);

        this.selectedRadio = this._multilanguageService.usedLang;
    }

    public get getPasswordValidationErrors(): ValidationErrors | null {
        return this.changePassCheckForm?.get('passwordFormControl')?.errors;
    }

    closeModal() {
        if (this.profileContent === 'profile') {
            if (this.changeNameCheckForm.dirty) {
                this.modal
                    .showModal(
                        this.parentRef,
                        ModalManager.createConfiguration(
                            'errors.warning',
                            'errors.discardMessage',
                            'generic.yesBtn',
                            'generic.noBtn'
                        )
                    )
                    .subscribe((res) => {
                        if (res) {
                            this.onProfileClose.emit('closeProfile');
                        }
                    });
            } else {
                this.dialogRef.close();
            }
        }

        if (this.profileContent === 'password') {
            if (
                this.changePassCheckForm.get('oldPasswordFormControl').dirty ||
                this.changePassCheckForm.get('passwordFormControl').dirty ||
                this.changePassCheckForm.get('passwordConfirmFormControl').dirty
            ) {
                this.modal
                    .showModal(
                        this.parentRef,
                        ModalManager.createConfiguration(
                            'errors.warning',
                            'errors.discardMessage',
                            'generic.yesBtn',
                            'generic.noBtn'
                        )
                    )
                    .subscribe((res) => {
                        if (res) {
                            this.onProfileClose.emit('closeProfile');
                        }
                    });
            } else {
                this.dialogRef.close();
            }
        }
    }

    changePassword() {
        if (this.changePassCheckForm.invalid) {
            this.changePassCheckForm
                .get('oldPasswordFormControl')
                .markAsDirty();
            this.changePassCheckForm.get('passwordFormControl').markAsDirty();
            this.changePassCheckForm
                .get('passwordConfirmFormControl')
                .markAsDirty();
            return;
        }

        this.adminAuthService
            .changeUserPass(
                this.changePassCheckForm.get('oldPasswordFormControl').value,
                this.changePassCheckForm.get('passwordFormControl').value,
                this.changePassCheckForm.get('passwordConfirmFormControl').value
            )
            .subscribe((data) => {
                this.clearPasswordForm();
                this.openSnackbar();
                this.changePassCheckForm.markAsUntouched();
                if (data && data['userId']) {
                    this.getUserProfile(data);
                }
                this.dialogRef.close();
            });
    }

    changeName() {
        if (this.changeNameCheckForm.invalid) {
            this.changeNameCheckForm.get('name').markAsDirty();
            return;
        }

        const displayNameValue = this.changeNameCheckForm
            .get('name')
            .value.trim();
        if (this.userProfile !== null && this.userProfile.userProfileId) {
            this.userProfile.screenName = displayNameValue;
            this.userProfile.userId = this.authService.user.userId;
            if (this.imageId) {
                this.userProfile.mediaId = this.imageId;
            }

            const show3DNotifications =
                this.userSettingsComponent.userSettingsCheckForm.get(
                    'show3DNotificationsFormControl'
                ).value;
            const isHoverHelperActive =
                this.userSettingsComponent.userSettingsCheckForm.get(
                    'isHoverHelperActiveControl'
                ).value;

            const requests: Observable<any>[] = [
                this.userProfileService.updateProfile(this.userProfile),
            ];

            if (
                this.userProfile.userSettings !== null &&
                (this.userProfile.userSettings.show3DNotifications !==
                    show3DNotifications ||
                    this.userProfile.userSettings.isHoverHelperActive !==
                    isHoverHelperActive)
            ) {
                this.userProfile.userSettings.show3DNotifications =
                    show3DNotifications;
                this.userProfile.userSettings.isHoverHelperActive =
                    isHoverHelperActive;
                requests.push(
                    this.authService.updateUserSettings(
                        this.userProfile.userSettings
                    )
                );
            }

            forkJoin(requests).subscribe((data) => {
                this.openSnackbar();
                this.changeNameCheckForm.markAsUntouched();
                this.dialogRef.close();
            });
        }
    }

    getUserProfile(data) {
        this.authService.user = data as User;
        this.authService.getUserProfile();
    }

    clearPasswordForm() {
        this.changePassForm.resetForm();
        this.changePassCheckForm.markAsUntouched();
    }

    openSnackbar() {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            'generic.success',
            3000
        );
    }

    passwordMatchValidator(c: AbstractControl): { invalid: boolean } {
        if (c.get('passwordFormControl').value !== c.get('passwordConfirmFormControl').value) {
            return { invalid: true };
        }
        return { invalid: false };
    }

    switchLanguage() {
        this._multilanguageService.updateUsedLanguage();
        this.languageModified = false;
    }

    onLanguageChange() {
        this.languageModified = true;
    }

    public get modified(): boolean {
        return (
            this.changePassCheckForm.touched ||
            this.changeNameCheckForm.touched ||
            this.languageModified
        );
    }

    public get containerRef(): ViewContainerRef {
        return this.viewContainerRef;
    }

    uploadImage() {
        const imageDialogRef = this.dialog.open(ImageUploadComponent);
        imageDialogRef.disableClose = true;
        imageDialogRef.componentInstance.parentRef = this.viewContainerRef;
        imageDialogRef.componentInstance.imageWidth = 110;
        imageDialogRef.componentInstance.imageHeight = 110;
        imageDialogRef.componentInstance.imageUploadPath = '/profile';

        const closeProfileSub =
            imageDialogRef.componentInstance.onImageUploadClose.subscribe(
                (data) => {
                    if (data) {
                        this.imageData = `${environment.apiPath}/media/public/${data}`;
                        this.imageId = data;
                        this.changeNameCheckForm.markAsDirty();
                    }

                    imageDialogRef.close();
                }
            );

        imageDialogRef.afterClosed().subscribe((result) => {
            closeProfileSub.unsubscribe();
        });

        imageDialogRef.backdropClick().subscribe(() => {
            imageDialogRef.componentInstance.closeImageUploadModal();
        });
    }
}
