import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';

import {
    AuthService,
    KeyCode,
    PermitComment,
    PermitProject,
    TextureColorEnum,
    FurbanUtil,
    PermitCommentsService,
} from '@furban/utilities';

@Component({
    selector: 'furban-permit-comment-form',
    templateUrl: './comment-form.component.html',
    styleUrls: ['./comment-form.component.scss'],
})
export class CommentFormComponent implements OnInit {
    @ViewChild('comment') inputText: ElementRef;

    @Input() public isDialog: boolean;
    @Input() public project: PermitProject;
    @Output() public commentSave = new EventEmitter<PermitComment>();
    @Output() toggleEmojiEvent: EventEmitter<boolean> = new EventEmitter();

    public commentForm: FormGroup;
    public noWhiteSpacesValidator = FurbanUtil.noWhiteSpacesValidator;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public curentY: number;
    public curentX: number;
    public emojiOpened = false;
    public emojiColor = TextureColorEnum.mildBlue;
    public isPinComment = false;
    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private permitCommentsService: PermitCommentsService
    ) { }

    ngOnInit(): void {
        this.commentForm = this.formBuilder.group({
            commentText: [
                '',
                [
                    Validators.maxLength(256),
                    this.htmlValidator,
                    this.noWhiteSpacesValidator,
                ],
            ],
        });
    }

    public toggleEmoji() {
        this.emojiOpened = !this.emojiOpened;
        this.toggleEmojiEvent.emit(this.emojiOpened);
    }

    public checkError(errorType: string): boolean {
        if (!this.commentForm.get('commentText').errors) {
            return false;
        }
        return !!this.commentForm.get('commentText').errors['maxlength'];
    }

    private calculateEmojiPosition(): void {
        const parent = document.getElementById('emojiButton');
        if (parent) {
            const y = parent.getBoundingClientRect().bottom;
            this.curentX = this.isDialog
                ? parent.getBoundingClientRect().right - 366
                : 0;
            if (this.isDialog) {
                const documentHeight = document.getElementsByClassName(
                    'cdk-overlay-container'
                )[0].clientHeight;
                if (documentHeight - y < 402) {
                    this.curentY = y - 402;
                }
            } else {
                this.curentY = y - 402;
            }
        }
    }

    public openEmoji(): void {
        this.toggleEmoji();
        this.calculateEmojiPosition();
    }

    public saveComment(): void {
        const commentText = this.commentForm.get('commentText').value;
        if (
            !this.commentForm.valid ||
            this.commentForm.pristine ||
            commentText.length === 0 ||
            commentText.trim().length === 0
        ) {
            return;
        }

        const comment = new PermitComment();
        comment.commentText = commentText;
        comment.projectId = this.project.projectId;
        comment.userProfile = this.authService.userProfile;
        comment.highlighted = this.authService.isAdmin();

        this.permitCommentsService
            .savePermitComment(comment)
            .subscribe((data) => {
                this.commentSave.emit(data);
                this.commentForm.reset();
                this.commentForm.get('commentText').setErrors(null);
                this.scrollCommentsContainer();
            });
    }

    public handleClick($event: EmojiEvent): void {
        let curentText = this.commentForm.get('commentText').value;
        if (!curentText) {
            curentText = '';
        }
        const insertEmojiPosition = this.inputText.nativeElement.selectionStart;
        const emojiText =
            curentText.slice(0, insertEmojiPosition) +
            $event.emoji.native +
            curentText.slice(insertEmojiPosition);
        this.commentForm.get('commentText').setValue(emojiText);
        this.commentForm.get('commentText').markAsTouched();
        this.commentForm.get('commentText').markAsDirty();
    }

    public onCommentKey(event: KeyboardEvent): void {
        if (
            event.key === KeyCode.arrowDown ||
            event.key === KeyCode.arrowUp ||
            event.key === KeyCode.arrowLeft ||
            event.key === KeyCode.arrowRight
        ) {
            event.stopImmediatePropagation();
        }
    }

    private scrollCommentsContainer(): void {
        setTimeout(() => {
            let container =
                document.getElementsByClassName('mat-dialog-content')[0];
            if (!container) {
                container = document.getElementsByClassName(
                    'container-for-scroll'
                )[0];
            }
            container.scrollTop = container.scrollHeight;
        }, 100);
    }
}
