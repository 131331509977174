import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    AuthService, Project, routingConstants, StepperService, Path, ProjectService, MapService, ProjectInvolvementTypeEnum, ProjectTypeNameEnum, ToolingVisibility, ToolingActionsEnum,
    ThreeStateEnum, RIGHT_MENU_TOUR_ITEMS, Project3dModel, MenuService, FurbanUtil, SvgImageEnum, ThreeStore, Tool, CustomToasterService, FileUploadService, CustomToasterComponent
} from '@furban/utilities';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { Modifiable } from '../../shared/modifyStatus/modifiable.interface';
import { ToolingService } from '../../shared/tooling/tooling.service';
import { ProjectDetailsService } from '../project-details/project-details.service';

@Component({
    selector: 'furban-project-container',
    templateUrl: './project-container.component.html',
    styleUrls: ['./project-container.component.scss'],
})
export class ProjectContainerComponent

    implements OnInit, OnDestroy, Modifiable {

    public homeRoute: string;
    public isLoaded = false;
    public activeButton: ToolingActionsEnum;
    public projectChangeSubscription: Subscription;
    public modified = false;
    public toolingVisibility: ToolingVisibility;
    public rightMenuTourItems = RIGHT_MENU_TOUR_ITEMS;
    public projectIsPublished: boolean;
    public materials: Project3dModel[];
    public isIOS = FurbanUtil.isIOS();
    public isRedoEnabled = false;
    public isUndoEnabled = false;
    public isApp = FurbanUtil.isApp;

    private storeSubscription: Subscription;
    private actionFinishedSubscription: Subscription;
    private disableActiveButtonsSubscription: Subscription;

    constructor(private route: ActivatedRoute,
        private router: Router,
        public stepperService: StepperService,
        public containerRef: ViewContainerRef,
        public toolingService: ToolingService,
        private projectDetailsService: ProjectDetailsService,
        private mapService: MapService,
        private projectService: ProjectService,
        private authService: AuthService,
        private store: Store<{ store: ThreeStore }>,
        private menuService: MenuService,
        private fileUploadService: FileUploadService,
        private customToasterService: CustomToasterService
    ) {

    }

    public get svgImageType(): typeof SvgImageEnum {
        return SvgImageEnum;
    }

    public get isProjectPublished(): boolean {
        return this.stepperService.isProjectPublished
    }

    public get disableBtnDefaultDesignIfPublished(): boolean {
        return this.isProjectEnded || (this.isProjectPublished && (this.checkIfRouteIncludes('project:objects')));
    }

    public get isProjectEnded(): boolean {
        return this.stepperService.project && this.stepperService.project.ended;
    }

    ngOnInit(): void {
        this.menuService.shouldShowMenu = false;
        this.projectService.curentDrawing = new Path();
        this.projectService.copyDrawing = new Path();
        this.subscribeToRouteObservableAndGetProjectInformation();
        this.subscribeToOnProjectChangeObservable();
        this.subscribeToModifiedObservable();
        this.getAllStatus();
        this.subscribeToToolingVisibility();
        this.subscribeToStore();
        this.subscribeToActionsFinished();
        this.subscribeToDisableActive();
    }

    ngOnDestroy(): void {
        this.stepperService.resetProject();
        this.projectChangeSubscription.unsubscribe();
        this.storeSubscription.unsubscribe();
        this.actionFinishedSubscription.unsubscribe();
        this.disableActiveButtonsSubscription.unsubscribe();
    }



    public onButtonClick(buttonType: ToolingActionsEnum): void {
        if (this.fileUploadService.isUndergroundUploaded || this.fileUploadService.isCustomObjectUploaded) {
            this.showToaster('warning', 'info', 'errors.navigateToMenu', 20000);
            return;
        }
        this.menuService.shouldShowMenu = false;
        if (this.activeButton === buttonType) {
            this.activeButton = null;
            const tool = new Tool(buttonType, false);
            this.menuService.emitOnButtonClicked(tool);
            this.menuService.shouldShowMenu = false;
            return;
        }

        this.activeButton = buttonType;
        const tool = new Tool(buttonType, true);
        this.menuService.emitOnButtonClicked(tool);
    }

    public shouldDisplayContextMenu(): boolean {
        return FurbanUtil.isWidthSmallerThan(1054);
    }

    public shouldDisplayViewFromTop(): boolean {
        return this.checkIfRouteIncludes('partner/permit');
    }

    public showToaster(icon: string, type: string, message: string, duration: number): void {
        this.customToasterService.openCustomToaster(CustomToasterComponent, icon, type, message, duration);
    }


    public checkActiveButton(buttonType: ToolingActionsEnum): boolean {
        return this.activeButton === buttonType;
    }

    public shouldDisplayBackButton(): boolean {
        return this.checkIfRouteIncludes('project:create-design');
    }

    public checkHeaderType(): string {
        return (this.checkIfRouteIncludes('project:area-definition') && !this.stepperService.isProjectPublished) || (this.checkIfRouteIncludes('project:objects') ||
            this.checkIfRouteIncludes('project:create-design')) && this.toolingVisibility.editMode
            ? 'header-space-between'
            : 'header-center';
    }

    public checkInitiativeRoute(): boolean {
        return (this.checkIfRouteIncludes('project:objects') || this.checkIfRouteIncludes('project:create-design')) && !this.checkIfRouteIncludes('initiatives');
    }

    public shouldShowIcons(): boolean {
        return this.showTooling() ||
            (this.checkIfRouteIncludes('preview') || this.checkIfRouteIncludes('update'));
    }

    public getAllStatus(): void {
        if (!this.projectService.projectStatuses) {
            this.projectDetailsService.getAllStatus().subscribe((data) => {
                this.projectService.projectStatuses = data;
            });
        }
    }

    public checkIfShouldDisplayScreenshot(): boolean {
        return this.checkIfRouteIncludes('project:create-design');
    }

    public showTooling(): boolean {
        return this.toolingVisibility &&
            (this.checkIfRouteIncludes('project:objects') ||
                this.checkIfRouteIncludes('project:create-design') ||
                this.checkIfRouteIncludes('project:collaborative-design'))
    }

    public get hideUndoRedo(): boolean {
        return this.checkIfRouteIncludes('project:collaborative-design');
    }

    public isProjectLoaded(): boolean {
        return !!this.stepperService.project;
    }

    public isPioneerOrAdminLoggedIn(): boolean {
        return this.authService.hasAdministrativeRole();
    }

    public checkIfRouteIncludes(includedString: string): boolean {
        return this.router.url.includes(includedString);
    }

    public get toolingActions(): typeof ToolingActionsEnum {
        return ToolingActionsEnum;
    }

    public get threeStateEnum(): typeof ThreeStateEnum {
        return ThreeStateEnum;
    }

    public callToolingAction(toolingAction: ToolingActionsEnum) {
        this.toolingService.callToolingAction(toolingAction);
    }

    public showMapTooling(): boolean {
        return this.toolingVisibility &&
            !this.stepperService.isProjectPublished &&
            this.checkIfRouteIncludes('project:area-definition');
    }

    public showDesignTooling(): boolean {
        return this.checkIfRouteIncludes('project:objects') ||
            this.checkIfRouteIncludes('project:create-design');
    }

    public isDrawingEnabled(): boolean {
        return this.projectService.isUserDrawing;
    }

    public onMaterialChange(): void {
        this.stepperService.changeProjectModifiedState(true);
    }

    public showButtonForGroundMaterial(): boolean {
        return FurbanUtil.isMobile() &&
            FurbanUtil.isPortraitMode();
    }

    public showObjectsTooling(): boolean {
        return this.toolingVisibility &&
            this.checkIfRouteIncludes('project:setup-default-objects');
    }

    public showDetailsTooling(): boolean {
        return this.toolingVisibility &&
            this.checkIfRouteIncludes('project:details');
    }

    public showGroupTooling(): boolean {
        return this.toolingVisibility &&
            !this.checkIfRouteIncludes('initiatives') &&
            this.checkIfRouteIncludes('project:manage-users');
    }

    public showInitiativeTooling(): boolean {
        return this.toolingVisibility &&
            this.checkIfRouteIncludes('initiatives') &&
            this.checkIfRouteIncludes('project:manage-users');
    }

    public showStepperAndTooling(): boolean {
        if (!this.stepperService.steps || !this.stepperService.currentStep) {
            return false;
        }
        return !this.checkIfRouteIncludes('overview') &&
            !this.checkIfRouteIncludes('collaborative-design');
    }

    private subscribeToToolingVisibility(): void {
        this.toolingService.toolingVisibilityObservable.subscribe((data) => {
            this.toolingVisibility = data;
        });
    }

    private subscribeToActionsFinished(): void {
        this.actionFinishedSubscription = this.menuService.actionFinishedObservable$.subscribe((data) => {
            this.activeButton = null;
        });
    }

    private subscribeToRouteObservableAndGetProjectInformation(): void {
        combineLatest([this.route.params, this.route.data]).subscribe(
            (data: any) => {
                if (!data) {
                    return;
                }
                this.stepperService.projectId = data[0]['projectId'];
                this.stepperService.projectType = data[1]['type'] as string;
                this.setHomeRoute();

                if (this.stepperService.projectId === 'new') {
                    this.initializeNewProject();
                    this.stepperService.generateStepsWithStatus(
                        this.stepperService.projectType
                    );
                } else {
                    this.getGroundMaterials();
                    this.getAllProjectInformation(
                        this.stepperService.projectId
                    );
                }
            }
        );
    }

    private setHomeRoute(): void {
        if (this.authService.isPioneer()) {
            this.homeRoute = `${routingConstants.initiativeProcess}/${this.stepperService.projectId}/(project:overview)`;
        } else {
            this.homeRoute = `${routingConstants.process}/${this.stepperService.projectId}/(project:overview)`;
        }
    }

    private getAllProjectInformation(projectId: string): void {
        this.projectDetailsService.hasAccessOnProject(projectId).pipe(
            filter(data => !!data),
            switchMap(() => this.projectDetailsService.getProject(projectId))
        ).subscribe(
            project => {
                this.stepperService.project = project as Project;
                this.stepperService.generateStepsWithStatus(this.stepperService.projectType);
                this.getPolygonInformation(project.projectId);
            },
            () => {
                if (this.authService.isPioneer()) {
                    this.router.navigate([routingConstants.initiatives]);
                } else {
                    this.router.navigate([routingConstants.admin]);
                }
            }
        );
    }

    private getPolygonInformation(projectId: string): void {
        this.mapService.getPolygon(projectId).subscribe(
            {
                next: (data: Path) => {
                    this.projectService.hasDrawing = true;
                    this.projectService.mapImageId = data.imageId;
                },
                error: (err) => {
                    this.projectService.hasDrawing = false;
                    this.projectService.curentDrawing.defaultMaterial = 1001;
                }
            }
        );
    }

    private initializeNewProject(): void {
        const project = new Project();
        project.isCitizenDesigns =
            this.projectService.projectInvolvementType ===
            ProjectInvolvementTypeEnum.citizen;
        project.allowCitizenDesigns = true;
        this.stepperService.project = project;
    }

    private subscribeToOnProjectChangeObservable(): void {
        this.projectChangeSubscription =
            this.stepperService.projectChanged.subscribe((projectChanged) => {
                if (!projectChanged) {
                    return;
                }

                this.projectDetailsService
                    .saveProject(projectChanged.project as Project)
                    .subscribe((data) => {
                        if (
                            this.stepperService.projectType ===
                            ProjectTypeNameEnum.adminProject
                        ) {
                            this.stepperService.regenerateMunicipalitySteps(
                                data,
                                projectChanged.redirect
                            );
                        } else if (
                            this.stepperService.projectType ===
                            ProjectTypeNameEnum.pioneerInitiativeProject
                        ) {
                            this.stepperService.regenerateInitiativeSteps(
                                data,
                                projectChanged.redirect
                            );
                        } else {
                            console.error(
                                'Something went wrong when generating steps'
                            );
                        }
                    });
            });
    }

    private subscribeToDisableActive(): void {
        this.disableActiveButtonsSubscription = this.router.events.subscribe(() => {
            this.activeButton = null;
            this.toolingVisibility.editMode = true;
        });
    }

    private subscribeToModifiedObservable(): void {
        this.stepperService.projectModifiedStateObservable.subscribe((data) => {
            this.modified = data;
        });
    }

    private getGroundMaterials(): void {
        this.menuService
            .getObjectsOrdered(this.stepperService.projectId)
            .subscribe((data) => {
                this.materials = data.find(
                    (object) =>
                        object.category.categoryName ===
                        'user.projectMenu.groundMaterials'
                ).models;
                if (!this.projectService.curentDrawing.defaultMaterial) {
                    this.toolingService.defaultMaterial =
                        this.materials[0].furban3DModel.objectLookId;
                } else {
                    this.toolingService.defaultMaterial =
                        this.projectService.curentDrawing.defaultMaterial;
                }
            });
    }

    private subscribeToStore(): void {
        this.storeSubscription = this.store
            .pipe(
        )
            .subscribe((data) => {
                this.isRedoEnabled = data.store.future?.length > 0;
                this.isUndoEnabled = data.store.previous?.length > 0;
            });
    }
}
