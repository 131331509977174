import { NgModule } from '@angular/core';
import { PermitProcessDashboardComponent } from './permit-process-dashboard/permit-process-dashboard.component';
import { RegisterAuthService } from '../shared/register/register-auth.service';
import { RouterModule } from '@angular/router';
import { PioneerLoginGuard } from '../pioneer/guards/pioneer-login.guard';
import { SharedModule } from '../shared/shared.module';
import { ProjectSharedModule } from '../project-shared/project-shared.module';
import { PermitRoutingModule } from './permit-routing.module';
import { HouseDrawingFormComponent } from './house-drawing-form/house-drawing-form.component';
import { PermitSelectHouseMapComponent } from './permit-select-house-map/permit-select-house-map.component';
import { AssetAttachEventsDirective } from './_directives/asset-attach-events.directive';
import { PermitControlsMenuComponent } from './permit-controls-menu/permit-controls-menu.component';
import { AssetSelectEventsDirective } from './_directives/asset-select-events.directive';
import { HousePreviewComponent } from './house-preview/house-preview.component';
import { PermitContainerComponent } from './permit-container/permit-container.component';
import { PermitProjectComponent } from './permit-project/permit-project.component';
import { PermitInvolvementComponent } from './permit-involvement/permit-involvement.component';

import {
    PermitEffects,
    PermitReducer,
    UtilitiesModule,
} from '@furban/utilities';
import { CodesStatusComponent } from './codes-status/codes-status.component';
import { AddPinCommentEventsDirective } from './_directives/add-pin-comment-events.directive';
import { PermitApprovalDialogComponent } from './_components/approval-dialog/approval-dialog.component';
import { SelectPinCommentsDirective } from './_directives/select-pin-comments.directive';
import { PermitHouseParallelComponent } from './permit-house-parallel/permit-house-parallel.component';
import { NgrxPermitEditorComponent } from './ngrx-permit-editor/ngrx-permit-editor.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NeighborPermitReviewsComponent } from './codes-status/neighbor-permit-reviews/neighbor-permit-reviews.component';
import { PublicReviewsComponent } from './public-reviews/public-reviews.component';
import { PlaceObjectsInLineDirective } from './_directives/place-objects-in-line';
import { environment } from '../../environments/environment';

@NgModule({
    declarations: [
        PermitProcessDashboardComponent,
        HouseDrawingFormComponent,
        PermitSelectHouseMapComponent,
        HousePreviewComponent,
        PlaceObjectsInLineDirective,
        AssetSelectEventsDirective,
        AssetAttachEventsDirective,
        AddPinCommentEventsDirective,
        SelectPinCommentsDirective,
        PermitControlsMenuComponent,
        PermitContainerComponent,
        PermitProjectComponent,
        PermitInvolvementComponent,
        CodesStatusComponent,
        PermitApprovalDialogComponent,
        PermitHouseParallelComponent,
        NeighborPermitReviewsComponent,
        NgrxPermitEditorComponent,
        PublicReviewsComponent,
    ],
    imports: [
        PermitRoutingModule,
        SharedModule,
        ProjectSharedModule,
        UtilitiesModule.forRoot(environment),
        StoreModule.forFeature('permit', { store: PermitReducer }),
        EffectsModule.forFeature([PermitEffects]),
    ],
    providers: [RegisterAuthService, RouterModule, PioneerLoginGuard],
    exports: [NgrxPermitEditorComponent],
})
export class PermitModule { }
