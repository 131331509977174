import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { FurbanUtil } from '../helpers/furbanUtil';
import { AuthService } from '../_services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AppLoginGuard implements CanActivate {
    public notLoggedIn: boolean;
    public isApp = FurbanUtil.isApp;

    constructor(private authService: AuthService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> {
        if (this.isApp()) {
            if (this.authService.user) {
                this.authService.redirectByRole();
                return false;
            }

            return true;
        }

        this.authService.redirectToInfoPage();
        return false;
    }
}
