import {
    AfterViewInit,
    Component,
    HostListener,
    ViewChild,
    ViewContainerRef,
    OnDestroy
} from '@angular/core';

import {
    FurbanUtil,
    House,
    HouseAsset,
    HouseService,
    HouseSceneConfig,
    PermitActions,
    PermitAssetNamesEnum,
    PermitComment,
    PermitDefaultValues,
    PermitGroupBuilder,
    PermitProject,
    PermitThreeService,
    ThreeWallBuilder,
    PermitUtils,
    PermitView,
    ThreeCommentPopupComponent,
    ThreeControlBuilder,
    ThreeGroupBuilder,
    ThreeGroupEnum,
    ThreeRendererBuilder,
    PermitAssetTypeValues,
    ThreeRoofBuilder,
    ThreeMeshBuilder,
    Roof,
    ColorsDefaultValuesEnum,
    HouseFormValuesEnum,
    HouseArea,
    ThreeLightsBuilder,
    ThreeSunBuilder,
    sunConstants,
    ViewPortDimension,
    ViewPort,
    MediaService,
    ThreeUtils
} from '@furban/utilities';
import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import {
    combineLatest,
    forkJoin,
    from,
    interval,
    Observable,
    Subscription,
} from 'rxjs';
import { PermitProjectService } from '../permit-project/permit-project.service';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { map, switchMap, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PermitReview } from '../_models/permit-review';
import { PermitApprovalDialogComponent } from '../_components/approval-dialog/approval-dialog.component';
import { PermitReviewService } from '../_services/permit-review.service';
import { ActivatedRoute } from '@angular/router';
import { PublicReviewsComponent } from '../public-reviews/public-reviews.component';
import { MatSliderChange } from '@angular/material/slider';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-permit-house-parallel',
    templateUrl: './permit-house-parallel.component.html',
    styleUrls: ['./permit-house-parallel.component.scss'],
})
export class PermitHouseParallelComponent implements AfterViewInit, OnDestroy {
    public permitReview: PermitReview;

    @ViewChild('commentPopup')
    threeCommentPopupComponent: ThreeCommentPopupComponent;

    public addingCommentsEnabled = false;
    public viewCommentsEnabled = false;
    public currentProject: PermitProject;
    public showLess = true;
    public houseSceneConfig = new HouseSceneConfig();
    public isViewerOnly: boolean;

    public meshBuilder = new ThreeMeshBuilder();
    public switchViewPort = false;

    public sunSliderValue: number;
    public isSunAnimationRunning: boolean;
    public shouldDisplayTimeSlider = false;
    public noProjectFound: boolean;

    private renderer: THREE.WebGLRenderer;
    private houseAssets: HouseAsset[];
    private updatedHouseAssets: HouseAsset[];
    private house: House;
    private updatedHouse: House;
    private gltfLoader: GLTFLoader = new GLTFLoader();
    private originalViewport: ViewPortDimension[] = [new ViewPortDimension(0, 0, 1.0, 1.0), new ViewPortDimension(0.75, 0, 0.25, 0.25)]
    private splitViewport: ViewPortDimension[] = [new ViewPortDimension(0, 0, 0.5, 1.0), new ViewPortDimension(0.5, 0, 0.5, 1.0)]

    private viewPort = new ViewPort(this.originalViewport, this.splitViewport);
    private views: PermitView[] = [new PermitView(0, this.viewPort.splitViewport[0], new THREE.Color("rgb(247, 251, 255)")),
    new PermitView(1, this.viewPort.splitViewport[1], new THREE.Color("rgb(227, 243, 227)"))];

    private projectId: string;
    private neighborHouses: House[];
    private sunBuilderView1: ThreeSunBuilder;
    private sunBuilderView2: ThreeSunBuilder;
    private dateForSun: Date;
    private sunSubscription: Subscription;
    private readonly SUNSHINING_MINUTES =
        (sunConstants.SUNSET_TIME - sunConstants.SUNSRISE_TIME) * 60;
    private animationFrameId: number;

    constructor(
        public viewContainerRef: ViewContainerRef,
        private houseService: HouseService,
        private permitThreeService: PermitThreeService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private permitReviewService: PermitReviewService,
        private permitService: PermitProjectService,
        private mediaService: MediaService,
        private translateService: TranslateService
    ) { }

    public get sunriseTime(): string {
        return (
            FurbanUtil.displayNumberWithTwoDigits(sunConstants.SUNSRISE_TIME) +
            ':00'
        );
    }

    public get sunsetTime(): string {
        return (
            FurbanUtil.displayNumberWithTwoDigits(sunConstants.SUNSET_TIME) +
            ':00'
        );
    }

    public get sunShiningMinutesInterval(): number {
        return this.SUNSHINING_MINUTES;
    }

    public get isSplitScreenView(): boolean {
        return this.houseSceneConfig.isSpiltScreenView;
    }

    public get statusTooltipText(): string {
        return this.isSplitScreenView ? this.translateService.instant("buttonsTooltips.fullView") : this.translateService.instant("buttonsTooltips.splitView");
    }

    ngAfterViewInit(): void {
        this.subscribeToRoute();
    }

    ngOnDestroy(): void {
        cancelAnimationFrame(this.animationFrameId);
        ThreeUtils.disposeThreeElement(this.views[0].scene);
        ThreeUtils.disposeThreeElement(this.views[1].scene);
        this.houseSceneConfig.renderer.dispose();
        delete this.houseSceneConfig.scene;
        delete this.houseSceneConfig.camera;
        delete this.houseSceneConfig.renderer;
        delete this.houseSceneConfig.htmlRenderer;
    }

    @HostListener('window:resize', ['$event'])
    public onResize() {
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    public getMedia(mediaId): string {
        return this.mediaService.getMedia(mediaId);
    }

    public timeLabel(value: number): string {
        const hour = Math.floor(sunConstants.SUNSRISE_TIME + value / 60);
        const minute = value % 60;
        return (
            FurbanUtil.displayNumberWithTwoDigits(hour) +
            ':' +
            FurbanUtil.displayNumberWithTwoDigits(minute)
        );
    }

    public toggleChangeDisplay(): void {
        this.onPinPopupClose(false);

        if (this.views[0].index === 1) {
            this.toggleSwitchPlaces();
        }

        this.houseSceneConfig.isSpiltScreenView = !this.houseSceneConfig.isSpiltScreenView;
        this.views[0].viewPortDimension = this.houseSceneConfig.isSpiltScreenView ? this.viewPort.splitViewport[0] : this.viewPort.originalViewport[0];
        this.views[1].viewPortDimension = this.houseSceneConfig.isSpiltScreenView ? this.viewPort.splitViewport[1] : this.viewPort.originalViewport[1];

        this.setHtmlSizeForScreen();
    }

    public toggleSwitchPlaces(): void {
        this.onPinPopupClose(false);
        const oldCamera = this.views[0].camera;
        const newCamera = this.views[1].camera;
        const oldScene = this.views[0].scene;
        const newScene = this.views[1].scene;
        const oldBackground = this.views[0].background;
        const newBackground = this.views[1].background;

        this.views[0].camera = newCamera;
        this.views[0].scene = newScene;
        this.views[0].background = newBackground;
        this.views[0].index = (this.views[0].index === 0) ? 1 : 0;

        this.views[1].camera = oldCamera;
        this.views[1].scene = oldScene;
        this.views[1].background = oldBackground;
        this.views[1].index = (this.views[1].index === 1) ? 0 : 1;
    }

    public updateTimeValue(event: MatSliderChange): void {
        this.setSunBasedOnIntervalValue(event.value);
    }

    public playSunAnimation(): void {
        this.isSunAnimationRunning = true;
        this.sunSubscription = interval(50).subscribe((integer) => {
            this.setSunBasedOnIntervalValue(integer);
        });
    }

    public stopSunAnimation(): void {
        this.isSunAnimationRunning = false;
        this.sunSubscription.unsubscribe();
    }

    public openReviewDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '632px';
        dialogConfig.data = {
            permitReview: this.permitReview,
        };
        const approvalDialog = this.dialog.open(
            PermitApprovalDialogComponent,
            dialogConfig
        );
        approvalDialog.afterClosed().subscribe((data) => {
            if (data) {
                this.permitReviewService
                    .updatePermitStatus(data)
                    .subscribe((response) => {
                        this.permitReview = response;
                    });
            }
        });
    }

    public openApprovalStatusDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '632px';
        dialogConfig.width = '40%';
        dialogConfig.data = {
            projectId: this.projectId,
        };
        this.dialog.open(PublicReviewsComponent, dialogConfig);
    }

    public onPinPopupClose(pinWasModified: boolean): void {
        if (pinWasModified) {
            return;
        }
        this.permitThreeService.detachPopupToPin.next(true);
    }

    public onPinCommentSaved(comment: PermitComment): void {
        this.permitThreeService.savePinData.next(comment);
    }

    public toggleShowDescription(): void {
        this.showLess = !this.showLess;
    }

    public shouldDisplayReviewButton(): boolean {
        return !this.isViewerOnly && !!this.permitReview;
    }

    public shouldDisplayPinPopup(): boolean {
        return !!this.currentProject;
    }

    public shouldHaveSelectPinDirective(): boolean {
        return !!this.houseSceneConfig?.pinHelper;
    }

    public shouldHaveAddPinDirective(): boolean {
        return (
            !this.isViewerOnly && !!this.houseSceneConfig?.scene && !!this.house
        );
    }

    public toggleSunSlider(): void {
        this.shouldDisplayTimeSlider = !this.shouldDisplayTimeSlider;
    }

    private setHtmlSizeForScreen(): void {
        if (this.houseSceneConfig.isSpiltScreenView) {
            this.houseSceneConfig.htmlRenderer.setSize(this.houseSceneConfig.renderer.domElement.width / 2, this.houseSceneConfig.renderer.domElement.height);
        } else {
            this.houseSceneConfig.htmlRenderer.setSize(this.houseSceneConfig.renderer.domElement.width, this.houseSceneConfig.renderer.domElement.height);
        }
    }

    private createHouseWalls(
        house: House,
        view: PermitView,
        isNeighborHouse: boolean = false
    ): void {
        const wallBuilder = new ThreeWallBuilder(house);
        const walls = wallBuilder.createHouseWalls();

        let currentCoordinates = FurbanUtil.deepCopy(house.xYCoordinates);
        currentCoordinates = FurbanUtil.reduceCoordinatesToMaxYAndMinX(
            currentCoordinates,
            this.houseSceneConfig.maxY,
            this.houseSceneConfig.minX
        );
        currentCoordinates.pop();
        const centroid = FurbanUtil.getPolygonCentroid(currentCoordinates);
        walls.position.x = centroid.x;
        walls.position.z = -centroid.y;
        walls.castShadow = true;
        walls.receiveShadow = true;
        view.scene.add(walls);

        const isFlatRoof = true;
        const roof = isFlatRoof
            ? PermitActions.createHouseFlatRoof(
                house,
                walls.userData['constructionHeight'],
                view.scene
            )
            : PermitActions.createHousePointyRoof(
                house,
                walls.userData['constructionHeight'],
                view.scene
            );

        roof.position.x = centroid.x;
        roof.position.z = -centroid.y;
        roof.castShadow = true;
        roof.receiveShadow = true;
        const groupChildren: THREE.Object3D[] = walls.children.concat(
            roof.children
        );
        this.makeSubscriptionForGroupAnimation(groupChildren, view);
        if (isNeighborHouse) {
            roof.visible = true;
            return;
        }

        const pointToLookAt = new THREE.Vector3(centroid.x, 0, -centroid.y);
        view.camera.lookAt(pointToLookAt);
        view.controls.target = pointToLookAt;
        view.camera.updateProjectionMatrix();
        view.controls.update();
    }

    public makeSubscriptionForGroupAnimation(
        groupChildren: THREE.Object3D[],
        view: PermitView
    ) {
        PermitActions.getChildrenFromGroup(groupChildren).subscribe(
            {
                next: (data) => PermitActions.setupPositionAndMakeAnimation(data),
                complete: () => {
                    setTimeout(() => {
                        this.setObjectVisible(view);
                    }, PermitDefaultValues.animationTime);
                }
            }
        );
    }

    public addPinComments(): void {
        this.addingCommentsEnabled = !this.addingCommentsEnabled;
        this.permitThreeService.addPinEvents.next(this.addingCommentsEnabled);
    }

    public toggleCommentsVisibility(value: boolean): void {
        this.viewCommentsEnabled = value;
    }

    public updatePinCommentToDisplay(comment: PermitComment): void {
        this.threeCommentPopupComponent.setComment(comment);
    }

    private setObjectVisible(view: PermitView): void {
        const assetObjectsGroup = view.scene.getObjectByName(PermitAssetNamesEnum.assetGroup);
        const roofObjectsGroup = view.scene.getObjectByName(PermitAssetNamesEnum.roofAssetGroup);
        const roofGroup = view.scene.getObjectByName(PermitAssetNamesEnum.roofGroup);
        const extensionGroup = view.scene.getObjectByName(PermitAssetNamesEnum.extensionGroup);
        const solarGroup = view.scene.getObjectByName(PermitAssetNamesEnum.solarGroup);
        const dormersGroup = view.scene.getObjectByName(PermitAssetNamesEnum.dormersGroup);

        assetObjectsGroup.visible = true;
        roofObjectsGroup.visible = true;
        roofGroup.visible = true;
        extensionGroup.visible = true;
        solarGroup.visible = true;
        dormersGroup.visible = true;

        if (view.index === 1) {
            this.houseSceneConfig.pinHelper.visible = true;
        }
    }

    private getHouseInformation(projectId: string): Observable<any[]> {
        const requests = [
            this.houseService.getHouseAreaPolygon(projectId),
            this.houseService.getHousePolygon(projectId),
            this.houseService.getModifiedHousePolygon(projectId),
            this.houseService.getNeigborHousePolygons(projectId),
        ];

        return forkJoin(requests);
    }

    private setupGroups(scene: THREE.Scene): void {
        this.houseSceneConfig.groundGroup = ThreeGroupBuilder.createGroup(ThreeGroupEnum.ground, 0);
        const extensionGroup = PermitGroupBuilder.createGroup(PermitAssetNamesEnum.extensionGroup, 0);
        const assetObjectsGroup = PermitGroupBuilder.createGroup(PermitAssetNamesEnum.assetGroup, 1);
        const dormersGroup = PermitGroupBuilder.createGroup(PermitAssetNamesEnum.dormersGroup, 1);
        const solarPanels = PermitGroupBuilder.createGroup(PermitAssetNamesEnum.solarGroup, 1);
        const roofObjectsGroup = PermitGroupBuilder.createGroup(PermitAssetNamesEnum.roofAssetGroup, 2);

        scene.add(assetObjectsGroup);
        scene.add(roofObjectsGroup);
        scene.add(extensionGroup);
        scene.add(dormersGroup);
        scene.add(solarPanels);

        scene.add(this.houseSceneConfig.groundGroup);
        this.houseSceneConfig.intersectionHelpers = PermitGroupBuilder.createGroup(PermitAssetNamesEnum.intersectionHelpersGroup, 2);
    }

    private generateHouse(data: House): House {
        const house = House.copyHouse(data);
        house.processedCoordinatesForThree.pop();
        return house;
    }

    private getHouseAssets(): void {
        const requests = [
            this.houseService.getHouseAssets(this.house.houseId),
            this.houseService.getHouseAssets(this.updatedHouse.houseId),
        ];

        forkJoin(requests).subscribe((data) => {
            if (!data) {
                return;
            }

            this.houseSceneConfig.pinHelper.visible = false;

            this.houseAssets = data[0] as HouseAsset[];
            this.addThreeObjects(
                this.views[0].scene,
                this.houseAssets,
                this.house
            );

            this.updatedHouseAssets = data[1] as HouseAsset[];
            this.addThreeObjects(
                this.views[1].scene,
                this.updatedHouseAssets,
                this.updatedHouse
            );
        });
    }

    private addThreeObjects(scene: any, houseAssets: HouseAsset[], house: House): void {
        const assetObjectsGroup = scene.getObjectByName(PermitAssetNamesEnum.assetGroup);
        const roofObjectsGroup = scene.getObjectByName(PermitAssetNamesEnum.roofAssetGroup);
        const extensionObjectsGroup = scene.getObjectByName(PermitAssetNamesEnum.extensionGroup);
        const dormersGroup = scene.getObjectByName(PermitAssetNamesEnum.dormersGroup);
        const solarPanels = scene.getObjectByName(PermitAssetNamesEnum.solarGroup);

        assetObjectsGroup.visible = false;
        roofObjectsGroup.visible = false;
        extensionObjectsGroup.visible = false;
        dormersGroup.visible = false;
        solarPanels.visible = false;

        const objectsIds = this.getUniqueAssetLookIds(houseAssets);
        const arrayObject = {};
        const extensions: HouseAsset[] = [];
        const roofAssets: HouseAsset[] = [];

        for (const setItem of objectsIds) {
            arrayObject[setItem] = [];
        }
        for (const houseAsset of houseAssets) {
            if (houseAsset.asset.objectLookId.includes('EXT')) {
                extensions.push(houseAsset);
                continue;
            } else if (houseAsset.asset.objectLookId.includes('RFA')) {
                roofAssets.push(houseAsset);
                continue;
            }
            arrayObject[houseAsset.asset.objectLookId].push(houseAsset);
        }

        for (let i = 0; i < objectsIds.length; i++) {
            if (arrayObject[objectsIds[i]].length > 0) {
                PermitUtils.loadAndCloneAssetsWithTheSameLookId(
                    this.gltfLoader,
                    arrayObject[objectsIds[i]],
                    objectsIds[i],
                    scene,
                    house
                );
            }
        }

        this.addExtensionsToGroup(extensions, extensionObjectsGroup, house);
        this.addRoofAssetsToGroup(roofAssets, roofObjectsGroup, house);
    }

    private addExtensionsToGroup(
        extensions: HouseAsset[],
        extensionGroup: THREE.Group,
        house: House
    ): void {
        extensions.forEach((extension) => {
            const extensionMesh = PermitUtils.setupExtensionMesh(
                house,
                extension.coordinates as THREE.Vector3,
                extension.rotation as THREE.Euler,
                extension.scale as THREE.Vector3
            );
            extensionMesh.userData['asset'] = extension.asset;
            extensionMesh.userData['houseId'] = house.houseId;
            extensionMesh.userData['assetType'] =
                PermitAssetTypeValues.FOR_EXTENSION.id;
            extensionMesh.userData['houseAsset'] = extension;
            extensionGroup.add(extensionMesh);
        });
    }

    private addRoofAssetsToGroup(
        roofAssets: HouseAsset[],
        roofObjectsGroup: THREE.Group,
        house: House
    ): void {
        roofAssets.forEach((roofAsset) => {
            const roofBuilder = new ThreeRoofBuilder(
                house.roof,
                house.processedCoordinatesForThree
            );
            const roof = roofBuilder.createRoofAssetWithCostumTexture(
                roofAsset.asset.objectLookId,
                2,
                roofAsset,
                this.house,
            );

            roof.userData['asset'] = roofAsset.asset;
            roof.userData['houseId'] = house.houseId;
            roof.userData['assetType'] = PermitAssetTypeValues.FOR_ROOF.id;
            roof.userData['houseAsset'] = roofAsset;
            roofObjectsGroup.add(roof);
        });
    }

    private getUniqueAssetLookIds(houseAssets: HouseAsset[]): string[] {
        const objectsIds = [];
        houseAssets.map((result) => objectsIds.push(result.asset.objectLookId));
        return [...new Set(objectsIds)];
    }

    private initializeThreeJS(): void {
        this.createSetup();
        this.setupHouseSceneConfig();
        this.startRendering();
    }

    private initializeHouses(house: House[]): void {
        this.generateHouses(house[0], house[1]);
        this.getHouseAssets();
    }

    private generateHouses(house: House, updatedHouse: House): void {
        this.house = this.generateHouse(house);
        this.createHouseWalls(this.house, this.views[0]);

        this.updatedHouse = this.generateHouse(updatedHouse);
        this.createHouseWalls(this.updatedHouse, this.views[1]);
    }

    private createSetup(): void {
        const content = document.getElementById('content');

        this.views.forEach((view) => {
            const camera = new THREE.PerspectiveCamera(view.fov, window.innerWidth / window.innerHeight, 1, 10000);
            camera.position.fromArray(view.eye);
            camera.up.fromArray(view.up);
            view.camera = camera;

            view.scene = new THREE.Scene();

            this.setupGroups(view.scene);

            const light = new THREE.HemisphereLight(0xffffff, 0x444444, 1);
            light.position.set(-2, 2, 2);
            view.scene.add(light.clone());
            view.controls = ThreeControlBuilder.createOrbitControls(
                view.camera,
                content,
                new THREE.Vector3(7, 3, -5)
            );
            this.houseSceneConfig.controls = view.controls;

            this.houseSceneConfig.currentCoordinates =
                FurbanUtil.parseCoordinatesFor2DAnd3D(
                    this.houseSceneConfig.currentCoordinates,
                    1
                );
            this.houseSceneConfig.maxXPointFromArray = Math.abs(
                FurbanUtil.getMax(this.houseSceneConfig.currentCoordinates, 'X')
            );
            this.houseSceneConfig.minYPointFromArray = Math.abs(
                FurbanUtil.getMin(this.houseSceneConfig.currentCoordinates, 'Y')
            );
            view.directionalLight = ThreeLightsBuilder.createDirectionalLight(
                this.houseSceneConfig.maxXPointFromArray,
                this.houseSceneConfig.minYPointFromArray
            );
            view.scene.add(view.directionalLight);
        });

        this.renderer = ThreeRendererBuilder.createRenderer();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        content.appendChild(this.renderer.domElement);
    }

    private setupHouseSceneConfig(): void {
        this.houseSceneConfig.renderer = this.renderer;
        this.houseSceneConfig.scene = this.views[1].scene;
        this.houseSceneConfig.camera = this.views[1].camera;
        this.houseSceneConfig.pinHelper = ThreeGroupBuilder.createGroup(
            ThreeGroupEnum.pin_helpers,
            3
        );
        this.houseSceneConfig.scene.add(this.houseSceneConfig.pinHelper);
        this.houseSceneConfig.isSpiltScreenView = true;
        this.houseSceneConfig.htmlRenderer =
            ThreeRendererBuilder.createHTMLRenderer(
                this.houseSceneConfig.renderer.domElement
            );
        this.houseSceneConfig.htmlRenderer.setSize(
            this.houseSceneConfig.renderer.domElement.width / 2,
            this.houseSceneConfig.renderer.domElement.height
        );
    }

    private render(): void {
        this.views.forEach((view) => {
            const camera = view.camera;
            const left = Math.floor(window.innerWidth * view.viewPortDimension.left);
            const bottom = Math.floor(window.innerHeight * view.viewPortDimension.bottom);
            const width = Math.floor(window.innerWidth * view.viewPortDimension.width);
            const height = Math.floor(window.innerHeight * view.viewPortDimension.height);

            this.renderer.setViewport(left, bottom, width, height);
            this.renderer.setScissor(left, bottom, width, height);
            this.renderer.setScissorTest(true);
            this.renderer.setClearColor(view.background);

            camera.aspect = width / height;
            camera.updateProjectionMatrix();

            this.renderer.render(view.scene, camera);
            if (view.index === 1) {
                this.houseSceneConfig.htmlRenderer?.render(
                    view.scene,
                    view.camera
                );
            }
        });
    }

    private startRendering(): void {
        const render = () => {
            this.animationFrameId = requestAnimationFrame(render);
            TWEEN.update(TWEEN.now());
            this.render();
        };
        render();
    }

    private getPermitReview(): void {
        this.permitReviewService
            .getPermitReview(this.currentProject.projectId)
            .subscribe((data) => {
                this.permitReview = data;
            });
    }

    private getProjectInformations(): void {
        this.permitService
            .getAllNeighborProjects()
            .pipe(
                switchMap((data) => {
                    this.currentProject = data[0] as PermitProject;
                    if (!this.currentProject) {
                        this.noProjectFound = true;
                        return new Observable(null);
                    }
                    this.projectId = this.currentProject.projectId;
                    this.getPermitReview();
                    return this.getHouseInformation(
                        this.currentProject.projectId
                    );
                })
            )
            .subscribe((data) => {
                if (!data) {
                    return;
                }
                this.initializeDataForReducingCoordinates(data[0] as HouseArea);
                this.onHouseDataLoaded([data[1] as House, data[2] as House]);
                this.neighborHouses = data[3] as House[];
                this.addNeighborHousesOnCanvas();
                this.createSceneGround();


            });
    }

    private createSceneGround(): void {
        const plane = this.meshBuilder.createPlaneFromGroundAreaSelection(
            this.houseSceneConfig.currentCoordinates,
            1001
        );
        plane.castShadow = true;
        plane.receiveShadow = true;
        const groundGroupView1 = this.views[0].scene.getObjectByName(
            PermitAssetNamesEnum.ground
        );
        groundGroupView1.add(plane);

        const secondPlane = plane.clone();
        secondPlane.castShadow = true;
        secondPlane.receiveShadow = true;
        const groundGroupView2 = this.views[1].scene.getObjectByName(
            PermitAssetNamesEnum.ground
        );
        groundGroupView2.add(secondPlane);

        this.sunSliderValue =
            (sunConstants.NOON_TIME - sunConstants.SUNSRISE_TIME) * 60;
        this.updateLightPositionForHour(sunConstants.NOON_TIME);
    }

    private subscribeToRoute(): void {
        combineLatest([this.route.params, this.route.data]).subscribe(
            (data: any) => {
                this.projectId = data[0]['id'];
                this.isViewerOnly = data[1]['isViewerOnly'] as boolean;
                if (this.isViewerOnly) {
                    this.initializeDataForisViewerOnly();
                    return;
                }
                this.getProjectInformations();
            }
        );
    }

    private initializeDataForisViewerOnly(): void {
        this.permitService
            .getPublicProject(this.projectId)
            .pipe(
                switchMap((data) => {
                    this.currentProject = data as PermitProject;
                    return this.getHouseInformation(
                        this.currentProject.projectId
                    );
                })
            )
            .subscribe((data) => {
                if (!data) {
                    return;
                }
                this.initializeDataForReducingCoordinates(data[0] as HouseArea);
                this.onHouseDataLoaded([data[1] as House, data[2] as House]);
                this.neighborHouses = data[3] as House[];
                this.addNeighborHousesOnCanvas();
                this.createSceneGround();
            });
    }

    private onHouseDataLoaded(houseData: House[]): void {
        this.initializeThreeJS();
        this.initializeHouses(houseData);
    }

    private initializeDataForReducingCoordinates(houseArea: HouseArea): void {
        houseArea.xYCoordinates = FurbanUtil.getJSONfromStringfyValue(
            houseArea.xYCoordinates
        );
        this.houseSceneConfig.currentCoordinates = FurbanUtil.deepCopy(
            houseArea.xYCoordinates
        );
        this.houseSceneConfig.maxY = FurbanUtil.getMax(
            this.houseSceneConfig.currentCoordinates,
            'Y'
        );
        this.houseSceneConfig.minX = FurbanUtil.getMin(
            this.houseSceneConfig.currentCoordinates,
            'X'
        );
        this.houseSceneConfig.currentCoordinates.pop();
        this.houseSceneConfig.currentCoordinates =
            FurbanUtil.reduceCoordinatesToMaxYAndMinX(
                this.houseSceneConfig.currentCoordinates,
                this.houseSceneConfig.maxY,
                this.houseSceneConfig.minX
            );
    }

    private addNeighborHousesOnCanvas(): void {
        from(this.neighborHouses)
            .pipe(
                map((house) => {
                    return this.initializeNeighborHouse(house);
                }),
                tap((house) => {
                    this.createHouseWalls(house, this.views[0], true);
                    this.createHouseWalls(house, this.views[1], true);
                })
            )
            .subscribe();
    }

    private initializeNeighborHouse(house): House {
        const newHouse = House.copyHouse(house);
        if (!newHouse.roof) {
            newHouse.roof = new Roof();
        }
        newHouse.roof.roofMaterial = this.house.roof.roofMaterial;
        newHouse.roof.roofColor = ColorsDefaultValuesEnum.defaultRoofColor;
        newHouse.processedCoordinatesForThree.pop();
        newHouse.houseMaterial = this.house.houseMaterial;
        newHouse.numberOfFloors = HouseFormValuesEnum.numberOfFloorsDefault;
        newHouse.floorHeight = HouseFormValuesEnum.floorHeightDefault;
        return newHouse;
    }

    private setSunBasedOnIntervalValue(integer: number): void {
        integer = integer % this.SUNSHINING_MINUTES;
        this.sunSliderValue = integer;
        const hour = Math.floor(sunConstants.SUNSRISE_TIME + integer / 60);
        const minute = integer % 60;
        this.dateForSun.setHours(hour);
        this.dateForSun.setMinutes(minute);
        this.updateSun(this.sunBuilderView1);
        this.updateSun(this.sunBuilderView2);
    }

    private updateLightPositionForHour(hour?: number): void {
        if (!this.sunBuilderView1) {
            this.sunBuilderView1 = this.cerateSunBuilder(this.views[0]);
        }

        if (!this.sunBuilderView2) {
            this.sunBuilderView2 = this.cerateSunBuilder(this.views[1]);
        }
        this.dateForSun = new Date();

        if (hour) {
            this.dateForSun.setHours(hour);
        }
        this.updateSun(this.sunBuilderView1);
        this.updateSun(this.sunBuilderView2);
    }

    private updateSun(sunBuilder: ThreeSunBuilder): void {
        sunBuilder.updateOrientation(this.dateForSun);
        sunBuilder.updateDirectionalLight();
    }

    private cerateSunBuilder(view: PermitView): ThreeSunBuilder {
        return new ThreeSunBuilder(
            view,
            new THREE.Vector2(43.78, 23.7),
            new THREE.Vector3(0, 0.0, -1),
            new THREE.Vector3(1, 0.0, 0),
            new THREE.Vector3(0.0, -1.0, 0.0)
        );
    }
}
