<form [formGroup]="adressFormGroup" class="full-width">
    <mat-form-field class="full-width grey" appearance="outline">
        <mat-label>
            {{ 'admin.map.searchAddress' | translate }}
        </mat-label>
        <input
            matInput
            autofocus
            type="text"
            formControlName="location"
            placeholder="{{ 'admin.map.searchAddress' | translate }}"
            [matAutocomplete]="auto"
        />
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option
            *ngFor="let option of options"
            [value]="getDisplayName(option)"
            (click)="selectMapLocation(option)"
        >
            {{ getDisplayName(option)}}
        </mat-option>
    </mat-autocomplete>
</form>
