import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'apps/furban-client/src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermitProject } from '@furban/utilities';

@Injectable({
    providedIn: 'root',
})
export class PermitProjectService {
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    public saveProjectDetailsSubject = new Subject<boolean>();
    public saveProjectDetailsObservable$: Observable<boolean> =
        this.saveProjectDetailsSubject.asObservable();

    constructor(private http: HttpClient) { }

    public getProject(projectId): Observable<PermitProject> {
        return this.http
            .get(`${environment.apiPath}/permit/${projectId}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as PermitProject));
    }

    public getPublicProject(projectId): Observable<PermitProject> {
        return this.http
            .get(`${environment.apiPath}/permit/public/${projectId}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as PermitProject));
    }

    public createProject(project: PermitProject): Observable<PermitProject> {
        return this.http
            .put(`${environment.apiPath}/permit`, project, {
                headers: this._headers,
            })
            .pipe(map((result) => result as PermitProject));
    }

    public saveProject(project: PermitProject): Observable<PermitProject> {
        return this.http
            .post(`${environment.apiPath}/permit`, project, {
                headers: this._headers,
            })
            .pipe(map((result) => result as PermitProject));
    }

    public getAllPermitProjects(): Observable<PermitProject[]> {
        return this.http
            .get(`${environment.apiPath}/permit`, { headers: this._headers })
            .pipe(map((result) => result as PermitProject[]));
    }

    public getAllNeighborProjects(): Observable<PermitProject> {
        return this.http
            .get(`${environment.apiPath}/permit/projects`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as PermitProject));
    }

    public involveMunicipality(
        project: PermitProject
    ): Observable<PermitProject> {
        return this.http
            .post<PermitProject>(
                `${environment.apiPath}/permit/municipality-involvement/${project.projectId}`,
                { headers: this._headers }
            )
            .pipe(map((result) => result as PermitProject));
    }

    public deletePermitProject(projectId: string): Observable<void> {
        return this.http.delete<void>(
            `${environment.apiPath}/permit/${projectId}`
        );
    }
}
