import { Component, Inject, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DesignProposal } from '../_models/design-proposal';
import { Report } from '../_models/report';
import { ReportService } from '../_services/report.service';
import { ModalManager } from '../furban-modal/furban-modal.manager';
import { Comment } from '../_models/comment';
import { CustomToasterComponent } from '../custom-toaster/custom-toaster.component';
import { Project } from '../_models/project';
import { AuthService } from '../_services/auth.service';
import { CustomToasterService } from '../_services/custom-toaster.service';

@Component({
    selector: 'furban-comments-popup',
    templateUrl: './comments-popup.component.html',
    styleUrls: ['./comments-popup.component.scss'],
})
export class CommentsPopupComponent {
    public dp: DesignProposal;
    public project: Project;
    public parentViewContainerRef: ViewContainerRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private reportService: ReportService,
        private modal: ModalManager,
        private customToasterService: CustomToasterService,
        private authService: AuthService
    ) {
        this.dp = data.designProposal;
        this.project = data.project;
    }

    report(comment: Comment) {
        this.modal
            .showModal(
                this.parentViewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'report.warningCommentMessage',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.saveReport(comment);
                }
            });
    }

    saveReport(comment: Comment) {
        const report = new Report();
        report.reportType = 1;
        report.reportCommentId = comment.commentId;
        report.reportDesignProposalId = comment.designProposalId;
        report.reportingUserProfile =
            this.authService.userProfile.userProfileId;
        report.isActive = true;

        this.reportService.saveReport(report).subscribe((data) => {
            this.customToasterService.openCustomToaster(
                CustomToasterComponent,
                'check_circle',
                'success',
                'report.reportCommentSuccesfully',
                1000
            );
        });
    }
    public isLoggedIn() {
        return this.authService.isLoggedIn();
    }
}
