import { UserSettings } from './user-settings';

export class UserProfile {
    public userProfileId: string; // UUID
    public userId: number;
    public screenName: string;
    public mediaId: string; // UUID
    public acceptedTOS: boolean;
    public winner: boolean;
    public online: boolean;
    public hasUnreadMessages: boolean;
    public userSettings: UserSettings;
}
