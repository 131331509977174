import { Component, Inject } from '@angular/core';
import {
    MatSnackBarRef,
    MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

import { AuthService } from '@furban/utilities';

@Component({
    selector: 'furban-notification-toaster',
    templateUrl: './notification-toaster.component.html',
    styleUrls: ['./notification-toaster.component.scss'],
})
export class NotificationToasterComponent {
    public hideNotifications = false;

    constructor(
        public snackBarRef: MatSnackBarRef<NotificationToasterComponent>,
        public authService: AuthService,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {
        this.snackBarRef.afterDismissed().subscribe(() => {
            if (this.hideNotifications) {
                const userSettings = this.authService.userProfile.userSettings;
                userSettings.show3DNotifications = false;
                this.authService.updateUserSettings(userSettings).subscribe();
            }
        });
    }

    dismiss() {
        this.snackBarRef.closeWithAction();
    }
}
