<furban-login-wrapper [stylingClass]="'admin'" [infoText]="'admin.auth.infoText' | translate">
    <form class="login-form" [formGroup]="changePassCheckForm" (ngSubmit)="changeUserPass()">
        <mat-form-field appearance="outline">
            <mat-label>
                {{ 'admin.auth.newPassword' | translate }}
            </mat-label>
            <input required matInput placeholder="{{ 'admin.auth.newPassword' | translate }}"
                formControlName="passwordFormControl" type="password" />

            <mat-hint align="end">{{
                'auth.validPasswordHint' | translate
                }}</mat-hint>

            <mat-error *ngIf="getPasswordValidationErrors">
                {{
                getPasswordErrorMessage(
                getPasswordValidationErrors,
                translateService
                )
                }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>
                {{ 'admin.auth.confirmPassword' | translate }}
            </mat-label>
            <input required matInput placeholder="{{ 'admin.auth.confirmPassword' | translate }}"
                formControlName="passwordConfirmFormControl" type="password" [errorStateMatcher]="errorMatcher" />
            <mat-error *ngIf="hasControlRequiredError('passwordConfirmFormControl',changePassCheckForm)">
                {{ 'admin.auth.confirmPasswordRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="changePassCheckForm.hasError('passwordMatches')">
                {{ 'admin.auth.confirmPasswordMatch' | translate }}
            </mat-error>
        </mat-form-field>

        <button class="primary-button full-width" type="submit">
            <h6>{{ 'auth.submit' | translate }}</h6>
        </button>
    </form>
</furban-login-wrapper>