import * as THREE from 'three';
import { ThreeUtils } from './three.utils';

export class ThreeGridHelperBuilder {
    public static createGridHelper(
        size: number,
        divisions: number
    ): THREE.GridHelper {
        const gridHelper = new THREE.GridHelper(size, divisions);
        gridHelper.name = ThreeUtils.gridHelperName;
        return gridHelper;
    }
}
