import { Inject, Component } from '@angular/core';
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
    selector: 'furban-app-toaster',
    templateUrl: './custom-toaster.component.html',
    styleUrls: ['./custom-toaster.component.scss'],
})
export class CustomToasterComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<CustomToasterComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {}

    dismiss() {
        this.snackBarRef.dismissWithAction();
    }
}
