import { FormGroup } from '@angular/forms';

export function containSpaceErrorAndNotRequirredTriggered(
    form: FormGroup,
    field: string
) {
    return (
        form.get(field).errors?.['whitespace'] && !form.get(field).errors?.['required']
    );
}
