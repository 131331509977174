<div *ngFor="let poll of getFilteredPolls()" class="poll-card {{getPollStatus(poll)}}">
    <section class="poll-question">
        <p>{{ 'admin.poll.question' | translate }}: <span class="question-text">{{ poll.pollQuestion }}</span></p>
    </section>
    <section class="poll-options">
        <furban-poll-options [options]="poll.pollOptions"></furban-poll-options>
    </section>
    <section class="footer">
        <span class="status-label {{getPollStatus(poll)}}">
            <mat-icon>{{checkIconByStatus(poll)}}</mat-icon>
            <p>{{"admin.poll." + getPollStatus(poll) | translate}}</p>
        </span>

        <div class="date-container">
            <mat-icon class="date-icon" svgIcon="date-icon"></mat-icon>
            {{ poll.startDate | date }} - {{ poll.endDate | date }}
            <ng-container *ngIf="showRemainingDays(poll)">
                {{ "(" + calculateRemainingDays(poll) + ")" }}
            </ng-container>
        </div>
        <ng-container *ngIf="isAdmin()">

            <div class="action-buttons">
                <mat-icon class="red" matTooltip="{{
                            'buttonsTooltips.delete' | translate
                        }}" (click)="deletePoll(poll)">
                    delete
                </mat-icon>
                <ng-container *ngIf="!isDatePassed(poll.endDate) && !poll.isEnabled">
                    <mat-icon class="primary" matTooltip="{{ 'admin.poll.edit' | translate }}"
                        (click)="openCreatePollModal(poll)">
                        edit
                    </mat-icon>
                </ng-container>
                <ng-container *ngIf="!isDatePassed(poll.endDate)">
                    <button class="primary-button" (click)="togglePollState(poll)">
                        <h6>{{ (poll.isEnabled ? 'admin.poll.deactivate' : 'admin.poll.activate') | translate }}</h6>
                    </button>
                </ng-container>
            </div>
        </ng-container>
    </section>
</div>