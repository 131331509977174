import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'apps/furban-client/src/environments/environment';
import { Supplier, SupplierClient, Furban3DModel } from '@furban/utilities';

@Injectable({
    providedIn: 'root',
})
export class SuppliersService {
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );
    constructor(private http: HttpClient) { }

    public getSuppliersById(clientUUID: string): Observable<Supplier[]> {
        return this.http
            .get(environment.apiPath + `/supplier/${clientUUID}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Supplier[]));
    }

    public getSuppliers(): Observable<Supplier[]> {
        return this.http
            .get(environment.apiPath + '/supplier', { headers: this._headers })
            .pipe(map((result) => result as Supplier[]));
    }

    public saveSupplier(supplier: Supplier): Observable<Supplier> {
        return this.http
            .post(environment.apiPath + '/supplier', supplier, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Supplier));
    }

    public getAllObjectsBySupplierId(
        supplierId: string
    ): Observable<Furban3DModel[]> {
        return this.http
            .get(`${environment.apiPath}/object/all/${supplierId}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Furban3DModel[]));
    }

    public updateFurban3DModel(
        model: Furban3DModel
    ): Observable<Furban3DModel> {
        return this.http
            .post(`${environment.apiPath}/object`, model, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Furban3DModel));
    }


    public assignSupplierToClient(
        supplierClients: SupplierClient[]
    ): Observable<SupplierClient[]> {
        return this.http
            .put(environment.apiPath + '/supplier/assign', supplierClients, {
                headers: this._headers,
            })
            .pipe(map((result) => result as SupplierClient[]));
    }

    public deleteSupplierFromClient(
        supplierClients: SupplierClient[]
    ): Observable<any> {
        const httpOptions = {
            headers: this._headers,
            body: supplierClients,
        };
        return this.http
            .delete(environment.apiPath + '/supplier/delete', httpOptions)
            .pipe(map((result) => result));
    }
}
