import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@furban/app-config';
import { DesignProposalReaction, Environment } from '@furban/utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DesignViewerCarouselService {
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        private http: HttpClient
    ) { }

    public deleteDesignProposalReaction(
        designProposalReaction: DesignProposalReaction
    ): Observable<void> {
        const httpOptions = {
            headers: this._headers,
            body: designProposalReaction,
        };

        return this.http.delete<void>(
            `${this.environment.apiPath}/reaction`,
            httpOptions
        );
    }

    public reactToDesignProposal(
        designProposalReaction: DesignProposalReaction
    ): Observable<DesignProposalReaction> {
        return this.http
            .post(
                this.environment.apiPath + '/reaction',
                designProposalReaction,
                { headers: this._headers }
            )
            .pipe(map((result) => result as DesignProposalReaction));
    }

    public getReactionToDesignProposal(
        userProfileId: string,
        designProposalId: string
    ): Observable<DesignProposalReaction> {
        const params = new HttpParams()
            .set('userprofileId', userProfileId)
            .set('designProposalId', designProposalId);

        return this.http.get<DesignProposalReaction>(
            `${this.environment.apiPath}/reaction`,
            {
                params: params,
            }
        );
    }
}
