import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    public leftNavOpened = true;
    public isFullScreen = false;
    public isSafetyEnabled = false;
    public isTransparentEnabled = false;

    public leftNavToggled: EventEmitter<boolean> = new EventEmitter();
    public fullScreenToggled: EventEmitter<boolean> = new EventEmitter();
    public safetyAreaToggled: EventEmitter<boolean> = new EventEmitter();
    public transparentToggled: EventEmitter<boolean> = new EventEmitter();

    public toggleNav(value) {
        this.leftNavOpened = value;
        this.leftNavToggled.emit(value);
    }

    public toggleFullScreen(value) {
        this.isFullScreen = value;
        this.fullScreenToggled.emit(value);
    }

    public toggleSafetyArea(value) {
        this.isSafetyEnabled = value;
        this.safetyAreaToggled.emit(value);
    }

    public toggleTransparency(value: boolean): void {
        this.isTransparentEnabled = value;
        this.transparentToggled.emit(value);
    }
}
