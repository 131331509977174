import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '@furban/utilities';

import { environment } from 'apps/furban-client/src/environments/environment';

@Injectable()
export class RegisterAuthService {
    private _isRegisteringAgain = false;

    public get isRegisteringAgain(): boolean {
        return this._isRegisteringAgain;
    }

    public set isRegisteringAgain(value: boolean) {
        this._isRegisteringAgain = value;
    }

    constructor(private http: HttpClient) { }
    createPioneer(
        username: string,
        password: string,
        clientUuid: string
    ): Observable<User> {
        const REQUEST_DATA = {
            username: username,
            password: password,
        };

        const params = new HttpParams().set(
            'isRegisteringAgain',
            this.isRegisteringAgain.toString()
        );

        return this.http
            .post(
                `${environment.apiPath}/register/pioneer/${clientUuid}`,
                REQUEST_DATA,
                {
                    headers: new HttpHeaders().set(
                        'Content-Type',
                        'application/json'
                    ),
                    params: params,
                }
            )
            .pipe(map((user) => user as User));
    }

    createAdmin(
        username: string,
        password: string,
        clientUuid: string
    ): Observable<User> {
        const REQUEST_DATA = {
            username: username,
            password: password,
        };
        return this.http
            .post(
                `${environment.apiPath}/register/admin/${clientUuid}`,
                REQUEST_DATA,
                {
                    headers: new HttpHeaders().set(
                        'Content-Type',
                        'application/json'
                    ),
                }
            )
            .pipe(map((user) => user as User));
    }
}
