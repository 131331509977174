import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Request } from '@furban/utilities';
import { environment } from 'apps/furban-client/src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SuperadminRequestsService {
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(private http: HttpClient) {}

    getPendingRequests(): Observable<Request[]> {
        return this.http
            .get(`${environment.apiPath}/requests/pending`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Request[]));
    }

    approveRequest(request: Request) {
        return this.http
            .post(environment.apiPath + '/requests/approve', request, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Request));
    }

    rejectRequest(request: Request) {
        return this.http
            .post(environment.apiPath + '/requests/reject', request, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Request));
    }
}
