import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminAuthRecoverService } from './admin-auth-recover.service';
import {
    FormGroup,
    FormBuilder,
    Validators,
    ValidationErrors,
} from '@angular/forms';

import {
    ModalManager,
    AuthService,
    CustomToasterComponent,
    FurbanUtil,
    MultilanguageService,
    MyErrorStateMatcher,
    routingConstants,
    CustomToasterService,
    validatePassword,
    ControlsUtil,
} from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-app-admin-auth-recover',
    templateUrl: './admin-auth-recover.component.html',
    styleUrls: ['./admin-auth-recover.component.scss'],
})
export class AdminAuthRecoverComponent implements OnInit {
    public changePassCheckForm: FormGroup;
    public keyIsExpired = true;
    public passwordConfirmValidator = FurbanUtil.passwordConfirmValidator;
    public errorMatcher = new MyErrorStateMatcher();
    private key: string;
    public getPasswordErrorMessage = FurbanUtil.getPasswordErrorMessage;

    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;

    constructor(
        public router: Router,
        public translateService: TranslateService,
        public authService: AuthService,
        private adminAuthRecoverService: AdminAuthRecoverService,
        private modal: ModalManager,
        private formBuilder: FormBuilder,
        private multilanguageService: MultilanguageService,
        private route: ActivatedRoute,
        private containerRef: ViewContainerRef,
        private customToasterService: CustomToasterService
    ) { }

    ngOnInit() {
        this.changePassCheckForm = this.formBuilder.group(
            {
                passwordFormControl: [
                    '',
                    [Validators.required, validatePassword],
                ],
                passwordConfirmFormControl: ['', Validators.required],
            },
            { validator: this.passwordConfirmValidator }
        );

        this.multilanguageService.setupLanguage();
        this.route.params.subscribe((params) => {
            this.key = params['key'];
            this.keyIsExpired = false;
        });
    }

    public get getPasswordValidationErrors(): ValidationErrors | null {
        return this.changePassCheckForm?.get('passwordFormControl')?.errors;
    }

    changeUserPass() {
        if (this.changePassCheckForm.invalid) {
            return;
        }

        this.adminAuthRecoverService
            .changeAdminPass(
                this.key,
                this.changePassCheckForm.get('passwordFormControl').value
            )
            .subscribe((data) => {
                if (data) {
                    this.customToasterService.openCustomToaster(
                        CustomToasterComponent,
                        'check_circle',
                        'success',
                        'admin.auth.changePassSuccessMessage',
                        2000
                    );

                    this.router.navigate([routingConstants.admin]);
                } else {
                    this.modal
                        .showModal(
                            this.containerRef,
                            ModalManager.createConfiguration(
                                'errors.warning',
                                'admin.auth.changePassErrorMessage',
                                'generic.okBtn'
                            )
                        )
                        .subscribe();
                }
            });
    }
}
