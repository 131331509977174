import { NgModule } from '@angular/core';
import { ProjectDashboardComponent } from './project-dashboard/project-dashboard.component';
import { ProjectActivateComponent } from './project-activate/project-activate.component';
import { ProjectDashboardHeaderComponent } from './project-dashboard-header/project-dashboard-header.component';
import { ProjectDetailsService } from './project-details/project-details.service';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { SetupDefaultObjectsComponent } from './setup-default-objects/setup-default-objects.component';
import { ProjectDesignsComponent } from './project-design-proposals/project-design-proposals.component';
import { ProjectGroupsComponent } from './project-groups/project-groups.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { CustomObjectComponent } from './user-project/custom-object/custom-object.component';
import { UserProjectComponent } from './user-project/project/user-project.component';
import { PublishService } from './publish-popup/publish.service';
import { ProjectPioneerGroupComponent } from './project-pioneer-group/project-pioneer-group.component';
import { SharedModule } from '../shared/shared.module';
import { GenerateGreenComponent } from './user-project/generate-green/generate-green.component';
import { ProjectOverviewDialogComponent } from './project-overview-dialog/project-overview-dialog.component';
import { BaseProjectMenuComponent } from './user-project/base-project-menu/base-project-menu.component';
import { ProjectMenuThreeDComponent } from './user-project/project-menu-three-d/project-menu-three-d.component';
import { RowOfObjectsDialogComponent } from './user-project/row-of-objects-dialog/row-of-objects-dialog.component';
import { CreateDefaultDesignsComponent } from './create-default-designs/create-default-designs.component';
import { ProjectMapComponent } from './project-map/project-map.component';
import { FurbanMapComponent } from './project-map/map/map.component';

import { UtilitiesModule } from '@furban/utilities';
import { DesignProposalsComponent } from './design-proposals/design-proposals.component';
import { ProjectContainerComponent } from './project-container/project-container.component';
import { CategoriesStatisticsComponent } from './categories-statistics/categories-statistics.component';
import { PollsComponent } from './polls/polls.component';
import { DesignProposalCardComponent } from './design-proposals/design-proposal-card/design-proposal-card.component';
import { CollaborativeDesignComponent } from './collaborative-design/collaborative-design.component';
import { GenerateCollaborativeCodesComponent } from './generate-collaborative-codes/generate-collaborative-codes.component';
import { ManageCollaborativeUsersComponent } from './manage-collaborative-users/manage-collaborative-users.component';
import { CollaborativeDesignService } from './collaborative-design/collaborative-design.service';
import { ContributorsShortViewComponent } from './contributors-short-view/contributors-short-view.component';
import { EmbededPopupComponent } from './embeded-popup/embeded-popup.component';
import { CollaborativeSessionsComponent } from './collaborative-sessions/collaborative-sessions.component';
import { GenericMunicipalityCardsComponent } from './generic-municipality-cards/generic-municipality-cards.component';
import { UserStatisticsComponent } from './user-statistics/user-statistics.component';
import { ObjectStatisticsComponent } from './object-statistics/object-statistics.component';
import { StatisticsOverviewComponent } from './statistics-overview/statistics-overview.component';
import { UserChartsComponent } from './user-charts/user-charts.component';
import { PollStatisticsComponent } from './poll-statistics/poll-statistics.component';
import { EmailCodesComponent } from './generate-collaborative-codes/email-codes/email-codes.component';
import { DesignViewerCarouselService } from './_services/design-viewer-carousel.service';
import { InitiativeUsersComponent } from './project-pioneer-group/initiative-users/initiative-users.component';
import { environment } from '../../environments/environment';
import { CommonComponentsModule } from '@furban/common-components';
import { OpenMapComponent } from '../shared/open-map/open-map.component';

@NgModule({
    declarations: [
        ProjectDashboardComponent,
        ProjectActivateComponent,
        ProjectDashboardHeaderComponent,
        ProjectDetailsComponent,
        SetupDefaultObjectsComponent,
        ProjectDesignsComponent,
        DesignProposalsComponent,
        ProjectGroupsComponent,
        CreateGroupComponent,
        CustomObjectComponent,
        UserProjectComponent,
        ProjectPioneerGroupComponent,
        GenerateGreenComponent,
        ProjectOverviewDialogComponent,
        BaseProjectMenuComponent,
        ProjectMenuThreeDComponent,
        RowOfObjectsDialogComponent,
        CreateDefaultDesignsComponent,
        ProjectMapComponent,
        FurbanMapComponent,
        ProjectContainerComponent,
        CategoriesStatisticsComponent,
        PollsComponent,
        DesignProposalCardComponent,
        CollaborativeDesignComponent,
        GenerateCollaborativeCodesComponent,
        ManageCollaborativeUsersComponent,
        ContributorsShortViewComponent,
        DesignProposalCardComponent,
        EmbededPopupComponent,
        CollaborativeSessionsComponent,
        GenericMunicipalityCardsComponent,
        UserStatisticsComponent,
        ObjectStatisticsComponent,
        StatisticsOverviewComponent,
        UserChartsComponent,
        PollStatisticsComponent,
        EmailCodesComponent,
        InitiativeUsersComponent,
        OpenMapComponent
    ],
    imports: [UtilitiesModule.forRoot(environment),
        SharedModule, CommonComponentsModule],
    exports: [
        ProjectDashboardComponent,
        ProjectActivateComponent,
        ProjectDashboardHeaderComponent,
        ProjectDetailsComponent,
        SetupDefaultObjectsComponent,
        ProjectDesignsComponent,
        DesignProposalsComponent,
        ProjectGroupsComponent,
        CreateGroupComponent,
        UserProjectComponent,
        CreateDefaultDesignsComponent,
        ProjectPioneerGroupComponent,
        FurbanMapComponent,
        CategoriesStatisticsComponent,
        PollsComponent,
        CollaborativeDesignComponent,
        DesignProposalCardComponent,
        UserStatisticsComponent,
        ObjectStatisticsComponent,
        StatisticsOverviewComponent,
        UserChartsComponent,
        PollStatisticsComponent,
        EmailCodesComponent,
        OpenMapComponent
    ],
    providers: [
        ProjectDetailsService,
        PublishService,
        CollaborativeDesignService,
        DesignViewerCarouselService
    ],
    entryComponents: [
        CustomObjectComponent,
        GenerateGreenComponent,
        RowOfObjectsDialogComponent,
        ProjectOverviewDialogComponent,
    ],
})
export class ProjectSharedModule { }
