import { PermitState } from './permit-state';

export interface PermitStore {
    actual: PermitState;
    previous: PermitState[];
    future: PermitState[];
}

export const currentPermitStore: PermitStore = {
    actual: {
        assets: [],
        assetsToRemove: [],
        assetsToAdd: [],
    },
    previous: [],
    future: [],
};

export const selectActualPermitState = (state: PermitStore) => state.actual;
