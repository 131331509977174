<section class="padding-0-32" [style.margin-top.px]="78">
    <ng-container *ngIf="!hasGroups() && areGroupsLoaded">
        <div class="flex-column-center" [style.margin-top.px]="40">
            <img src="../../../assets/images/add_user.svg" [style.height.px]="240" />
            <h4 class="placeholder-text">
                {{ 'admin.groups.noGroups' | translate }}
            </h4>
        </div>
    </ng-container>

    <h5 *ngIf="hasGroups()" class="padding-32-0" [style.margin-bottom.px]="0">
        {{ 'admin.groups.curentGroups' | translate }} | {{ totalExperts }}
        {{ 'admin.groups.experts' | translate }}, {{ totalCitizens }}
        {{ 'admin.groups.citizens' | translate }}
    </h5>

    <div *ngIf="areGroupsLoaded" [class.flex-row-center]="!hasGroups()" [class.flex-row-end]="hasGroups()"
        [style.margin-bottom.px]="20">
        <button *ngIf="selection.selected.length > 0" class="secondary-button" [matMenuTriggerFor]="menu"
            [style.margin-right.px]="20">
            <h6>{{ 'admin.manageGroups.export' | translate }}</h6>
        </button>
        <mat-menu #menu="matMenu" overlapTrigger="false">
            <ng-container *ngIf="!isApp()">
                <button mat-menu-item (click)="exportCodes($event)" value="citizen">
                    {{ 'admin.manageGroups.citizenCodes' | translate }}
                </button>
                <button mat-menu-item (click)="exportCodes($event)" value="expert">
                    {{ 'admin.manageGroups.expertCodes' | translate }}
                </button>
            </ng-container>

        </mat-menu>
    </div>

    <table *ngIf="curentGroups && curentGroups.length > 0" mat-table class="full-width" [dataSource]="dataSource">
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="groupName">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'admin.groups.groupName' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="pointer" (click)="navigateToGroup(row)">
                {{ row.groupName }}
            </td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'admin.groups.createdDate' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.createdDate | date : 'mediumDate' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="numberOfCitizens">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'admin.groups.noOfCitizens' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row.usersCount }}</td>
        </ng-container>
        <ng-container matColumnDef="numberOfExperts">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'admin.groups.noOfExperts' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row.expertsCount }}</td>
        </ng-container>
        <ng-container matColumnDef="linkOff">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <span class="material-icons remove" (click)="removeGroup(row.groupId)">link_off</span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</section>