export enum SocketActionsEnum {
    roomEnter = 'roomEnter',
    roomExit = 'roomExit',
    objectsAdd = 'objectsAdd',
    objectsEdit = 'objectsEdit',
    objectsDelete = 'objectsDelete',
    objectsFreeze = 'objectsFreeze',
    objectsUnfreeze = 'objectsUnfreeze',
    sessionStatusUpdate = 'sessionStatusUpdate',
    onAdd = 'onObjectsAdd',
    onEdit = 'onObjectsEdit',
    onDelete = 'onObjectsDelete',
    onFreeze = 'onObjectsFreeze',
    onUnfreeze = 'onObjectsUnfreeze',
    onConnectionsCountUpdate = 'onConnectionsCountUpdate',
    onSessionStatusUpdate = 'onSessionStatusUpdate',
}
