<div class="important-space-between" mat-dialog-title>
    <h2>{{ getTitleText() }}</h2>

    <button class="ghost-button" (click)="closePopup()">
        <span class="material-icons">close</span>
    </button>
</div>

<mat-dialog-content>
    <furban-email-codes (usersAdded)="addUsers($event)"> </furban-email-codes>
</mat-dialog-content>
