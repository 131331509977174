<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'collaborativeDesign.designContributors' | translate }}</h2>
    <button class="ghost-button" (click)="closePopup()">
        <span class="material-icons">close</span>
    </button>
</div>

<mat-dialog-content>
    <ng-container *ngIf="shouldAddNewUsers()">
        <section class="flex-row-start">
            <mat-form-field appearance="standard" class="full-width" [style.margin-right.px]="12">
                <mat-label>
                    {{ 'collaborativeDesign.contributorEmail' | translate }}
                </mat-label>
                <input required matInput placeholder="{{
                        'collaborativeDesign.contributorEmail' | translate
                    }}" [formControl]="inviteUserFormControl" />

                <mat-error *ngIf="hasControlError(inviteUserFormControl,'email')">
                    {{ 'admin.auth.validEmail' | translate }}
                </mat-error>
            </mat-form-field>

            <button class="primary-button" (click)="addUser()">
                <h6>{{ 'collaborativeDesign.invite' | translate }}</h6>
            </button>
        </section>
    </ng-container>

    <section class="user-list">
        <div *ngFor="let user of contributorsList" class="user">
            <section class="user-details">
                <furban-avatar-picture [name]="user.screenName" [size]="48" [mediaId]="getUserMedia(user.mediaId)"
                    [style.margin-right.px]="12">
                </furban-avatar-picture>
                <h3>{{ user.screenName }}</h3>
            </section>

            <ng-container *ngIf="isAdmin">
                <span class="material-icons pointer" (click)="removeUser(user.userProfileId)">
                    delete
                </span>
            </ng-container>
        </div>
    </section>

</mat-dialog-content>