import { GeneralComment } from './general-comment';
import { UserProfile } from './user-profile';

export class Comment extends GeneralComment {
    public designProposalId: string;
    public reportsCount?: number;
    public childComments?: Comment[];

    constructor(
        designProposalId?: string,
        userProfile?: UserProfile,
        position?: string,
        text?: string
    ) {
        super();
        this.designProposalId = designProposalId;
        this.userProfile = userProfile;
        this.position = position;
        this.commentText = text;
    }
}
