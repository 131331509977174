import * as THREE from 'three';
import { TextureColorEnum } from '../_enum/texture-color.enum';

export class FreeshapeHelper {
    drawShapeFromPoints(points: THREE.Vector3[]): THREE.Shape {
        const shape = new THREE.Shape();
        shape.moveTo(points[0].x, points[0].z);

        points.forEach((coordinate) => {
            shape.lineTo(coordinate['x'], coordinate['z']);
        });
        return shape;
    }

    createMeshFromShape(shape: THREE.Shape, yPosition: number): THREE.Mesh {
        const geometry = new THREE.ShapeGeometry(shape);
        const material = new THREE.MeshBasicMaterial({
            color: TextureColorEnum.lightBlue,
        });
        material.transparent = true;
        material.opacity = 0.4;
        const mesh = new THREE.Mesh(geometry, material);
        this.addPropertiesToMesh(mesh, yPosition);
        return mesh;
    }

    addPropertiesToMesh(mesh: THREE.Mesh, yPosition: number) {
        mesh.rotation.x = -Math.PI / 2;
        mesh.updateMatrix();
        mesh.geometry.applyMatrix4(mesh.matrix);
        mesh.rotation.set(0, 0, 0);
        mesh.scale.z = -1;
        mesh.position.setY(yPosition);
        mesh.renderOrder = 100;
    }

    generateHelper(points: THREE.Vector3[]): THREE.Mesh {
        const shape = this.drawShapeFromPoints(points);
        const mesh = this.createMeshFromShape(shape, points[0].y);
        return mesh;
    }

    generateCylinderHelper(): THREE.Mesh {
        const geometry = new THREE.CylinderGeometry(0.1, 0.1, 10, 32);
        const material = new THREE.MeshBasicMaterial({
            color: TextureColorEnum.lightBlue,
        });
        return new THREE.Mesh(geometry, material);
    }
}
