import { Injectable } from '@angular/core';

@Injectable()
export class ProjectSettingsService {
    private _currentStep = 0;

    public get currentStep(): number {
        return this._currentStep;
    }

    public set currentStep(value: number) {
        this._currentStep = value;
    }
}
