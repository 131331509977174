import { Component, Input } from '@angular/core';

@Component({
    selector: 'furban-conversation-divider',
    templateUrl: './conversation-divider.component.html',
    styleUrls: ['./conversation-divider.component.scss'],
})
export class ConversationDividerComponent {
    @Input() text?: string;
}
