import { Component, OnInit } from '@angular/core';
import { TutorialsArray } from '../_constants/tutorials-array';
import { Tutorial } from '../_models/tutorial';
import { TutorialsService } from '../_services/tutorials.service';
import { AuthService } from '@furban/utilities';
@Component({
    selector: 'furban-tutorials-menu',
    templateUrl: './tutorials-menu.component.html',
    styleUrls: ['./tutorials-menu.component.scss'],
})
export class TutorialsMenuComponent implements OnInit {
    public tutorials: Tutorial[];
    public neighborTutorials: Tutorial[];
    public currentlyDispayedIndex: number;
    public currentlyDispayedTutorial: Tutorial;

    constructor(
        private tutorialsService: TutorialsService,
        public authService: AuthService
    ) {}

    ngOnInit(): void {
        const userRole = this.authService.getUserRoleAsString();
        this.tutorials = TutorialsArray.getTutorials(userRole);
    }

    closeTutorials(): void {
        this.tutorialsService.closeTutorialMenu();
    }

    openTutorial(index: number): void {
        this.currentlyDispayedIndex = index;
        this.currentlyDispayedTutorial = this.tutorials[index];
    }

    closeTutorialDetails(): void {
        this.currentlyDispayedIndex = null;
        this.currentlyDispayedTutorial = null;
    }

    next(): void {
        this.currentlyDispayedIndex++;
        this.currentlyDispayedTutorial =
            this.tutorials[this.currentlyDispayedIndex];
    }

    previous(): void {
        this.currentlyDispayedIndex--;
        this.currentlyDispayedTutorial =
            this.tutorials[this.currentlyDispayedIndex];
    }
}
