import { Node, Selection } from 'd3';
import { d3tip } from 'd3-v6-tip';

export class BubbleChartInstance {
    public min: number;
    public max: number;
    public values: number[];
    public root: Node;

    public svg: Selection;
    public tip: d3tip;
    public nodeStructure: Selection;

    public diameter = 300;
    public margin = {
        left: 24,
        right: 24,
        top: 24,
        bottom: 24,
    };
    public tooltipPosition = [-12, 0];
    public nodePadding = 4;
}
