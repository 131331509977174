<h3 class="page-title">{{ 'admin.permitRequests.title' | translate }}</h3>

<ng-container *ngIf="permitRequestsCount === 0; else permitCards">
    <div class="flex-column-center padding-20">
        <img
            src="../../../assets/images/no_permit_reviews.svg"
            [style.height.px]="300"
        />
        <h4 class="placeholder-text">
            {{ 'admin.permitRequests.noPermitRequestsMessage' | translate }}
        </h4>
    </div>
</ng-container>

<ng-template #permitCards>
    <div
        infiniteScroll
        class="cards"
        [infiniteScrollContainer]="'.main-container'"
        [infiniteScrollThrottle]="300"
        [infiniteScrollDistance]="1"
        [fromRoot]="true"
        [scrollWindow]="false"
        (scrolled)="onScrollDown()"
    >
        <furban-permit-request-card
            *ngFor="let permitRequestProject of permitRequests"
            [permitReview]="permitRequestProject"
            (commentDialogOpened)="openCommentDialog($event)"
            (reviewDialogOpened)="openReviewDialog($event)"
        >
        </furban-permit-request-card>
    </div>
</ng-template>
