<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'cookies.cookiePolicyDialogTitle' | translate }}</h2>
    <button (click)="closeDialog()" class="ghost-button" mat-dialog-close>
        <span class="material-icons"> close </span>
    </button>
</div>

<div mat-dialog-content>
    <ng-container
        *ngIf="multilanguageService && multilanguageService.usedLang === 'en-US'"
    >
        <p>
            This is the Cookie Policy for Furban, accessible from
            <a href="www.furban.nl">www.furban.nl</a>.
        </p>

        <p><strong>What are cookies</strong></p>
        <p>
            As is common practice with almost all professional websites this
            site uses cookies, which are tiny files that are downloaded to your
            computer, to improve your experience. This page describes what
            information they gather, how we use it and why we sometimes need to
            store these cookies. We will also share how you can prevent these
            cookies from being stored however this may downgrade or 'break'
            certain elements of the sites functionality.
        </p>

        <p><strong> How we use cookies </strong></p>
        <p>
            We use cookies for a variety of reasons detailed below.
            Unfortunately in most cases there are no industry standard options
            for disabling cookies without completely disabling the functionality
            and features they add to this site. It is recommended that you leave
            on all cookies if you are not sure whether you need them or not in
            case they are used to provide a service that you use.
        </p>

        <p><strong> Disabling cookies </strong></p>
        <p>
            You can prevent the setting of cookies by adjusting the settings on
            your browser (see your browser Help for how to do this). Be aware
            that disabling cookies will affect the functionality of this and
            many other websites that you visit. Disabling cookies will usually
            result in also disabling certain functionality and features of the
            this site. Therefore it is recommended that you do not disable
            cookies.
        </p>

        <p><strong>The cookies we set </strong></p>
        <ul [style.padding-left.px]="24">
            <li>
                <strong>Login related cookies </strong><br />
                We use cookies when you are logged in so that we can remember
                this fact. This prevents you from having to log in every single
                time you visit a new page. These cookies are typically removed
                or cleared when you log out to ensure that you can only access
                restricted features and areas when logged in.
            </li>
            <li>
                <strong> Site preferences cookies </strong> <br />
                In order to provide you with a great experience on this site we
                provide the functionality to set your preferences for how this
                site runs when you use it. In order to remember your
                preferences, like your language, we need to set cookies so that
                this information can be called whenever you interact with a page
                is affected by your preferences.
            </li>
        </ul>
        <p>
            <strong>More Information</strong>
        </p>
        <p>
            If you are still looking for more information, contact us by email:
            <a href="mailto://info@furban.nl">info@furban.nl</a>.
        </p>
    </ng-container>
    <ng-container
        *ngIf="multilanguageService && multilanguageService.usedLang === 'nl-NL'"
    >
        <p>
            Dit is het Cookiebeleid voor Furban, toegankelijk via
            <a href="www.furban.nl">www.furban.nl</a>.
        </p>

        <p><strong>Wat zijn cookies?</strong></p>
        <p>
            Zoals gebruikelijk bij bijna alle professionele websites, gebruikt
            deze site cookies, dit zijn kleine bestandjes die naar uw computer
            worden gedownload, om uw ervaring te verbeteren. Deze pagina
            beschrijft welke informatie ze verzamelen, hoe we deze gebruiken en
            waarom we deze cookies soms moeten opslaan. We zullen ook delen hoe
            u kunt voorkomen dat deze cookies worden opgeslagen, maar dit kan
            bepaalde elementen van de functionaliteit van de site downgraden of
            kapot maken.
        </p>

        <p><strong> Hoe we cookies gebruiken </strong></p>
        <p>
            We gebruiken cookies om verschillende redenen die hieronder worden
            beschreven. Helaas zijn er in de meeste gevallen geen
            industriestandaard opties om cookies uit te schakelen zonder de
            functionaliteit en functies die ze aan deze site toevoegen volledig
            uit te schakelen. Het wordt aanbevolen om alle cookies te laten
            staan ​​als u niet zeker weet of u ze nodig heeft of niet voor het
            geval ze worden gebruikt om een ​​dienst te verlenen die u gebruikt.
        </p>

        <p><strong> Cookies uitschakelen </strong></p>
        <p>
            U kunt het plaatsen van cookies voorkomen door de instellingen in uw
            browser aan te passen (zie de Help van uw browser voor meer
            informatie hierover). Houd er rekening mee dat het uitschakelen van
            cookies de functionaliteit van deze en vele andere websites die u
            bezoekt, beïnvloedt. Het uitschakelen van cookies resulteert meestal
            ook in het uitschakelen van bepaalde functionaliteit en functies van
            de site. Daarom wordt aanbevolen dat u cookies niet uitschakelt.
        </p>

        <p><strong>De cookies die we plaatsen </strong></p>
        <ul [style.padding-left.px]="24">
            <li>
                <strong>Inlog-gerelateerde cookies</strong><br />
                We gebruiken cookies wanneer u bent ingelogd, zodat we dit feit
                kunnen onthouden. Dit voorkomt dat u elke keer dat u een nieuwe
                pagina bezoekt, moet inloggen. Deze cookies worden meestal
                verwijderd of gewist wanneer u uitlogt om ervoor te zorgen dat u
                alleen toegang heeft tot beperkte functies en gebieden wanneer u
                bent ingelogd.
            </li>
            <li>
                <strong> Cookies voor sitevoorkeuren </strong> <br />
                Om u een geweldige ervaring op deze site te bieden, bieden we de
                functionaliteit om uw voorkeuren in te stellen voor hoe deze
                site werkt wanneer u deze gebruikt. Om uw voorkeuren, zoals uw
                taal, te onthouden, moeten we cookies instellen zodat deze
                informatie kan worden opgeroepen wanneer uw interactie met een
                pagina wordt beïnvloed door uw voorkeuren.
            </li>
        </ul>
        <p>
            <strong>Meer informatie</strong>
        </p>
        <p>
            Mocht je nog meer informatie willen, neem dan contact met ons op via
            <a href="mailto://info@furban.nl">info@furban.nl</a>.
        </p>
    </ng-container>
    <ng-container
        *ngIf="multilanguageService && multilanguageService.usedLang === 'ro-RO'"
    >
        <p>
            This is the Cookie Policy for Furban, accessible from
            <a href="www.furban.nl">www.furban.nl</a>.
        </p>

        <p><strong>What are cookies</strong></p>
        <p>
            As is common practice with almost all professional websites this
            site uses cookies, which are tiny files that are downloaded to your
            computer, to improve your experience. This page describes what
            information they gather, how we use it and why we sometimes need to
            store these cookies. We will also share how you can prevent these
            cookies from being stored however this may downgrade or 'break'
            certain elements of the sites functionality.
        </p>

        <p><strong> How we use cookies </strong></p>
        <p>
            We use cookies for a variety of reasons detailed below.
            Unfortunately in most cases there are no industry standard options
            for disabling cookies without completely disabling the functionality
            and features they add to this site. It is recommended that you leave
            on all cookies if you are not sure whether you need them or not in
            case they are used to provide a service that you use.
        </p>

        <p><strong> Disabling cookies </strong></p>
        <p>
            You can prevent the setting of cookies by adjusting the settings on
            your browser (see your browser Help for how to do this). Be aware
            that disabling cookies will affect the functionality of this and
            many other websites that you visit. Disabling cookies will usually
            result in also disabling certain functionality and features of the
            this site. Therefore it is recommended that you do not disable
            cookies.
        </p>

        <p><strong>The cookies we set </strong></p>
        <ul [style.padding-left.px]="24">
            <li>
                <strong>Login related cookies </strong><br />
                We use cookies when you are logged in so that we can remember
                this fact. This prevents you from having to log in every single
                time you visit a new page. These cookies are typically removed
                or cleared when you log out to ensure that you can only access
                restricted features and areas when logged in.
            </li>
            <li>
                <strong> Site preferences cookies </strong> <br />
                In order to provide you with a great experience on this site we
                provide the functionality to set your preferences for how this
                site runs when you use it. In order to remember your
                preferences, like your language, we need to set cookies so that
                this information can be called whenever you interact with a page
                is affected by your preferences.
            </li>
        </ul>
        <p>
            <strong>More Information</strong>
        </p>
        <p>
            If you are still looking for more information, contact us by email:
            <a href="mailto://info@furban.nl">info@furban.nl</a>.
        </p>
    </ng-container>
</div>
