<ng-container *ngIf="threeIsPrepared">
    <div class="three-container">
        <ng-container
            *ngIf="curentDPs && curentDPs.length > 0; else noDesignsImage"
        >
            <div class="image-container">
                <ng-container *ngIf="!viewDetailsMode">
                    <ng-container *ngFor="let dp of curentDPs">
                        <img
                            *ngIf="
                                dp.designProposalId ===
                                currentDisplayedDp.designProposalId
                            "
                            [src]="dp.src"
                            @carouselAnimation
                        />
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="viewDetailsMode">
                    <furban-public-three-js-editor
                        [project]="project"
                        [userObjects]="currentObjects"
                        [state]="threeState"
                        [showButtons]="false"
                        [userProfileId]="
                            currentDisplayedDp.userProfile?.userProfileId
                        "
                    >
                    </furban-public-three-js-editor>
                </ng-container>
            </div>
            <div class="left-buttons">
                <furban-like
                    [style.margin-right.px]="12"
                    class="likes"
                    [disabled]="project.ended"
                    [dp]="currentDisplayedDp"
                    [hasCircle]="true"
                >
                </furban-like>
                <ng-container *ngIf="currentDisplayedDp.userProfile">
                    <furban-avatar-picture
                        [name]="currentDisplayedDp.userProfile.screenName"
                        [size]="40"
                        [mediaId]="
                            getUserMedia(currentDisplayedDp.userProfile.mediaId)
                        "
                    >
                    </furban-avatar-picture>
                </ng-container>
            </div>

            <div class="right-buttons">
                <label
                    class="project-type"
                    [style.color]="
                        getColorBasedOnProposalType(currentDisplayedDp)
                    "
                >
                    {{
                        getDesignProposalTypeText(currentDisplayedDp)
                            | translate
                    }}
                </label>
                <ng-container *ngIf="viewDetailsMode">
                    <span
                        class="material-icons"
                        [style.margin-left.px]="8"
                        (click)="close3D()"
                        >close</span
                    >
                </ng-container>
            </div>

            <ng-container *ngIf="isSwipeActive">
                <div
                    class="swipe flex-row-center"
                    (swipeleft)="swipe($event.type)"
                    (swiperight)="swipe($event.type)"
                    appHoldable
                    [longpressTimer]="2000"
                    [isLoadingComputed]="true"
                    (eventHandler)="holdHandler($event)"
                ></div>
            </ng-container>

            <div
                class="swipe-buttons flex-row-spaced"
                [class.default-cursor]="viewDetailsMode"
            >
                <button
                    class="flex-row-center"
                    (click)="swipe(swipeAction.left)"
                >
                    <span class="material-icons"> chevron_left</span>
                </button>

                <button
                    class="flex-row-center"
                    (click)="swipe(swipeAction.right)"
                >
                    <span class="material-icons"> chevron_right </span>
                </button>
            </div>
        </ng-container>

        <ng-template #noDesignsImage>
            <div class="no-design padding-40 flex-row-center">
                <img src="../../assets/images/no_design.svg" />
            </div>
        </ng-template>

        <ng-container *ngIf="isSwipeActive && percentage && percentage > 0">
            <mat-progress-bar mode="determinate" [value]="percentage">
            </mat-progress-bar>
        </ng-container>
        <div
            class="message flex-row-spaced padding-16-24"
            id="designProposals-step"
        >
            <ng-container
                *ngIf="curentDPs && curentDPs.length > 0; else noDesignsMessage"
            >
                <p>
                    {{ 'user.dashboard.swipeMessage' | translate }}
                </p>
            </ng-container>
            <ng-template #noDesignsMessage>
                <p>{{ 'user.dashboard.noDesignsYet' | translate }}</p>
            </ng-template>
            <button class="secondary-button" (click)="openDesignProposals()">
                <h6>{{ 'user.dashboard.viewAll' | translate }}</h6>
            </button>
        </div>
    </div>
</ng-container>
