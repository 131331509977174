import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PermitUtils } from '@furban/utilities';
import { PublicPermitReview } from '../_models/public-permit-review';
import { PermitReviewService } from '../_services/permit-review.service';

@Component({
    selector: 'furban-public-reviews',
    templateUrl: './public-reviews.component.html',
    styleUrls: ['./public-reviews.component.scss'],
})
export class PublicReviewsComponent implements OnInit {
    public permitReviews: PublicPermitReview[];
    public getPermitLabelColor = PermitUtils.getPermitLabelColor;
    private projectId: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private permitReviewService: PermitReviewService,
        private dialogRef: MatDialogRef<PublicReviewsComponent>
    ) {
        this.projectId = data.projectId;
    }

    ngOnInit(): void {
        this.getPermitPublicReviews();
    }

    public closePopup(): void {
        this.dialogRef.close();
    }

    private getPermitPublicReviews(): void {
        this.permitReviewService
            .getPublicPermitReviews(this.projectId)
            .subscribe((data) => {
                this.permitReviews = data;
            });
    }
}
