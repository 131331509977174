<form class="padding-0-32 flex-column-start" [style.margin-top.px]="76" [formGroup]="projectCheckForm">
    <div class="flex-row-start full-width">
        <div class="flex-column-start custom-width-30" [style.margin-right.px]="32">
            <div class="project-image image-container background-center">
                <img #projectImage *ngIf="imageData" alt="Project Image" class="project-image custom-image"
                    [src]="imageData" />
                <div *ngIf="!project?.hasWinner" class="overlay-image" (click)="openImageUploadDialog()">
                    <span class="material-icons"> add_a_photo </span>
                    <a>{{ 'admin.projectDetails.uploadPic' | translate }}</a>
                </div>
            </div>
        </div>

        <div class="flex-column-start custom-width-70">
            <div class="flex-row-spaced full-width">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                        'admin.projectDetails.projectName' | translate
                        }}</mat-label>
                    <input matInput required #projectName maxlength="{{
                            projectConstantValues.displayNameMaxLength
                        }}" placeholder="{{
                            'admin.projectDetails.projectName' | translate
                        }}" formControlName="projectNameFormControl" />
                    <mat-hint align="end">{{ projectName.value.length }} /
                        {{ projectConstantValues.displayNameMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="hasControlIsHtmlError('projectNameFormControl',projectCheckForm)">
                        {{ 'validators.noHTML' | translate }}
                    </mat-error>
                    <mat-error *ngIf="
                            containSpaceErrorAndNotRequirredTriggeredFunction(
                                projectCheckForm,
                                'projectNameFormControl'
                            )
                        ">
                        {{ 'validators.noWhiteSpaces' | translate }}
                    </mat-error>
                    <mat-error *ngIf="hasControlRequiredError('projectNameFormControl',projectCheckForm)">
                        {{
                        'admin.projectDetails.projectNameRequiredText'
                        | translate
                        }}
                    </mat-error>
                    <mat-error *ngIf="hasControlMaxLengthError('projectNameFormControl',projectCheckForm)">
                        {{
                        'admin.projectDetails.projectNamemaxLengthText'
                        | translate
                        }}
                    </mat-error>
                </mat-form-field>
            </div>
            <mat-form-field class="full-width project-desc" appearance="outline">
                <mat-label>{{
                    'admin.projectDetails.projectDescr' | translate
                    }}</mat-label>
                <textarea matInput required maxlength="{{ projectConstantValues.descriptionMaxLength }}"
                    matTextareaAutosize matAutosizeMinRows="2" #projectDescription placeholder="{{
                        'admin.projectDetails.projectDescr' | translate
                    }}" formControlName="descriptionFormControl"></textarea>
                <mat-hint align="end">{{ projectDescription.value.length }} /
                    {{ projectConstantValues.descriptionMaxLength }}</mat-hint>
                <mat-error *ngIf="hasControlIsHtmlError('descriptionFormControl', projectCheckForm)">
                    {{ 'validators.noHTML' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlWhitespacesError('descriptionFormControl',projectCheckForm)">
                    {{ 'validators.noWhiteSpaces' | translate }}
                </mat-error>
            </mat-form-field>
            <div class="flex-row-spaced full-width" [class.date-container-3]="!project.isCitizenDesigns">
                <mat-form-field class="full-width date-field" appearance="outline" (click)="pickerStart.open()">
                    <mat-label>
                        {{ 'admin.projectDetails.chooseStartDate' | translate }}
                    </mat-label>
                    <input matInput required autocomplete="off" formControlName="projectStartDateFormControl"
                        placeholder="{{
                            'admin.projectDetails.chooseStartDate' | translate
                        }}" [min]="minStartDate" [matDatepicker]="pickerStart" />
                    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStart required> </mat-datepicker>
                </mat-form-field>

                <mat-form-field class="full-width date-field" appearance="outline" [style.margin-left.px]="20"
                    (click)="pickerEnd.open()">
                    <mat-label>
                        {{ 'admin.projectDetails.chooseEndDate' | translate }}
                    </mat-label>
                    <input matInput required autocomplete="off" formControlName="projectEndDateFormControl" placeholder="{{
                            'admin.projectDetails.chooseEndDate' | translate
                        }}" [min]="minEndDate" [matDatepicker]="pickerEnd" />
                    <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEnd> </mat-datepicker>
                </mat-form-field>

                <ng-container *ngIf="!project.isCitizenDesigns">
                    <mat-form-field class="date-field" appearance="outline" (click)="pickerExpiredVoting.open()">
                        <mat-label>
                            {{
                            'admin.projectDetails.chooseVotingExpiringDate'
                            | translate
                            }}
                        </mat-label>
                        <input matInput required autocomplete="off"
                            formControlName="projectVotingExpiringDateFormControl" placeholder="{{
                                'admin.projectDetails.chooseVotingExpiringDate'
                                    | translate
                            }}" [min]="minVotingExipringDate" [matDatepicker]="pickerExpiredVoting" />
                        <mat-datepicker-toggle matSuffix [for]="pickerExpiredVoting"></mat-datepicker-toggle>
                        <mat-datepicker #pickerExpiredVoting required>
                        </mat-datepicker>
                    </mat-form-field>
                </ng-container>
            </div>

            <div class="flex-row-start full-width toggles-container">
                <section *ngIf="!isPioneerProject" class="flex-row-spaced price" [style.width.%]="50">
                    <mat-form-field class="full-width" appearance="outline" [style.margin-right.px]="8">
                        <mat-label>
                            {{
                            'admin.projectDetails.projectPrice' | translate
                            }}
                        </mat-label>
                        <input matInput type="number" #projectPrice placeholder="{{
                                'admin.projectDetails.projectPrice' | translate
                            }}" formControlName="projectPriceFormControl" />
                        <mat-error *ngIf="
                                projectCheckForm.get('projectPriceFormControl')
                                    .errors
                            ">
                            {{
                            'admin.projectDetails.projectPricePattern'
                            | translate
                            }}
                        </mat-error>
                    </mat-form-field>

                    <mat-slide-toggle formControlName="priceRestrictControl" labelPosition="before"
                        [style.margin-left.px]="12">
                        {{
                        'admin.projectDetails.maxPriceRestriction'
                        | translate
                        }}
                    </mat-slide-toggle>
                </section>

                <mat-slide-toggle *ngIf="project && !project.isCitizenDesigns"
                    formControlName="allowCitizenDesignsControl" labelPosition="before" [style.margin-left.px]="20">
                    {{ 'admin.projectDetails.allowCitizenDesigns' | translate }}
                </mat-slide-toggle>
            </div>
        </div>
    </div>
    <ng-container *ngIf="hasDocumentsOrNoWinner()">
        <section class="padding-20-0 flex-column-start full-width">
            <h5 [style.margin-bottom.px]="12">
                {{ 'admin.projectDetails.projectFiles' | translate }}
            </h5>
            <ng-container *ngIf="documents">
                <div class="list">
                    <div *ngFor="let document of documents" class="list-element">
                        <ng-container *ngIf="document.isActive">
                            <span class="material-icons green-icon">attach_file</span>
                            <ng-container *ngIf="!isApp()">
                                <a (click)="downloadDocument(document)">{{
                                    document.fileName
                                    }}</a>
                            </ng-container>
                            <span *ngIf="!project?.hasWinner" (click)="deleteDocumentLabel(document)"
                                class="material-icons remove">delete</span>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!project?.hasWinner">
                <div class="info-message">
                    <h4>{{ 'errors.info' | translate }}</h4>
                    <h5>
                        {{ 'admin.projectDetails.uploadFilesInfo' | translate }}
                    </h5>
                </div>

                <button class="secondary-button" (click)="clickUploadFileLink()">
                    <h6>{{ 'admin.projectDetails.uploadPdf' | translate }}</h6>
                </button>
                <input type="file" multiple class="file-upload-input" (change)="fileChange($event)"
                    accept=".pdf, image/png, image/jpg, image/gif, image/jpeg" />
            </ng-container>
        </section>
    </ng-container>
</form>