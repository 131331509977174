<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'faq.addQuestion' | translate }}</h2>

    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons"> close </span>
    </button>
</div>

<mat-dialog-content>
    <form class="flex-column-end" [formGroup]="questionForm">
        <mat-form-field class="full-width" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'faq.dutchQuestion' | translate }}
            </mat-label>
            <input required matInput placeholder="{{ 'faq.dutchQuestion' | translate }}" formControlName="questionNL" />
            <mat-error *ngIf="hasControlRequiredError('questionNL',questionForm)">
                {{ 'faq.questionFieldRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlMaxLengthError('questionNL',questionForm)">
                {{ 'faq.questionMax' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'faq.englishQuestion' | translate }}
            </mat-label>
            <input required matInput placeholder="{{ 'faq.englishQuestion' | translate }}"
                formControlName="questionEN" />
            <mat-error *ngIf="hasControlRequiredError('questionEN',questionForm)">
                {{ 'faq.questionFieldRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlMaxLengthError('questionEN',questionForm)">
                {{ 'faq.questionMax' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'faq.dutchAnswer' | translate }}
            </mat-label>
            <textarea matInput required placeholder="{{ 'faq.dutchAnswer' | translate }}"
                formControlName="answerNL"></textarea>
            <mat-error *ngIf="hasControlRequiredError('answerNL',questionForm)">
                {{ 'faq.answerFieldRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlMaxLengthError('answerNL',questionForm)">
                {{ 'faq.answerMax' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'faq.englishAnswer' | translate }}
            </mat-label>
            <textarea matInput required placeholder="{{ 'faq.englishAnswer' | translate }}"
                formControlName="answerEN"></textarea>
            <mat-error *ngIf="hasControlRequiredError('answerEN',questionForm)">
                {{ 'faq.answerFieldRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlMaxLengthError('answerEN',questionForm)">
                {{ 'faq.answerMax' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'faq.videoURL' | translate }}
            </mat-label>
            <input matInput placeholder="{{ 'faq.videoURL' | translate }}" formControlName="videoUrl" />
            <mat-error *ngIf="hasControlMaxLengthError('videoUrl',questionForm)">
                {{ 'faq.videoURLMax' | translate }}
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closeDialog()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="saveQuestion()">
        <h6>{{ 'generic.save' | translate }}</h6>
    </button>
</div>