<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'pioneer.permitInvolvement.codesStatusTitle' | translate }}</h2>
    <button class="ghost-button" (click)="closePopup()">
        <span class="material-icons">close</span>
    </button>
</div>

<mat-dialog-content class="flex-column-center">
    <mat-tab-group class="generate-codes-section" animationDuration="0ms">
        <mat-tab label="{{ 'admin.codes.generateCodes' | translate }}">
            <section class="flex-row-spaced">
                <mat-form-field class="generate-codes-field" appearance="standard">
                    <mat-label>
                        {{
                        'pioneer.permitInvolvement.numberOfNeighbors'
                        | translate
                        }}
                    </mat-label>
                    <input required matInput min="1" max="20" [formControl]="noOfCodesFormControl" placeholder="{{
                            'pioneer.permitInvolvement.numberOfNeighbors'
                                | translate
                        }}" type="number" />
                    <mat-error *ngIf="hasControlError(noOfCodesFormControl,'max')">
                        {{ 'pioneer.permitInvolvement.maxError' | translate }}
                    </mat-error>

                    <mat-error *ngIf="hasControlError(noOfCodesFormControl,'min')">
                        {{ 'pioneer.permitInvolvement.minError' | translate }}
                    </mat-error>
                </mat-form-field>

                <button class="primary-button" [class.disabled]="isGenerateButtonDisabled"
                    (click)="generateNeighborCodes()">
                    <h6>
                        {{ 'admin.codes.generateCodes' | translate }}
                    </h6>
                </button>
            </section>

            <ng-container *ngIf="permitReviews?.plainNeighborReviews">
                <furban-neighbor-permit-reviews class="codes-list" [permitReviews]="permitReviews.plainNeighborReviews">
                </furban-neighbor-permit-reviews>
            </ng-container>
        </mat-tab>

        <mat-tab label="{{ 'admin.codes.emailCodes' | translate }}">
            <furban-email-codes (usersAdded)="generateByEmail($event)">
            </furban-email-codes>

            <ng-container *ngIf="permitReviews?.neighborWithEmailReviews">
                <furban-neighbor-permit-reviews class="codes-list"
                    [permitReviews]="permitReviews.neighborWithEmailReviews">
                </furban-neighbor-permit-reviews>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
    <div class="action-buttons-container">
        <button class="secondary-button" [style.margin-right.px]="12" (click)="closePopup()">
            <h6>{{ 'generic.cancelBtn' | translate }}</h6>
        </button>
        <button class="primary-button" [class.disabled]="isExportButtonDisabled" (click)="exportCodes()">
            <h6>{{ 'pioneer.permitInvolvement.export' | translate }}</h6>
        </button>
    </div>
</mat-dialog-content>