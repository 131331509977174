import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ViewContainerRef } from '@angular/core';

import {
    ModalManager,
    AuthService,
    ErrorService,
    routingConstants,
    UserVerificationEmailService,
    FurbanUtil,
} from '@furban/utilities';
import { Router, NavigationStart } from '@angular/router';

import { googleAnalytics } from 'apps/furban-client/src/assets/scripts/ga';
import { ModifyStatusService } from './shared/modifyStatus/modifyStatus.service';
import { NavigationService } from './shared/navigation/navigation.service';
import { RegisterAuthService } from './shared/register/register-auth.service';
import { ProjectDetailsService } from './project-shared/project-details/project-details.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
    selector: 'furban-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public showSideNav = true;
    private ngUnsubscribe = new Subject();
    private projectsListSubscription: Subscription;

    
    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private errorService: ErrorService,
        private modal: ModalManager,
        private viewContainerRef: ViewContainerRef,
        private modifyStatusService: ModifyStatusService,
        private router: Router,
        private navigationService: NavigationService,
        private sentEmailService: UserVerificationEmailService,
        private registerAuthService: RegisterAuthService,
        private projectDetailsService: ProjectDetailsService,
        public authService: AuthService,
        private matIconRegistry: MatIconRegistry,
        private domSanitzer: DomSanitizer
    ) {
        this.initializeErrors();
        this.initializeGA();
        this.generateCustomIcons();
    }

    ngOnInit() {
        this.modifyStatusService.clear();
        this.initializeProjectList();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
        this.projectsListSubscription?.unsubscribe();
    }

    changeNavigationState(): void {
        this.showSideNav = !this.showSideNav;
        this.navigationService.toggleNav(this.showSideNav);
    }

    isFullScreenMode(): boolean {
        return this.navigationService.isFullScreen;
    }

    showNavigation(): boolean {
        if (
            this.authService.user &&
            (this.router.url === routingConstants.app ||
                this.router.url === routingConstants.admin ||
                this.router.url === routingConstants.info ||
                this.router.url.includes('/recover'))
        ) {
            return false;
        } else if (!this.authService.user) {
            return false;
        }


        return true;
    }

    checkIfInfoPage(): boolean {
        return this.router.url === routingConstants.info;
    }

    showExpiredEmailVerificationModal() {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'generic.whatsup',
                    'errors.40022',
                    'pioneer.auth.resendEmailButtonText',
                    'pioneer.auth.registerAgainButtonText'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.resendValidationEmail();
                } else {
                    this.registerAgain();
                }
            });
    }

    showResentTimesExceededToaster(): void {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'generic.whatsup',
                    'errors.40023',
                    'pioneer.auth.registerAgainButtonText'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.registerAgain();
                }
            });
    }

    resendValidationEmail(): void {
        this.sentEmailService.resendValidationEmail().subscribe(() => {
            this.modal
                .showModal(
                    this.viewContainerRef,
                    ModalManager.createConfiguration(
                        'generic.whatsup',
                        'pioneer.auth.resentVerificationEmailMessage'
                    )
                )
                .subscribe();
        });
    }

    registerAgain(): void {
        this.registerAuthService.isRegisteringAgain = true;
        this.router.navigate([routingConstants.registerPioneer]);
    }

    isExpand(): boolean {
        return (
            !this.showSideNav ||
            this.checkIfInfoPage() ||
            !this.showNavigation() ||
            this.isFullScreenMode()
        );
    }

    isExtend(): boolean {
        return (
            this.checkIfInfoPage() ||
            !this.showNavigation() ||
            this.isFullScreenMode()
        );
    }

    isNavigationDisplayed(): boolean {
        return (
            this.showNavigation() &&
            this.showSideNav &&
            !this.isFullScreenMode()
        );
    }

    private generateCustomIcons(): void {
        this.matIconRegistry.addSvgIcon(
            'add-objects-icon',
            this.domSanitzer.bypassSecurityTrustResourceUrl('../../../assets/icons/add-objects-icon.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'date-icon',
            this.domSanitzer.bypassSecurityTrustResourceUrl('../../../assets/icons/date-icon.svg')
        );
    }

    private initializeProjectList(): void {
        this.projectsListSubscription = this.projectDetailsService
            .getProjects()
            .subscribe();
    }

    private initializeGA(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((event) => {
                const url = event['url'];
                if (
                    url !== null &&
                    url !== undefined &&
                    url !== '' &&
                    url.indexOf('null') < 0
                ) {
                    googleAnalytics(url);
                }
            });
    }

    private initializeErrors(): void {
        this.errorService
            .getError()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((error) => {
                if (error === '40022') {
                    this.showExpiredEmailVerificationModal();
                } else if (error === '40023') {
                    this.showResentTimesExceededToaster();
                } else {
                    this.modal
                        .showModal(
                            this.viewContainerRef,
                            ModalManager.createConfiguration(
                                'errors.error',
                                error
                            )
                        )
                        .subscribe((res) => {
                            if (this.errorService.errorHandler) {
                                this.errorService.errorHandler();
                                this.errorService.errorHandler = null;
                            }
                            this.errorService.clientErrorCode = null;
                        });
                }
            });
    }
}
