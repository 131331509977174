<div class="important-space-between"
     mat-dialog-title>
    <h2>{{ 'pioneer.permitInvolvement.reviewsTitle' | translate }}</h2>
    <button class="ghost-button"
            (click)="closePopup()">
        <span class="material-icons">close</span>
    </button>
</div>
<mat-dialog-content>
    <ng-container *ngIf="permitReviews?.length; else noReviews">
        <ng-container *ngFor="let permitReview of permitReviews">
            <section class="user-entry">
                <h6>
                    {{ permitReview.userProfileName }}
                </h6>
                <h6 [style.color]="
                    getPermitLabelColor(permitReview.status.statusLabel)
                ">
                    {{
                    'pioneer.permitInvolvement.' +
                    permitReview.status.statusLabel | translate
                    }}
                </h6>
            </section>
        </ng-container>
    </ng-container>
    <ng-template #noReviews>
        <img class="no-reviews-image"
             src="../../../assets/images/public_no_neighbors_involved.png"
             alt="No reviews available">
    </ng-template>
</mat-dialog-content>