export const PERMIT_CONFIG = {
    container: 'mapViewer',
    copyright: '',
    layers: [
        {
            type: 'wmts',
            name: 'water',
            label: 'BRT Achtergrondkaart',
            url: 'https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0',
            opacity: '1.0',
            minResolution: '0.84',
            maxResolution: '3440.64',
            variableOpacity: false,
            visible: true,
            expanded: false,
            identify: false,
            snappable: true,
            tileOrigin: {
                x: '-285401.92',
                y: '903401.92',
            },
            tileFullExtent: {
                minX: '-285401.92',
                minY: '22598.80',
                maxX: '595401.92',
                maxY: '903401.92',
            },
            projection: 'EPSG:28992',
            publish: false,
            publishLabel: 'BRT Achtergrondkaart',
            publishDescription: '',
            publishSource: '',
            singleTile: false,
            wfsName: 'standaard',
            wfsUrl: 'https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0',
            wfsFeatureNamespace: '',
            wfsPropertyName: '',
            groupId: -1,
            groupName: '',
            groupExpanded: true,
            groupVisible: true,
        },
        {
            type: 'wmts',
            name: 'achtergrondvisualisatie',
            label: 'BGT Achtergrondkaart',
            url: 'https://service.pdok.nl/lv/bgt/wmts/v1_0',
            opacity: '0.8',
            minResolution: '0.013125',
            maxResolution: '0.84',
            wfsName: 'standaard',
            wfsUrl: 'https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0',
            wfsFeatureNamespace: '',
            wfsPropertyName: '',
            variableOpacity: false,
            visible: true,
            expanded: false,
            identify: false,
            wmsProtocolVersion: '1.3.0',
            wfsProtocolVersion: '1.0.0',
            snappable: true,
            tileOrigin: {
                x: '-285401.92',
                y: '903401.92',
            },
            tileFullExtent: {
                minX: '-285401.92',
                minY: '22598.80',
                maxX: '595401.92',
                maxY: '903401.92',
            },
            projection: 'EPSG:28992',
            publish: false,
            publishLabel: 'BGT Achtergrondkaart',
            publishDescription: '',
            publishSource: '',
            singleTile: false,
            groupId: -1,
            groupName: '',
            groupExpanded: true,
            groupVisible: true,
        },
        {
            type: 'wms',
            name: 'pand',
            label: 'Panden',
            url: 'https://service.pdok.nl/lv/bag/wms/v2_0?service=wms',
            opacity: '1.0',
            minResolution: '0.013125',
            maxResolution: '1.68',
            variableOpacity: false,
            visible: true,
            selectable: true,
            expanded: false,
            identify: false,
            wmsProtocolVersion: '1.1.0',
            wfsProtocolVersion: '1.1.0',
            snappable: true,
            tileOrigin: {
                x: '-285401.92',
                y: '903401.92',
            },
            tileFullExtent: {
                minX: '-285401.92',
                minY: '22598.80',
                maxX: '595401.92',
                maxY: '903401.92',
            },
            projection: 'EPSG:28992',
            publish: false,
            publishLabel: 'bgtachtergrond_1',
            publishDescription: '',
            publishSource: '',
            wfsName: 'pand',
            wfsUrl: 'https://service.pdok.nl/lv/bag/wfs/v2_0',
            wfsFeatureNamespace: 'http://bag.geonovum.nl',
            wfsPropertyName: 'Geometry',
            singleTile: false,
            groupId: 2,
            groupName: '',
            groupExpanded: true,
            groupVisible: true,
        },
    ],
    projection: 'EPSG:28992',
    projectionExtent: {
        minX: '-285401.92',
        minY: '22598.08',
        maxX: '595401.92',
        maxY: '903401.92',
    },
    mapExtent: {
        minX: '145832',
        minY: '460051',
        maxX: '167031',
        maxY: '468226',
    },
    layerPanelButtonVisible: false,
    layerPanelVisible: false,
    infoBarVisible: true,
    geoLocationButtonsVisible: true,
    selectableLayers: [],
    geoServerPrintUrl: '',
};
