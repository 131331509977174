import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    AuthService,
    CustomToasterComponent,
    CustomToasterService,
    MultilanguageService,
    routingConstants,
    UserVerificationEmailService,
} from '@furban/utilities';
import { CookieService } from 'ng2-cookies';

@Component({
    selector: 'furban-app-furban-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent implements OnInit {
    private emailId: string;

    constructor(
        private multilanguageService: MultilanguageService,
        private route: ActivatedRoute,
        private router: Router,
        private customToasterService: CustomToasterService,
        private userVerificationEmailService: UserVerificationEmailService,
        private authService: AuthService,
        private cookieService: CookieService
    ) { }

    ngOnInit(): void {
        this.logoutAndRemoveCookies();
        this.multilanguageService.setupLanguage();
        this.setup();
    }

    private logoutAndRemoveCookies() {
        this.authService.logout().subscribe((data) => {
            this.cookieService.deleteAll('/');
            localStorage.clear();
        });
    }

    private setup(): void {
        this.route.params.subscribe((params) => {
            this.emailId = params['sentEmailId'];
            this.userVerificationEmailService.sentUserVerificationEmailId =
                this.emailId;
            this.getUserVerificationEmail();
        });
    }

    private getUserVerificationEmail(): void {
        this.userVerificationEmailService
            .getUserVerificationEmailById(this.emailId)
            .subscribe(
                {
                    next: (data) => {
                        if (data.isActive) {
                            this.markEmailAsConfirmed();
                        } else {
                            this.router.navigate([routingConstants.admin]);
                            this.showToaster('auth.pioneerEmailConfirmed');
                        }
                    },
                    error: (err) => {
                        this.router.navigate([routingConstants.admin]);

                    }
                }
            );
    }

    private markEmailAsConfirmed(): void {
        this.userVerificationEmailService
            .markEmailAsConfirmedForUser(this.emailId)
            .subscribe(() => {
                this.router.navigate([routingConstants.admin]);
                this.showToaster('auth.prioneerConfirmation');
            });
    }

    private showToaster(label: string) {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            label,
            6000
        );
    }
}
