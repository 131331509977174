import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { FurbanUtil } from '../../helpers/furbanUtil';
import { TextureColorEnum } from '../../_enum/texture-color.enum';
import { DesignProposal } from '../../_models/design-proposal';
import { AuthService } from '../../_services/auth.service';
import { DesignProposalService } from '../../_services/design-proposals.service';
import { NotificationService } from '../../_services/notifications.service';
import { Comment } from '../../_models/comment';
import { NotificationTypeEnum } from '../../_enum/notifications-type.enum';
import { NotificationModel } from '../../_models/notification';
import { CommentNotification } from '../../_models/comment-notification';
import { KeyCode } from '../../_enum/key-code.enum';
import { CommentsService } from '../../comments/comments.service';
import { Router } from '@angular/router';
import { routingConstants } from '../../_constants/routing-constants';
import { Subscription } from 'rxjs';
import { PermitComment } from '../../_models/permit-comment';
import { PermitCommentsService } from '../../_services/permit-comments.service';
import { ControlsUtil } from '../../helpers/controlsUtil';

@Component({
    selector: 'furban-comment-form',
    templateUrl: './comment-form.component.html',
    styleUrls: ['./comment-form.component.scss'],
})
export class CommentFormComponent implements OnInit {
    @ViewChild('comment') commentRef: ElementRef;

    @Output() saveCommentEvent: EventEmitter<Comment | PermitComment> =
        new EventEmitter();
    @Output() toggleEmojiEvent: EventEmitter<boolean> = new EventEmitter();

    @Input() hidePinBtn?: boolean;
    @Input() designProposal?: DesignProposal;
    @Input() isPinComment: boolean;
    @Input() position?: string;
    @Input() rotation?: string;
    @Input() isDialog: boolean;

    @Input() permitProjectId?: string;

    public parentComment?: Comment;
    public commentForm: FormGroup;
    public emojiOpened = false;
    public emojiColor = TextureColorEnum.mildBlue;
    public highlightPin: boolean;
    private noWhiteSpacesValidator = FurbanUtil.noWhiteSpacesValidator;
    private htmlValidator = FurbanUtil.htmlContentValidator;
    private highlightPinSubscription: Subscription;
    public hasControlMaxLengthError = ControlsUtil.hasControlMaxLengthError;
    public hasControlIsHtmlError = ControlsUtil.hasControlIsHtmlError;
	public hasControlWhitespacesError = ControlsUtil.hasControlWhitespacesError;

    constructor(
        private formBuilder: FormBuilder,
        private dpService: DesignProposalService,
        private permitCommentsService: PermitCommentsService,
        private authService: AuthService,
        private notificationsService: NotificationService,
        private commentService: CommentsService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.commentForm = this.formBuilder.group({
            commentText: [
                '',
                [
                    Validators.maxLength(256),
                    this.htmlValidator,
                    this.noWhiteSpacesValidator,
                ],
            ],
        });

        if (this.hidePinBtn) {
            return;
        }
        this.subscribeToPinAdded();
    }

    ngOnDestroy(): void {
        if (this.hidePinBtn) {
            return;
        }

        this.highlightPinSubscription.unsubscribe();
    }

    public saveComment(): void {
        const commentText = this.commentForm.get('commentText').value;
        if (this.isInvalidForm()) {
            return;
        }
        if (this.designProposal) {
            this.saveDPComment(commentText);
        } else if (this.permitProjectId) {
            this.savePermitComment(commentText);
        }
    }

    public get hidePinOnCommentForm(): boolean {
        return this.router.url !== routingConstants.designs;
    }

    public onPinEventEmit(): void {
        this.commentService.addPinCommentEmitter.emit();
    }

    public onCommentKey(event: KeyboardEvent): void {
        if (
            event.key === KeyCode.arrowDown ||
            event.key === KeyCode.arrowUp ||
            event.key === KeyCode.arrowLeft ||
            event.key === KeyCode.arrowRight
        ) {
            event.stopImmediatePropagation();
        }
    }

    public toggleEmoji(): void {
        this.emojiOpened = !this.emojiOpened;
        this.toggleEmojiEvent.emit(this.emojiOpened);
    }

    public openEmoji(): void {
        this.toggleEmoji();
    }

    public handleClick($event: EmojiEvent): void {
        let curentText = this.commentForm.get('commentText').value;
        if (!curentText) {
            curentText = '';
        }
        const emojiText = curentText + $event.emoji.native;
        this.commentForm.get('commentText').setValue(emojiText);
        this.commentForm.get('commentText').markAsTouched();
        this.commentForm.get('commentText').markAsDirty();
    }

    public resetComment(comment: Comment | PermitComment): void {
        this.position = comment.position;
        this.rotation = comment.rotation;

        if (comment) {
            this.commentForm
                .get('commentText')
                .setValue(comment.commentText, { emitEvent: false });
        }
    }

    private saveDPComment(commentText: string): void {
        const comment = new Comment(
            this.designProposal.designProposalId,
            this.authService.userProfile,
            this.position,
            commentText
        );

        this.addingChildRelationToComment(comment);

        this.dpService.commentDesignProposal(comment).subscribe((data) => {
            if (!data) {
                return;
            }

            const notifications: NotificationModel[] =
                this.getAllNotifications(comment);
            this.sendCommentNotifications(notifications);

            this.resetCommentForm(data);
        });
    }

    private savePermitComment(commentText: string): void {
        const comment = new PermitComment(
            this.permitProjectId,
            this.authService.userProfile,
            this.position,
            this.rotation,
            commentText
        );
        comment.highlighted = false;
        this.addingChildRelationToComment(comment);

        this.permitCommentsService
            .savePermitComment(comment)
            .subscribe((data) => {
                if (!data) {
                    return;
                }
                this.resetCommentForm(data);
                this.permitCommentsService.pinCommentSavedEmitter.emit(data);
            });
    }

    private addingChildRelationToComment(
        comment: Comment | PermitComment
    ): void {
        if (!this.parentComment) {
            return;
        }

        const expectedString = `@${this.parentComment.userProfile.screenName}`;

        if (comment.commentText.indexOf(expectedString) !== -1) {
            comment.parentCommentId = this.parentComment.commentId;
            comment.commentText = comment.commentText
                .replace(expectedString, '')
                .trim();
        }
    }

    private isReplyComment(comment: Comment): boolean {
        return comment && comment.parentCommentId ? true : false;
    }

    private isInvalidForm(): boolean {
        return (
            !this.commentForm.valid ||
            this.commentForm.pristine ||
            this.checkInvalidCommentText()
        );
    }

    private checkInvalidCommentText(): boolean {
        const commentText = this.commentForm.get('commentText').value;
        return (
            !commentText ||
            commentText.length === 0 ||
            commentText.trim().length === 0
        );
    }

    private getNotificationMessage(isReply?: boolean): string {
        const commentNotification = new CommentNotification(
            this.authService.userProfile.screenName,
            this.designProposal.title,
            isReply
        );
        const notificationMessage = JSON.stringify({
            commentNotification,
        });
        return notificationMessage;
    }

    private getNotificationModel(
        userProfileId: string,
        screenName: string,
        isReply?: boolean
    ): NotificationModel {
        const notificationMessage = this.getNotificationMessage(isReply);
        const expiringDate = FurbanUtil.getCommentExpiringDate();

        return new NotificationModel(
            notificationMessage,
            expiringDate,
            userProfileId,
            screenName
        );
    }

    private getAllNotifications(comment: Comment): NotificationModel[] {
        const isReplyComment = this.isReplyComment(comment);
        const isCommentingOnSomebodyElseDp =
            this.designProposal?.userProfile &&
            this.designProposal.userProfile.userProfileId !==
                this.authService.userProfile.userProfileId;

        const notifications: NotificationModel[] = [];

        if (isCommentingOnSomebodyElseDp) {
            const commentNotification = this.getNotificationModel(
                this.designProposal.userProfile.userProfileId,
                this.designProposal.userProfile.screenName
            );
            notifications.push(commentNotification);
        }

        if (isReplyComment) {
            const commentNotification = this.getNotificationModel(
                this.parentComment.userProfile.userProfileId,
                this.parentComment.userProfile.screenName,
                isReplyComment
            );
            notifications.push(commentNotification);
        }

        return notifications;
    }

    private sendCommentNotifications(notifications: NotificationModel[]): void {
        this.notificationsService
            .postUserNotification(notifications, NotificationTypeEnum.user)
            .subscribe();
    }

    private subscribeToPinAdded(): void {
        this.highlightPinSubscription =
            this.commentService.highlightPinObservable.subscribe((data) => {
                this.highlightPin = data;
            });
    }

    private resetCommentForm(comment: Comment | PermitComment): void {
        this.parentComment = null;
        this.commentForm.reset();
        this.commentForm.get('commentText').setErrors(null);
        this.saveCommentEvent.emit(comment);
    }
}
