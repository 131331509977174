import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EmbedService, Project } from '@furban/utilities';
import { combineLatest } from 'rxjs';
import { DesignProposalsComponent } from '../project-shared/design-proposals/design-proposals.component';
import { ProjectDetailsService } from '../project-shared/project-details/project-details.service';

@Component({
    selector: 'furban-public-embeded-project',
    templateUrl: './public-embeded-project.component.html',
    styleUrls: ['./public-embeded-project.component.scss'],
})
export class PublicEmbededProjectComponent implements OnInit {
    @ViewChild('designProposals') designProposals: DesignProposalsComponent;

    public projectId: string;
    public project: Project;

    constructor(
        public viewContainerRef: ViewContainerRef,
        private projectDetailsService: ProjectDetailsService,
        private embedService: EmbedService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.subscribeToRoute();
        this.embedService.isEmbededComponentEmitter.emit(true);
        this.projectDetailsService
            .getPublicProject(this.projectId)
            .subscribe((data) => {
                this.project = data;
            });
    }

    public onScrollDown(): void {
        this.designProposals.onParentScrollDown();
    }

    private subscribeToRoute(): void {
        combineLatest([this.route.params, this.route.data]).subscribe(
            (data: any) => {
                this.projectId = data[0]['id'];
            }
        );
    }
}
