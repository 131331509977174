<ng-container *ngIf="!isUser()">
    <div class="container-btn-back">
        <mat-icon (click)="goBack()">arrow_back_ios</mat-icon>
        <h3 class="page-title">
            {{ 'user.dashboard.designProposals' | translate }}
        </h3>
    </div>
</ng-container>
<ng-container *ngIf="!isPublicPage">
    <div
        class="filter-and-sorting"
        [class.flex-row-end]="checkIfIsAdmin()"
        [class.flex-row-spaced]="!checkIfIsAdmin()"
        [class.is-viewer-only]="isViewerOnly"
    >
        <div class="flex-row-start">
            <mat-form-field [style.margin-right.px]="40" appearance="outline">
                <mat-label>{{
                    'dpFilter.filterTitlePrice' | translate
                }}</mat-label>
                <mat-select
                    multiple
                    disableOptionCentering
                    panelClass="filter-selection"
                    [formControl]="priceFilter"
                >
                    <mat-option
                        value="{{
                            designProposalPriceFilterEnum.onBudgetDesigns
                        }}"
                        >{{ 'dpFilter.onBudget' | translate }}
                    </mat-option>
                    <mat-option
                        value="{{
                            designProposalPriceFilterEnum.overBudgetDesign
                        }}"
                        >{{ 'dpFilter.overBudget' | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="hasFilterByDesignType()">
                <mat-form-field
                    appearance="outline"
                    [style.margin-right.px]="40"
                >
                    <mat-label>{{
                        'dpFilter.filterTitleDesignType' | translate
                    }}</mat-label>
                    <mat-select
                        multiple
                        disableOptionCentering
                        panelClass="filter-selection"
                        [formControl]="designTypeFilter"
                    >
                        <mat-option
                            value="{{
                                designProposalIsAdminFilterEnum.adminDesigns
                            }}"
                        >
                            {{
                                'dpFilter.municipalityDesigns' | translate
                            }}</mat-option
                        >
                        <mat-option
                            value="{{
                                designProposalIsAdminFilterEnum.citizenDesigns
                            }}"
                        >
                            {{
                                'dpFilter.citizenDesigns' | translate
                            }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!isCollaborativeProject && isUser()">
                <button
                    class="secondary-button"
                    [style.margin-bottom.px]="8"
                    [class.larger-button]="
                        checkIfVotingIsExpiredOrProjectArchived()
                    "
                    (click)="revote()"
                >
                    <h6>
                        {{
                            (checkIfVotingIsExpiredOrProjectArchived()
                                ? 'designProposals.showTop'
                                : 'generic.revote'
                            ) | translate
                        }}
                    </h6>
                </button>
            </ng-container>
        </div>
        <ng-container *ngIf="shouldDisplaySortingOptions()">
            <ul class="filter-panel padding-20-0">
                <li
                    *ngFor="let sortObject of availableSortingOptions"
                    [class.selected]="sortObject.tag === currentSorting"
                    (click)="setSorting(sortObject.tag)"
                >
                    {{ sortObject.title | translate }}
                </li>
            </ul>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="loaded && currentDPs">
    <ng-container *ngIf="isPublicPage">
        <furban-project-details-card [project]="project">
        </furban-project-details-card>
    </ng-container>
    <div class="padding-20-0 cards" [@listStagger]="currentDPs.length">
        <furban-design-proposal-card
            *ngFor="let dp of currentDPs; let i = index"
            [designProposal]="dp"
            [index]="i"
            [project]="project"
            [disabledActions]="disabledActions"
            [viewContainerRef]="viewContainerRef"
            [isCollaborativeProject]="isCollaborativeProject"
            [isEmbeded]="isEmbeded"
            [isPublicPage]="isPublicPage"
            (cardClicked)="openDPDetails(dp)"
            (commentDialogOpened)="openCommentDialog($event)"
            (winnerPopupOpened)="openWinnerPopup($event)"
            (userDeleted)="initialiseDesignProposals()"
        >
        </furban-design-proposal-card>
    </div>
</ng-container>

<ng-container *ngIf="showNoDesigns()">
    <div class="flex-column-center padding-20">
        <img
            src="../../../assets/images/no_design.svg"
            [style.height.px]="300"
        />
        <h4 class="placeholder-text">
            {{ 'user.dashboard.noDesignsMessage' | translate }}
        </h4>
    </div>
</ng-container>
