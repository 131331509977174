import { ViewPortDimension } from "./permit-viewport-dimension";

export class ViewPort {
    public originalViewport: ViewPortDimension[];
    public splitViewport: ViewPortDimension[];

    constructor(originalViewport: ViewPortDimension[], splitViewport: ViewPortDimension[]) {
        this.originalViewport = originalViewport;
        this.splitViewport = splitViewport;
    }


}