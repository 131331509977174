import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ControlsUtil, ExportFilesUtil } from '@furban/utilities';
import { CodesService } from '../../shared/_services/codes.service';
import { NeighborPermitReviews } from '../_models/neighbor-permit-reviews';
import { NeighbourService } from '../_services/neighbour.service';
import { PermitReviewService } from '../_services/permit-review.service';
@Component({
    selector: 'furban-codes-status',
    templateUrl: './codes-status.component.html',
    styleUrls: ['./codes-status.component.scss'],
})
export class CodesStatusComponent implements OnInit {
    public permitReviews: NeighborPermitReviews;
    public noOfCodesFormControl = new FormControl('', [
        Validators.required,
        Validators.max(20),
        Validators.min(1),
    ]);

    private isUserListUpdated = false;
    private groupId: string;
    private projectId: string;

    public hasControlError = ControlsUtil.hasControlError;

    public checkIfExceedsLimit(): boolean {
        return (
            parseInt(this.noOfCodesFormControl.value) > 20 - this.permitReviews.plainNeighborReviews?.length
        );
    }

    public get isGenerateButtonDisabled(): boolean {
        return !this.permitReviews
            ? false
            : this.checkIfExceedsLimit() || this.noOfCodesFormControl.invalid;
    }

    public get isExportButtonDisabled(): boolean {
        if (!this.permitReviews) {
            return true;
        }
        return (
            !this.permitReviews.plainNeighborReviews?.length &&
            !this.permitReviews.neighborWithEmailReviews?.length
        );
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<CodesStatusComponent>,
        private permitReviewService: PermitReviewService,
        private neighbourService: NeighbourService,
        private codesService: CodesService
    ) {
        this.groupId = data.groupId;
        this.projectId = data.projectId;
    }

    ngOnInit(): void {
        this.getPermitReviews();
    }

    public closePopup(): void {
        this.dialogRef.close(this.isUserListUpdated);
    }

    public generateNeighborCodes(): void {
        if (
            this.noOfCodesFormControl.invalid ||
            this.isGenerateButtonDisabled
        ) {
            return;
        }

        const countNeighbors = this.noOfCodesFormControl.value;

        this.neighbourService
            .generateNeighborCodes(parseInt(countNeighbors), this.groupId, this.projectId)
            .subscribe((data) => {
                this.isUserListUpdated = true;
                this.permitReviews.plainNeighborReviews =
                    this.permitReviews.plainNeighborReviews.concat(...data);
            });
    }

    public exportCodes(): void {
        this.codesService
            .exportNeighborsCodes(this.groupId)
            .subscribe((data) => {
                ExportFilesUtil.createCSV(data, 'neighbourExportCsv_codes_');
            });
    }

    public generateByEmail(emails: string[]): void {
        this.neighbourService
            .inviteByEmail(emails, this.groupId, this.projectId)
            .subscribe((data) => {
                this.isUserListUpdated = true;
                this.permitReviews.neighborWithEmailReviews =
                    this.permitReviews.neighborWithEmailReviews.concat(...data);
            });
    }

    private getPermitReviews(): void {
        this.permitReviewService
            .getNeighboursPermitReviews(this.projectId)
            .subscribe((data) => {
                this.permitReviews = data;
            });
    }
}
