import {
    AfterContentChecked,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Data, Params } from '@angular/router';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
    AuthService,
    CategoryObject,
    CustomToasterComponent,
    CustomToasterService,
    DesignProposal,
    DesignProposalService,
    DesignProposalStatus,
    DesignProposalStatusEnum, Emitted3DObject, FurbanUtil, MapService, MenuService, ObjectTypeEnum, PathObject, PathObjectsService,
    Project, Project3dModel, ShepherdTourService, SocketActionsEnum, SocketService, StepperService, SvgImageEnum, ThreeStateEnum, ThreeStore, ThreeUtils,
    Tool, ToolingActionsEnum, ToolingVisibility, ToolNameEnum, UserProfile
} from '@furban/utilities';
import { UserProfileService } from '../../municipality/user/user-profile/user-profile.service';
import { GenerateCollaborativeCodesComponent } from '../generate-collaborative-codes/generate-collaborative-codes.component';
import { CollaborativeDesignService } from './collaborative-design.service';
import { ProjectDetailsService } from '../project-details/project-details.service';
import { Store } from '@ngrx/store';
import { NgrxThreeLiveCollaborationComponent } from '../../shared/ngrx-three-live-collaboration/ngrx-three-live-collaboration.component';
import { ProjectMenuThreeDComponent } from '../user-project/project-menu-three-d/project-menu-three-d.component';
import { Vector2 } from 'three';
import { ToolingService } from '../../shared/tooling/tooling.service';
import { TermsAndConditionsComponent } from '../../shared/terms-and-conditions/terms.component';
import { Location } from '@angular/common';
import { NotificationToasterComponent } from '../../shared/notification-toaster/notification-toaster.component';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
    selector: 'furban-collaborative-design',
    templateUrl: './collaborative-design.component.html',
    styleUrls: ['./collaborative-design.component.scss'],
})
export class CollaborativeDesignComponent
    implements OnInit, AfterContentChecked {
    @ViewChild('threeJsLiveComponent')
    threeJsEditorComponent: NgrxThreeLiveCollaborationComponent;
    @ViewChild('sideMenuUserProj') sideMenu: ProjectMenuThreeDComponent;

    public hasUsers: boolean;
    public userListDisplayed = false;
    public projId: string;
    public dpId: string;
    public project: Project;
    public objects: CategoryObject[];
    public userObjects: PathObject[] = [];
    public availableBudget = 0;
    public is3dReady = false;
    public defaultObjects: PathObject[] = [];
    public freeShapeDrawing = false;
    public currentlyEnabledTool: Tool;
    public toolingVisibility: ToolingVisibility;

    public isRedoEnabled = false;
    public isUndoEnabled = false;
    public threeState: string;
    public isEditMode = true;
    public showNotStartedOverlay: boolean;
    public isIOS = FurbanUtil.isIOS();
    public activeConnectionsCount: number;
    public designProposal: DesignProposal;
    public activeButton: ToolingActionsEnum;
    public shouldShowUploadMenu: boolean;
    public shouldDisplayFreeshapeMenu: boolean;
    public selectedGroundMaterial: Project3dModel;
    public selectedGroundFreeShape = '';
    public generateShapesAssetsURL = FurbanUtil.generateShapesAssetsURL;

    public shapes = [
        ObjectTypeEnum.freeshape,
        ObjectTypeEnum.square,
        ObjectTypeEnum.elipse,
    ];

    private routeSub: Subscription;
    private sub: Subscription;
    private storeSubscription: Subscription;
    private shouldCheckForTour = false;
    private actionFinishedSubscription: Subscription;
    private buttonSubcription: Subscription;

    constructor(
        private authService: AuthService,
        private translateService: TranslateService,
        private dialog: MatDialog,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private designProposalService: DesignProposalService,
        private collaborativeDesignService: CollaborativeDesignService,
        private userProfileService: UserProfileService,
        private stepperService: StepperService,
        private projectDetailsService: ProjectDetailsService,
        private mapService: MapService,
        private menuService: MenuService,
        private pathObjectsService: PathObjectsService,
        private customToasterService: CustomToasterService,
        private store: Store<{ store: ThreeStore }>,
        private toolingService: ToolingService,
        private socketService: SocketService,
        private shepherdTourService: ShepherdTourService,
        private changeDetector: ChangeDetectorRef,
        private location: Location
    ) { }

    ngOnInit(): void {
        if (this.authService.hasAdministrativeRole()) {
            this.loadDataForAdmins();
        } else {
            this.loadDataForCollaborator();
        }
        this.subscribeToStore();
        this.checkForTOS();
        this.subscribeToButtonClickedEvent();
        this.subscribeToActionsFinished();
    }

    ngOnDestroy() {
        this.routeSub?.unsubscribe();
        this.sub?.unsubscribe();
        this.storeSubscription?.unsubscribe();
        this.actionFinishedSubscription.unsubscribe();
        this.buttonSubcription.unsubscribe();
    }

    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
    }

    public get toolingActions(): typeof ToolingActionsEnum {
        return ToolingActionsEnum;
    }

    public get threeStateEnum(): typeof ThreeStateEnum {
        return ThreeStateEnum;
    }

    public get svgImageType(): typeof SvgImageEnum {
        return SvgImageEnum;
    }

    public get totalNumberOfUsers(): number {
        return this.collaborativeDesignService.contributors.length;
    }

    public get sessionFinished(): boolean {
        return this.designProposal.designProposalStatus.designProposalStatusId === DesignProposalStatusEnum.finished;
    }

    public get designProposalStatus(): typeof DesignProposalStatusEnum {
        return DesignProposalStatusEnum;
    }

    public getPolygon() {
        this.mapService.getPolygon(this.projId).subscribe();
    }

    public setupProjectDetails(): void {
        const requests = [
            this.projectDetailsService.getProject(this.projId),
            this.menuService.getObjectsOrdered(this.projId),
        ];

        forkJoin(requests).subscribe((data: any) => {
            this.setProject(data[0]);
            this.objects = data[1];
            this.is3dReady = true;
            this.setDefaultGroundMaterial();
            if (!this.authService.isCollaborator()) {
                return;
            }

            const customObject = this.objects.find(
                (category) => category.category.categoryId === 9
            ).models[0];
            this.shepherdTourService.setCustomObjectForTour(customObject);
        });
    }

    private subscribeToStore(): void {
        this.storeSubscription = this.store.pipe().subscribe((data) => {
            this.isRedoEnabled = data.store.future?.length > 0;
            this.isUndoEnabled = data.store.previous?.length > 0;
        });
    }

    public checkIfReady() {
        return this.project && this.objects && this.userObjects;
    }

    public toggleViewContributors(): void {
        if(!this.userListDisplayed){
            this.getCollaboratorsProfiles();
        }
        this.userListDisplayed = !this.userListDisplayed;
    }

    public addContributors(): void {
        const addingCodesDialog = this.dialog.open(
            GenerateCollaborativeCodesComponent,
            {
                width: '600px',
            }
        );

        addingCodesDialog.disableClose = true;
        addingCodesDialog.componentInstance.parentRef = this.viewContainerRef;
        addingCodesDialog.componentInstance.groupId =
            this.designProposal.groupId;
        addingCodesDialog.componentInstance.isCollaborativeUser = true;

        addingCodesDialog.componentInstance.onModalClose.subscribe((data) => {
            if (data !== 'closeModal') {
                this.getCollaboratorsProfiles();
            }
        });
    }

    public onScreenshotTaken(): void {
        this.threeState = ThreeStateEnum.default;
    }

    public onProjectPriceChange(price: number): void {
        this.availableBudget = price;
    }

    public onToolDisable(): void {
        this.currentlyEnabledTool = null;
    }

    public onFinishedDrawing(): void {
        this.freeShapeDrawing = false;
    }

    public toggleEdit(isEditing: boolean): void {
        this.isEditMode = isEditing;
    }

    public getCollaboratorsProfiles(): void {
        this.userProfileService
            .getAllCollaboratorUserProfies(this.designProposal.groupId)
            .subscribe((data) => {
                this.setUserList(data);
            });
    }

    public setProject(project: Project): void {
        this.project = project;
        this.setupThreeState(this.project.ended);
        if (this.project.price) {
            this.availableBudget = this.project.price;
        }
        if (!this.shouldCheckForTour) {
            return;
        }
        this.checkTourForCollaborator();
    }

    public getUserPathObjects(): void {
        this.pathObjectsService
            .getPathObjects(
                this.projId,
                this.authService.userProfile.userProfileId
            )
            .subscribe((data) => {
                this.addSpecificPathObjects(data);
            });
    }

    public onUpdateDesignStatus(status: DesignProposalStatusEnum): void {
        this.designProposal.designProposalStatus = new DesignProposalStatus(
            status
        );
        this.showNotStartedOverlay =
            status === DesignProposalStatusEnum.created;

        if (status === DesignProposalStatusEnum.finished) {
            this.threeJsEditorComponent.onToggleEditChange(false);
        }
    }

    public onConnectionCountUpdate(count: number): void {
        this.activeConnectionsCount = count > 1 ? count - 1 : 0;
    }

    public onObject3DAdd(emittedObject: Emitted3DObject) {
        this.onMultipleSelectionToolStateChange(false);
        this.setupDefaultPropertiesToGroundObjects(
            emittedObject.project3DModel
        );

        if (
            emittedObject.project3DModel.objectType === ObjectTypeEnum.freeshape
        ) {
            return;
        }

        this.addRegularObjectBasedOnPriceRestriction(emittedObject);
    }

    public addRegularObjectBasedOnPriceRestriction(
        emittedObject: Emitted3DObject
    ) {
        if (!this.authService.isAdminDesignRouteOrNotAdministrativeRole()) {
            this.threeJsEditorComponent.add3DObject(emittedObject);
        } else {
            this.checkIfBudgetExceededAndAddObject(emittedObject);
        }
    }

    public checkIfBudgetExceededAndAddObject(emittedObject: Emitted3DObject) {
        if (
            this.isBudgetExceded(emittedObject.project3DModel.price) &&
            this.project.isPriceRestrictionEnabled
        ) {
            this.showBudgetExcededToaster();
        } else {
            this.availableBudget =
                this.availableBudget - emittedObject.project3DModel.price;
            this.threeJsEditorComponent.add3DObject(emittedObject);
        }
    }

    public isBudgetExceded(addedObjectPrice: number): boolean {
        return this.project.price && this.availableBudget < addedObjectPrice;
    }

    public showBudgetExcededToaster(): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'info',
            'info',
            this.project.isPriceRestrictionEnabled
                ? 'price.excededWhenEnabledRestriction'
                : 'price.exceded',
            2000
        );
    }

    public setupDefaultPropertiesToGroundObjects(
        project3DModel: Project3dModel
    ) {
        if (project3DModel.objectType === ObjectTypeEnum.freeshape) {
            this.threeJsEditorComponent.drawFreeshape(
                ObjectTypeEnum.freeshape,
                project3DModel
            );
            this.freeShapeDrawing = true;
            return;
        } else if (project3DModel.objectType === ObjectTypeEnum.square) {
            project3DModel.freeshapePoints = JSON.stringify(new Vector2(3, 3));
        } else if (project3DModel.objectType === ObjectTypeEnum.elipse) {
            project3DModel.freeshapePoints = JSON.stringify(new Vector2(2, 2));
        } else if (project3DModel.objectType === ObjectTypeEnum.custom) {
            project3DModel.freeshapePoints = JSON.stringify(
                ThreeUtils.customHouseDefaultDim
            );
        }
    }

    public onFinishUploadCustomObject() {
        this.threeJsEditorComponent.finishUploadCustomObject();
    }

    public onMultiple3DObjectAdd(object: Emitted3DObject) {
        if (object) {
            this.threeJsEditorComponent.addOnClickObject =
                object.project3DModel;
        } else {
            this.menuService.objectToAddOnClick = null;
            this.threeJsEditorComponent.addOnClickObject = null;
        }
    }

    public removeLastUnfinishedFreeShape() {
        this.threeJsEditorComponent.clearLastDrawing();
        this.threeJsEditorComponent.addEventsOnCanvas();
        this.freeShapeDrawing = false;
    }

    public modify(event) {
        this.stepperService.changeProjectModifiedState(event);
    }

    public onToolChange(tool: Tool): void {
        if (tool.toolName === ToolNameEnum.multipleSelection) {
            this.onMultipleSelectionToolStateChange(tool.enabled);
        }
        this.currentlyEnabledTool = tool;
    }

    public onMultipleSelectionToolStateChange(enabled: boolean): void {
        if (enabled) {
            this.threeJsEditorComponent.enableMultiSelect();
        } else {
            this.threeJsEditorComponent.removeControlsFromObject(true);
        }
    }

    public callToolingAction(toolingAction: ToolingActionsEnum) {
        this.toolingService.callToolingAction(toolingAction);
    }

    private addSpecificPathObjects(data: PathObject[]) {
        if (!data) {
            return;
        }

        if (this.userObjects && this.userObjects.length > 0) {
            this.userObjects = this.userObjects.concat(data);
        } else {
            this.userObjects = data;
        }
        this.calculateAvailableBudget(data);
        this.is3dReady = true;
    }

    public getDefaultDPPathObjects() {
        this.pathObjectsService
            .getPathObjectsForDefaultDP(this.projId, this.dpId)
            .subscribe((data) => {
                this.addSpecificPathObjects(data);
            });
    }

    public calculateAvailableBudget(data: PathObject[]) {
        for (const object of data) {
            const furbanObject = this.menuService.getClientObject(object.objId);
            if (furbanObject) {
                this.availableBudget =
                    this.availableBudget - furbanObject.price;
            } else {
                console.error(
                    'This object could not be found to calculate the price: ',
                    object
                );
            }
        }
    }

    public hideContributors(): void {
        this.userListDisplayed = false;
    }

    public isAdmin() {
        return this.authService.hasAdministrativeRole();
    }

    public setupThreeState(isEnded: boolean) {
        if (isEnded) {
            this.threeState = ThreeStateEnum.view;
        } else {
            this.threeState = ThreeStateEnum.default;
        }
    }

    public goBack(): void {
        this.location.back();
    }

    public updateLiveSessionStatus(status: DesignProposalStatusEnum): void {
        if (status === DesignProposalStatusEnum.started) {
            this.warmUpLambdaFunctions();
        }

        if (status === DesignProposalStatusEnum.finished) {
            this.threeJsEditorComponent.takeDefaultScreenshotLiveCollaboration();
            this.threeJsEditorComponent.onToggleEditChange(false);
        }

        this.designProposalService
            .changeLiveSessionStatus(this.designProposal, status)
            .subscribe((data) => {
                this.designProposal = data;
                this.emitSessionStatusUpdate(status);
            });
    }

    public isFinishButtonDisabled(): boolean {
        return (
            this.designProposal?.designProposalStatus
                ?.designProposalStatusId === DesignProposalStatusEnum.finished
        );
    }

    public isStartButtonVisible(): boolean {
        return (
            this.designProposal?.designProposalStatus
                ?.designProposalStatusId === DesignProposalStatusEnum.created
        );
    }

    public onHighlightDropingArea(shouldHighlight: boolean): void {
        this.threeJsEditorComponent.changeCanvasColor(shouldHighlight);
    }

    public checkActiveButton(buttonType: ToolingActionsEnum): boolean {
        return this.activeButton === buttonType;
    }

    public onRegularObjectSelection(selectedObject: Project3dModel): void {
        this.currentlyEnabledTool = null;
        if (this.freeShapeDrawing) {
            this.removeLastUnfinishedFreeShape();
        }
        this.emitLastAddedObject(selectedObject, false);
    }

    public getDragDelay() {
        return FurbanUtil.isMobile() ? 70 : 0;
    }

    public dropShape(event: CdkDragDrop<string[]>): void {
        this.onHighlightDropingArea(false);
        this.currentlyEnabledTool = null;

        if (this.isDropEventInsideMenu(event)) {
            return;
        }

        if (this.freeShapeDrawing) {
            this.removeLastUnfinishedFreeShape();
        }
        const shapeType = event.item.data;

        const objectToAdd = this.selectedGroundMaterial;
        objectToAdd.objectType = shapeType;
        this.selectedGroundFreeShape = shapeType;
        this.emitLastAddedObject(objectToAdd, true);
    }

    public isDropEventInsideMenu(event: CdkDragDrop<string[]>): boolean {
        const offsetLeft = event.item.element.nativeElement.offsetLeft;
        return event.distance.x + offsetLeft < 300;
    }

    public onShapeObjectSelection(selectedType: string): void {
        this.currentlyEnabledTool = null;
        const selectedShapeModel = this.selectedGroundMaterial;
        selectedShapeModel.objectType = selectedType;
        this.removeLastUnfinishedFreeShape();
        this.selectedGroundFreeShape = selectedType;
        this.onRegularObjectSelection(selectedShapeModel);
    }

    public onButtonClick(buttonType: ToolingActionsEnum): void {
        this.resetActions();
        if (this.activeButton === buttonType) {
            this.activeButton = null;
            const tool = new Tool(buttonType, false);
            this.currentlyEnabledTool = tool;
            this.checkAction(tool);
            this.menuService.shouldShowMenu = false;
            return;
        }

        const tool = new Tool(buttonType, true);
        this.currentlyEnabledTool = tool;
        this.checkAction(tool);
        this.activeButton = buttonType;
    }

    public checkAction(tool: Tool): void {
        this.resetActions();
        switch (tool.toolName) {
            case this.toolingActions.addObjects: {
                this.toggleObjectsMenu(tool);
                break;
            }
            case this.toolingActions.generateObjects: {
                this.onGenerateGreenObjectsClick(tool);
                break;
            }
            case this.toolingActions.objectsInLine: {
                this.onObjectsInLineClick(tool);
                break;
            }
            case this.toolingActions.multiselect: {
                this.onMultipleSelectionStateChange(tool);
                break;
            }
            case this.toolingActions.upload: {
                this.toggleUpload(tool);
                break;
            }
            case this.toolingActions.freeshape: {
                this.toggleFreeShape(tool);
                break;
            }
            default: {
                break;
            }
        }
    }

    private setDefaultGroundMaterial() {
        this.selectedGroundMaterial = this.objects.find(
            (category) =>
                category.category.categoryName ===
                'user.projectMenu.groundMaterials'
        ).models[0];
    }

    private emitLastAddedObject(
        addedObject: Project3dModel,
        isDragged: boolean
    ) {
        const emitted3DObject: Emitted3DObject = new Emitted3DObject(
            addedObject,
            isDragged
        );
        this.onObject3DAdd(emitted3DObject);
    }

    private subscribeToButtonClickedEvent(): void {
        this.buttonSubcription = this.menuService.buttonClickedObservable$.subscribe((data) => {
            this.checkAction(data);
        });
    }

    private subscribeToActionsFinished(): void {
        this.actionFinishedSubscription = this.menuService.actionFinishedObservable$.subscribe((data) => {
            this.activeButton = null;
        });
    }

    private onRowOfObjectsAdd(): void {
        this.threeJsEditorComponent.drawFreeshape(ObjectTypeEnum.row);
        this.freeShapeDrawing = true;
    }

    private onObjectsInLineClick(tool: Tool): void {
        if (tool.enabled) {
            this.onRowOfObjectsAdd();
            return;
        }

        this.removeLastUnfinishedFreeShape();
    }

    private onMultipleSelectionStateChange(tool: Tool): void {
        if (tool.enabled) {
            this.onMultipleSelectionToolStateChange(tool.enabled);
            this.open3DNotificationSnackbar(
                this.authService,
                this.translateService,
                'userSettings.dragMessage'
            );
            return;
        }

        this.removeLastUnfinishedFreeShape();
    }

    public onAddGreenObjects() {
        this.threeJsEditorComponent.drawFreeshape(ObjectTypeEnum.multiple);
        this.freeShapeDrawing = true;
    }

    public onGenerateGreenObjectsClick(tool: Tool): void {
        if (tool.enabled) {
            this.onAddGreenObjects();
            return;
        }
        this.removeLastUnfinishedFreeShape();
    }

    private toggleUpload(tool: Tool): void {
        this.shouldShowUploadMenu = tool.enabled;
    }

    private toggleFreeShape(tool: Tool): void {
        this.shouldDisplayFreeshapeMenu = tool.enabled;
    }

    private toggleObjectsMenu(tool: Tool): void {
        if (!tool.enabled) {
            return;
        }
        this.menuService.shouldShowMenu = true;
        this.sideMenu.activeItem = this.objects[1];
    }

    private resetActions(): void {
        this.removeLastUnfinishedFreeShape();
        this.shouldShowUploadMenu = false;
        this.shouldDisplayFreeshapeMenu = false;
        this.menuService.shouldShowMenu = false;
    }

    private open3DNotificationSnackbar(
        authService: AuthService,
        translateService: TranslateService,
        message: string
    ): void {
        if (authService.getUserSettings().show3DNotifications) {
            this.customToasterService.openCustomToaster(
                NotificationToasterComponent,
                '3d_rotation',
                'notification',
                translateService.instant(message),
                0
            );
        }
    }

    private warmUpLambdaFunctions(): void {
        this.socketService.socket.send(
            `{"action": "${SocketActionsEnum.roomEnter}", "data": "warm-up"}`
        );
        this.socketService.socket.send(
            `{"action": "${SocketActionsEnum.roomExit}", "data": "warm-up"}`
        );
        this.socketService.socket.send(
            `{"action": "${SocketActionsEnum.objectsAdd}", "data": "warm-up"}`
        );
        this.socketService.socket.send(
            `{"action": "${SocketActionsEnum.objectsDelete}", "data": "warm-up"}`
        );
        this.socketService.socket.send(
            `{"action": "${SocketActionsEnum.objectsEdit}", "data": "warm-up"}`
        );
        this.socketService.socket.send(
            `{"action": "${SocketActionsEnum.objectsFreeze}", "data": "warm-up"}`
        );
        this.socketService.socket.send(
            `{"action": "${SocketActionsEnum.objectsUnfreeze}", "data": "warm-up"}`
        );
    }

    private checkForTOS(): void {
        if (!this.authService.isCollaborator()) {
            return;
        }

        if (!this.authService.userProfile.acceptedTOS) {
            this.openTermsModal();
        } else {
            this.shouldCheckForTour = true;
        }
    }

    private openTermsModal(): void {
        const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
            width: '60%',
        });
        dialogRef.disableClose = true;
        dialogRef.componentInstance.parentRef = this.viewContainerRef;

        dialogRef.afterClosed().subscribe((result) => {
            if (result && this.authService.isCollaborator()) {
                localStorage.setItem('has-collaborator-tour', 'false');
                this.checkTourForCollaborator();
            }
        });
    }

    private loadDataFromServer(): void {
        this.getPolygon();
        this.setupProjectDetails();
        this.subscribeToToolingVisibility();
    }

    private loadDataForAdmins(): void {
        this.projId = this.stepperService.projectId;
        if (!this.projId) {
            this.routeSub = this.route.params.subscribe((params) => {
                this.projId = params['id'];
            });
        }

        this.sub = combineLatest([
            this.route.params,
            this.route.data,
        ]).subscribe((data: [Params, Data]) => {
            if (!data) {
                return;
            }
            this.projId = data[0]['id'];
            this.dpId = data[0]['dpId'];
            this.getDesignProposalForAdmin();
            this.loadDataFromServer();
        });
    }

    private loadDataForCollaborator(): void {
        this.designProposalService
            .getCollaborativeDesignProposal()
            .subscribe((data) => {
                this.designProposal = data as DesignProposal;
                this.projId = this.designProposal.projectId;
                this.dpId = data.designProposalId;
                this.loadDataFromServer();
                this.loadActiveCollaborators();
                this.showNotStartedOverlay =
                    this.designProposal.designProposalStatus
                        ?.designProposalStatusId ===
                    DesignProposalStatusEnum.created;
            });
    }

    private loadActiveCollaborators(): void {
        this.userProfileService
            .getActiveCollaboratorUserProfies(this.designProposal.groupId)
            .subscribe((data) => {
                this.setUserList(data);
            });
    }

    private subscribeToToolingVisibility(): void {
        this.toolingService.toolingVisibilityObservable.subscribe((data) => {
            this.toolingVisibility = data;
        });
    }

    private setUserList(data: UserProfile[]): void {
        if (!data) {
            this.hasUsers = false;
            return;
        }
        this.hasUsers = data.length > 0;
        this.collaborativeDesignService.contributors = data;
    }

    private getDesignProposalForAdmin(): void {
        this.designProposalService
            .getDetails(this.dpId)
            .pipe(
                switchMap((data) => {
                    this.designProposal = data as DesignProposal;
                    return this.userProfileService.getAllCollaboratorUserProfies(
                        this.designProposal.groupId
                    );
                })
            )
            .subscribe((data) => {
                this.setUserList(data);
            });
    }

    private checkTourForCollaborator(): void {
        const hasTour = localStorage.getItem('has-collaborator-tour');
        if (
            this.authService.isCollaborator() &&
            hasTour &&
            hasTour === 'false'
        ) {
            localStorage.setItem('has-collaborator-tour', 'false');
            this.shepherdTourService.startCollaboratorTour(this.project);
        }
    }

    private emitSessionStatusUpdate(status: DesignProposalStatusEnum): void {
        const roomAndMessage = { roomId: this.dpId, msg: status };
        const dataStringify = JSON.stringify(roomAndMessage);
        this.threeJsEditorComponent.sendDataToSocket(
            `{"action": "${SocketActionsEnum.sessionStatusUpdate}", "data":${dataStringify}}`
        );
    }
}
