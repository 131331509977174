<section class="main-container padding-40-32">
    <div class="flex-row-spaced">
        <h3 class="page-title" [style.margin-bottom.px]="32">
            {{ 'suppliers.title' | translate }}
        </h3>
        <mat-radio-group
            [value]="selectedView"
            (change)="onRadioChange($event)"
        >
            <mat-radio-button [style.margin-right.px]="12" value="allSuppliers">
                {{ 'suppliers.allSuppliers' | translate }}
            </mat-radio-button>
            <mat-radio-button value="clientSuppliers">
                {{ 'suppliers.clientSuppliers' | translate }}
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <ng-container *ngIf="showAllSuppliersView()">
        <div class="flex-row-end" [style.margin-bottom.px]="32">
            <button class="primary-button" (click)="openAddModal()">
                <h6>{{ 'suppliers.addSupplier' | translate }}</h6>
            </button>
        </div>

        <mat-accordion>
            <mat-expansion-panel
                *ngFor="let supplier of suppliers"
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
                (afterExpand)="setObjectsForSupplier(supplier)"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>{{ supplier.supplierName }}</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <section class="objects">
                    <div
                        *ngFor="let object of supplier.objects"
                        class="object-container pointer flex-column-spaced"
                    >

                        <span class="material-icons edit-safety-area"
                            matTooltip="{{ 'superadmin.safetyArea.title' | translate }}" (click)="openSafetyAreaModal(object)">
                            edit
                        </span>
                        <img
                            class="full-width"
                            alt="objectPicture"
                            [src]="composeImageString(object.objectLookId)"
                            [style.margin.px]="12"
                            
                        />
                        <div class="flex-column-center text-container">
                            <h5>
                                {{ object.translationsLabel | translate }}
                            </h5>
                        </div>
                    </div>
                </section>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>

    <ng-container *ngIf="showClientSupplierView()">
        <form [formGroup]="clientSelectFormGroup">
            <furban-dropdown-autocomplete
                [municipalities]="municipalities"
                [formGroup]="clientSelectFormGroup"
                (clientSelect)="onSelectClient($event)"
            >
            </furban-dropdown-autocomplete>
        </form>

        <ng-container *ngIf="clientSuppliers && mappedSuppliers">
            <div
                *ngFor="let mapSupplier of mappedSuppliers | keyvalue"
                class="list-element flex-row-spaced"
            >
                <mat-checkbox
                    [checked]="mapSupplier.value"
                    [disabled]="checkIfDisabledSupplier(mapSupplier)"
                    [style.margin-top.px]="8"
                    class="example-margin"
                    (change)="onCheckBoxChange($event, mapSupplier)"
                    >{{ mapSupplier.key.supplierName }}
                </mat-checkbox>
            </div>
        </ng-container>
    </ng-container>
</section>
