import { Vector3 } from 'three/';

export class MiddleVector {
    public index: number;
    public vectors: Vector3[];
    public distance: number;
    public middle: Vector3;

    constructor(
        index: number,
        vectors: Vector3[],
        distance: number,
        middle: Vector3
    ) {
        this.index = index;
        this.vectors = vectors;
        this.distance = distance;
        this.middle = middle;
    }
}
