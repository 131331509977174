import * as THREE from 'three';

export class Loaded3DObject {
    public scene: THREE.Group;
    public animation: THREE.AnimationClip;

    constructor(scene: THREE.Group, animation: THREE.AnimationClip) {
        this.scene = scene;
        this.animation = animation;
    }
}
