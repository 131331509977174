import { ThreeState } from './three-state';
import { createSelector } from '@ngrx/store';

export interface ThreeStore {
    actual: ThreeState;
    previous: ThreeState[];
    future: ThreeState[];
}

export const currentStore: ThreeStore = {
    actual: {
        defaultObjects: [],
        userObjects: [],
        objectsToRemove: [],
        objectsToAdd: [],
    },
    previous: [],
    future: [],
};

export const selectActualState = (state: ThreeStore) => state.actual;

export const defaultObjectsSelector = createSelector(
    selectActualState,
    (state: ThreeState) => state.defaultObjects
);

export const userObjectsSelector = createSelector(
    selectActualState,
    (state: ThreeState) => state.userObjects
);
