import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, catchError, map } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import {
    addAssetObjectRequest, addMultipleAssetObjectsRequest, deleteAssetRequest, deleteMultipleAssetsRequest, getHouseAssets, getHouseInformationsRequest, redoRequestPermit,
    resetHouse,
    resetHouseRequest,
    undoRequestPermit, updateAssetRequest, updateHouseInformationsRequest, updateMultipleAssetObjectsRequest, updateMultipleRoofAssetsWhenHouseChangesRequest
} from './permit-actions';
import { HouseService } from '../_services/house.service';
import { House } from '../_models/house';
import { HouseAsset } from '../_models/house-asset';

@Injectable()
export class PermitEffects {
    constructor(
        private actions$: Actions,
        protected houseService: HouseService) { }

    getHouse$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getHouseInformationsRequest),
            switchMap((action) => {
                return this.houseService
                    .getHouse(action.projectId, action.isClone)
                    .pipe(
                        map((house: House) => ({
                            type: '[permit-editor] get house informations',
                            house: house,
                        })),

                        catchError((error) =>
                            of({
                                type: '[permit-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    updateHouse$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateHouseInformationsRequest),
            switchMap((action) => {
                return this.houseService
                    .saveHouse(action.house)
                    .pipe(
                        map((house: House) => ({
                            type: '[permit-editor] update house informations',
                            house: house,
                            shouldUpdatePrevious: action.shouldUpdatePrevious
                        })),

                        catchError((error) =>
                            of({
                                type: '[permit-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );


    getHouseAssets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getHouseAssets),
            switchMap((action) => {
                return this.houseService
                    .getHouseAssets(action.houseId)
                    .pipe(
                        map((objects: HouseAsset[]) => ({
                            type: '[permit-editor] load house assets',
                            objects: objects,
                        })),
                        catchError((error) =>
                            of({
                                type: '[permit-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    addAssetObject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addAssetObjectRequest),
            switchMap((action) => {
                return this.houseService
                    .saveHouseAssets([action.object])
                    .pipe(
                        map((objects: HouseAsset[]) => {

                            return {
                                type: '[permit-editor] add house asset',
                                object: objects[0],
                            };
                        }),

                        catchError((error) =>
                            of({
                                type: '[permit-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    addMultipleAssetObjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addMultipleAssetObjectsRequest),
            switchMap((action) => {
                return this.houseService
                    .saveHouseAssets(action.objects)
                    .pipe(
                        map((objects: HouseAsset[]) => {

                            return {
                                type: '[permit-editor] add multipe house assets',
                                objects: objects
                            };
                        }),

                        catchError((error) =>
                            of({
                                type: '[permit-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );


    deleteAsset$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteAssetRequest),
            switchMap((action) => {
                return this.houseService
                    .deleteHouseAssets([action.object])
                    .pipe(
                        map(() => ({ type: '[permit-editor] delete asset', object: action.object })),
                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    deleteMultipleAsset$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteMultipleAssetsRequest),
            switchMap((action) => {
                return this.houseService
                    .deleteHouseAssets(action.objects)
                    .pipe(
                        map(() => ({ type: '[permit-editor] delete multiple assets', objects: action.objects })),
                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    updateMultipleAssets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateMultipleAssetObjectsRequest),
            switchMap((action) => {
                return this.houseService
                    .saveHouseAssets(action.objects)
                    .pipe(
                        map((objects: HouseAsset[]) => {
                            return {
                                type: '[permit-editor] update multipe house assets',
                                objects: objects,
                            };
                        }),

                        catchError((error) =>
                            of({
                                type: '[permit-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    updateMultipleRoofAssets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateMultipleRoofAssetsWhenHouseChangesRequest),
            switchMap((action) => {
                return this.houseService
                    .saveHouseAssets(action.objects)
                    .pipe(
                        map((objects: HouseAsset[]) => {
                            return {
                                type: '[permit-editor] update multipe roof assets when house changed',
                                objects: objects
                            };
                        }),

                        catchError((error) =>
                            of({
                                type: '[permit-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

     resetHouse$ = createEffect(() =>
        this.actions$.pipe(
            ofType(resetHouseRequest),
            switchMap((action) => {
                return this.houseService
                    .resetHouse(action.house)
                    .pipe(
                        map(() => ({
                            type: '[permit-editor] reset house', house: action.house
                        })),

                        catchError((error) =>
                            of({
                                type: '[permit-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    ); 
   

    resetUpdatedHouseEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(resetHouse),
            switchMap((action) => {
                return of({
                    type: '[permit-editor] get house assets',
                    houseId: action.house.houseId,
                })
            })
        )
    );

    updateAsset$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateAssetRequest),
            switchMap((action) => {
                return this.houseService
                    .updateHouseAsset(action.object)
                    .pipe(
                        map(() => ({ type: '[permit-editor] update asset', object: action.object })),
                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );


    undoRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(undoRequestPermit),
            switchMap((action) => {
                const requests: Observable<any>[] = [];
                const objectsToSave = action.objectsToSave.map(element => {
                    return {
                        ...element,
                        coordinates: typeof element.coordinates === 'string' ? element.coordinates : JSON.stringify(element.coordinates),
                        rotation: typeof element.rotation === 'string' ? element.rotation : JSON.stringify(element.rotation),
                        scale: typeof element.scale === 'string' ? element.scale : JSON.stringify(element.scale)
                    }
                });

                const objectsToRemove = action.objectsToRemove.map(element => {
                    return {
                        ...element,
                        coordinates: typeof element.coordinates === 'string' ? element.coordinates : JSON.stringify(element.coordinates),
                        rotation: typeof element.rotation === 'string' ? element.rotation : JSON.stringify(element.rotation),
                        scale: typeof element.scale === 'string' ? element.scale : JSON.stringify(element.scale)
                    }
                });
                requests.push(this.houseService.saveHouseAssets(objectsToSave));
                requests.push(this.houseService.deleteHouseAssets(objectsToRemove));
                if (action.houseToSave) {
                    requests.push(
                        this.houseService.saveHouse(action.houseToSave)
                    );
                }

                return forkJoin(
                    requests
                ).pipe(
                    map((data) => ({ type: '[permit-editor] undo', objectsToReMove: objectsToRemove, objectsToReplace: action.objectsToSave, newObjects: data[0], houseToSave: data[2] })),
                    catchError(error => of({
                        type: '[permit-editor] error item', message: error
                    }))
                )
            })
        )
    );

    redoRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(redoRequestPermit),
            switchMap((action) => {
                const requests: Observable<any>[] = [];
                const objectsToSave = action.objectsToSave.map((element) => {
                    return {
                        ...element,
                        coordinates: typeof element.coordinates === 'string' ? element.coordinates : JSON.stringify(element.coordinates),
                        rotation: typeof element.rotation === 'string' ? element.rotation : JSON.stringify(element.rotation),
                        scale: typeof element.scale === 'string' ? element.scale : JSON.stringify(element.scale)

                    }
                });

                const objectsToRemove = action.objectsToRemove.map((element) => {
                    return {
                        ...element,
                        coordinates: typeof element.coordinates === 'string' ? element.coordinates : JSON.stringify(element.coordinates),
                        rotation: typeof element.rotation === 'string' ? element.rotation : JSON.stringify(element.rotation),
                        scale: typeof element.scale === 'string' ? element.scale : JSON.stringify(element.scale)

                    }
                });
                requests.push(this.houseService.saveHouseAssets(objectsToSave));
                requests.push(this.houseService.deleteHouseAssets(objectsToRemove));
                if (action.houseToSave) {
                    requests.push(
                        this.houseService.saveHouse(action.houseToSave)
                    );
                }
                return forkJoin(
                    requests
                ).pipe(
                    map((data) => ({ type: '[permit-editor] redo', objectsToReMove: objectsToRemove, objectsToReplace: action.objectsToSave, newObjects: data[0], houseToSave: data[2] })),
                    catchError(error => of({
                        type: '[permit-editor] error item', message: error
                    }))
                )
            })
        )
    );
}
