import { DatePipe } from '@angular/common';
import { AdminPollService } from './../admin-poll/admin-poll.service';
import {
    FormGroup,
    FormBuilder,
    Validators,
    AbstractControlOptions,
    Validator,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    Component,
    OnInit,
    ViewContainerRef,
    Inject,
    ViewChild,
    EventEmitter,
} from '@angular/core';

import {
    containSpaceErrorAndNotRequirredTriggered,
    ControlsUtil,
    FurbanUtil,
    ModalManager,
    Poll,
    PollOption,
    validateWhitespace,
} from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'furban-app-admin-poll-popup',
    templateUrl: './admin-poll-popup.component.html',
    styleUrls: ['./admin-poll-popup.component.scss'],
})
export class AdminPollPopupComponent implements OnInit {
    @ViewChild('adminPollForm', { static: true }) adminPollForm;
    @ViewChild('adminPollOptionForm') adminPollOptionForm;

    public existingPoll: Poll;
    public dialogTitle: string;
    public projectId: string;
    public onModalClose = new EventEmitter();
    public parentViewContainerRef: ViewContainerRef;
    public containSpaceErrorAndNotRequirredTriggeredFunction =
        containSpaceErrorAndNotRequirredTriggered;
    public isDatePassed = FurbanUtil.isDatePassed;
    public questionLimit = 256;

    public adminPollCheckForm: FormGroup;
    public adminPollOptionCheckForm: FormGroup;
    private pollOptions = 4;


    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;
    public hasControlMaxLengthError = ControlsUtil.hasControlMaxLengthError;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<AdminPollPopupComponent>,
        private pollService: AdminPollService,
        private modal: ModalManager,
        private translateService: TranslateService,
        private formBuilder: FormBuilder
    ) {
        this.existingPoll = data.poll;
        this.projectId = data.projectId;
    }

    ngOnInit() {
        this.dialogTitle = this.existingPoll
            ? this.translateService.instant('admin.poll.editPoll')
            : this.translateService.instant('admin.poll.createPoll');
        const datePipe = new DatePipe('en-US');

        this.adminPollCheckForm = this.formBuilder.group({
            startDate: [
                this.existingPoll
                    ? new Date(
                        datePipe.transform(
                            this.existingPoll.startDate,
                            'yyyy-MM-dd'
                        )
                    )
                    : '',
                [Validators.required],
            ],
            endDate: [
                this.existingPoll
                    ? new Date(
                        datePipe.transform(
                            this.existingPoll.endDate,
                            'yyyy-MM-dd'
                        )
                    )
                    : '',
                [Validators.required],
            ],
            pollQuestion: [
                {
                    value: this.existingPoll
                        ? this.existingPoll.pollQuestion
                        : '',
                    disabled: this.shouldPollOptionsBeDisabled(),
                },
                [
                    Validators.required,
                    Validators.maxLength(256),
                    validateWhitespace,
                ],
            ],
        });

        this.adminPollOptionCheckForm = this.formBuilder.group(
            this.constructPollOptionsObject()
        );
    }

    constructPollOptionsObject = (): AbstractControlOptions => {
        const object: AbstractControlOptions = {};

        for (let i = 0; i < this.pollOptions; i++) {
            const controlConfigName = `option${i + 1}`;
            const constructValidators = this.constructValidators(i);
            object[controlConfigName] = [
                {
                    value: this.getPollOption(i),
                    disabled: this.shouldPollOptionsBeDisabled(),
                },
                constructValidators,
            ];
        }

        return object;
    };

    closePopup() {
        if (
            this.adminPollCheckForm.dirty ||
            this.adminPollOptionCheckForm.dirty
        ) {
            this.modal
                .showModal(
                    this.parentViewContainerRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'errors.discardMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (res) {
                        this.dialogRef.close();
                    }
                });
        } else {
            this.dialogRef.close();
        }
    }

    // MAYBE REFACTOR
    savePoll(): void {
        if (
            this.adminPollCheckForm.invalid ||
            this.adminPollOptionCheckForm.invalid
        ) {
            this.markFormAsDirty();
            return;
        }

        if (!this.existingPoll) {
            const poll: Poll = this.getValuesFromPollFormGroup();
            this.pollService
                .createPoll(poll)
                .pipe(
                    switchMap((result) => {
                        const pollOptionArray: PollOption[] =
                            this.getValuesFromPollOptionFormGroup(result);
                        return this.pollService.saveOptions(pollOptionArray);
                    })
                )
                .subscribe(() => {
                    this.dialogRef.close();
                    this.onModalClose.emit();
                });
        } else {
            const poll: Poll = this.getValuesFromPollFormGroup(
                this.existingPoll
            );

            this.pollService
                .savePoll(poll)
                .pipe(
                    switchMap((result) => {
                        if (!result) {
                            return;
                        }
                        const pollOptionArray: PollOption[] =
                            this.getValuesFromPollOptionFormGroup(
                                result,
                                this.existingPoll
                            );
                        return this.pollService.saveOptions(
                            pollOptionArray
                        );

                    })
                )
                .subscribe(() => {
                    this.dialogRef.close();
                    this.onModalClose.emit();
                });
        }
    }

    markFormAsDirty() {
        Object.keys(this.adminPollCheckForm.controls).forEach((key) => {
            this.adminPollCheckForm.get(key).markAsDirty();
        });
        Object.keys(this.adminPollOptionCheckForm.controls).forEach((key) => {
            this.adminPollOptionCheckForm.get(key).markAsDirty();
        });
    }

    getValuesFromPollOptionFormGroup(
        poll: Poll,
        existingPoll?: Poll
    ): PollOption[] {
        const pollOptionsArray: PollOption[] = [];

        Object.keys(this.adminPollOptionCheckForm.controls).forEach(
            (key, index) => {
                if (
                    !existingPoll &&
                    !this.adminPollOptionCheckForm.get(key).value
                ) {
                    return;
                }

                const pollOption: PollOption = new PollOption();
                pollOption.isActive = true;
                pollOption.pollId = poll.pollId;
                pollOption.option =
                    this.adminPollOptionCheckForm.get(key).value;

                if (existingPoll && existingPoll.pollOptions[index]) {
                    pollOption.pollOptionId =
                        existingPoll.pollOptions[index].pollOptionId;
                }

                if (
                    existingPoll &&
                    !this.adminPollOptionCheckForm.get(key).value
                ) {
                    pollOption.isActive = false;
                    pollOption.option = 'DELETED';
                }

                pollOptionsArray.push(pollOption);
            }
        );

        return pollOptionsArray;
    }

    getValuesFromPollFormGroup(existingPoll?: Poll): Poll {
        const poll: Poll = new Poll();
        const datePipe = new DatePipe('en-US');

        let startDateValue = this.adminPollCheckForm.get('startDate').value;
        let endDateValue = this.adminPollCheckForm.get('endDate').value;
        startDateValue = datePipe.transform(startDateValue, 'yyyy-MM-dd');
        endDateValue = datePipe.transform(endDateValue, 'yyyy-MM-dd');

        poll.pollQuestion = this.adminPollCheckForm.get('pollQuestion').value;
        poll.startDate = startDateValue;
        poll.endDate = endDateValue;

        if (!existingPoll) {
            poll.projectId = this.projectId;
            return poll;
        } else {
            poll.projectId = existingPoll.projectId;
            poll.pollId = existingPoll.pollId;
            poll.isActive = existingPoll.isActive;
            poll.isEnabled = existingPoll.isEnabled;
            return poll;
        }
    }

    private shouldPollOptionsBeDisabled(): boolean {
        return (
            this.existingPoll?.isEnabled &&
            this.isDatePassed(this.existingPoll?.endDate)
        );
    }

    private isRequiredOption = (i: number): boolean => {
        return i <= 1;
    };

    private constructValidators(i: number): Validators {
        const validators = [Validators.maxLength(64)];

        if (this.isRequiredOption(i)) {
            validators.push(Validators.required);
        }

        return validators;
    }

    private getPollOption = (i: number): string => {
        return this.existingPoll?.pollOptions[i]
            ? this.existingPoll.pollOptions[i].option
            : '';
    };
}
