import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';

import {
    AuthService,
    NavigationModel,
    routingConstants,
    ProjectStorageService,
    navigationConstants,
    TutorialsService,
    FurbanUtil,
} from '@furban/utilities';
import { Router } from '@angular/router';

@Component({
    selector: 'furban-app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class FurbanNavigationComponent implements OnInit, OnDestroy {

    public items: NavigationModel[];

    public isAppOrMobile = FurbanUtil.isAppOrMobile;

    constructor(
        private router: Router,
        private tutorialService: TutorialsService,
        private projectStorageService: ProjectStorageService,
        private viewContainerRef: ViewContainerRef,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.items = this.getMenuItems();
    }

    ngOnDestroy() {
        this.tutorialService.closeTutorialMenu();
    }

    get isTutorialsOpened(): boolean {
        return this.tutorialService.isTutorialMenuOpened;
    }

    get containerRef(): ViewContainerRef {
        return this.viewContainerRef;
    }

    public shouldDisplayTutorials(): boolean {
        return !this.authService.isNeighbor();
    }

    public toggleTutorials(): void {
        this.tutorialService.toggleTutorialMenu();
    }

    public checkIfSuperAdmin(): boolean {
        return this.authService.isSuperAdmin();
    }

    public checkIfShouldDisplayNotifications(): boolean {
        return !this.checkIfSuperAdmin() && !this.authService.isPioneer()
    }

    public getMenuItems(): NavigationModel[] {
        if (this.authService.isSuperAdmin()) {
            return [
                {
                    title: navigationConstants.superadminGenerateMunicipality,
                    icon: 'location_city',
                    urlLink: routingConstants.adminMunicipality,
                },
                {
                    title: navigationConstants.superadminGenerateAdmin,
                    icon: 'person_add',
                    urlLink: routingConstants.adminNew,
                },
                {
                    title: navigationConstants.superadminBackground,
                    icon: 'business',
                    urlLink: routingConstants.adminBackground,
                },
                {
                    title: navigationConstants.superadminQuestions,
                    icon: 'help_outline',
                    urlLink: routingConstants.adminFaq,
                },
                {
                    title: navigationConstants.notifications,
                    icon: 'add_alert',
                    urlLink: routingConstants.adminNotifications,
                },
                {
                    title: navigationConstants.superadminSupliers,
                    icon: 'store',
                    urlLink: routingConstants.adminSuppliers,
                },
                {
                    title: navigationConstants.superadminRequests,
                    icon: 'assignment',
                    urlLink: routingConstants.adminRequests,
                },
            ];
        } else if (this.authService.isAdmin()) {
            return [
                {
                    title: navigationConstants.dashboard,
                    icon: 'dashboard',
                    urlLink: routingConstants.adminDashboard,
                },
                {
                    title: navigationConstants.adminCodes,
                    icon: 'lock',
                    urlLink: routingConstants.adminCodes,
                },
                {
                    title: navigationConstants.notifications,
                    icon: 'add_alert',
                    urlLink: routingConstants.adminNotifications,
                },
                {
                    title: navigationConstants.adminPermitRequests,
                    icon: 'home_work',
                    urlLink: routingConstants.permitRequests,
                },
            ];
        } else if (this.authService.isCitizenOrExpert) {
            return [
                {
                    title: navigationConstants.dashboard,
                    icon: 'dashboard',
                    urlLink: routingConstants.dashboard,
                },
                {
                    title: navigationConstants.citizenStatistics,
                    icon: 'pie_chart',
                    urlLink: routingConstants.statistics,
                },
                {
                    title: navigationConstants.citizenDesign,
                    icon: 'widgets',
                    urlLink: routingConstants.project,
                },
                {
                    title: navigationConstants.citizenProposals,
                    icon: 'local_florist',
                    urlLink: routingConstants.designs,
                },

                // Delete chat to make room on the left menu, since its not in use
                // {
                //     title: navigationConstants.citizenChat,
                //     icon: 'forum',
                //     urlLink: routingConstants.chat,
                // },
                {
                    title: navigationConstants.citizenHelp,
                    icon: 'help',
                    urlLink: routingConstants.faq,
                },
            ];
        } else if (this.authService.isPioneer()) {
            return [
                {
                    title: navigationConstants.dashboard,
                    icon: 'dashboard',
                    urlLink: routingConstants.initiatives,
                },
                {
                    title: 'navigation.pioneer.initiatives',
                    icon: 'nature_people',
                    urlLink: routingConstants.initiativesProjectNew
                },
                {
                    title: navigationConstants.pioneerPermit,
                    icon: 'home_work',
                    urlLink: `/${routingConstants.permitProjectNew}`,
                },
            ];
        } else if (this.authService.isNeighbor()) {
            return [
                {
                    title: navigationConstants.dashboard,
                    icon: 'house',
                    urlLink: routingConstants.neighborDashboard,
                },
            ];
        } else if (this.authService.isCollaborator()) {
            return [
                {
                    title: navigationConstants.dashboard,
                    icon: 'dashboard',
                    urlLink: routingConstants.collaboratorDashboard,
                },
            ];
        }

        return [];
    }

    public navigateToItem(item: NavigationModel): void {
        if (this.isRouteStatisticsOrDesignOrDp(item.title)) {
            const project = this.projectStorageService.curentProject;
            if (!project.hasWinner) {
                this.router.navigate([item.urlLink, project.projectId]);
            }
        } else {
            this.router.navigateByUrl(item.urlLink);
        }
    }

    public checkForActiveTab(nm: NavigationModel): boolean {
        if (
            nm.urlLink === routingConstants.initiatives &&
            this.router.url !== routingConstants.initiatives
        ) {
            return false;
        }
        return this.router.url.indexOf(nm.urlLink) != -1;
    }

    public checkForActiveTabByString(routingString: string): boolean {
        return this.router.url.indexOf(routingString) === 0;
    }

    public checkIfDisabled(item: NavigationModel): boolean {
        if (item.title === navigationConstants.citizenDesign) {
            if (this.projectStorageService.curentProject) {
                const project = this.projectStorageService.curentProject;
                return project.ended || !project.allowCitizenDesigns;
            }
            return true;
        }

        return item.title === navigationConstants.citizenChat;
    }


    private isRouteStatisticsOrDesignOrDp(title: string): boolean {
        return !!this.projectStorageService.curentProject && ((title === navigationConstants.citizenProposals) || (title === navigationConstants.citizenStatistics ) || (title === navigationConstants.citizenDesign));
    }

    // public checkIfDisabled(item: NavigationModel): boolean {
    //     const project = this.projectStorageService.curentProject;
    //     const isCitizenDesign = item.title === navigationConstants.citizenDesign;
    //     const isCitizenChat = item.title === navigationConstants.citizenChat;

    //     if (this.authService.isCitizenOrExpertOrCollaborator && (!project || isCitizenChat)) {
    //         return true;
    //     }

    //     if (isCitizenDesign) {
    //         return project.ended || !project.allowCitizenDesigns;
    //     }

    //     return false;

    // }


    public checkMatBadgeHidden(item: NavigationModel): boolean {
        /* TO-DO chat - when you want to show again the badge, uncoment this:
    return item.title != "navigation.citizen.chat" || (item.title == "navigation.citizen.chat" && this.talkJsService.amountOfUnreads == 0);
    */
        return true;
    }

    public navigateToHomepage(): void {
        this.router.navigate([routingConstants.info]);
    }

}