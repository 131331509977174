import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

import { PermitUtils } from '@furban/utilities';

import { PermitReview } from '../../_models/permit-review';

@Component({
    selector: 'furban-neighbor-permit-reviews',
    templateUrl: './neighbor-permit-reviews.component.html',
    styleUrls: ['./neighbor-permit-reviews.component.scss'],
})
export class NeighborPermitReviewsComponent implements OnChanges {
    public getPermitLabelColor = PermitUtils.getPermitLabelColor;

    @Input() public permitReviews: PermitReview[];

    public ngOnChanges(changes: SimpleChanges): void {
        this.permitReviews = changes['permitReviews']
            .currentValue as PermitReview[];
    }
}
