<div class="container-btn-back">
    <mat-icon (click)="goBack()">arrow_back_ios</mat-icon>
    <h3 class="page-title">
        {{ 'user.statistics.userStatistics' | translate }}
    </h3>
</div>
<ng-container *ngIf="curentProjectId; else noProjects">
    <furban-user-charts> </furban-user-charts>
</ng-container>
<ng-template #noProjects>
    <div class="flex-column-center padding-40-20">
        <img src="../../../assets/images/no_statistics.svg" />
        <h4 class="placeholder-text">
            {{ 'user.statistics.noStatistics' | translate }}
        </h4>
    </div>
</ng-template>
