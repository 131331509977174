import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationModalComponent } from '../notification-modal/notification-modal.component';

import {
    NotificationModel,
    NotificationService,
    CustomToasterComponent,
    CustomToasterService,
} from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-notification-panel',
    templateUrl: './notification-panel.component.html',
    styleUrls: ['./notification-panel.component.scss'],
})
export class NotificationPanelComponent implements OnInit {
    public notificationListMaxSize: number;
    public curentPage = 0;
    public notificationList: NotificationModel[] = [];
    public dataSource: MatTableDataSource<NotificationModel>;
    public displayedColumns: string[] = [
        'message',
        'notificationType',
        'targetName',
        'createdDate',
        'expiringDate',
    ];

    constructor(
        private dialog: MatDialog,
        private viewContainerRef: ViewContainerRef,
        private notificationService: NotificationService,
        private customToasterService: CustomToasterService
    ) { }

    ngOnInit(): void {
        this.notificationService
            .countNotificationsBySender()
            .subscribe((data) => {
                this.notificationListMaxSize = data;
                this.getNotifications();
            });
    }

    getNotifications() {
        this.notificationService
            .getNotificationsBySender(this.curentPage)
            .subscribe(
                {
                    next: (data) => {
                        this.notificationList = this.notificationList.concat(data);
                        this.dataSource = new MatTableDataSource(
                            this.notificationList
                        );
                    },
                    error: (err) => {
                        this.curentPage = this.curentPage - 1;

                    }
                }
            );
    }

    openNotificationDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '50%';

        const notification = this.dialog.open(
            NotificationModalComponent,
            dialogConfig
        );
        notification.componentInstance.parentRef = this.viewContainerRef;

        notification.afterClosed().subscribe((data) => {
            if (data) {
                data.forEach((element) => {
                    this.notificationList.unshift(element);
                });
                this.dataSource = new MatTableDataSource(this.notificationList);
                const manyNotificationsCreated = data.length > 1;
                this.showSuccessToaster(manyNotificationsCreated);
            }
        });
    }

    showSuccessToaster(manyNotificationsCreated: boolean) {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            manyNotificationsCreated
                ? 'notifications.createManyNotificationsSuccess'
                : 'notifications.createNotificationSuccess',
            2000
        );
    }

    onScrollDown() {
        this.curentPage = this.curentPage + 1;
        if (this.notificationList.length < this.notificationListMaxSize) {
            this.getNotifications();
        }
    }

    returnNotificationTypeAsString(item: NotificationModel) {
        return this.notificationService.returnNotificationTypeAsString(item);
    }
}
