<section class="map-container">
    <ng-container *ngIf="!isProjectPublished">
        <div class="floating-input floating-buttons">
            <button
                class="round-button"
                matTooltip="{{
                    'buttonsTooltips.goToCurentLocation' | translate
                }}"
                (click)="goToCurentLocation()"
            >
                <span class="material-icons">gps_fixed</span>
            </button>
            <furban-address-search
                [geocodingProvider]="geocodingProvider"
                (changeAddress)="changeAddress($event)"
            ></furban-address-search>
        </div>
    </ng-container>

    <div class="floating-buttons bottom right">
        <mat-slide-toggle [style.height.px]="36" (change)="switchLayer($event)">
            {{ layerType }}
        </mat-slide-toggle>
    </div>

    <furban-open-map></furban-open-map>
</section>
