import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';

import {
    CodesFilter,
    CodesRequestDTO,
    FurbanUtil,
    User,
    UserLight,
} from '@furban/utilities';

import { environment } from '../../../environments/environment';
import { SelectionModel } from '@angular/cdk/collections';

@Injectable({
    providedIn: 'root',
})
export class CodesService {
    public urls = {
        expertExportCsv: '/user/expert/csv',
        citizenExportCsv: '/user/citizen/csv',
        neighbourExportCsv: '/user/neighbor/csv',
    };

    public selection = new SelectionModel<UserLight>(true, []);
    public codesFilter: CodesFilter = new CodesFilter();

    private _headers: HttpHeaders = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );
    private _options = {
        headers: new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
        ),
    };

    constructor(private http: HttpClient) { }

    public getAllCodes(requestParam: CodesRequestDTO): Observable<UserLight[]> {
        const paramObject: any = {};

        paramObject.pageIndex = requestParam.pageIndex;
        paramObject.perPage = requestParam.perPage;

        if (requestParam.groupId) {
            paramObject.groupId = requestParam.groupId;
        }

        if (requestParam.startDate != null) {
            paramObject.startDate = requestParam.startDate;
        }

        if (requestParam.endDate != null) {
            paramObject.endDate = requestParam.endDate;
        }

        if (requestParam.isEmailSent != null) {
            paramObject.isEmailSent = requestParam.isEmailSent;
        }

        if (requestParam.role != null) {
            paramObject.role = requestParam.role;
        }

        return this.http.get<UserLight[]>(`${environment.apiPath}/user/all`, {
            params: paramObject,
        });
    }

    public getCodesCount(
        groupId: string,
        role?: number,
        startDate?: Date,
        endDate?: Date
    ): Observable<number> {
        const datePipe = new DatePipe('en-US');
        const paramObject: any = {};
        if (groupId != null) {
            paramObject.groupId = groupId;
        }
        if (startDate != null) {
            const stringifyStartDate = datePipe.transform(
                startDate,
                'dd/MM/yyyy'
            );
            paramObject.startDate = stringifyStartDate;
        }

        if (endDate != null) {
            const stringifyEndDate = datePipe.transform(endDate, 'dd/MM/yyyy');
            paramObject.endDate = stringifyEndDate;
        }

        if (role !== 0 && role != null) {
            paramObject.role = role.toString();
        }

        return this.http.get<number>(`${environment.apiPath}/user/all/count`, {
            params: paramObject,
        });
    }

    public deleteExpertOrCitizenCodes(
        codes: UserLight[],
        groupId: string
    ): Observable<string> {
        const userCodes = [];
        for (const userCode of codes) {
            userCodes.push(userCode.userId);
        }

        const headers = new HttpHeaders().set(
            'Content-Type',
            'application/json'
        );

        return this.http
            .post<string>(
                `${environment.apiPath}/user/delete/${groupId}`,
                userCodes,
                { headers: headers }
            )
            .pipe(map((result) => result));
    }

    public deletePioneerUserCodes(codes: User[]): Observable<string> {
        const userCodes = [];
        for (const userCode of codes) {
            userCodes.push(userCode.userId);
        }

        const headers = new HttpHeaders().set(
            'Content-Type',
            'application/json'
        );

        return this.http
            .post<string>(`${environment.apiPath}/user/delete`, userCodes, {
                headers: headers,
            })
            .pipe(map((result) => result));
    }

    public getUserByCodeAndGroupId(
        code: string,
        groupId?: string
    ): Observable<User> {
        const paramObject: any = {
            userCode: code.toUpperCase().trim(),
        };
        if (groupId != null) {
            paramObject.groupId = groupId;
        }

        return this.http.get<User>(`${environment.apiPath}/user/code`, {
            params: paramObject,
        });
    }

    public exportExpertOrCitizenCodes(
        url: string,
        groupIds?: string[]
    ): Observable<ArrayBuffer> {
        const headers: HttpHeaders = this._headers;
        return this.http
            .post(`${environment.apiPath}${url}`, groupIds, {
                headers,
                responseType: 'arraybuffer',
            })
            .pipe(map((csv) => csv));
    }

    public exportNeighborsCodes(groupId?: string): Observable<ArrayBuffer> {
        const headers: HttpHeaders = this._headers;
        return this.http
            .get(
                `${environment.apiPath}${this.urls.neighbourExportCsv}/${groupId}`,
                { headers, responseType: 'arraybuffer' }
            )
            .pipe(map((csv) => csv));
    }

    public generateCodesInMultipleRequests(
        paramObject,
        dialogRef,
        data?
    ): void {
        const apiCalls = [];
        while (paramObject.citizensCount > 0 || paramObject.expertsCount > 0) {
            if (paramObject.citizensCount <= 50) {
                apiCalls.push(paramObject);
                break;
            }

            const object: any = {};
            object.citizensCount = 50;
            object.expertsCount = paramObject.expertsCount;
            object.groupId = paramObject.groupId;

            paramObject.citizensCount = paramObject.citizensCount - 50;
            paramObject.expertsCount = 0;
            apiCalls.push(object);
        }

        const generateCodes$ = from(apiCalls);

        generateCodes$
            .pipe(
                concatMap((apiCall) =>
                    this.generateExpertOrCitizenCodes(apiCall)
                )
            )
            .subscribe({
                complete: () => {
                    dialogRef.close(data ? data : 'close');
                },
            });
    }

    public generateExpertOrCitizenCodes(paramObject): Observable<User[]> {
        return this.http
            .post(
                `${environment.apiPath}/user/generate`,
                FurbanUtil.encodeObjectURIComponents(paramObject),
                this._options
            )
            .pipe(map((result) => result as User[]));
    }

    public generatePioneerCodes(paramObject): Observable<User[]> {
        return this.http
            .post(
                `${environment.apiPath}/user/generate`,
                paramObject,
                this._options
            )
            .pipe(map((result) => result as User[]));
    }


}
