import { ThreeStateEnum } from '../_enum/three-state.enum';

export class ToolingVisibility {
    public isFullScreen = false;
    public editMode = true;
    public state: ThreeStateEnum;
    public isSafetyEnabled = false;
    public isPublished: boolean;
    public isTransparencyEnabled = false;
}
