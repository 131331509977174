import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    ViewContainerRef,
    HostListener,
} from '@angular/core';
import { CustomObjectComponent } from './../custom-object/custom-object.component';

import {
    AuthService,
    CategoryObject,
    CustomToasterComponent,
    Emitted3DObject,
    ObjectTypeEnum,
    PathObject,
    PathObjectsService,
    Project,
    Project3dModel,
    ProjectStorageService,
    ThreeStateEnum,
    Tool,
    ToolNameEnum,
    MapService,
    MenuService,
    ThreeUtils,
    StepperService,
    ToolingActionsEnum,
    ToolingVisibility,
    RIGHT_MENU_TOUR_ITEMS,
    FurbanUtil,
    RIGHT_MENU_TOUR_ITEMS_IPAD,
    CustomToasterService,
    SvgImageEnum,
    FileUploadService,
    UploadedObjectTypeEnum,
    ShepherdTourService,
    ThreeStore,
    ACCEPTED_FORMATS,
    MAX_SIZE,
    Project3DUpload,
    ObjAndTextureFiles,
    TourNameEnum,
    DragUploadComponent,
} from '@furban/utilities';
import { ProjectDetailsService } from '../../project-details/project-details.service';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { Modifiable } from '../../../shared/modifyStatus/modifiable.interface';
import { ToolingService } from '../../../shared/tooling/tooling.service';
import { Vector2 } from 'three';
import { ProjectMenuThreeDComponent } from '../project-menu-three-d/project-menu-three-d.component';
import { NgrxThreeEditorComponent } from '../../../shared/ngrx-three-editor/ngrx-three-editor.component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NotificationToasterComponent } from '../../../shared/notification-toaster/notification-toaster.component';
import { NavigationService } from '../../../shared/navigation/navigation.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
    selector: 'furban-app-user-project',
    templateUrl: './user-project.component.html',
    styleUrls: ['./user-project.component.scss'],
})
export class UserProjectComponent implements OnInit, OnDestroy, Modifiable {

    @ViewChild('undergroundUpload') undergroundUpload: DragUploadComponent;
    @ViewChild('customObjectUpload') customObjectUpload: DragUploadComponent;
    @ViewChild('fixedObjectUpload') fixedObjectUpload: DragUploadComponent;

    @ViewChild('threeJsEditorComponent')
    threeJsEditorComponent: NgrxThreeEditorComponent;
    @ViewChild('sideMenuUserProj') sideMenu: ProjectMenuThreeDComponent;

    public isApp = FurbanUtil.isApp;

    public heightOfHoverDiv = 200;
    public hoverContainerTop: number;
    public minTopForHover: number;
    public headerHeight: number;
    public projId: string;
    public selectedGroundFreeShape = '';
    public project: Project;
    public userObjects: PathObject[] = [];
    public defaultObjects: PathObject[] = [];

    public freeShapeDrawing = false;
    public currentlyEnabledTool: Tool;
    public threeState: string;
    public hoverImagePath: string;
    public hoverObjectName: string;
    public hoverActionDescription: string;
    public availableBudget = 0;
    public objects: CategoryObject[];
    public isEditMode = true;
    public startAddOnClick = false;
    public shouldShowUploadMenu = false;
    public shouldDisplayFreeshapeMenu = false;
    public maxSize = MAX_SIZE;
    public uploadMultilayerAcceptedFormats = ACCEPTED_FORMATS;
    /**
     * This will become true when toggeling to 3D first time
     * This will prevent the blocking of the 2D canvas while 3D is loading
     */
    public activeButton: ToolingActionsEnum;
    public is3dReady = false;
    public isFullScreen = false;
    public dpId: string;
    public toolingVisibility: ToolingVisibility;
    public selectedGroundMaterial: Project3dModel;
    public isIOS = FurbanUtil.isIOS();
    public isRedoEnabled = false;
    public isUndoEnabled = false;

    public rightMenuTourItems = this.isIOS
        ? RIGHT_MENU_TOUR_ITEMS_IPAD
        : RIGHT_MENU_TOUR_ITEMS;

    public exceedTopPosition: boolean;
    public isToolsHover: boolean;
    public generateShapesAssetsURL = FurbanUtil.generateShapesAssetsURL;
    public uploadedObjectTypeEnum = UploadedObjectTypeEnum;

    public shapes = [
        ObjectTypeEnum.freeshape,
        ObjectTypeEnum.square,
        ObjectTypeEnum.elipse,
    ];

    private _modified = false;
    private sub: any;
    private isMunicipalityDesignsProject: boolean;
    private toolingVisibilitySubscription: Subscription;
    private storeSubscription: Subscription;
    private buttonSubcription: Subscription;
    private actionFinishedSubscription: Subscription;
    private editModeSubscription: Subscription;

    constructor(
        private _location: Location,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private pathObjectsService: PathObjectsService,
        private authService: AuthService,
        private mapService: MapService,
        private viewContainerRef: ViewContainerRef,
        private projectDetailsService: ProjectDetailsService,
        private dialog: MatDialog,
        private fileUploadService: FileUploadService,
        private customToasterService: CustomToasterService,
        private router: Router,
        private projectStorageService: ProjectStorageService,
        private menuService: MenuService,
        private stepperService: StepperService,
        private toolingService: ToolingService,
        private store: Store<{ store: ThreeStore }>,
        private shepherdTourService: ShepherdTourService,
        private navigationService: NavigationService
    ) {
        this.projectStorageService.projectChanged.subscribe((item) => {
            if (!this.authService.hasAdministrativeRole()) {
                this.resetValues(item);
            }
        });
    }

    public get svgImageType(): typeof SvgImageEnum {
        return SvgImageEnum;
    }

    public get shouldShowMenu(): boolean {
        return this.menuService.shouldShowMenu;
    }

    public get undergroundProject3DUpload(): Project3DUpload {
        return this.fileUploadService.undergroundProject3DUpload;
    }

    public get customObjectProject3DUpload(): Project3DUpload {
        return this.fileUploadService.customObjectProject3DUpload;
    }

    public get fixedObjectProject3DUpload(): Project3DUpload {
        return this.fileUploadService.fixedObjectProject3DUpload;
    }

    public get isCitizenOrExpert(): boolean {
        return this.authService.isCitizen() || this.authService.isExpert();
    }

    ngOnInit() {
        this.menuService.shouldShowMenu = false;
        this.projId = this.stepperService.projectId;
        this.sub = combineLatest([
            this.route.params,
            this.route.data,
        ]).subscribe((data: any) => {
            if (!data) {
                return;
            }

            if (!this.projId) {
                this.projId = data[0]['projectId'];
            }
            this.isMunicipalityDesignsProject = data[1][
                'isMunicipalityDesignsProject'
            ] as boolean;
            this.dpId = data[0]['dpId'];
            this.getPolygon();
            this.setupProjectDetails();
            this.subscribeToToolingVisibility();
        });
        this.subscribeToStore();
        this.subscribeToButtonClickedEvent();
        this.subscribeToActionsFinished();
        this.subscribeToEditMode();

    }

    ngOnDestroy() {
        if (this.fileUploadService.isUndergroundUploaded) {
            this.onFinishUpload();
        }
        this.sub.unsubscribe();
        this.storeSubscription.unsubscribe();
        this.toolingVisibilitySubscription.unsubscribe();
        this.fileUploadService.resetFilesOnProjectChange();
        this.buttonSubcription.unsubscribe();
        this.actionFinishedSubscription.unsubscribe();
        this.editModeSubscription.unsubscribe();
        this.fileUploadService.changeObjectTextureFile(null, UploadedObjectTypeEnum.fixedDesign);
    }

    public shouldDisplayTooling(): ToolingVisibility {
        return !this.isPioneerOrAdminLoggedIn() &&
            this.isProjectLoaded() &&
            this.toolingVisibility;
    }

    public onFileUpload(file: File, type: UploadedObjectTypeEnum) {
        const isCustom = type === UploadedObjectTypeEnum.customObject;
        const isUnderground = type === UploadedObjectTypeEnum.underground;
        const isFileUploaded = !!file;

        if (!isFileUploaded) {
            this.navigationService.toggleTransparency(false);
        }

        if (isCustom) {
            this.fileUploadService.isCustomObjectUploaded = isFileUploaded;
        } else if (isUnderground) {
            this.fileUploadService.isUndergroundUploaded = isFileUploaded;
            this.toolingService.toolingVisibility.isTransparencyEnabled = isFileUploaded;
            this.navigationService.toggleTransparency(true);
        } else {
            this.fileUploadService.isFixedObjectUploaded = isFileUploaded;
        }

        this.fileUploadService.changeObjectFile(file, type);
    }

    public onObjectTextureUpload(files: ObjAndTextureFiles, type: UploadedObjectTypeEnum): void {
        this.fileUploadService.changeObjectTextureFile(files, type);
    }

    public mouseEnterWithSrc(imagePath: string, event) {
        if (this.displayHoverFunctionality()) {
            this.hoverImagePath = imagePath;
            this.hoverContainerTop = this.getTopPosition(event);
        }
    }

    public onRemoveUpload(objectType: UploadedObjectTypeEnum): void {
        this.removeUpload(objectType);
    }

    public getTopPosition(event) {
        const mouseHoverPosition: number =
            event.clientY - this.heightOfHoverDiv;
        let computedPosition = Math.min(
            mouseHoverPosition,
            this.minTopForHover - 8
        );
        if (computedPosition < this.headerHeight) {
            this.exceedTopPosition = true;
            computedPosition = 8;
        } else {
            this.exceedTopPosition = false;
        }
        return computedPosition;
    }

    public displayHoverFunctionality(): boolean {
        if (
            this.authService != null &&
            this.authService.userProfile != null &&
            this.authService.userProfile.userSettings != null
        ) {
            return this.authService.userProfile.userSettings
                .isHoverHelperActive;
        }
        return true;
    }

    public startTour() {
        if (!this.selectedGroundMaterial) {
            return;
        }
        const hasTour = localStorage.getItem('has-design-tour');
        if (this.isCitizenOrExpert && hasTour && hasTour === 'false') {
            localStorage.setItem('has-design-tour', 'false');
            this.shepherdTourService.startTour(TourNameEnum.designTour);
        }
    }

    public setDefaultGroundMaterial() {
        this.selectedGroundMaterial = this.objects.find(
            (category) =>
                category.category.categoryName ===
                'user.projectMenu.groundMaterials'
        ).models[0];
    }


    public mouseEnterShapes(shape: ObjectTypeEnum, translationLabel, event) {
        this.hoverObjectName =
            this.translateService.instant(translationLabel) +
            ' ' +
            this.translateService.instant('3Dmenu.hoverTitles.' + shape);
        const srcValue: string = this.generateShapesAssetsURL(
            this.selectedGroundMaterial.furban3DModel.objectLookId,
            shape
        );
        this.mouseEnterWithSrc(srcValue, event);
    }

    public isDropEventInsideMenu(event: CdkDragDrop<string[]>): boolean {
        const offsetLeft = event.item.element.nativeElement.offsetLeft;
        return event.distance.x + offsetLeft < 300;
    }

    public onShapeObjectSelection(selectedType: string): void {
        this.currentlyEnabledTool = null;
        const selectedShapeModel = this.selectedGroundMaterial;
        selectedShapeModel.objectType = selectedType;
        this.removeLastUnfinishedFreeShape();
        this.selectedGroundFreeShape = selectedType;
        this.onRegularObjectSelection(selectedShapeModel);
    }

    public onRegularObjectSelection(selectedObject: Project3dModel): void {
        this.currentlyEnabledTool = null;
        if (this.freeShapeDrawing) {
            this.removeLastUnfinishedFreeShape();
        }
        this.emitLastAddedObject(selectedObject, false);
    }

    public getDragDelay() {
        return FurbanUtil.isMobile() ? 70 : 0;
    }

    public dropShape(event: CdkDragDrop<string[]>): void {
        this.onHighlightDropingArea(false);
        this.currentlyEnabledTool = null;

        if (this.isDropEventInsideMenu(event)) {
            return;
        }

        if (this.freeShapeDrawing) {
            this.removeLastUnfinishedFreeShape();
        }
        const shapeType = event.item.data;

        const objectToAdd = this.selectedGroundMaterial;
        objectToAdd.objectType = shapeType;
        this.selectedGroundFreeShape = shapeType;
        this.emitLastAddedObject(objectToAdd, true);
    }


    public mouseLeave() {
        this.hoverImagePath = null;
        this.isToolsHover = false;
        this.hoverActionDescription = null;
        this.hoverObjectName = null;
    }

    public shouldDisplayPrice(): boolean {
        return (
            this.authService.isAdminDesignRouteOrNotAdministrativeRole() &&
            !this.authService.isCollaborator() &&
            !!this.project.price
        );
    }

    public checkActiveButton(buttonType: ToolingActionsEnum): boolean {
        return this.activeButton === buttonType;
    }

    public onButtonClick(buttonType: ToolingActionsEnum): void {
        this.resetActions();
        if (this.activeButton === buttonType) {
            this.activeButton = null;
            const tool = new Tool(buttonType, false);
            this.currentlyEnabledTool = tool;
            this.checkAction(tool);
            this.menuService.shouldShowMenu = false;
            return;
        }

        const tool = new Tool(buttonType, true);
        this.currentlyEnabledTool = tool;
        this.checkAction(tool);
        this.activeButton = buttonType;
    }

    public checkAction(tool: Tool): void {
        this.resetActions();
        switch (tool.toolName) {
            case this.toolingActions.addObjects: {
                this.toggleObjectsMenu(tool);
                break;
            }
            case this.toolingActions.generateObjects: {
                this.onGenerateGreenObjectsClick(tool);
                break;
            }
            case this.toolingActions.objectsInLine: {
                this.onObjectsInLineClick(tool);
                break;
            }
            case this.toolingActions.multiselect: {
                this.onMultipleSelectionStateChange(tool);
                break;
            }
            case this.toolingActions.upload: {
                this.toggleUpload(tool);
                break;
            }
            case this.toolingActions.freeshape: {
                this.toggleFreeShape(tool);
                break;
            }
            case this.toolingActions.navigateBack: {
                this.navigateBack();
                break;
            }
            default: {
                break;
            }
        }
    }

    public checkIfUndergroundUploaded(): void {
        if (this.fileUploadService.isUndergroundUploaded) {
            this.showToaster('warning', 'info', 'errors.navigateToMenu', 20000);
            this.shouldShowUploadMenu = true;
            return;
        }
    }

    public showToaster(icon: string, type: string, message: string, duration: number): void {
        this.customToasterService.openCustomToaster(CustomToasterComponent, icon, type, message, duration);
    }

    public onFinishUpload(): void {
        this.fileUploadService.isUndergroundUploaded = false;
        this.fileUploadService.isCustomObjectUploaded = false;
        this.fileUploadService.isFixedObjectUploaded = false;
        this.onFinishUploadCustomObject();
    }

    public onObjectUploadDelete(project3DUpload: Project3DUpload, type: UploadedObjectTypeEnum): void {
        this.fileUploadService
            .removeProject3DUpload(project3DUpload)
            .subscribe(() => {
                this.fileUploadService.changeObjectFile(null, type);
                this.clearProject3DFromService(type);
            });
    }


    public switchOpacity(): void {
        this.fileUploadService.isUndergroundUploaded = false;
        this.toolingService.toolingVisibility.isTransparencyEnabled = false;
        this.navigationService.toggleTransparency(false);
    }


    public checkIfRouteIncludes(includedString: string): boolean {
        return this.router.url.includes(includedString);
    }

    public resetValues(item: Project) {
        this._modified = false;
        this.stepperService.changeProjectModifiedState(false);
        this.userObjects = null;
        this.objects = null;
        this.is3dReady = false;
        this.project = item;
        this.projId = this.project.projectId;
    }

    public setupProjectDetails(): void {
        const requests = [
            this.projectDetailsService.getProject(this.projId),
            this.menuService.getObjectsOrdered(this.projId),
            this.pathObjectsService.getPathDefaultObjects(this.projId),
        ];

        forkJoin(requests).subscribe((data: any) => {
            this.setProject(data[0]);
            this.objects = data[1];
            this.setDefaultGroundMaterial();
            this.setProjectDefaultObjects(data[2]);
            // REFACTOR - this is not working if the objects change
            this.setCustomObjectForTour(
                this.objects[this.objects.length - 1].models[0]
            );
            this.startTour();
        });
    }

    public checkIfReady(): PathObject[] {
        return this.project && this.objects && this.userObjects;
    }

    public checkIfHideThreeDMenu(): boolean {
        return this.isEditMode && this.menuService.shouldShowMenu;
    }

    public setupThreeState(isEnded: boolean) {
        if (isEnded) {
            this.threeState = ThreeStateEnum.view;
        } else {
            this.threeState = ThreeStateEnum.default;
        }
    }

    public setProject(project: Project): void {
        this.project = project;
        this.setupThreeState(this.project.ended);
        if (this.project.price) {
            this.availableBudget = this.project.price;
        }
    }

    public setProjectDefaultObjects(pathObjects: PathObject[]): void {
        this.userObjects = pathObjects;
        this.defaultObjects = pathObjects;
        if (this.authService.user.role.roleId !== 2) {
            this.getUserPathObjects();
        } else {
            if (this.isMunicipalityDesignsProject) {
                this.getDefaultDPPathObjects();
            } else {
                this.is3dReady = true;
            }
        }
    }

    public setCustomObjectForTour(projectModel: Project3dModel) {
        const hasTour = localStorage.getItem('has-design-tour');
        if (this.isCitizenOrExpert && hasTour && hasTour === 'false') {
            this.shepherdTourService.setCustomObjectForTour(projectModel);
        }
    }

    public getUserPathObjects() {
        this.pathObjectsService
            .getPathObjects(
                this.projId,
                this.authService.userProfile.userProfileId
            )
            .subscribe((data) => {
                this.addSpecificPathObjects(data);
            });
    }

    public getDefaultDPPathObjects() {
        this.pathObjectsService
            .getPathObjectsForDefaultDP(this.projId, this.dpId)
            .subscribe((data) => {
                this.addSpecificPathObjects(data);
            });
    }

    public calculateAvailableBudget(data: PathObject[]) {
        for (const object of data) {
            const furbanObject = this.menuService.getClientObject(object.objId);
            if (furbanObject) {
                this.availableBudget =
                    this.availableBudget - furbanObject.price;
            } else {
                console.error(
                    'This object could not be found to calculate the price: ',
                    object
                );
            }
        }
    }

    public modify(event) {
        this._modified = event;
        this.stepperService.changeProjectModifiedState(event);
    }

    public onFinishedDrawing() {
        this.freeShapeDrawing = false;
    }

    public removeLastUnfinishedFreeShape() {
        this.threeJsEditorComponent.clearLastDrawing();
        this.threeJsEditorComponent.addEventsOnCanvas();
        this.freeShapeDrawing = false;
    }

    public getPolygon() {
        this.mapService.getPolygon(this.projId).subscribe();
    }

    public moveToScreenshot() {
        this.threeState = ThreeStateEnum.screenshot;
    }

    public onScreenshotTaken() {
        this.threeState = ThreeStateEnum.default;
    }

    public get modified(): boolean {
        return this._modified;
    }

    public get containerRef(): ViewContainerRef {
        return this.viewContainerRef;
    }

    public openCustomObjectModal(object: any, editMode: boolean) {
        let dimensions = {
            width: 3,
            height: 3,
            depth: 3,
        };

        if (editMode) {
            dimensions = {
                width: object.target.width / 100,
                height: object.target.height / 100,
                depth: object.target.depth / 100,
            };
        }

        const dialogRef = this.dialog.open(CustomObjectComponent, {
            width: '600px',
        });
        dialogRef.disableClose = true;
        dialogRef.componentInstance.parentRef = this.viewContainerRef;
        dialogRef.componentInstance.dimensions = dimensions;

        dialogRef.componentInstance.onModalClose.subscribe((data) => {
            if (data) {
                if (editMode) {
                    object.target.width = data.width * 100;
                    object.target.height = data.height * 100;
                    object.target.depth = data.depth * 100;
                    object.target.freeshapePoints = JSON.stringify(data);
                } else {
                    object.width = data.width * 100;
                    object.height = data.height * 100;
                    object.depth = data.depth * 100;
                    object.freeshapePoints = JSON.stringify(data);
                }
                dialogRef.close();
            }
        });
    }

    public isPioneerOrAdminLoggedIn() {
        return this.authService.hasAdministrativeRole();
    }

    public showNavigation() {
        return this.isPioneerOrAdminLoggedIn() && !this.isFullScreen;
    }

    public onObjectDelete(objectId: number) {
        const clientObject = this.menuService.getClientObject(objectId);
        if (
            this.authService.isAdminDesignRouteOrNotAdministrativeRole() &&
            this.project.price &&
            !this.isFreeObject(clientObject.furban3DModel.objectLookId)
        ) {
            this.availableBudget = this.availableBudget + clientObject.price;
        }
    }

    public modifyCustomObject(event) {
        this.openCustomObjectModal(event, true);
    }

    public isBudgetExceded(addedObjectPrice: number): boolean {
        return this.project.price && this.availableBudget < addedObjectPrice;
    }

    public showBudgetExcededToaster(): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'info',
            'info',
            this.project.isPriceRestrictionEnabled
                ? 'price.excededWhenEnabledRestriction'
                : 'price.exceded',
            2000
        );
    }

    public onDesignRestart() {
        if (this.threeJsEditorComponent) {
            this.toolingService.toolingVisibility.isPublished = false;
        }
        if (this.project.price) {
            this.availableBudget = this.project.price;
        }
    }

    public onProjectPriceModify(emittedObject: any) {
        this.availableBudget =
            this.availableBudget - emittedObject.priceOfGeneratedGreenObjects;
        if (emittedObject.showBudgetExceededToaster) {
            this.showBudgetExcededToaster();
        }
    }

    public toggleEdit(isEditing: boolean) {
        this.isEditMode = isEditing;
    }

    public checkIfBudgetExceededAndAddObject(emittedObject: Emitted3DObject) {
        if (
            this.isBudgetExceded(emittedObject.project3DModel.price) &&
            this.project.isPriceRestrictionEnabled
        ) {
            this.showBudgetExcededToaster();
        } else {
            this.threeJsEditorComponent.add3DObject(emittedObject);
        }
    }

    public addRegularObjectBasedOnPriceRestriction(
        emittedObject: Emitted3DObject
    ) {
        if (!this.authService.isAdminDesignRouteOrNotAdministrativeRole()) {
            this.threeJsEditorComponent.add3DObject(emittedObject);
        } else {
            this.checkIfBudgetExceededAndAddObject(emittedObject);
        }
    }

    public setupDefaultPropertiesToGroundObjects(
        project3DModel: Project3dModel
    ) {
        if (project3DModel.objectType === ObjectTypeEnum.freeshape) {
            this.threeJsEditorComponent.drawFreeshape(
                ObjectTypeEnum.freeshape,
                project3DModel
            );
            this.freeShapeDrawing = true;
            return;
        } else if (project3DModel.objectType === ObjectTypeEnum.square) {
            project3DModel.freeshapePoints = JSON.stringify(new Vector2(3, 3));
        } else if (project3DModel.objectType === ObjectTypeEnum.elipse) {
            project3DModel.freeshapePoints = JSON.stringify(new Vector2(2, 2));
        } else if (project3DModel.objectType === ObjectTypeEnum.custom) {
            project3DModel.freeshapePoints = JSON.stringify(
                ThreeUtils.customHouseDefaultDim
            );
        }
    }

    public onObject3DAdd(emittedObject: Emitted3DObject): void {
        this.onMultipleSelectionToolStateChange(false);
        this.setupDefaultPropertiesToGroundObjects(
            emittedObject.project3DModel
        );

        if (
            emittedObject.project3DModel.objectType === ObjectTypeEnum.freeshape
        ) {
            return;
        }

        this.addRegularObjectBasedOnPriceRestriction(emittedObject);

        if (this.threeJsEditorComponent.isSafetyEnabled) {
            this.threeJsEditorComponent.enableSafetyArea();
        }
    }

    public onHighlightDropingArea(shouldHighlight: boolean): void {
        this.threeJsEditorComponent.changeCanvasColor(shouldHighlight);
    }

    public onRowOfObjectsAdd(): void {
        this.threeJsEditorComponent.drawFreeshape(ObjectTypeEnum.row);
        this.freeShapeDrawing = true;
    }

    public onObjectsInLineClick(tool: Tool): void {
        if (tool.enabled) {
            this.onRowOfObjectsAdd();
            return;
        }

        this.removeLastUnfinishedFreeShape();
    }

    public onAddGreenObjects() {
        this.threeJsEditorComponent.drawFreeshape(ObjectTypeEnum.multiple);
        this.freeShapeDrawing = true;
    }

    public onGenerateGreenObjectsClick(tool: Tool): void {
        if (tool.enabled) {
            this.onAddGreenObjects();
            return;
        }
        this.removeLastUnfinishedFreeShape();
    }


    public onMultiple3DObjectAdd(object: Emitted3DObject) {
        if (object) {
            this.threeJsEditorComponent.addOnClickObject =
                object.project3DModel;
        } else {
            this.menuService.objectToAddOnClick = null;
            this.threeJsEditorComponent.addOnClickObject = null;
        }
    }

    public onToolChange(tool: Tool): void {
        if (tool.toolName === ToolNameEnum.multipleSelection) {
            this.onMultipleSelectionToolStateChange(tool.enabled);
        }
        this.currentlyEnabledTool = tool;
    }

    public onMultipleSelectionToolStateChange(enabled: boolean): void {
        if (enabled) {
            this.threeJsEditorComponent.enableMultiSelect();
        } else {
            this.threeJsEditorComponent.removeControlsFromObject(true);
        }
    }

    public onToolDisable(): void {
        this.currentlyEnabledTool = null;
    }

    public onProjectPriceChange(price: number): void {
        this.availableBudget = price;
    }


    public removeUpload(objectType: UploadedObjectTypeEnum): void {
        if (!this.customObjectUpload || !this.undergroundUpload || !this.fixedObjectUpload) {
            // this switch the tab from 3d menu to upload section, so customObjectUpload  and undergroundUpload will be initialized
            this.navigateToUploadTab();
        }

        if (objectType === UploadedObjectTypeEnum.customObject) {
            this.customObjectUpload?.removeFile();
        } else if (objectType === UploadedObjectTypeEnum.fixedDesign) {
            this.fixedObjectUpload?.removeFile();
        } else if (objectType === UploadedObjectTypeEnum.underground) {
            this.switchOpacity();
            this.undergroundUpload?.removeFile();
        }
    }

    public navigateToUploadTab(): void {
        this.restoreDragComponentsInfo();
    }

    public isProjectLoaded() {
        return this.project;
    }

    public isProjectEnded() {
        return this.project && this.project.ended;
    }

    public get toolingActions(): typeof ToolingActionsEnum {
        return ToolingActionsEnum;
    }

    public get threeStateEnum(): typeof ThreeStateEnum {
        return ThreeStateEnum;
    }

    public callToolingAction(toolingAction: ToolingActionsEnum) {
        this.toolingService.callToolingAction(toolingAction);
    }

    public onFinishUploadCustomObject() {
        this.threeJsEditorComponent.finishUploadCustomObject();
    }

    public onMultipleSelectionStateChange(tool: Tool): void {
        if (tool.enabled) {
            this.onMultipleSelectionToolStateChange(true);
            this.open3DNotificationSnackbar(
                this.authService,
                this.translateService,
                'userSettings.dragMessage'
            );
            return;
        }

        this.onMultipleSelectionToolStateChange(false);
        this.removeLastUnfinishedFreeShape();
    }

    private clearProject3DFromService(type: UploadedObjectTypeEnum): void {
        if (type === UploadedObjectTypeEnum.customObject) {
            this.fileUploadService.customObjectProject3DUpload = null;
        } else if (type === UploadedObjectTypeEnum.underground) {
            this.fileUploadService.undergroundProject3DUpload = null;
            this.switchOpacity();
        } else {
            this.fileUploadService.fixedObjectProject3DUpload = null;
        }
    }


    private subscribeToEditMode(): void {
        this.editModeSubscription = this.router.events.subscribe(() => {
            this.toolingVisibility.editMode = true;
        });
    }

    private restoreDragComponentsInfo(): void {
        if (this.fileUploadService.currentCustomObjectFile) {
            this.customObjectUpload.uploadedFile =
                this.fileUploadService.currentCustomObjectFile;
        }
        if (this.fileUploadService.currentUndergroundFile) {
            this.undergroundUpload.uploadedFile =
                this.fileUploadService.currentUndergroundFile;
        }
    }

    private emitLastAddedObject(
        addedObject: Project3dModel,
        isDragged: boolean
    ) {
        const emitted3DObject: Emitted3DObject = new Emitted3DObject(
            addedObject,
            isDragged
        );
        this.onObject3DAdd(emitted3DObject);
    }


    private isFreeObject(clientObjectId: number): boolean {
        return clientObjectId >= 1001 && clientObjectId <= 1008;
    }

    private addSpecificPathObjects(data: PathObject[]) {
        if (!data) {
            return;
        }

        if (this.userObjects && this.userObjects.length > 0) {
            this.userObjects = this.userObjects.concat(data);
        } else {
            this.userObjects = data;
        }
        this.calculateAvailableBudget(data);
        this.is3dReady = true;
    }

    @HostListener('window:orientationchange', ['$event'])
    private onOrientationChange(event: Event) {
        const shepherd = this.shepherdTourService.shepherdServiceInstance;
        const currentStepId = shepherd.tourObject.getCurrentStep().id;
        shepherd.show(currentStepId);
    }


    private subscribeToToolingVisibility() {
        this.toolingVisibilitySubscription =
            this.toolingService.toolingVisibilityObservable.subscribe(
                (data) => {
                    this.toolingVisibility = data;
                }
            );
    }

    private subscribeToStore(): void {
        this.storeSubscription = this.store.pipe().subscribe((data) => {
            this.isRedoEnabled = data.store.future?.length > 0;
            this.isUndoEnabled = data.store.previous?.length > 0;
        });
    }

    private subscribeToButtonClickedEvent(): void {
        this.buttonSubcription = this.menuService.buttonClickedObservable$.subscribe((data) => {
            this.checkAction(data);
        });
    }

    private subscribeToActionsFinished(): void {
        this.actionFinishedSubscription = this.menuService.actionFinishedObservable$.subscribe((data) => {
            this.activeButton = null;
        });
    }

    private checkIfShouldShowMenu(): boolean {
        if (this.fileUploadService.isUndergroundUploaded && this.fileUploadService.isCustomObjectUploaded) {
            this.showToaster('warning', 'info', 'errors.navigateToMenu', 20000);
            this.shouldShowUploadMenu = true;
            return false;
        }
        return true;
    }

    private toggleUpload(tool: Tool): void {
        this.shouldShowUploadMenu = tool.enabled;
    }

    private toggleFreeShape(tool: Tool): void {
        this.shouldDisplayFreeshapeMenu = tool.enabled;
    }

    private navigateBack() {
        this._location.back();
    }

    private toggleObjectsMenu(tool: Tool): void {
        if (!tool.enabled || !this.checkIfShouldShowMenu()) {
            return;
        }
        this.menuService.shouldShowMenu = true;
        this.sideMenu.activeItem = this.objects[1];
    }

    private resetActions(): void {
        this.removeLastUnfinishedFreeShape();
        this.shouldShowUploadMenu = false;
        this.shouldDisplayFreeshapeMenu = false;
        this.menuService.shouldShowMenu = false;
    }

    protected open3DNotificationSnackbar(
        authService: AuthService,
        translateService: TranslateService,
        message: string
    ): void {
        if (authService.getUserSettings().show3DNotifications) {
            this.customToasterService.openCustomToaster(
                NotificationToasterComponent,
                '3d_rotation',
                'notification',
                translateService.instant(message),
                0
            );
        }
    }

}
