import { Component } from '@angular/core';

@Component({
    selector: 'furban-gdpr',
    templateUrl: './gdpr.component.html',
    styleUrls: ['./gdpr.component.scss'],
})
export class GdprComponent {

}
