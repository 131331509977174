export const navigationConstants = {
    superadminGenerateMunicipality:
        'navigation.superadmin.generateMunicipality',
    superadminGenerateAdmin: 'navigation.superadmin.generateAdmin',
    superadminBackground: 'navigation.superadmin.background',
    superadminQuestions: 'navigation.superadmin.questions',
    notifications: 'navigation.superadmin.createNotifications',
    superadminSupliers: 'navigation.superadmin.suppliers',
    superadminRequests: 'navigation.superadmin.requests',
    dashboard: 'navigation.dashboard',
    adminCodes: 'navigation.admin.codes',
    adminPermitRequests: 'navigation.admin.permitRequests',
    citizenStatistics: 'navigation.citizen.statistic',
    citizenDesign: 'navigation.citizen.design',
    citizenProposals: 'navigation.citizen.proposals',
    citizenChat: 'navigation.citizen.chat',
    citizenHelp: 'navigation.citizen.help',
    pioneerPermit: 'navigation.pioneer.permit',
};
