import { FormControl, FormGroup } from '@angular/forms';

export class ControlsUtil {
    public static hasControlMaxError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['max']);
    }

    public static hasControlMinError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['min']);
    }

    public static hasControlMinLengthError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['minlength']);
    }

    public static hasControlMaxLengthError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['maxlength']);
    }

    public static hasControlRequiredError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['required']);
    }

    public static hasControlIsHtmlError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['isHTML']);
    }

    public static hasControlWhitespacesError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['whitespace']);
    }

    public static hasControlEmailError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['email']);
    }

    public static hasControlDuplicateError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['isDuplicate']);
    }

    public static hasControlDatePassedError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['datePassed']);
    }

    public static hasControlIsCodeNamedError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['isCodeName']);
    }

    public static hasControlMunicipalityNotSelectedError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['municipalityNotSelected']);
    }

    public static hasControlBothEmptyError(controlName: string, form: FormGroup): boolean {
        return form && (form.get(controlName)?.errors?.['bothEmpty']);
    }

    public static hasControlError(control: FormControl, errorType: string): boolean {
        return control && control.errors && control.errors[errorType];
    }

}
