import { Component, Input } from '@angular/core';
import { SvgImageEnum } from '../_enum/svg-image.enum';

@Component({
    selector: 'furban-svg-container',
    templateUrl: './svg-container.component.html',
    styleUrls: ['./svg-container.component.scss'],
})
export class SvgContainerComponent {
    @Input() public svgImage: string;

    public get svgImageType(): typeof SvgImageEnum {
        return SvgImageEnum;
    }

}
