<div class="main-container">
    <div class="flex-row-spaced" [style.margin-bottom.px]="8">
        <p>{{ 'designProposals.quickViewTitle' | translate }}</p>
        <button class="ghost-button" (click)="toggleQuickView()">
            <span class="material-icons"> close </span>
        </button>
    </div>

    <ng-container *ngIf="isDpView">
        <div class="quick-view-container" (scroll)="onScroll($event)">
            <furban-design-proposal-card
                *ngFor="let dp of designProposals; let i = index"
                [designProposal]="dp"
                [index]="i"
                [project]="project"
                [disabledActions]="disabledActions"
                [viewContainerRef]="viewContainerRef"
                [isCollaborativeProject]="isCollaborativeProject"
                (cardClicked)="changeDp(dp)"
                (commentDialogOpened)="openCommentDialog($event)"
            >
            </furban-design-proposal-card>
        </div>
    </ng-container>

    <ng-container *ngIf="isCommentsView">
        <furban-comments
            #comments
            class="comments"
            [dp]="currentDp"
            [type]="1"
            [isDialog]="false"
            [project]="project"
            [parentViewContainerRef]="viewContainerRef"
            (reportOutput)="report($event)"
        >
        </furban-comments>
    </ng-container>
</div>
