import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    ViewContainerRef,
} from '@angular/core';
import { ObjCategoriesMenuComponent } from '../../shared/obj-categories-menu/obj-categories-menu.component';
import { ActivatedRoute } from '@angular/router';

import {
    ColorsDefaultValuesEnum,
    getHouseInformationsRequest,
    House,
    HouseFormValuesEnum,
    HouseService,
    MenuService,
    PermitCategoryObject,
    PermitStore,
    PermitThreeService,
    restoreInitialStatePermit,
    Roof,
    selectActualPermitState,
    StepperService,
    updateHouseInformationsRequest,
} from '@furban/utilities';
import { NgrxPermitEditorComponent } from '../ngrx-permit-editor/ngrx-permit-editor.component';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { HouseDrawingFormComponent } from '../house-drawing-form/house-drawing-form.component';

@Component({
    selector: 'furban-house-preview',
    templateUrl: './house-preview.component.html',
    styleUrls: ['./house-preview.component.scss'],
})
export class HousePreviewComponent implements OnInit, OnDestroy {
    @ViewChild('permitThreeJs')
    private permitThreeJs: NgrxPermitEditorComponent;

    @ViewChild('permitMenu')
    private permitMenu: ObjCategoriesMenuComponent;

    @ViewChild('permitForm')
    private permitHouseForm: HouseDrawingFormComponent;

    public objectsCategory: PermitCategoryObject[];
    public house: House;
    public isMaterialLoaded = false;
    private isClone: boolean;
    private storeSubscription: Subscription;

    constructor(
        protected viewContainerRef: ViewContainerRef,
        private menuService: MenuService,
        private route: ActivatedRoute,
        private houseService: HouseService,
        private permitThreeService: PermitThreeService,
        private store: Store<{ store: PermitStore }>,
        private stepperService: StepperService
    ) { }

    ngOnInit(): void {
        this.subscribeToStore();
        this.initialSetup();

        this.menuService.getPermitObjectsOrdered().subscribe((data) => {
            this.objectsCategory = data;
        });
        this.getAllMaterials();
    }

    public isViewMode(): boolean {
        return this.stepperService.currentStep.isCompleted() && !this.isClone;
    }

    private initialSetup(): void {
        this.route.data.subscribe((data) => {
            this.isClone = data['isClone'];
            this.dispatchGetHouseRequest(
                this.stepperService.projectId,
                this.isClone
            );
        });
    }

    public onHouseFormChanged(house: House): void {
        this.permitThreeJs.updateHouseValues(house);
    }

    public onDiscardSelectionFromMenu(): void {
        this.permitMenu.removeItemSelection();
    }

    public ngOnDestroy(): void {
        this.storeSubscription.unsubscribe();
        this.dispatchResetStore();
        this.permitThreeService.house = null;
    }

    private setupHouse(data: House): void {
        this.house = data;
        if (
            this.permitThreeService.house &&
            this.permitThreeService.house.isClone === data.isClone
        ) {
            this.permitThreeJs?.updateHouseView(data);
        }
        this.permitThreeService.house = House.copyHouse(data);
        this.permitThreeService.house.processedCoordinatesForThree.pop();
        this.addDefaultDataForHouseIfMissing();
    }

    private addDefaultDataForHouseIfMissing(): void {
        let shouldDispatchHouseSave = false;

        if (
            !this.permitThreeService.house.roof ||
            !this.permitThreeService.house.houseMaterial
        ) {
            this.setDefaultMaterialsAndColors();
            shouldDispatchHouseSave = true;
        }
        if (
            !this.permitThreeService.house.floorHeight ||
            this.permitThreeService.house.floorHeight < 200
        ) {
            this.permitThreeService.house.floorHeight =
                HouseFormValuesEnum.floorHeightDefault;
            shouldDispatchHouseSave = true;
        }

        if (
            !this.permitThreeService.house.numberOfFloors ||
            this.permitThreeService.house.numberOfFloors < 1
        ) {
            this.permitThreeService.house.numberOfFloors =
                HouseFormValuesEnum.numberOfFloorsDefault;
            shouldDispatchHouseSave = true;
        }
        if (!shouldDispatchHouseSave) {
            return;
        }

        const houseToSave = this.houseService.resetCoordinatesForBackendCall(
            this.permitThreeService.house
        );

        this.dispatchUpdateHouseRequest(houseToSave, false);
    }

    private setDefaultMaterialsAndColors(): void {
        if (!this.permitThreeService.house.houseMaterial) {
            this.permitThreeService.house.houseMaterial =
                this.houseService.houseMaterials[0];
        }

        if (!this.permitThreeService.house.houseColor) {
            this.permitThreeService.house.houseColor =
                ColorsDefaultValuesEnum.defaultHouseColor;
        }

        if (
            !this.permitThreeService.house.roof ||
            !this.permitThreeService.house.roof.roofMaterial ||
            !this.permitThreeService.house.roof.roofColor
        ) {
            this.permitThreeService.house.roof = new Roof();
            this.permitThreeService.house.roof.roofMaterial =
                this.houseService.roofMaterials[0];
            this.permitThreeService.house.roof.roofColor =
                ColorsDefaultValuesEnum.defaultRoofColor;
        }
    }

    private getAllMaterials(): void {
        if (
            !this.houseService.roofMaterials ||
            !this.houseService.houseMaterials
        ) {
            this.houseService.getAllMaterials().subscribe(() => {
                this.isMaterialLoaded = true;
            });
        } else {
            this.isMaterialLoaded = true;
        }
    }

    private subscribeToStore(): void {
        this.storeSubscription = this.store
            .pipe(
                map((state) => selectActualPermitState(state['permit'].store))
            )
            .subscribe((data) => {
                if (!data.shouldUpdateHouse || data.isRequestAction) {
                    return;
                }
                this.setupHouse(data.houseInfo);
            });
    }

    private dispatchGetHouseRequest(projectId: string, isClone: boolean): void {
        this.store.dispatch(
            getHouseInformationsRequest({
                projectId: projectId,
                isClone: isClone,
            })
        );
    }

    private dispatchUpdateHouseRequest(
        house: House,
        shouldUpdate: boolean
    ): void {
        this.store.dispatch(
            updateHouseInformationsRequest({
                house: house,
                shouldUpdatePrevious: shouldUpdate,
            })
        );
    }

    private dispatchResetStore(): void {
        this.store.dispatch(restoreInitialStatePermit());
    }
}
