import {
    trigger,
    transition,
    query,
    style,
    animate,
    stagger,
} from '@angular/animations';

export const dashboardListAnimations = trigger('listStagger', [
    transition('* <=> *', [
        query(
            ':enter',
            [
                style({ opacity: 0, transform: 'translateY(-20px)' }),
                stagger(
                    '150ms',
                    animate(
                        '550ms ease-out',
                        style({ opacity: 1, transform: 'translateY(0px)' })
                    )
                ),
            ],
            { optional: true }
        ),
    ]),
]);
