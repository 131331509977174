import { Component, Inject, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
    CustomToasterComponent,
    CustomToasterService,
} from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-embeded-popup',
    templateUrl: './embeded-popup.component.html',
    styleUrls: ['./embeded-popup.component.scss'],
})
export class EmbededPopupComponent {
    public htmlText: string;
    public parentViewContainerRef: ViewContainerRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<EmbededPopupComponent>,
        private customToasterService: CustomToasterService,
        private translateService: TranslateService
    ) {
        this.htmlText = data.iframeText;
    }

    public onCopyClipboard(): void {
        this.dialogRef.close();
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            this.translateService.instant(
                'admin.projectOverview.embededMessage'
            ),
            3000
        );
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }
}
