import { Component, OnInit, Input } from '@angular/core';
import { Card } from '../_models/card';

@Component({
    selector: 'furban-app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
    @Input() card: Card;
    @Input() backgroundImage?: string;
    @Input() disabled?: boolean;

    ngOnInit() {
        if (this.backgroundImage) {
            this.backgroundImage = "url('" + this.backgroundImage + "')";
        } else {
            this.backgroundImage = this.card.background;
        }
    }
}
