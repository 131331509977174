import { TourNameEnum } from './../_enum/tour-name.enum';
import { RIGHT_MENU_TOUR_ITEMS } from './../_constants/design-tour-items';
import { FurbanUtil } from './../helpers/furbanUtil';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter, Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { Project3dModel } from '../_models/project-3d-model';
import { AuthService } from './auth.service';
import {
    DesignTourSteps,
    DashboardTourSteps,
    CollaboratorTourSteps,
} from '../_constants/tour-steps';
import { DefaultStepOptions } from '../_constants/tour-step-options';
import { TourEvent } from '../_enum/tour-event.enum';
import { Project } from '../_models/project';
import { ObjectTypeEnum } from '../_enum/object-type-enum';
import { ThreeUtils } from '../_three-helpers/three.utils';
import { ProjectStorageService } from './project-storage.service';

@Injectable({
    providedIn: 'root',
})
export class ShepherdTourService {
    public tourStepChangedEmitter: EventEmitter<string> = new EventEmitter();
    public customObjectForTour: Project3dModel;
    private cancelTourEvent = this.cancelTour.bind(this);

    public get shepherdServiceInstance(): ShepherdService {
        return this.shepherdService;
    }

    constructor(
        private shepherdService: ShepherdService,
        private authService: AuthService,
        private projectStorageService: ProjectStorageService,
        private translateService: TranslateService
    ) { }

    private setCommonPropertiesToTour(): void {
        this.shepherdService.defaultStepOptions = DefaultStepOptions;
        this.shepherdService.modal = true;
        this.shepherdService.confirmCancel = false;
    }

    private cancelTour(): void {
        this.tourStepChangedEmitter.emit(TourEvent.endEvent);
        localStorage.setItem('has-' + this.shepherdService.tourName, 'true');
    }

    public startTour(
        tourName: string,
        allowCitizensDesign: boolean = false,
        isProjectPresent: boolean = false
    ): void {
        this.shepherdService.tourName = tourName;
        this.setCommonPropertiesToTour();
        const steps =
            tourName === TourNameEnum.designTour
                ? DesignTourSteps.createSteps(
                    this.translateService,
                    FurbanUtil.isMobile(),
                    this.tourStepChangedEmitter
                )
                : DashboardTourSteps.createSteps(
                    this.authService,
                    this.translateService,
                    window.innerWidth,
                    !!this.projectStorageService.curentProject || isProjectPresent,
                    allowCitizensDesign
                );
        this.shepherdService.addSteps(steps);
        this.shepherdService.tourObject.start();
        this.addCancelingEventsHandler();
    }

    public startCollaboratorTour(project: Project): void {
        this.shepherdService.tourName = TourNameEnum.collaboratorTour;
        this.setCommonPropertiesToTour();
        const steps = CollaboratorTourSteps.createSteps(
            this.translateService,
            FurbanUtil.isMobile(),
            this.tourStepChangedEmitter,
            project
        );
        this.shepherdService.addSteps(steps);
        this.shepherdService.tourObject.start();
        this.addCancelingEventsHandler();
    }

    private addCancelingEventsHandler(): void {
        this.shepherdService.tourObject.on('cancel', this.cancelTourEvent);
        this.shepherdService.tourObject.on('complete', this.cancelTourEvent);
    }

    public setCustomObjectForTour(projectModel: Project3dModel): void {
        this.customObjectForTour = projectModel;
        this.customObjectForTour.freeshapePoints = JSON.stringify(
            ThreeUtils.customHouseDefaultDim
        );
        this.customObjectForTour.objectType = ObjectTypeEnum.custom;
    }
}
