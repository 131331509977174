import { Component, Input, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
	AuthService,
	FurbanUtil,
	Poll,
	ModalManager,
	Project,
} from '@furban/utilities';
import { AdminPollService } from '../../municipality/admin/admin-poll/admin-poll.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AdminPollPopupComponent } from '../../municipality/admin/admin-poll-popup/admin-poll-popup.component';
import { PollStatus } from '../_enums/poll-status.enum';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
	selector: 'furban-polls',
	templateUrl: './polls.component.html',
	styleUrls: ['./polls.component.scss'],
})
export class PollsComponent {
	@Input() polls: Poll[];
	@Input() currentProject: Project;

	@Output() noPollEvent = new EventEmitter<void>();

	public isDatePassed = FurbanUtil.isDatePassed;
	public calculateDays = FurbanUtil.calculateDays;

	constructor(
		private pollService: AdminPollService,
		private viewContainerRef: ViewContainerRef,
		private translateService: TranslateService,
		private authService: AuthService,
		private modal: ModalManager,
		private dialog: MatDialog
	) { }

	public get translateServiceInstance(): TranslateService {
		return this.translateService;
	}

	public getClassNameByStatus(poll: Poll): string {
		return this.isDatePassed(poll.endDate) ? 'inactive' : poll.isEnabled ? 'active' : 'ended';
	}

	public isAdmin(): boolean {
		return this.authService.hasAdministrativeRole();
	}

	public showRemainingDays(poll): boolean {
		return !this.isDatePassed(poll.endDate) && poll.isEnabled && this.isAdmin();
	}

	public checkIconByStatus(poll: Poll): string {
		const status = this.getPollStatus(poll);
		switch (status) {
			case PollStatus.ACTIVE:
				return 'visibility';
			case PollStatus.INACTIVE:
				return 'visibility_off'
			case PollStatus.ENDED:
				return 'timer';
			default:
				return;
		}
	}

	public getPollStatus(poll: Poll): PollStatus {
		if (!poll.isEnabled) {
			return PollStatus.INACTIVE;
		}
		if (this.isDatePassed(poll.endDate)) {
			return PollStatus.ENDED;
		} else {
			return PollStatus.ACTIVE;
		}
	}

	public calculateRemainingDays(poll: Poll): string {
		return this.calculateDays(poll.endDate) === 1
			? this.translateService.instant('admin.poll.remainingDay', {
				noOfDays: this.calculateDays(poll.endDate),
			})
			: this.translateService.instant('admin.poll.remainingDays', {
				noOfDays: this.calculateDays(poll.endDate),
			});
	}

	public togglePollState(poll: Poll): void {
		const action = poll.isEnabled ? 'admin.poll.deactivateWarning' : 'admin.poll.enableWarning';
		this.modal.showModal(this.viewContainerRef,
			ModalManager.createConfiguration(
				'errors.warning',
				action,
				'generic.yesBtn',
				'generic.noBtn'
			)
		)
			.subscribe((res) => {
				if (res) {
					poll.isEnabled = !poll.isEnabled;
					this.savePoll(poll);
				}
			});
	}

	public deletePoll(poll: Poll): void {
		this.modal.showModal(this.viewContainerRef,
			ModalManager.createConfiguration(
				'errors.warning',
				'admin.poll.deleteWarning',
				'generic.yesBtn',
				'generic.noBtn'
			)
		)
			.subscribe((res) => {
				if (res) {
					poll.isActive = false;
					this.savePoll(poll);
				}
			});
	}


	public getFilteredPolls(): Poll[] {
		if (!this.isAdmin())
			return this.polls?.filter((poll) => {
				const citizenPolls = this.isDatePassed(poll.endDate) || (poll.isEnabled && !this.isDatePassed(poll.endDate));
				return citizenPolls;
			});
		return this.polls;
	}


	public openCreatePollModal(poll?: Poll): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.width = '540px';
		dialogConfig.data = {
			poll: poll,
			projectId: this.currentProject.projectId,
		};

		const groupDialog = this.dialog.open(AdminPollPopupComponent, dialogConfig);
		groupDialog.componentInstance.parentViewContainerRef =
			this.viewContainerRef;
		groupDialog.componentInstance.onModalClose.subscribe((data) => {
			this.getAllPolls(this.currentProject.projectId);
		});
	}


	private savePoll(poll: Poll): void {
		this.pollService.savePoll(poll).subscribe((data) => {
			if (data) {
				this.getAllPolls(this.currentProject.projectId);
			}
		});
	}


	private getAllPolls(projectId: string): void {
		this.pollService.getPolls(projectId).subscribe((data) => {
			if (data) {
				this.polls = data;

				if (this.polls.length === 0) {
					this.noPollEvent.emit();
				}
			}
		});
	}
}
