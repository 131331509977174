<ng-container *ngIf="profileContent && profileContent === 'profile'">
    <div class="important-space-between" mat-dialog-title>
        <h2>{{ 'admin.profile.displayNameAndPhotoLabel' | translate }}</h2>
        <button class="ghost-button" (click)="closeModal()">
            <span class="material-icons">close</span>
        </button>
    </div>

    <mat-dialog-content>
        <form #changePassForm="ngForm" name="changeNameForm" class="flex-row-start full-width" role="form"
            [formGroup]="changeNameCheckForm" (ngSubmit)="changeName()">
            <div class="profile-image custom-image background-center">
                <furban-avatar-picture [name]="userProfile.screenName" [size]="160" [mediaId]="imageData">
                </furban-avatar-picture>
                <div class="overlay-image" (click)="uploadImage()">
                    <span class="material-icons"> add_a_photo </span>
                    <a>{{ 'admin.profile.uploadPic' | translate }}</a>
                </div>
            </div>

            <mat-form-field class="full-width" [style.margin-left.px]="20" appearance="outline">
                <mat-label>
                    {{ 'admin.profile.name' | translate }}
                </mat-label>
                <input required matInput placeholder="{{ 'admin.profile.name' | translate }}" formControlName="name"
                    value="{{ userProfile.screenName }}" />
                <mat-error *ngIf="hasControlRequiredError('name',changeNameCheckForm)">
                    {{ 'admin.profile.required' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlMaxLengthError('name',changeNameCheckForm)">
                    {{ 'admin.profile.maxLength' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlIsHtmlError('name',changeNameCheckForm)">
                    {{ 'validators.noHTML' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlWhitespacesError('name',changeNameCheckForm)">
                    {{ 'validators.noWhiteSpaces' | translate }}
                </mat-error>
            </mat-form-field>
        </form>
        <div class="flex-row-start padding-20-0">
            <furban-user-settings class="full-width" #userSettingsComponent [userProfile]="userProfile">
            </furban-user-settings>
        </div>
    </mat-dialog-content>

    <div class="buttons-right-container" [style.margin-top.px]="20">
        <ng-container>
            <button class="secondary-button" (click)="closeModal()">
                <h6>{{ 'generic.cancelBtn' | translate }}</h6>
            </button>
            <button class="primary-button" (click)="changeName()">
                <h6>{{ 'user.profile.okBtn' | translate }}</h6>
            </button>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="profileContent && profileContent === 'password'">
    <div class="important-space-between" mat-dialog-title>
        <h2>
            {{ 'admin.profile.passwordLabel' | translate }}
        </h2>
        <button class="ghost-button" (click)="closeModal()">
            <span class="material-icons">close</span>
        </button>
    </div>

    <mat-dialog-content>
        <form #changePassForm="ngForm" name="changePassForm" class="flex-column-end" role="form"
            [formGroup]="changePassCheckForm" (ngSubmit)="changePassword()">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>
                    {{ 'admin.profile.oldPass' | translate }}
                </mat-label>
                <input required matInput placeholder="{{ 'admin.profile.oldPass' | translate }}"
                    formControlName="oldPasswordFormControl" type="password" />
                <mat-error *ngIf="hasControlRequiredError('oldPasswordFormControl',changePassCheckForm)">
                    {{ 'admin.profile.required' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
                <mat-label>
                    {{ 'admin.profile.newPass' | translate }}
                </mat-label>
                <input required matInput placeholder="{{ 'admin.profile.newPass' | translate }}"
                    formControlName="passwordFormControl" type="password" />

                <mat-hint align="end">{{
                    'auth.validPasswordHint' | translate
                    }}</mat-hint>

                <mat-error *ngIf="getPasswordValidationErrors">
                    {{
                    getPasswordErrorMessage(
                    getPasswordValidationErrors,
                    translateService
                    )
                    }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
                <mat-label>
                    {{ 'admin.profile.newPassConf' | translate }}
                </mat-label>
                <input required matInput placeholder="{{ 'admin.profile.newPassConf' | translate }}"
                    formControlName="passwordConfirmFormControl" type="password" [errorStateMatcher]="errorMatcher" />
                <mat-error *ngIf="hasControlRequiredError('passwordConfirmFormControl',changePassCheckForm)">
                    {{ 'admin.profile.required' | translate }}
                </mat-error>
                <mat-error *ngIf="changePassCheckForm.hasError('passwordMatches')">
                    {{ 'admin.profile.samePass' | translate }}
                </mat-error>
            </mat-form-field>
        </form>
    </mat-dialog-content>

    <div class="buttons-right-container" [style.margin-top.px]="20">
        <ng-container>
            <button class="secondary-button" (click)="closeModal()">
                <h6>{{ 'generic.cancelBtn' | translate }}</h6>
            </button>
            <button class="primary-button" (click)="changePassword()">
                <h6>{{ 'user.profile.okBtn' | translate }}</h6>
            </button>
        </ng-container>
    </div>
</ng-container>