import { PerspectiveCamera, Scene, Color, DirectionalLight } from 'three';
import { ViewPortDimension } from './permit-viewport-dimension';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export class PermitView {
    public index: number;
    public viewPortDimension: ViewPortDimension;
    public background: Color;
    public eye: number[] = [-25, 20, 15];
    public up: number[] = [0, 1, 0];
    public fov = 45;
    public camera: PerspectiveCamera = null;
    public scene: Scene = null;
    public controls: OrbitControls;
    public directionalLight: DirectionalLight;

    constructor(index: number, viewPortDimension: ViewPortDimension, background: Color) {
        this.index = index;
        this.viewPortDimension = viewPortDimension;
        this.background = background;
    }


}