<div class="flex-row-start">
    <div [ngClass]="['icon-container flex-row-center', data.type]">
        <span class="material-icons">{{ data.icon }}</span>
    </div>
    <section class="flex-column-center">
        <p>{{ data.message }}</p>
        <div class="flex-row-spaced full-width">
            <mat-slide-toggle [(ngModel)]="hideNotifications">
                {{ 'userSettings.hideNotifications' | translate }}
            </mat-slide-toggle>
            <button class="secondary-button" (click)="dismiss()">
                <h6>{{ data.action }}</h6>
            </button>
        </div>
    </section>
</div>
