<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'admin.manageGroups.generateCodes' | translate }}</h2>
    <button class="ghost-button" (click)="closePopup()">
        <span class="material-icons">close</span>
    </button>
</div>

<mat-dialog-content>
    <form [formGroup]="codesForm">
        <mat-form-field class="full-width" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'admin.groups.noOfCitizens' | translate }}
            </mat-label>
            <input type="number" name="inputNumber" formControlName="citizensCount" matInput
                placeholder="{{ 'admin.groups.noOfCitizens' | translate }}" />
            <mat-error *ngIf="hasControlMaxError('citizensCount',codesForm)">
                {{ 'admin.groups.maxCitizen' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlMinError('citizensCount',codesForm)">
                {{ 'admin.groups.negativaValidation' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" [class.disabled]="expertsCountOnAllProjects >= 5" appearance="outline">
            <mat-label>
                {{ 'admin.groups.noOfExperts' | translate }}
            </mat-label>
            <input type="number" name="inputNumber" formControlName="expertsCount" matInput
                placeholder="{{ 'admin.groups.noOfExperts' | translate }}"
                [readonly]="expertsCountOnAllProjects >= 5" />
            <mat-error *ngIf="hasControlMaxError('expertsCount',codesForm)">
                {{
                'admin.groups.maxExpertCodes'
                | translate
                : { noOfExperts: 5 - expertsCountOnAllProjects }
                }}
            </mat-error>
            <mat-error *ngIf="hasControlMinError('expertsCount',codesForm)">
                {{ 'admin.groups.negativaValidation' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-error *ngIf="hasControlBothEmptyError('bothEmpty', codesForm)" class="form-error">
            {{ 'admin.groups.bothEmpty' | translate }}
        </mat-error>
        <div class="warning-message">
            <h5 [style.margin-bottom.px]="0">
                {{
                'admin.groups.maxExpertsWarrning'
                | translate
                : {
                noOfExperts: expertsCountOnAllProjects,
                remainingNoOfExperts:
                5 - expertsCountOnAllProjects
                }
                }}
            </h5>
        </div>
    </form>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closePopup()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="save()">
        <h6>{{ 'user.profile.okBtn' | translate }}</h6>
    </button>
</div>