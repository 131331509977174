import {
    Component,
    OnInit,
    ViewChild,
    EventEmitter,
    ViewContainerRef,
    Input,
} from '@angular/core';
import { ImageCropperComponent, CropperSettings } from 'ngx-img-cropper';
import { ImageUploadService } from './image-upload.service';

import {
    ModalManager,
    FurbanUtil,
    FILE_EXTENSION_FORMAT,
} from '@furban/utilities';

@Component({
    selector: 'furban-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
    @ViewChild('cropper', { static: true }) cropper: ImageCropperComponent;
    @Input() public parentRef: ViewContainerRef;
    @Input() public imageWidth: number;
    @Input() public imageHeight: number;
    @Input() public imageUploadPath: string;
    @Input() public imageToCrop: HTMLImageElement;
    public imageModified: boolean;
    public fileName: string;
    public data: any;
    public cropperSettings: CropperSettings;
    public croppedWidth: number;
    public croppedHeight: number;
    public onImageUploadClose = new EventEmitter();
    public fileExtentionFormat = FILE_EXTENSION_FORMAT;

    private imageId;
    private lastImage;

    constructor(
        private imageUploadService: ImageUploadService,
        private modal: ModalManager
    ) { }

    ngOnInit() {
        this.cropperSettings = new CropperSettings();
        this.cropperSettings.width = this.imageWidth;
        this.cropperSettings.height = this.imageHeight;

        this.cropperSettings.croppedWidth = this.imageWidth;
        this.cropperSettings.croppedHeight = this.imageHeight;

        this.cropperSettings.canvasWidth = 400;
        this.cropperSettings.canvasHeight = 400;

        this.cropperSettings.minWidth = 10;
        this.cropperSettings.minHeight = 10;

        this.cropperSettings.keepAspect = true;

        this.cropperSettings.rounded = false;
        this.cropperSettings.noFileInput = true;
        this.cropperSettings.cropperClass = 'profile-cropper-class';
        this.cropperSettings.croppingClass = 'profile-cropping-class';

        this.data = {};

        if (this.imageToCrop) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;
            const tempImage = this.imageToCrop;

            this.cropper.settings = this.cropperSettings;
            tempImage.onload = () => {
                that.cropper.setImage(tempImage);
            };
            this.fileName = 'noFileName.png';
        } else {
            this.uploadImage();
        }
    }

    fileChangeListener($event) {
        const image: any = new Image();
        const file: File = $event.target.files[0];
        this.fileName =
            file.name.length > 124
                ? FurbanUtil.reduceFileNameLength(file.name, 120)
                : file.name;
        const myReader: FileReader = new FileReader();
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        myReader.onloadend = function (loadEvent: any) {
            image.src = loadEvent.target.result;
            image.onload = () => {
                that.cropper.setImage(image);
            };
            that.imageModified = true;
        };

        myReader.readAsDataURL(file);
    }

    uploadImage() {
        this.lastImage = this.data.image;
        setTimeout(() => {
            (<HTMLElement>(
                document.getElementsByClassName('custom-photo-input')[0]
            )).click();
        });
    }

    // REFACTOR
    cropImage() {
        if (this.fileName && this.data && this.data.image) {
            if (this.fileName.length > 124) {
                this.fileName = FurbanUtil.reduceFileNameLength(
                    this.fileName,
                    120
                );
            }

            const parts = this.data.image.split(',');
            this.imageUploadService
                .uploadPhoto(
                    this.data.image.length,
                    this.fileName,
                    parts[1],
                    this.imageUploadService.getContentType(parts[0]),
                    this.imageUploadPath
                )
                .subscribe(
                    {
                        next: (data) => {
                            this.imageId = data;
                            this.onImageUploadClose.emit(this.imageId);
                        },
                        error: (err) => {
                            this.data.image = this.lastImage;

                        }
                    }
                );
        } else {
            this.cancelImageCrop();
            this.modal
                .showModal(
                    this.parentRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'user.profile.selectPhoto'
                    )
                )
                .subscribe();
        }
    }

    cancelImageCrop() {
        this.onImageUploadClose.emit(null);
    }

    closeImageUploadModal() {
        // check if modified to save before close
        this.onImageUploadClose.emit(null);
    }
}
