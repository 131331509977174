import {
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    ThreeUtils,
    HouseSceneConfig,
    PermitAssetNamesEnum,
    PermitThreeService,
    urlConstants,
    PermitComment,
    AuthService,
} from '@furban/utilities';
import * as THREE from 'three';

@Directive({
    selector: '[furbanAddPinCommentEvents]',
})
export class AddPinCommentEventsDirective implements OnDestroy {
    private lastAddedPin: THREE.Group;
    @Input() houseSceneConfig: HouseSceneConfig;
    @Input() permitProjectId: string;
    @Output() updatePinCommentToDisplay: EventEmitter<PermitComment> =
        new EventEmitter();

    private bindMouseDown = this.mouseDownEvent.bind(this);
    private groupsToCheckIntersection: any[];
    private currentPinComment: PermitComment;
    private detachPopupToPinSubscription: Subscription;
    private saveDataToPinSubscription: Subscription;
    private addPinEventsSubscription: Subscription;

    constructor(
        private permitThreeService: PermitThreeService,
        private authService: AuthService
    ) { }

    ngOnDestroy(): void {
        this.removeMouseEventsFromRenderer();
        this.unsubscribeToPopupEvents();
        this.addPinEventsSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.intializeGroupToCheckIntersection();
        this.loadPin();
        this.setupPinPopupOnRenderer();
        this.subscribeToAddPinEvents();
    }

    public addingMouseEventsFromRenderer(): void {
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'pointerdown',
            this.bindMouseDown,
            { passive: false }
        );
    }

    public removeMouseEventsFromRenderer(): void {
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'pointerdown',
            this.bindMouseDown
        );
    }

    private intializeGroupToCheckIntersection(): void {
        const assetGroup = this.houseSceneConfig.scene.getObjectByName(
            PermitAssetNamesEnum.assetGroup
        );
        const roofGroup = this.houseSceneConfig.scene.getObjectByName(
            PermitAssetNamesEnum.roofGroup
        );
        const wallGroup = this.houseSceneConfig.scene.getObjectByName(
            PermitAssetNamesEnum.wallGroup
        );
        const groundGroup = this.houseSceneConfig.scene.getObjectByName(
            PermitAssetNamesEnum.ground
        );
        const roofAssetGroup = this.houseSceneConfig.scene.getObjectByName(
            PermitAssetNamesEnum.roofAssetGroup
        );
        const extensionGroup = this.houseSceneConfig.scene.getObjectByName(
            PermitAssetNamesEnum.extensionGroup
        );

        this.groupsToCheckIntersection = [
            assetGroup,
            roofGroup,
            wallGroup,
            groundGroup,
            roofAssetGroup,
            extensionGroup,
        ];
    }

    private mouseDownEvent(event: MouseEvent): void {
        if (event.button === 2) {
            return;
        }

        const intersections = ThreeUtils.getIntersectionArrayForPermitComment(
            event,
            this.houseSceneConfig,
            this.groupsToCheckIntersection
        );

        const newPinToAdd = this.houseSceneConfig.loadedPin.clone();
        ThreeUtils.cloneMaterial(newPinToAdd);
        ThreeUtils.snapObjectToFaceAndSetPosition(newPinToAdd, intersections);
        newPinToAdd.userData = new Comment();
        this.houseSceneConfig.pinHelper.add(newPinToAdd);

        this.initializeNewCommentForPopup(newPinToAdd);
        newPinToAdd.add(this.houseSceneConfig.pinCommentPopup);

        if (!this.lastAddedPin?.userData?.['commentId']) {
            this.houseSceneConfig.pinHelper.remove(this.lastAddedPin);
        }

        this.lastAddedPin = newPinToAdd;
    }

    private initializeNewCommentForPopup(newPinToAdd: THREE.Object3D): void {
        this.currentPinComment = new PermitComment(
            this.permitProjectId,
            this.authService.userProfile,
            JSON.stringify(newPinToAdd.position),
            JSON.stringify(newPinToAdd.rotation)
        );
        this.updatePinCommentToDisplay.emit(this.currentPinComment);
    }

    private loadPin(): void {
        if (this.houseSceneConfig.loadedPin) {
            return;
        }

        const loadingUrl = urlConstants.pin;
        this.houseSceneConfig.loader.load(loadingUrl, (gltf) => {
            this.houseSceneConfig.loadedPin = gltf.scene;
        });
    }

    private setupPinPopupOnRenderer(): void {
        this.houseSceneConfig.pinCommentPopup =
            ThreeUtils.getCommentPopupHTMLElement();
    }

    private subscribeToPopupEvents(): void {
        this.detachPopupToPinSubscription =
            this.permitThreeService.detachPopupToPinObservable.subscribe(
                (data) => {
                    if (!data) {
                        return;
                    }
                    this.removePopupFromPin();
                }
            );

        this.saveDataToPinSubscription =
            this.permitThreeService.savePinDataObservable.subscribe((data) => {
                if (!data) {
                    return;
                }
                this.saveDataToPin(data);
            });
    }

    private unsubscribeToPopupEvents(): void {
        this.detachPopupToPinSubscription?.unsubscribe();
        this.saveDataToPinSubscription?.unsubscribe();
    }

    private subscribeToAddPinEvents(): void {
        this.addPinEventsSubscription =
            this.permitThreeService.addPinEventsObservable.subscribe((data) => {
                if (data) {
                    this.addingMouseEventsFromRenderer();
                    this.subscribeToPopupEvents();
                } else {
                    this.removeMouseEventsFromRenderer();
                    this.unsubscribeToPopupEvents();
                }
            });
    }

    private removePopupFromPin(): void {
        this.lastAddedPin?.remove(this.houseSceneConfig.pinCommentPopup);
        if (!this.lastAddedPin?.userData?.['commentId']) {
            this.houseSceneConfig.pinHelper.remove(this.lastAddedPin);
        }
    }

    private saveDataToPin(comment: PermitComment): void {
        this.lastAddedPin.remove(this.houseSceneConfig.pinCommentPopup);
        this.lastAddedPin.userData = comment;
    }
}
