<ng-container *ngIf="isAdmin">
    <h3
        [class.link-type]="isAdmin"
        [matMenuTriggerFor]="userAction"
        (click)="getUser()"
    >
        {{ userProfile.screenName }}
    </h3>
</ng-container>

<ng-container *ngIf="!isAdmin">
    <h3>{{ userProfile.screenName }}</h3>
</ng-container>

<mat-menu #userAction="matMenu" overlapTrigger="false">
    <ng-container *ngIf="user">
        <button mat-menu-item (click)="copyCode()" value="copyCode">
            {{
                isRoleAdmin(user)
                    ? ('admin.userMenuActions.copyEmailToClipboard' | translate)
                    : ('admin.userMenuActions.copyCodeClipboard'
                      | translate : { code: user.username })
            }}
        </button>
    </ng-container>
    <ng-container *ngIf="user && !isRoleAdmin(user)">
        <button mat-menu-item (click)="showWarningModal()" value="deleteUser">
            {{ 'admin.userMenuActions.deleteUser' | translate }}
        </button>
    </ng-container>
</mat-menu>
