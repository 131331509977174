import { NgModule } from '@angular/core';
import { SuperadminQuestionAddComponent } from './superadmin-question-add/superadmin-question-add.component';
import { AdminAuthRecoverComponent } from './admin/admin-auth-recover/admin-auth-recover.component';
import { AdminAuthRecoverService } from './admin/admin-auth-recover/admin-auth-recover.service';
import { AdminAuthComponent } from './admin/admin-auth/admin-auth.component';
import { AdminAuthService } from './admin/admin-auth/admin-auth.service';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminNewComponent } from './admin/admin-new/admin-new.component';
import { AdminNewService } from './admin/admin-new/admin-new.service';
import { AdminPollPopupComponent } from './admin/admin-poll-popup/admin-poll-popup.component';
import { AdminPollComponent } from './admin/admin-poll/admin-poll.component';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { BackgroundMediaComponent } from './admin/background-media/background-media.component';
import { BackgroundMediaService } from './admin/background-media/background-media.service';
import { MunicipalityNewComponent } from './admin/municipality-new/municipality-new.component';
import { NotificationModalComponent } from './admin/notification-modal/notification-modal.component';
import { NotificationPanelComponent } from './admin/notification-panel/notification-panel.component';
import { SuperadminAddSupplierComponent } from './admin/superadmin-add-supplier/superadmin-add-supplier.component';
import { SuperadminSuppliersComponent } from './admin/superadmin-suppliers/superadmin-suppliers.component';
import { StatisticsService } from './user/statistics/statistics.service';
import { UserAuthComponent } from './user/user-auth/user-auth.component';
import { UserDashboardComponent } from './user/user-dashboard/user-dashboard.component';
import { CitizenProposalsComponent } from './user/citizen-proposals/citizen-proposals.component';
import { ExpertsModalComponent } from './user/experts-modal/experts-modal.component';
import { ExpertsService } from './user/experts-modal/experts.service';
import { PollComponent } from './user/poll/poll.component';

import { UserLocationInfoPopupComponent } from './user/user-location-info-popup/user-location-info-popup.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { UserProfileService } from './user/user-profile/user-profile.service';
import { UserProjectsComponent } from './user/user-projects/user-projects.component';
import { WinnerCongratulationsComponent } from './user/winner-congratulations/winner-congratulations.component';
import { AddGroupPopupComponent } from './admin/admin-codes/add-group-popup/add-group-popup.component';
import { AddingCodesComponent } from './admin/admin-codes/adding-codes/adding-codes.component';
import { CodesComponent } from './admin/admin-codes/codes/codes.component';
import { CodesService } from '../shared/_services/codes.service';
import { DisplayProjectsPopupComponent } from './admin/admin-codes/display-projects-popup/display-projects-popup.component';
import { GenerateCodesComponent } from './admin/admin-codes/generate-codes/generate-codes.component';
import { GroupsComponent } from './admin/admin-codes/groups/groups.component';
import { GroupsService } from './admin/admin-codes/groups/groups.service';

import { MunicipalityRoutingModule } from './municipality-routing.module';
import { SharedModule } from '../shared/shared.module';
import { UserLoginGuard } from './_guards/user-login.guard';
import { AdminLoginGuard } from './_guards/admin-login.guard';
import { ProjectSharedModule } from '../project-shared/project-shared.module';
import { PublishPopupComponent } from '../project-shared/publish-popup/publish-popup.component';
import { PublishService } from '../project-shared/publish-popup/publish.service';
import { DesignsViewerComponent } from './user/designs-viewer/designs-viewer.component';
import { UserSettingsComponent } from './user/user-settings/user-settings.component';
import { SuperadminRequestsComponent } from './admin/superadmin-requests/superadmin-requests.component';
import { AdminViewPermitComponent } from './admin/admin-view-permit/admin-view-permit.component';

import { UtilitiesModule } from '@furban/utilities';
import { SelectProjectTypeComponent } from './admin/select-project-type/select-project-type.component';
import { DesignViewerCarouselComponent } from './user/design-viewer-carousel/design-viewer-carousel.component';
import { DropdownAutocompleteComponent } from './admin/dropdown-autocomplete/dropdown-autocomplete.component';
import { DpQuickViewComponent } from './user/dp-quick-view/dp-quick-view.component';
import { PermitModule } from '../permit/permit.module';
import { PermitRequestsComponent } from './permit-requests/permit-requests.component';
import { PermitRequestCardComponent } from './permit-requests/permit-request-card/permit-request-card.component';
import { environment } from '../../environments/environment';
import { CodeGroupPopupComponent } from './admin/admin-codes/code-group-popup/code-group-popup.component';
import { SuperadminManageSafetyAreaComponent } from './admin/superadmin-manage-safety-area/superadmin-manage-safety-area.component';

@NgModule({
  declarations: [
    AdminAuthComponent,
    AdminAuthRecoverComponent,
    AdminNewComponent,
    MunicipalityNewComponent,
    AdminProfileComponent,
    AdminDashboardComponent,
    UserDashboardComponent,
    UserProfileComponent,
    UserProjectsComponent,
    UserAuthComponent,
    PublishPopupComponent,
    UserLocationInfoPopupComponent,
    GroupsComponent,
    AddingCodesComponent,
    ExpertsModalComponent,
    AddGroupPopupComponent,
    CodesComponent,
    GenerateCodesComponent,
    SuperadminQuestionAddComponent,
    BackgroundMediaComponent,
    CitizenProposalsComponent,
    WinnerCongratulationsComponent,
    DisplayProjectsPopupComponent,
    AdminPollComponent,
    AdminPollPopupComponent,
    PollComponent,
    NotificationPanelComponent,
    NotificationModalComponent,
    SuperadminSuppliersComponent,
    SuperadminAddSupplierComponent,
    DesignsViewerComponent,
    UserSettingsComponent,
    SuperadminRequestsComponent,
    AdminViewPermitComponent,
    SelectProjectTypeComponent,
    DesignViewerCarouselComponent,
    DropdownAutocompleteComponent,
    DpQuickViewComponent,
    PermitRequestsComponent,
    PermitRequestCardComponent,
    CodeGroupPopupComponent,
    SuperadminManageSafetyAreaComponent,
  ],
  imports: [
    SharedModule,
    MunicipalityRoutingModule,
    ProjectSharedModule,
    UtilitiesModule.forRoot(environment),
    PermitModule,
  ],
  providers: [
    AdminNewService,
    AdminAuthService,
    AdminAuthRecoverService,
    UserProfileService,
    PublishService,
    GroupsService,
    ExpertsService,
    CodesService,
    StatisticsService,
    BackgroundMediaService,
    UserLoginGuard,
    AdminLoginGuard,
  ],
  entryComponents: [
    UserProfileComponent,
    PublishPopupComponent,
    UserLocationInfoPopupComponent,
    AddingCodesComponent,
    ExpertsModalComponent,
    AddGroupPopupComponent,
    GenerateCodesComponent,
    WinnerCongratulationsComponent,
    DisplayProjectsPopupComponent,
    SuperadminQuestionAddComponent,
    BackgroundMediaComponent,
    AdminPollPopupComponent,
    AdminPollComponent,
    NotificationModalComponent,
    SuperadminAddSupplierComponent,
    DropdownAutocompleteComponent,
  ],
})
export class MunicipalityModule {}
