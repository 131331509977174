import {
    Component,
    Input,
    SimpleChanges,
    OnChanges,
} from '@angular/core';

import {
    Project,
    Poll,
    PollOption,
    FurbanUtil,
    PollAnswer,
    AuthService,
} from '@furban/utilities';
import { AdminPollService } from './../../admin/admin-poll/admin-poll.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-poll',
    templateUrl: './poll.component.html',
    styleUrls: ['./poll.component.scss'],
})
export class PollComponent implements OnChanges {
    @Input() public project: Project;

    public changeAction = { left: 'left', right: 'right' };

    public isDatePassed = FurbanUtil.isDatePassed;
    public calculateDays = FurbanUtil.calculateDays;

    public polls: Poll[];
    public currentPollIndex = 0;

    public selectedAnswer: PollAnswer;
    public selectedOption: PollOption;

    constructor(
        private translateService: TranslateService,
        private pollService: AdminPollService,
        private authService: AuthService) { }

    ngOnChanges(changes: SimpleChanges) {
        this.project = changes['project'].currentValue;
        this.getPollForProject(true);
    }

    public get translateServiceInstance(): TranslateService {
        return this.translateService;
    }

    public get hasPolls(): boolean {
        return this.polls?.length > 0 ?? false;
    }

    public showRemainingDays(poll): boolean {
        return !this.isDatePassed(poll.endDate) && poll.isEnabled;
    }

    public isAdmin(): boolean {
        return this.authService.hasAdministrativeRole();
    }

    public calculateRemainingDays(poll: Poll): string {
        return this.calculateDays(poll.endDate) === 1
            ? this.translateService.instant('admin.poll.remainingDay', {
                noOfDays: this.calculateDays(poll.endDate)
            })
            : this.translateService.instant('admin.poll.remainingDays', {
                noOfDays: this.calculateDays(poll.endDate)
            })
    }

    public saveAnswer(selectedOption: PollOption): void {
        if (!selectedOption) {
            return;
        }

        const pollAnswer: PollAnswer = new PollAnswer();
        if (this.selectedAnswer) {
            pollAnswer.pollAnswerId = this.selectedAnswer.pollAnswerId;
        }
        pollAnswer.projectId = this.project.projectId;
        pollAnswer.pollId = selectedOption.pollId;
        pollAnswer.pollOptionId = selectedOption.pollOptionId;
        pollAnswer.isActive = true;

        if (
            this.selectedAnswer &&
            selectedOption.pollOptionId === this.selectedAnswer.pollOptionId
        ) {
            this.deleteAnswer(pollAnswer);
            return;
        }

        this.pollService.saveAnswer(pollAnswer).subscribe((data) => {
            this.selectedAnswer = data;
            this.getPollForProject(false);
        });
    }

    public calculatePercentage = (selectedOption: PollOption): number =>
        FurbanUtil.calculatePercentage(
            selectedOption.pollAnswers.length,
            selectedOption.totalAnswers
        );

    public hasAnswerSelected = (pollOption): boolean =>
        this.selectedAnswer &&
        this.selectedAnswer.pollOptionId === pollOption.pollOptionId;

    public hasOptionOrAnswerSelected = (pollOption): boolean =>
        (this.selectedOption &&
            this.selectedOption.pollOptionId === pollOption.pollOptionId) ||
        (this.selectedAnswer &&
            this.selectedAnswer.pollOptionId === pollOption.pollOptionId);


    public changePoll(direction: string): void {
        if (direction === this.changeAction.left) {
            this.currentPollIndex = (this.currentPollIndex === 0) ? this.polls.length - 1 : this.currentPollIndex - 1;
        } else if (direction === this.changeAction.right) {
            this.currentPollIndex = (this.currentPollIndex === this.polls.length - 1) ? 0 : this.currentPollIndex + 1;
        }

        this.getAnswerByPollId(this.polls[this.currentPollIndex].pollId);
    }

    private getPollForProject(getAnswer: boolean): void {

        this.pollService.getPollsFromProject(this.project.projectId).subscribe(
            {
                next: (data) => {

                    if (!data) {
                        return;
                    }

                    this.polls = data;

                    //bring answer for the first poll if available
                    if (getAnswer) {
                        this.getAnswerByPollId(this.polls[this.currentPollIndex].pollId);
                    }

                },
                error: (err) => {
                    this.polls = [];
                    this.selectedAnswer = null;
                }
            }

        );
    }

    private getAnswerByPollId(pollId: string): void {
        this.pollService.getAnswer(pollId).subscribe(
            {
                next: (data) => {
                    if (data) {
                        this.selectedAnswer = data;
                    }
                },
                error: (err) => {
                    this.selectedAnswer = null;

                }
            }
        );
    }

    private deleteAnswer(pollAnswer: PollAnswer): void {
        pollAnswer.isActive = false;
        this.pollService.saveAnswer(pollAnswer).subscribe((data) => {
            if (data) {
                this.selectedAnswer = null;
                this.selectedOption = null;
            }
        });
    }

}
