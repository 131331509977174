import { Component, OnInit, Inject, ViewContainerRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupsService } from '../groups/groups.service';

import {
    ControlsUtil,
    FurbanUtil,
    Group,
    ModalManager,
    validateWhitespace,
} from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-app-add-group-popup',
    templateUrl: './add-group-popup.component.html',
    styleUrls: ['./add-group-popup.component.scss'],
})
export class AddGroupPopupComponent implements OnInit {
    public existingGroup: Group;
    public groups: Group[];
    public dialogTitle: string;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public newGroup = new FormControl('', [
        this.htmlValidator,
        validateWhitespace,
        Validators.required,
        Validators.maxLength(32),
    ]);
    public parentViewContainerRef: ViewContainerRef;

    public hasControlError = ControlsUtil.hasControlError;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<AddGroupPopupComponent>,
        private groupsService: GroupsService,
        private modal: ModalManager,
        private translateService: TranslateService
    ) {
        this.existingGroup = data.group;
        this.groups = data.groups;
    }

    ngOnInit() {
        if (this.existingGroup) {
            this.dialogTitle = this.translateService.instant(
                'admin.groups.editGroup'
            );
            this.newGroup = new FormControl(this.existingGroup.groupName, [
                this.htmlValidator,
                validateWhitespace,
                Validators.required,
                Validators.maxLength(32),
            ]);
        } else {
            this.dialogTitle = this.translateService.instant(
                'admin.groups.createNewGroup'
            );
            this.newGroup = new FormControl('', [
                this.htmlValidator,
                validateWhitespace,
                Validators.required,
                Validators.maxLength(32),
            ]);
        }
    }

    // REFACTOR
    saveGroup() {
        if (
            this.existingGroup &&
            this.existingGroup.groupName &&
            this.newGroup.value === this.existingGroup.groupName
        ) {
            this.newGroup.markAsUntouched();
            this.newGroup.markAsPristine();
            this.closeDialog();
            return;
        }

        if (this.newGroup.invalid) {
            return;
        }

        const groupName = this.newGroup.value.trim().toLowerCase();

        if (
            this.groups.filter((x) => x.groupName.toLowerCase() === groupName)
                .length > 0
        ) {
            this.newGroup.setErrors({ isDuplicate: true });
        } else {
            const group: Group = new Group();
            if (this.existingGroup) {
                group.createdDate = this.existingGroup.createdDate;
                group.groupId = this.existingGroup.groupId;
                group.groupName = groupName;
            } else {
                group.createdDate = new Date(Date.now());
                group.groupName = groupName;
            }
            this.groupsService.saveGroup(group).subscribe((data) => {
                this.dialogRef.close(data);
            });
        }
    }

    closeDialog() {
        if (this.isModified()) {
            this.modal
                .showModal(
                    this.parentViewContainerRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'formChangesDialog.message',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res: any) => {
                    if (res) {
                        this.dialogRef.close();
                    }
                });
        } else {
            this.dialogRef.close();
        }
    }

    isModified(): boolean {
        if (this.newGroup.dirty) {
            return true;
        }
        return false;
    }
}
