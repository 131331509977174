import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
    ElementRef,
    ViewEncapsulation,
    HostListener,
} from '@angular/core';

import { ModalConfigService } from './furban-modal.config';
import { ModalConfiguration } from './furban-modal.config';
import { ModalDirective } from 'ngx-bootstrap/modal';
@Component({
    selector: 'furban-modal',
    templateUrl: './furban-modal.component.html',
    styleUrls: ['./furban-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
    @Output() public modalOutput: EventEmitter<any> = new EventEmitter();
    @ViewChild('autoShownModal') private autoShownModal: ModalDirective;

    public isModalShown = false;
    public cancelLabel: string;
    public okLabel: string;
    public modalBody: string;
    public modalTitle: string;

    private result: boolean;
    private callback: any;
    private cancelCallback: any;
    private scope: any;

    private _conf: ModalConfiguration;

    constructor(
        private modalConfig: ModalConfigService,
        private elementRef: ElementRef
    ) {
        this._conf = this.modalConfig.conf;
        if (!this._conf) {
            throw new Error('modal not configured');
        }
    }

    @HostListener('document:keydown', ['$event'])
    public onHostKeyDown(event: KeyboardEvent) {
        const clickedInside = this.elementRef.nativeElement.contains(
            event.target
        );
        if (!clickedInside) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    ngOnInit() {
        this.modalTitle = this._conf.title;
        this.modalBody = this._conf.message;
        this.okLabel = this._conf.okLabel || 'OK';
        if (this._conf.cancelLabel) {
            this.cancelLabel = this._conf.cancelLabel;
        }

        if (this._conf.callback) {
            this.callback = this._conf.callback;
        }
        if (this._conf.cancelCallback) {
            this.cancelCallback = this._conf.cancelCallback;
        }
        if (this._conf.scope) {
            this.scope = this._conf.scope;
        }

        // discard the configuration
        this._conf = null;
        // show the modal
        this.isModalShown = true;
        if (
            document.getElementsByClassName('main-container') &&
            document.getElementsByClassName('main-container').length > 0
        ) {
            document
                .getElementsByClassName('main-container')[0]
                .classList.add('no-scroll');
        }
    }

    public set conf(value: ModalConfiguration) {
        this._conf = value;
    }

    public cancel() {
        this.result = false;
        this.removeScroll();
        this.autoShownModal.hide();

        if (this.cancelCallback) {
            this.cancelCallback();
        }
    }

    public accept() {
        if (this.callback) {
            this.callback();
        }

        this.removeScroll();
        this.result = true;
        this.autoShownModal.hide();
    }

    public onHidden(): void {
        this.isModalShown = false;
        if (this.result) {
            this.modalOutput.emit(true);
        } else {
            this.modalOutput.emit(false);
        }
    }

    private removeScroll(): void {
        const modalElements = document.getElementsByClassName('furban-modal');

        if (!modalElements.length) {
            const containerElements =
                document.getElementsByClassName('main-container');

            if (containerElements && containerElements.length > 0) {
                containerElements[0].classList.remove('no-scroll');
            }
        }
    }
}
