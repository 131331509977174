import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {
    Component,
    OnInit,
    ViewContainerRef,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { switchMap } from 'rxjs/operators';

import {
    Client,
    ControlsUtil,
    FurbanUtil,
    ModalManager,
    Supplier,
    SupplierClient,
} from '@furban/utilities';
import { SuppliersService } from '../superadmin-suppliers/suppliers.service';

@Component({
    selector: 'furban-app-superadmin-add-supplier',
    templateUrl: './superadmin-add-supplier.component.html',
    styleUrls: ['./superadmin-add-supplier.component.scss'],
})
export class SuperadminAddSupplierComponent implements OnInit {
    @ViewChild('dropDownInput') dropDownInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    public municipalities: Client[];
    public supplierForm: FormGroup;
    public parentViewContainerRef: ViewContainerRef;
    public selectable = true;
    public removable = true;
    public selectedData: Client[] = [];
    public separatorKeysCodes: number[] = [ENTER, COMMA];
    public noWhiteSpacesValidator = FurbanUtil.noWhiteSpacesValidator;
    public htmlValidator = FurbanUtil.htmlContentValidator;

    public hasControlMaxError = ControlsUtil.hasControlMaxError;
    public hasControlMinError = ControlsUtil.hasControlMinError;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;
    public hasControlMaxLengthError = ControlsUtil.hasControlMaxLengthError;
    public hasControlMinLengthError = ControlsUtil.hasControlMinLengthError;
    public hasControlIsHtmlError = ControlsUtil.hasControlIsHtmlError;
    public hasControlWhitespacesError = ControlsUtil.hasControlWhitespacesError;
    public hasControlEmailError = ControlsUtil.hasControlEmailError;
    constructor(
        private dialogRef: MatDialogRef<SuperadminAddSupplierComponent>,
        private formBuilder: FormBuilder,
        private supplierService: SuppliersService,
        private modal: ModalManager
    ) { }

    ngOnInit() {
        this.supplierForm = this.formBuilder.group({
            supplierName: [
                '',
                [
                    Validators.required,
                    this.noWhiteSpacesValidator,
                    this.htmlValidator,
                    Validators.maxLength(64),
                ],
            ],
            chipListControl: [],
            dropdownControl: [],
        });
    }

    public closeDialog(): void {
        if (this.supplierForm.dirty) {
            this.modal
                .showModal(
                    this.parentViewContainerRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'errors.discardMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (res) {
                        this.dialogRef.close();
                    }
                });
        } else {
            this.dialogRef.close();
        }
    }

    public remove(municipality: Client): void {
        const index = this.selectedData.indexOf(municipality);

        if (index >= 0) {
            this.selectedData.splice(index, 1);
        }
    }

    public selected(dataItem: any): void {
        const newMun = dataItem.option.value;
        if (
            !this.selectedData.some(
                (item) => item.clientName === newMun.clientName
            )
        ) {
            this.selectedData.push(newMun);
        }
        this.dropDownInput.nativeElement.value = '';
        (document.activeElement as HTMLElement).blur();
    }

    public saveSupplier(): void {
        if (this.supplierForm.invalid) {
            this.supplierForm.get('supplierName').markAsDirty();
            return;
        }

        const toSaveSupplier = new Supplier();
        toSaveSupplier.supplierName =
            this.supplierForm.get('supplierName').value;

        this.supplierService
            .saveSupplier(toSaveSupplier)
            .pipe(
                switchMap((data) => {
                    if (data && this.selectedData) {
                        const supplierClients: SupplierClient[] =
                            this.convertSelectedClientsToSupplierClients(
                                this.selectedData,
                                data.supplierId
                            );
                        return this.supplierService.assignSupplierToClient(
                            supplierClients
                        );
                    }
                })
            )
            .subscribe((data) => {
                this.dialogRef.close(data);
            });
    }

    private convertSelectedClientsToSupplierClients = (
        clients: Client[],
        supplierId: string
    ): SupplierClient[] => {
        const supplierClients: SupplierClient[] = [];

        clients.forEach((client) => {
            const supplierClient = new SupplierClient();
            supplierClient.supplierId = supplierId;
            supplierClient.clientId = client.clientUuid;
            supplierClients.push(supplierClient);
        });

        return supplierClients;
    };
}
