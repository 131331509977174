import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MultilanguageService } from './multilanguage.service';

@Component({
    selector: 'furban-app-multilanguage',
    templateUrl: './multilanguage.component.html',
    styleUrls: ['./multilanguage.component.scss'],
})
export class MultilanguageComponent implements OnInit {
    @Input() hideButtons: boolean;
    @ViewChild('customLanguageSelect') customLanguageSelect;

    constructor(public multilanguageService: MultilanguageService) {}

    ngOnInit() {
        this.multilanguageService.setupLanguage();
    }

    onSelectChange() {
        this.multilanguageService.updateUsedLanguage();
    }
}
