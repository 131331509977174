import { Furban3DModel } from './furban-3d-model';

export class Project3dModel {
    public project3DModelId: number;
    public projectId: string;
    public furban3DModel: Furban3DModel;
    public price: number;
    public enabled: boolean;
    public objectType?: string;
    public freeshapePoints?: string;

    constructor(
        furban3DModel: Furban3DModel,
        price: number,
        enabled: boolean,
        projectId?: string,
        project3DModelId?: number
    ) {
        this.furban3DModel = furban3DModel;
        this.price = price;
        this.enabled = enabled !== undefined ? enabled : this.enabled;
        this.projectId = projectId !== undefined ? projectId : this.projectId;
        this.project3DModelId =
            project3DModelId !== undefined
                ? project3DModelId
                : this.project3DModelId;
    }
}
