import { createAction, props } from '@ngrx/store';
import { PathObject } from '../_models/path-object';

export const getDefaultObjects = createAction(
    '[three-editor] get default objects',
    props<{
        projectId: string;
    }>()
);

export const setInitialAvailableBudget = createAction(
    '[three-editor] set initial budget',
    props<{
        budget: number;
    }>()
);

export const loadDefaultObjects = createAction(
    '[three-editor] load default objects',
    props<{ objects: PathObject[] }>()
);

export const loadLiveObjects = createAction(
    '[three-editor] load live objects',
    props<{ objects: PathObject[] }>()
);

export const getUserObjects = createAction(
    '[three-editor] get user objects',
    props<{
        projectId: string;
        userProfileId: string;
    }>()
);

export const getLiveObjects = createAction(
    '[three-editor] get live objects',
    props<{
        dpId: string;
    }>()
);

export const loadUserObjects = createAction(
    '[three-editor] load user objects',
    props<{ objects: PathObject[] }>()
);

export const getDefaultDesignObjects = createAction(
    '[three-editor] get default design objects',
    props<{
        projectId: string;
        designId: string;
    }>()
);

export const loadDefaultDesignObjects = createAction(
    '[three-editor] load default design objects',
    props<{ objects: PathObject[] }>()
);

export const addObject = createAction(
    '[three-editor] add user object',
    props<{ object: PathObject; shouldSelect: boolean; budget?: number }>()
);

export const addObjectRequest = createAction(
    '[three-editor] add user object request',
    props<{
        object: PathObject;
        shouldSelect: boolean;
        budget?: number;
        socketId?: string;
    }>()
);

export const deleteObject = createAction(
    '[three-editor] delete object',
    props<{ object: PathObject; budget?: number }>()
);

export const deleteObjectRequest = createAction(
    '[three-editor] delete object request',
    props<{ object: PathObject; budget?: number }>()
);

export const updateObject = createAction(
    '[three-editor] update default object',
    props<{ object: PathObject }>()
);

export const updateObjectRequest = createAction(
    '[three-editor] update default object request',
    props<{ object: PathObject }>()
);

export const addMultipleObjects = createAction(
    '[three-editor] add multpile default objects',
    props<{
        objects: PathObject[];
        shouldGroup: boolean;
        shouldSelect: boolean;
        budget?: number;
        socket?: string;
    }>()
);

export const addMultipleObjectsRequest = createAction(
    '[three-editor] add multpile default objects request',
    props<{
        objects: PathObject[];
        shouldGroup: boolean;
        shouldSelect: boolean;
        budget?: number;
        socketId?: string;
    }>()
);

export const deleteMultipleObjects = createAction(
    '[three-editor] delete multiple objects',
    props<{ objects: PathObject[]; budget?: number }>()
);

export const deleteMultipleObjectsRequest = createAction(
    '[three-editor] delete multiple objects request',
    props<{ objects: PathObject[]; budget?: number }>()
);

export const updateMultipleObjects = createAction(
    '[three-editor] update multiple objects',
    props<{ objects: PathObject[] }>()
);

export const updateMultipleObjectsRequest = createAction(
    '[three-editor] update multiple objects request',
    props<{ objects: PathObject[] }>()
);

export const restoreInitialState = createAction(
    '[three-editor] restore initial'
);

export const restartDesignRequest = createAction(
    '[three-editor] restart design',
    props<{ budget?: number }>()
);

export const undoRequest = createAction(
    '[three-editor] undo request',
    props<{ objectsToSave: PathObject[]; objectsToRemove: PathObject[] }>()
);

export const undo = createAction(
    '[three-editor] undo',
    props<{ objectsToReplace: PathObject[]; newObjects: PathObject[] }>()
);

export const redoRequest = createAction(
    '[three-editor] redo request',
    props<{ objectsToSave: PathObject[]; objectsToRemove: PathObject[] }>()
);

export const redo = createAction(
    '[three-editor] redo',
    props<{ objectsToReplace: PathObject[]; newObjects: PathObject[] }>()
);
