<div
    class="main-container overflow"
    infiniteScroll
    [infiniteScrollThrottle]="300"
    (scrolled)="onScrollDown()"
    [scrollWindow]="false"
>
    <ng-container *ngIf="!isViewerOnly; else publicHeader">
        <furban-homepage-header></furban-homepage-header>
    </ng-container>

    <ng-template #publicHeader>
        <furban-configurable-header
            [backRoute]="headerBackRoute"
            [isSticky]="true"
        ></furban-configurable-header>
    </ng-template>

    <ng-container *ngIf="project">
        <div class="padding-0-32">
            <furban-app-design-proposals
                #designProposals
                [project]="project"
                [viewContainerRef]="viewContainerRef"
                [disabledActions]="true"
                [isViewerOnly]="isViewerOnly"
                [isPublicPage]="true"
            >
            </furban-app-design-proposals>
        </div>
    </ng-container>
</div>
