export const FORBIDDEN_DOMAINS: string[] = [
    'gmail',
    'yahoo',
    'outlook',
    'hotmail',
    'aol',
    'icloud',
];

export const TOP_LEVEL_DOMAIN = 'nl';
