import { WebGLRenderer, Vector2 } from 'three';
import { SelectionBox } from 'three/examples/jsm/interactive/SelectionBox.js';
import { FurbanUtil } from '../helpers/furbanUtil';

export class ThreeSelectionHelper {
    public element: HTMLDivElement;
    public renderer: WebGLRenderer;
    public startPoint: Vector2;
    public pointTopLeft: Vector2;
    public pointBottomRight: Vector2;
    public isDown: boolean;

    constructor(
        selectionBox: SelectionBox,
        renderer: WebGLRenderer,
        cssClassName: string
    ) {
        this.element = document.createElement('div');
        this.element.classList.add(cssClassName);
        this.element.style.pointerEvents = 'none';

        this.renderer = renderer;

        this.startPoint = new Vector2();
        this.pointTopLeft = new Vector2();
        this.pointBottomRight = new Vector2();

        this.isDown = false;

        if (FurbanUtil.isMobile()) {
            this.renderer.domElement.addEventListener(
                'touchstart',
                this.onSelectStart
            );
            this.renderer.domElement.addEventListener(
                'touchmove',
                this.onSelectMove
            );
            this.renderer.domElement.addEventListener(
                'touchend',
                this.onSelectOver
            );
        } else {
            this.renderer.domElement.addEventListener(
                'pointerdown',
                this.onSelectStart
            );
            this.renderer.domElement.addEventListener(
                'pointermove',
                this.onSelectMove
            );
            this.renderer.domElement.addEventListener(
                'pointerup',
                this.onSelectOver
            );
        }
    }

    onSelectStart = (event: MouseEvent | any) => {
        if (event.changedTouches) {
            event = event.changedTouches[0];
        }

        this.isDown = true;
        this.renderer.domElement.parentElement.appendChild(this.element);

        this.element.style.left = event.clientX + 'px';
        this.element.style.top = event.clientY + 'px';
        this.element.style.width = '0px';
        this.element.style.height = '0px';

        this.startPoint.x = event.clientX;
        this.startPoint.y = event.clientY;
    };

    onSelectMove = (event: MouseEvent | any) => {
        if (event.changedTouches) {
            event = event.changedTouches[0];
        }

        if (this.isDown) {
            this.pointBottomRight.x = Math.max(
                this.startPoint.x,
                event.clientX
            );
            this.pointBottomRight.y = Math.max(
                this.startPoint.y,
                event.clientY
            );
            this.pointTopLeft.x = Math.min(this.startPoint.x, event.clientX);
            this.pointTopLeft.y = Math.min(this.startPoint.y, event.clientY);

            this.element.style.left = this.pointTopLeft.x + 'px';
            this.element.style.top = this.pointTopLeft.y + 'px';
            this.element.style.width =
                this.pointBottomRight.x - this.pointTopLeft.x + 'px';
            this.element.style.height =
                this.pointBottomRight.y - this.pointTopLeft.y + 'px';
        }
    };

    onSelectOver = () => {
        this.isDown = false;
        this.element.parentElement.removeChild(this.element);
    };

    removeEvents() {
        if (FurbanUtil.isMobile()) {
            this.renderer.domElement.removeEventListener(
                'touchstart',
                this.onSelectStart
            );
            this.renderer.domElement.removeEventListener(
                'touchmove',
                this.onSelectMove
            );
            this.renderer.domElement.removeEventListener(
                'touchend',
                this.onSelectOver
            );
        } else {
            this.renderer.domElement.removeEventListener(
                'pointerdown',
                this.onSelectStart
            );
            this.renderer.domElement.removeEventListener(
                'pointermove',
                this.onSelectMove
            );
            this.renderer.domElement.removeEventListener(
                'pointerup',
                this.onSelectOver
            );
        }
    }
}
