export class ExportFilesUtil {
    public static createCSV(res: any, csvName: string): void {
        const todayDate = new Date(Date.now()).toLocaleString();
        const csvData = new Blob([res], { type: 'text/csv;charset=utf-8;' });
        if (navigator['msSaveBlob']) {
            navigator['msSaveBlob'](csvData, csvName + todayDate + '.csv');
        } else {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(csvData);
            link.setAttribute('download', csvName + todayDate + '.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    public static createGLB(res: any, glbName: string) {
        const csvData = new Blob([res], {
            type: 'application/octet-stream;charset=utf-8;',
        });
        if (navigator['msSaveBlob']) {
            navigator['msSaveBlob'](csvData, glbName);
        } else {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(csvData);
            link.setAttribute('download', glbName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
