import { Component, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { ProjectDetailsService } from '../project-details/project-details.service';
import { TranslateService } from '@ngx-translate/core';

import {
    ModalManager,
    AuthService,
    ProjectInvolvementTypeEnum,
    AdminDashboardItemsArray,
    CustomToasterComponent,
    DashboardItem,
    House,
    PermitDashboardItemsArray,
    PioneerDashboardItemsArray,
    ProjectTypeNameEnum,
    routingConstants,
    Path,
    AdminDefaultDashboardItemsArray,
    ProjectSettingsService,
    MapService,
    PermitThreeService,
    ProjectService,
    CustomToasterService,
} from '@furban/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// This component is deprecated
@Component({
    selector: 'furban-app-project-dashboard-header',
    templateUrl: './project-dashboard-header.component.html',
    styleUrls: ['./project-dashboard-header.component.scss'],
})
export class ProjectDashboardHeaderComponent implements OnInit, OnDestroy {
    public dashboardItems: DashboardItem[];
    public projectProgress = 0;
    public projectType: string;

    private stepsNumber: number;
    private projectDetailsStateSubscription: Subscription;
    private subscription: Subscription;
    private isModifiedProjectDetailsFormState = false;
    private projectDashboardPath: string;
    private oldProjectId: string;
    private projectId: string;
    private isCitizenDesignsProject: boolean;

    constructor(
        private projectDetailsService: ProjectDetailsService,
        private translateService: TranslateService,
        private viewContainerRef: ViewContainerRef,
        private modal: ModalManager,
        private router: Router,
        private route: ActivatedRoute,
        private projectService: ProjectService,
        private customToasterService: CustomToasterService,
        private mapService: MapService,
        private authService: AuthService,
        private projectSettingsService: ProjectSettingsService,
        private permitThreeService: PermitThreeService
    ) { }

    ngOnInit() {
        this.route.data.subscribe((data: any) => {
            this.projectType = data['type'] as string;
        });
        this.projectDetailsStateSubscription =
            this.projectDetailsService.projectDetailsFormState.subscribe(
                (isModified) => {
                    this.isModifiedProjectDetailsFormState = isModified;
                }
            );
        this.subscription = this.route.params.subscribe((params) => {
            this.oldProjectId = this.projectId;
            this.projectId = params['id'];
            this.initialSetup();
        });
    }

    ngOnDestroy(): void {
        this.projectDetailsStateSubscription.unsubscribe();
        this.subscription.unsubscribe();
    }

    // REFACTOR
    setupRoute(): void {
        if (this.authService.isAdmin()) {
            if (
                this.router.url ===
                `${routingConstants.adminProject}/${this.projectId}`
            ) {
                this.projectDashboardPath = routingConstants.adminDashboard;
                return;
            }
            this.projectDashboardPath = `${routingConstants.adminProject}/${this.projectId}`;
        } else {
            if (
                this.projectType ===
                ProjectTypeNameEnum.pioneerInitiativeProject
            ) {
                if (
                    this.router.url ===
                    `/${routingConstants.initiativesProject}/${this.projectId}`
                ) {
                    if (this.projectId === 'new') {
                        this.projectDashboardPath =
                            routingConstants.initiativesProject;
                    } else {
                        this.projectDashboardPath =
                            routingConstants.initiativesProjectList;
                    }
                    return;
                }
                this.projectDashboardPath = `${routingConstants.initiativesProject}/${this.projectId}`;
            } else {
                if (
                    this.router.url ===
                    `/${routingConstants.permitProject}/${this.projectId}`
                ) {
                    if (this.projectId === 'new') {
                        this.projectDashboardPath =
                            routingConstants.initiativesProject;
                    } else {
                        this.projectDashboardPath =
                            routingConstants.initiativesProjectList;
                    }
                    return;
                }
                if (this.projectId === 'new') {
                    this.projectDashboardPath = `${routingConstants.permitProject}/${this.projectId}`;
                    return;
                }
                this.projectDashboardPath = `${routingConstants.permitProject}/${this.projectId}/process`;
            }
        }
    }

    setupStepsTitles(): void {
        if (this.authService.isAdmin()) {
            this.createSetupStepsTitlesForAdmin();
        } else {
            this.createSetupStepsTitlesForPioneer();
        }
        this.stepsNumber = this.dashboardItems.length;
    }

    initialSetup(): void {
        if (this.isNewProject() || !this.authService.isAdmin()) {
            this.setupDashboardHeader();
        } else {
            this.projectDetailsService
                .getProject(this.projectId)
                .subscribe((data) => {
                    this.isCitizenDesignsProject = data.isCitizenDesigns;
                    this.setupDashboardHeader();
                });
        }
    }

    setupDashboardHeader() {
        this.setupRoute();
        this.setupStepsTitles();
        this.setCurrentStep();
        this.getCurrentDrawing();
    }

    isNewProject(): boolean {
        return this.projectId === 'new';
    }

    hasCurrentDrawing(): boolean {
        return (
            this.projectService.curentDrawing &&
            !!this.projectService.curentDrawing.defaultMaterial
        );
    }

    getCurrentDrawing(): void {
        if (this.isNewProject() || this.hasCurrentDrawing()) {
            return;
        }
        this.mapService.getPolygon(this.projectId).subscribe(
            {
                next: (data: Path) => {
                    this.projectService.hasDrawing = true;
                    this.projectService.mapImageId = data.imageId;
                },
                error: (err) => {
                    this.projectService.hasDrawing = false;
                    this.projectService.curentDrawing.defaultMaterial = 1001;
                }
            }
        );
    }

    showUnsavedAreaToaster() {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'warning',
            'info',
            'admin.projectDetails.pathUnsavedWarning',
            1000
        );
    }

    showDiscardChanges(path: string) {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.projectDetails.projectUnsavedWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.router.navigateByUrl(path);
                }
            });
    }

    returnToDashboard() {
        if (this.isModifiedProjectDetailsFormState) {
            this.showDiscardChanges(this.projectDashboardPath);
            return;
        }
        this.router.navigateByUrl(this.projectDashboardPath);
    }

    getHeaderTitle(): string {
        if (!this.dashboardItems) {
            return;
        }
        return `${this.translateService.instant(
            this.dashboardItems[this.projectSettingsService.currentStep - 1]
                .title
        )}`;
    }

    getBackwardStepTooltipText(): string {
        if (!this.dashboardItems) {
            return;
        }
        return this.projectSettingsService.currentStep <= 1
            ? ''
            : `${this.translateService.instant('admin.dashboard.step')}  ${this.projectSettingsService.currentStep - 1
            }:` +
            `${this.translateService.instant(
                this.dashboardItems[
                    this.projectSettingsService.currentStep - 2
                ].title
            )}`;
    }

    getForwardStepTooltipText(): string {
        if (!this.dashboardItems) {
            return;
        }
        return this.projectSettingsService.currentStep >= this.stepsNumber
            ? ''
            : `${this.translateService.instant('admin.dashboard.step')}  ${this.projectSettingsService.currentStep + 1
            }:` +
            `${this.translateService.instant(
                this.dashboardItems[
                    this.projectSettingsService.currentStep
                ].title
            )}`;
    }

    goBack(): void {
        if (
            this.projectSettingsService.currentStep === this.stepsNumber - 1 &&
            !this.projectService.hasDrawing
        ) {
            this.showUnsavedAreaToaster();
            return;
        }
        this.router.navigateByUrl(
            this.dashboardItems[this.projectSettingsService.currentStep - 2]
                .path
        );
    }

    goForward(): void {
        if (this.projectSettingsService.currentStep === 1) {
            if (this.isModifiedProjectDetailsFormState) {
                this.showDiscardChanges(
                    this.dashboardItems[this.projectSettingsService.currentStep]
                        .path
                );
                return;
            }
            if (this.oldProjectId === 'new') {
                this.projectService.curentDrawing = new Path();
                this.permitThreeService.house = new House();
                this.projectService.curentDrawing.defaultMaterial = 1001;
            }
        }
        if (
            (this.projectSettingsService.currentStep === 2 ||
                (this.authService.isAdmin() &&
                    this.projectSettingsService.currentStep === 3)) &&
            !this.projectService.hasDrawing
        ) {
            this.showUnsavedAreaToaster();
            return;
        }

        if (
            this.projectType === ProjectTypeNameEnum.pioneerPermitProject &&
            this.projectSettingsService.currentStep === 3
        ) {
            this.router.navigateByUrl(
                `${routingConstants.permitProject}/${this.projectId}/process`
            );
            return;
        }
        this.router.navigateByUrl(
            this.dashboardItems[this.projectSettingsService.currentStep].path
        );
    }

    isTheFirstStepAndIsNewProjectOrIsTheLastStep(): boolean {
        return (
            this.projectSettingsService.currentStep === this.stepsNumber ||
            (this.projectSettingsService.currentStep === 1 &&
                this.projectId === 'new')
        );
    }

    getProgressStatusText(): string {
        return `${this.translateService.instant('admin.dashboard.step')}  ${this.projectSettingsService.currentStep
            } / ${this.stepsNumber}`;
    }

    isOnProjectSettingsPage(): boolean {
        return this.projectSettingsService.currentStep > 0;
    }

    isOnProjectDetailsPage(): boolean {
        return this.projectSettingsService.currentStep === 1;
    }

    private setCurrentStep() {
        const splittedUrl = this.router.url.split('/');
        const urlEnding = splittedUrl[splittedUrl.length - 1];
        if (urlEnding === this.projectId) {
            this.projectSettingsService.currentStep = 0;
        } else {
            this.dashboardItems
                .filter((item) => item.path.includes(urlEnding))
                .map(
                    (item) =>
                        (this.projectSettingsService.currentStep = item.id + 1)
                );
        }
        this.projectProgress =
            (this.projectSettingsService.currentStep / this.stepsNumber) * 100;
    }

    private createSetupStepsTitlesForAdmin() {
        if (
            this.projectService.projectInvolvementType ===
            ProjectInvolvementTypeEnum.citizen ||
            this.isCitizenDesignsProject
        ) {
            this.dashboardItems = AdminDashboardItemsArray.createDashboardItems(
                this.projectId
            );
        } else {
            this.dashboardItems =
                AdminDefaultDashboardItemsArray.createDashboardItems(
                    this.projectId
                );
        }
    }

    private createSetupStepsTitlesForPioneer() {
        if (this.projectType === ProjectTypeNameEnum.pioneerInitiativeProject) {
            this.dashboardItems =
                PioneerDashboardItemsArray.createDashboardItems(this.projectId);
        } else {
            this.dashboardItems =
                PermitDashboardItemsArray.createDashboardItems(this.projectId);
        }
    }
}
