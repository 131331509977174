<div class="important-space-between" mat-dialog-title>
    <h2 class="project-title">
        {{ 'admin.groups.projectsModalHeader'| translate : { userGroup: groupName } }}
    </h2>

    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons"> close </span>
    </button>
</div>

<mat-dialog-content>
    <mat-list>
        <ng-container *ngFor="let project of projects">
            <div class="project-items pointer" (click)="navigateToProject(project.projectId)">
                <mat-list-item class="inline">{{ project.name }}</mat-list-item>
                <h6 class="project-text">{{ 'admin.groups.seeProject' | translate }}</h6>
            </div>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="primary-button" (click)="closeDialog()">
        <h6>{{ 'generic.closeBtn' | translate }}</h6>
    </button>
</div>