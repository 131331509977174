import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ErrorService {
    public clientErrorCode: number;
    public errorHandler: Function;

    private error = new Subject<string>();

    constructor() {}

    public addError = (error: string): void => this.error.next(error);

    public getError = () => this.error.asObservable();
}
