<div class="design-card">
    <ng-container *ngIf="project">
        <div card-type="content" class="project-card-content">
            <label
                class="project-type"
                [style.color]="getColorBasedOnProjectType(project)"
            >
                {{ getProjectTypeText(project) | translate }}
            </label>
            <div
                *ngIf="project && project.projectStatus"
                matTooltip="{{ getStatusTooltipText(project) }}"
                class="status-background {{ getClassForProject(project) }}"
            ></div>
        </div>
    </ng-container>

    <div class="gradient" (click)="cardClicked()"></div>
    <div
        class="design-img background-center"
        [style.background-image]="'url(' + getMedia(cardMedia) + ')'"
    >
        <div class="flex-row-spaced bottom-content">
            <section class="avatar flex-row-start card-type">
                <ng-container *ngIf="userProfile">
                    <furban-avatar-picture
                        [name]="userProfile.screenName"
                        [size]="28"
                        [mediaId]="getUserMedia(userProfile.mediaId)"
                    >
                    </furban-avatar-picture>
                </ng-container>

                <span>
                    <h6>{{ title | titlecase }}</h6>
                </span>
            </section>

            <div class="share">
                <ng-container
                    *ngIf="
                        project &&
                        project.projectStatus.statusWeight ===
                            projectStatus.published
                    "
                >
                    <button
                        class="card-action-button"
                        [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation()"
                    >
                        <span class="material-icons"> share </span>
                    </button>

                    <mat-menu #menu="matMenu" overlapTrigger="false">
                        <button
                            mat-menu-item
                            (click)="buildURL('facebook', project.projectId)"
                            value="fb"
                        >
                            <img
                                class="social-media-icon"
                                src="/assets/images/social-media/facebook.svg"
                            />
                            {{ 'socialMedia.shareFB' | translate }}
                        </button>
                        <button
                            mat-menu-item
                            (click)="buildURL('twitter', project.projectId)"
                            value="twitter"
                        >
                            <img
                                class="social-media-icon"
                                src="/assets/images/social-media/twitter.svg"
                            />
                            {{ 'socialMedia.shareTwitter' | translate }}
                        </button>
                        <button
                            mat-menu-item
                            (click)="buildURL('linkedin', project.projectId)"
                            value="linkedin"
                        >
                            <img
                                class="social-media-icon"
                                src="/assets/images/social-media/linkedin.svg"
                            />
                            {{ 'socialMedia.shareLinkedIn' | translate }}
                        </button>
                    </mat-menu>
                </ng-container>
                <ng-container *ngIf="!project">
                    <button class="card-action-button">
                        <span class="material-icons" (click)="deleteClicked()"
                            >delete</span
                        >
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>
