<div class="flex-row-center"
     id="controlsHTML">
     <div class="inline-controls">
    <button class="control-btn active"
            [class.active]="isActiveTab(threeObjectControlsEnum.move)"
            (click)="triggerAction(threeObjectControlsEnum.move)">
        <span class="material-icons inline-icon">control_camera</span>
    </button>

    <ng-container *ngIf="isTransformActive">
        <button class="control-btn"
                [class.active]="isActiveTab(threeObjectControlsEnum.transform)"
                (click)="triggerAction(threeObjectControlsEnum.transform)">
            <span class="material-icons inline-icon">settings_overscan</span>
        </button>
    </ng-container>

    <ng-container *ngIf="shouldDisplayConfigureButton">
        <button class="control-btn"
                [class.active]="isActiveTab(threeObjectControlsEnum.configure)"
                (click)="triggerAction(threeObjectControlsEnum.configure)">
            <span class="material-icons inline-icon">palette</span>
        </button>
    </ng-container>

    <ng-container *ngIf="isRotateActive">
        <button class="control-btn"
                [class.active]="isActiveTab(threeObjectControlsEnum.rotate)"
                (click)="triggerAction(threeObjectControlsEnum.rotate)">
            <span class="material-icons inline-icon">360</span>
        </button>
    </ng-container>

    <button class="control-btn shadow"
            (click)="deleteAction()">
        <span class="material-icons">delete</span>
    </button>
</div>
    <button class="close-button"
            [style.margin-left.px]="8">
        <span class="material-icons"
              (click)="discardAction()">close</span>
    </button>
</div>