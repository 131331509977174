<div
    class="flex-row-start"
    [class.disabled]="disabled"
    [class.circle-mode]="hasCircle"
    (click)="likeDesign(dp)"
>
    <div class="heart">
        <svg
            [class.active]="checkIfLiked(dp)"
            class="hided-heart"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="var(--alert-1)"
            stroke="var(--alert-1)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path
                d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
            ></path>
        </svg>
        <svg
            [@openClose]="
                checkIfLiked(dp)
                    ? { value: 'closed', params: { color: heartColor } }
                    : { value: 'open', params: { color: heartColor } }
            "
            [class.white]="isForAdminCard"
            [class.marked]="checkIfLiked(dp)"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-heart normal-heart"
        >
            <path
                d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
            ></path>
        </svg>
    </div>
    <ng-container *ngIf="dp && dp.likes">
        <h6 [class.white]="isForAdminCard" [style.margin-left.px]="8">{{ getLikesText() }}</h6>
    </ng-container>
</div>
