import { UploadedObjectNameEnum, UploadedObjectTypeEnum } from "../_enum/uploaded-object-type.enum";

export const convertUploadedObjectTypeEnumToNameEnum = (uploadedObjectType: UploadedObjectTypeEnum): UploadedObjectNameEnum => {
    switch (uploadedObjectType) {
        case UploadedObjectTypeEnum.customObject:
            return UploadedObjectNameEnum.customObject;
        case UploadedObjectTypeEnum.underground:
            return UploadedObjectNameEnum.underground;
        case UploadedObjectTypeEnum.fixedDesign:
            return UploadedObjectNameEnum.fixedDesign;
        default:
            return UploadedObjectNameEnum.customObject;
    }
}