import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import {
    AuthService,
    Question,
    MultilanguageService,
    routingConstants,
    TutorialsService,
    ProjectStorageService,
} from '@furban/utilities';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FaqService } from './faq.service';
import { Router } from '@angular/router';
import { SuperadminQuestionAddComponent } from '../../municipality/superadmin-question-add/superadmin-question-add.component';
import { PublishService } from '../../project-shared/publish-popup/publish.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'furban-app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit, OnDestroy {
    public parentViewContainerRef: ViewContainerRef;

    public questions: Question[];
    public language: string;
    public isPublished: boolean;

    private publicServiceSubscription: Subscription;

    constructor(
        private sanitizer: DomSanitizer,
        private authService: AuthService,
        private dialog: MatDialog,
        private containerRef: ViewContainerRef,
        private faqService: FaqService,
        private router: Router,
        private tutorialService: TutorialsService,
        public multilanguageService: MultilanguageService,
        private projectStorageService: ProjectStorageService,
        public publishService: PublishService
    ) {
        multilanguageService.languageChanged.subscribe((lang) => {
            this.language = lang;
            this.getQuestions();
        });
    }

    ngOnInit() {
        this.language = localStorage.getItem('language');
        this.getQuestions();
        this.checkIfDesignIsPublished();
    }

    ngOnDestroy(): void {
        this.publicServiceSubscription.unsubscribe();
    }

    canUpdateDesign(): boolean {
        return (
            !this.isPublished &&
            !this.checkIfProjectEnded() &&
            (!this.publishService.designProposal.isActive ||
                this.publishService.designProposal.isAdminDesign)
        );
    }

    getQuestions(): void {
        this.faqService.getQuestions().subscribe((data) => {
            this.questions = data;
        });
    }

    getQuestionForLanguage(question: Question): string {
        if (this.language === 'nl-NL') {
            return question.questionNL;
        } else if (this.language === 'ro-RO') {
            // TODO: ro-RO
            return question.questionEN;
        } else {
            return question.questionEN;
        }
    }

    getAnswerForLanguage(question: Question): string {
        if (this.language === 'nl-NL') {
            return question.answerNL;
        } else if (this.language === 'ro-RO') {
            // TODO: ro-RO
            return question.answerEN;
        } else {
            return question.answerEN;
        }
    }

    checkIfSuperadmin(): boolean {
        return this.authService.isSuperAdmin();
    }

    openAddModal(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '40%';

        const faqDialogRef = this.dialog.open(
            SuperadminQuestionAddComponent,
            dialogConfig
        );
        faqDialogRef.componentInstance.parentViewContainerRef =
            this.containerRef;

        faqDialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.questions.unshift(data);
            }
        });
    }

    getURLLink(question: Question) {
        const decodedURL = atob(question.videoUrl);
        return this.sanitizer.bypassSecurityTrustResourceUrl(decodedURL);
    }

    goToDesignMode(): void {
        localStorage.setItem('has-design-tour', 'false');
        this.tutorialService.closeTutorialMenu();
        if (this.projectStorageService.curentProject) {
            this.router.navigate([
                routingConstants.project,
                this.projectStorageService.curentProject.projectId,
            ]);
        }
    }

    goToDashboard(): void {
        localStorage.setItem('has-dashboard-tour', 'false');
        this.tutorialService.closeTutorialMenu();
        this.router.navigate([routingConstants.dashboard]);
    }

    showTourButtons(): boolean {
        if (
            this.authService.isCitizen() &&
            this.projectStorageService.curentProject
        ) {
            return true;
        }
        return false;
    }

    checkIfProjectEnded(): boolean {
        if (this.projectStorageService.curentProject) {
            return this.projectStorageService.curentProject.ended;
        }
        return false;
    }

    private checkIfDesignIsPublished(): void {
        const project = this.projectStorageService.curentProject;
        this.publicServiceSubscription = this.publishService
            .getPublishedDesignByProject(project.projectId)
            .subscribe((data) => {
                this.isPublished = !!data;
            });
    }
}
