import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/furban-client/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NeighborPermitReviews } from '../_models/neighbor-permit-reviews';
import { PermitProjectStatus } from '../_models/permit-project-status';
import { PermitReview } from '../_models/permit-review';
import { PublicPermitReview } from '../_models/public-permit-review';
import { ReviewsCount } from '../_models/reviews-count.model';
import { UpdatePermitReview } from '../_models/update-permit-review';

@Injectable({
    providedIn: 'root',
})
export class PermitReviewService {
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(private http: HttpClient) {}

    public getAllMunicipalityPermitRequests(
        pageIndex: number,
        perPage: number
    ): Observable<PermitReview[]> {
        const params = new HttpParams()
            .set('pageIndex', pageIndex.toString())
            .set('perPage', perPage.toString());
        return this.http
            .get(`${environment.apiPath}/permit-reviews`, {
                headers: this._headers,
                params: params,
            })
            .pipe(map((result) => result as PermitReview[]));
    }

    public getAllPermitRequestsCount(): Observable<number> {
        return this.http
            .get(`${environment.apiPath}/permit-reviews/count`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as number));
    }

    public getMunicipalityPermitStatusByProjectId(
        projectId: string
    ): Observable<PermitProjectStatus> {
        return this.http
            .get(
                `${environment.apiPath}/permit-reviews/${projectId}/municipality-status`,
                { headers: this._headers }
            )
            .pipe(map((result) => result as PermitProjectStatus));
    }

    public updatePermitStatus(
        permitReview: UpdatePermitReview
    ): Observable<PermitReview> {
        return this.http
            .put(`${environment.apiPath}/permit-reviews`, permitReview, {
                headers: this._headers,
            })
            .pipe(map((result) => result as PermitReview));
    }

    public getApprovalAndRejectsCount(
        projectId: string
    ): Observable<ReviewsCount> {
        return this.http
            .get(
                `${environment.apiPath}/permit-reviews/neighbours/count/${projectId}`
            )
            .pipe(map((response) => response as ReviewsCount));
    }

    public getNeighboursPermitReviews(
        projectId: string
    ): Observable<NeighborPermitReviews> {
        return this.http
            .get(
                `${environment.apiPath}/permit-reviews/neighbours/${projectId}`,
                { headers: this._headers }
            )
            .pipe(map((result) => result as NeighborPermitReviews));
    }

    public getPublicPermitReviews(
        projectId: string
    ): Observable<PublicPermitReview[]> {
        return this.http
            .get(`${environment.apiPath}/permit-reviews/all/${projectId}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as PublicPermitReview[]));
    }

    public getPermitReview(projectId: string): Observable<PermitReview> {
        return this.http
            .get(`${environment.apiPath}/permit-reviews/${projectId}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as PermitReview));
    }

    public getMunicipalityPermitReviewForProject(
        projectId: string
    ): Observable<PermitReview> {
        return this.http
            .get(
                `${environment.apiPath}/permit-reviews/municipality/${projectId}`,
                { headers: this._headers }
            )
            .pipe(map((result) => result as PermitReview));
    }
}
