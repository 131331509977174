<div class="important-space-between" mat-dialog-title>
    <h2>{{ dialogTitle }}</h2>

    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons"> close </span>
    </button>
</div>

<mat-dialog-content>
    <mat-form-field class="full-width" [style.margin-bottom.px]="12" appearance="outline">
        <mat-label>
            {{ 'admin.groups.groupName' | translate }}
        </mat-label>
        <input matInput placeholder="{{ 'admin.groups.groupName' | translate }}" maxlength="32"
            [formControl]="newGroup" />
        <mat-error *ngIf="hasControlError(newGroup,'isHTML')">
            {{ 'comments.noHTMLContent' | translate }}
        </mat-error>
        <mat-error *ngIf="hasControlError(newGroup,'isDuplicate')">
            {{ 'admin.groups.alreadyExists' | translate }}
        </mat-error>
        <mat-error *ngIf="hasControlError(newGroup,'whitespace')">
            {{ 'admin.groups.whitespace' | translate }}
        </mat-error>
    </mat-form-field>
    <mat-error *ngIf="hasControlError(newGroup,'maxlength')">
        {{ 'admin.groups.groupNameMaxLength' | translate }}
    </mat-error>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closeDialog()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="saveGroup()">
        <h6>{{ dialogTitle }}</h6>
    </button>
</div>