import { FreezeActionEnum } from '../_enum/freeze-action.enum';

export class FreezeAction {
    public action: FreezeActionEnum;
    public ids: number[];

    constructor(action: FreezeActionEnum, ids: number[]) {
        this.action = action;
        this.ids = ids;
    }
}
