<section class="main-container padding-40-32">
    <div class="flex-row-spaced">
        <h3 class="page-title" [style.margin-bottom.px]="32">
            {{ 'suppliers.manageRequests' | translate }}
        </h3>
    </div>

    <div class="bottom-container">
        <table mat-table class="full-width" [dataSource]="dataSource">
            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'superadmin.pendingRequests.username' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.username }}
                </td>
            </ng-container>
            <ng-container matColumnDef="clientName">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'superadmin.pendingRequests.municipality' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.clientName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'superadmin.pendingRequests.createdDate' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.createdDate | date : 'mediumDate' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="approve">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'superadmin.pendingRequests.approve' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)="approveRequest(row)">
                        <span class="material-icons green">check</span>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="reject">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'superadmin.pendingRequests.reject' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)="rejectRequest(row)">
                        <span class="material-icons red">close</span>
                    </button>
                </td>
            </ng-container>
            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</section>
