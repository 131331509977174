<section class="important-space-between" [style.margin-top.px]="12">
    <h3 class="page-title">{{ 'navigation.tutorials.title' | translate }}</h3>
    <button class="ghost-button" (click)="closeTutorials()">
        <span class="material-icons"> close </span>
    </button>
</section>

<ng-container *ngIf="!currentlyDispayedTutorial; else tutorialDetails">
    <section class="gif-container">
        <div
            *ngFor="let tutorial of tutorials; let i = index"
            class="tutorial-item"
            (click)="openTutorial(i)"
        >
            <img [src]="tutorial.imagePath" [alt]="tutorial.name" />
            <h6>
                {{ tutorial.title | translate }}
            </h6>
        </div>
    </section>
</ng-container>

<ng-template #tutorialDetails>
    <div class="tutorial-details">
        <img
            [src]="currentlyDispayedTutorial.gifPath"
            [alt]="currentlyDispayedTutorial.name"
        />

        <div
            class="flex-row-start"
            [style.width.%]="100"
            [style.margin-top.px]="16"
        >
            <span
                class="material-icons pointer"
                matTooltip="{{
                    'navigation.tutorials.goToAllTutorials' | translate
                }}"
                [style.margin-right.px]="8"
                (click)="closeTutorialDetails()"
            >
                chevron_left
            </span>
            <h3 class="page-title">
                {{ currentlyDispayedTutorial.title | translate }}
            </h3>
        </div>

        <h6 [style.margin-top.px]="16">
            {{ currentlyDispayedTutorial.description | translate }}
        </h6>

        <div class="buttons-right-container">
            <button
                class="secondary-button"
                [class.disabled]="currentlyDispayedIndex === 0"
                [disabled]="currentlyDispayedIndex === 0"
                (click)="previous()"
            >
                <h6>{{ 'generic.backBtn' | translate }}</h6>
            </button>

            <button
                class="primary-button"
                [class.disabled]="
                    currentlyDispayedIndex === tutorials.length - 1
                "
                [disabled]="currentlyDispayedIndex === tutorials.length - 1"
                (click)="next()"
            >
                <h6>{{ 'generic.nextBtn' | translate }}</h6>
            </button>
        </div>
    </div>
</ng-template>
