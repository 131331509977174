export enum UploadedObjectTypeEnum {
    customObject = 1,
    underground = 2,
    fixedDesign = 3
}

export enum UploadedObjectNameEnum {
    customObject = 'customDesign',
    underground = 'undergroundDesign',
    fixedDesign = 'fixedDesign',
}
