import { UserProfile } from './user-profile';

export class GeneralComment {
    commentId?: number;
    commentText: string;
    userProfile: UserProfile;
    isActive?: boolean;
    lastModifiedDate?: Date;
    position?: string;
    rotation?: string;
    parentCommentId?: number;
}
