<h3>
    {{ 'user.statistics.objectCategory' | translate }}
</h3>
<div class="flex-row-center full-width relative">
    <section class="legend">
        <h5>
            {{ 'user.statistics.categoriesLegend' | translate }}
        </h5>
        <p id="legend-content">
            {{ 'user.statistics.total' | translate }}: {{ objectsTotalCount }}
        </p>
    </section>
    <div id="chart"></div>
</div>

