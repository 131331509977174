import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

import {
    AreaSelectionSquare,
    MapPosition,
    MapPaginator,
    ProjectLight,
} from '@furban/utilities';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '@furban/app-config';

@Injectable({
    providedIn: 'root',
})
export class ProjectsOnAreaService {
    private _lastMapPosition: MapPosition;
    private _previousPaginator: MapPaginator;
    private headers = new HttpHeaders().set('Content-Type', 'application/json');
    constructor(
        @Inject(APP_CONFIG) private environment: any,
        private http: HttpClient
    ) { }

    public get lastMapPosition(): MapPosition {
        return this._lastMapPosition;
    }

    public set lastMapPosition(value: MapPosition) {
        this._lastMapPosition = value;
    }

    public get previousPaginator(): MapPaginator {
        return this._previousPaginator;
    }

    public set previousPaginator(value: MapPaginator) {
        this._previousPaginator = value;
    }

    getProjectsWithinArea(
        areaSelectionSquare: AreaSelectionSquare,
        perPage: number,
        pageIndex: number,
        hideLoader?: boolean
    ): Observable<ProjectLight[]> {
        const loaderState = hideLoader ? 'isLoaderHidden' : 'isHalfSize';

        const params = new HttpParams()
            .set(loaderState, 'true')
            .set('pageIndex', pageIndex.toString())
            .set('perPage', perPage.toString());

        return this.http
            .post(
                `${this.environment.apiPath}/taking-part/projects`,
                areaSelectionSquare,
                {
                    headers: this.headers,
                    params: params,
                }
            )
            .pipe(map((result) => result as ProjectLight[]));
    }

    getCountProjectsOnArea(
        areaSelectionSquare: AreaSelectionSquare,
        hideLoader?: boolean
    ): Observable<number> {
        const loaderState: string = hideLoader
            ? 'isLoaderHidden'
            : 'isHalfSize';
        const params = new HttpParams().set(loaderState, 'true');

        return this.http
            .post(
                `${this.environment.apiPath}/taking-part/projects/count`,
                areaSelectionSquare,
                {
                    headers: this.headers,
                    params: params,
                }
            )
            .pipe(map((result) => result as number));
    }
}
