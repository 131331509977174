<header>
    <img src="../../assets/images/presentation/furban_color.svg" />
</header>
<div class="header-title">
    <h5>Disclaimer</h5>
</div>
<div class="static-container">
    <p>
        Wij danken je voor het downloaden van de Furban app (de “App”). We
        wensen je een onvergetelijke Augmented Reality-ervaring!
    </p>
    <h2>Centric Netherlands</h2>
    <p>
        De Furban app wordt ter beschikking gesteld door Centric Netherlands,
        Antwerpseweg 8, 2803 PB Gouda. De app werk het beste als je ook een
        logincode van je gemeente hebt gekregen voor Furban. Door onze App te
        gebruiken dan wel te openen, gaat je akkoord met deze voorwaarden. Lees
        de voorwaarden zorgvuldig door.
    </p>
    <h2>De App & Services gebruiken</h2>
    <p>
        U moet deze voorwaarden en alle voorwaarden en regels naleven die in de
        App en/of de Services worden weergegeven en/of waarnaar in de App en/of
        de Services wordt verwezen. Je mag onze Services en de App niet
        misbruiken. Verstoor onze App en Services niet en probeer de Services
        en/of de App niet te openen via een andere methode dan volgens de
        interface en de instructies die wij leveren, of in strijd met deze
        voorwaarden. Je mag onze Services en App alleen gebruiken op de
        wettelijk toegestane wijze, van toepassing zijnde wet- en regelgeving
        met betrekking tot exporteren en her-exporteren daaronder begrepen. Wij
        kunnen de levering c.q. ter beschikking stelling van onze App en/of
        Services aan je opschorten of stopzetten als je niet voldoet aan onze
        voorwaarden of ons beleid of als we vermeend wangedrag onderzoeken of
        constateren. Door het gebruik van de App en/of onze Services verkrijgt
        je geen (intellectuele eigendoms-) rechten op onze App en/of Services.
        Deze voorwaarden verlenen je niet het recht de merken of logo’s te
        gebruiken die in onze App en/of Services worden gebruikt. Je mag de
        juridische informatie die in of bij onze App en/of Services wordt
        weergegeven, niet verwijderen, onleesbaar maken of aanpassen. In onze
        App en Services wordt bepaalde inhoud weergegeven of ter beschikking
        gesteld die geen eigendom is van ons. De entiteit die de inhoud
        beschikbaar stelt, is als enige verantwoordelijk voor die inhoud. Wij
        kunnen inhoud beoordelen om na te gaan of deze illegaal is of ons beleid
        schendt en we kunnen inhoud waarvan we redelijkerwijs hebben vastgesteld
        dat deze ons beleid of de wet schendt, verwijderen of niet weergeven.
        Dit betekent echter niet noodzakelijkerwijs dat wij inhoud beoordelen,
        ga er daarom niet vanuit dat wij dit doen. Gebruik de App niet op een
        manier waardoor je wordt afgeleid of waardoor je zich niet kunt houden
        aan de geldende verkeers- of veiligheidsregels.
    </p>
    <h2>Uw toegang tot onze App en/of Services</h2>
    <p>
        De toegang tot onze Services wordt gereguleerd via Centric Netherlands.
        Deze App is vrij toegankelijk en er zijn geen kosten aan verbonden. Er
        wordt geen gebruik gemaakt van specifieke gebruikersinformatie.
    </p>
    <h2>Gegevens die we gebruiken</h2>
    <p>
        Onze App heeft toegang tot de volgende items (“Gegevens”) op het
        apparaat:
    </p>
    <ul>
        <li>
            Algemene (netwerk gebaseerde) locatie en gedetailleerde (GPS)
            locatie
        </li>
        <li>Bewegingssensoren van het device</li>
        <li>Inhoud van extern opslagapparaat lezen</li>
        <li>Volledige internettoegang</li>
        <li>Voorkomen dat apparaat in slaapstand gaat</li>
        <li>Batterijgebruik</li>
        <li>Camera</li>
        <li>Je IP adres;</li>
    </ul>
    <p>
        De Services hebben heen directe toegang tot het device; de App neemt het
        initiatief om data die door de Services beschikbaar wordt gesteld, op te
        halen. Wij plaatsen geen cookies.
    </p>
    <h2>Privacy</h2>
    <p>
        De App maakt slechts verbinding met de Services om de laatste stand van
        de benodigde data op te halen. Het gebruik van de camera dient slechts
        om Augmented Reality content in relatie tot de werkelijke wereld te
        kunnen tonen. Camerabeelden worden door de App niet opgeslagen dan wel
        doorgegeven aan de Services. Er worden door de App of de Services geen
        gegevens van de gebruiker of het device vastgelegd. Je geeft toestemming
        voor het gebruik en de opslag van de data op uw device. In de App wordt
        op de achtergrond uw locatie opgevraagd met slechts het doel om je te
        informeren over locaties waar content getoond kan worden.
        Locatiegegevens worden niet aan de Services doorgegeven.
    </p>
    <h2>Over software in onze Services</h2>
    <p>
        Wanneer de App en/of een Service downloadbare software vereist of bevat,
        kan deze software automatisch worden bijgewerkt op uw apparaat wanneer
        er een nieuwe versie of functie beschikbaar komt. Centric Netherlands
        verleent je hierbij een persoonlijke, wereldwijde, niet-overdraagbare,
        niet-sublicentieerbare en niet-exclusieve licentie om de App te
        gebruiken. Deze licentie word je uitsluitend verstrekt om je in staat te
        stellen de door Centric Netherlands geleverde App te gebruiken en te
        benutten, op een wijze die voldoet aan deze voorwaarden. Je mag geen
        enkel deel van onze App of bijgeleverde software kopiëren, aanpassen,
        verspreiden, verkopen of leasen, en je mag de (broncode van) die
        software of deze App niet onderwerpen aan reverse-engineering of
        proberen deze te extraheren, tenzij dergelijke beperkingen bij de wet
        zijn verboden of je onze schriftelijke toestemming heeft gekregen. Deze
        licentie kan door Centric Netherlands op ieder moment worden beëindigd
        door middel van een daartoe strekkende kennisgeving, zonder terzake
        enigerlei (schade)vergoeding aan je verschuldigd te raken. Bepaalde
        software die wordt gebruikt in onze App of Services, kan worden
        aangeboden onder een open source-licentie of onderhevig zijn aan de
        intellectuele eigendomsrechten van derden. In dat geval zullen de open
        source-licentiebepalingen, respectievelijk de licentievoorwaarden van de
        desbetreffende derde(n), voorrang hebben boven deze voorwaarden. Als en
        voorzover de App of de Services geheel of gedeeltelijk bestaan uit of
        gebaseerd zijn op programmatuur waar Centric Netherlands niet de (enig)
        rechthebbende van is geldt, zal Centric Netherlands nooit meer rechten
        verstrekken dan zij van haar derde toeleverancier(s) heeft verkregen.
    </p>
    <h2>Onze App/ Services aanpassen en beëindigen</h2>
    <p>
        Wij zijn voortdurend bezig onze App en Services aan te passen en te
        verbeteren. Wij kunnen functies of mogelijkheden daaraan toevoegen of
        daaruit verwijderen. Ook kunnen wij je gebruiksrecht terzake van de App
        (en in voorkomend geval: de Services) opschorten of zelfs volledig
        stopzetten. Je kunt je gebruik van onze Services en/of de App op elk
        gewenst moment stopzetten. Centric Netherlands kan ook op elk gewenst
        moment de levering van Services aan je stopzetten of nieuwe beperkingen
        toevoegen aan of instellen voor onze App en/of Services. Bovenstaande
        bevoegdheden mag Centric Netherlands naar eigen inzicht en op ieder
        gewenst moment uitoefenen, zonder terzake enigerlei (schade)vergoeding
        aan je verschuldigd te raken.
    </p>
    <h2>Garanties en voorbehouden</h2>
    <p>
        ANDERS DAN UITDRUKKELIJK UITEENGEZET IN DEZE VOORWAARDEN OF AANVULLENDE
        VOORWAARDEN, DOEN CENTRIC NETHERLANDS EN HAAR TOELEVERANCIERS OF
        DISTRIBUTEURS GEEN SPECIFIEKE BELOFTEN MET BETREKKING TOT DE SERVICES EN
        DE APP. WIJ DOEN BIJVOORBEELD GEEN TOEZEGGINGEN OVER DE INHOUD IN DE
        SERVICES, DE SPECIFIEKE FUNCTIONALITEIT VAN DE SERVICES OF DE APP EN DE
        BETROUWBAARHEID, BESCHIKBAARHEID OF MOGELIJKHEID DAARVAN OM TE VOLDOEN
        AAN UW BEHOEFTEN. WIJ LEVEREN DE APP EN (IN VOORKOMEND GEVAL:) DE
        SERVICES ALS ZODANIG (‘AS IS’). SOMMIGE RECHTSGEBIEDEN STELLEN
        VOORWAARDEN AAN BEPAALDE GARANTIES, ZOALS DE IMPLICIETE GARANTIE VAN
        VERHANDELBAARHEID, GESCHIKTHEID VOOR EEN BEPAALD DOEL EN
        NIET-INBREUKMAKENDHEID. VOOR ZOVER WETTELIJK TOEGESTAAN, SLUITEN WIJ
        ALLE IMPLICIETE GARANTIES UIT. CHEESE EXPRERIENCE GARANDEERT NIET DAT
        VIA DE SERVICES EN/OF DE APP VERSTUURDE (PUSH) BERICHTEN, NOTIFICATIES
        EN/OF ALERTERINGEN (TIJDIG) WORDEN ONTVANGEN DOOR DE PERSONEN EN/OF
        ONTVANGERS VOOR WIE DEZE BERICHTEN, NOTIFICATIES EN/OF ALERTERINGEN
        BESTEMD ZIJN. CENTRIC NETHERLANDS, DE AAN HAAR GELIEERDE BEDRIJVEN EN
        HAAR TOELEVERANCIERS ZULLEN TERZAKE EN VOOR ALLE SAMENHANGENDE GEVOLGEN
        NIET VERANTWOORDELIJK EN/OF AANSPRAKELIJK ZIJN. CENTRIC NETHERLANDS
        GARANDEERT NIET DAT DE BERICHTEN DIE je VIA DE SERVICES EN/OF DE APP
        ONTVANGT OF VERSTUURT JUIST EN/OF VOLLEDIG ZIJN EN/OF TIJDIG ONTVANGEN
        WORDEN. CENTRIC NETHERLANDS, HAAR TOELEVERANCIERS EN DE AAN HAAR
        GELIEERDE BEDRIJVEN ZIJN TERZAKE NIET VERANTWOORDELIJK EN NIET
        AANSPRAKELIJK. Aansprakelijkheid voor onze App/Services VOORZOVER
        WETTELIJK TOEGESTAAN, IS IEDERE AANSPRAKELIJKHEID VAN CENTRIC
        NETHERLANDS, HAAR TOELEVERANCIERS EN DE AAN CENTRIC NETHERLANDS
        GELIEERDE BEDRIJVEN VOLLEDIG UITGESLOTEN. DEZE UITSLUITING HEEFT (TEVENS
        MAAR NIET UITSLUITEND) BETREKKING OP:
    </p>
    <ul>
        <li>
            SCHADE BESTAANDE UIT OF VERBAND HOUDENDE MET (DE GEVOLGEN VAN)
            LETSEL EN/OF DOOD;
        </li>
        <li>IEDERE ANDERE VORM VAN SCHADE;</li>
        <li>
            CLAIMS UIT HOOFDE VAN ONRECHTMATIGE DAAD, CLAIMS UIT HOOFDE VAN
            WANPRESTATIE, EN OP CLAIMS UIT HOOFDE VAN IEDERE ANDERE (RECHTS)
            GROND.
        </li>
    </ul>
    <h2>Over deze Voorwaarden</h2>
    <p>
        Wij kunnen deze voorwaarden aanpassen bijvoorbeeld om wijzigingen in de
        wet of wijzigingen in onze App of Services te weerspiegelen. Je moet
        deze voorwaarden regelmatig bekijken. Wij posten kennisgevingen van
        aangepaste (aanvullende) voorwaarden in de betreffende Services en/of de
        App. Wijzigingen worden niet met terugwerkende kracht toegepast en
        worden niet eerder van kracht dan veertien dagen nadat ze zijn gepost.
        Wijzigingen als gevolg van nieuwe functies of wijzigingen die om
        juridische redenen worden aangebracht, worden echter onmiddellijk van
        kracht. Als je niet akkoord gaat met de aangepaste voorwaarden, moet je
        uw gebruik van de Services en de App onmiddellijk beëindigen. Deze
        voorwaarden beheersen de relatie tussen Centric Netherlands en jou over
        de App en (in voorkomend geval) onze Services. Aan de voorwaarden kunnen
        geen rechten voor derden worden ontleend. Als je niet voldoet aan deze
        voorwaarden en wij niet onmiddellijk actie ondernemen, betekent dit niet
        dat wij afstand doen van eventuele rechten waarover beschikken (zoals
        het recht om in de toekomst actie te ondernemen). Als blijkt dat een
        bepaalde voorwaarde niet uitvoerbaar is, is dit niet van invloed op de
        andere voorwaarden. Op deze voorwaarden en het gebruik van de App en de
        Services is het Nederlands recht van toepassing. Geschillen dienen in
        eerste aanleg bij uitsluiting te worden voorgelegd aan de bevoegde
        rechtbank te Rotterdam.
    </p>
</div>
