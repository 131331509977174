import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { routingConstants } from '@furban/utilities';

@Component({
    selector: 'furban-login-wrapper',
    templateUrl: './login-wrapper.component.html',
    styleUrls: ['./login-wrapper.component.scss'],
})
export class LoginWrapperComponent {
    @Input() stylingClass: string;
    @Input() infoText: string;

    constructor(public router: Router) { }
    navigateToWebsite(): void {
        this.router.navigate([routingConstants.website]);
    }

    goToAdminRegister(): void {
        this.router.navigate([routingConstants.adminRegister]);
    }
}
