import {
    OnInit,
    Component,
    Input,
    Output,
    EventEmitter,
    OnDestroy
} from '@angular/core';

import {
    AuthService,
    Project,
    ProjectStorageService,
    CustomToasterComponent,
    routingConstants,
    CustomToasterService,
    MediaService
} from '@furban/utilities';

import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProjectDetailsService } from '../../project-shared/project-details/project-details.service';
import { ChatService } from '../chat/chat.service';

@Component({
    selector: 'furban-projects-dropdown',
    templateUrl: './projects-dropdown.component.html',
    styleUrls: ['./projects-dropdown.component.scss'],
})
export class ProjectsDropdownComponent implements OnInit, OnDestroy {
    @Input() disabled: boolean;
    @Output() chatSessionCreated = new EventEmitter<Project>();

    public curentSelectedProject: Project;
    public dropdownOpen: boolean;
    public projects: Project[];
    private projectsListSubscription: Subscription;

    constructor(
        private authService: AuthService,
        private chatService: ChatService,
        private projectStorageService: ProjectStorageService,
        private projectDetailsService: ProjectDetailsService,
        private customToasterService: CustomToasterService,
        private mediaService: MediaService,
        private router: Router
    ) {
        projectStorageService.onProjectListModified.subscribe((item) => {
            this.initialiseDropdown();
        });
    }

    ngOnDestroy(): void {
        this.projectDetailsService.allProjects = null;
        this.projectDetailsService.myProjects = null;
        this.projectsListSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.initialiseDropdown();
    }

    public getMedia(mediaId): string {
        return this.mediaService.getMedia(mediaId);
    }

    initialiseDropdown() {
        if (this.projectDetailsService.allProjects) {
            this.setProjectsList(this.projectDetailsService.allProjects);
        }

        this.projectsListSubscription =
            this.projectDetailsService.allProjectsListObservable.subscribe(
                (data) => {
                    this.setProjectsList(data);
                }
            );
    }

    selectProject(project: Project) {
        if (this.curentSelectedProject.projectId !== project.projectId) {
            if (this.authService.isCitizen()) {
                this.chatService.signarure = null;
            }
            this.changeProject(project);

            if (this.checkIfOnDesignProposal()) {
                this.router.navigate([routingConstants.designs, project?.projectId]);
            } else if (this.checkIfOnCitizenProjectDashboard()) {
                this.navigateFromCitizenProjectDashboard(project);
            }
        }
    }

    checkIfOnDesignProposal(): boolean {
        return (
            this.router.url.includes('design-proposal') &&
            this.authService.isCitizenOrExpertOrCollaborator
        );
    }

    checkIfOnCitizenProjectDashboard(): boolean {
        return (
            this.router.url.includes('project') &&
            (this.authService.isCitizen() ||
                this.authService.isExpert() ||
                this.authService.isCollaborator())
        );
    }

    private setProjectsList(data: Project[]): void {
        this.projects = data;
        if (this.projects.length <= 0) {
            return;
        }
        if (!this.projectStorageService.curentProject) {
            this.projectStorageService.changeProject(this.projects[0]);
            this.curentSelectedProject = this.projects[0];
        } else {
            this.curentSelectedProject =
                this.projectStorageService.curentProject;
            const found = this.projects.find(
                (proj) =>
                    proj.projectId === this.curentSelectedProject.projectId
            );

            if (!found) {
                this.projectStorageService.changeProject(this.projects[0]);
                this.curentSelectedProject = this.projects[0];
            }
            this.projectStorageService.emitCurentProject();
        }
    }

    private showToaster(label: string) {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'info',
            'info',
            label,
            1000
        );
    }

    private navigateFromCitizenProjectDashboard(project: Project) {
        if (!project.ended && project.allowCitizenDesigns) {
            this.router.navigate([routingConstants.project, project.projectId]);
        } else {
            this.router.navigate([routingConstants.dashboard]);
            this.showToaster('user.dashboard.redirectDashboard');
        }
    }

    private changeProject(project: Project) {
        this.projectStorageService.changeProject(project);
        this.curentSelectedProject = project;
        this.projectStorageService.curentProject = project;
    }
}
