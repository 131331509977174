import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { CommentFormComponent } from '../comment/comment-form/comment-form.component';
import { Comment } from '../_models/comment';
import { DesignProposal } from '../_models/design-proposal';
import { PermitComment } from '../_models/permit-comment';
import { AuthService } from '../_services/auth.service';

@Component({
    selector: 'furban-three-comment-popup',
    templateUrl: './three-comment-popup.component.html',
    styleUrls: ['./three-comment-popup.component.scss'],
})
export class ThreeCommentPopupComponent {
    @Input() public dp: DesignProposal;
    @Input() public permitProjectId: string;

    @Input() position?: string;
    @Input() rotation?: string;
    @Input() comment: Comment | PermitComment;
    @Input() isViewOnly?: boolean;

    @Output() closePopUp: EventEmitter<boolean> = new EventEmitter();
    @Output() savedComment: EventEmitter<Comment | PermitComment> =
        new EventEmitter();

    public displayOnlyText: boolean;
    public emojiOpened = false;

    @ViewChild('commentForm') commentForm: CommentFormComponent;

    constructor(
        public viewContainerRef: ViewContainerRef,
        private authService: AuthService
    ) {}

    onSaveComment(comment: Comment | PermitComment): void {
        this.savedComment.emit(comment);
    }

    closeDialog(): void {
        this.closePopUp.emit(false);
    }

    setComment(comment: Comment | PermitComment): void {
        this.comment = comment;
        this.checkIfShouldDisplayForm();
        if (this.displayOnlyText) {
            return;
        }
        this.position = comment.position;
        this.rotation = comment.rotation;
        this.commentForm?.resetComment(comment);
    }

    onToggleEmoji(emojiState: boolean): void {
        this.emojiOpened = emojiState;
    }

    private checkIfShouldDisplayForm(): void {
        this.displayOnlyText = false;
        if (!this.comment) {
            return;
        }
        if (
            this.isViewOnly ||
            this.comment.userProfile?.userProfileId !==
                this.authService.userProfile.userProfileId ||
            this.comment.commentId
        ) {
            this.displayOnlyText = true;
        }
    }
}
