<ng-container [ngSwitch]="svgImage">
    <svg
        *ngSwitchCase="svgImageType.changePerspective"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.272 8.2L14.37 9.4H16.8V16.6H7.2V9.4H9.63L10.728 8.2H13.272V8.2ZM13.8 7H10.2L9.102 8.2H7.2C6.54 8.2 6 8.74 6 9.4V16.6C6 17.26 6.54 17.8 7.2 17.8H16.8C17.46 17.8 18 17.26 18 16.6V9.4C18 8.74 17.46 8.2 16.8 8.2H14.898L13.8 7ZM12 11.2C12.99 11.2 13.8 12.01 13.8 13C13.8 13.99 12.99 14.8 12 14.8C11.01 14.8 10.2 13.99 10.2 13C10.2 12.01 11.01 11.2 12 11.2ZM12 10C10.344 10 9 11.344 9 13C9 14.656 10.344 16 12 16C13.656 16 15 14.656 15 13C15 11.344 13.656 10 12 10Z"
            fill="#00578A"
        />
        <path
            d="M7.47 21.48C4.2 19.94 1.86 16.76 1.5 13H0C0.51 19.16 5.66 24 11.95 24L12.61 23.97L8.8 20.16L7.47 21.48ZM11.95 0L11.29 0.03L15.1 3.84L16.43 2.51C19.7 4.06 22.04 7.23 22.39 10.99H23.89C23.39 4.84 18.24 0 11.95 0Z"
            fill="#00578A"
        />
    </svg>

    <svg
        *ngSwitchCase="svgImageType.undoDelete"
        width="21"
        height="18"
        viewBox="0 0 21 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="10" y="7" width="4" height="5" fill="#00578A" />
        <path
            d="M13.7143 7.57143V11.8571H10.2857V7.57143H13.7143ZM13.0714 5H10.9286L10.5 5.42857H9V6.28571H15V5.42857H13.5L13.0714 5ZM14.5714 6.71429H9.42857V11.8571C9.42857 12.3286 9.81429 12.7143 10.2857 12.7143H13.7143C14.1857 12.7143 14.5714 12.3286 14.5714 11.8571V6.71429Z"
            fill="#00578A"
        />
        <path
            d="M12 0C7.03 0 3 4.03 3 9H0L4 13L8 9H5C5 5.13 8.13 2 12 2C15.87 2 19 5.13 19 9C19 12.87 15.87 16 12 16C10.49 16 9.09 15.51 7.94 14.7L6.52 16.14C8.04 17.3 9.94 18 12 18C16.97 18 21 13.97 21 9C21 4.03 16.97 0 12 0Z"
            fill="#00578A"
        />
    </svg>
</ng-container>
