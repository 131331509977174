import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'apps/furban-client/src/environments/environment';
import { SilenceHttpParams, UserProfile } from '@furban/utilities';

@Injectable()
export class UserProfileService {
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(private http: HttpClient) {}

    uploadPhoto(size: number, title: string, media: string) {
        const REQUEST_DATA = {
            title: title, // optional
            contentType: this.getContentType(media),
            content: media,
            fileName: title,
        };

        return this.http
            .put(environment.apiPath + '/media/profile', REQUEST_DATA, {
                headers: this._headers,
                responseType: 'text',
            })
            .pipe(map((result) => result));
    }

    getProfile(userId) {
        return this.http
            .get(`${environment.apiPath}/userprofile/${userId}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result));
    }

    getProfileByUserProfileId(userProfileId: string) {
        return this.http
            .get(
                `${environment.apiPath}/userprofile/profile/${userProfileId}`,
                {
                    headers: this._headers,
                    params: new SilenceHttpParams(true, true, false),
                }
            )
            .pipe(map((result) => result as UserProfile));
    }

    createProfile(userProfile: UserProfile) {
        return this.http
            .put(environment.apiPath + '/userprofile', userProfile, {
                headers: this._headers,
            })
            .pipe(map((result) => result));
    }

    updateProfile(userProfile: UserProfile): Observable<UserProfile> {
        return this.http
            .post(environment.apiPath + '/userprofile', userProfile, {
                headers: this._headers,
            })
            .pipe(map((result) => result as UserProfile));
    }

    getActiveCollaboratorUserProfies(
        groupId: string
    ): Observable<UserProfile[]> {
        return this.http
            .get(
                `${environment.apiPath}/userprofile/collaborators/${groupId}`,
                { headers: this._headers }
            )
            .pipe(map((result) => result as UserProfile[]));
    }

    getAllCollaboratorUserProfies(groupId: string): Observable<UserProfile[]> {
        return this.http
            .get(
                `${environment.apiPath}/userprofile/collaborators/all/${groupId}`,
                { headers: this._headers }
            )
            .pipe(map((result) => result as UserProfile[]));
    }

    // REFACTOR OR ADD DOCUMENTATION
    getContentType(content: string): string {
        if ('' === content) {
            return '';
        }
        if (content.indexOf('data:') < 0) {
            return '';
        }
        const endIndex = content.indexOf(';');
        if (endIndex < 0) {
            return '';
        }
        return content.substring(5, endIndex);
    }
}
