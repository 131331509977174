<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'user.projectMenu.customObjectDefine' | translate }}</h2>
    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons">close</span>
    </button>
</div>

<mat-dialog-content>
    <form #customObjectForm="ngForm" class="padding-20-0 flex-column-end" name="loginForm"
        [formGroup]="customObjectCheckForm">
        <mat-form-field>
            <input required matInput placeholder="{{
                    'user.projectMenu.customObjectWidth' | translate
                }}" min="0" max="40" formControlName="widthFormControl" type="number" />
            <mat-error *ngIf="hasControlRequiredError('widthFormControl',customObjectCheckForm)">
                {{ 'user.projectMenu.customObjectWidthRequired' | translate }}
            </mat-error>
            <mat-error
                *ngIf="hasControlMaxError('widthFormControl',customObjectCheckForm) || hasControlMinError('widthFormControl',customObjectCheckForm)">
                {{ 'user.projectMenu.customObjectValue' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>
                {{ 'user.projectMenu.customObjectHeight' | translate }}
            </mat-label>
            <input required matInput placeholder="{{
                    'user.projectMenu.customObjectHeight' | translate
                }}" min="0" max="40" formControlName="heigthFormControl" type="number" />
            <mat-error *ngIf="hasControlRequiredError('heigthFormControl',customObjectCheckForm)">
                {{ 'user.projectMenu.customObjectHeightRequired' | translate }}
            </mat-error>
            <mat-error
                *ngIf="hasControlMaxError('heigthFormControl',customObjectCheckForm) || hasControlMinError('heigthFormControl',customObjectCheckForm)">
                {{ 'user.projectMenu.customObjectValue' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>
                {{ 'user.projectMenu.customObjectDepth' | translate }}
            </mat-label>
            <input required matInput placeholder="{{
                    'user.projectMenu.customObjectDepth' | translate
                }}" min="0" max="40" formControlName="depthFormControl" type="number" />
            <mat-error *ngIf="hasControlRequiredError('depthFormControl',customObjectCheckForm)">
                {{ 'user.projectMenu.customObjectDepthRequired' | translate }}
            </mat-error>
            <mat-error
                *ngIf="hasControlMaxError('depthFormControl',customObjectCheckForm) || hasControlMinError('depthFormControl',customObjectCheckForm)">
                {{ 'user.projectMenu.customObjectValue' | translate }}
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closeDialog()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="saveCustomObject()">
        <h6>{{ 'generic.save' | translate }}</h6>
    </button>
</div>