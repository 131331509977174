import { ViewContainerRef } from '@angular/core';
import { ThreeInstance } from './three-instance';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { ModalManager } from '../furban-modal/furban-modal.manager';
import { FurbanUtil } from '../helpers/furbanUtil';

export class ThreeGLTFExportHelper {
    public exportGLTF(threeInstance: ThreeInstance, fileName: string, modal: ModalManager, viewContainerRef: ViewContainerRef): void {
        if (FurbanUtil.isApp()) {
            modal.showModal(
                viewContainerRef,
                ModalManager.createConfiguration(
                    'buttonsTooltips.exportDesign',
                    'errors.exportDesignMobileConstraint',
                    'generic.okBtn'
                )
            )
            .subscribe();
            return;
        }        

        threeInstance.groundGroup.children[0].visible = false;

        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link); // Firefox workaround, see #6594

        const exporter = new GLTFExporter();

        const options = {
            trs: true,
            onlyVisible: true,
            truncateDrawRange: true,
            binary: true,
            forcePowerOfTwoTextures: true,
            maxTextureSize: Infinity, // To prevent NaN value
        };
        exporter.parse(
            threeInstance.scene,
            (result) => {
                if (result instanceof ArrayBuffer) {
                    this.saveArrayBuffer(result, fileName + '.glb', link);
                } else {
                    const output = JSON.stringify(result, null, 2);
                    this.saveString(output, fileName + '.gltf', link);
                }
            },
            (err) => {
                console.error(err);
            },
            options
        );
        threeInstance.groundGroup.children[0].visible = true;
    }

    saveString(text: any, filename: any, link: any) {
        this.save(new Blob([text], { type: 'text/plain' }), filename, link);
    }

    saveArrayBuffer(buffer: any, filename: any, link: any) {
        this.save(
            new Blob([buffer], { type: 'application/octet-stream' }),
            filename,
            link
        );
    }

    save(blob: any, filename: any, link: any) {
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }
}
