<ng-container *ngIf="isNotLoggedInAndIsNotPublic()">
    <furban-homepage-header></furban-homepage-header>
</ng-container>

<ng-container *ngIf="!isLoggedInOrIsNotViewOnly()">
    <furban-configurable-header
        [backRoute]="headerBackRoute"
    ></furban-configurable-header>
</ng-container>

<div
    class="main-container flex-row-start"
    [class.public-page-container]="!isLoggedInOrIsNotViewOnly()"
    [class.full-width]="!isRightMenuVisible"
>
    <ng-container *ngIf="threeIsPrepared">
        <div class="three-container" [class.full-width]="!isRightMenuVisible">
            <furban-public-three-js-editor
                [userObjects]="userObjects"
                [state]="threeState"
                [showButtons]="!isViewerOnly"
                [userProfileId]="userProfileId"
                [project]="project"
                [designProposal]="designProposal"
                (threeJSCommentSave)="onThreeJSCommentSave($event)"
                (rightMenuVisibiltyChanged)="
                    updateRightSideMenuVisibilty($event)
                "
            >
            </furban-public-three-js-editor>
            <ng-container *ngIf="isLoggedInOrIsNotViewOnly()">
                <div class="floating-buttons left" (click)="goBack()">
                    <span class="material-icons"> arrow_back </span>
                    <h6>{{ 'generic.back' | translate }}</h6>
                </div>
            </ng-container>
        </div>

        <!-- REFACTOR: right menu should be a components -->
        <ng-container *ngIf="project && !isViewerOnly && isRightMenuVisible">
            <section class="right-side-section">
                <section class="project-info-header">
                    <div
                        class="flex-row-spaced full-width"
                        [style.margin-bottom.px]="12"
                    >
                        <div class="title-container">
                            <ng-container *ngIf="shouldDisplayWarning()">
                                <span
                                    class="material-icons reported-icon"
                                    [matTooltip]="getTooltipTextForWarning()"
                                >
                                    warning
                                </span>
                            </ng-container>
                            <h2 class="ellipsis">{{ designProposal.title }}</h2>
                        </div>
                        <ng-container *ngIf="shouldDisplayMenu()">
                            <span
                                class="material-icons pointer"
                                [matMenuTriggerFor]="menuAdd"
                            >
                                more_vert
                            </span>
                            <mat-menu #menuAdd="matMenu" overlapTrigger="false">
                                <button
                                    mat-menu-item
                                    (click)="deleteReporting(designProposal)"
                                >
                                    {{ 'report.deleteReportBtn' | translate }}
                                </button>
                            </mat-menu>
                        </ng-container>
                    </div>
                    <div class="like-container">
                        <ng-container *ngIf="showDpPrice()">
                            <h4>
                                {{ 'price.price' | translate }}
                                {{
                                    designProposal.price
                                        | currency : 'EUR' : 'symbol' : '0.0-2'
                                }}
                            </h4>
                        </ng-container>

                        <furban-like
                            [dp]="designProposal"
                            [disabled]="project.ended || !isLoggedIn"
                        ></furban-like>
                    </div>

                    <h4>{{ designProposal.description }}</h4>
                </section>
                <div
                    class="comments-container container-for-scroll"
                    [class.price-active]="designProposal.price"
                >
                    <!-- REFACTOR: type should be enum -->
                    <furban-comments
                        *ngIf="authService.isLoggedIn(); else loggedOut"
                        #comments
                        class="comments"
                        [dp]="designProposal"
                        [commentId]="commentId"
                        [type]="1"
                        [isDialog]="false"
                        [project]="project"
                        [parentViewContainerRef]="viewContainerRef"
                        (reportOutput)="report($event)"
                    >
                    </furban-comments>

                    <ng-template #loggedOut>
                        <div class="flex-column-center padding-20 full-width">
                            <img
                                src="../../../assets/images/no_comm.svg"
                                [style.height.px]="200"
                                [style.margin-bottom.px]="20"
                            />
                            <p class="no-comments">
                                {{
                                    'comments.commentsNotAvailable' | translate
                                }}
                            </p>
                        </div>
                    </ng-template>
                </div>
            </section>
        </ng-container>
    </ng-container>
</div>
