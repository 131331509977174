<div class="default-designs-container" (scroll)="onScroll($event)">
    <div class="cards">
        <furban-app-card
            class="admin-card"
            [style.height]="cardConfig.height + 'px'"
            [card]="cardConfig"
            [backgroundImage]="'../../../assets/images/create_new_design.png'"
            (click)="createMunicipalityDesign()"
        >
            <div card-type="content">
                <span
                    class="material-icons large-icon"
                    [style.padding-bottom.px]="56"
                    >library_add</span
                >
            </div>
            <div card-type="footer">
                {{ 'admin.dashboard.newMunicipalityDP' | translate }}
            </div>
        </furban-app-card>

        <div *ngFor="let dp of curentDPs">
            <furban-admin-card
                [userProfile]="dp.userProfile"
                [title]="dp.title"
                [cardMedia]="dp.media"
                (cardClickedEmitter)="goToDP(dp)"
                (deleteClickedEmitter)="
                    showDeleteWarningPopup(dp.designProposalId)
                "
            >
            </furban-admin-card>
        </div>
    </div>
</div>
