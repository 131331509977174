import { Component } from '@angular/core';

import { MultilanguageService } from '@furban/utilities';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'furban-cookie-policy-dialog',
    templateUrl: './cookie-policy-dialog.component.html',
    styleUrls: ['./cookie-policy-dialog.component.scss'],
})
export class CookiePolicyDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<CookiePolicyDialogComponent>,
        public multilanguageService: MultilanguageService
    ) { }

    public closeDialog(): void {
        this.dialogRef.close(false);
    }
}
