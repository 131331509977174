import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Group, Object3D, Vector2, Vector3 } from 'three';

import { FurbanUtil } from '../helpers/furbanUtil';
import { PermitActions } from '../helpers/permit-actions';
import { PermitUtils } from '../helpers/permit.utils';
import { ThreeRoofBuilder } from '../helpers/three-roof-builder';
import { ThreeWallBuilder } from '../helpers/three-wall-builder';
import { PermitAssetNamesEnum } from '../_enum/permit-asset-names.enum';
import { PermitDefaultValues } from '../_enum/permit-default-values.enum';
import { PermitMenuControlsEnum } from '../_enum/permt-menu-controls.enum';
import { ToolingButtonsEnum } from '../_enum/tooling-buttons.enum';
import { AssetColorAndMaterial } from '../_models/asset-color-and-material';
import { CreatedHouse } from '../_models/created-house';
import { House } from '../_models/house';
import { HouseAsset } from '../_models/house-asset';
import { HouseSceneConfig } from '../_models/house-config';
import { PermitAsset } from '../_models/permit-asset';
import { PermitComment } from '../_models/permit-comment';
import { PermitKeyboardType } from '../_models/permit-keyboard-type';
import { XY } from '../_models/xy';

@Injectable({
    providedIn: 'root',
})
export class PermitThreeService {
    public assetToAdd: PermitAsset;
    public loadedAsset: Group;
    public house: House;
    public assetsToDelete: HouseAsset[] = [];
    public assetObjects: Group;
    public roofAssetGroup: Group;
    public extensionGroup: Group;

    public solarPanels: Group;
    public dormersGroup: Group;
    public projId: string;
    public houseLoadedObservable: Subject<boolean> = new Subject();
    public buttonPressed: Subject<ToolingButtonsEnum> = new Subject();
    public buttonPressedObservable = this.buttonPressed.asObservable();
    public selectEventsAdded: Subject<boolean> = new Subject();
    public selectEventsAddedObservable = this.selectEventsAdded.asObservable();
    public attachEventsAdded: Subject<number> = new Subject();
    public attachEventsAddedObservable = this.attachEventsAdded.asObservable();
    public attachEventsRemoved: Subject<boolean> = new Subject();
    public attachEventsRemovedObservable =
        this.attachEventsRemoved.asObservable();
    public placeObjectsInLineEventsAdded: Subject<boolean> = new Subject();
    public placeObjectsInLineEventsAddedObservable =
        this.placeObjectsInLineEventsAdded.asObservable();
    public multiselectEventsAdded: Subject<boolean> = new Subject();
    public multiselectEventsAddedObservable = this.multiselectEventsAdded.asObservable();
    public multiselectPerformedEvents: Subject<boolean> = new Subject();
    public multiselectPerformedObservable = this.multiselectPerformedEvents.asObservable();
    public disableToolEvents: Subject<boolean> = new Subject();
    public disableToolObservable = this.disableToolEvents.asObservable();
    public selectControls: Subject<PermitMenuControlsEnum> = new Subject();
    public selectControlsObservable = this.selectControls.asObservable();
    public assetColorAndMaterialChangeEvent: Subject<AssetColorAndMaterial> = new Subject();
    public assetColorAndMaterialChangeObservable = this.assetColorAndMaterialChangeEvent.asObservable();
    public detachPopupToPin: Subject<boolean> = new Subject();
    public detachPopupToPinObservable = this.detachPopupToPin.asObservable();
    public savePinData: Subject<PermitComment> = new Subject();
    public savePinDataObservable = this.savePinData.asObservable();
    public addPinEvents: Subject<boolean> = new Subject();
    public addPinEventsObservable = this.addPinEvents.asObservable();

    public keyboardPressEvent: Subject<PermitKeyboardType> = new Subject();
    public keyboardPressObservable = this.keyboardPressEvent.asObservable();

    public houseHeightUpdateEvent: Subject<number> = new Subject();
    public houseHeightUpdateObservable = this.houseHeightUpdateEvent.asObservable();

    public houseUpdatedEmitter: EventEmitter<House> = new EventEmitter();

    public drawLine(): void {
        this.attachEventsRemoved.next(true);
        this.selectEventsAdded.next(false);
        this.multiselectEventsAdded.next(false);
        this.placeObjectsInLineEventsAdded.next(true);
    }

    public finishedDrawingLine(): void {
        this.selectEventsAdded.next(true);
        this.placeObjectsInLineEventsAdded.next(false);
        this.disableToolEvents.next(true);

    }

    public activateMultiselect(): void {
        this.attachEventsRemoved.next(true);
        this.selectEventsAdded.next(false);
        this.placeObjectsInLineEventsAdded.next(false);
        this.multiselectEventsAdded.next(true);
    }

    public deactivateMultiselect(): void {
        this.selectEventsAdded.next(true);
        this.multiselectEventsAdded.next(false);
        this.disableToolEvents.next(true);
    }

    public clearAssetGroup(): void {
        if (!this.assetObjects) {
            return;
        }
        this.assetObjects.remove(...this.assetObjects.children);
    }

    public hideAssets(): void {
        if (!this.assetObjects) {
            return;
        }
        this.assetObjects.visible = false;
    }

    public showAssets(): void {
        if (!this.assetObjects) {
            return;
        }
        this.assetObjects.visible = true;
    }

    public createHouse(instance: HouseSceneConfig): number {
        const createdHouse = this.createGenericHouse(instance, this.house);
        const groupChildren = createdHouse.groupChildren;
        if (!instance.withAnimation) {
            PermitActions.getChildrenFromGroupWithoutAnimation(groupChildren);
            this.showAssets();
        } else {
            this.makeSubscriptionForGroupAnimation(groupChildren);
        }

        return createdHouse.houseHeight;
    }

    public createNeighborHouse(instance: HouseSceneConfig, house: House): void {
        const createdHouse = this.createGenericHouse(instance, house);
        const groupChildren = createdHouse.groupChildren;
        PermitActions.getChildrenFromGroupWithoutAnimation(groupChildren);
    }

    private createGenericHouse(
        instance: HouseSceneConfig,
        house: House
    ): CreatedHouse {
        this.hideAssets();
        const wallBuilder = new ThreeWallBuilder(house);
        const walls = wallBuilder.createHouseWalls();
        walls.castShadow = true;
        walls.receiveShadow = true;

        let currentCoordinates = FurbanUtil.deepCopy(house.xYCoordinates);
        currentCoordinates.pop();
        currentCoordinates = FurbanUtil.reduceCoordinatesToMaxYAndMinX(
            currentCoordinates,
            instance.maxY,
            instance.minX
        );
        const centroid = FurbanUtil.getPolygonCentroid(currentCoordinates);

        walls.position.x = centroid.x;
        walls.position.z = -centroid.y;

        instance.scene.add(walls);

        if (!house.isNeighborHouse) {
            const pointToLookAt = new Vector3(centroid.x, 0, -centroid.y);
            instance.camera.lookAt(pointToLookAt);
            instance.controls.target = pointToLookAt;
            instance.camera.updateProjectionMatrix();
            instance.controls.update();
        }

        const roofGroup = this.addRoofToHouse(
            house,
            walls.userData['constructionHeight'],
            instance,
            centroid
        );
        const groupChildren: Object3D[] = walls.children.concat(
            roofGroup.children
        );
        return new CreatedHouse(groupChildren, walls.userData['constructionHeight']);
    }

    private addRoofToHouse(
        house: House,
        constructionHeight: number,
        instance: HouseSceneConfig,
        centroid: XY
    ): Group {
        const roofGroupName = house.isNeighborHouse
            ? PermitAssetNamesEnum.neighborRoofGroup
            : PermitAssetNamesEnum.roofGroup;
        const roofBuilder = new ThreeRoofBuilder(
            house.roof,
            house.processedCoordinatesForThree,
            roofGroupName
        );
        const wallDivision = roofBuilder.createRoofDivision(constructionHeight);
        const roofGroup = new Group();
        roofGroup.name = roofGroupName;
        roofGroup.add(wallDivision);
        roofGroup.position.x = centroid.x;
        roofGroup.position.z = -centroid.y;
        instance.scene.add(roofGroup);
        return roofGroup;
    }

    public makeSubscriptionForGroupAnimation(groupChildren: Object3D[]): void {
        PermitActions.getChildrenFromGroup(groupChildren).subscribe(
            {
                next: (data) => {
                    PermitActions.setupPositionAndMakeAnimation(data);
                },
                complete: () => {
                    setTimeout(() => {
                        this.houseLoadedObservable.next(true);
                    }, PermitDefaultValues.animationTime);
                }
            }
        );
    }

    public updateHouseValues(
        house: House,
        houseSceneConfig: HouseSceneConfig
    ): void {
        if (
            house.houseMaterial.backgroundImage !==
            this.house.houseMaterial.backgroundImage
        ) {
            this.house.houseMaterial = house.houseMaterial;
            this.changeHouseAndExtensionsTexture(house, houseSceneConfig);
            this.changeHouseAndExtensionsColor(houseSceneConfig);
        }
        if (
            house.roof.roofMaterial.backgroundImage !==
            this.house.roof.roofMaterial.backgroundImage
        ) {
            this.house.roof.roofMaterial = house.roof.roofMaterial;
            this.changeRoofAndRoofExtensionMaterialTexture(
                house,
                houseSceneConfig
            );
            this.changeRoofAndRoofExtensionColor(house, houseSceneConfig);
        }
        if (house.numberOfFloors !== this.house.numberOfFloors) {
            this.house.numberOfFloors = house.numberOfFloors;
            this.reloadHouse(houseSceneConfig);
        }
        if (house.floorHeight !== this.house.floorHeight) {
            this.house.floorHeight = house.floorHeight;
            this.reloadHouse(houseSceneConfig);
        }
        if (house.houseColor !== this.house.houseColor) {
            this.house.houseColor = house.houseColor;
            this.changeHouseAndExtensionsColor(houseSceneConfig);
        }
        if (house.roof.roofColor !== this.house.roof.roofColor) {
            this.house.roof.roofColor = house.roof.roofColor;
            this.changeRoofAndRoofExtensionColor(house, houseSceneConfig);
        }
        if (house.isFlatRoof !== this.house.isFlatRoof) {
            this.house.isFlatRoof = house.isFlatRoof;
            this.reloadHouse(houseSceneConfig);
        }
        this.houseUpdatedEmitter.emit(house);
    }

    private changeRoofAndRoofExtensionMaterialTexture(
        house: House,
        houseSceneConfig: HouseSceneConfig
    ): void {
        PermitUtils.changeMaterialTexture(
            houseSceneConfig.scene,
            PermitAssetNamesEnum.roofGroup,
            PermitAssetNamesEnum.roofMaterial,
            house.roof.roofMaterial.backgroundImage
        );
    }

    private changeRoofAndRoofExtensionColor(
        house: House,
        houseSceneConfig: HouseSceneConfig
    ): void {
        PermitUtils.changeMaterialColorOnGroup(
            houseSceneConfig.scene,
            PermitAssetNamesEnum.roofGroup,
            PermitAssetNamesEnum.roofMaterial,
            this.house.roof.roofColor
        );
    }

    private changeHouseAndExtensionsTexture(
        house: House,
        houseSceneConfig: HouseSceneConfig
    ): void {
        PermitUtils.changeMaterialTexture(
            houseSceneConfig.scene,
            PermitAssetNamesEnum.wallGroup,
            PermitAssetNamesEnum.wallMaterial,
            house.houseMaterial.backgroundImage
        );

        PermitUtils.changeMaterialTexture(
            houseSceneConfig.scene,
            PermitAssetNamesEnum.extensionGroup,
            PermitAssetNamesEnum.wallMaterial,
            house.houseMaterial.backgroundImage,
            new Vector2(3, 3)
        );
    }

    private changeHouseAndExtensionsColor(
        houseSceneConfig: HouseSceneConfig
    ): void {
        PermitUtils.changeMaterialColorOnGroup(
            houseSceneConfig.scene,
            PermitAssetNamesEnum.wallGroup,
            PermitAssetNamesEnum.wallMaterial,
            this.house.houseColor
        );

        PermitUtils.changeMaterialColorOnGroup(
            houseSceneConfig.scene,
            PermitAssetNamesEnum.extensionGroup,
            PermitAssetNamesEnum.wallMaterial,
            this.house.houseColor
        );
    }

    private reloadHouse(houseSceneConfig: HouseSceneConfig): void {
        this.hideAssets();
        PermitUtils.removeGroups(houseSceneConfig.scene, [
            PermitAssetNamesEnum.wallGroup,
            PermitAssetNamesEnum.roofGroup,
        ]);
        const houseHeight = this.createHouse(houseSceneConfig);
        this.updateRoofAssetsPosition(houseHeight);
    }

    private updateRoofAssetsPosition(houseHeight: number): void {
        this.houseHeightUpdateEvent.next(houseHeight);
    }
}
