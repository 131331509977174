import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    Validators,
    ValidationErrors,
} from '@angular/forms';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
    MunicipalityService,
    Client,
    routingConstants,
    FurbanUtil,
    MyErrorStateMatcher,
    validateMunicipalityEmail,
    validatePassword,
    validateTLDEmail,
    ControlsUtil,
} from '@furban/utilities';
import { RegisterAuthService } from './register-auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
    @ViewChild(MatAutocompleteTrigger)
    public trigger: MatAutocompleteTrigger;

    public registerForm: FormGroup;
    public selectedMunicipality: Client;
    public municipalities: Client[];
    public filteredMunicipalitiesOptions: Client[];

    public submited = false;
    public passwordConfirmValidator = FurbanUtil.passwordConfirmValidator;
    public errorMatcher = new MyErrorStateMatcher();
    public userRole: string;
    public subscription: Subscription;
    public readyToCheckForError = false;
    public getPasswordErrorMessage = FurbanUtil.getPasswordErrorMessage;
    public hasControlMunicipalityNotSelectedError = ControlsUtil.hasControlMunicipalityNotSelectedError;

    constructor(
        public translateService: TranslateService,
        private formBuilder: FormBuilder,
        private municipalityService: MunicipalityService,
        private registerAuthService: RegisterAuthService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.route.data.subscribe((data: any) => {
            this.userRole = data['role'] as string;
            this.initialiseregisterForm();
        });
        this.getMunicipalities();
    }

    ngOnDestroy(): void {
        this.removeSubscription();
    }

    public get getPasswordValidationErrors(): ValidationErrors | null {
        return this.registerForm?.get('passwordFormControl')?.errors;
    }

    submitRegistration() {
        if (this.selectedMunicipality == null) {
            this.registerForm
                .get('municipalityFormControl')
                .setErrors({ municipalityNotSelected: true });
            this.filteredMunicipalitiesOptions = this.municipalities;
            return;
        }
        if (this.registerForm.invalid) {
            return;
        }
        if (this.isPioneerRole()) {
            this.registerPioneer();
        } else if (this.isAdminRole()) {
            this.registerAdmin();
        }
    }

    getEmailHintMessage() {
        if (!this.userRole) {
            return;
        }
        return this.isAdminRole()
            ? this.translateService.instant('admin.auth.municipalityEmailHint')
            : this.translateService.instant('admin.auth.validEmailHint');
    }

    getEmailErrorMessage() {
        const emailError = 'email';
        if (this.registerForm.get('emailFormControl').errors['required']) {
            return this.translateService.instant('auth.requiredEmail');
        } else if (this.registerForm.get('emailFormControl').errors[emailError]) {
            return this.translateService.instant('admin.auth.validEmail');
        } else if (
            this.registerForm.get('emailFormControl').errors['notMunicipalityEmail']
        ) {
            return this.translateService.instant(
                'admin.auth.municipalityEmailError'
            );
        } else if (
            this.registerForm.get('emailFormControl').errors['notExpectedTopLevelDomain']
        ) {
            return this.translateService.instant('admin.auth.notTLDError');
        } else {
            return null;
        }
    }

    getConfirmPasswordErrorMessage() {
        const requiredError = 'required';
        if (
            this.registerForm.get('passwordConfirmFormControl').errors?.[requiredError]
        ) {
            return this.translateService.instant(
                'admin.auth.confirmPasswordRequired'
            );
        } else if (this.registerForm.hasError('passwordMatches')) {
            return this.translateService.instant(
                'admin.auth.confirmPasswordMatch'
            );
        } else {
            return null;
        }
    }

    goToLogin() {
        this.router.navigate([routingConstants.admin]);
    }

    goToTakingPart() {
        this.router.navigate([routingConstants.takingPart]);
    }

    submitedForRole(role: string): boolean {
        return this.userRole === role && this.submited;
    }

    displayFn(client: Client): string {
        return client && client.clientName ? client.clientName : '';
    }

    updateSelectedMunicipality(value) {
        this.selectedMunicipality = value;
    }

    private isPioneerRole() {
        return this.userRole === 'pioneer';
    }

    private isAdminRole() {
        return this.userRole === 'admin';
    }

    private registerPioneer() {
        this.registerAuthService
            .createPioneer(
                this.registerForm
                    .get('emailFormControl')
                    .value.trim()
                    .toLowerCase(),
                this.registerForm.get('passwordFormControl').value,
                this.selectedMunicipality.clientUuid
            )
            .subscribe(() => {
                this.resetFormAfterSubmit();
                this.registerAuthService.isRegisteringAgain = false;
            });
    }

    private registerAdmin() {
        this.registerAuthService
            .createAdmin(
                this.registerForm
                    .get('emailFormControl')
                    .value.trim()
                    .toLowerCase(),
                this.registerForm.get('passwordFormControl').value,
                this.selectedMunicipality.clientUuid
            )
            .subscribe(() => {
                this.resetFormAfterSubmit();
            });
    }

    private initialiseregisterForm() {
        this.registerForm = this.formBuilder.group(
            {
                emailFormControl: [
                    '',
                    [
                        Validators.required,
                        Validators.email,
                        validateMunicipalityEmail,
                        validateTLDEmail,
                    ],
                ],
                passwordFormControl: [
                    '',
                    [Validators.required, validatePassword],
                ],
                passwordConfirmFormControl: ['', Validators.required],
                municipalityFormControl: ['', Validators.required],
            },
            { validator: this.passwordConfirmValidator }
        );

        if (this.isPioneerRole()) {
            this.registerForm.controls['emailFormControl'].setValidators([
                Validators.required,
                Validators.email,
            ]);
        }

        this.readyToCheckForError = true;
    }

    private getMunicipalities() {
        this.municipalityService.getAllMunicipalities().subscribe((data) => {
            this.municipalities = data;
            this.initializeFilteredMunicipalities();
        });
    }

    private resetFormAfterSubmit() {
        this.submited = true;
        this.registerForm.reset();
    }

    private _filter(clientName: string): Client[] {
        const filterValue = clientName.toLowerCase();
        return this.municipalities.filter(
            (municipality) =>
                municipality.clientName.toLowerCase().indexOf(filterValue) === 0
        );
    }

    private subscribeToPanelClosingActions() {
        this.subscription = this.trigger.panelClosingActions.subscribe(() => {
            if (this.selectedMunicipality === null) {
                if (this.filteredMunicipalitiesOptions.length === 0) {
                    this.registerForm
                        .get('municipalityFormControl')
                        .setErrors({ municipalityNotSelected: true });
                    this.registerForm
                        .get('municipalityFormControl')
                        .patchValue(null);
                } else {
                    this.selectedMunicipality =
                        this.filteredMunicipalitiesOptions[0];
                    this.registerForm
                        .get('municipalityFormControl')
                        .patchValue(this.selectedMunicipality);
                }
            }
        });
    }

    private initializeFilteredMunicipalities() {
        this.filteredMunicipalitiesOptions = this.municipalities;
        this.subscribeToPanelClosingActions();
        this.registerForm.controls[
            'municipalityFormControl'
        ].valueChanges.subscribe((value) => {
            if (typeof value === 'string') {
                this.selectedMunicipality = null;
                this.filteredMunicipalitiesOptions = this._filter(value);
            }
        });
    }

    private removeSubscription() {
        if (!this.subscription) {
            return;
        }
        this.subscription.unsubscribe();
        this.subscription = null;
    }
}
