import * as THREE from 'three';

export class ThreeAnimationBuilder {
    public static createAnimationMixer(
        object: THREE.Group,
        animation: THREE.AnimationClip
    ) {
        const mixer = new THREE.AnimationMixer(object);
        const action = mixer.clipAction(animation);
        action.play();
        return mixer;
    }
}
