import { Injectable } from '@angular/core';
import { scriptStore } from '../_models/script-store';

declare let document: any;

@Injectable()
export class ScriptService {
    private scriptsToLoad: any[] = [];

    load(...scripts: string[]): Promise<any> {
        scripts.forEach((scriptName) => {
            this.scriptsToLoad.push(
                scriptStore.find((script) => script.name === scriptName)
            );
        });

        return new Promise((resolve, reject) => {
            this.loadScript(resolve);
        });
    }

    loadScript(resolve) {
        if (this.scriptsToLoad.length === 0) {
            resolve();
            return;
        }

        const existingScript =
            document.querySelectorAll(`[src="${this.scriptsToLoad[0].src}"]`)
                .length > 0;

        if (!existingScript) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = this.scriptsToLoad[0].src;
            script.onload = () => {
                this.scriptsToLoad.shift();
                this.loadScript(resolve);
            };
            script.onerror = (error: any) => {
                resolve();
            };
            document.getElementsByTagName('head')[0].appendChild(script);
        } else {
            this.scriptsToLoad.shift();
            this.loadScript(resolve);
        }
    }
}
