<div class="furban-logo">
    <img src="../../../assets/images/homepage/furban white 1.svg" />
    <h4> {{ 'presentation.welcomeSubtitle' | translate }}
    </h4>
</div>

<div class="login-cards">
    <div class="card-container email" (click)="navigate('/app/partner')">
        <h4>
            {{ 'landingPage.emailTitle' | translate }}
        </h4>
        <section>
            <p>
                {{ 'landingPage.emailDescription' | translate }}
            </p>
            <img src="../../../assets/images/homepage/undraw_forgot_password_re_hxwm 2.svg" />
        </section>
    </div>


    <div class="card-container code" (click)="navigate('/app')">
        <h4>
            {{ 'landingPage.codeTitle' | translate }}
        </h4>
        <section>
            <p>
                {{ 'landingPage.codeDescription' | translate }}
            </p>
            <img src="../../../assets/images/homepage/undraw_forgot_password_re_hxwm 1.svg" />
        </section>
    </div>
</div>

<div class="main-section" furbanThreeRotate #rotateDir="rotateDirective" [mesh]="mesh"
    (mouseDownEvent)="onMouseDown($event)">
    <canvas class="canvasStyle" #canvas></canvas>
</div>