<section class="main-container padding-40-32">
    <h3 class="page-title" [style.margin-bottom.px]="32">
        {{ 'faq.title' | translate }}
    </h3>

    <div
        *ngIf="showTourButtons()"
        class="flex-row-start"
        [style.margin-bottom.px]="32"
    >
        <button
            class="secondary-button"
            [style.margin-right.px]="20"
            (click)="goToDashboard()"
        >
            <h6>{{ 'faq.takeDashboardTour' | translate }}</h6>
        </button>
        <ng-container *ngIf="canUpdateDesign()">
            <button class="secondary-button" (click)="goToDesignMode()">
                <h6>{{ 'faq.takeDesingTour' | translate }}</h6>
            </button>
        </ng-container>
    </div>
    <ng-container *ngIf="questions">
        <mat-accordion>
            <mat-expansion-panel
                *ngFor="let question of questions"
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h3>{{ getQuestionForLanguage(question) }}</h3>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>{{ getAnswerForLanguage(question) }}</p>
                <iframe
                    *ngIf="question.videoUrl"
                    width="560"
                    height="315"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    [src]="getURLLink(question)"
                    [style.margin-top.px]="20"
                ></iframe>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>

    <ng-container *ngIf="!questions || (questions && questions.length == 0)">
        <div class="flex-column-center padding-40-20">
            <img
                src="../../../assets/images/no_question.svg"
                [style.height.px]="300"
            />
            <h4 class="placeholder-text" [style.margin-top.px]="20">
                {{ 'faq.noQuestions' | translate }}
            </h4>
        </div>
    </ng-container>

    <div class="flex-row-end" [style.margin-top.px]="32">
        <button
            *ngIf="checkIfSuperadmin()"
            class="primary-button"
            (click)="openAddModal()"
        >
            <h6>{{ 'faq.addQuestion' | translate }}</h6>
        </button>
    </div>
</section>
