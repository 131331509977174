import { Injectable } from '@angular/core';

import { ToolingActionsEnum, ToolingVisibility } from '@furban/utilities';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ToolingService {
    private _toolingVisibility = new ToolingVisibility();

    public toolingVisibilitySubject = new BehaviorSubject(
        this._toolingVisibility
    );
    public toolingVisibilityObservable =
        this.toolingVisibilitySubject.asObservable();

    public toolingActionsSubject = new Subject<string>();
    public toolingActionsObservable = this.toolingActionsSubject.asObservable();
    public defaultMaterial: number;

    public get toolingVisibility(): ToolingVisibility {
        return this._toolingVisibility;
    }

    public set toolingVisibility(value: ToolingVisibility) {
        this._toolingVisibility = value;
        this.changeToolingVisibility(this._toolingVisibility);
    }

    public callToolingAction(toolingAction: ToolingActionsEnum) {
        this.toolingActionsSubject.next(toolingAction);
    }

    private changeToolingVisibility(toolingVisibility: ToolingVisibility) {
        this.toolingVisibilitySubject.next(toolingVisibility);
    }
}
