import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { PermitProjectService } from '../permit-project/permit-project.service';

import {
    FurbanUtil,
    HouseArea,
    PointLatLon,
    StepperService,
    FurbanLoaderService,
    HouseService,
    ScriptService,
    CustomToasterComponent,
    House,
    PointXY,
    MapService,
    CustomToasterService,
    XY,
    MapCoordinateEnum,
    PermitThreeService,
    ModalManager,
} from '@furban/utilities';
import { forkJoin } from 'rxjs';
import { PERMIT_CONFIG } from './permit.config';
import { finalize } from 'rxjs/operators';

declare let GeocompApi: any;
const mapContainer = 'mapViewer';

@Component({
    selector: 'furban-permit-select-house-map',
    templateUrl: './permit-select-house-map.component.html',
    styleUrls: ['./permit-select-house-map.component.scss'],
})
export class PermitSelectHouseMapComponent implements OnInit, OnDestroy {
    public house: House;
    public neighborHouses: House[];

    public houseArea: HouseArea;

    private GeocompApi: any;

    public get redrawButtonDisabled(): boolean {
        return !this.houseArea?.xYCoordinates || this.houseArea.xYCoordinates.length <= 0;
    }

    public get existingAreaCoordinates(): PointXY[] {
        return typeof this.houseArea.xYCoordinates === 'string' ||
            this.houseArea.xYCoordinates instanceof String
            ? (JSON.parse(
                this.houseArea.xYCoordinates as string
            ) as Array<PointXY>)
            : this.houseArea.xYCoordinates;
    }

    public get existingHouseCoordinates(): PointXY[] {
        return typeof this.house.xYCoordinates === 'string' ||
            this.house.xYCoordinates instanceof String
            ? (JSON.parse(this.house.xYCoordinates as string) as Array<PointXY>)
            : this.house.xYCoordinates;
    }

    constructor(private modal: ModalManager,
        private viewContainerRef: ViewContainerRef,
        public script: ScriptService,
        public loaderService: FurbanLoaderService,
        public translateService: TranslateService,
        public snackBar: MatSnackBar,
        public customToasterService: CustomToasterService,
        public formBuilder: FormBuilder,
        public mapService: MapService,
        public houseService: HouseService,
        public stepperService: StepperService,
        public permitProjectService: PermitProjectService,
        public permitThreeService: PermitThreeService
    ) { }

    ngOnInit(): void {
        this.initaliseComponent();
    }

    ngOnDestroy(): void {
        this.permitThreeService.house = new House();
    }

    public changeAddress(point: XY): void {
        this.GeocompApi.setMapPosition(
            point.x,
            point.y,
            10,
            MapCoordinateEnum.meters
        );
    }

    public goToCurentLocation(): void {
        this.GeocompApi.goToMyCurentGeolocation();
    }

    public savePolygons(): void {
        const requests = [];
        if (this.GeocompApi.getDrawing().length > 0) {
            this.houseArea.xYCoordinates = JSON.stringify(
                this.houseArea.xYCoordinates
            );
            this.houseArea.latLonCoordinates = JSON.stringify(
                this.houseArea.latLonCoordinates
            );
            requests.push(this.houseService.saveHouseArea(this.houseArea));
        }

        if (!this.neighborHouses || this.neighborHouses.length === 0) {
            this.openSnackBarError(
                'pioneer.permitProcess.noNeighborHouseSelected'
            );
            return;
        }
        requests.push(
            this.houseService.saveMultipleHouses(this.neighborHouses)
        );

        const drawnHouse = this.GeocompApi.getSelectedGeometries('GeoJSON');
        if (drawnHouse.length === 0) {
            this.openSnackBarError('pioneer.permitProcess.noHouseSelected');
            return;
        }
        const parsedHouse = JSON.parse(drawnHouse[0]);
        const xyArray = [];
        parsedHouse.geometry.coordinates[0].forEach((el) => {
            const xy = new PointXY(el[0], el[1]);
            xyArray.push(xy);
        });
        this.house.xYCoordinates = JSON.stringify(xyArray);
        requests.push(this.houseService.saveHouse(this.house));

        forkJoin(requests).subscribe(() => {
            this.GeocompApi?.zoomToDrawing();
            this.stepperService.modifyCurrentStepId(true);
            this.stepperService.changeProjectModifiedState(false);
        });
    }

    public resetMap():void{
        this.modal.showModal(this.viewContainerRef, ModalManager.createConfiguration(
            'errors.warning',
            'pioneer.permitProcess.resetWarning',
            'generic.yesBtn',
            'generic.noBtn'
        )).subscribe(
            res => {
                if (!res) {
                   return;
                }
                this.onMapReset();
            }
        );
    }
    
    public onMapReset(): void {
        if (!this.house?.houseId) {
            this.clearMap();
            this.initializeHouses();
            return;
        }

        this.houseService.deleteAllHousesForProjectId(this.stepperService.projectId).subscribe(
            () => {
                this.clearMap();
                this.initializeHouses();
                this.stepperService.resetCurrentStepIdForProject(this.stepperService.currentStep.id - 1);
            }
        )
    }

    private initializeHouses(): void {
        this.house = new House();
        this.houseArea = new HouseArea();
        this.houseArea.projectId = this.stepperService.projectId;
        this.house.projectId = this.stepperService.projectId;
    }

    private clearMap(): void {
        this.GeocompApi?.clearMap();
        this.GeocompApi?.setFeatureSelectionChangedCallback((data) =>
            this.selectFeaturesCallbackForMultipleHouses(data)
        );
        this.GeocompApi?.setGeocompMode('DrawPolygon');
    }

    private initaliseComponent(): void {
        const requests = [
            this.houseService.getHousePolygon(this.stepperService.projectId),
            this.houseService.getHouseAreaPolygon(
                this.stepperService.projectId
            ),
            this.houseService.getNeigborHousePolygons(
                this.stepperService.projectId
            ),
        ];

        forkJoin(requests)
            .pipe(
                finalize(() => {
                    this.loadMapScripts();
                })
            )
            .subscribe(
                {
                    next: (data) => {
                        this.house = data[0] as House;
                        this.houseArea = data[1] as HouseArea;
                        this.neighborHouses = data[2] as House[];
                    },
                    error: (err) => {
                        this.house = new House();
                        this.houseArea = new HouseArea();
                        this.houseArea.projectId = this.stepperService.projectId;
                        this.house.projectId = this.stepperService.projectId;
                    }
                }
            );
    }

    private loadMapScripts(): void {
        this.script
            .load(
                'jquery',
                'olJs',
                'olrotateFeatureJs',
                'centricGeoComp',
                'passiveEvents'
            )
            .then((data) => {
                this.loaderService.hide();
                this.createMap();
            })
            .catch((error) => {
                console.log(error);
                this.loaderService.hide();
            });
    }

    private createMap(): void {
        PERMIT_CONFIG.container = mapContainer;
        this.GeocompApi = new GeocompApi(PERMIT_CONFIG);
        this.GeocompApi.setLayerSelectability([
            { layerName: 'pand', selectable: true },
        ]);
        this.GeocompApi?.setDrawCallback((data) =>
            this.drawPolygonCallback(data)
        );
        this.GeocompApi.setFeatureSelectionChangedCallback((data) =>
            this.selectFeaturesCallbackForMultipleHouses(data)
        );

        this.drawExistingPolygons();
    }

    private drawExistingPolygons(): void {
        if (this.house.xYCoordinates) {
            this.drawPolygonOnMap(this.existingHouseCoordinates);
        }

        if (this.houseArea.xYCoordinates) {
            this.drawPolygonOnMap(this.existingAreaCoordinates);
        }

        if (this.neighborHouses) {
            this.GeocompApi?.setDrawingStyle(
                'rgba(68, 188, 216,0.5)',
                '#1e81b0',
                1
            );
            this.drawNeighborHousesOnMap();
        }

        if (this.houseArea.xYCoordinates && !this.house.xYCoordinates) {
            this.GeocompApi?.zoomToDrawing();
            this.GeocompApi?.setGeocompMode('SelectFeaturesByPolygon');
        } else if (!this.houseArea.xYCoordinates && !this.house.xYCoordinates) {
            this.GeocompApi.setGeocompMode('DrawPolygon');
        } else {
            this.GeocompApi?.zoomToDrawing();
            this.GeocompApi.setGeocompMode('Navigate');
        }
    }

    private drawPolygonCallback(data): void {
        let curentCoordinates = FurbanUtil.deepCopy(data.XyCoordinates);
        curentCoordinates = FurbanUtil.parseCoordinatesFor2DAnd3D(
            curentCoordinates,
            100
        );

        if (
            FurbanUtil.isAreaToBig(curentCoordinates) ||
            curentCoordinates.length < 4
        ) {
            this.GeocompApi?.clearMap();

            const errorMessage =
                curentCoordinates.length < 4
                    ? 'admin.map.minPoints'
                    : 'admin.map.areaBig';
            this.openSnackBar(errorMessage, 2000, 'warning');
        } else {
            this.houseArea.latLonCoordinates =
                data.LatLonCoordinates as Array<PointLatLon>;
            this.houseArea.xYCoordinates = data.XyCoordinates as Array<PointXY>;
            const polygonCenter = FurbanUtil.getPolygonCentroid(
                data.XyCoordinates
            );
            this.houseArea.xCenter = polygonCenter.x;
            this.houseArea.yCenter = polygonCenter.y;
            this.GeocompApi.setGeocompMode('SelectFeaturesByPolygon');
            this.openSnackBar(
                'pioneer.permitProcess.identifyNeighbors',
                4000,
                'info'
            );
        }
    }

    private selectFeaturesCallbackForCurrentHouse(data): void {
        if (data.length < 1) {
            return;
        }

        const drawnHouses = this.GeocompApi.getSelectedGeometries('GeoJSON');
        if (drawnHouses.length === 0) {
            this.openSnackBarError('pioneer.permitProcess.noHouseDetected');
        } else if (drawnHouses.length > 1) {
            this.GeocompApi.clearSelection();
            this.openSnackBarError('pioneer.permitProcess.tooManyBuildings');
        } else {
            this.GeocompApi.setGeocompMode('Navigate');
        }

        const selectedHouses = JSON.parse(drawnHouses[0]);

        this.removeMyHouseFromNeighborsArray(
            selectedHouses.properties.identificatie
        );
    }

    private selectFeaturesCallbackForMultipleHouses(data): void {
        if (data.length < 1) {
            return;
        }

        const drawnHouses = this.GeocompApi.getSelectedGeometries('GeoJSON');
        if (drawnHouses.length <= 0) {
            return;
        }

        this.neighborHouses = [];
        drawnHouses.forEach((element) => {
            const parsedHouse = JSON.parse(element);
            const xyArray = [];
            parsedHouse.geometry.coordinates[0].forEach((el) => {
                const xy = new PointXY(el[0], el[1]);
                xyArray.push(xy);
            });
            const neighborHouse = new House();
            neighborHouse.xYCoordinates = JSON.stringify(xyArray);
            neighborHouse.projectId = this.stepperService.projectId;
            neighborHouse.isNeighborHouse = true;
            neighborHouse.houseIdentificationNumber =
                parsedHouse.properties.identificatie;
            this.neighborHouses.push(neighborHouse);
        });
        this.drawNeighborHouses();
        this.openSnackBar('pioneer.permitProcess.identifyHouse', 4000, 'info'),
            this.GeocompApi.setFeatureSelectionChangedCallback((data) =>
                this.selectFeaturesCallbackForCurrentHouse(data)
            );
    }

    private removeMyHouseFromNeighborsArray(
        houseIdentificationNumber: string
    ): void {
        this.neighborHouses = this.neighborHouses.filter(
            (house) =>
                house.houseIdentificationNumber !== houseIdentificationNumber
        );
    }

    private drawNeighborHouses(): void {
        this.GeocompApi.clearSelection();
        this.drawNeighborHousesOnMap();
    }

    private openSnackBar(
        messageCode: string,
        duration: number,
        icon: string
    ): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            icon,
            'info',
            this.translateService.instant(messageCode),
            duration
        );
    }

    private openSnackBarError(messageCode: string): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'alert',
            'error',
            this.translateService.instant(messageCode),
            4000
        );
    }

    private drawPolygonOnMap(pointsOfPolygon: Array<PointXY>): void {
        const exteriorRing = [];

        for (const coordinate of pointsOfPolygon) {
            exteriorRing.push({ xLat: coordinate.X, yLon: coordinate.Y });
        }

        const polygon = { exteriorRing: exteriorRing, interiorRings: [] };
        this.GeocompApi?.drawPolygon(polygon, 'Meters');
    }

    private drawNeighborHousesOnMap(): void {
        this.GeocompApi?.setDrawingStyle(
            'rgba(68, 188, 216,0.5)',
            '#1e81b0',
            1
        );
        this.neighborHouses.forEach((element) => {
            if (element.xYCoordinates) {
                this.drawPolygonOnMap(this.getHouseCoordinates(element));
            }
        });
    }

    private getHouseCoordinates(house: House): PointXY[] {
        return typeof house.xYCoordinates === 'string' ||
            house.xYCoordinates instanceof String
            ? (JSON.parse(house.xYCoordinates as string) as Array<PointXY>)
            : house.xYCoordinates;
    }
}
