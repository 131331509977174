<ng-container *ngFor="let permitReview of permitReviews">
    <section class="user-entry">
        <h6>
            {{ permitReview.user.username }}
        </h6>
        <h6
            [style.color]="
                getPermitLabelColor(permitReview.projectStatus.statusLabel)
            "
        >
            {{
                'pioneer.permitInvolvement.' +
                    permitReview.projectStatus.statusLabel | translate
            }}
        </h6>
    </section>
</ng-container>
