<section class="important-space-between" mat-dialog-title>
    <h2>{{ 'pioneer.permitInvolvement.question' | translate }}</h2>
    <button class="ghost-button" (click)="closePopup()">
        <span class="material-icons">close</span>
    </button>
</section>

<mat-dialog-content>
    <section class="flex-row-center">
        <div class="card" (click)="involveMunicipality()">
            <div class="card-container">
                <img
                    src="../../../assets/images/municipality.svg"
                    [style.height.px]="100"
                />
                <h4 class="placeholder-text">
                    {{ 'pioneer.permitInvolvement.firstOption' | translate }}
                </h4>
            </div>
        </div>
        <div class="card" (click)="involveNeighbors()">
            <div class="card-container">
                <img
                    src="../../../assets/images/neighbors.svg"
                    [style.height.px]="100"
                />
                <h4 class="placeholder-text">
                    {{ 'pioneer.permitInvolvement.secondOption' | translate }}
                </h4>
            </div>
        </div>
    </section>
</mat-dialog-content>
