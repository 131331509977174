import {
    Component,
    OnInit,
    ViewChild,
    EventEmitter,
    ViewContainerRef,
    Input,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserDashboardComponent } from '../user-dashboard/user-dashboard.component';
import { ImageCropperComponent, CropperSettings } from 'ngx-img-cropper';
import { UserProfileService } from './user-profile.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import {
    ModalManager,
    AuthService,
    CustomToasterComponent,
    FurbanUtil,
    UserProfile,
    CustomToasterService,
    FILE_EXTENSION_FORMAT,
    ControlsUtil,
} from '@furban/utilities';

import { ImageUploadService } from 'apps/furban-client/src/app/shared/image-upload/image-upload.service';

import { environment } from 'apps/furban-client/src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsComponent } from '../user-settings/user-settings.component';
import { forkJoin, Observable } from 'rxjs';

@Component({
    selector: 'furban-app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
    @ViewChild('cropperUserProfile') cropper: ImageCropperComponent;
    @ViewChild('profileImage') profileImg;
    @ViewChild('userSettingsComponent')
    userSettingsComponent: UserSettingsComponent;
    @Input() public parentRef: ViewContainerRef;

    public userProfile: UserProfile = new UserProfile();
    public userProfileCheckForm: FormGroup;
    public state = 'profile';
    public imageModified: boolean;
    public fileName: string;
    public data: any;
    public cropperSettingsUserProfile: CropperSettings;
    public croppedWidth: number;
    public croppedHeight: number;
    public onProfileClose = new EventEmitter();
    public readonly userDisplayNameMaxLength = 64;
    public noWhiteSpacesValidator = FurbanUtil.noWhiteSpacesValidator;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public nameValidator = FurbanUtil.nameValidator;
    public fileExtentionFormat = FILE_EXTENSION_FORMAT;

    private imageId;
    private lastImage;
    private codeUser: string;
    public hasControlMaxError = ControlsUtil.hasControlMaxError;
    public hasControlMinError = ControlsUtil.hasControlMinError;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;
    public hasControlMaxLengthError = ControlsUtil.hasControlMaxLengthError;
    public hasControlMinLengthError = ControlsUtil.hasControlMinLengthError;
    public hasControlIsHtmlError = ControlsUtil.hasControlIsHtmlError;
    public hasControlWhitespacesError = ControlsUtil.hasControlWhitespacesError;
    public hasControlEmailError = ControlsUtil.hasControlEmailError;
    public hasControlIsCodeNamedError = ControlsUtil.hasControlIsCodeNamedError;

    constructor(
        public dialogRef: MatDialogRef<UserDashboardComponent>,
        private userProfileService: UserProfileService,
        private modal: ModalManager,
        private viewContainerRef: ViewContainerRef,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private imageUploadService: ImageUploadService,
        private customToasterService: CustomToasterService,
        private translateService: TranslateService
    ) {
        this.codeUser = authService.codeUser;
        this.userProfileCheckForm = this.formBuilder.group({
            displayNameFormControl: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(this.userDisplayNameMaxLength),
                    this.noWhiteSpacesValidator,
                    this.htmlValidator,
                    this.nameValidator(this.codeUser),
                ],
            ],
        });

        this.cropperSettingsUserProfile = new CropperSettings();
        this.cropperSettingsUserProfile.width = 110;
        this.cropperSettingsUserProfile.height = 110;

        this.cropperSettingsUserProfile.croppedWidth = 110;
        this.cropperSettingsUserProfile.croppedHeight = 110;

        this.cropperSettingsUserProfile.canvasWidth = 300;
        this.cropperSettingsUserProfile.canvasHeight = 300;

        this.cropperSettingsUserProfile.minWidth = 10;
        this.cropperSettingsUserProfile.minHeight = 10;

        this.cropperSettingsUserProfile.keepAspect = true;

        this.cropperSettingsUserProfile.rounded = false;
        this.cropperSettingsUserProfile.cropperClass = 'profile-cropper-class';
        this.cropperSettingsUserProfile.croppingClass =
            'profile-cropping-class';

        this.data = {};
    }

    ngOnInit() {
        if (this.authService.userProfile) {
            this.userProfile = this.authService.userProfile;
            this.userProfileCheckForm
                .get('displayNameFormControl')
                .setValue(this.userProfile.screenName);
            this.getProfilePicture();
        } else {
            this.userProfile = new UserProfile();
        }
    }

    fileChangeListener($event) {
        const image: any = new Image();
        const file: File = $event.target.files[0];
        this.fileName = file.name;
        const myReader: FileReader = new FileReader();
        myReader.onloadend = (loadEvent: any) => {
            image.src = loadEvent.target.result;
            image.onload = () => {
                this.cropper.setImage(image);
            };
            this.state = 'cropImage';
            this.imageModified = true;
        };

        myReader.readAsDataURL(file);
    }

    uploadImage() {
        this.lastImage = this.data.image;
        (<HTMLElement>(
            document.getElementsByClassName('custom-photo-input')[0]
        )).click();
    }

    cropImage() {
        if (this.fileName && this.data && this.data.image) {
            if (this.fileName.length > 29) {
                this.fileName = FurbanUtil.reduceFileNameLength(
                    this.fileName,
                    30
                );
            }

            const parts = this.data.image.split(',');
            this.imageUploadService
                .uploadPhoto(
                    this.data.image.length,
                    this.fileName,
                    parts[1],
                    this.imageUploadService.getContentType(parts[0]),
                    '/profile'
                )
                .subscribe(
                    {
                        next: (data) => {
                            this.imageId = data;
                            this.state = 'profile';
                        },
                        error: (err) => {
                            this.data.image = this.lastImage;
                        }
                    }
                );
        } else {
            this.modal
                .showModal(
                    this.parentRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'user.profile.selectPhoto'
                    )
                )
                .subscribe();
        }
    }

    cancelImageCrop() {
        this.data.image = this.lastImage;
        this.state = 'profile';
    }

    closeProfileModal(): void {
        // check if modified to save before close
        if (
            this.userProfileCheckForm.get('displayNameFormControl').dirty ||
            this.imageModified
        ) {
            this.modal
                .showModal(
                    this.parentRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'errors.discardMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (res) {
                        this.onProfileClose.emit('closeProfile');
                    }
                });
        } else {
            this.onProfileClose.emit('closeProfile');
        }
    }

    getProfilePicture() {
        if (this.userProfile.mediaId) {
            this.data.image = `${environment.apiPath}/media/public/${this.userProfile.mediaId}`;
        }
    }

    saveProfile(): void {
        if (this.userProfileCheckForm.invalid) {
            return;
        }

        const displayNameValue = this.userProfileCheckForm
            .get('displayNameFormControl')
            .value.trim();
        const show3DNotifications =
            this.userSettingsComponent.userSettingsCheckForm.get(
                'show3DNotificationsFormControl'
            ).value;
        const isHoverHelperActive =
            this.userSettingsComponent.userSettingsCheckForm.get(
                'isHoverHelperActiveControl'
            ).value;

        if (this.userProfile != null && this.userProfile.userProfileId) {
            if (this.imageId) {
                this.userProfile.mediaId = this.imageId;
            }
            this.userProfile.screenName = displayNameValue;
            this.userProfile.userId = this.authService.user.userId;
            const requests: Observable<any>[] = [
                this.userProfileService.updateProfile(this.userProfile),
            ];
            if (
                this.userProfile.userSettings != null &&
                (this.userProfile.userSettings.show3DNotifications !==
                    show3DNotifications ||
                    this.userProfile.userSettings.isHoverHelperActive !==
                    isHoverHelperActive)
            ) {
                this.userProfile.userSettings.show3DNotifications =
                    show3DNotifications;
                this.userProfile.userSettings.isHoverHelperActive =
                    isHoverHelperActive;
                requests.push(
                    this.authService.updateUserSettings(
                        this.userProfile.userSettings
                    )
                );
            }

            forkJoin(requests).subscribe((data: any) => {
                this.onProfileClose.emit('closeProfile');
                this.openSnackbar();
            });
        } else {
            const newUserProfile = new UserProfile();
            if (this.imageId) {
                newUserProfile.mediaId = this.imageId;
            }
            newUserProfile.screenName = displayNameValue;
            newUserProfile.userId = this.authService.user.userId;

            this.userProfileService
                .createProfile(newUserProfile)
                .subscribe((data) => {
                    this.onProfileClose.emit('closeProfile');
                });
        }
    }

    openSnackbar() {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            'generic.success',
            3000
        );
    }
}
