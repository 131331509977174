import { Component, Input, ViewContainerRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {
    AuthService,
    MultilanguageService,
    routingConstants,
} from '@furban/utilities';
import { Router } from '@angular/router';
import { UserProfileService } from '../../municipality/user/user-profile/user-profile.service';

@Component({
    selector: 'furban-terms-component',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
    @Input() public parentRef: ViewContainerRef;
    public showButtons = true;
    constructor(
        private dialogRef: MatDialogRef<TermsAndConditionsComponent>,
        private authService: AuthService,
        private userProfileService: UserProfileService,
        public multilanguageService: MultilanguageService,
        private router: Router
    ) { }

    ngOnInit() {
        if (this.router.url === routingConstants.info) {
            this.showButtons = false;
        }
    }

    onNoClick(): void {
        this.authService.logoutRedirect();
        this.dialogRef.close(false);
    }

    onYesClick(): void {
        const curentUserProfile = this.authService.userProfile;
        curentUserProfile.acceptedTOS = true;
        this.userProfileService
            .updateProfile(curentUserProfile)
            .subscribe((data) => {
                this.dialogRef.close(true);
            });
    }
}
