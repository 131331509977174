import {
    AfterViewInit,
    Component,
    ElementRef,
    ViewChild,
    Output,
    EventEmitter,
    OnDestroy,
    Input,
    ViewContainerRef,
} from '@angular/core';
import TWEEN from '@tweenjs/tween.js';
import { Group, Vector3, Vector2, Scene, Object3D } from 'three';

import { PermitControlsMenuComponent } from '../permit-controls-menu/permit-controls-menu.component';
import { from, interval, Subscription } from 'rxjs';
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    House,
    HouseAsset,
    HouseSceneConfig,
    ObjectMenuSettings,
    PermitActions,
    PermitAsset,
    PermitAssetNamesEnum,
    PermitComment,
    PermitDefaultValues,
    PermitGroupBuilder,
    PermitThreeService,
    PermitUtils,
    StepperService,
    ThreeCameraBuilder,
    ThreeCommentPopupComponent,
    ThreeControlBuilder,
    ThreeGroupBuilder,
    ThreeGroupEnum,
    ThreeLightsBuilder,
    ThreeMeshBuilder,
    ThreeRendererBuilder,
    ThreeUtils,
    ToolingButtonsEnum,
    PermitStore,
    HouseService,
    selectActualPermitState,
    getHouseAssets,
    restoreInitialStatePermit,
    updateHouseInformationsRequest,
    undoRequestPermit,
    redoRequestPermit,
    PermitState,
    TextureColorEnum,
    ThreeRoofBuilder,
    PermitAssetTypeValues,
    PermitMenuControlsEnum,
    HouseFormValuesEnum,
    FurbanUtil,
    Roof,
    ColorsDefaultValuesEnum,
    PermitKeyboardActionEnum,
    ThreeSunBuilder,
    sunConstants,
    resetHouseRequest,
    ProjectTypeEnum,
    RowOfObjects,
    addMultipleAssetObjectsRequest,
    HouseMaterial,
    AssetColorAndMaterial,
    updateMultipleRoofAssetsWhenHouseChangesRequest,
    MenuService,
} from '@furban/utilities';
import { State, Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { MatSliderChange } from '@angular/material/slider';
import { RowOfObjectsDialogComponent } from '../../project-shared/user-project/row-of-objects-dialog/row-of-objects-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { KeyCode } from '@furban/utilities';
import { PermitStepStatusEnum } from 'libs/utilities/src/lib/_enum/permit-step-status.enum';
import * as THREE from 'three';

@Component({
    selector: 'furban-ngrx-permit-editor',
    templateUrl: './ngrx-permit-editor.component.html',
    styleUrls: ['./ngrx-permit-editor.component.scss'],
})
export class NgrxPermitEditorComponent
    implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('canvasPermit')
    public canvasRef: ElementRef;

    @ViewChild('controlsMenu')
    public permitControlsMenu: PermitControlsMenuComponent;

    @ViewChild('commentPopup')
    threeCommentPopupComponent: ThreeCommentPopupComponent;

    @Input() deactivateControls: boolean;

    @Input() shouldDisableActions?: boolean;

    @Input() projectId: string;

    @Input() parentView: ViewContainerRef;

    @Output()
    protected discardSelectionFromMenu = new EventEmitter<boolean>();

    public menuSettings: ObjectMenuSettings = new ObjectMenuSettings();
    public activateAttach = false;
    public houseSceneConfig = new HouseSceneConfig();
    public meshBuilder = new ThreeMeshBuilder();
    public houseAssets: HouseAsset[] = [];
    public toolingsBottonSubscription: Subscription;
    public assetAddSubscription: Subscription;
    public houseLoadedSubscription: Subscription;
    public isClonedHouse: boolean;
    public isSunAnimationRunning: boolean;
    public shouldDisplayTimeSlider = false;
    public sunSliderValue: number;

    public roofMaterials: HouseMaterial[] = [];
    public selectedRoofMaterial: HouseMaterial;

    public roofAssetColorControl = new FormControl('', { updateOn: 'blur' });
    public getURLForMaterials = PermitUtils.getURLForMaterials;

    private storeSubscription: Subscription;
    private neighborHouses: House[];
    private sunBuilder: ThreeSunBuilder;
    private dateForSun: Date;
    private readonly SUNSHINING_MINUTES =
        (sunConstants.SUNSET_TIME - sunConstants.SUNSRISE_TIME) * 60;

    private sunSubscription: Subscription;
    private houseHeightUpdateSubscription: Subscription;
    private animationFrameId: number;

    constructor(
        protected houseService: HouseService,
        protected permitThreeService: PermitThreeService,
        protected dialog: MatDialog,
        protected menuService: MenuService,
        protected stepperService: StepperService,
        protected store: Store<{ store: PermitStore }>,
        protected currentPermitState: State<PermitStore>,
        protected route: ActivatedRoute
    ) { }

    public get house(): House {
        return this.permitThreeService.house;
    }

    public get futureState(): PermitState[] {
        return this.currentPermitState.getValue()['permit'].store.future;
    }

    public get previousState(): PermitState[] {
        return this.currentPermitState.getValue()['permit'].store.previous;
    }

    public get actualState(): PermitState {
        return this.currentPermitState.getValue()['permit'].store.actual;
    }

    public get assetType(): number {
        return this.houseSceneConfig.assetType;
    }

    public get isMultiselect(): boolean {
        return this.houseSceneConfig.multiselectGroup && this.houseSceneConfig.multiselectGroup.userData['selected']?.length > 0;
    }

    public get sunriseTime(): string {
        return (
            FurbanUtil.displayNumberWithTwoDigits(sunConstants.SUNSRISE_TIME) +
            ':00'
        );
    }

    public get sunsetTime(): string {
        return (
            FurbanUtil.displayNumberWithTwoDigits(sunConstants.SUNSET_TIME) +
            ':00'
        );
    }

    public get sunShiningMinutesInterval(): number {
        return this.SUNSHINING_MINUTES;
    }

    ngOnInit(): void {
        this.subscribeToStore();
        this.toolingsBottonSubscription =
            this.permitThreeService.buttonPressedObservable.subscribe(
                (data) => {
                    this.pressButtonFromTooling(data);
                }
            );

        this.assetAddSubscription =
            this.houseService.addObjectsObservable.subscribe(
                (state: PermitAsset) => {
                    this.addAssetOnCanvas(state);
                    this.permitThreeService.selectControls.next(
                        PermitMenuControlsEnum.discard
                    );
                }
            );

        this.houseLoadedSubscription =
            this.permitThreeService.houseLoadedObservable.subscribe((data) => {
                if (!data) {
                    return;
                }
                this.getHouseAssets();
            });

        this.projectId = this.projectId
            ? this.projectId
            : this.stepperService.projectId;
        this.subscribeToKeyboardObservable();
        this.subscribeToHouseHeightUpdated();
    }

    ngAfterViewInit(): void {
        this.menuSettings.enabled = true;
        PermitUtils.getValuesFromParsedArray(
            this.houseSceneConfig,
            this.permitThreeService
        );
        this.route.data.subscribe((data) => {
            this.isClonedHouse = data['isClone'];
            this.initializeThreeJS();
        });

        this.initializeDataForColorAndTexturePopup();
    }

    ngOnDestroy(): void {
        cancelAnimationFrame(this.animationFrameId);
        this.permitThreeService.clearAssetGroup();
        this.houseAssets = [];
        ThreeUtils.disposeThreeElement(this.houseSceneConfig.scene);
        this.houseSceneConfig = new HouseSceneConfig();
        this.toolingsBottonSubscription.unsubscribe();
        this.assetAddSubscription.unsubscribe();
        this.houseLoadedSubscription.unsubscribe();
        this.dispatchResetStore();
        this.storeSubscription.unsubscribe();
        this.houseHeightUpdateSubscription.unsubscribe();
        this.removeEventListenersForPan();
    }

    public get canvas(): HTMLCanvasElement {
        return this.canvasRef.nativeElement;
    }

    public changeCameraPerspective(): void {
        this.permitThreeService.selectControls.next(
            PermitMenuControlsEnum.discard
        );

        switch (this.houseSceneConfig.perspectiveCubeState) {
            case 0:
            case 1:
            case 2:
                this.callForCameraAnimation();
                this.houseSceneConfig.perspectiveCubeState++;
                break;
            case 3:
            default:
                this.callForCameraAnimation();
                this.houseSceneConfig.perspectiveCubeState = 0;
                break;
        }
    }

    public onControlSelected(action: PermitMenuControlsEnum): void {
        switch (action) {
            case PermitMenuControlsEnum.delete:
                this.permitThreeService.selectControls.next(
                    PermitMenuControlsEnum.delete
                );
                break;
            case PermitMenuControlsEnum.move:
                this.permitThreeService.selectControls.next(
                    PermitMenuControlsEnum.move
                );
                break;
            case PermitMenuControlsEnum.transform:
                this.permitThreeService.selectControls.next(
                    PermitMenuControlsEnum.transform
                );
                break;
            case PermitMenuControlsEnum.rotate:
                this.permitThreeService.selectControls.next(
                    PermitMenuControlsEnum.rotate
                );
                break;
            case PermitMenuControlsEnum.configure:
                this.permitThreeService.selectControls.next(
                    PermitMenuControlsEnum.configure
                );
                break;
            case PermitMenuControlsEnum.discard:
                this.permitThreeService.selectControls.next(
                    PermitMenuControlsEnum.discard
                );
                break;
            default:
                console.error('Control not found');
                break;
        }
    }

    public addRowOfObjects(points: Vector3[]): void {
        this.triggerRowOfObjectsModal(points);
    }

    public updateHouseValues(house: House) {
        this.houseSceneConfig.withAnimation = false;
        this.permitThreeService.selectControls.next(
            PermitMenuControlsEnum.discard
        );
        const houseToSave =
            this.houseService.resetCoordinatesForBackendCall(house);
        this.dispatchUpdateHouseRequest(houseToSave, true);
    }

    public finishHouseDesign(): void {
        this.discardMenuSelection();
        this.callFinishDesign();
    }

    public finishHouseUpdate(): void {
        this.discardMenuSelection();
        this.stepperService.modifyCurrentStepId(true);
    }

    public enableAddingPinComment(): void {
        this.permitThreeService.addPinEvents.next(true);
    }

    public getHouseAssets(): void {
        this.dispatchGetHouseAssetsRequest(
            this.permitThreeService.house.houseId
        );
    }

    public updatePinCommentToDisplay(comment: PermitComment): void {
        this.threeCommentPopupComponent.setComment(comment);
    }

    public onPinPopupClose(pinWasModified: boolean): void {
        if (pinWasModified) {
            return;
        }
        this.permitThreeService.detachPopupToPin.next(true);
    }

    public onPinCommentSaved(comment: PermitComment): void {
        this.permitThreeService.savePinData.next(comment);
    }

    public timeLabel(value: number): string {
        const hour = Math.floor(sunConstants.SUNSRISE_TIME + value / 60);
        const minute = value % 60;
        return (
            FurbanUtil.displayNumberWithTwoDigits(hour) +
            ':' +
            FurbanUtil.displayNumberWithTwoDigits(minute)
        );
    }

    public updateTimeValue(event: MatSliderChange): void {
        if (this.isSunAnimationRunning) {
            this.stopSunAnimation();
        }
        this.setSunBasedOnIntervalValue(event.value);
    }

    public playSunAnimation(): void {
        this.isSunAnimationRunning = true;
        this.sunSubscription = interval(50).subscribe((integer) => {
            this.setSunBasedOnIntervalValue(integer);
        });
    }

    public stopSunAnimation(): void {
        this.isSunAnimationRunning = false;
        this.sunSubscription.unsubscribe();
    }

    public shouldAddPermitDirectives(): boolean {
        return !!(
            !!this.houseSceneConfig?.scene &&
            !!this.houseSceneConfig.renderer &&
            this.deactivateControls
        );
    }

    public checkIfRoofMaterials(): boolean {
        return !!this.selectedRoofMaterial;
    }

    public objectComparisonFunction(
        option: HouseMaterial,
        value: HouseMaterial): boolean {
        return option.materialId === value.materialId;
    }

    public roofMaterialUpdated(): void {
        this.roofAssetColorControl
            .setValue(this.selectedRoofMaterial.materialColor);
        this.updateCurrentSelectedAssetColorAndMaterial(this.selectedRoofMaterial.materialColor, this.selectedRoofMaterial);
    }

    public initializeRoofAssetConfigurationModal(colorAndMaterial: AssetColorAndMaterial): void {
        this.selectedRoofMaterial = colorAndMaterial.material;
        this.roofAssetColorControl
            .setValue(colorAndMaterial.color);
    }

    private callFinishDesign(): void {
        const house = this.houseService.resetCoordinatesForBackendCall(
            this.permitThreeService.house
        );
        this.houseService.finishHouseDesign(house).subscribe((data) => {
            this.stepperService.modifyCurrentStepId(true);
            this.permitThreeService.house = data;
        });
    }

    private initializeDataForColorAndTexturePopup(): void {
        this.getAllMaterials();
        this.roofAssetColorControl.valueChanges.subscribe(
            (data) => {
                this.updateCurrentSelectedAssetColorAndMaterial(data);
            }
        )
    }

    private updateCurrentSelectedAssetColorAndMaterial(color: string, material?: HouseMaterial): void {
        const newColorAndMaterial = new AssetColorAndMaterial();
        newColorAndMaterial.color = color;
        if (!!material) {
            newColorAndMaterial.material = material;
        }
        this.permitThreeService.assetColorAndMaterialChangeEvent.next(newColorAndMaterial);
    }

    private getAllMaterials(): void {
        if (!this.houseService.roofMaterials) {
            this.houseService.getAllMaterials().subscribe(() => {
                this.setDefaultMaterials();
            });
        } else {
            this.setDefaultMaterials();
        }
    }

    private setDefaultMaterials(): void {
        this.roofMaterials = this.houseService.roofMaterials;
        this.selectedRoofMaterial = this.roofMaterials[0];
    }

    private initializeThreeJS(): void {
        this.createScene();
        this.initializeAssets();
    }

    private initializeAssets(): void {
        this.createGroups();
        this.createSceneGround();
    }

    private loadHouses(): void {
        this.permitThreeService.createHouse(this.houseSceneConfig);
        this.getNeighborHouseRequest(this.projectId);
        this.setupSkeletonHelper();
    }

    private enableExtension(asset: PermitAsset): void {
        const assetType = PermitAssetTypeValues.FOR_EXTENSION.id;
        const extensionMesh = PermitUtils.setupExtensionMesh(this.house);
        this.setLoadedAssetType(extensionMesh, asset, assetType);

        ThreeUtils.setHexColorOnMaterial(
            this.permitThreeService.loadedAsset,
            TextureColorEnum.mildBlue
        );
        this.houseSceneConfig.scene.add(this.permitThreeService.loadedAsset);
    }

    private enableRoofAsset(asset: PermitAsset): void {
        const roofBuilder = new ThreeRoofBuilder(
            this.house.roof,
            this.house.processedCoordinatesForThree
        );
        const assetType = PermitAssetTypeValues.FOR_ROOF.id;
        const roofAsset = roofBuilder.createRoofAsset(
            asset.objectLookId,
            2,
            this.house
        );


        this.setLoadedAssetType(roofAsset, asset, assetType);
        ThreeUtils.setHexColorOnMaterial(
            this.permitThreeService.loadedAsset,
            TextureColorEnum.mildBlue
        );
        this.houseSceneConfig.scene.add(this.permitThreeService.loadedAsset);
    }

    private setLoadedAssetType(
        mesh: Group,
        asset: PermitAsset,
        assetType: number
    ): void {
        this.permitThreeService.assetToAdd = asset;
        this.permitThreeService.selectEventsAdded.next(false);
        this.permitThreeService.attachEventsAdded.next(assetType);

        this.permitThreeService.loadedAsset = mesh;
        this.permitThreeService.loadedAsset.visible = false;
        this.permitThreeService.loadedAsset.userData['asset'] =
            this.permitThreeService.assetToAdd;
        this.permitThreeService.loadedAsset.userData['houseId'] =
            this.permitThreeService.house.houseId;
        this.permitThreeService.loadedAsset.userData['assetType'] = assetType;
    }

    private addAssetOnCanvas(asset: PermitAsset): void {
        this.stepperService.changeProjectModifiedState(true);

        if (this.permitThreeService.loadedAsset) {
            this.houseSceneConfig.scene.remove(
                this.permitThreeService.loadedAsset
            );
        }

        if (asset) {
            if (
                asset.assetType.permitAssetTypeId ===
                PermitAssetTypeValues.FOR_EXTENSION.id
            ) {
                this.enableExtension(asset);
                return;
            } else if (
                asset.assetType.permitAssetTypeId ===
                PermitAssetTypeValues.FOR_ROOF.id &&
                asset.objectLookId.includes('RFA')
            ) {
                this.enableRoofAsset(asset);
                return;
            }

            this.permitThreeService.assetToAdd = asset;
            this.permitThreeService.selectEventsAdded.next(false);
            const assetType = asset.assetType.permitAssetTypeId;
            this.permitThreeService.attachEventsAdded.next(assetType);

            PermitUtils.loadPermitAssetFromURL(
                this.houseSceneConfig,
                this.permitThreeService
            );
        } else {
            this.permitThreeService.attachEventsRemoved.next(true);
            this.permitThreeService.selectEventsAdded.next(true);
        }
    }

    private callForCameraAnimation(): void {
        PermitActions.makeAnimationTransition(this.houseSceneConfig.camera.position, this.houseSceneConfig.cameraStates[this.houseSceneConfig.perspectiveCubeState],
            PermitDefaultValues.animationTime,
            () => {
                this.houseSceneConfig.controls.target = new Vector3(
                    this.houseSceneConfig.centroid.x,
                    this.houseSceneConfig.centroid.y,
                    this.houseSceneConfig.centroid.z
                );
            },
            () => {
                this.houseSceneConfig.controls.update();
            }
        );
    }

    private setupSkeletonHelper(): void {
        const wallObject = this.houseSceneConfig.scene.getObjectByName(
            PermitAssetNamesEnum.wallGroup
        );
        const constructionHeight = wallObject.userData['constructionHeight'];
        const topVertices = PermitUtils.getTopVertices(
            this.permitThreeService.house.processedCoordinatesForThree as any[],
            0
        );

        PermitUtils.generateVerticalSkeleton(
            this.houseSceneConfig,
            topVertices,
            constructionHeight
        );
    }

    protected createGroups(): void {
        this.houseSceneConfig.groundGroup = ThreeGroupBuilder.createGroup(ThreeGroupEnum.ground, 0);
        this.houseSceneConfig.scene.add(this.houseSceneConfig.groundGroup);

        this.permitThreeService.extensionGroup = PermitGroupBuilder.createGroup(PermitAssetNamesEnum.extensionGroup, 0);
        this.houseSceneConfig.scene.add(this.permitThreeService.extensionGroup);

        this.permitThreeService.assetObjects = PermitGroupBuilder.createGroup(PermitAssetNamesEnum.assetGroup, 1);
        this.houseSceneConfig.scene.add(this.permitThreeService.assetObjects);

        this.permitThreeService.roofAssetGroup = PermitGroupBuilder.createGroup(PermitAssetNamesEnum.roofAssetGroup, 1);
        this.houseSceneConfig.scene.add(this.permitThreeService.roofAssetGroup);

        this.permitThreeService.dormersGroup = PermitGroupBuilder.createGroup(PermitAssetNamesEnum.dormersGroup, 1);
        this.houseSceneConfig.scene.add(this.permitThreeService.dormersGroup);

        this.permitThreeService.solarPanels = PermitGroupBuilder.createGroup(PermitAssetNamesEnum.solarGroup, 1);
        this.houseSceneConfig.scene.add(this.permitThreeService.solarPanels);

        this.houseSceneConfig.intersectionHelpers = PermitGroupBuilder.createGroup(PermitAssetNamesEnum.intersectionHelpersGroup, 2);
        this.houseSceneConfig.scene.add(this.houseSceneConfig.intersectionHelpers);

        this.houseSceneConfig.pinHelper = ThreeGroupBuilder.createGroup(ThreeGroupEnum.pin_helpers, 3);
        this.houseSceneConfig.scene.add(this.houseSceneConfig.pinHelper);

        this.houseSceneConfig.helpersGroup = ThreeGroupBuilder.createGroup(ThreeGroupEnum.helpers, 3);
        this.houseSceneConfig.scene.add(this.houseSceneConfig.helpersGroup);
    }

    private createScene(): void {
        this.houseSceneConfig.scene = new Scene();
    }

    private createLight(): void {
        // Adding Ambient Light //
        this.houseSceneConfig.scene.add(
            ThreeLightsBuilder.createAmbientLight()
        );

        // Adding Hemisphere Light //
        this.houseSceneConfig.scene.add(
            ThreeLightsBuilder.createHemisphereLight(
                this.houseSceneConfig.maxValues.x / 2,
                this.houseSceneConfig.minValues.y / 2
            )
        );

        // Adding Directional Light //
        this.houseSceneConfig.directionalLight =
            ThreeLightsBuilder.createDirectionalLight(
                this.houseSceneConfig.maxXPointFromArray,
                this.houseSceneConfig.minYPointFromArray
            );
        this.houseSceneConfig.scene.add(this.houseSceneConfig.directionalLight);
    }

    private createCamera(): void {
        const aspectRatio = ThreeUtils.getAspectRatio(this.canvas);
        const position = new Vector3(
            this.houseSceneConfig.centroid.x - 40,
            20,
            this.houseSceneConfig.centroid.z + 40
        );
        this.houseSceneConfig.camera =
            ThreeCameraBuilder.createPerspectiveCamera(aspectRatio, position);
    }

    private configureSceneGround(): void {
        this.houseSceneConfig.currentCoordinates = FurbanUtil.deepCopy(
            this.houseService.houseAreaDrawing.xYCoordinates
        );
        this.houseSceneConfig.maxY = FurbanUtil.getMax(
            this.houseSceneConfig.currentCoordinates,
            'Y'
        );
        this.houseSceneConfig.minX = FurbanUtil.getMin(
            this.houseSceneConfig.currentCoordinates,
            'X'
        );
        this.houseSceneConfig.currentCoordinates =
            FurbanUtil.reduceCoordinatesToMaxYAndMinX(
                this.houseSceneConfig.currentCoordinates,
                this.houseSceneConfig.maxY,
                this.houseSceneConfig.minX
            );

        ThreeUtils.parseCoordinaresForGround(
            this.houseSceneConfig,
            this.houseService.houseAreaDrawing.xYCoordinates
        );
        PermitUtils.setCameraState(this.houseSceneConfig);
        const defaultPlane = this.meshBuilder.createDefaultPlane();
        defaultPlane.castShadow = true;
        defaultPlane.receiveShadow = true;

        this.houseSceneConfig.groundGroup.add(defaultPlane);

        const plane = this.meshBuilder.createPlaneFromGroundAreaSelection(
            this.houseSceneConfig.currentCoordinates,
            1001
        );
        this.houseSceneConfig.groundGroup.add(plane);
        this.configureThreeJsScene();
    }

    private configureThreeJsScene(): void {
        this.createCamera();
        this.startRendering();
        this.addControls();
        this.loadHouses();
        this.createLight();
        this.sunSliderValue =
            (sunConstants.NOON_TIME - sunConstants.SUNSRISE_TIME) * 60;
        this.updateLightPositionForHour(sunConstants.NOON_TIME);
    }

    private createSceneGround(): void {
        if (this.houseService.houseAreaDrawing) {
            this.configureSceneGround();
            return;
        }
        const projectId = this.projectId
            ? this.projectId
            : this.stepperService.projectId;
        this.houseService.getHouseAreaPolygon(projectId).subscribe(() => {
            this.configureSceneGround();
        });
    }

    private startRendering(): void {
        PermitUtils.setCanvasDimensionsTest(
            this.canvas,
            this.houseSceneConfig.camera
        );
        this.houseSceneConfig.renderer = ThreeRendererBuilder.createRenderer(
            this.canvas
        );
        this.houseSceneConfig.htmlRenderer =
            ThreeRendererBuilder.createHTMLRenderer(
                this.houseSceneConfig.renderer.domElement
            );
        this.houseSceneConfig.controlBtns =
            PermitUtils.getControlsHTMLElement();

        this.recursiveRender();
    }

    recursiveRender = () => {
        this.animationFrameId = requestAnimationFrame(this.recursiveRender);
        TWEEN.update(TWEEN.now());
        this.render();
    };

    private addControls(): void {
        this.houseSceneConfig.controls =
            ThreeControlBuilder.createOrbitControls(
                this.houseSceneConfig.camera,
                this.houseSceneConfig.renderer.domElement,
                this.houseSceneConfig.centroid
            );

        this.houseSceneConfig.transformGroup = ThreeGroupBuilder.createGroup(
            ThreeGroupEnum.transform,
            4
        );
        this.houseSceneConfig.scene.add(this.houseSceneConfig.transformGroup);

        PermitUtils.addTransformControls(this.houseSceneConfig);

        this.addEventListenersForCustomPan();
    }

    private addEventListenersForCustomPan(): void {
        document.addEventListener('keyup', this.keyUpFunctionForPan, {
            passive: false,
        });

        document.addEventListener('keydown', this.keyDownFunctionForPan, {
            passive: false,
        });
    }

    private removeEventListenersForPan(): void {
        document.removeEventListener('keydown', this.keyDownFunctionForPan);
        document.removeEventListener('keyup', this.keyUpFunctionForPan);
    }

    private keyUpFunctionForPan = (e: KeyboardEvent) => {
        if (e.code === KeyCode.space) {
            this.houseSceneConfig.controls.mouseButtons.LEFT = THREE.MOUSE.ROTATE;
        }
    };

    public keyDownFunctionForPan = (e: KeyboardEvent) => {
        if (e.code === KeyCode.space) {
            this.houseSceneConfig.controls.mouseButtons.LEFT = THREE.MOUSE.PAN;
        }
    };

    private render(): void {
        if (this.houseSceneConfig.scene && this.houseSceneConfig.camera) {
            this.houseSceneConfig.renderer.render(
                this.houseSceneConfig.scene,
                this.houseSceneConfig.camera
            );
            if (this.houseSceneConfig.htmlRenderer) {
                this.houseSceneConfig.htmlRenderer.render(
                    this.houseSceneConfig.scene,
                    this.houseSceneConfig.camera
                );
            }
        }
    }

    private addThreeObjects(houseAssets: HouseAsset[]): void {
        const objectsIds = this.getUniqueAssetLookIds(houseAssets);
        const arrayObject = {};

        const extensions: HouseAsset[] = [];
        const roofAssets: HouseAsset[] = [];

        for (const setItem of objectsIds) {
            arrayObject[setItem] = [];
        }

        for (const houseAsset of houseAssets) {
            if (houseAsset.asset.objectLookId.includes('EXT')) {
                extensions.push(houseAsset);
                continue;
            } else if (houseAsset.asset.objectLookId.includes('RFA')) {
                roofAssets.push(houseAsset);
                continue;
            }
            arrayObject[houseAsset.asset.objectLookId].push(houseAsset);
        }

        for (let i = 0; i < objectsIds.length; i++) {
            if (arrayObject[objectsIds[i]].length > 0) {
                PermitUtils.loadAndCloneAssetsWithTheSameLookId(
                    this.houseSceneConfig.loader,
                    arrayObject[objectsIds[i]],
                    objectsIds[i],
                    this.houseSceneConfig.scene,
                    this.house
                );
            }
        }

        this.addExtensionsAssets(extensions);
        this.addRoofAssets(roofAssets);

        if (!this.permitThreeService.loadedAsset) {
            this.permitThreeService.selectEventsAdded.next(true);
        }
        this.permitThreeService.showAssets();


    }

    private addExtensionsAssets(extensions: HouseAsset[]): void {
        extensions.forEach((extension) => {
            const extensionMesh = PermitUtils.setupExtensionMesh(
                this.house,
                extension.coordinates,
                extension.rotation,
                extension.scale as THREE.Vector3
            );
            extensionMesh.userData['asset'] = extension.asset;
            extensionMesh.userData['houseId'] = this.house.houseId;
            extensionMesh.userData['assetType'] =
                PermitAssetTypeValues.FOR_EXTENSION.id;
            extensionMesh.userData['houseAsset'] = extension;
            this.permitThreeService.extensionGroup.add(extensionMesh);
        });
    }

    private addRoofAssets(roofAssets: HouseAsset[]): void {
        roofAssets.forEach((roofAsset) => {
            const roofBuilder = new ThreeRoofBuilder(
                this.house.roof,
                this.house.processedCoordinatesForThree
            );
            const roof1 = roofBuilder.createRoofAssetWithCostumTexture(
                roofAsset.asset.objectLookId,
                2,
                roofAsset,
                this.house,
            );

            roof1.userData['asset'] = roofAsset.asset;
            roof1.userData['houseId'] = this.house.houseId;
            roof1.userData['assetType'] = PermitAssetTypeValues.FOR_ROOF.id;
            roof1.userData['houseAsset'] = roofAsset;
            this.permitThreeService.roofAssetGroup.add(roof1);
        });
    }

    private getUniqueAssetLookIds(houseAssets: HouseAsset[]): string[] {
        const objectsIds = [];
        houseAssets.map((result) => objectsIds.push(result.asset.objectLookId));
        return [...new Set(objectsIds)];
    }

    private pressButtonFromTooling(button: ToolingButtonsEnum): void {
        switch (button) {
            case ToolingButtonsEnum.changeCamera:
                this.changeCameraPerspective();
                break;
            case ToolingButtonsEnum.finishDesign:
                this.finishHouseDesign();
                break;
            case ToolingButtonsEnum.finishUpdate:
                this.finishHouseUpdate();
                break;
            case ToolingButtonsEnum.undo:
                this.undo();
                break;
            case ToolingButtonsEnum.redo:
                this.redo();
                break;
            case ToolingButtonsEnum.reset:
                this.reset();
                break;
            case ToolingButtonsEnum.toggleSunSlider:
                this.toggleSunSlider();
                break;
            default:
                break;
        }
    }

    private discardMenuSelection(): void {
        this.discardSelectionFromMenu.emit();
    }

    private addHouseAssets(assets: HouseAsset[]): void {
        if (!assets || assets.length === 0) {
            return;
        }
        this.addThreeObjects(assets);
    }

    private removeHoseAssets(assets: HouseAsset[]): void {
        if (!assets || assets.length === 0) {
            return;
        }

        assets.forEach((asset) => {
            this.removeHouseAssetFromScene(asset);
        });
    }

    private removeHouseAssetFromScene(houseAsset: HouseAsset) {
        switch (houseAsset.asset.assetType.permitAssetTypeId) {
            case PermitAssetTypeValues.FOR_EXTENSION.id:
                // eslint-disable-next-line no-case-declarations
                const extensionMesh =
                    this.permitThreeService.extensionGroup.children.find(
                        (element) =>
                            element.userData['houseAsset'].houseAssetsId ===
                            houseAsset.houseAssetsId
                    );
                this.permitThreeService.extensionGroup.remove(extensionMesh);
                break;
            case PermitAssetTypeValues.FOR_ROOF.id:
                // eslint-disable-next-line no-case-declarations
                const roofMesh =
                    this.permitThreeService.roofAssetGroup.children.find(
                        (element) =>
                            element.userData['houseAsset'].houseAssetsId ===
                            houseAsset.houseAssetsId
                    );
                this.permitThreeService.roofAssetGroup.remove(roofMesh);
                break;
            case PermitAssetTypeValues.FOR_PANELS.id:
                // eslint-disable-next-line no-case-declarations
                const panelMesh =
                    this.permitThreeService.solarPanels.children.find(
                        (element) =>
                            element.userData['houseAsset'].houseAssetsId ===
                            houseAsset.houseAssetsId
                    );
                this.permitThreeService.solarPanels.remove(panelMesh);
                break;
            case PermitAssetTypeValues.FOR_DORMERS.id:
                // eslint-disable-next-line no-case-declarations
                const dormersMesh =
                    this.permitThreeService.dormersGroup.children.find(
                        (element) =>
                            element.userData['houseAsset'].houseAssetsId ===
                            houseAsset.houseAssetsId
                    );
                this.permitThreeService.dormersGroup.remove(dormersMesh);
                break;
            default:
                // eslint-disable-next-line no-case-declarations
                const assetMesh =
                    this.permitThreeService.assetObjects.children.find(
                        (element) =>
                            element.userData['houseAsset'].houseAssetsId ===
                            houseAsset.houseAssetsId
                    );
                this.permitThreeService.assetObjects.remove(assetMesh);
                break;
        }
    }

    public updateHouseView(house: House): void {
        this.permitThreeService.updateHouseValues(house, this.houseSceneConfig);
    }

    private filterElementNotPresentIn(
        assetsArrayToFilter: HouseAsset[],
        assetsArrayToCheckAgains: HouseAsset[]
    ): HouseAsset[] {
        const assetsToReturn = assetsArrayToFilter.filter(
            (obj) =>
                undefined ===
                assetsArrayToCheckAgains.find(
                    (element) => JSON.stringify(element) === JSON.stringify(obj)
                )
        );
        return assetsToReturn;
    }

    private filterHuseAssetsIdNotPresentIn(
        assetsArrayToFilter: HouseAsset[],
        assetsArrayToCheckAgains: HouseAsset[]
    ): HouseAsset[] {
        const assetsToReturn = assetsArrayToFilter.filter(
            (obj) =>
                undefined ===
                assetsArrayToCheckAgains.find(
                    (element) => element.houseAssetsId === obj.houseAssetsId
                )
        );
        return assetsToReturn;
    }

    private undo(): void {
        this.permitThreeService.selectControls.next(
            PermitMenuControlsEnum.discard
        );
        if (!this.previousState.length || this.previousState.length < 1) {
            return;
        }
        const previousState = this.previousState[this.previousState.length - 1];

        const assetsToRemove = this.filterHuseAssetsIdNotPresentIn(
            this.actualState.assets,
            previousState.assets
        );

        let houseToSave;
        if (previousState.shouldUpdateHouse || previousState.areAssetsLoaded || this.actualState.shouldUpdateHouse) {
            houseToSave = this.houseService.resetCoordinatesForBackendCall(
                House.copyHouse(previousState.houseInfo)
            );
        }

        const assetsToSave = this.filterElementNotPresentIn(
            previousState.assets,
            this.actualState.assets
        );

        this.dispatchUndoRequestPermit(
            assetsToSave,
            assetsToRemove,
            houseToSave
        );
    }

    private redo(): void {
        this.permitThreeService.selectControls.next(
            PermitMenuControlsEnum.discard
        );

        if (!this.futureState.length || this.futureState.length < 1) {
            return;
        }

        const futureState = this.futureState[this.futureState.length - 1];

        const assetsToRemove = this.filterHuseAssetsIdNotPresentIn(
            this.actualState.assets,
            futureState.assets
        );

        let houseToSave;
        if (futureState.shouldUpdateHouse || this.actualState.areAssetsLoaded) {
            houseToSave = this.houseService.resetCoordinatesForBackendCall(
                House.copyHouse(futureState.houseInfo)
            );
        }

        const assetsToSave = this.filterElementNotPresentIn(
            futureState.assets,
            this.actualState.assets
        );

        this.dispatchRedoRequestPermit(
            assetsToSave,
            assetsToRemove,
            houseToSave
        );
    }

    private reset(): void {
        if (this.house.isClone) {
            this.setOriginalHouseValuesToClone();
            return;
        }
        let initialHouse = this.setDefaultValuesToHouse();
        initialHouse = this.houseService.resetCoordinatesForBackendCall(initialHouse);
        this.resetCurrentStep();
        this.dispatchResetHouseRequest(initialHouse);
    }

    private setDefaultValuesToHouse(): House {
        const defaultHouse = House.copyHouse(this.house);
        this.setDefaultMaterialsAndColors(defaultHouse);
        defaultHouse.floorHeight = HouseFormValuesEnum.floorHeightDefault;
        defaultHouse.numberOfFloors = HouseFormValuesEnum.numberOfFloorsDefault;
        return defaultHouse;
    }

    private setDefaultMaterialsAndColors(houseToUpdate: House): void {
        houseToUpdate.houseMaterial = this.houseService.houseMaterials[0];
        houseToUpdate.houseColor = ColorsDefaultValuesEnum.defaultHouseColor;
        houseToUpdate.roof.roofMaterial = this.houseService.roofMaterials[0];
        houseToUpdate.roof.roofColor = ColorsDefaultValuesEnum.defaultRoofColor;
    }

    private setOriginalHouseValuesToClone(): void {
        this.houseService.getHouse(this.projectId, false).subscribe(
            (data) => {
                if (!data) {
                    return;
                }

                const defaultHouse = House.copyHouse(this.house);
                this.setMaterialAndColor(defaultHouse, data);
                this.resetCurrentStep();
                this.dispatchResetHouseRequest(defaultHouse);
            }
        )
    }

    private setMaterialAndColor(defaultHouse: House, originalHouse: House): void {
        defaultHouse.houseMaterial = originalHouse.houseMaterial;
        defaultHouse.houseColor = originalHouse.houseColor;
        defaultHouse.roof.roofMaterial = originalHouse.roof.roofMaterial;
        defaultHouse.roof.roofColor = originalHouse.roof.roofColor;
        defaultHouse.floorHeight = originalHouse.floorHeight;
        defaultHouse.numberOfFloors = originalHouse.numberOfFloors;
        defaultHouse.xYCoordinates = JSON.stringify(defaultHouse.xYCoordinates);
        defaultHouse.latLonCoordinates = JSON.stringify(defaultHouse.latLonCoordinates);
    }

    private subscribeToStore(): void {
        this.storeSubscription = this.store
            .pipe(
                map((state) => selectActualPermitState(state['permit'].store))
            )
            .subscribe((data) => {
                if (data.isRequestAction) {
                    return;
                }

                this.houseAssets = data.assets;
                this.removeHoseAssets(data.assetsToRemove);
                this.addHouseAssets(data.assetsToAdd);
            });
    }

    private subscribeToHouseHeightUpdated(): void {
        this.houseHeightUpdateSubscription = this.permitThreeService.houseHeightUpdateObservable.subscribe(
            (newHouseHeight) => {
                this.updateRoofAssets(newHouseHeight);
            }
        )
    }

    private updateRoofAssets(height: number): void {
        const pathObjectsToUpdate = [];
        let heightVariation = 0;
        this.permitThreeService.roofAssetGroup.children.forEach((element) => {
            heightVariation = height - element.position.y;
            const objToSave = this.updateHeightOfRoofAsset(element, height);
            pathObjectsToUpdate.push(objToSave);
        });

        this.permitThreeService.dormersGroup.children.forEach((element) => {
            const objToSave = this.updateHeightOfRoofAsset(element, heightVariation, true);
            pathObjectsToUpdate.push(objToSave);
        });

        this.permitThreeService.solarPanels.children.forEach((element) => {
            const objToSave = this.updateHeightOfRoofAsset(element, heightVariation, true);
            pathObjectsToUpdate.push(objToSave);
        });

        this.dispatchUpdateMultipleRoofObjectsRequest(pathObjectsToUpdate);
    }

    private updateHeightOfRoofAsset(element: Object3D, heightVariation: number, shouldUseCurrentHeight: boolean = false): HouseAsset {
        const objToSave = PermitUtils.getHouseAssetFromGroupUserData(element);
        const newPosition = element.position;
        const updatedHeightForDormer = shouldUseCurrentHeight ? heightVariation + newPosition.y : heightVariation
        newPosition.setY(updatedHeightForDormer);
        objToSave.coordinates = JSON.stringify(newPosition);
        element.position.copy(newPosition);
        element.updateMatrix();
        return objToSave;
    }

    private resetCurrentStep(): void {
        this.stepperService.resetCurrentStepIdForProject(this.stepperService.currentStep.id - 1);
        this.stepperService.currentStep.status = PermitStepStatusEnum.inProgress;
    }

    private dispatchResetHouseRequest(house: House): void {
        this.store.dispatch(resetHouseRequest({ house: house }));
    }

    private dispatchGetHouseAssetsRequest(houseId: string): void {
        this.store.dispatch(getHouseAssets({ houseId: houseId }));
    }

    private dispatchResetStore(): void {
        this.store.dispatch(restoreInitialStatePermit());
    }

    private dispatchUpdateHouseRequest(
        house: House,
        shouldUpdate: boolean
    ): void {
        this.store.dispatch(
            updateHouseInformationsRequest({
                house: house,
                shouldUpdatePrevious: shouldUpdate,
            })
        );
    }

    protected dispatchAddMultipleAssetsRequest(assets: HouseAsset[]): void {
        this.store.dispatch(
            addMultipleAssetObjectsRequest({ objects: assets })
        );
    }

    protected dispatchUpdateMultipleRoofObjectsRequest(houseAssetsToUpdate: HouseAsset[]): void {
        this.store.dispatch(updateMultipleRoofAssetsWhenHouseChangesRequest({ objects: houseAssetsToUpdate }));
    }

    private dispatchUndoRequestPermit(
        objectsToSave: HouseAsset[],
        objectsToRemove: HouseAsset[],
        houseToSave: House
    ): void {
        this.store.dispatch(
            undoRequestPermit({
                objectsToSave: objectsToSave,
                objectsToRemove: objectsToRemove,
                houseToSave: houseToSave,
            })
        );
    }

    private dispatchRedoRequestPermit(
        objectsToSave: HouseAsset[],
        objectsToRemove: HouseAsset[],
        houseToSave: House
    ): void {
        this.store.dispatch(
            redoRequestPermit({
                objectsToSave: objectsToSave,
                objectsToRemove: objectsToRemove,
                houseToSave: houseToSave,
            })
        );
    }

    private getNeighborHouseRequest(projectId: string): void {
        this.houseService
            .getNeigborHousePolygons(projectId)
            .subscribe((data) => {
                this.neighborHouses = data;
                this.checkIfMaterialsLoadedAndAddNeighbors();
            });
    }

    private checkIfMaterialsLoadedAndAddNeighbors(): void {
        if (
            this.houseService.roofMaterials &&
            this.houseService.houseMaterials
        ) {
            this.addNeighborHousesOnCanvas();
            return;
        }

        this.houseService.getAllMaterials().subscribe(() => {
            this.addNeighborHousesOnCanvas();
        });
    }

    private addNeighborHousesOnCanvas(): void {
        from(this.neighborHouses)
            .pipe(
                map((house) => {
                    return this.initializeNeighborHouse(house);
                }),
                tap((house) => {
                    this.permitThreeService.createNeighborHouse(
                        this.houseSceneConfig,
                        house
                    );
                })
            )
            .subscribe();
    }

    private initializeNeighborHouse(house): House {
        const newHouse = House.copyHouse(house);
        if (!newHouse.roof) {
            newHouse.roof = new Roof();
        }
        newHouse.roof.roofMaterial = this.houseService.roofMaterials[0];
        newHouse.roof.roofColor = ColorsDefaultValuesEnum.defaultRoofColor;
        newHouse.processedCoordinatesForThree.pop();
        newHouse.houseMaterial = this.house.houseMaterial;
        newHouse.numberOfFloors = HouseFormValuesEnum.numberOfFloorsDefault;
        newHouse.floorHeight = HouseFormValuesEnum.floorHeightDefault;
        return newHouse;
    }

    private updateLightPositionForHour(hour?: number): void {
        if (!this.sunBuilder) {
            this.createSun();
        }
        if (hour) {
            this.dateForSun.setHours(hour);
        }
        this.updateSun();
    }

    private updateSun(): void {
        this.sunBuilder.updateOrientation(this.dateForSun);
        this.sunBuilder.updateDirectionalLight();
    }

    private createSun(): void {
        this.sunBuilder = new ThreeSunBuilder(
            this.houseSceneConfig,
            new Vector2(43.78, 23.7),
            new Vector3(0, 0.0, -1),
            new Vector3(1, 0.0, 0),
            new Vector3(0.0, -1.0, 0.0)
        );
        this.dateForSun = new Date();
    }

    private setSunBasedOnIntervalValue(integer: number): void {
        integer = integer % this.SUNSHINING_MINUTES;
        this.sunSliderValue = integer;
        const hour = Math.floor(sunConstants.SUNSRISE_TIME + integer / 60);
        const minute = integer % 60;
        this.dateForSun.setHours(hour);
        this.dateForSun.setMinutes(minute);
        this.updateSun();
    }

    private toggleSunSlider(): void {
        this.shouldDisplayTimeSlider = !this.shouldDisplayTimeSlider;
    }

    private subscribeToKeyboardObservable(): void {
        this.permitThreeService.keyboardPressObservable.subscribe((data) => {
            switch (data.type) {
                case PermitKeyboardActionEnum.undo:
                    this.undo();
                    break;
                case PermitKeyboardActionEnum.redo:
                    this.redo();
                    break;
                default:
                    break;
            }
        });
    }

    private triggerRowOfObjectsModal(coordinates: Vector3[]): void {
        const dialogRef = this.dialog.open(RowOfObjectsDialogComponent, {
            width: '600px',
        });
        dialogRef.disableClose = true;
        dialogRef.componentInstance.parentRef = this.parentView;
        dialogRef.componentInstance.projectId = this.projectId;
        dialogRef.componentInstance.projectType =
            ProjectTypeEnum.pioneerPermitProject;

        dialogRef.componentInstance.onModalClose.subscribe((selectedObject) => {
            if (selectedObject) {
                selectedObject.freeshapePoints = JSON.stringify(coordinates);
                this.placeRowOfObjects(selectedObject, coordinates);
                dialogRef.close();
            }
        });

        dialogRef.afterClosed().subscribe(() => {
            this.menuService.emitOnActionFinished();
        });
    }

    private placeRowOfObjects(
        object: PermitAsset,
        coordinates: Vector3[]
    ): void {
        const objectsToAdd = RowOfObjects.getAssetsPlacedInARow(
            this.houseSceneConfig,
            object,
            coordinates,
            this.house.houseId
        );
        this.dispatchAddMultipleAssetsRequest(objectsToAdd);
    }
}
