import * as THREE from 'three';
import { sRGBEncoding } from 'three';
import { PermitAssetNamesEnum } from '../_enum/permit-asset-names.enum';
import { House } from '../_models/house';
import { ThreeUtils } from '../_three-helpers/three.utils';
import { PermitUtils } from './permit.utils';

export class ThreeWallBuilder {
    public house: House;
    public defaultDivisionColor = 0xffffff;
    public defaultFloorDivision = 0.2;
    public walllGroupName = PermitAssetNamesEnum.wallGroup;
    readonly BUILDING_HEIGHT_SCALE_PARAMETER = 100;

    constructor(house: House) {
        this.house = house;
        if (!this.house.isNeighborHouse) {
            return;
        }
        this.walllGroupName = PermitAssetNamesEnum.neighborWallGroup;
    }

    public createHouseWalls(): THREE.Group {
        const wallGroup = new THREE.Group();
        wallGroup.name = this.walllGroupName;
        const wall = this.singleWallCreate();
        wallGroup.userData['constructionHeight'] = 0;

        const wallDivision = PermitUtils.createDivision(
            this.house.processedCoordinatesForThree,
            this.defaultFloorDivision,
            this.defaultDivisionColor
        );

        wall.visible = false;
        wallDivision.visible = false;
        this.addFloor(wallDivision, wallGroup);

        const clonedFloor = wall.clone();
        clonedFloor.position.y =
            this.house.floorHeight / this.BUILDING_HEIGHT_SCALE_PARAMETER / 2 +
            this.defaultFloorDivision;
        wallGroup.add(clonedFloor);

        for (let i = 1; i < this.house.numberOfFloors; i++) {
            /* This will create division for the floor */
            this.addFloor(wallDivision, wallGroup);
            /* This will create the wall itself */
            const clonedFloor = wall.clone();
            clonedFloor.position.y =
                wallGroup.userData['constructionHeight'] -
                this.house.floorHeight /
                    this.BUILDING_HEIGHT_SCALE_PARAMETER /
                    2;
            wallGroup.add(clonedFloor);
        }

        return wallGroup;
    }

    public createMaterial(textureName: string): THREE.MeshLambertMaterial {
        const texture = new THREE.TextureLoader().load(textureName);
        texture.encoding = sRGBEncoding;
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(1, 1);
        const material = new THREE.MeshLambertMaterial({
            map: texture,
            side: THREE.DoubleSide,
        });
        material.needsUpdate = true;
        material.name = PermitAssetNamesEnum.wallMaterial;

        return material;
    }

    public singleWallCreate(): THREE.Mesh {
        const extrudedGeometry = PermitUtils.createExtrudeGeometry(
            this.house.processedCoordinatesForThree,
            this.house.floorHeight / this.BUILDING_HEIGHT_SCALE_PARAMETER,
            true
        );
        const material = this.createMaterial(
            this.house.houseMaterial.backgroundImage
        );
        material.color.set(this.house.houseColor);
        const wall = PermitUtils.createMesh(extrudedGeometry, material);
        ThreeUtils.applyPositionAndRotationToMesh(wall, true);
        wall.castShadow = true;
        wall.receiveShadow = true;
        return wall;
    }

    private addFloor(wallDivision: THREE.Mesh, wallGroup: THREE.Group): void {
        const clonedWallDivision = wallDivision.clone();
        clonedWallDivision.position.y =
            wallGroup.userData['constructionHeight'] +
            this.defaultFloorDivision / 2;
        wallGroup.add(clonedWallDivision);
        wallGroup.userData['constructionHeight'] =
            wallGroup.userData['constructionHeight'] +
            this.house.floorHeight / this.BUILDING_HEIGHT_SCALE_PARAMETER +
            this.defaultFloorDivision;
    }
}
