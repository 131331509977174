<div class="full-width full-height flex-row-center">
    <div class="new-admin-container flex-column-center" *ngIf="isSuperAdmin">
        <h4>{{ 'superadmin.newAdmin.createNewAdmin' | translate }}</h4>

        <form
            *ngIf="municipalities"
            [formGroup]="adminCreateFormGroup"
            (ngSubmit)="addNewAdmin()"
        >
            <furban-dropdown-autocomplete
                [municipalities]="municipalities"
                [formGroup]="adminCreateFormGroup"
            >
            </furban-dropdown-autocomplete>

            <mat-form-field class="full-width" appearance="outline">
                <input
                    required
                    matInput
                    placeholder="{{ 'superadmin.newAdmin.email' | translate }}"
                    formControlName="emailFormControl"
                />
                <mat-error *ngIf="showEmailError()">
                    {{ 'superadmin.newAdmin.validEmailErrorText' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="
                        adminCreateFormGroup
                            .get('emailFormControl')
                            ?.hasError('required')
                    "
                >
                    {{
                        'superadmin.newAdmin.requiredEmailErrorText' | translate
                    }}
                    <strong>{{
                        'superadmin.newAdmin.required' | translate
                    }}</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width field-pass" appearance="outline">
                <input
                    required
                    matInput
                    placeholder="{{
                        'superadmin.newAdmin.password' | translate
                    }}"
                    formControlName="passwordFormControl"
                    type="password"
                />
                <mat-error *ngIf="showPasswordError()">
                    {{
                        'superadmin.newAdmin.validPasswordErrorText' | translate
                    }}
                </mat-error>
                <mat-error
                    *ngIf="
                        adminCreateFormGroup
                            .get('passwordFormControl')
                            ?.hasError('required')
                    "
                >
                    {{
                        'superadmin.newAdmin.requiredPasswordErrorText'
                            | translate
                    }}
                    <strong>{{
                        'superadmin.newAdmin.required' | translate
                    }}</strong>
                </mat-error>
            </mat-form-field>
            <button class="primary-button" type="submit">
                <h6>
                    {{
                        'superadmin.newAdmin.addNewAdminButtonText' | translate
                    }}
                </h6>
            </button>
        </form>
    </div>
</div>
