<div class="title-content">
    <h4>{{ 'conversation.generalDiscussion' | translate }}</h4>
    <button class="ghost-button">
        <span class="material-icons">close</span>
    </button>
</div>

<furban-conversation-divider></furban-conversation-divider>

<div class="full-width" [style.padding.px]="20">
    {{ 'conversation.filter' | translate }}
</div>

<div class="comments-main">
    <furban-conversation-divider [text]="'February 10, 2022'"></furban-conversation-divider>

    <furban-general-comment *ngFor="let comm of conversationComments" [comment]="comm">
        <ng-container subarea-type="likes">
            <div class="likes-content" [style.margin-right.px]="16">
                <span class="material-icons" [style.margin-right.px]="4">
                    {{ ownLiked(comm) ? 'favorite' : 'favorite_border' }}
                </span>

                <ng-container *ngIf="hasLikes(comm)">
                    <p>{{ comm.likes?.length }}</p>
                </ng-container>
            </div>
        </ng-container>
        <ng-container subarea-type="hour">
            <p class="created-hour">
                {{ comm.createdHour }}
            </p>
        </ng-container>
        <ng-container subarea-type="attachments">
            <ng-container *ngIf="hasAttachments(comm)">
                <div class="attachment-content">
                    <ng-container *ngIf="true">
                        <span class="material-icons">attach_file</span>
                        <p>{{ 'conversation.attachments' | translate }}</p>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>

        <ng-container subarea-type="menuTrigger">
            <!-- Place to add buttons  -->
        </ng-container>
    </furban-general-comment>
</div>

<furban-comment-form [designProposal]="dp"> </furban-comment-form>
