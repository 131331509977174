import { PermitAsset } from './permit-asset';
import { Vector3, Euler } from 'three';
import { HouseMaterial } from './house-material';

export class HouseAsset {
    public houseAssetsId?: string;
    public asset: PermitAsset;
    public houseId: string;
    public coordinates: string | Vector3;
    public rotation: string | Euler;
    public scale: string | Vector3;
    public isCloned = false;
    public color?: string;
    public material?: HouseMaterial;
}
