<div class="project-info">
    <section
        class="image"
        [style.background-image]="'url(' + getMedia(project.media) + ')'"
    ></section>
    <section class="project-details">
        <h6 [style.margin-bottom.px]="8">{{ project.name }}</h6>
        <div [style.margin-bottom.px]="24">
            <span [style.margin-right.px]="64">
                {{ 'projectDetailsCard.startDate' | translate }}
                {{ project.startDate | date : 'M/d/yyyy' }}
            </span>
            <span>
                {{ 'projectDetailsCard.endDate' | translate }}
                {{ project.endDate | date : 'M/d/yyyy' }}
            </span>
        </div>
        <p class="project-description">
            {{ project.description }}
        </p>
    </section>
</div>
<hr />
