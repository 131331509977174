import { PermitStepStatusEnum } from '../_enum/permit-step-status.enum';

export class Step {
    public id: number;
    public title: string;
    public status: number;
    public path: string;

    public constructor(
        id: number,
        title: string,
        status: number,
        path: string
    ) {
        this.id = id;
        this.title = title;
        this.status = status;
        this.path = path;
    }

    public isCompleted(): boolean {
        return this.status === PermitStepStatusEnum.completed;
    }

    public isInProgress(): boolean {
        return this.status === PermitStepStatusEnum.inProgress;
    }

    public isUntouched(): boolean {
        return this.status === PermitStepStatusEnum.untouched;
    }
}
