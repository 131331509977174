import { Component, OnInit, ViewContainerRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupsService } from '../groups/groups.service';

import { Project, routingConstants } from '@furban/utilities';
import { Router } from '@angular/router';

@Component({
    selector: 'furban-app-display-projects-popup',
    templateUrl: './display-projects-popup.component.html',
    styleUrls: ['./display-projects-popup.component.scss'],
})
export class DisplayProjectsPopupComponent implements OnInit {
    public parentViewContainerRef: ViewContainerRef;
    public groupId: string;
    public projects: Project[];
    public groupName: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<DisplayProjectsPopupComponent>,
        private groupService: GroupsService,
        private router: Router
    ) {
        this.groupId = data.groupId;
        this.groupName = data.groupName;
    }

    ngOnInit(): void {
        this.groupService.getProjectsByGroupId(this.groupId).subscribe((data) => {
            this.projects = data;
        });
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public navigateToProject(projectId: string): void {
        this.dialogRef.close();
        this.router.navigateByUrl(
            `${routingConstants.justApp}/partner/process/${projectId}/(project:overview)`
        );
    }
}
