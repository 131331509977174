import { Vector3, BufferGeometry, Line, LineBasicMaterial } from 'three';
import { Project3dModel } from './project-3d-model';
import { TextureColorEnum } from '../_enum/texture-color.enum';

export class FreeShapeLineDetails {
    public points: Vector3[] = [];
    public lineGeometry: BufferGeometry;
    public line: Line;
    public lineMaterial: LineBasicMaterial;
    public freeshapeProject3DModel?: Project3dModel;
    public pointsLength = 0;
    public lengthInMeters?: number = 0;
    public freeshapeType: string;

    public setFreeShapeLineObject(): void {
        this.lineGeometry = new BufferGeometry();
        this.lineMaterial = new LineBasicMaterial({
            color: TextureColorEnum.neutral1,
        });
        this.line = new Line(this.lineGeometry, this.lineMaterial);
        this.lengthInMeters = 0;
    }

    public resetPointsDetails(): void {
        this.points = [];
        this.pointsLength = 0;
    }

    public updateGeometry(): void {
        this.lineGeometry.setFromPoints(this.points);
    }
}
