<header>
    <img src="../../assets/images/presentation/furban_color.svg" />
</header>
<div class="header-title">
    <h5>{{ 'homepage.citizenRegister.gdpr-title' | translate }}</h5>
</div>
<div class="static-container">
    <p><strong>(1)</strong></p>
    <p>
        In faucibus nulla ac sapien venenatis vulputate. Sed vitae velit et
        neque interdum pharetra non eu urna. Quisque viverra diam non ultricies
        malesuada. Sed suscipit justo ut lacus cursus finibus. Donec congue
        dignissim justo id dictum. Mauris aliquet dictum ante et imperdiet.
        Suspendisse ut sapien nec nunc elementum gravida. Phasellus in nisi id
        neque varius interdum id rutrum eros. Ut blandit, ante a lacinia
        laoreet, nunc metus placerat justo, at porta nisi neque ut augue.
        Aliquam non ligula metus. Praesent mattis libero sed turpis dictum, at
        scelerisque felis mattis. Nulla id pellentesque nunc. Nulla pretium
        mauris lorem, vel consectetur mi maximus non. Maecenas interdum maximus
        quam, a lobortis dolor fringilla id. Pellentesque sit amet consequat
        lacus.
    </p>
    <p><strong>(2)</strong></p>
    <p>
        Aliquam erat volutpat. Nulla rhoncus scelerisque nisl, hendrerit rutrum
        turpis condimentum vitae. Aliquam ultricies felis odio, eget eleifend
        erat finibus vitae. Cras ullamcorper finibus euismod. Proin eu diam
        urna. Mauris iaculis, metus in scelerisque dapibus, odio nisl fringilla
        justo, sed faucibus sapien quam eu diam. Donec et neque massa. In hac
        habitasse platea dictumst.
    </p>

    <p><strong>(3)</strong></p>
    <p>
        Cras faucibus sodales sem in posuere. Curabitur egestas pharetra metus,
        id efficitur orci tempor eget. Proin leo lorem, scelerisque vel placerat
        sollicitudin, finibus in est. Mauris tempus lacus ut hendrerit pulvinar.
        Pellentesque diam ex, ultricies at suscipit quis, pellentesque non nisi.
        Suspendisse ullamcorper est nunc, ac varius leo imperdiet et. Nulla
        laoreet malesuada ligula, imperdiet iaculis sapien sollicitudin vitae.
        Proin vulputate justo vel odio maximus pharetra. Duis eu ipsum et urna
        faucibus pretium non et velit. Donec ut lorem augue. Morbi nec cursus
        nunc, non maximus tellus. Donec nec elementum velit, malesuada feugiat
        dolor. Etiam justo dolor, semper vel velit eget, mattis fermentum elit.
        Phasellus sollicitudin lectus pretium libero porttitor vestibulum. Nulla
        mollis, lacus quis congue vulputate, urna orci elementum est, eget
        rhoncus enim neque eget ligula. Ut ut magna pretium, luctus lacus non,
        porta eros.
    </p>
</div>
