import { SortTypeEnum } from '../_enum/sort-type.enum';
import { DpSortObject } from '../_models/dp-sort-object';

export class SortingObjectsArray {
    public static getSortingObjects(hideTop?: boolean): DpSortObject[] {
        const sortingObjects: DpSortObject[] = [
            {
                title: 'user.dashboard.mostLiked',
                tag: SortTypeEnum.SORT_BY_LIKES,
            },
        ];
        if (hideTop === false) {
            sortingObjects.unshift({
                title: 'user.dashboard.mostPoints',
                tag: SortTypeEnum.SORT_BY_POINTS,
            });
        }
        return sortingObjects;
    }
}
