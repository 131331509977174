import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'apps/furban-client/src/environments/environment';
import { Observable } from 'rxjs';

import { FurbanUtil } from '@furban/utilities';

@Injectable({
    providedIn: 'root',
})
export class CitizenRegisterService {
    private _options = {
        headers: new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
        ),
    };
    constructor(private http: HttpClient) { }

    public submitRegistration(
        projectId: string,
        email: string,
        captcha: string
    ): Observable<void> {
        const REQUEST_DATA: any = {
            projectId: projectId,
            email: email,
            captcha: captcha,
        };

        return this.http.post<void>(
            `${environment.apiPath}/register/citizen`,
            FurbanUtil.encodeObjectURIComponents(REQUEST_DATA),
            this._options
        );
    }
}
