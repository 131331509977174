import { Component, Input } from "@angular/core";
import { PollOption } from "@furban/utilities";
@Component({
    selector: 'furban-poll-options',
    templateUrl: './poll-options.component.html',
    styleUrls: ['./poll-options.component.scss'],
})
export class PollOptionsComponent{
    @Input() options: PollOption[];

    calculatePercentage = (option: PollOption): number =>{
        if (option.pollAnswers.length === 0 && option.totalAnswers === 0) {
            return 0;
        }
        return Math.round((option.pollAnswers.length * 100) / option.totalAnswers);
    }
}