import {
    Directive,
    EventEmitter,
    Output,
    Input,
    OnInit,
    OnDestroy,
    ElementRef
} from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { Quaternion, Vector2, Vector3 } from 'three';
import { take } from 'rxjs/operators';

@Directive({
    selector: '[furbanThreeRotate]',
    exportAs: 'rotateDirective'
})
export class ThreeRotateDirective implements OnInit, OnDestroy {

    @Input() mesh: any;
    @Output() mouseDownEvent: EventEmitter<boolean> = new EventEmitter();

    public deltaX = 0;
    public deltaY = 0;
    private startPoint = new Vector2();
    private rotationSpeed = 2;
    private lastMoveTimestamp;
    private moveReleaseTimeDelta = 50;
    private rotateStartPoint = new Vector3(0, 0, 1);
    private rotateEndPoint = new Vector3(0, 0, 1);
    private curQuaternion: Quaternion;

    private windowHalfX = window.innerWidth / 2;
    private windowHalfY = window.innerHeight / 2;

    private bindedMouseDown = this.onDocumentMouseDown.bind(this);
    private bindedMouseMove = this.onDocumentMouseMove.bind(this);
    private bindedMouseUp = this.onDocumentMouseUp.bind(this);

    private subscription: Subscription;

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        this.el.nativeElement.addEventListener('pointerdown', this.bindedMouseDown, false);
    }

    ngOnDestroy() {
        this.el.nativeElement.removeEventListener('pointerdown', this.bindedMouseDown);
        this.subscription?.unsubscribe();
    }

    public handleRotation = (): void => {
        this.rotateEndPoint = this.projectOnTrackball(this.deltaX, this.deltaY);

        const rotateQuaternion = this.rotateMatrix(this.rotateStartPoint, this.rotateEndPoint);
        this.curQuaternion = this.mesh.quaternion;
        this.curQuaternion.multiplyQuaternions(rotateQuaternion, this.curQuaternion);
        this.curQuaternion.normalize();
        this.mesh.setRotationFromQuaternion(this.curQuaternion);
    }

    private onDocumentMouseDown(event: MouseEvent): void {
        event.preventDefault();
        this.subscription?.unsubscribe();

        this.el.nativeElement.addEventListener('pointermove', this.bindedMouseMove, false);
        this.el.nativeElement.addEventListener('pointerup', this.bindedMouseUp, false);

        this.mouseDownEvent.emit(true);

        this.startPoint.x = event.clientX;
        this.startPoint.y = event.clientY;

        this.rotateStartPoint = this.rotateEndPoint = this.projectOnTrackball(0, 0);
    }

    private onDocumentMouseMove(event: MouseEvent): void {
        this.deltaX = event.x - this.startPoint.x;
        this.deltaY = event.y - this.startPoint.y;

        this.handleRotation();

        this.startPoint.x = event.x;
        this.startPoint.y = event.y;

        this.lastMoveTimestamp = new Date();
    }

    private subscribeToTimer(): void {
        const interval$ = interval(1000);
        const example = interval$.pipe(take(5));

        this.subscription = example.subscribe(val => {
            const curentDate = new Date().getTime();
            const lastMoveTimestamp = this.lastMoveTimestamp ? this.lastMoveTimestamp.getTime() : new Date().getTime();
            const isMoreThanFiveSeconds = (curentDate - lastMoveTimestamp) > 5000;
            if (isMoreThanFiveSeconds) {
                this.mouseDownEvent.emit(undefined)
            }
        });
    }

    private onDocumentMouseUp(event: MouseEvent): void {
        if (new Date().getTime() - this.lastMoveTimestamp.getTime() > this.moveReleaseTimeDelta) {
            this.deltaX = event.x - this.startPoint.x;
            this.deltaY = event.y - this.startPoint.y;
        }

        this.mouseDownEvent.emit(false);

        this.el.nativeElement.removeEventListener('pointermove', this.bindedMouseMove, false);
        this.el.nativeElement.removeEventListener('pointerup', this.bindedMouseUp, false);

        this.subscribeToTimer();
    }

    private projectOnTrackball(touchX: number, touchY: number): Vector3 {
        const mouseOnBall = new Vector3();

        mouseOnBall.set(
            this.clamp(touchX / this.windowHalfX, -1, 1), this.clamp(-touchY / this.windowHalfY, -1, 1),
            0.0
        );

        const length = mouseOnBall.length();

        if (length > 1.0) {
            mouseOnBall.normalize();
        }
        else {
            mouseOnBall.z = Math.sqrt(1.0 - length * length);
        }

        return mouseOnBall;
    }

    private rotateMatrix(rotateStart: Vector3, rotateEnd: Vector3): Quaternion {
        const axis = new Vector3();
        const quaternion = new Quaternion();

        let angle = Math.acos(rotateStart.dot(rotateEnd) / rotateStart.length() / rotateEnd.length());

        if (angle) {
            axis.crossVectors(rotateStart, rotateEnd).normalize();
            angle *= this.rotationSpeed;
            quaternion.setFromAxisAngle(axis, angle);
        }

        quaternion.x = 0;
        quaternion.z = 0;
        return quaternion;
    }

    private clamp(value: number, min: number, max: number): number {
        return Math.min(Math.max(value, min), max);
    }

}