import {
    Component,
    OnInit,
    Input,
    ViewChild,
    EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { ControlsUtil, ModalManager } from '@furban/utilities';

@Component({
    selector: 'furban-app-custom-object',
    templateUrl: './custom-object.component.html',
    styleUrls: ['./custom-object.component.scss'],
})
export class CustomObjectComponent implements OnInit {
    @Input() public parentRef;
    @Input() public dimensions;
    @ViewChild('customObjectForm', { static: true }) customObjectForm;
    customObjectCheckForm: FormGroup;
    onModalClose = new EventEmitter();

    public hasControlMaxError = ControlsUtil.hasControlMaxError;
    public hasControlMinError = ControlsUtil.hasControlMinError;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;

    constructor(
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<CustomObjectComponent>,
        private modal: ModalManager,
        public translateService: TranslateService
    ) { }

    ngOnInit() {
        this.customObjectCheckForm = this.formBuilder.group({
            widthFormControl: [
                this.dimensions.width,
                [Validators.required, Validators.max(40), Validators.min(1)],
            ],
            heigthFormControl: [
                this.dimensions.height,
                [Validators.required, Validators.max(40), Validators.min(1)],
            ],
            depthFormControl: [
                this.dimensions.depth,
                [Validators.required, Validators.max(40), Validators.min(1)],
            ],
        });
    }

    closeDialog() {
        // check if modified to save before close
        if (
            this.customObjectCheckForm.get('widthFormControl').dirty ||
            this.customObjectCheckForm.get('heigthFormControl').dirty ||
            this.customObjectCheckForm.get('depthFormControl').dirty
        ) {
            this.modal
                .showModal(
                    this.parentRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'errors.discardMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (res) {
                        this.dialogRef.close();
                    }
                });
        } else {
            this.dialogRef.close();
        }
    }

    saveCustomObject() {
        if (this.customObjectCheckForm.invalid) {
            return;
        }

        const dimensions = {
            width: this.customObjectCheckForm.get('widthFormControl').value,
            height: this.customObjectCheckForm.get('heigthFormControl').value,
            depth: this.customObjectCheckForm.get('depthFormControl').value,
        };

        this.onModalClose.emit(dimensions);
    }
}
