import { ThreeGridValues } from '../_models/three-grid-values';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import {
    Group,
    Vector3,
    Vector2,
    Scene,
    PerspectiveCamera,
    WebGLRenderer,
    Raycaster,
    DirectionalLight,
    Mesh,
} from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import {
    CSS2DObject,
    CSS2DRenderer,
} from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { FreeShapeLineDetails } from './freeshape-line-details';
import { ThreeSelectionHelper } from '../_three-helpers/three-selection-helper';
import { SelectionBox } from 'three/examples/jsm//interactive/SelectionBox.js';

export class HouseSceneConfig {
    public scene: Scene;
    public camera: PerspectiveCamera;
    public renderer: WebGLRenderer;
    public controls: OrbitControls;
    public centroid: Vector3;
    public minValues: Vector2;
    public maxValues: Vector2;
    public cameraStates: Array<Vector3> = [];
    public perspectiveCubeState = 0;
    public raycaster = new Raycaster();
    public loader = new GLTFLoader();
    public gridValues: ThreeGridValues = { size: 100, divisions: 50 };
    public offsetCentroid = 40;
    public lockedValueY = NaN;
    public controlBtns: CSS2DObject;
    public htmlRenderer: CSS2DRenderer;
    public transformControls: TransformControls;
    public intersectionHelpers: Group;
    public assetType: number;
    public transformGroup: Group;
    public groundGroup: Group;
    public currentCoordinates = [];
    public maxY: number;
    public minX: number;
    public currentInflatedCoordinates = [];
    public maxXPointFromArray;
    public minYPointFromArray;
    public states = [];
    public pinHelper: Group;
    public loadedPin: Group;
    public pinCommentPopup: CSS2DObject;
    public colorAndTexturePopup: CSS2DObject;
    public isSpiltScreenView = false;
    public withAnimation = true;
    public directionalLight: DirectionalLight;
    public freeShapeLineDetails?: FreeShapeLineDetails =
        new FreeShapeLineDetails();
    public infoParagraph: CSS2DObject;
    public helpersGroup: Group;
    public freeshapeHelper: Mesh;
    public pillarHelper: Group;
    public mylatesttap: number;
    instance: Vector2;
    public isPublished = false;
    public selectionBox: SelectionBox;
    public selectionHelper: ThreeSelectionHelper;
    public multiselectGroup: Group;

    public get freeshapeLinePoints(): Vector3[] {
        return this.freeShapeLineDetails.points;
    }
}
