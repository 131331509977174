import { DatePipe } from '@angular/common';

export class CodesRequestDTO {
    public pageIndex: string;
    public perPage: string;
    public startDate?: string;
    public endDate?: string;
    public groupId?: string;
    public role?: string;
    public isEmailSent?: string;

    constructor(
        pageIndex: number,
        perPage: number,
        startDate?: Date,
        endDate?: Date,
        groupId?: string,
        role?: number,
        isEmailSent?: boolean
    ) {
        const datePipe = new DatePipe('en-US');

        this.pageIndex = pageIndex.toString();
        this.perPage = perPage.toString();
        if (startDate !== null) {
            this.startDate = datePipe.transform(startDate, 'dd/MM/yyyy');
        }
        if (endDate !== null) {
            this.endDate = datePipe.transform(endDate, 'dd/MM/yyyy');
        }
        if (groupId != null) {
            this.groupId = groupId.toString();
        }
        if (role !== 0 && role != null) {
            this.role = role.toString();
        }
        if (isEmailSent != null) {
            this.isEmailSent = isEmailSent.toString();
        }
    }
}
