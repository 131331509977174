<nav class="navbar navbar-expand-xl navbar-light fixed-top" id="mainNav">
    <div class="container">
        <img
            (click)="scrollToElement('.masthead')"
            src="../../assets/images/presentation/furban_color.png"
            alt="Furban Logo"
        />
        <button
            class="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="material-icons">menu</span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
                <li
                    class="nav-item"
                    (click)="scrollToElement('#projects-around')"
                >
                    <a class="nav-link">{{
                        'presentation.menu.projectsAround' | translate
                    }}</a>
                </li>
<!--                
        TO-DO: Uncomment to add back subscriptions
    <li
                    class="nav-item"
                    (click)="scrollToElement('#subscriptions')"
                >
                    <a class="nav-link">{{
                        'presentation.menu.subscriptions' | translate
                    }}</a>
                </li> -->
                <li class="nav-item" (click)="scrollToElement('#roadMap')">
                    <a class="nav-link">{{
                        'presentation.menu.roadMap' | translate
                    }}</a>
                </li>
                <li class="nav-item" (click)="scrollToElement('#team')">
                    <a class="nav-link">{{
                        'presentation.menu.team' | translate
                    }}</a>
                </li>
                <li class="nav-item" (click)="scrollToElement('#contact')">
                    <a class="nav-link">{{
                        'presentation.menu.contact' | translate
                    }}</a>
                </li>
                <li
                    class="secondary-button"
                    (click)="goToAdmin()"
                    style="margin: 0px 16px"
                >
                    <a>{{ 'presentation.menu.loginAdmin' | translate }}</a>
                </li>
                <li class="primary-button" (click)="goToCitizen()">
                    <a>{{ 'presentation.menu.loginCitizen' | translate }}</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<!-- Header -->
<header class="masthead">
    <div class="container">
        <div class="intro-text">
            <img
                src="../../assets/images/presentation/furban white.svg"
                alt="Furban Logo"
                class="furban-logo"
            />
            <div class="intro-heading">
                {{ 'presentation.welcomeSubtitle' | translate }}
            </div>
            <div class="intro-lead-in">
                {{ 'presentation.description' | translate }}
            </div>

            <ng-template
                *ngTemplateOutlet="
                    nextSection;
                    context: { $implicit: '#partners' }
                "
            >
            </ng-template>
        </div>
    </div>
</header>
<!-- Partners -->
<section id="partners">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="section-heading">
                    {{ 'presentation.menu.partners' | translate }}
                </h2>
                <h3 class="section-subheading text-muted">
                    {{ 'presentation.partners.subtitle' | translate }}
                </h3>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-md-4 center-container">
                <img
                    class="partner-picture"
                    src="../../assets/images/homepage/partners_rotterdam.jpg"
                    alt=""
                />
                <h4 class="service-heading">Rotterdam</h4>
            </div>
            <div class="col-md-4 center-container">
                <img
                    class="partner-picture"
                    src="../../assets/images/homepage/partners_gouda.jpg"
                    alt=""
                />
                <h4 class="service-heading">Gouda</h4>
            </div>
            <div class="col-md-4 center-container">
                <img
                    class="partner-picture"
                    src="../../assets/images/homepage/partners_zoetermeer.jpg"
                    alt=""
                />
                <h4 class="service-heading">Zoetermeer</h4>
            </div>
        </div>
    </div>

    <ng-template
        *ngTemplateOutlet="
            nextSection;
            context: { $implicit: '#projects-around' }
        "
    >
    </ng-template>
</section>

<!-- Projects around-->
<section id="projects-around">
    <div class="text-overlay">
        <h3>{{ 'presentation.menu.projectsAround' | translate }}</h3>
        <p>
            {{ 'presentation.projectsAround.text' | translate }}
        </p>
        <button
            class="secondary-button"
            [style.margin-top.px]="40"
            (click)="goToFindProjects()"
        >
            <h6>
                {{ 'presentation.projectsAround.viewMoreButton' | translate }}
            </h6>
        </button>
    </div>

    <furban-projects-on-area
        class="projects-map"
        #projectsAroundMap
        [hideCards]="true"
    ></furban-projects-on-area>

    <div *ngIf="displayMapOverlay" class="map-overlay" (click)="hideOverlay()">
        <div class="info-message">
            <span class="material-icons">mouse</span>
            <h5 [style.font-size.px]="12">
                {{ 'presentation.projectsAround.infoMessage' | translate }}
            </h5>
        </div>
    </div>

 <!-- 
    TO-DO: replace ng-template roadMap with this one when adding back Subscriptions
    <ng-template
        *ngTemplateOutlet="
            nextSection;
            context: { $implicit: '#subscriptions' }
        "
    >
    </ng-template> -->

    <ng-template
    *ngTemplateOutlet="nextSection; context: { $implicit: '#roadMap' }"
>
</ng-template>
</section>

<!-- Subscriptions -->
<!-- 
    TO-DO: Uncomment to add back subscriptions
    <section id="subscriptions">
    <h2 class="section-heading">
        {{ 'presentation.menu.subscriptions' | translate }}
    </h2>
    <div class="subscriptions-container">
        <furban-app-card
            *ngFor="let subscription of subscriptions"
            [style.border-color]="subscription.labelColor"
            [card]="cardConfig"
            (click)="selectSubscription(subscription)"
        >
            <div card-type="header">
                <label
                    class="subscription-name"
                    [style.color]="subscription.labelColor"
                    >{{ subscription.name | translate }}</label
                >
            </div>
            <ng-container card-type="content">
                <ul>
                    <li *ngFor="let benefit of subscription.benefits">
                        {{ benefit | translate }}
                    </li>
                </ul>
            </ng-container>
            <div class="full-width" card-type="footer">
                <h5 *ngIf="subscription.onboardingPrice" class="onboarding">
                    {{ subscription.onboardingPrice | translate }}
                </h5>
                <h1 *ngIf="subscription.perMonthPrice" class="per-month">
                    {{ subscription.perMonthPrice | translate }}
                </h1>
                <a
                    class="secondary-button"
                    [href]="computeEmailString(subscription)"
                    >{{
                        'presentation.subscription.chooseButton' | translate
                    }}</a
                >
            </div>
        </furban-app-card>
    </div>

    <ng-template
        *ngTemplateOutlet="nextSection; context: { $implicit: '#roadMap' }"
    >
    </ng-template>
</section> -->

<!-- Roadmap -->
<section id="roadMap">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="section-heading">
                    {{ 'presentation.menu.roadMap' | translate }}
                </h2>
                <h3 class="section-subheading text-muted">
                    {{ 'presentation.roadMap.subtitle' | translate }}
                </h3>
            </div>
        </div>
        <model-viewer
            id="3dRoadmap"
            camera-controls
            touch-action="pan-y"
            camera-orbit="45deg 40deg 50%"
            camera-target="0m 0m 0m"
            bounds="tight"
            field-of-view="auto"
            data-js-focus-visible
            alt="3D Roadmap"
            poster="../../../assets/images/homepage/roadmap-poster.png"
            environment-image="../../../assets/images/homepage/environment-light.hdr"
            src="../../../assets/images/homepage/roadmap.glb"
            style="background-color: unset"
        >
        </model-viewer>
    </div>

    <ng-template
        *ngTemplateOutlet="nextSection; context: { $implicit: '#team' }"
    >
    </ng-template>
</section>

<!-- Team -->
<section class="bg-light" id="team">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="section-heading">
                    {{ 'presentation.team.title' | translate }}
                </h2>
                <h3 class="section-subheading text-muted">
                    {{ 'presentation.team.subtitle' | translate }}
                </h3>
            </div>
        </div>
        <div class="row flex-center">
            <div class="col-sm-6 col-lg-3">
                <div class="team-member">
                    <img
                        class="mx-auto rounded-circle"
                        src="../../assets/images/presentation/team/2.jpg"
                        alt=""
                    />
                    <h4>
                        <a href="https://www.linkedin.com/in/sandermeinders/"
                            >Sander Meinders</a
                        >
                    </h4>
                    <p class="text-muted">Co-founder & CEO</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="team-member">
                    <img
                        class="mx-auto rounded-circle"
                        src="../../assets/images/presentation/team/4.jpg"
                        alt=""
                    />
                    <h4>
                        <a
                            href="https://www.linkedin.com/in/andra-spiridon-424955181/"
                            >Andra Spiridon</a
                        >
                    </h4>
                    <p class="text-muted">Chief Product Owner</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="team-member">
                    <img
                        class="mx-auto rounded-circle"
                        src="../../assets/images/presentation/team/1.jpg"
                        alt=""
                    />
                    <h4>
                        <a
                            href="https://www.linkedin.com/in/neslihan-sahin-a037676a"
                            >Neslihan Sahin</a
                        >
                    </h4>
                    <p class="text-muted">Co-founder & Creative Director</p>
                </div>
            </div>
        </div>
        <div class="row flex-center">
            <div class="col-sm-6 col-lg-3">
                <div class="team-member">
                    <img
                        class="mx-auto rounded-circle"
                        src="../../assets/images/presentation/team/8.jpg"
                        alt=""
                    />
                    <h4>Dana Dram</h4>
                    <p class="text-muted">Team Lead</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="team-member">
                    <img
                        class="mx-auto rounded-circle"
                        src="../../assets/images/presentation/team/12.jpg"
                        alt=""
                    />
                    <h4>Karlijn van Duinen</h4>
                    <p class="text-muted">Account Manager</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="team-member">
                    <img
                        class="mx-auto rounded-circle"
                        src="../../assets/images/presentation/team/6.jpg"
                        alt=""
                    />
                    <h4>Daniel Frunza</h4>
                    <p class="text-muted">Software Architect</p>
                </div>
            </div>
        </div>
        <div class="row flex-center">
            <div class="col-sm-6 col-lg-3">
                <div class="team-member">
                    <img
                        class="mx-auto rounded-circle"
                        src="../../assets/images/presentation/team/13.png"
                        alt=""
                    />
                    <h4>Andrada Ciulei</h4>
                    <p class="text-muted">Software Developer</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="team-member">
                    <img
                        class="mx-auto rounded-circle"
                        src="../../assets/images/presentation/team/7.jpg"
                        alt=""
                    />
                    <h4>Mirel Albu</h4>
                    <p class="text-muted">Software Developer</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="team-member">
                    <img
                        class="mx-auto rounded-circle"
                        src="../../assets/images/presentation/team/9.png"
                        alt=""
                    />
                    <h4>Denis Todireanu</h4>
                    <p class="text-muted">Software Developer</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 mx-auto text-center">
                <p class="large text-muted">
                    {{ 'presentation.team.text' | translate }}
                </p>
            </div>
        </div>
    </div>

    <ng-template
        *ngTemplateOutlet="nextSection; context: { $implicit: '#contact' }"
    >
    </ng-template>
</section>
<!-- Contact -->
<section id="contact">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <a
                    class="btn btn-primary btn-xl"
                    href="mailto:sander.meinders@centric.eu"
                    >{{ 'presentation.contact.contactUs' | translate }}</a
                >
            </div>
        </div>
    </div>
</section>
<!-- Footer -->
<footer id="footer">
    <div class="container">
        <div class="row">
            <ul class="list-inline quicklinks">
                <li class="list-inline-item">
                    <span class="copyright"
                        >Copyright &copy; Furban {{ currentYear }}</span
                    >
                </li>
                <li class="list-inline-item">
                    <a (click)="openPrivacy()">{{
                        'presentation.privacyPolicy' | translate
                    }}</a>
                </li>
                <li class="list-inline-item">
                    <a (click)="openTerms()">{{
                        'presentation.terms' | translate
                    }}</a>
                </li>
            </ul>
            <ul class="list-inline social-buttons">
                <li class="list-inline-item">
                    <a href="https://twitter.com/FurbanNl?lang=nl">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-twitter"
                        >
                            <path
                                d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"
                            ></path>
                        </svg>
                    </a>
                </li>
                <li class="list-inline-item">
                    <a href="https://www.instagram.com/furbannl/">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-instagram"
                        >
                            <rect
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="5"
                                ry="5"
                            ></rect>
                            <path
                                d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"
                            ></path>
                            <line x1="17.5" y1="6.5" x2="17.5" y2="6.5"></line>
                        </svg>
                    </a>
                </li>
                <li class="list-inline-item">
                    <a
                        href="https://www.youtube.com/channel/UC-UQc6420MmSNnwDXIiACVA"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-youtube"
                        >
                            <path
                                d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"
                            ></path>
                            <polygon
                                points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"
                            ></polygon>
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</footer>

<ng-template #nextSection let-nextSectionName>
    <div class="full-width flex-row-center">
        <span
            (click)="scrollToElement(nextSectionName)"
            class="material-icons arrow pointer"
            >arrow_downward</span
        >
    </div>
</ng-template>

<furban-app-multilanguage [hideButtons]="true"></furban-app-multilanguage>
