import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import {
    BaseMapComponent,
    AreaSelectionSquare,
    Card,
    ProjectLight,
    FurbanLoaderService,
    MultilanguageService,
    routingConstants,
    MapPosition,
    MapCoordinateEnum,
    MapPinColorEnum,
    PreviousRouteService,
    ProjectType,
    ObjectPropertiesUtils,
    ScriptService,
    MapService,
    GEOCOMPCONFIG,
    XY,
    MediaService,
} from '@furban/utilities';
import { ProjectsOnAreaService } from './projects-on-area.service';
import { PageEvent } from '@angular/material/paginator';

declare let GeocompApi: any;
const mapContainer = 'mapViewer';

@Component({
    selector: 'furban-projects-on-area',
    templateUrl: './projects-on-area.component.html',
    styleUrls: ['./projects-on-area.component.scss'],
})
export class ProjectsOnAreaComponent
    extends BaseMapComponent
    implements OnInit {
    @Input() public hideCards: boolean;

    public cardConfig: Card = new Card();
    public pageIndex = 0;
    public perPage = 12;
    public projectsLength: number;
    public square: AreaSelectionSquare;
    public filterOptions: Observable<string[]>;
    public searchEnabled = false;
    public searchPositionLeft: number;
    public searchPositionWidth: number;
    public pinClickedTime: number = Date.now();
    public projectsLight: ProjectLight[] = [];
    public displayLoader: boolean;
    constructor(
        public override script: ScriptService,
        public override loaderService: FurbanLoaderService,
        public override translateService: TranslateService,
        private multilanguageService: MultilanguageService,
        public override snackBar: MatSnackBar,
        public projectsOnAreaService: ProjectsOnAreaService,
        public currencyPipe: CurrencyPipe,
        public datePipe: DatePipe,
        public mapService: MapService,
        public override formBuilder: FormBuilder,
        public router: Router,
        private mediaService: MediaService,
        public previousRouteService: PreviousRouteService
    ) {
        super(script, loaderService, translateService, snackBar, formBuilder);
    }

    override ngOnInit(): void {
        this.multilanguageService.setupLanguage();
        this.loadMapDependencies();
        this.setupCardConfigSettings();
    }

    public getMedia(mediaId): string {
        return this.mediaService.getMedia(mediaId);
    }

    public returnToHomepage(): void {
        this.router.navigateByUrl(routingConstants.website);
    }

    public setupCardConfigSettings(): void {
        this.cardConfig.cardType = 'white';
        this.cardConfig.contentAlignmentHorizontal = 'center';
        this.cardConfig.contentAlignmentVertical = 'center';
        this.cardConfig.hasHeader = false;
        this.cardConfig.height = 280;
        this.cardConfig.hoverable = true;
        this.cardConfig.backgroundPosition = 'center';
    }

    public resetMapAndPageIndex(getPreviousValues: boolean): void {
        this.GeocompApi.clearMap();
        this.projectsLight = [];
        if (getPreviousValues && this.projectsOnAreaService.previousPaginator) {
            this.pageIndex =
                this.projectsOnAreaService.previousPaginator.pageIndex;
            this.perPage = this.projectsOnAreaService.previousPaginator.perPage;
        }
    }

    public getProjectsCountOnArea(
        areaSelectionSquare: AreaSelectionSquare
    ): void {
        this.projectsOnAreaService
            .getCountProjectsOnArea(areaSelectionSquare, this.hideCards)
            .subscribe(
                {
                    next: (data) => {
                        this.projectsLength = data;
                        this.displayLoader = false;
                    },
                    error: (err) => {
                        this.displayLoader = false;

                    }
                }
            );
    }

    public setupDefaultValuesForPaginator(): void {
        this.perPage = 12;
        this.pageIndex = 0;
    }

    public storeMapPositionAndPaginator(): void {
        this.projectsOnAreaService.lastMapPosition =
            ObjectPropertiesUtils.toCamel(
                this.GeocompApi.getMapPosition()
            ) as MapPosition;
        this.projectsOnAreaService.previousPaginator = {
            perPage: this.perPage,
            pageIndex: this.pageIndex,
        };
    }

    public clearMapAndRedraw(getPreviousValues: boolean): void {
        this.setupDefaultValuesForPaginator();
        this.square = this.getSquareCoordinates();
        this.resetMapAndPageIndex(getPreviousValues);
        this.displayLoader = true;
        this.getProjectsCountOnArea(this.square);
        this.getProjectsFromSelectedArea(
            this.square,
            this.perPage,
            this.pageIndex
        );
    }

    public setupMapDefaultPosition(): void {
        // If last Map position is stored in the service, set that one as default
        if (this.projectsOnAreaService.lastMapPosition) {
            this.GeocompApi.setMapPosition(
                this.projectsOnAreaService.lastMapPosition.lat,
                this.projectsOnAreaService.lastMapPosition.lon,
                this.projectsOnAreaService.lastMapPosition.zoomLevel,
                MapCoordinateEnum.latLon
            );
        } else {
            // If no last position is setup, then it should be set to Amsterdam
            this.GeocompApi.setMapPosition(
                '52.379189',
                '4.877423',
                10,
                MapCoordinateEnum.latLon
            );
        }
    }

    public override createMap(mapName: string): void {
        GEOCOMPCONFIG.container = mapName;
        this.layers = GEOCOMPCONFIG.layers;
        this.GeocompApi = new GeocompApi(GEOCOMPCONFIG);
        this.setupMapDefaultPosition();
        this.clearMapAndRedraw(true);
        this.GeocompApi.setMapMovedCallback(() => {
            const nowTime = Date.now();
            const diff = nowTime - this.pinClickedTime;
            if (diff > 1000) {
                this.clearMapAndRedraw(false);
                this.storeMapPositionAndPaginator();
            }
        });
        this.GeocompApi.setMarkerClickedCallback((data) => {
            this.pinClickedTime = Date.now();
            this.GeocompApi.showMarkerPopup(data[0]);
            const currentPopup = document.getElementById(data[0]);
            currentPopup.addEventListener('click', this.onClickFunction);
        });
    }

    public onPaginatorChange(pageEvent: PageEvent) {
        this.perPage = pageEvent.pageSize;
        const previousPaginator = {
            perPage: pageEvent.pageSize,
            pageIndex: pageEvent.pageIndex,
        };
        this.projectsOnAreaService.previousPaginator = previousPaginator;
        this.getProjectsFromSelectedArea(
            this.square,
            pageEvent.pageSize,
            pageEvent.pageIndex
        );
    }

    public getTooltipText(project: ProjectLight): string {
        return project.projectType.projectTypeId === 0 ||
            project.projectType.projectTypeId === 1
            ? this.translateService.instant('mapLabels.open')
            : this.translateService.instant('mapLabels.closed');
    }

    public getTitleIcon(project: ProjectLight): string {
        return project.projectType.projectTypeId === 0 ||
            project.projectType.projectTypeId === 1
            ? 'lock_open'
            : 'lock';
    }

    public getProjectLabel(project: ProjectLight): string {
        switch (project.projectType.projectTypeId) {
            case 0:
                return this.translateService.instant('mapLabels.admin');
            case 1:
                return this.translateService.instant('mapLabels.initiatives');
            case 2:
                return this.translateService.instant('mapLabels.permit');
            default:
                return this.translateService.instant('mapLabels.admin');
        }
    }

    public getProjectPrice(project: ProjectLight): string {
        return project?.price
            ? this.currencyPipe.transform(
                project.price,
                'EUR',
                'symbol',
                '1.0-0'
            )
            : this.translateService.instant('mapLabels.noPrice');
    }

    public getProjectDates(project: ProjectLight, format?: string) {
        return (
            this.datePipe.transform(
                project.startDate,
                format ? format : 'dd MMMM'
            ) +
            ' - ' +
            this.datePipe.transform(
                project.endDate,
                format ? format : 'dd MMMM'
            )
        );
    }

    public goToCurentLocation() {
        this.GeocompApi.navigateToCurrentLocation();
    }

    public toggleSearch() {
        this.searchEnabled = !this.searchEnabled;
    }

    public goToProject(projId: string, event?: MouseEvent) {
        event.preventDefault();
        this.router.navigate([routingConstants.takingPart, projId]);
    }

    public onClickFunction = (e) => {
        e.preventDefault();
        this.goToProject(e.currentTarget.id, e);
    };

    public addPinForEachProject(projects: ProjectLight[]) {
        const markers = [];
        for (let i = 0; i < projects.length; i++) {
            const pin = this.getPinFromProject(projects[i]);
            markers.push(pin);
        }
        this.GeocompApi.setMarkers(markers, MapCoordinateEnum.meters, false);
    }

    public getPinColor(projectType: ProjectType) {
        return projectType.projectTypeId === 0
            ? MapPinColorEnum.purple
            : projectType.projectTypeId === 1
                ? MapPinColorEnum.orange
                : MapPinColorEnum.green;
    }

    public getPinFromProject(project: ProjectLight) {
        // REFACTOR - don't user initcap
        return {
            id: project.projectId,
            x: project.path.xCenter, // eslint-disable-line @typescript-eslint/naming-convention
            y: project.path.yCenter, // eslint-disable-line @typescript-eslint/naming-convention
            color: this.getPinColor(project.projectType), // eslint-disable-line @typescript-eslint/naming-convention
            content: this.generateHtmlContentForMarker(project),
        };
    }

    public getProjectsFromSelectedArea(
        area: AreaSelectionSquare,
        perPage: number,
        pageIndex: number
    ) {
        this.projectsOnAreaService
            .getProjectsWithinArea(area, perPage, pageIndex, this.hideCards)
            .subscribe(
                {
                    next: (data) => {
                        this.projectsLight = data;
                        this.addPinForEachProject(this.projectsLight);
                        this.displayLoader = false;
                    },
                    error: (err) => {
                        this.displayLoader = false;

                    }
                }
            );
    }

    public getSquareCoordinates(): AreaSelectionSquare {
        const mapExtent = this.GeocompApi.getMapExtent();
        const square: AreaSelectionSquare = new AreaSelectionSquare();
        square.maxX = mapExtent.BoundingBoxXy.Right;
        square.minX = mapExtent.BoundingBoxXy.Left;
        square.maxY = mapExtent.BoundingBoxXy.Top;
        square.minY = mapExtent.BoundingBoxXy.Bottom;
        return square;
    }

    public changeAddress(point: XY): void {
        this.GeocompApi.setMapPosition(
            point.x,
            point.y,
            10,
            MapCoordinateEnum.meters
        );
    }

    private generateHtmlContentForMarker(project: ProjectLight): string {
        const projectPrice = this.getProjectPrice(project);
        const projectDates = this.getProjectDates(project, 'dd MMM');
        const projectImage = "url('" + this.getMedia(project.media) + "')";
        const htmlProjectImage = `<div class="map-popup-image" style="background-image: ${projectImage}"></div>`;
        const htmlNameAndDescription = `<div class="map-popup-content">
                              <h5>${project.name}</h5>
                              <p>${project.description}</p>
                              <div>
                                <h6>${projectPrice}</h6>
                                <h6>${projectDates}</h6>
                              </div>
                              </div>`;
        const htmlContent =
            '<div id="' +
            project.projectId +
            '" class="map-popup" >' +
            htmlProjectImage +
            htmlNameAndDescription +
            '</div>';

        return htmlContent;
    }
}
