export class BubbleStyle {
    public fontSize: number;
    public bubbleColor: string;
    public borderColor: string;
    constructor(fontSize: number, bubbleColor: string, borderColor: string) {
        this.fontSize = fontSize;
        this.bubbleColor = bubbleColor;
        this.borderColor = borderColor;
    }
}
