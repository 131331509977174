<div class="important-space-between" mat-dialog-title>
    <h2 class="project-title">{{ groupName }}</h2>
    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons"> close </span>
    </button>
</div>
<mat-dialog-content>
    <furban-codes #codesComponent [isPreviewPopup]="true" [groupIdPreviewPopup]="groupId">
    </furban-codes>
</mat-dialog-content>
<div class="flex-row-spaced">
    <div class="buttons-left-container">
        <button *ngIf="!codeServiceInstance.selection.isEmpty()" class="red-button" (click)="deleteCodes()">
            <div class="flex-row-center delete-btn">
                <span class="material-icons">
                    delete_forever
                </span>
                <h6>
                    {{ 'admin.projectCodes.deleteCodes' | translate }}
                    {{ codeServiceInstance.selection.selected.length }} codes
                </h6>
            </div>
        </button>
        <button *ngIf="codesComponent.showResetButton()" class="secondary-button"
            (click)="codesComponent.resetFilters()">
            <h6>{{ 'admin.projectCodes.resetFilters' | translate }}</h6>
        </button>
    </div>
    <div class="buttons-right-container">
        <button class="secondary-button" (click)="closeDialog()">
            <h6>{{ 'generic.closeBtn' | translate }}</h6>
        </button>
        <button class="primary-button" (click)="changeGroup(groupId)">
            <h6>{{ 'admin.manageGroups.editGroup' | translate }}</h6>
        </button>
    </div>
</div>