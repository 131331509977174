<div class="remove-events" [class.full-width]="!isSplitScreenView" [class.half-width]="isSplitScreenView"
    id="htmlRenderer"></div>

<div id="pinPopupHTML" class="flex-column-center">
    <ng-container *ngIf="shouldDisplayPinPopup()">
        <furban-three-comment-popup #commentPopup [permitProjectId]="currentProject.projectId"
            [isViewOnly]="isViewerOnly" (closePopUp)="onPinPopupClose($event)"
            (savedComment)="onPinCommentSaved($event)">
        </furban-three-comment-popup>
    </ng-container>
</div>

<ng-container *ngIf="!noProjectFound">
    <ng-container *ngIf="!isSplitScreenView">
        <div id="rotate-btn">
            <button class="round-button rotate" matTooltip="{{ 'buttonsTooltips.changeView' | translate }}"
                (click)="toggleSwitchPlaces()">
                <span class="material-icons">call_received</span>
            </button>
        </div>
    </ng-container>
    <div id="permit-container">
        <div id="content"></div>

        <ng-container *ngIf="currentProject">
            <furban-tooling [class.coments-hidden]="!viewCommentsEnabled">
                <ng-container buttons-type="main">
                    <ng-container *ngIf="shouldDisplayReviewButton()">
                        <button class="primary-button" (click)="openReviewDialog()">
                            <h6>
                                {{
                                'pioneer.permitInvolvement.reviewPermit'
                                | translate
                                }}
                            </h6>
                        </button>
                    </ng-container>

                    <ng-container *ngIf="isViewerOnly">
                        <button class="primary-button" (click)="openApprovalStatusDialog()">
                            <h6>
                                {{
                                'pioneer.permitInvolvement.approvalStatusPermit'
                                | translate
                                }}
                            </h6>
                        </button>
                    </ng-container>

                    <ng-container *ngIf="!isViewerOnly">
                        <button class="round-button" matTooltip="{{
                                'buttonsTooltips.addComments' | translate
                            }}" (click)="addPinComments()">
                            <span class="material-icons" [class.active]="addingCommentsEnabled">add_location</span>
                        </button>
                    </ng-container>

                    <button class="round-button" matTooltip="{{
                            'sunAnimation.toggleSunSlider' | translate
                        }}" (click)="toggleSunSlider()">
                        <span class="material-icons">wb_sunny</span>
                    </button>

                    <ng-container *ngIf="viewCommentsEnabled; else noMenuButton">
                        <button class="round-button" matTooltip="{{
                                'buttonsTooltips.hideComments' | translate
                            }}" (click)="toggleCommentsVisibility(false)">
                            <span class="material-icons">speaker_notes_off</span>
                        </button>
                    </ng-container>
                    <ng-template #noMenuButton>
                        <button class="round-button" matTooltip="{{
                                'buttonsTooltips.showComments' | translate
                            }}" (click)="toggleCommentsVisibility(true)">
                            <span class="material-icons"> comment </span>
                        </button>
                    </ng-template>

                    <button class="round-button" matTooltip="{{ statusTooltipText }}" (click)="toggleChangeDisplay()">
                        <span class="material-icons">{{isSplitScreenView ? 'picture_in_picture_alt' : 'compare'}}</span>
                    </button>
                </ng-container>
            </furban-tooling>

            <section [hidden]="!viewCommentsEnabled" class="right-side-section">
                <p class="description" [class.show-less]="showLess" (click)="toggleShowDescription()">
                    {{ currentProject.description }}
                </p>
                <furban-permit-comments *ngIf="currentProject" class="full-width" [project]="currentProject"
                    [viewContainerRef]="viewContainerRef" [isViewOnly]="isViewerOnly" [isDialog]="false">
                </furban-permit-comments>
            </section>

            <ng-container *ngIf="shouldHaveAddPinDirective()">
                <permit-add-pin-events furbanAddPinCommentEvents [houseSceneConfig]="houseSceneConfig"
                    [permitProjectId]="currentProject.projectId" (updatePinCommentToDisplay)="
                        updatePinCommentToDisplay($event)
                    ">
                </permit-add-pin-events>
            </ng-container>

            <ng-container *ngIf="shouldHaveSelectPinDirective()">
                <permit-select-pin furbanSelectPinComments [houseSceneConfig]="houseSceneConfig"
                    [permitProjectId]="currentProject.projectId" (updatePinCommentToDisplay)="
                        updatePinCommentToDisplay($event)
                    ">
                </permit-select-pin>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="isSplitScreenView">
        <section class="section-label">
            <h6>{{'pioneer.permitGeneral.oldHouse' | translate}}</h6>
        </section>
        <section class="section-label">
            <h6>{{'pioneer.permitGeneral.newHouse' | translate}}</h6>
        </section>
    </ng-container>

    <ng-container *ngIf="shouldDisplayTimeSlider">
        <div class="time-slider">
            <section class="flex-row-start">
                <ng-container *ngIf="isSunAnimationRunning; else stopAnimation">
                    <button class="round-button button-margin" matTooltip="{{ 'sunAnimation.stop' | translate }}"
                        (click)="stopSunAnimation()">
                        <span class="material-icons">stop</span>
                    </button>
                </ng-container>

                <ng-template #stopAnimation>
                    <button class="round-button button-margin" matTooltip="{{ 'sunAnimation.start' | translate }}"
                        (click)="playSunAnimation()">
                        <span class="material-icons">play_arrow</span>
                    </button>
                </ng-template>

                <mat-slider thumbLabel tickInterval="auto" step="1" min="0" [max]="sunShiningMinutesInterval"
                    aria-label="units" [displayWith]="timeLabel.bind(this)" [value]="sunSliderValue"
                    (input)="updateTimeValue($event)">
                </mat-slider>
            </section>

            <section class="slider-legend">
                <div class="flex-row-start">
                    <span class="material-icons span-margin"> wb_sunny </span>
                    <h3>{{ sunriseTime }}</h3>
                </div>

                <div class="flex-row-start">
                    <span class="material-icons span-margin"> nights_stay </span>
                    <h3>{{ sunsetTime }}</h3>
                </div>
            </section>
        </div>
    </ng-container>

</ng-container>


<ng-container *ngIf="noProjectFound">
    <div class="flex-column-center padding-40-20 full-width">
        <img src="../../../assets/images/no_data.svg" [style.height.px]="400" />
        <h4>{{ 'user.project.noProject' | translate }}</h4>
    </div>
</ng-container>