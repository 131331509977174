<div
    class="furban-card {{ card.cardType }}"
    [class.hoverable]="card.hoverable"
    [class.disabled]="disabled"
    [style.height]="card.height + 'px'"
>
    <section class="furban-header" [style.background]="card.headerBackground">
        <ng-content select="[card-type=header]"></ng-content>
    </section>

    <section
        class="furban-content"
        [style.background]="backgroundImage"
        [style.background-position]="card.backgroundPosition"
        [style.align-items]="card.contentAlignmentVertical"
        [style.justify-content]="card.contentAlignmentHorizontal"
    >
        <ng-content select="[card-type=content]"></ng-content>
    </section>

    <section class="furban-footer">
        <ng-content select="[card-type=footer]"></ng-content>
    </section>
</div>
