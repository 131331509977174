<furban-configurable-header></furban-configurable-header>
<ng-container *ngIf="project && !project.ended">
    <div class="subheader-container" [style.background]="getImageData()">
        <h1>{{ project.name }}</h1>
    </div>
    <div class="padding-20">
        <p>
            {{ project.description }}
        </p>
        <ng-container *ngIf="!project.ended">
            <p class="description">
                {{
                    'publicEntryCards.publicDescription'
                        | translate : { projectName: project.name }
                }}
            </p>
            <div class="card-container">
                <div class="card" (click)="register()">
                    <img src="../../../assets/images/municipality.svg" />
                    <h5>
                        {{ 'publicEntryCards.register' | translate }}
                    </h5>
                </div>
                <div class="card" (click)="seeProjectDetails()">
                    <img src="../../../assets/images/neighbors.svg" />
                    <h5>
                        {{ 'publicEntryCards.seeProject' | translate }}
                    </h5>
                </div>
            </div>
        </ng-container>

        <h1 [style.margin-top.px]="40">Furban City</h1>
        <p [style.margin-top.px]="20">
            {{ 'publicEntryCards.appDescription' | translate }}
        </p>
        <div class="store-badges">
            <a
                href="https://apps.apple.com/us/app/furban-city/id1567182277?itsct=apps_box_badge&amp;itscg=30200"
                ><img
                    src="../../../assets/images/badges/ios.svg"
                    alt="Download on the App Store"
                    [style.margin-right.px]="40"
            /></a>
            <a
                href="https://play.google.com/store/apps/details?id=com.Furban.FurbanCity"
            >
                <img
                    alt="Get it on Google Play"
                    src="../../../assets/images/badges/google.png"
            /></a>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="project.ended">
    <furban-city></furban-city>
</ng-container>
