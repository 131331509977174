<ng-container *ngIf="isAdmin()">
    <section class="header-container">
        <div class="container-btn-back">
            <mat-icon class="back-btn" (click)="goBack()">arrow_back_ios</mat-icon>
            <h3 class="page-title">{{ 'admin.poll.title' | translate }}</h3>
        </div>
        <div class="buttons-container">
            <ng-container *ngIf="showCreatePoll()">
                <button class="primary-button" (click)="openCreatePollModal()">
                    <h6>{{ 'generic.create' | translate }}</h6>
                </button>
            </ng-container>
        </div>
    </section>
</ng-container>

<ng-container *ngIf="hasPolls; else hasNoPolls">
    <furban-polls [polls]="polls" [currentProject]="currentProject" (noPollEvent)="onNoPollEvent()"></furban-polls>
</ng-container>
<ng-template #hasNoPolls>
    <div class="flex-column-center" [style.margin-top.px]="40">
        <img src="../../../assets/images/no_poll.svg" [style.height.px]="240" />
        <h4 class="placeholder-text">
            {{ 'admin.poll.noPoll' | translate }}
        </h4>
    </div>
</ng-template>