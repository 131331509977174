import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectDetailsService } from '../project-shared/project-details/project-details.service';

import { environment } from 'apps/furban-client/src/environments/environment';

import { Project, routingConstants } from '@furban/utilities';

@Component({
    selector: 'furban-public-entry-page',
    templateUrl: './public-entry-page.component.html',
    styleUrls: ['./public-entry-page.component.scss'],
})
export class PublicEntryPageComponent implements OnInit {
    public project: Project;
    private projectId: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private projectDetailsService: ProjectDetailsService
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.projectId = params['id'];
            this.getProjectDetailsInformation();
        });
    }

    public register() {
        this.router.navigate([
            routingConstants.registerAsCitizen,
            this.projectId,
        ]);
    }

    public seeProjectDetails() {
        this.router.navigate([routingConstants.publicProject, this.projectId]);
    }

    public getImageData(): string {
        if (this.project && this.project.media) {
            return `linear-gradient(rgba(25, 32, 54, 0.08), rgba(25, 32, 54, 0.9)), 
                    url(${environment.apiPath}/media/public/${this.project.media}) no-repeat center`;
        } else {
            return `linear-gradient(rgba(25, 32, 54, 0.08), rgba(25, 32, 54, 0.9)), 
            url('../../images/homepage/background-login.png') no-repeat center`;
        }
    }

    private getProjectDetailsInformation() {
        this.projectDetailsService
            .getPublicProject(this.projectId)
            .subscribe((data) => {
                this.project = data;
            });
    }
}
