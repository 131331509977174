<ng-container *ngIf="isModalShown">
    <div [config]="{ show: true, backdrop: 'static' }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
        class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header buttons-container">
                    <h4 class="modal-title pull-left">
                        {{ modalTitle | translate }}
                    </h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
                        <span class="material-icons"> close </span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>{{ modalBody | translate }}</p>
                </div>
                <div class="modal-footer">
                    <ng-container *ngIf="cancelLabel">
                        <button type="button" class="secondary-button" (click)="cancel()" [style.margin-right.px]="10">
                            <h6>{{ cancelLabel | translate }}</h6>
                        </button>
                    </ng-container>

                    <button type="button" class="primary-button" (click)="accept()" [style.margin-left.px]="10">
                        <h6>
                            {{ okLabel | translate }}
                        </h6>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>