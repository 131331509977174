import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { routingConstants } from '../_constants/routing-constants';

@Component({
    selector: 'furban-homepage-header',
    templateUrl: './homepage-header.component.html',
    styleUrls: ['./homepage-header.component.scss'],
})
export class HomepageHeaderComponent {
    constructor(private router: Router) {}

    public loginWithCode(): void {
        this.router.navigateByUrl(routingConstants.app);
    }

    public loginWithCredentials(): void {
        this.router.navigateByUrl(routingConstants.admin);
    }

    public navigateToHomepage(): void {
        this.router.navigate([routingConstants.info]);
    }
}
