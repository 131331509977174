import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { TranslateService } from '@ngx-translate/core';
import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators,
} from '@angular/forms';
import { ProjectDetailsService } from '../project-details/project-details.service';

import {
    Project3dModel,
    DesignProposalService,
    FurbanUtil,
    ObjectTypeEnum,
    CustomToasterComponent,
    ObjectUtil,
    SupplierTreeNode,
    ParentTreeNode,
    CategoryObject,
    SupplierWithAllCategoryObjects,
    LeafTreeNode,
    ProjectService,
    StepperService,
    CustomToasterService,
    ToolingActionsEnum,
} from '@furban/utilities';
import { forkJoin, Subscription } from 'rxjs'; // REFACTOR
import { ToolingService } from '../../shared/tooling/tooling.service';
import { filter } from 'rxjs/operators';
@Component({
    selector: 'furban-setup-default-objects',
    templateUrl: './setup-default-objects.component.html',
    styleUrls: ['./setup-default-objects.component.scss'],
})
export class SetupDefaultObjectsComponent implements OnInit, OnDestroy {
    public projectId: string;
    public objectsPriceForm: FormGroup;
    public objectsPriceFormInput: any = [];
    public suppliersTreeControl: NestedTreeControl<SupplierTreeNode> =
        new NestedTreeControl<SupplierTreeNode>((node) => node.children);
    public suppliersDataSource: MatTreeNestedDataSource<SupplierTreeNode> =
        new MatTreeNestedDataSource();
    public checklistSelection = new SelectionModel<SupplierTreeNode>(true);
    public loaded = false;
    public hasPublishedProposals: boolean;
    private unchangeableObjectLookIds: number[] = [];
    private existingObjectsOnProject: Project3dModel[] = [];
    private toolingActionsSubscription: Subscription;

    constructor(
        public stepperService: StepperService,
        private projectService: ProjectService,
        private translateService: TranslateService,
        private customToasterService: CustomToasterService,
        private formBuilder: FormBuilder,
        private projectDetailsService: ProjectDetailsService,
        private designProposalsService: DesignProposalService,
        private toolingService: ToolingService
    ) { }

    ngOnInit() {
        this.projectId = this.stepperService.projectId;
        this.initialSetup();
        this.subscribeToToolingActionObservable();
    }

    ngOnDestroy() {
        this.toolingActionsSubscription.unsubscribe();
    }

    public hasChild = (_: number, node: SupplierTreeNode) =>
        !!node.children && node.children.length > 0;

    public descendantsAllSelected(node: SupplierTreeNode): boolean {
        return this.suppliersTreeControl
            .getDescendants(node)
            .every((child) => this.checklistSelection.isSelected(child));
    }

    public descendantsPartiallySelected(node: SupplierTreeNode): boolean {
        return (
            this.suppliersTreeControl
                .getDescendants(node)
                .some((child) => this.checklistSelection.isSelected(child)) &&
            !this.descendantsAllSelected(node)
        );
    }

    public supplierNodeSelectionToggle(node: SupplierTreeNode): void {
        if (
            this.checklistSelection.isSelected(node) &&
            this.descendantsPartiallySelected(node)
        ) {
            this.checklistSelection.select(node);
        } else {
            this.checklistSelection.toggle(node);
        }

        this.stepperService.changeProjectModifiedState(true);
        const descendants = this.suppliersTreeControl.getDescendants(node);
        if (this.checklistSelection.isSelected(node)) {
            this.checklistSelection.select(...descendants);
        } else {
            this.checklistSelection.deselect(...descendants);
        }
        this.checkAllParentsSelection(node);
    }

    public nodeToggleClick(event, node: SupplierTreeNode) {
        if (this.isNodeSelectedAndHasPublishedDPs(node)) {
            event.preventDefault();
        }
    }

    public isNodeSelectedAndHasPublishedDPs(node: SupplierTreeNode) {
        return (
            this.checklistSelection.isSelected(node) &&
            this.hasPublishedProposals
        );
    }

    public checkAllParentsSelection(node: SupplierTreeNode): void {
        this.suppliersDataSource.data.map((supplierNode) => {
            if (supplierNode.itemName === node.itemName) {
                return;
            }
            supplierNode.children.map((categoryNode) => {
                if (categoryNode.itemName === node.itemName) {
                    this.checklistSelection.select(supplierNode);
                    this.checkRootNodeSelection(supplierNode);
                    return;
                }
                categoryNode.children.map((objectNode) => {
                    if (objectNode.itemName === node.itemName) {
                        this.checklistSelection.select(supplierNode);
                        this.checklistSelection.select(categoryNode);
                        this.checkRootNodeSelection(categoryNode);
                        this.checkRootNodeSelection(supplierNode);
                        return;
                    }
                });
            });
        });
    }

    public checkRootNodeSelection(node: SupplierTreeNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.suppliersTreeControl.getDescendants(node);
        const descAllSelected = descendants.every((child) =>
            this.checklistSelection.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    public supplierLeafNodeSelectionToggle(node: SupplierTreeNode): void {
        this.checklistSelection.toggle(node);
        this.stepperService.changeProjectModifiedState(true);
        this.checkAllParentsSelection(node);
    }

    public getObjectPrice(node: SupplierTreeNode): number {
        return node.price;
    }

    public getObjectImageURL(node: SupplierTreeNode): string {
        const objectId = node.furban3DModel.objectLookId;
        const categoryId = node.furban3DModel.category.categoryId;

        const objectImage =
            categoryId === 1
                ? FurbanUtil.generateShapesAssetsURL(
                    objectId,
                    ObjectTypeEnum.square
                )
                : FurbanUtil.composeImageString(objectId);
        return 'url(' + objectImage + ')';
    }

    public getSelectedObjects(): Project3dModel[] {
        return this.checklistSelection.selected
            .filter((node: SupplierTreeNode) => !node.children)
            .map(
                (leafNode: SupplierTreeNode) =>
                    new Project3dModel(
                        leafNode.furban3DModel,
                        leafNode.price,
                        true,
                        this.projectId,
                        leafNode.itemId
                    )
            );
    }

    public saveObjectsToProject(): void {
        if (!this.checkIfShouldSave()) {
            return;
        }
        this.stepperService.changeProjectModifiedState(false);
        const selectedObjects = this.getSelectedObjects();

        this.existingObjectsOnProject.forEach(
            (project3DModel: Project3dModel) => {
                const selectedObject = this.isSelected(
                    selectedObjects,
                    project3DModel
                );

                if (selectedObject) {
                    const priceControl = this.objectsPriceForm.get(
                        selectedObject.furban3DModel.furban3DModelId
                    );
                    project3DModel.enabled = true;
                    if (priceControl) {
                        project3DModel.price = priceControl.value;
                    }
                } else {
                    project3DModel.enabled = false;
                }
            }
        );

        this.projectService
            .saveObjectsToProject(this.existingObjectsOnProject)
            .subscribe((data) => {
                this.existingObjectsOnProject = data;
                this.showToaster(
                    this.translateService.instant(
                        'admin.setupDefaultObjects.applyChangesSuccessToasterText'
                    ),
                    'success'
                );
                this.stepperService.redirectToNextStep();
            });
    }

    public checkIfShouldSave(): boolean {
        if (this.objectsPriceForm.invalid) {
            this.objectsPriceForm.markAsDirty();
            this.showToaster(
                this.translateService.instant(
                    'admin.setupDefaultObjects.applyChangesPriceError'
                ),
                'error'
            );
            return false;
        }
        return true;
    }

    public showToaster(text: string, type: string) {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            type,
            text,
            1000
        );
    }

    public getObjectTranslationsLabel(node: SupplierTreeNode): string {
        return node.furban3DModel ? node.furban3DModel.translationsLabel : '';
    }

    public isFreeShape(node: SupplierTreeNode): boolean {
        return (
            node.furban3DModel && node.furban3DModel.category.categoryId === 1
        );
    }

    public isFreeShapesOrWallsParent(node: SupplierTreeNode): boolean {
        return (
            node.itemName === 'user.projectMenu.groundMaterials' ||
            node.itemName === 'Default' ||
            node.itemName === 'user.projectMenu.walls'
        );
    }

    public isParentNodeDisabled(node: SupplierTreeNode): boolean {
        return (
            this.isNodeSelectedAndHasPublishedDPs(node) ||
            this.isFreeShapesOrWallsParent(node) ||
            !node.canBeUnchecked
        );
    }

    public isCustomObject(node: SupplierTreeNode): boolean {
        return (
            node.furban3DModel && ObjectUtil.isCustomObject(node.furban3DModel)
        );
    }

    public isWall(node: SupplierTreeNode): boolean {
        return (
            node.furban3DModel.objectLookId > 9000 &&
            node.furban3DModel.objectLookId < 9004
        );
    }

    public countDesignProposals() {
        this.designProposalsService
            .getCitizenDesignProposalCount(this.projectId)
            .subscribe((data) => {
                this.hasPublishedProposals = data > 0;
            });
    }

    public isNodeDisabled(node: SupplierTreeNode): boolean {
        return (
            this.isFreeShape(node) ||
            this.isWall(node) ||
            (this.checklistSelection.isSelected(node) &&
                !node.canBeUnchecked) ||
            this.isNodeSelectedAndHasPublishedDPs(node)
        );
    }

    private isSelected(
        selectedObjects: Project3dModel[],
        project3DModel: Project3dModel
    ) {
        return selectedObjects.find(
            (selectedObject) =>
                selectedObject.furban3DModel.furban3DModelId ===
                project3DModel.furban3DModel.furban3DModelId
        );
    }

    private initialSetup(): void {
        this.objectsSetup();
        this.countDesignProposals();
        this.stepperService.modifyCurrentStepId(false);
    }

    private objectsSetup(): void {
        const requests = [
            this.projectDetailsService.getObjectLookIdsOfObjectsSavedOnArea(
                this.projectId
            ),
            this.projectService.getDefaultObjects(this.projectId),
        ];
        forkJoin(requests).subscribe((data: any) => {
            this.unchangeableObjectLookIds = data[0];
            this.suppliersDataSource.data = this.getTreeNodeStructure(data[1]);
            this.existingObjectsSetup();
        });
    }

    private existingObjectsSetup(): void {
        const leafNodes: SupplierTreeNode[] = [];
        this.suppliersDataSource.data.map((supplierNode) => {
            supplierNode.children.map((categoryNode) => {
                leafNodes.push(...categoryNode.children);
                categoryNode.children.map((objectNode: LeafTreeNode) => {
                    if (
                        this.unchangeableObjectLookIds.includes(
                            objectNode.furban3DModel.objectLookId
                        )
                    ) {
                        supplierNode.canBeUnchecked = false;
                        categoryNode.canBeUnchecked = false;
                        objectNode.canBeUnchecked = false;
                        if (!this.isCustomObjectWithItsOwnId(objectNode)) {
                            this.objectsPriceForm
                                .get(objectNode.furban3DModel.furban3DModelId)
                                .disable({ emitEvent: false });
                        }
                    }
                });
            });
        });

        this.existingObjectsOnProject.map((existingObject) => {
            const treeNode = leafNodes.find(
                (node) =>
                    node.itemName === existingObject.furban3DModel.label &&
                    existingObject.enabled
            );
            if (!treeNode) {
                return;
            }
            this.checklistSelection.select(treeNode);
            this.checkAllParentsSelection(treeNode);
        });
    }

    private isCustomObjectWithItsOwnId(node: SupplierTreeNode): boolean {
        return node.furban3DModel.objectLookId === 8001;
    }

    private getObjectAsTreeNode(object: Project3dModel): SupplierTreeNode {
        return new LeafTreeNode(
            object.furban3DModel.label,
            object.project3DModelId,
            object.furban3DModel,
            object.price,
            object.projectId,
            object.enabled
        );
    }

    private getCategoryObjectAsTreeNode(
        categoryObject: CategoryObject
    ): SupplierTreeNode {
        const children: SupplierTreeNode[] = [];
        categoryObject.models.map((object) => {
            const objectAsTreeNode = this.getObjectAsTreeNode(object);
            if (object.project3DModelId) {
                this.existingObjectsOnProject.push(object);
            }
            children.push(objectAsTreeNode);
            if (
                !this.isFreeShape(objectAsTreeNode) &&
                !this.isCustomObject(objectAsTreeNode)
            ) {
                this.objectsPriceFormInput[
                    object.furban3DModel.furban3DModelId
                ] = new FormControl(
                    object.price,
                    Validators.compose([
                        Validators.required,
                        Validators.max(1000000),
                        Validators.min(0),
                    ])
                );
            }
        });
        return new ParentTreeNode(
            categoryObject.category.categoryName,
            categoryObject.category.categoryId,
            children
        );
    }

    private getSupplierAsTreeNode(
        supplier: SupplierWithAllCategoryObjects
    ): SupplierTreeNode {
        const children: SupplierTreeNode[] = [];
        supplier.categoryObjects.map((categoryObject: CategoryObject) =>
            children.push(this.getCategoryObjectAsTreeNode(categoryObject))
        );
        return new ParentTreeNode(
            supplier.supplier.supplierName,
            supplier.supplier.supplierId,
            children
        );
    }

    private getTreeNodeStructure(
        suppliers: SupplierWithAllCategoryObjects[]
    ): SupplierTreeNode[] {
        const supplierTreeNodes: SupplierTreeNode[] = [];
        suppliers.map((supplier) => {
            supplierTreeNodes.push(this.getSupplierAsTreeNode(supplier));
        });
        this.objectsPriceForm = this.formBuilder.group(
            this.objectsPriceFormInput
        );
        this.objectsPriceForm.valueChanges.subscribe(() =>
            this.stepperService.changeProjectModifiedState(true)
        );
        this.loaded = true;
        return supplierTreeNodes;
    }

    private subscribeToToolingActionObservable() {
        this.toolingActionsSubscription =
            this.toolingService.toolingActionsObservable
                .pipe(
                    filter(
                        (data) =>
                            data && data === ToolingActionsEnum.saveObjects
                    )
                )
                .subscribe((data) => {
                    this.saveObjectsToProject();
                });
    }
}
