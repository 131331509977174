export class UpdatePermitReview {
    public permitReviewId: string;
    public projectStatusId: number;
    public projectId: string;
    public message: string;

    public constructor(
        permitReviewId: string,
        projectStatusId: number,
        projectId: string,
        message: string
    ) {
        this.permitReviewId = permitReviewId;
        this.projectStatusId = projectStatusId;
        this.projectId = projectId;
        this.message = message;
    }
}
