<div class="main-container">
    <h3 *ngIf="client" [style.margin-left.px]="32" [style.margin-top.px]="32">
        <section class="header-container">
            <div class="page-title elipsis-on-exceeding-width-80">
                {{ 'admin.dashboard.title' | translate }}
                {{ client.clientName }}
            </div>
            <div class="buttons-container">
                <button class="primary-button" (click)="selectProjectType()">
                    <h6>{{ 'admin.dashboard.createProject' | translate }}</h6>
                </button>
            </div>
        </section>
    </h3>

    <section class="dashboard-header">
        <ul class="filter-panel">
            <li
                [class.selected]="isTabSelected(tabs.all)"
                (click)="switchAllProjects()"
            >
                <h6>{{ 'admin.dashboard.allProjects' | translate }}</h6>
            </li>

            <li
                [class.selected]="isTabSelected(tabs.mine)"
                (click)="switchMyProjects()"
            >
                <h6>{{ 'admin.dashboard.myProjects' | translate }}</h6>
            </li>
        </ul>
    </section>
    <div class="cards">
        <div *ngFor="let project of projects">
            <furban-admin-card
                [project]="project"
                [userProfile]="project.createdBy"
                [title]="project.name"
                [cardMedia]="project.media"
                (cardClickedEmitter)="goToProject(project.projectId)"
            >
            </furban-admin-card>
        </div>
    </div>
</div>
