import { PollOption } from './poll-option';
import { PollAnswer } from './poll-answer';

export class Poll {
    public pollId?: string;
    public projectId: string;
    public startDate: Date;
    public endDate: Date;
    public pollQuestion: string;
    public isActive?: boolean;
    public isEnabled?: boolean;
    public pollOptions?: PollOption[];
}
