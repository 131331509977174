import { MatDialog } from '@angular/material/dialog';

export class DialogUtil {
    public static openModalFromTemplate(
        dialog: MatDialog,
        modalComponent: any
    ): void {
        const dialogRef = dialog.open(modalComponent, {
            width: '50%',
        });
        dialogRef.disableClose = true;

        document.getElementsByTagName('html')[0].style.top = '';
        document.getElementsByTagName('html')[0].style.left = '';
        document
            .getElementsByTagName('html')[0]
            .classList.remove('cdk-global-scrollblock');
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';

        dialogRef.afterClosed().subscribe((result) => {
            document.getElementsByTagName('body')[0].style.overflow = '';
        });
    }
}
