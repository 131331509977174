import { TranslateService } from '@ngx-translate/core';
import { Project } from '../_models/project';

export class TourUtils {
    public static getMenuStepText(
        translateServiceInstance: TranslateService,
        items: string[][]
    ): string {
        let steps = '';
        items.forEach((item) => {
            steps = steps.concat(`<p><b> ${translateServiceInstance.instant(
                item[0]
            )} </b>
                    ${translateServiceInstance.instant(item[1])}</p>`);
        });
        return steps;
    }

    public static getCollabIntroductionText(
        translateServiceInstance: TranslateService,
        project: Project
    ): string {
        return `<p> ${translateServiceInstance.instant('uiTour.designMode.collaboratorDescription')} 
        <b>${project.name}</b> </p> <br> <br> <p> ${translateServiceInstance.instant('uiTour.designMode.projectDescription')} ${project.description} </p>`;

    }
}
