export const OBJECTS_WIDTHS = new Map([
    [4001, 1.312],
    [4002, 3.466],
    [4003, 1.14],
    [4004, 5.5],
    [4005, 5],
    [5001, 0.5067],
    [5002, 0.846],
    [5003, 0.8271],
    [5004, 0.801],
    [5005, 0.9],
    [5006, 0.9],
    [5007, 2],
    [5008, 2],
    [6008, 2.793],
    [6009, 5.0048],
    [6010, 2.346],
    [6011, 1.932],
    [6012, 1.929],
    [9001, 4.9152],
    [9002, 4.9152],
    [9003, 4.9152],
]);

export const ASSET_WIDTH = new Map([
    ['TF1001', 1.2],
    ['TF1002', 1.1],
    ['TF1003', 1.3],
    ['TF1004', 1.3],
    ['TF1005', 1.2],
    ['TF1006', 2],
    ['TF1007', 0.91],
]);

