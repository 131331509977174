<div class="divider-text">
    <div class="line">
        <mat-divider></mat-divider>
    </div>
    <ng-container *ngIf="text">
        <div class="text">{{ text }}</div>
        <div class="line">
            <mat-divider></mat-divider>
        </div>
    </ng-container>
</div>
