import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ControlsUtil, FurbanUtil, ModalManager, Question } from '@furban/utilities';

import { FaqService } from 'apps/furban-client/src/app/shared/faq/faq.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'furban-app-superadmin-question-add',
    templateUrl: './superadmin-question-add.component.html',
    styleUrls: ['./superadmin-question-add.component.scss'],
})
export class SuperadminQuestionAddComponent implements OnInit {
    public questionForm: FormGroup;
    public parentViewContainerRef: ViewContainerRef;
    public question: Question;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;
    public hasControlMaxLengthError = ControlsUtil.hasControlMaxLengthError;

    constructor(
        private dialogRef: MatDialogRef<SuperadminQuestionAddComponent>,
        private formBuilder: FormBuilder,
        private faqService: FaqService,
        private modal: ModalManager
    ) { }

    ngOnInit() {
        this.questionForm = this.formBuilder.group({
            questionNL: ['', [Validators.required, Validators.maxLength(512)]],
            answerNL: ['', [Validators.required, Validators.maxLength(2048)]],
            questionEN: ['', [Validators.required, Validators.maxLength(512)]],
            answerEN: ['', [Validators.required, Validators.maxLength(2048)]],
            videoUrl: ['', [Validators.maxLength(512)]],
        });
    }

    closeDialog() {
        if (this.questionForm.dirty) {
            this.modal
                .showModal(
                    this.parentViewContainerRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'errors.discardMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (res) {
                        this.dialogRef.close();
                    }
                });
        } else {
            this.dialogRef.close();
        }
    }

    saveQuestion() {
        if (this.questionForm.invalid) {
            this.markFormAsInvalid();
            return;
        }

        this.faqService
            .saveQuestion(this.questionForm.value as Question)
            .subscribe((data) => {
                this.dialogRef.close(data);
            });
    }

    markFormAsInvalid() {
        Object.keys(this.questionForm.controls).forEach((key) => {
            this.questionForm.get(key).markAsDirty();
        });
    }
}
