import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ProjectStatus } from '../_models/project-status';
import { Path } from '../_models/path';
import { Project } from '../_models/project';
import {
    ProjectStatusEnum,
    ProjectStatusNameEnum,
} from '../_enum/project-status.enum';
import { StringifiedPath } from '../_models/stringify-paths';
import { PointXY } from '../_models/point-xy';
import { PointLatLon } from '../_models/point-lat-lon';
import { SupplierWithAllCategoryObjects } from '../_models/supplier-with-all-category-objects';
import { Project3dModel } from '../_models/project-3d-model';
import { ProjectTypeEnum } from '../_enum/project-type.enum';
import { Environment } from '../_models/environment-model';
import { APP_CONFIG } from '@furban/app-config';
@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    public curentDrawing: Path = new Path();
    public hasDrawing: boolean;
    public isUserDrawing = false;
    public mapImageId: string;
    /**
     * Copy drawing is used for revert and overwrite path functions.
     * The values are set for xy and latlon coordinates if there are changes of the drawing.
     */
    public copyDrawing: Path = new Path();
    private _savedProject: Project;
    private _projectStatuses: ProjectStatus[];
    private _projectInvolvementType: string;
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor( @Inject(APP_CONFIG) private environment: Environment, private http: HttpClient) { }

    public get savedProject(): Project {
        return this._savedProject;
    }

    public set savedProject(value: Project) {
        this._savedProject = value;
    }

    public get projectStatuses(): ProjectStatus[] {
        return this._projectStatuses;
    }

    public set projectStatuses(value: ProjectStatus[]) {
        this._projectStatuses = value;
    }

    public get projectInvolvementType(): string {
        return this._projectInvolvementType;
    }

    public set projectInvolvementType(value: string) {
        this._projectInvolvementType = value;
    }

    public getStatus(stat: string): ProjectStatus {
        let foundStat = null;
        this.projectStatuses.forEach((status) => {
            if (
                stat === ProjectStatusNameEnum.published &&
                status.statusWeight === ProjectStatusEnum.published
            ) {
                foundStat = status;
            } else if (
                stat === ProjectStatusNameEnum.unpublished &&
                status.statusWeight === ProjectStatusEnum.unpublished
            ) {
                foundStat = status;
            } else if (
                stat === ProjectStatusNameEnum.archived &&
                status.statusWeight === ProjectStatusEnum.archived
            ) {
                foundStat = status;
            }
        });

        return foundStat;
    }

    public savePolygon(
        defaultMaterial: number,
        projectId: string
    ): Observable<Path> {
        const data = {
            pathId: this.curentDrawing.pathId,
            projectId: projectId,
            xYCoordinates: JSON.stringify(this.curentDrawing.xYCoordinates),
            latLonCoordinates: JSON.stringify(this.curentDrawing.xYCoordinates),
            defaultMaterial: defaultMaterial,
            xCenter: this.curentDrawing.xCenter,
            yCenter: this.curentDrawing.yCenter,
            imageId: this.curentDrawing.imageId,
        };
        return this.http
            .post(this.environment.apiPath + '/project/path', data, {
                headers: this._headers,
            })
            .pipe(
                map((result) => {
                    this.assignPathValues(result as StringifiedPath);
                    return result as Path;
                })
            );
    }

    public assignPathValues(item: StringifiedPath): void {
        this.curentDrawing.latLonCoordinates = JSON.parse(
            item.latLonCoordinates
        ) as Array<PointLatLon>;
        this.curentDrawing.xYCoordinates = JSON.parse(
            item.xYCoordinates
        ) as Array<PointXY>;
        this.curentDrawing.pathId = item.pathId;
        this.curentDrawing.projectId = item.projectId;
        this.curentDrawing.defaultMaterial = item.defaultMaterial;
        this.curentDrawing.imageId = item.imageId;
    }

    public generateParticipationDocument(projectId: string): Observable<any> {
        const headers: HttpHeaders = this._headers;

        return this.http
            .get(`${this.environment.apiPath}/word/${projectId}`, {
                headers,
                responseType: 'arraybuffer',
            })
            .pipe(map((res) => res));
    }

    public getDefaultObjects(
        projectId: string
    ): Observable<SupplierWithAllCategoryObjects[]> {
        return this.http
            .get(
                `${this.environment.apiPath}/models/category-objects/${projectId}`,
                { headers: this._headers }
            )
            .pipe(map((res) => res as SupplierWithAllCategoryObjects[]));
    }

    public saveObjectsToProject(
        objectsAssignedToProject: Project3dModel[]
    ): Observable<Project3dModel[]> {
        return this.http
            .post(`${this.environment.apiPath}/models`, objectsAssignedToProject, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Project3dModel[]));
    }

    public isPermitProject(): boolean {
        return (
            this.savedProject.projectType.projectTypeId ===
            ProjectTypeEnum.pioneerPermitProject
        );
    }

    public endProjectNow(projectId: string): Observable<void> {
        return this.http.post<void>(
            `${this.environment.apiPath}/project/endNow/${projectId}`,
            { headers: this._headers }
        );
    }

    public getProjectIdFromService() {
        let projectId: string;

        if (this.savedProject && this.savedProject.projectId) {
            projectId = this.savedProject.projectId;
        } else if (this.curentDrawing && this.curentDrawing.projectId) {
            projectId = this.curentDrawing.projectId;
        }

        return projectId;
    }

    public getObjectPriceOnArea(
        projectId: string,
        objectIds: number[]
    ): Observable<number> {
        return this.http
            .post(
                `${this.environment.apiPath}/models/allObjPrice/${projectId}`,
                objectIds,
                { headers: this._headers }
            )
            .pipe(map((res) => res as number));
    }
}
