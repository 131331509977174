<div [formGroup]="formGroup">
    <mat-form-field class="full-width" appearance="outline">
        <input
            matInput
            required
            type="text"
            formControlName="clientFormControl"
            placeholder="{{ 'auth.municipality' | translate }}"
            [matAutocomplete]="auto"
        />
        <mat-error
            *ngIf="!formGroup.get('clientFormControl')?.hasError('required')"
        >
            {{ 'superadmin.newAdmin.selectMunicipalityError' | translate }}
        </mat-error>
    </mat-form-field>
    <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="updateSelectedMunicipality($event.option.value)"
    >
        <mat-option
            *ngFor="let municipality of filteredMunicipalitiesOptions"
            [value]="municipality"
        >
            {{ municipality.clientName }}
        </mat-option>
    </mat-autocomplete>
</div>
