<furban-login-wrapper [stylingClass]="'citizen'" [infoText]="'user.auth.infoText' | translate">
    <ng-container *ngIf="state === 'changePass'">
        <div class="padding-40-64 flex-column-center">
            <h5>{{ 'user.auth.recoverText' | translate }}</h5>
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>
                    {{ 'auth.municipality' | translate }}
                </mat-label>
                <mat-select disableOptionCentering placeholder="{{ 'auth.municipality' | translate }}"
                    [(value)]="selectedClient">
                    <mat-option *ngFor="let municipality of municipalities" value="{{ municipality.phone }}">
                        {{ municipality.clientName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <h4 *ngIf="selectedClient" class="phone-number">
                {{ 'auth.contact' | translate }}: {{ selectedClient }}
            </h4>
            <a (click)="state = 'login'">{{
                'user.auth.backToLogin' | translate
                }}</a>
        </div>
    </ng-container>

    <ng-container *ngIf="state === 'login'">
        <form class="login-form" [formGroup]="loginCheckForm" (ngSubmit)="login()">
            <mat-form-field class="field-full-width" appearance="outline">
                <mat-label>
                    {{ 'user.auth.code' | translate }}
                </mat-label>
                <input matInput required type="password" placeholder="{{ 'user.auth.code' | translate }}"
                    formControlName="userCode" />
                <mat-error *ngIf="hasControlRequiredError('userCode',loginCheckForm)">
                    {{ 'user.auth.codeRequired' | translate }}
                </mat-error>
            </mat-form-field>

            <ng-container *ngIf="isBlocked">
                <section class="captcha-message">
                    <h6>{{ 'captcha.message' | translate }}</h6>
                    <img [src]="captchaData" width="200" height="50" />
                    <button mat-icon-button type="button" (click)="showCaptcha($event)">
                        <span class="material-icons">replay</span>
                    </button>
                </section>
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{ 'captcha.code' | translate }}
                    </mat-label>
                    <input matInput required type="text" placeholder="{{ 'captcha.code' | translate }}"
                        formControlName="captchaAnswer" />
                </mat-form-field>
            </ng-container>
            
            <ng-container *ngIf="!isApp()">
                <mat-checkbox formControlName="remberMeFormControl" class="remember-checkbox">
                    {{ 'auth.rememberMe' | translate }}
                </mat-checkbox>
            </ng-container>

            <button class="primary-button full-width" type="submit">
                <h6>{{ 'auth.loginLabel' | translate }}</h6>
            </button>

            <ng-container *ngIf="isApp()">
                <button class="secondary-button full-width" [style.margin-bottom.px]="12"
                    (click)="navigateToLandscape()">
                    <h6>{{ 'auth.back' | translate }}</h6>
                </button>
            </ng-container>

            <a (click)="forgotCode()">{{ 'auth.lostLoginCode' | translate }}</a>
        </form>
    </ng-container>
    <ng-container *ngIf="state === 'changeName'">
        <furban-change-name [profile]="profile"></furban-change-name>
    </ng-container>
</furban-login-wrapper>