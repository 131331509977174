import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { FurbanUtil } from '../helpers/furbanUtil';
import { AuthService } from '../_services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class NotLoggedInGuard implements CanActivate {
    public notLoggedIn: boolean;
    public isApp = FurbanUtil.isApp;

    constructor(private authService: AuthService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> {
        const role = route.data['role'] as string;


        if (this.isApp()) {
            this.authService.redirectToAppLandingPage();
            return false;
        }

        if (this.authService.user) {
            this.authService.redirectByRole();
            return false;
        }

        if (this.authService.allInfoInPlace(role, state)) {
            return true;
        }
        
        return new Observable((observer) => {
            this.authService.getUser().subscribe(
                (data) => {
                    if (data) {
                        observer.next(false);
                        this.authService.redirectByRole();
                    } else {
                        observer.next(true);
                    }
                },
                (error) => {
                    if (
                        error.status === 401 ||
                        (error.status === 500 &&
                            error.message.indexOf(
                                'Invalid remember-me token'
                            ) > -1)
                    ) {
                        observer.next(true);
                    }
                }
            );
        });

    }
}
