<div id="confetti-container">
    <div class="important-space-between" mat-dialog-title>
        <h2>{{ 'winner.congratulations' | translate }}</h2>
        <button class="ghost-button" (click)="closeDialog()">
            <span class="material-icons"> close </span>
        </button>
    </div>

    <mat-dialog-content>
        <div class="flex-column-center">
            <img
                src="../../../assets/images/winner-green.svg"
                [style.height.px]="240"
            />
            <p>{{ 'winner.congratulationsMessage' | translate }}</p>
            <button class="primary-button" (click)="closeDialog()">
                <h6>{{ 'generic.okBtn' | translate }}</h6>
            </button>
        </div>
    </mat-dialog-content>
</div>
