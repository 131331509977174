<ng-container *ngIf="shouldShowMenu">
    <section class="menu">
        <nav class="tabs">
            <img class="menu-item pointer"
                 alt="ProjPic"
                 src="/assets/images/homepage/looking_permit.png"
                 matTooltip="{{ 'pioneer.permitProcess.createHouse' | translate }}"
                 [class.active]="checkSetupTabIsActive()"
                 [style.padding.px]="4"
                 (click)="navigateToSetupTab()" />
            <img *ngFor="let object of objects"
                 class="menu-item pointer"
                 alt="ProjPic"
                 matTooltip="{{ object.category.categoryName | translate }}"
                 [matTooltipDisabled]="isAppOrMobile()"
                 [class.active]="checkForActiveTab(object)"
                 [src]="object.models[0].imagePath"
                 [style.padding.px]="4"
                 (click)="navigateToTab(object)" />
        </nav>

        <section *ngIf="activeItem; else customTabs"
                 class="items-container menu-grid">
            <div *ngFor="let obj of activeItem.models"
                 class="menu-item-container pointer"
                 [class.enabled]="isAssetSelected(obj)"
                 (click)="onObjectClick(obj)">
                <img class="menu-item-image full-width"
                     alt="ProjPic"
                     [src]="obj.imagePath"
                     [style.padding.px]="8" />
            </div>
        </section>

        <ng-template #customTabs>
            <ng-container [ngSwitch]="activeTab">
                <ng-container *ngSwitchCase="tabEnum.setupHouse">
                    <div class="items-container"
                         [style.padding.px]="20">
                        <ng-content></ng-content>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="tabEnum.tools">
                    <div class="items-container tools-container">
                        <h6 class="category-title"
                            [style.margin-bottom.px]="20">
                            {{ 'user.projectMenu.toolsTitle' | translate }}
                        </h6>
                    </div>
                </ng-container>
            </ng-container>
        </ng-template>
    </section>
</ng-container>