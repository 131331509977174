<div class="main-container padding-40-32">
    <h3
        *ngIf="client"
        class="page-title stretch-content flex-row-start"
        [style.margin-bottom.px]="32"
    >
        {{ 'admin.dashboard.title' | translate }} {{ client.clientName }}
    </h3>
    <div class="parent">
        <furban-app-card (click)="createInitiativeProject()"
                  id="initiative-card-step"
                  class="pioneer-card"
                  [style.height]="cardConfig.height + 'px'"
                  [card]="cardConfig"
                  [backgroundImage]="'../assets/images/pioneer/dashboard/create-initiative.svg'">
            <div class="tab-card-content"
                 card-type="content"
                 [style.margin-left.px]="24">
                <h3>{{'pioneer.dashboard.cards.create'|translate}}</h3>
                <h1>{{'pioneer.dashboard.cards.initiative'|translate}}</h1>
                <h3>{{'pioneer.dashboard.cards.initiativeProject'|translate}}</h3>
            </div>
        </furban-app-card> 

        <furban-app-card
            id="permit-card-step"
            class="pioneer-card"
            [disabled]="false"
            [class.disabled]="false"
            [style.height]="cardConfig.height + 'px'"
            [card]="cardConfig"
            [backgroundImage]="
                '../assets/images/pioneer/dashboard/create-permit.svg'
            "
            (click)="createPermitProject()"
        >
            <div
                class="tab-card-content"
                card-type="content"
                [style.margin-left.px]="24"
            >
                <h3>{{ 'pioneer.dashboard.cards.create' | translate }}</h3>
                <h1>{{ 'pioneer.dashboard.cards.permit' | translate }}</h1>
                <h3>
                    {{ 'pioneer.dashboard.cards.permitProject' | translate }}
                </h3>
            </div>
        </furban-app-card>

        <furban-app-card
            id="projects-card-step"
            class="pioneer-card"
            [card]="whiteCardConfig"
            [backgroundImage]="'../../../assets/images/location.jpg'"
            (click)="goToProjects()"
        >
            <div card-type="footer">
                {{ 'pioneer.dashboard.cards.projectsCard' | translate }}
            </div>
        </furban-app-card>
    </div>
</div>
