import { AbstractControl } from '@angular/forms';
import { TOP_LEVEL_DOMAIN } from '../_constants/domains.constants';

export function validateTLDEmail(control: AbstractControl) {
    const text: string = control.value;
    if (text === '' || control.pristine) {
        return null;
    }

    const domains: string = text.split('@')[1];

    if (!domains) {
        return null;
    }
    const domainList: string[] = domains.split('.');
    const mainDomain: string = domainList[domainList.length - 1];

    if (mainDomain.toLowerCase() !== TOP_LEVEL_DOMAIN) {
        return { notExpectedTopLevelDomain: true };
    }

    return null;
}
