import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'furban-reconnect-modal',
    templateUrl: './reconnect-modal.component.html',
    styleUrls: ['./reconnect-modal.component.scss'],
})
export class ReconnectModalComponent {
    constructor(private dialogRef: MatDialogRef<ReconnectModalComponent>) {}

    reconnect(): void {
        this.dialogRef.close(true);
    }
}
