import { LoginForm } from '../prototypes/loginform';

export class CaptchaUtil {
    /**
     * call this when the IP address is blocked and have to display a new captcha
     * @param event optional, sent from the view
     */
    public static loadCaptcha(loginForm: LoginForm) {
        loginForm.isBlocked = true;
        loginForm.loginCheckForm.get('captchaAnswer').reset();
        loginForm.authService.getCaptcha(loginForm.captchaId).subscribe(
            (tuple) => {
                loginForm.captchaId = tuple.first;
                loginForm.captchaData = `data:image/jpg;base64,${tuple.second}`;
            },
            (error) => {
                console.error(error);
            }
        );
    }
}
