import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User, FurbanUtil, Environment } from '@furban/utilities';
import { APP_CONFIG } from '@furban/app-config';

@Injectable()
export class AdminAuthRecoverService {
    private _options = {
        headers: new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
        ),
    };

    private _headers: HttpHeaders = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(@Inject(APP_CONFIG) private environment: Environment, private http: HttpClient, private router: Router) { }
    /**
     * changes the password for admin from recover
     * @param password
     * @param password2
     */
    changeAdminPass(key: string, password: string): Observable<User> {
        const REQUEST_DATA = {
            key: key,
            password: password,
        };

        return this.http
            .post(
                `${this.environment.apiPath}/user/admin/recover/pass`,
                FurbanUtil.encodeObjectURIComponents(REQUEST_DATA),
                this._options
            )
            .pipe(map((user) => <User>user));
    }
}
