import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    AuthService,
    FurbanUtil,
    ObjectCategoryStatistics,
    Project,
    ProjectStorageService,
    StepperService,
} from '@furban/utilities';
import { Subscription, switchMap, of } from 'rxjs';
import { StatisticsService } from '../../municipality/user/statistics/statistics.service';
import { ProjectDetailsService } from '../project-details/project-details.service';

@Component({
    selector: 'furban-user-charts',
    templateUrl: './user-charts.component.html',
    styleUrls: ['./user-charts.component.scss'],
})
export class UserChartsComponent implements OnInit, OnDestroy {
    public curentProject: Project;
    public curentProjectId: string;
    public totalUsers: number;
    public activeUsers: number;
    public designProposals: number;
    public likes: number;
    public comments: number;
    public objectsPerCategoryCount: ObjectCategoryStatistics[];
    public objectsTotalCount: number;
    public isDatePassed = FurbanUtil.isDatePassed;
    private routeSub: Subscription;

    public get isCitizenOrExpertOrAdmin(): boolean {
        return this.authService.isCitizenOrExpert || this.authService.isAdmin();
    }

    public get isAdminOrPioneer(): boolean {
        return this.authService.isPioneer() || this.authService.isAdmin();
    }

    public get isCitizenOrExpert(): boolean {
        return this.authService.isCitizenOrExpert;
    }

    constructor(
        private projectDetailsService: ProjectDetailsService,
        private statisticsService: StatisticsService,
        private projectStorageService: ProjectStorageService,
        private authService: AuthService,
        private stepperService: StepperService,
        private route: ActivatedRoute
    ) {
        this.subscribeToProjectChangedForUsers(projectStorageService);
    }

    ngOnInit(): void {
        this.setCurrentProject();
        this.loadDataForProject();
    }

    public ngOnDestroy(): void {
        this.routeSub?.unsubscribe();
    }


    private setCurrentProject(): void {
        if (this.isAdminOrPioneer) {
            this.curentProject = this.stepperService.project as Project;
        } else {
            this.curentProject = this.projectStorageService.curentProject as Project;
        }

        this.curentProjectId = this.curentProject?.projectId;

        if (!this.curentProject) {
            this.subscribeToRoute();
        }
    }


    private subscribeToRoute(): void {
        this.routeSub = this.route.params
            .pipe(
                switchMap(params => {
                    this.curentProjectId = params['projectId'];
                    return this.curentProjectId ? this.projectDetailsService.getProject(this.curentProjectId) : of(null);
                })
            )
            .subscribe((data) => {
                if (!data) {
                    return;
                }

                this.curentProject = data as Project;

                if (this.isCitizenOrExpert) {
                    this.projectStorageService.changeProject(this.curentProject);
                }
            });
    }

    private subscribeToProjectChangedForUsers(projectStorageService: ProjectStorageService): void {
        if (this.isCitizenOrExpertOrAdmin) {
            projectStorageService.projectChanged.subscribe((item) => {
                if (item.projectId !== this.curentProject.projectId) {
                    this.curentProjectId = item.projectId;
                    this.curentProject = item;
                    this.loadDataForProject();
                }
            });
        }
    }

    private loadDataForProject(): void {
        this.getDataForCharts();
        if (this.authService.isAdmin()) {
            this.getDataForAdminCharts();
        }
    }

    private getDataForCharts(): void {
        this.statisticsService
            .getDataForCharts(this.curentProjectId)
            .subscribe((responseList) => {
                this.totalUsers = responseList[0];
                this.activeUsers = responseList[1];
                this.designProposals = responseList[2];
                this.likes = responseList[3];
                this.comments = responseList[4];
            });
    }

    private getDataForAdminCharts(): void {
        this.statisticsService
            .getDataForAdminCharts(this.curentProjectId)
            .subscribe((responseList) => {
                this.objectsPerCategoryCount = responseList[0];
                this.objectsTotalCount = responseList[1];
            });
    }
}
