import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { StepperService } from '@furban/utilities';
import { Subscription } from 'rxjs';

@Component({
    selector: 'furban-user-statistics',
    templateUrl: './user-statistics.component.html',
    styleUrls: ['./user-statistics.component.scss'],
})
export class UserStatisticsComponent implements OnInit, OnDestroy {
    public curentProjectId: string;
    private routeSub: Subscription;
    constructor(
        private stepperService: StepperService,
        private location: Location,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.setCurrentProject();
    }

    public ngOnDestroy(): void {
        this.routeSub?.unsubscribe();
    }

    public goBack(): void {
        this.location.back();
    }

    private setCurrentProject(): void {
        if (
            this.stepperService.project &&
            this.stepperService.project.projectId
        ) {
            this.curentProjectId = this.stepperService.project.projectId;
            return;
        }
        this.routeSub = this.route.params.subscribe((params) => {
            this.curentProjectId = params['projectId'];
        });
    }
}
