<div
    *ngIf="project"
    class="scrollable-container padding-0-32"
    infiniteScroll
    [infiniteScrollThrottle]="300"
    (scrolled)="onScrollDown()"
    [scrollWindow]="false"
>
    <furban-app-design-proposals
        #designProposals
        [project]="project"
        [viewContainerRef]="viewContainerRef"
        [disabledActions]="project.ended"
    >
    </furban-app-design-proposals>
</div>
