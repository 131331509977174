import {
    Component,
    OnInit,
    Inject,
    ViewContainerRef,
    NgZone,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PublishService } from './publish.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {
    ControlsUtil,
    CustomToasterComponent,
    CustomToasterService,
    DesignProposal,
    FurbanUtil,
    MediaService,
    ModalManager,
    Project,
    routingConstants,
} from '@furban/utilities';

@Component({
    selector: 'furban-publish-popup',
    templateUrl: './publish-popup.component.html',
    styleUrls: ['./publish-popup.component.scss'],
})
export class PublishPopupComponent implements OnInit {
    public project: Project;
    public publishForm: FormGroup;
    public noWhiteSpacesValidator = FurbanUtil.noWhiteSpacesValidator;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public parentViewContainerRef: ViewContainerRef;
    public availableBudget: number;
    private modified = false;
    public hasControlMaxLengthError = ControlsUtil.hasControlMaxLengthError;
    public hasControlIsHtmlError = ControlsUtil.hasControlIsHtmlError;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<PublishPopupComponent>,
        private formBuilder: FormBuilder,
        public publishService: PublishService,
        private customToasterService: CustomToasterService,
        private modal: ModalManager,
        private translateService: TranslateService,
        public router: Router,
        private mediaService: MediaService,
        private zone: NgZone
    ) {
        this.project = data.project;
        this.availableBudget = data.availableBudget;
    }

    ngOnInit(): void {
        this.publishForm = this.formBuilder.group({
            designDescription: [
                '',
                [Validators.maxLength(140), this.htmlValidator],
            ],
        });
    }

    public getMedia(mediaId): string {
        return this.mediaService.getMedia(mediaId);
    }

    publishDesign() {
        if (this.publishForm.invalid) {
            this.publishForm.get('designDescription').markAsDirty();
            this.publishForm.get('designDescription').markAsTouched();
            return;
        }

        if (this.publishForm.get('designDescription').value) {
            this.publishService.designProposal.description =
                this.publishForm.get('designDescription').value;
        }

        this.publishService
            .publishDesign(this.project, this.availableBudget)
            .subscribe(
                (data) => {
                    this.dialogRef.close(1);
                    this.router.navigate([routingConstants.initiatives]);
                    this.publishService.designProposal = new DesignProposal();
                    this.zone.run(() => {
                        this.customToasterService.openCustomToaster(
                            CustomToasterComponent,
                            'check_circle',
                            'success',
                            'user.publish.publishedSuccesfully',
                            1000
                        );
                    });
                }
            );
    }

    public closeDialog() {
        if (this.modified || this.publishForm.touched) {
            this.modal
                .showModal(
                    this.parentViewContainerRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'formChangesDialog.message',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res: any) => {
                    if (res) {
                        this.dialogRef.close(1);
                    }
                });
        } else {
            this.dialogRef.close(1);
        }
    }

    public takeSS() {
        this.modified = true;
        this.dialogRef.close(0);
    }

    public changeToScreenShot() {
        this.dialogRef.close(0);
    }
}
