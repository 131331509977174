import { Like } from './like';
import { Comment } from './comment';
import { DesignProposalLight } from './design-proposal-light';
import { UserProfile } from '@furban/utilities';
import { DesignProposalStatus } from './design-proposal-status';
import { DesignProposalStatusEnum } from '../_enum/design-proposal-status.enum';

export class DesignProposal extends DesignProposalLight {
    public likes: Like[];
    public comments: Comment[];
    public winnerMessage?: string;
    public isAdminDesign?: boolean;
    public reactionPoints?: number;

    public static createMunicipalityDesignProposal(
        userProfile: UserProfile,
        projectId: string
    ): DesignProposal {
        const newMunicipalityDesignProposal =
            this.createDPWithRandomName(projectId);
        newMunicipalityDesignProposal.userProfile = userProfile;
        newMunicipalityDesignProposal.isAdminDesign = true;
        return newMunicipalityDesignProposal;
    }

    public static createCollaborativeDesignProposal(
        projectId: string
    ): DesignProposal {
        const liveDp = this.createDPWithRandomName(projectId);
        liveDp.designProposalStatus = new DesignProposalStatus(
            DesignProposalStatusEnum.created
        );
        return liveDp;
    }

    private static createDPWithRandomName(projectId): DesignProposal {
        const newCollaborativeDesignProposal = new DesignProposal();
        newCollaborativeDesignProposal.title = Math.random()
            .toString(36)
            .substring(7);
        newCollaborativeDesignProposal.projectId = projectId;
        newCollaborativeDesignProposal.isActive = true;
        return newCollaborativeDesignProposal;
    }
}
