import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Navigation, Router } from '@angular/router';

import {
    AuthService,
    DesignProposal,
    DesignProposalService,
    EmbedService,
    MapService,
    PathObject,
    PathObjectsService,
    Project,
    routingConstants,
} from '@furban/utilities';
import { combineLatest, forkJoin, Observable, Subscription } from 'rxjs';
import { ProjectDetailsService } from '../project-shared/project-details/project-details.service';

@Component({
    selector: 'furban-public-embeded-design',
    templateUrl: './public-embeded-design.component.html',
    styleUrls: ['./public-embeded-design.component.scss'],
})
export class PublicEmbededDesignComponent implements OnInit, OnDestroy {
    private isViewerOnly: boolean;
    private subscription: Subscription;
    private dpId: string;
    private designProposal: DesignProposal;
    private navigation: Navigation;
    public userObjects: PathObject[];
    public project: Project;
    public threeIsPrepared = false;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private dpService: DesignProposalService,
        private pathObjectsService: PathObjectsService,
        private mapService: MapService,
        private embedService: EmbedService,
        private projectService: ProjectDetailsService
    ) {
        this.navigation = this.router.getCurrentNavigation();
    }

    ngOnInit(): void {
        this.subscribeToRoute();
        this.embedService.isEmbededComponentEmitter.emit(true);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public isLoggedInOrIsNotViewOnly(): boolean {
        return this.authService.isLoggedIn() || !this.isViewerOnly;
    }

    public goBack(): void {
        this.router.navigate([
            routingConstants.publicPageProject,
            this.project.projectId,
        ]);
    }

    public get showBack(): boolean {
        return this.navigation.extras?.state?.['showBack'];
    }

    private subscribeToRoute(): void {
        this.subscription = combineLatest([
            this.route.params,
            this.route.data,
        ]).subscribe((data: any) => {
            this.dpId = data[0]['id'];
            this.isViewerOnly = data[1]['isViewerOnly'] as boolean;
            this.getDesignProposalDetails();
        });
    }

    private getDesignProposalDetails(): void {
        this.dpService.getDetails(this.dpId).subscribe((data) => {
            this.designProposal = data;
            const requests = [
                this.mapService.getPolygon(this.designProposal.projectId),
                this.pathObjectsService.getPathDefaultObjects(
                    this.designProposal.projectId
                ),
            ];

            this.addDesignSpecificObjectsRequest(requests);
            this.addProjectRequest(requests);
            this.getProjectDetailsAndObjects(requests);
        });
    }

    private addDesignSpecificObjectsRequest(requests): void {
        if (this.designProposal.isAdminDesign) {
            requests.push(
                this.pathObjectsService.getPathObjectsForDefaultDP(
                    this.designProposal.projectId,
                    this.designProposal.designProposalId
                )
            );
        } else {
            requests.push(
                this.pathObjectsService.getPathObjects(
                    this.designProposal.projectId,
                    this.designProposal.userProfile.userProfileId
                )
            );
        }
    }

    private addProjectRequest(
        requests: Observable<any | PathObject[]>[]
    ): void {
        if (this.authService.isLoggedIn()) {
            requests.push(
                this.projectService.getProject(this.designProposal.projectId)
            );
        } else {
            requests.push(
                this.projectService.getPublicProject(
                    this.designProposal.projectId
                )
            );
        }
    }

    private getProjectDetailsAndObjects(requests): void {
        forkJoin(requests).subscribe((result) => {
            this.userObjects = result[1] ? (result[1] as PathObject[]) : [];
            this.project = result[3] as Project;
            if (result[2] != null) {
                this.userObjects = this.userObjects.concat(
                    result[2] as PathObject[]
                );
            }
            this.threeIsPrepared = true;
        });
    }
}
