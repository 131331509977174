import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomToasterService } from './custom-toaster.service';
import { CustomToasterComponent } from '../custom-toaster/custom-toaster.component';
import { FurbanUtil } from '../helpers/furbanUtil';
import { Clipboard } from '@capacitor/clipboard';

@Injectable({
    providedIn: 'root',
})
export class ClipboardService {
    private isApp = FurbanUtil.isApp;

    public writeToClipboard(clipboadTextToCopy: string): Promise<void> {
        if (this.isApp()) {
            return Clipboard.write({ string: clipboadTextToCopy });
        }

        return navigator.clipboard.writeText(clipboadTextToCopy);
    }

    public showToaster(
        text: string,
        fail: boolean,
        customToasterService: CustomToasterService
    ): void {
        customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            fail ? 'error' : 'success',
            text,
            500
        );
    }

    public copyToClipboard(
        clipboadTextToCopy: string,
        translateService: TranslateService,
        customToasterService: CustomToasterService
    ): void {
        this.writeToClipboard(clipboadTextToCopy)
            .then(() => {
                this.showToaster(
                    translateService.instant('generic.succesCopyToClipboard'),
                    false,
                    customToasterService
                );
            })
            .catch(() => {
                this.showToaster(
                    translateService.instant('generic.failCopyToClipboard'),
                    true,
                    customToasterService
                );
            });
    }
}
