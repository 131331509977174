import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User, FurbanUtil, Environment } from '@furban/utilities';
import { APP_CONFIG } from '@furban/app-config';

@Injectable()
export class AdminAuthService {
    private _options = {
        headers: new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
        ),
    };

    constructor(@Inject(APP_CONFIG) private environment: Environment, private http: HttpClient) { }
    /**
     * changes a password
     * @param oldpassword the current user password
     * @param password
     * @param password2
     */
    changeUserPass(
        oldpassword: string,
        password: string,
        password2: string
    ): Observable<User> {
        const REQUEST_DATA = {
            oldpassword: oldpassword,
            password: password,
            password2: password2,
        };

        return this.http
            .post(
                `${this.environment.apiPath}/user/admin`,
                FurbanUtil.encodeObjectURIComponents(REQUEST_DATA),
                this._options
            )
            .pipe(map((user) => <User>user));
    }

    recoverAdminPass(email: string): Observable<any> {
        const REQUEST_DATA = {
            email: email,
        };

        return this.http
            .post(
                `${this.environment.apiPath}/user/admin/recover`,
                FurbanUtil.encodeObjectURIComponents(REQUEST_DATA),
                this._options
            )
            .pipe(map((data) => data));
    }
}
