import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService, DesignProposal, Project } from '@furban/utilities';

import { environment } from '../../../environments/environment';

@Injectable()
export class PublishService {
    public designProposal: DesignProposal = new DesignProposal();
    private httpClientWithoutError: HttpClient;
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(
        private http: HttpClient,
        private handler: HttpBackend,
        private auth: AuthService
    ) {
        this.httpClientWithoutError = new HttpClient(handler);
    }

    publishDesign(project: Project, price: number): Observable<DesignProposal> {
        const REQUEST_DATA = {
            description: this.designProposal.description,
            title: project.name,
            projectId: project.projectId,
            media: this.designProposal.mediaId,
            userProfile: this.auth.userProfile,
            isActive: true,
            price: project.price - price,
        };
        return this.http
            .post(environment.apiPath + '/design-proposal', REQUEST_DATA, {
                headers: this._headers,
            })
            .pipe(map((result) => this.createNewDP(result)));
    }

    restartDesign(designProposal: DesignProposal): Observable<DesignProposal> {
        designProposal.isActive = false;
        return this.http
            .post(environment.apiPath + '/design-proposal', designProposal, {
                headers: this._headers,
            })
            .pipe(map((result) => this.createNewDP(result)));
    }

    getPublishedDesignByProject(projectId: string) {
        return this.httpClientWithoutError
            .get(
                `${environment.apiPath}/design-proposal/project/${projectId}`,
                { headers: this._headers }
            )
            .pipe(
                map((result) => {
                    const dp = result as DesignProposal;
                    if (dp) {
                        this.designProposal = dp;
                    } else {
                        this.designProposal = new DesignProposal();
                    }
                    return dp;
                })
            );
    }

    private createNewDP(dp: any): DesignProposal {
        this.designProposal = new DesignProposal();
        return dp as DesignProposal;
    }
}
