import { PointXY } from './point-xy';
import { PointLatLon } from './point-lat-lon';

export class HouseArea {
    public pathId?: number;
    public xYCoordinates: Array<PointXY> | string;
    public latLonCoordinates: Array<PointLatLon> | string;
    public defaultMaterial?: number;
    public projectId?: string;
    public xCenter: number;
    public yCenter: number;
}
