import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermitUtils } from '../helpers/permit.utils';
import { MaterialTypeEnum } from '../_enum/material-type.enum';
import { Environment } from '../_models/environment-model';
import { House } from '../_models/house';
import { HouseArea } from '../_models/house-area';
import { HouseAsset } from '../_models/house-asset';
import { HouseMaterial } from '../_models/house-material';
import { Path } from '../_models/path';
import { PermitAsset } from '../_models/permit-asset';
import { PointLatLon } from '../_models/point-lat-lon';
import { PointXY } from '../_models/point-xy';
import { SilenceHttpParams } from '../_models/silence-http-param';
import { APP_CONFIG } from '@furban/app-config';

@Injectable({
    providedIn: 'root',
})
export class HouseService {
    public isUserDrawing = false;
    public houseSaved: boolean;
    public roofMaterials: HouseMaterial[];
    public houseMaterials: HouseMaterial[];
    public copyHouse: House = new House();
    public addObjectSubject = new Subject<PermitAsset>();
    public addObjectsObservable: Observable<PermitAsset> =
        this.addObjectSubject.asObservable();
    public cancelSelectionSubject = new Subject<boolean>();
    public cancelSelectionObservable$: Observable<boolean> =
        this.cancelSelectionSubject.asObservable();

    public houseAreaDrawing: Path;

    private _headersNoCache = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .append(
            'Cache-Control',
            'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
        )
        .append('Pragma', 'no-cache')
        .append('Vary', '*')
        .append('Expires', '0');
    constructor(@Inject(APP_CONFIG) private environment: Environment, private http: HttpClient) { }

    public resetHouse(house: House): Observable<House> {
        const httpOptions = {
            headers: this._headersNoCache,
            params: new SilenceHttpParams(true, false, false),
        };
        return this.http
            .post(this.environment.apiPath + '/house/reset', house, httpOptions)
            .pipe(
                map((result) => {
                    return this.assignPathValuesForHouse(result as House);
                })
            );
    }

    public deleteAllHousesForProjectId(projectId: string):Observable<void>{
        const httpOptions = {
            headers: this._headersNoCache,
            params: new SilenceHttpParams(true, false, false),
        };
        return this.http.delete<void>(
            `${this.environment.apiPath}/house/deleteAll/${projectId}`,
            httpOptions
        );
    }

    public saveHouse(house: House): Observable<House> {
        const httpOptions = {
            headers: this._headersNoCache,
            params: new SilenceHttpParams(true, false, false),
        };
        return this.http
            .post(this.environment.apiPath + '/house', house, httpOptions)
            .pipe(
                map((result) => {
                    return this.assignPathValuesForHouse(result as House);
                })
            );
    }

    public saveMultipleHouses(houses: House[]): Observable<House[]> {
        const httpOptions = {
            headers: this._headersNoCache,
            params: new SilenceHttpParams(true, false, false),
        };
        return this.http
            .post(
                this.environment.apiPath + '/house/mutliple-houses',
                houses,
                httpOptions
            )
            .pipe(
                map((result: House[]) => {
                    this.assignPathValuesForHouseArray(result);
                    return result;
                })
            );
    }

    public finishHouseDesign(house: House): Observable<House> {
        return this.http
            .post(this.environment.apiPath + '/house/clone', house, {
                headers: this._headersNoCache,
            })
            .pipe(
                map((result) => {
                    return this.assignPathValuesForHouse(result as House);
                })
            );
    }

    public getHousePolygon(projectId: string): Observable<House> {
        return this.http
            .get(`${this.environment.apiPath}/house/actual/${projectId}`, {
                headers: this._headersNoCache,
                params: new SilenceHttpParams(true, true, false),
            })
            .pipe(
                map((result) => {
                    if (!result) {
                        return;
                    }
                    
                    return this.assignPathValuesForHouse(result as House);
                })
            );
    }

    public getNeigborHousePolygons(projectId: string): Observable<House[]> {
        return this.http
            .get(`${this.environment.apiPath}/house/neighbor/${projectId}`, {
                headers: this._headersNoCache,
                params: new SilenceHttpParams(true, true, false),
            })
            .pipe(
                map((result: House[]) => {
                    if (!result) {
                        return;
                    }
                    this.assignPathValuesForHouseArray(result);
                    return result;
                })
            );
    }

    public getModifiedHousePolygon(projectId): Observable<House> {
        return this.http
            .get(`${this.environment.apiPath}/house/cloned/${projectId}`, {
                headers: this._headersNoCache,
                params: new SilenceHttpParams(true, true, false),
            })
            .pipe(
                map((result) => {
                    if (!result) {
                        return;
                    }
                    return this.assignPathValuesForHouse(result as House);
                })
            );
    }

    public getHouse(projectId: string, isClone: boolean): Observable<House> {
        return isClone
            ? this.getModifiedHousePolygon(projectId)
            : this.getHousePolygon(projectId);
    }

    public getAllMaterials() {
        return this.http
            .get(`${this.environment.apiPath}/house/materials`, {
                headers: this._headersNoCache,
                params: new SilenceHttpParams(true, true, false),
            })
            .pipe(
                map((result) => {
                    this.assignMaterialsForRoofAndHouse(
                        result as HouseMaterial[]
                    );
                    return result as HouseMaterial[];
                })
            );
    }

    public assignPathValuesForHouse(item: House): House {
        return House.copyHouse(item);
    }

    public assignPathValuesForHouseArray(houseArray: House[]): void {
        houseArray.map((item: House) => {
            House.copyHouse(item);
        });
    }

    public assignMaterialsForRoofAndHouse(data: HouseMaterial[]): void {
        this.houseMaterials = data;
        this.roofMaterials = data;
        this.houseMaterials = this.houseMaterials.filter(
            (material: HouseMaterial) =>
                material.materialType === MaterialTypeEnum.houseMaterial
        );
        this.roofMaterials = this.roofMaterials.filter(
            (material: HouseMaterial) =>
                material.materialType === MaterialTypeEnum.roofMaterial
        );
    }

    public changeObjectToAdd(object: PermitAsset): void {
        this.addObjectSubject.next(object);
    }

    public resetCoordinatesForBackendCall(houseToSave: House): House {
        const returnHouse = House.copyHouse(houseToSave);
        if (typeof returnHouse.xYCoordinates !== 'string') {
            returnHouse.xYCoordinates = JSON.stringify(
                houseToSave.xYCoordinates as Array<PointXY>
            );
        }
        if (typeof returnHouse.latLonCoordinates !== 'string') {
            returnHouse.latLonCoordinates = JSON.stringify(
                returnHouse.latLonCoordinates as Array<PointLatLon>
            );
        }
        return returnHouse;
    }

    public saveHouseAssets(
        houseAssets: HouseAsset[]
    ): Observable<HouseAsset[]> {
        const httpOptions = {
            headers: this._headersNoCache,
            params: new SilenceHttpParams(true, false, false),
        };
        return this.http
            .post(
                this.environment.apiPath + '/house/houseAssets',
                houseAssets,
                httpOptions
            )
            .pipe(
                map((result) =>
                    PermitUtils.deserializeHouseAssets(result as HouseAsset[])
                )
            );
    }

    public updateHouseAsset(houseAsset: HouseAsset): Observable<HouseAsset> {
        const httpOptions = {
            headers: this._headersNoCache,
            params: new SilenceHttpParams(true, false, false),
        };
        return this.http
            .put(
                this.environment.apiPath + '/house/houseAsset',
                houseAsset,
                httpOptions
            )
            .pipe(
                map(
                    (result) =>
                        PermitUtils.deserializeHouseAssets([
                            result,
                        ] as HouseAsset[])[0]
                )
            );
    }

    public getHouseAssets(houseId: string): Observable<HouseAsset[]> {
        return this.http
            .get(`${this.environment.apiPath}/house/houseAssets/${houseId}`, {
                headers: this._headersNoCache,
                params: new SilenceHttpParams(true, true, false),
            })
            .pipe(
                map((result) =>
                    PermitUtils.deserializeHouseAssets(result as HouseAsset[])
                )
            );
    }

    public deleteHouseAssets(
        houseAssetsToDelete: HouseAsset[]
    ): Observable<void> {
        const httpOptions = {
            headers: this._headersNoCache,
            params: new SilenceHttpParams(true, false, false),
            body: houseAssetsToDelete,
        };
        return this.http.delete<void>(
            `${this.environment.apiPath}/house/houseAssets/delete`,
            httpOptions
        );
    }

    public cancelSelection() {
        this.cancelSelectionSubject.next(true);
        this.changeObjectToAdd(null);
    }

    public saveHouseArea(houseArea: HouseArea): Observable<HouseArea> {
        return this.http
            .post(this.environment.apiPath + '/house/houseArea', houseArea, {
                headers: this._headersNoCache,
            })
            .pipe(
                map((result) => {
                    this.assignPathValues(result as HouseArea);
                    return result as HouseArea;
                })
            );
    }

    public getHouseAreaPolygon(projectId: string): Observable<HouseArea> {
        return this.http
            .get(`${this.environment.apiPath}/house/houseArea/${projectId}`, {
                headers: this._headersNoCache,
                params: new SilenceHttpParams(true, true, false),
            })
            .pipe(
                map((result) => {
                    this.assignPathValues(result as HouseArea);
                    return result as HouseArea;
                })
            );
    }

    private assignPathValues(item: HouseArea): void {
        this.houseAreaDrawing = new Path();
        this.houseAreaDrawing.latLonCoordinates = JSON.parse(
            item.latLonCoordinates as string
        ) as Array<PointLatLon>;
        this.houseAreaDrawing.xYCoordinates = JSON.parse(
            item.xYCoordinates as string
        ) as Array<PointXY>;
        this.houseAreaDrawing.pathId = item.pathId;
        this.houseAreaDrawing.projectId = item.projectId;
        this.houseAreaDrawing.defaultMaterial = 1001;
    }
}
