import { Inject, Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG } from '@furban/app-config';
import { CategoryObject } from '../_models/category-object';
import { PermitCategoryObject } from '../_models/permit-category-object';
import { AuthService } from './auth.service';
import { Furban3DModel } from '../_models/furban-3d-model';
import { Project3dModel } from '../_models/project-3d-model';
import { SilenceHttpParams } from '../_models/silence-http-param';
import { Tool } from '../_models/tool';
import { Environment } from '../_models/environment-model';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    public menuToggled: EventEmitter<boolean>;
    public menuOpened = true;
    public shouldShowMenu = false;
    public objectToAddOnClick: number;
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );
    private _availableObjects: CategoryObject[] = [];
    private _permitAvailableObjects: PermitCategoryObject[] = [];

    private buttonClickedSubject = new Subject<Tool>();

    private actionFinishedSubject = new Subject<boolean>();

    public get buttonClickedObservable$(): Observable<Tool> {
        return this.buttonClickedSubject.asObservable();
    }

    public get actionFinishedObservable$(): Observable<boolean> {
        return this.actionFinishedSubject.asObservable();
    }

    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        private http: HttpClient,
        private authService: AuthService
    ) {
        this.menuToggled = new EventEmitter();
    }

    public emitOnButtonClicked(toolButton: Tool): void {
        this.buttonClickedSubject.next(toolButton);
    }

    public emitOnActionFinished(): void {
        this.actionFinishedSubject.next(true);
    }

    public toggleNav(value) {
        this.menuOpened = value;
        this.menuToggled.emit(value);
    }

    public get availableObjects(): CategoryObject[] {
        return this._availableObjects;
    }
    public set availableObjects(value: CategoryObject[]) {
        this._availableObjects = value;
    }

    public get permitAvailableObjects(): PermitCategoryObject[] {
        return this._permitAvailableObjects;
    }

    public set permitAvailableObjects(value: PermitCategoryObject[]) {
        this._permitAvailableObjects = value;
    }

    getObject(objectId: number): Furban3DModel {
        for (const category of this.availableObjects) {
            const object = category.models.find(
                (obj) => obj.furban3DModel.objectLookId === objectId
            );
            if (object) {
                return object.furban3DModel;
            }
        }
    }

    getClientObject(objectId: number): Project3dModel {
        for (const category of this.availableObjects) {
            const object = category.models.find(
                (obj) => obj.furban3DModel.objectLookId === objectId
            );
            if (object) {
                return object;
            }
        }
    }

    getObjectsOrdered(projectId: string): Observable<CategoryObject[]> {
        if (this.authService.hasAdministrativeRole()) {
            return this.getAllObjectsOrdered(projectId);
        } else {
            return this.getEnabledObjectsOrdered(projectId);
        }
    }

    getAllObjectsOrdered(projectId: string): Observable<CategoryObject[]> {
        return this.http
            .get(`${this.environment.apiPath}/models/all/${projectId}`, {
                headers: this._headers,
            })
            .pipe(
                map((result) => {
                    const resultMap = result as CategoryObject[];
                    this.availableObjects = resultMap;
                    return resultMap;
                })
            );
    }

    getEnabledObjectsOrdered(projectId: string): Observable<CategoryObject[]> {
        return this.http
            .get(`${this.environment.apiPath}/models/${projectId}`, {
                headers: this._headers,
            })
            .pipe(
                map((result) => {
                    const resultMap = result as CategoryObject[];
                    this.availableObjects = resultMap;
                    return resultMap;
                })
            );
    }

    getPermitObjectsOrdered(): Observable<PermitCategoryObject[]> {
        return this.http
            .get(`${this.environment.apiPath}/assets/`, {
                headers: this._headers,
            })
            .pipe(
                map((result: PermitCategoryObject[]) => {
                    result.map((categoryObjs) => {
                        this.addImagePathToObjectsFromCategory(categoryObjs);
                    });
                    this.permitAvailableObjects = result;
                    return result;
                })
            );
    }

    getPermitObjectsToPlaceInRow(): Observable<PermitCategoryObject[]> {
        return this.http
            .get(`${this.environment.apiPath}/assets/objectsToPlaceInRow`, {
                headers: this._headers,
            })
            .pipe(
                map((result: PermitCategoryObject[]) => {
                    result.map((categoryObjs) => {
                        this.addImagePathToObjectsFromCategory(categoryObjs);
                    });
                    return result;
                })
            );
    }

    addImagePathToObjectsFromCategory(categoryObject: PermitCategoryObject) {
        categoryObject.models.map((model) => {
            model.imagePath =
                categoryObject.category.objectsPath +
                model.objectLookId +
                '/' +
                model.objectLookId +
                '_menu.png';
        });
    }

    getObjectsIds(projectId: string): Observable<number[]> {
        return this.http
            .get(`${this.environment.apiPath}/models/ids/${projectId}`, {
                headers: this._headers,
                params: new SilenceHttpParams(true, false, false),
            })
            .pipe(
                map((result) => {
                    return result as number[];
                })
            );
    }

    getGreenOjects(projectId: string): Observable<Project3dModel[]> {
        return this.http
            .get(
                `${this.environment.apiPath}/models/greenObjects/${projectId}`,
                { headers: this._headers }
            )
            .pipe(
                map((result) => {
                    const resultMap = result as Project3dModel[];
                    return resultMap;
                })
            );
    }

    getObjectsThatCanBePlacedInRow(
        projectId: string
    ): Observable<CategoryObject[]> {
        return this.http
            .get(
                `${this.environment.apiPath}/models/objectsToPlaceInRow/${projectId}`,
                { headers: this._headers }
            )
            .pipe(map((result) => result as CategoryObject[]));
    }
}
