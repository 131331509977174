import { Injectable } from '@angular/core';

@Injectable()
export class ModalConfigService {
    public conf: ModalConfiguration;

    constructor() {}
}

export class ModalConfiguration {
    constructor(
        public title: string,
        public message: string,
        public okLabel: string,
        public cancelLabel?: string,
        public callback?: any,
        public cancelCallback?: any,
        public scope?: any
    ) {}
}
