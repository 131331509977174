import { HttpParams } from '@angular/common/http';

export class SilenceHttpParams extends HttpParams {
    constructor(
        public isLoaderHidden: boolean,
        public isErrorIgnored: boolean,
        public isLoaderHalfSize: boolean
    ) {
        super();
    }
}
