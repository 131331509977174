import { Component, Inject, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AuthService, Project } from '@furban/utilities';

@Component({
    selector: 'furban-permit-comments-dialog',
    templateUrl: './permit-comments-dialog.component.html',
    styleUrls: ['./permit-comments-dialog.component.scss'],
})
export class PermitCommentsDialogComponent {
    public project: Project;
    public parentViewContainerRef: ViewContainerRef;
    public isModified = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private authService: AuthService,
        private dialogRef: MatDialogRef<PermitCommentsDialogComponent>
    ) {
        this.project = data.project;
    }

    public isLoggedIn(): boolean {
        return this.authService.isLoggedIn();
    }

    public close(): void {
        this.dialogRef.close(this.isModified);
    }

    public onCommentModified(): void {
        this.isModified = true;
    }
}
