import {
    Component,
    OnInit,
    ViewContainerRef,
    ChangeDetectorRef,
    AfterViewChecked,
    OnDestroy,
    Inject
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';

import {
    AdminDashboardTabsEnum,
    AuthService,
    Card,
    Client,
    Environment,
    FurbanUtil,
    Project,
    ProjectService,
    ProjectStatusEnum,
    routingConstants
} from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';
import { SelectProjectTypeComponent } from '../select-project-type/select-project-type.component';

import { TermsAndConditionsComponent } from '../../../shared/terms-and-conditions/terms.component';
import { Subscription } from 'rxjs';
import { ProjectDetailsService } from '../../../project-shared/project-details/project-details.service';
import { APP_CONFIG } from '@furban/app-config';

@Component({
    selector: 'furban-app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent
    implements OnInit, AfterViewChecked, OnDestroy {
    public cardConfig: Card = new Card();
    public projects: Project[];
    public activeTab: AdminDashboardTabsEnum;
    public getClassForProject = FurbanUtil.getClassForProjectStatus;
    public getColorBasedOnProjectType = FurbanUtil.getColorBasedOnProjectType;
    public getProjectTypeText = FurbanUtil.getProjectTypeText;
    public projectStatus = ProjectStatusEnum;
    public tabs = AdminDashboardTabsEnum;
    public client: Client;
    private projectsListSubscription: Subscription;

    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        private authService: AuthService,
        private projectDetailsService: ProjectDetailsService,
        private router: Router,
        private dialog: MatDialog,
        private viewContainerRef: ViewContainerRef,
        private cdRef: ChangeDetectorRef,
        private projectService: ProjectService,
        private translateService: TranslateService
    ) { }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    ngOnInit() {
        this.cardConfig.cardType = 'white';
        this.cardConfig.contentAlignmentHorizontal = 'center';
        this.cardConfig.contentAlignmentVertical = 'center';
        this.cardConfig.hasHeader = false;
        this.cardConfig.height = 224;
        this.cardConfig.hoverable = true;

        this.initializeProjectsList();
        this.initializeClient();

        if (!this.authService.userProfile.acceptedTOS) {
            setTimeout(() => {
                this.openTermsModal();
            });
        }
    }

    ngOnDestroy(): void {
        this.projectsListSubscription?.unsubscribe();
    }

    public openTermsModal(): void {
        const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
            width: '60%',
        });
        dialogRef.disableClose = true;
        dialogRef.componentInstance.parentRef = this.viewContainerRef;
    }

    public goToProject(projId: string): void {
        if (projId === 'new') {
            this.router.navigateByUrl(
                `${routingConstants.process}/${projId}/(project:details)`
            );
        } else {
            this.router.navigateByUrl(
                `${routingConstants.process}/${projId}/(project:overview)`
            );
        }
    }

    public selectProjectType(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '65%';
        dialogConfig.data = {
            parentRef: this.viewContainerRef,
        };

        const involvementPopup = this.dialog.open(
            SelectProjectTypeComponent,
            dialogConfig
        );
        involvementPopup.afterClosed().subscribe((data) => {
            if (!data) {
                return;
            }
            this.projectService.projectInvolvementType = data;
            this.router.navigateByUrl(
                `${routingConstants.process}/new/(project:details)`
            );
        });
    }

    public getMedia(mediaId): string {
        return mediaId
            ? `${this.environment.apiPath}/media/public/${mediaId}`
            : '../../../assets/images/default-project.png';
    }

    public buildURL(type: string, projectId: string) {
        if (type === 'linkedin') {
            const auxURL = this.environment.shareLinks[type].furbanLink + projectId;
            const url =
                this.environment.shareLinks[type].linkedingSharer +
                encodeURIComponent(auxURL);
            window.open(url, '_blank');
        } else {
            const url = this.environment.shareLinks[type] + projectId;
            window.open(url, '_blank');
        }
    }

    public getStatusTooltipText(project: Project): string {
        const status = this.getClassForProject(project);
        return this.translateService.instant(
            `buttonsTooltips.projectStatus.${status}`
        );
    }

    public switchMyProjects(): void {
        this.activeTab = AdminDashboardTabsEnum.mine;
        if (!this.projectDetailsService.myProjects.length) {
            this.projectDetailsService.getMyProjects().subscribe((data) => {
                this.projects = data;
            });
        } else {
            this.projects = this.projectDetailsService.myProjects;
        }
    }

    public switchAllProjects(): void {
        this.activeTab = AdminDashboardTabsEnum.all;
        this.projects = this.projectDetailsService.allProjects;
    }

    public isTabSelected(tab: AdminDashboardTabsEnum): boolean {
        return this.activeTab === tab;
    }

    private initializeProjectsList(): void {
        this.activeTab = AdminDashboardTabsEnum.all;
        this.projects = this.projectDetailsService.allProjects;
        this.projectsListSubscription =
            this.projectDetailsService.allProjectsListObservable.subscribe(
                (data) => {
                    this.projects = data;
                }
            );
    }

    private initializeClient(): void {
        if (this.authService.client) {
            this.client = this.authService.client;
        } else {
            this.authService.getClient().subscribe((data) => {
                this.client = data;
            });
        }
    }
}
