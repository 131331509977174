import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    AuthService,
    DesignProposalService,
    Project,
    StepperService,
} from '@furban/utilities';
import { Subscription } from 'rxjs';
import { DesignProposalsComponent } from '../design-proposals/design-proposals.component';
import { ProjectDetailsService } from '../project-details/project-details.service';

@Component({
    selector: 'furban-app-project-designs',
    templateUrl: './project-design-proposals.component.html',
    styleUrls: ['./project-design-proposals.component.scss'],
})
export class ProjectDesignsComponent implements OnInit, OnDestroy {
    @ViewChild('designProposals') designProposals: DesignProposalsComponent;

    public citizenTotalDPs: number;
    public projectId: string;
    public project: Project;
    public top3Length: number;

    private routeSub: Subscription;

    constructor(
        private projectDetailsService: ProjectDetailsService,
        public viewContainerRef: ViewContainerRef,
        public authService: AuthService,
        private stepperService: StepperService,
        private designProposalService: DesignProposalService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.setCurrentProject();
        this.countDesignProposals();
        this.stepperService.modifyCurrentStepId(false);
    }

    public ngOnDestroy(): void {
        this.routeSub?.unsubscribe();
    }

    public onScrollDown(): void {
        this.designProposals.onParentScrollDown();
    }

    private countDesignProposals(): void {
        this.designProposalService
            .getCitizenDesignProposalCount(this.projectId)
            .subscribe((data) => {
                this.citizenTotalDPs = data;
            });
    }

    private setCurrentProject(): void {
        if (this.stepperService && this.stepperService.project) {
            this.projectId = this.stepperService.project.projectId;
            this.project = this.stepperService.project as Project;
        } else {
            this.routeSub = this.route.params.subscribe((params) => {
                this.projectId = params['projectId'];
                if (this.projectId) {
                    this.projectDetailsService
                        .getProject(this.projectId)
                        .subscribe((data) => {
                            this.project = data;
                        });
                }
            });
        }
    }
}
