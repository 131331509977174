<furban-avatar-picture
    class="relative"
    [name]="comment.userProfile.screenName"
    [size]="56"
    [mediaId]="getUserMedia(comment.userProfile.mediaId)"
    [highlight]="highlight()"
>
</furban-avatar-picture>

<div class="user-description" [class.highlight]="highlight()">
    <div class="flex-row-spaced">
        <furban-app-user-action-menu
            *ngIf="parentViewContainerRef"
            [userProfile]="comment.userProfile"
            [parentViewContainerRef]="parentViewContainerRef"
            [projectName]="project.name"
            (userDelete)="userDelete($event)"
        >
        </furban-app-user-action-menu>
    </div>
    <h6>{{ comment.commentText }}</h6>
    <ng-container *ngIf="comment.position">
        <span class="material-icons location-span"> location_on </span>
    </ng-container>
</div>
