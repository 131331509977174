<div class="flex-row-center" (click)="returnToDashboard()">
    <div class="back-icon-button flex-row-center" [style.margin-right.px]="8">
        <span
            class="material-icons"
            matTooltip="{{ 'buttonsTooltips.goToDashboard' | translate }}"
        >
            chevron_left
        </span>
    </div>
    <h4 *ngIf="isOnProjectSettingsPage()">
        {{ 'admin.dashboard.backToProject' | translate }}
    </h4>
</div>

<div
    *ngIf="isOnProjectSettingsPage()"
    class="header-middle-content flex-row-spaced"
>
    <button
        mat-icon-button
        [disabled]="isOnProjectDetailsPage()"
        [matTooltip]="getBackwardStepTooltipText()"
        [style.margin-left.px]="20"
        (click)="goBack()"
    >
        <span class="material-icons">arrow_back</span>
    </button>
    <h4>
        {{ getHeaderTitle() }}
    </h4>
    <button
        mat-icon-button
        [disabled]="isTheFirstStepAndIsNewProjectOrIsTheLastStep()"
        [matTooltip]="getForwardStepTooltipText()"
        (click)="goForward()"
    >
        <span class="material-icons">arrow_forward</span>
    </button>
</div>

<div *ngIf="isOnProjectSettingsPage()" class="progress-status flex-row-spaced">
    <div class="spinner-container">
        <mat-progress-spinner
            class="background-spinner"
            [mode]="'determinate'"
            [value]="100"
            [strokeWidth]="6"
            [diameter]="32"
        >
        </mat-progress-spinner>

        <mat-progress-spinner
            class="status-spinner"
            [mode]="'determinate'"
            [value]="projectProgress"
            [strokeWidth]="6"
            [diameter]="32"
        >
        </mat-progress-spinner>
    </div>
    <h4>
        {{ getProgressStatusText() }}
    </h4>
</div>
