import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@furban/utilities';
import { environment } from 'apps/furban-client/src/environments/environment';

@Injectable()
export class AdminNewService {
    private _options = {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    constructor(private http: HttpClient) {}

    addNewAdmin(
        username: string,
        password: string,
        clientId: number
    ): Observable<User> {
        const REQUEST_DATA = {
            username: username,
            password: password,
        };

        return this.http
            .put(
                `${environment.apiPath}/user/admin/${clientId}`,
                REQUEST_DATA,
                this._options
            )
            .pipe(map((user) => <User>user));
    }
}
