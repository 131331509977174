<furban-login-wrapper [stylingClass]="'admin'" [infoText]="'admin.auth.infoText' | translate">
    <ng-container *ngIf="state === 'login'">
        <form class="login-form" [formGroup]="loginCheckForm" (ngSubmit)="login()">
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'generic.email' | translate }}
                </mat-label>
                <input required matInput placeholder="{{ 'generic.email' | translate }}"
                    formControlName="emailFormControl" />
                <mat-error *ngIf="hasControlRequiredError('emailFormControl',loginCheckForm)">
                    {{ 'auth.requiredEmail' | translate }}
                </mat-error>
                <mat-hint class="hint-color" *ngIf="hasControlEmailError('emailFormControl',loginCheckForm)">
                    {{ 'admin.auth.validEmail' | translate }}
                </mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'auth.password' | translate }}
                </mat-label>
                <input required matInput placeholder="{{ 'auth.password' | translate }}"
                    formControlName="passwordFormControl" type="password" />
                <mat-error *ngIf="hasControlRequiredError('passwordFormControl',loginCheckForm)">
                    {{ 'auth.requiredPassword' | translate }}
                </mat-error>
            </mat-form-field>
            <ng-container *ngIf="isBlocked">
                <section class="captcha-message">
                    <h6>{{ 'captcha.message' | translate }}</h6>
                    <img [src]="captchaData" width="200" height="50" />
                    <button mat-icon-button (click)="showCaptcha($event)">
                        <span class="material-icons">replay</span>
                    </button>
                </section>
                <mat-form-field appearance="outline">
                    <mat-label>
                        {{ 'captcha.code' | translate }}
                    </mat-label>
                    <input matInput required type="text" placeholder="{{ 'captcha.code' | translate }}"
                        formControlName="captchaAnswer" />
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!isApp()">
                <mat-checkbox formControlName="remberMeFormControl" class="remember-checkbox">
                    {{ 'auth.rememberMe' | translate }}</mat-checkbox>
            </ng-container>
            <button class="primary-button full-width" type="submit">
                <h6>{{ 'auth.loginLabel' | translate }}</h6>
            </button>

            <ng-container *ngIf="isApp()">
                <button class="secondary-button full-width" [style.margin-bottom.px]="12"
                    (click)="navigateToLandscape()">
                    <h6>{{ 'auth.back' | translate }}</h6>
                </button>
            </ng-container>

            <a (click)="state = 'recover'">{{
                'auth.lostPassword' | translate
                }}</a>

        </form>
    </ng-container>
    <ng-container *ngIf="state === 'changePass'">
        <form class="login-form" [formGroup]="changePassCheckForm" (ngSubmit)="changeUserPass()">
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'admin.profile.name' | translate }}
                </mat-label>
                <input required matInput placeholder="{{ 'admin.profile.name' | translate }}"
                    formControlName="displayNameFormControl" type="text" />
                <mat-error *ngIf="hasControlRequiredError('displayNameFormControl',changePassCheckForm)">
                    {{ 'admin.profile.required' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlIsHtmlError('displayNameFormControl',changePassCheckForm)">
                    {{ 'validators.noHTML' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlWhitespacesError('displayNameFormControl',changePassCheckForm)">
                    {{ 'validators.noWhiteSpaces' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlMaxLengthError('displayNameFormControl',changePassCheckForm)">
                    {{ 'admin.profile.maxLength' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'admin.auth.oldPassword' | translate }}
                </mat-label>

                <input required matInput placeholder="{{ 'admin.auth.oldPassword' | translate }}"
                    formControlName="oldPasswordFormControl" type="password" autocomplete="new-password" />
                <mat-error *ngIf="hasControlRequiredError('oldPasswordFormControl',changePassCheckForm)">
                    {{ 'admin.auth.oldPasswordRequired' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'admin.auth.newPassword' | translate }}
                </mat-label>
                <input required matInput placeholder="{{ 'admin.auth.newPassword' | translate }}"
                    formControlName="passwordFormControl" type="password" />

                <mat-hint align="end">{{
                    'auth.validPasswordHint' | translate
                    }}</mat-hint>

                <mat-error *ngIf="getPasswordValidationErrors">
                    {{
                    getPasswordErrorMessage(
                    getPasswordValidationErrors,
                    translateService
                    )
                    }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'admin.auth.confirmPassword' | translate }}
                </mat-label>
                <input required matInput placeholder="{{ 'admin.auth.confirmPassword' | translate }}"
                    formControlName="passwordConfirmFormControl" type="password" [errorStateMatcher]="errorMatcher" />
                <mat-error *ngIf="hasControlEmailError('passwordConfirmFormControl',changePassCheckForm)">
                    {{ 'admin.auth.confirmPasswordRequired' | translate }}
                </mat-error>
                <mat-error *ngIf="changePassCheckForm.hasError('passwordMatches')">
                    {{ 'admin.auth.confirmPasswordMatch' | translate }}
                </mat-error>
            </mat-form-field>

            <button class="primary-button full-width" type="submit">
                <h6>{{ 'auth.submit' | translate }}</h6>
            </button>
        </form>
    </ng-container>

    <ng-container *ngIf="state === 'recover'">
        <form class="login-form" [formGroup]="recoverPasswordCheckForm" (ngSubmit)="recover()">
            <mat-form-field appearance="outline">
                <mat-label>
                    {{ 'generic.email' | translate }}
                </mat-label>
                <input required matInput placeholder="{{ 'generic.email' | translate }}"
                    formControlName="recoverEmailFormControl" />
                <mat-error *ngIf="hasControlRequiredError('recoverEmailFormControl',recoverPasswordCheckForm)">
                    {{ 'auth.requiredEmail' | translate }}
                </mat-error>
                <mat-hint class="hint-color"
                    *ngIf="hasControlEmailError('recoverEmailFormControl',recoverPasswordCheckForm)">
                    {{ 'admin.auth.validEmail' | translate }}
                </mat-hint>
            </mat-form-field>

            <button class="primary-button full-width" type="submit">
                <h6>Recover</h6>
            </button>

            <a (click)="backToLogin()">{{
                'user.auth.backToLogin' | translate
                }}</a>
        </form>
    </ng-container>

    <ng-container *ngIf="state === 'changeName'">
        <furban-change-name [profile]="profile"></furban-change-name>
    </ng-container>

    <ng-container *ngIf="state === 'after_recover'">
        <div class="login-form">
            <h5 [style.margin-bottom.px]="20">
                {{ 'admin.auth.changePassSuccessInfo' | translate }}
            </h5>
            <a (click)="state = 'login'">{{
                'user.auth.backToLogin' | translate
                }}</a>
        </div>
    </ng-container>

</furban-login-wrapper>