import { PointXY } from './point-xy';
import { PointLatLon } from './point-lat-lon';

export class Path {
    public pathId?: number;
    public xYCoordinates: Array<PointXY>;
    public latLonCoordinates: Array<PointLatLon>;
    public defaultMaterial?: number;
    public projectId?: string;
    public xCenter: number;
    public yCenter: number;
    public imageId?: string;
}
