import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookiePolicyDialogComponent } from '../cookie-policy-dialog/cookie-policy-dialog.component';

import { DialogUtil, EmbedService } from '@furban/utilities';
import { Subscription } from 'rxjs';

@Component({
    selector: 'furban-app-cookie-banner',
    templateUrl: './cookie-banner.component.html',
    styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnInit, OnDestroy {
    public cookiesAccepted;
    public isEmbededComponent = false;
    private isEmbededSubscription: Subscription;

    constructor(
        private embedService: EmbedService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.cookiesAccepted = localStorage.getItem('acceptCookies');

        this.subscribeToEmbededEmitter();
    }

    ngOnDestroy(): void {
        this.isEmbededSubscription.unsubscribe();
    }

    acceptCookies(): void {
        localStorage.setItem('acceptCookies', 'true');
        this.cookiesAccepted = true;
    }

    openCookiePolicyDialog(): void {
        DialogUtil.openModalFromTemplate(
            this.dialog,
            CookiePolicyDialogComponent
        );
    }

    private subscribeToEmbededEmitter(): void {
        this.isEmbededSubscription =
            this.embedService.isEmbededComponentEmitter.subscribe((data) => {
                this.isEmbededComponent = data;
            });
    }
}
