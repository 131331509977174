import {
    AfterViewInit,
    Component,
    Injector,
    OnInit,
    Output,
    ViewContainerRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
} from '@angular/core';

import {
    addMultipleObjects,
    addMultipleObjectsRequest,
    addObjectRequest,
    deleteMultipleObjects,
    deleteMultipleObjectsRequest,
    deleteObjectRequest,
    DesignProposal,
    DesignProposalStatusEnum,
    FreezeActionEnum,
    FurbanMeshPosition,
    getDefaultObjects,
    getLiveObjects,
    ObjectUtil,
    PathObject,
    SocketActionsEnum,
    SocketService,
    TextureColorEnum,
    ThreeGroupEnum,
    ThreeInstance,
    ThreeStateEnum,
    ThreeStore,
    ThreeUtils,
    updateMultipleObjects,
    updateMultipleObjectsRequest,
    MediaService,
    updateObjectRequest,
} from '@furban/utilities';
import { MatSnackBar } from '@angular/material/snack-bar';
import { State, Store } from '@ngrx/store';
import { Object3D } from 'three';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NgrxThreeEditorComponent } from '../ngrx-three-editor/ngrx-three-editor.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { ReconnectModalComponent } from '../reconnect-modal/reconnect-modal.component';

@Component({
    selector: 'furban-ngrx-three-live-collaboration',
    templateUrl: './ngrx-three-live-collaboration.component.html',
    styleUrls: ['./ngrx-three-live-collaboration.component.scss'],
})
export class NgrxThreeLiveCollaborationComponent extends NgrxThreeEditorComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() designProposalStatusUpdate: EventEmitter<number> =
        new EventEmitter();
    @Output() connectionCountUpdate: EventEmitter<number> =
        new EventEmitter();
    @Input() override designProposal: DesignProposal;

    public override snackBar: MatSnackBar;
    public override viewContainerRef: ViewContainerRef;
    private freezeSubjectMultiselectSubscription: Subscription;
    private currentFreezedUserObjects: number[] = [];

    constructor(
        protected override injector: Injector,
        protected override store: Store<{ store: ThreeStore }>,
        protected override currentAppState: State<ThreeStore>,
        protected override mediaService: MediaService,
        private socketService: SocketService
    ) {
        super(injector, store, mediaService, currentAppState);
    }

    ngOnInit() {
        this.instantiateSocket();
        this.listenToSocketActions();
        this.onDisconnectFromSocket();
        this.designProposalId = this.designProposal.designProposalId;
    }

    override ngAfterViewInit() {
        super.ngAfterViewInit();
        this.subscribeToFreezeSubjectMultiselect();
        this.toggleViewOnDesignFinished();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.emitExitRoom(this.designProposal.designProposalId);
        this.removeFreezeOnExitPage();
        this.freezeSubjectMultiselectSubscription?.unsubscribe();
        this.unsubscribeToSocketClose();
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHander(event) {
        this.emitExitRoom(this.designProposal.designProposalId);
    }

    public override callForObjects(): void {
        this.store.dispatch(
            getDefaultObjects({ projectId: this.project.projectId })
        );

        this.store.dispatch(
            getLiveObjects({ dpId: this.designProposal.designProposalId })
        );
    }

    public override showViewAndEditMode(): boolean {
        return (
            this.toolingService.toolingVisibility &&
            !this.toolingService.toolingVisibility.isPublished &&
            !this.project.ended &&
            this.state !== ThreeStateEnum.screenshot &&
            this.designProposal?.designProposalStatus
                ?.designProposalStatusId !== DesignProposalStatusEnum.finished
        );
    }

    public openReconnectModal(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '40%';
        const reconnect = this.dialog.open(
            ReconnectModalComponent,
            dialogConfig
        );
        reconnect.afterClosed().subscribe((data) => {
            if (data) {
                this.reconnectOnDisconnect();
            }
        });
    }

    public sendDataToSocket(data): void {
        if (this.socketService.isSocketClosed()) {
            this.openReconnectModal();
        } else {
            this.socketService.socket.send(data);
        }
    }

    protected override dispatchAddObjectRequest(
        pathObject: PathObject,
        shouldSelect: boolean,
        budget?: number
    ): void {
        this.store.dispatch(
            addObjectRequest({
                object: pathObject,
                shouldSelect: shouldSelect,
                budget: budget,
                socketId: this.designProposal.designProposalId,
            })
        );
    }

    protected override dispatchMultipleDeleteObjectsRequest(
        pathObjectsToDelete: PathObject[],
        budget?: number
    ): void {
        this.store.dispatch(
            deleteMultipleObjectsRequest({
                objects: pathObjectsToDelete,
                budget: budget,
            })
        );
        this.emitDeleteObjects(pathObjectsToDelete);
    }

    protected override dispatchDeleteObjectsRequest(budget?: number): void {
        this.store.dispatch(
            deleteObjectRequest({
                object: this.instanceIntersectedObject.userData as any,
                budget: budget,
            })
        );
        this.emitDeleteObjects([this.instanceIntersectedObject.userData as any]);
    }

    protected override dispatchAddMultipleObjectsRequest(
        pathOjects: PathObject[],
        shouldGroup: boolean,
        shouldSelect: boolean,
        budget?: number
    ) {
        this.store.dispatch(
            addMultipleObjectsRequest({
                objects: pathOjects,
                shouldGroup: shouldGroup,
                shouldSelect: shouldSelect,
                budget: budget,
                socketId: this.designProposal.designProposalId,
            })
        );
    }

    protected override dispatchUpdateObjectRequest(pathObject: PathObject) {
        this.store.dispatch(updateObjectRequest({ object: pathObject }));
        this.emitEditObjects([pathObject]);
    }

    protected override dispatchUpdateMultipleObjectsRequest(
        pathObjectsToUpdate: PathObject[]
    ) {
        this.store.dispatch(
            updateMultipleObjectsRequest({ objects: pathObjectsToUpdate })
        );
        this.emitEditObjects(pathObjectsToUpdate);
    }

    private toggleViewOnDesignFinished(): void {
        const isFinished =
            this.designProposal?.designProposalStatus
                ?.designProposalStatusId === DesignProposalStatusEnum.finished;
        if (isFinished) {
            this.onToggleEditChange(false);
        }
    }

    private emitEnterRoom(roomId: string): void {
        this.sendDataToSocket(
            `{"action": "${SocketActionsEnum.roomEnter}", "data": "${roomId}"}`
        );
    }

    private emitExitRoom(roomId: string): void {
        this.sendDataToSocket(
            `{"action": "${SocketActionsEnum.roomExit}", "data": "${roomId}"}`
        );
    }

    private emitDeleteObjects(pathObjects: PathObject[]): void {
        const roomAndMessage = {
            roomId: this.designProposal.designProposalId,
            msg: pathObjects,
        };
        const dataStringify = JSON.stringify(roomAndMessage);
        this.sendDataToSocket(
            `{"action" : "${SocketActionsEnum.objectsDelete}", "data":${dataStringify}}`
        );
    }

    private emitEditObjects(pathObjects: PathObject[]): void {
        const roomAndMessage = {
            roomId: this.designProposal.designProposalId,
            msg: pathObjects,
        };
        const dataStringify = JSON.stringify(roomAndMessage);
        this.sendDataToSocket(
            `{"action": "${SocketActionsEnum.objectsEdit}", "data": ${dataStringify}}`
        );
    }

    private emitFreezeObjects(ids: number[]): void {
        const roomId = this.designProposal.designProposalId;
        const roomAndIds = {
            roomId: roomId,
            freezeObjIds: JSON.stringify(ids),
        };
        const dataStringify = JSON.stringify(roomAndIds);
        this.currentFreezedUserObjects = ids;
        this.sendDataToSocket(
            `{"action" : "${SocketActionsEnum.objectsFreeze}", "data" : ${dataStringify}}`
        );
    }

    private emitUnfreezeObjects(ids: number[]): void {
        const roomId = this.designProposal.designProposalId;
        const roomAndIds = {
            roomId: roomId,
            freezeObjIds: JSON.stringify(ids),
        };
        const dataStringify = JSON.stringify(roomAndIds);
        this.currentFreezedUserObjects = ids;
        this.sendDataToSocket(
            `{"action" : "${SocketActionsEnum.objectsUnfreeze}", "data" : ${dataStringify}}`
        );
    }

    private instantiateSocket(): void {
        this.socketService.socket = new WebSocket(
            environment.socket,
            environment.socketToken
        );
        this.socketService.socket.onopen = () => {
            this.emitEnterRoom(this.designProposal.designProposalId);
        };
    }

    private listenToSocketActions(): void {
        this.socketService.socket.onmessage = (event) => {
            const parsedResponse = JSON.parse(event.data);
            const action = parsedResponse.action;
            const data = parsedResponse.data;

            switch (action) {
                case SocketActionsEnum.onAdd:
                    this.store.dispatch(
                        addMultipleObjects({
                            objects: data,
                            shouldGroup: false,
                            shouldSelect: false,
                        })
                    );
                    break;
                case SocketActionsEnum.onEdit:
                    this.store.dispatch(
                        updateMultipleObjects({ objects: data })
                    );
                    this.updateThreeJsInstanceObjects(data);
                    break;
                case SocketActionsEnum.onDelete:
                    this.store.dispatch(
                        deleteMultipleObjects({ objects: data })
                    );
                    break;
                case SocketActionsEnum.onFreeze:
                    this.setFreezeOrUnfreezeColor(
                        JSON.parse(data),
                        SocketActionsEnum.objectsFreeze
                    );
                    break;
                case SocketActionsEnum.onUnfreeze:
                    this.setFreezeOrUnfreezeColor(
                        JSON.parse(data),
                        SocketActionsEnum.objectsUnfreeze
                    );
                    break;
                case SocketActionsEnum.onSessionStatusUpdate:
                    this.updateLiveSessionStatus(data);
                    break;
                case SocketActionsEnum.onConnectionsCountUpdate:
                    this.updateConnectionsCount(data);
                    break;
                default:
                    break;
            }
        };
    }

    private updateThreeJsInstanceObjects(pathObjects: PathObject[]): void {
        const allObjects = this.threeInstance.objectsRegular.children.concat(
            this.threeInstance.objectsGround.children
        );

        pathObjects.forEach((element) => {
            allObjects.map((obj) => {
                if (obj.userData['pathObjsId'] !== element.pathObjsId) {
                    return;
                }

                if (!ObjectUtil.isGroundOrCustomObject(element.name)) {
                    const meshPosition = new FurbanMeshPosition(
                        JSON.parse(element.position),
                        element.angle
                    );
                    ThreeUtils.setPositionAndRotationOnMesh(
                        obj,
                        meshPosition,
                        true
                    );
                    return;
                }

                this.updateFreeObject(obj, element);
            });
        });
    }

    private updateFreeObject(object: Object3D, pathObject: PathObject): void {
        const objectsGround = this.threeInstance?.scene?.getObjectByName(
            ThreeGroupEnum.objectsGround
        );
        const isFreeze = object.userData['freeze'];
        objectsGround.remove(object);
        this.attachGroundObjectToScene(pathObject, false, isFreeze);
    }

    private setFreezeOrUnfreezeColor(ids: number[], action: string): void {
        const objectsGround = this.threeInstance?.scene?.getObjectByName(
            ThreeGroupEnum.objectsGround
        );
        const objectsRegular = this.threeInstance?.scene?.getObjectByName(
            ThreeGroupEnum.objectsRegular
        );
        const allObjects = objectsGround.children.concat(
            objectsRegular.children
        );

        const color =
            action === SocketActionsEnum.objectsFreeze
                ? TextureColorEnum.yellow
                : TextureColorEnum.neutral0;

        allObjects.map((object) => {
            if (!ids.includes(object.userData['pathObjsId'])) {
                return;
            }

            ThreeUtils.setHexColorOnMaterial(object, color);
            object.userData['freeze'] =
                action === SocketActionsEnum.objectsFreeze ? true : false;
        });
    }

    private onDisconnectFromSocket(): void {
        this.socketService.socket.onclose = (event) => {
            this.openReconnectModal();
        };
    }

    private unsubscribeToSocketClose(): void {
        this.socketService.socket.onclose = null;
        this.socketService.socket.close();
    }

    private reconnectOnDisconnect(): void {
        this.instantiateSocket();
        this.listenToSocketActions();
    }

    private updateLiveSessionStatus(status: DesignProposalStatusEnum): void {
        this.designProposalStatusUpdate.emit(status);
    }

    private updateConnectionsCount(connectionsCount: number): void {
        this.connectionCountUpdate.emit(connectionsCount);
    }

    private subscribeToFreezeSubjectMultiselect(): void {
        this.freezeSubjectMultiselectSubscription =
            ThreeInstance.getFreezeSubject().subscribe((data) => {
                if (!data) {
                    return;
                }

                if (data.action === FreezeActionEnum.freeze) {
                    this.emitFreezeObjects(data.ids);
                } else if (data.action === FreezeActionEnum.unfreeze) {
                    this.emitUnfreezeObjects(data.ids);
                }
            });
    }

    private removeFreezeOnExitPage(): void {
        if (this.currentFreezedUserObjects.length === 0) {
            return;
        }
        this.emitUnfreezeObjects(this.currentFreezedUserObjects);
    }
}
