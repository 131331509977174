import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { FurbanLoaderState } from './furban-loaderState';

@Injectable()
export class FurbanLoaderService {
    public loaderSubject = new Subject<FurbanLoaderState>();

    public set permanent(value: boolean) {
        this._permanent = value;
    }
    public get permanent(): boolean {
        return this._permanent;
    }

    public furbanLoaderState: Observable<FurbanLoaderState> =
        this.loaderSubject.asObservable();
    private _permanent: boolean;

    show(half: boolean) {
        const loaderElement = document.querySelector('.loader-overlay');

        if (loaderElement) {
            loaderElement.classList.remove('temp-class');
        }
        this.loaderSubject.next(<FurbanLoaderState>{
            show: true,
            half: half,
        });
    }

    hide() {
        if (this._permanent) {
            return;
        }

        const loaderElement = document.querySelector('.loader-overlay');

        if (loaderElement) {
            loaderElement.classList.add('temp-class');
        }

        this.loaderSubject.next(<FurbanLoaderState>{ show: false });
    }
}
