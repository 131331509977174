<div class="poll-container">
    <section class="title">
        <h2 id="poll-step" class="poll-title">
            {{ 'admin.poll.pollTitle' | translate }}
        </h2>
    </section>

    <ng-container *ngIf="hasPolls; else hasNoPolls">

        <div class="voting-container">
            <span class="material-icons change-btn" [style.margin-right.px]="12"
                (click)="changePoll(changeAction.left)"> chevron_left</span>

            <div class="poll-card-section">
                <div class="top-poll-section">
                    <div class="poll-question">
                        {{
                            'admin.poll.questionWithNo'
                            | translate
                            : { size: currentPollIndex+1, total: polls.length }
                            }}

                        <p class="question-text">{{ polls[currentPollIndex].pollQuestion }}</p>
                    </div>
                    <div class="date-container">
                        <mat-icon class="date-icon" svgIcon="date-icon"></mat-icon>
                        <p class="bold"> {{ 'admin.poll.rangeDate' | translate }}:</p>
                        {{ polls[currentPollIndex].startDate | date }} - {{ polls[currentPollIndex].endDate | date }}
                        <ng-container *ngIf="showRemainingDays(polls[currentPollIndex])">
                            <div class="remaining-days">
                                &bull; {{ calculateRemainingDays(polls[currentPollIndex]) }}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <mat-radio-group aria-labelledby="radio-group-label" class="poll-radio-group"
                    [(ngModel)]="selectedOption">
                    <mat-radio-button *ngFor="let pollOption of polls[currentPollIndex].pollOptions"
                        [value]="pollOption" [checked]="hasAnswerSelected(pollOption)"
                        [class.selected]="hasOptionOrAnswerSelected(pollOption)" (click)="saveAnswer(pollOption)">
                        <div class="flex-column-start custom-width-95" [style.margin-left.px]="12">
                            <div class="break">
                                {{ pollOption.option }}
                            </div>
                            <div *ngIf="selectedAnswer" class="flex-row-spaced full-width" [style.margin-top.px]="8">
                                <mat-progress-bar class="progress-bar" mode="determinate"
                                    [value]="calculatePercentage(pollOption)"></mat-progress-bar>
                                <h6 [style.margin-right.px]="8">
                                    {{ calculatePercentage(pollOption) }}%
                                </h6>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>

            </div>
            <span class="material-icons change-btn" [style.margin-left.px]="12"
                (click)="changePoll(changeAction.right)"> chevron_right </span>

        </div>

    </ng-container>

    <ng-template #hasNoPolls>
        <div class="flex-column-center padding-40-20">
            <img [style.height.px]="240" [style.margin-top.px]="20" src="../../../assets/images/no_poll.svg" />
            <h4 class="placeholder-text">
                {{ 'admin.poll.noPoll' | translate }}
            </h4>
        </div>
    </ng-template>
</div>