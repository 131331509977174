<div class="main-container padding-40-32">
    <ng-container *ngIf="client">
        <section class="title">
            <h3 class="page-title">
                {{ 'user.dashboard.title' | translate }} |
                {{ client.clientName }}
            </h3>
            <furban-projects-dropdown [style.margin-left.px]="40" id="projects-step">
            </furban-projects-dropdown>
        </section>

    </ng-container>
    <ng-container *ngIf="!curentProject">
        <div class="flex-column-center padding-40-20">
            <img src="../../../assets/images/no_design.svg" [style.height.px]="300" [style.margin-top.px]="20"
                id="no-project-step" />
            <h4 class="placeholder-text">
                {{ 'user.dashboard.noDashboard' | translate }}
            </h4>
        </div>
    </ng-container>

    <ng-container *ngIf="curentProject">
        <div class="parent" [class.smaller-grid]="authService.isExpert()">
            <furban-app-card class="div1" id="remaining-step" [card]="cardNotHoverableConfig"
                (click)="checkForWinnerPopup()">
                <ng-container card-type="content">
                    <div class="dashboard-card">
                        <ng-container *ngIf="
                                !curentProject.ended;
                                else endedProjectMessage
                            ">
                            <h2 class="card-text">
                                {{ 'user.dashboard.youHave' | translate }}
                                <b [class.warning-days]="
                                        daysRemainingOnProject < 3
                                    ">
                                    {{ daysRemainingOnProject }}
                                    {{ computeDayOrDays() | translate }}
                                </b>
                                {{ computeRemainedText() | translate }}
                            </h2>
                        </ng-container>

                        <ng-template #endedProjectMessage>
                            <h2 class="card-text">
                                {{
                                nextStepMessageForEndedProjectTranslation
                                | translate
                                }}
                            </h2>
                        </ng-template>
                        <ng-container *ngIf="daysRemainingOnProject < 3">
                            <img src="../../../assets/images/clock_orange.svg" />
                        </ng-container>
                        <ng-container *ngIf="daysRemainingOnProject > 2">
                            <img src="../../../assets/images/clock.svg" />
                        </ng-container>
                    </div>
                </ng-container>
            </furban-app-card>

            <ng-container *ngIf="curentProject.allowCitizenDesigns; else giveOpinion">
                <furban-app-card class="div2" [disabled]="curentProject.ended || curentProject.hasWinner"
                    [card]="cardConfig" id="start-step" (click)="openProject()">
                    <ng-container card-type="content">
                        <div class="dashboard-card green">
                            <h2 class="card-text white">
                                {{ 'user.dashboard.start' | translate }}
                            </h2>
                            <img src="../../../assets/images/tree.svg" />
                        </div>
                    </ng-container>
                </furban-app-card>
            </ng-container>
            <ng-template #giveOpinion>
                <furban-app-card class="div2" [disabled]="curentProject.ended || curentProject.hasWinner"
                    [card]="cardConfig" id="start-step" (click)="navigateToDP()">
                    <ng-container card-type="content">
                        <div class="dashboard-card green">
                            <h2 class="card-text white">
                                {{ 'user.dashboard.giveOpinion' | translate }}
                            </h2>
                            <img src="../../../assets/images/tree.svg" />
                        </div>
                    </ng-container>
                </furban-app-card>
            </ng-template>
            <!-- <div class="div-special">
            <furban-conversation>
            </furban-conversation>
        </div> -->

            <furban-app-card class="div3" [card]="whiteCardConfig"
                [backgroundImage]="'../../../assets/images/location.jpg'" id="location-step"
                (click)="openProjectInfoPopup()">
                <div card-type="footer">
                    {{ 'user.dashboard.location' | translate }}
                </div>
            </furban-app-card>

            <ng-container *ngIf="isCitizenOrCollaborator">
                <furban-app-card class="div4" [card]="cardNotHoverableConfig">
                    <ng-container card-type="content">
                        <furban-poll [project]="curentProject" class="dashboard-card"></furban-poll>
                    </ng-container>
                </furban-app-card>
            </ng-container>

            <ng-container *ngIf="isCitizenOrCollaborator">
                <furban-app-card class="div5 disabled" id="talkToExpert-step" [disabled]="true" [card]="whiteCardConfig"
                    [backgroundImage]="'../../../assets/images/chat_expert.jpg'" (click)="openExpertsPopup()">
                    <div card-type="footer">
                        {{ 'user.chat.chatDashboardText' | translate }}
                    </div>
                </furban-app-card>
            </ng-container>

            <furban-app-card class="div6" [card]="cardNotHoverableConfig">
                <ng-container card-type="content" *ngIf="
                        curentProject !== null && curentProject.projectId !== null
                    ">
                    <furban-designs-viewer [project]="curentProject" (currentDp)="setCurrentDesignProposal($event)"
                        class="dashboard-card">
                    </furban-designs-viewer>
                </ng-container>
            </furban-app-card>
        </div>
    </ng-container>
</div>