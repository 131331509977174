<div
    class="pin-popup"
    [class.open-emoji]="emojiOpened"
    [class.white-container]="displayOnlyText"
>
    <ng-container *ngIf="!displayOnlyText; else dispalyedComment">
        <furban-comment-form
            #commentForm
            [style.margin-top.px]="8"
            [designProposal]="dp"
            [permitProjectId]="permitProjectId"
            [hidePinBtn]="true"
            [isPinComment]="true"
            [position]="position"
            [rotation]="rotation"
            (toggleEmojiEvent)="onToggleEmoji($event)"
            (saveCommentEvent)="onSaveComment($event)"
        >
        </furban-comment-form>
    </ng-container>

    <ng-template #dispalyedComment>
        <p class="full-width word-break-normal">
            {{ comment.commentText }}
        </p>
    </ng-template>
</div>
<button class="ghost-button" (click)="closeDialog()">
    <span class="material-icons"> close </span>
</button>
