
import {
    NotificationService,
    FullNotification,
    NotificationTypeEnum,
    CommentNotification,
    NotificationModel,
    TutorialsService,
} from '@furban/utilities';
import { mergeMap, startWith } from 'rxjs/operators';
import { interval } from 'rxjs';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
    public notificationsNumber: number;
    public maxNumberOfNotifications: number;
    public observableRef: any;
    public openedNotifications = false;
    public notificationList: FullNotification[] = [];
    public page = 0;
    public curentNumberOfList = 0;
    public loaded = false;
    private previousPage = -1;

    constructor(
        private notificationsService: NotificationService,
        private tutorialService: TutorialsService,
        private translateService: TranslateService
    ) { }

    @HostListener('document:click', ['$event'])
    clickOutsideNotificationsDropdown(event) {
        const menu = document.getElementById('notificationPanel');

        if (menu && !menu.contains(event.target)) {
            this.page = 0;
            this.openedNotifications = false;
            this.unloadNotificationList();
        }
    }
    ngOnInit() {
        const timeInterval = 10 * 60 * 1000;
        this.observableRef = interval(timeInterval)
            .pipe(
                startWith(0),
                mergeMap(() =>
                    this.notificationsService.getNotificationsCount()
                )
            )
            .subscribe((data) => (this.notificationsNumber = data));
    }

    ngOnDestroy() {
        this.observableRef.unsubscribe();
    }

    initialiseNotifications() {
        if (this.notificationList.length > 0) {
            this.unloadNotificationList();
        }
        this.notificationsService
            .countAllNotificationsForUser()
            .subscribe((data) => {
                this.maxNumberOfNotifications = data;
                this.getNotifications();
            });
    }

    unloadNotificationList() {
        this.notificationList = [];
        this.loaded = false;
        this.page = 0;
        this.previousPage = -1;
    }

    toggleNotifications() {
        if (!this.openedNotifications) {
            this.initialiseNotifications();
            this.tutorialService.closeTutorialMenu();
        } else {
            this.unloadNotificationList();
        }
        this.openedNotifications = !this.openedNotifications;
    }

    getNotifications() {
        if (this.previousPage !== this.page) {
            this.notificationsService.getNotifications(this.page).subscribe(
                {
                    next: (data) => {
                        this.previousPage = this.page;
                        this.notificationList = this.notificationList.concat(data);
                        this.loaded = true;
                    },
                    complete: () => {
                        this.page = this.page - 1;

                    }

                });
        } else {
            this.initialiseNotifications();
        }
    }

    onScrollDown() {
        if (this.notificationList.length < this.maxNumberOfNotifications) {
            this.page = this.page + 1;
            this.getNotifications();
        }
    }

    markNotificationAsRead(fullNotification: FullNotification) {
        if (fullNotification.userNotification.isRead) {
            return;
        }

        this.notificationsService
            .readNotification(fullNotification.userNotification)
            .subscribe((data) => {
                this.notificationsNumber = this.notificationsNumber - 1;
                fullNotification.userNotification.isRead = true;
            });
    }

    returnIcon(item: NotificationModel) {
        if (
            item.notificationType.notificationTypeId ===
            NotificationTypeEnum.project
        ) {
            return 'local_florist';
        } else if (
            item.notificationType.notificationTypeId ===
            NotificationTypeEnum.municipality
        ) {
            return 'location_city';
        } else if (
            item.notificationType.notificationTypeId ===
            NotificationTypeEnum.system
        ) {
            return 'dns';
        } else if (
            item.notificationType.notificationTypeId ===
            NotificationTypeEnum.user
        ) {
            return 'add_comment';
        }
    }

    returnNotificationTypeAsString(item: FullNotification) {
        let notificationString =
            this.notificationsService.returnNotificationTypeAsString(
                item.notification
            );

        if (
            item.notification.notificationType.notificationTypeId ===
            NotificationTypeEnum.project
        ) {
            notificationString =
                notificationString + ' | ' + item.notification.targetName;
        }
        return notificationString;
    }

    areNotificationsOpenedAndLoadedAndExist(): boolean {
        return (
            this.loaded &&
            this.openedNotifications &&
            this.notificationList &&
            this.notificationList.length > 0
        );
    }

    getNotificationMessage(notification: NotificationModel) {
        if (
            notification.notificationType.notificationTypeId ===
            NotificationTypeEnum.user
        ) {
            const commentNotification = JSON.parse(notification.message)
                .commentNotification as CommentNotification;
            return this.getCommentNotification(commentNotification);
        } else {
            return notification.message;
        }
    }

    private getCommentNotification(commentNotification: CommentNotification) {
        const message = commentNotification.isReply
            ? `${this.translateService.instant(
                'notifications.userNotificationReplyMessage'
            )}`
            : `${this.translateService.instant(
                'notifications.userNotificationMessage'
            )}`;
        return `${commentNotification.senderName} ${message} ${commentNotification.commentSourceName}`;
    }
}
