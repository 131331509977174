<div
    class="main-container padding-40-32"
    infiniteScroll
    [infiniteScrollThrottle]="300"
    (scrolled)="onScrollDown()"
    [scrollWindow]="false"
>
    <div class="flex-row-start">
        <span
            class="material-icons"
            matTooltip="{{ 'buttonsTooltips.goToDashboard' | translate }}"
            [style.margin-right.px]="8"
            (click)="goToDashboard()"
        >
            chevron_left
        </span>
        <h3 class="page-title">
            {{ 'user.publish.designProposal' | translate }}
        </h3>
    </div>

    <furban-app-design-proposals
        *ngIf="project"
        #designProposalsComponent
        [project]="project"
        [disabledActions]="project.ended"
        [viewContainerRef]="viewContainerRef"
    >
    </furban-app-design-proposals>

    <ng-container *ngIf="!project">
        <div class="flex-column-center padding-20">
            <img
                src="../../../assets/images/no_design.svg"
                [style.height.px]="300"
            />
            <h4 class="placeholder-text">
                {{ 'user.dashboard.noDesignsMessage' | translate }}
            </h4>
        </div>
    </ng-container>
</div>
