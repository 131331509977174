export const routingConstants = {
    info: '/',
    website: 'info',
    landing: 'landing',
    disclaimer: 'disclaimer',
    publicEntryPage: 'public/entry-page',
    takingPart: 'find-projects',
    takingPartDesign: 'find-projects/design-proposal',
    publicProject: 'public/project',
    publicDesignProposal: 'public/design-proposal',
    publicFurbanCity: 'public/furban-city',
    publicPageDesign: 'embeded/design',
    publicPageProject: 'embeded/project',
    publicPermitDetails: 'public/permit',
    app: '/app',
    justApp: 'app',
    gdpr: 'app/gdpr',
    faq: '/app/faq',
    chat: '/app/chat',
    designs: '/app/designs',
    neighborDashboard: '/app/permit-dashboard',
    project: '/app/project',
    collaborative: '/app/collaborative-design',
    process: 'app/partner/process',
    initiativeProcess: 'app/partner/initiatives/process',
    dashboard: '/app/dashboard',
    collaboratorDashboard: '/app/design',
    statistics: '/app/statistics',
    designProposals: '/app/design-proposal',
    initiatives: '/app/partner/initiatives',
    initiativesProjectNew: '/app/partner/initiatives/process/new/(project:details)',
    initiativesProjectList: '/app/partner/initiatives/projects',
    initiativesProject: 'app/partner/initiatives/initiative-project',
    pioneerDesignProposals: 'app/partner/initiatives/design-proposal',
    permitProject: 'app/partner/permit',
    permitProjectNew: 'app/partner/permit/new/(permit-project:details)',
    permitRequests: 'app/partner/permit-requests',
    registerPioneer: '/app/partner/initiatives/register',
    admin: '/app/partner',
    adminRegister: '/app/partner/register',
    adminNew: '/app/partner/new',
    adminFaq: '/app/partner/faq',
    adminPoll: '/app/partner/poll',
    adminCodes: '/app/partner/codes',
    adminRecover: '/app/partner/recover',
    adminProject: '/app/partner/project',
    adminStatistics: '/app/partner/statistics',
    adminDashboard: '/app/partner/dashboard',
    adminSuppliers: '/app/partner/suppliers',
    adminRequests: '/app/partner/requests',
    adminBackground: '/app/partner/background',
    adminMunicipality: '/app/partner/municipality',
    adminNotifications: '/app/partner/notifications',
    adminDesignProposals: '/app/partner/design-proposal',
    adminViewPermit: '/app/partner/permit-project',
    registerAsCitizen: 'app/register',
    collaborativeSessions: 'collaborative-design',
};
