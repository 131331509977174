export enum ToolingActionsEnum {
    saveDesign = 'saveDesign',
    changeCameraPerspective = 'changeCameraPerspective',
    publish = 'publish',
    switchFullScreenMode = 'switchFullScreenMode',
    switchSafetyArea = 'switchSafetyArea',
    toggleGridView = 'toggleGridView',
    exportSceneToGLTF = 'exportSceneToGLTF',
    restartDesign = 'restartDesign',
    undoDelete = 'undoDelete',
    drawPolygon = 'drawPolygon',
    resetMap = 'resetMap',
    takeScreenshot = 'takeScreenshot',
    takeDefaultDesignScreenshot = 'takeDefaultScreenshot',
    savePolygon = 'savePolygon',
    saveObjects = 'saveObjects',
    saveProjectDetails = 'saveProjectDetails',
    createGroup = 'createGroup',
    selectGroup = 'selectGroup',
    generateGroup = 'generateGroup',
    toggleTransparency = 'toggleTransparency',
    undo = 'undo',
    redo = 'redo',
    upload = 'upload',
    addObjects = 'addObjects',
    generateObjects = "generateObjects",
    objectsInLine = "objectsInLine",
    multiselect = "multiselect",
    freeshape = 'freeshape',
    viewFromTheTop = 'viewFromTheTop',
    navigateBack = 'navigateBack'
}
