import { Node } from 'd3';

export class BubbleChartUtils {
    public static getFontSize(value: number, min: number, max: number) {
        const minPx = 6;
        const maxPx = 24;
        const pxRange = maxPx - minPx;
        const dataRange = max - min;
        const ratio = pxRange / dataRange;
        return Math.min(maxPx, Math.round(value * ratio) + minPx);
    }

    public static truncateBubbleLabel(nodeLabel: string, node: Node) {
        const lengthAllowed: number = Math.ceil(
            (node.r * 2) / node.data.style.fontSize
        );
        return nodeLabel.length > lengthAllowed
            ? `${nodeLabel.slice(0, lengthAllowed)}...`
            : nodeLabel;
    }
}
