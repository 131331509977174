import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';

import {
    AuthService,
    CategoryObject,
    FurbanUtil,
    ObjectTypeEnum,
    Project,
    Project3dModel,
    routingConstants,
    ShepherdTourService,
} from '@furban/utilities';

import { environment } from 'apps/furban-client/src/environments/environment';
import { PublishService } from '../../publish-popup/publish.service';

@Component({
    selector: 'furban-base-project-menu',
    templateUrl: './base-project-menu.component.html',
    styleUrls: ['./base-project-menu.component.scss'],
})
export class BaseProjectMenuComponent {
    @Input() project: Project;
    @Input() objects: CategoryObject[];
    @Input() freeShapeDrawing: boolean;
    @Input() availableBudget: number;
    @Output() removeLastUnfinishedFreeShape: EventEmitter<any> =
        new EventEmitter();
    @Output() addedObject: EventEmitter<any> = new EventEmitter();

    public defaultImageURL = 'assets/images/default-project.png';
    public isSingleProject = false;

    public shapes = [
        ObjectTypeEnum.freeshape,
        ObjectTypeEnum.square,
        ObjectTypeEnum.elipse,
    ];
    public sortedObjects: Map<string, Project3dModel[]>;
    public composeImageString = FurbanUtil.composeImageString;

    constructor(
        public authService: AuthService,
        public publishService: PublishService,
        public shepherdTourService: ShepherdTourService,
        public router: Router
    ) { }

    public getMedia(): string {
        return this.project.media
            ? `${environment.apiPath}/media/public/${this.project.media}`
            : '../../../assets/images/default-project.png';
    }

    public goBackToDashboard() {
        this.router.navigate([routingConstants.dashboard]);
    }

    public isAdmin(): boolean {
        return this.authService.isAdmin();
    }

    public isPioneer(): boolean {
        return this.authService.isPioneer();
    }
}
