import { GeneralComment } from './general-comment';
import { UserProfile } from './user-profile';

export class PermitComment extends GeneralComment {
    public projectId: string;
    public highlighted: boolean;

    constructor(
        projectId?: string,
        userProfile?: UserProfile,
        position?: string,
        rotation?: string,
        text?: string
    ) {
        super();
        this.projectId = projectId;
        this.userProfile = userProfile;
        this.position = position;
        this.rotation = rotation;
        this.commentText = text;
    }
}
