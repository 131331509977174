import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'apps/furban-client/src/environments/environment';
import {
    Poll,
    PollOption,
    SkipErrorHttpParam,
    PollAnswer,
} from '@furban/utilities';

@Injectable({
    providedIn: 'root',
})
export class AdminPollService {
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );
    private _headersNoCache = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .append(
            'Cache-Control',
            'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
        )
        .append('Pragma', 'no-cache')
        .append('Vary', '*')
        .append('Expires', '0');

    constructor(private http: HttpClient) {}

    getActivePolls(projectId): Observable<Poll[]> {
        return this.http
            .get(`${environment.apiPath}/poll/active/${projectId}`, {
                headers: this._headers,
                params: new SkipErrorHttpParam(true),
            })
            .pipe(map((result) => result as Poll[]));
    }

    getPolls(projectId): Observable<Poll[]> {
        return this.http
            .get(`${environment.apiPath}/poll/all/${projectId}`, {
                headers: this._headers,
                params: new SkipErrorHttpParam(true),
            })
            .pipe(map((result) => result as Poll[]));
    }

    getPoll(pollId): Observable<Poll> {
        return this.http
            .get(`${environment.apiPath}/poll/${pollId}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Poll));
    }

    getPollsFromProject(projectId): Observable<Poll[]> {
        return this.http
            .get(`${environment.apiPath}/poll/project/${projectId}`, {
                headers: this._headersNoCache,
                params: new SkipErrorHttpParam(true),
            })
            .pipe(map((result) => result as Poll[]));
    }

    createPoll(poll: Poll): Observable<Poll> {
        return this.http
            .put(environment.apiPath + '/poll', poll, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Poll));
    }

    savePoll(poll: Poll): Observable<Poll> {
        return this.http
            .post(environment.apiPath + '/poll', poll, {
                headers: this._headers,
            })
            .pipe(map((result) => result as Poll));
    }

    saveOptions(pollOptions: PollOption[]): Observable<PollOption[]> {
        return this.http
            .post(environment.apiPath + '/poll/options', pollOptions, {
                headers: this._headers,
            })
            .pipe(map((result) => result as PollOption[]));
    }

    saveAnswer(pollAnswer: PollAnswer): Observable<PollAnswer> {
        return this.http
            .post(environment.apiPath + '/poll/answer', pollAnswer, {
                headers: this._headers,
            })
            .pipe(map((result) => result as PollAnswer));
    }

    getAnswer(pollId): Observable<PollAnswer> {
        return this.http
            .get(`${environment.apiPath}/poll/answer/${pollId}`, {
                headers: this._headers,
                params: new SkipErrorHttpParam(true),
            })
            .pipe(map((result) => result as PollAnswer));
    }
}
