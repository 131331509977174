<div class="floating-input floating-buttons bottom left"
     [style.margin-bottom.px]="24">
    <button class="round-button"
            matTooltip="{{ 'buttonsTooltips.goToCurentLocation' | translate }}"
            (click)="goToCurentLocation()">
        <span class="material-icons">gps_fixed</span>
    </button>
    <furban-address-search (changeAddress)="changeAddress($event)"></furban-address-search>
</div>
<section class="floating-buttons right">
    <button class="primary-button"
            matTooltip="{{ 'buttonsTooltips.save' | translate }}"
            (click)="savePolygons()">
        <h6>{{ 'generic.save' | translate }}</h6>
    </button>

    <button class="round-button"
            matTooltip="{{ 'buttonsTooltips.resetMapDrawing' | translate }}"
            [disabled]="redrawButtonDisabled"
            (click)="resetMap()">
        <span class="material-icons">replay</span>
    </button>
</section>
<section id="mapViewer"
         class="map zoom-top"></section>