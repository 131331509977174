import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'apps/furban-client/src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
    Group,
    Project,
    FurbanUtil,
    SkipErrorHttpParam,
} from '@furban/utilities';

@Injectable({
    providedIn: 'root',
})
export class GroupsService {
    public groups: Group[];
    private headers = new HttpHeaders().set('Content-Type', 'application/json');
    private _options = {
        headers: new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
        ),
    };
    constructor(private http: HttpClient) {}

    getGroupByGroupId(groupId: string): Observable<Group> {
        return this.http
            .get(environment.apiPath + `/group/${groupId}`)
            .pipe(map((result) => result as Group));
    }

    getGroupsByClientId(): Observable<Group[]> {
        return this.http.get(environment.apiPath + '/group').pipe(
            map((result) => {
                const groups = result as Group[];
                this.groups = groups;
                return groups;
            })
        );
    }

    saveGroup(group: Group): Observable<Group> {
        return this.http
            .post(environment.apiPath + '/group', group, {
                headers: this.headers,
            })
            .pipe(map((result) => result as Group));
    }

    deleteGroups(groups: Group[]) {
        const groupIds = [];
        for (const group of groups) {
            groupIds.push(group.groupId);
        }

        return this.http
            .post(environment.apiPath + '/group/delete', groupIds, {
                headers: this.headers,
            })
            .pipe(map((result) => result));
    }

    getGroupsByProjectId(projectId: string): Observable<Group[]> {
        return this.http
            .get(`${environment.apiPath}/group/projects/${projectId}`, {
                headers: this.headers,
            })
            .pipe(map((result) => result as Group[]));
    }

    assignGroupToProject(projectId: string, groupId: string) {
        return this.http
            .post(
                environment.apiPath + '/project/groups',
                this.getParamObjectEncoded(projectId, groupId),
                this._options
            )
            .pipe(map((result) => result));
    }

    unassignGroupToProject(projectId: string, groupId: string) {
        return this.http
            .post(
                environment.apiPath + '/project/groups/delete',
                this.getParamObjectEncoded(projectId, groupId),
                this._options
            )
            .pipe(map((result) => result));
    }

    createGroupWithUsers(group: Group, projectId: string): Observable<Group> {
        return this.http
            .post(`${environment.apiPath}/group/create/${projectId}`, group)
            .pipe(map((result) => result as Group));
    }

    filterGroupsByGroupName(groupName: string) {
        const filterdGroups: Group[] = [];
        this.groups.forEach((group) => {
            if (
                group.groupName
                    .toLowerCase()
                    .indexOf(groupName.trim().toLowerCase()) > -1
            ) {
                filterdGroups.push(group);
            }
        });

        return filterdGroups;
    }

    getProjectsByGroupId(groupId: string): Observable<Project[]> {
        return this.http
            .get(`${environment.apiPath}/project/groups/${groupId}`, {
                params: new SkipErrorHttpParam(true),
            })
            .pipe(map((result) => result as Project[]));
    }

    getProjectIdsByGroupId(groupId: string): Observable<string[]> {
        return this.http
            .get(`${environment.apiPath}/project/ids/${groupId}`, {
                params: new SkipErrorHttpParam(true),
            })
            .pipe(map((result) => result as string[]));
    }

    private getParamObjectEncoded(projectId: string, groupId: string): string {
        const paramObject: any = {
            projectId: projectId,
            groupId: groupId,
        };
        return FurbanUtil.encodeObjectURIComponents(paramObject);
    }
}
