export enum ProjectStatusEnum {
    created = 0,
    published = 1,
    unpublished = 2,
    archived = 3,
}

export enum ProjectStatusNameEnum {
    created = 'created',
    published = 'published',
    unpublished = 'unpublished',
    archived = 'archived',
}
