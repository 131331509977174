import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ProjectStepsArray } from '../_constants/project-steps-array';
import { routingConstants } from '../_constants/routing-constants';
import { PermitStepStatusEnum } from '../_enum/permit-step-status.enum';
import { PermitProject } from '../_models/permit-project';
import { Step } from '../_models/step';
import { Project } from '../_models/project';
import { ProjectChange } from '../_models/project-change';
import { ProjectTypeNameEnum } from '../_enum/project-type.enum';
import { ProjectStatusEnum } from '../_enum/project-status.enum';

@Injectable({
    providedIn: 'root',
})
export class StepperService {
    public projectModifiedState = new BehaviorSubject(false);
    public projectModifiedStateObservable =
        this.projectModifiedState.asObservable();
    public stepsChanged: EventEmitter<Step[]>;
    public currentStepChanged: EventEmitter<Step>;
    public projectChanged: EventEmitter<ProjectChange>;
    public isModified = false;

    private _project: PermitProject | Project;
    private _projectId: string;
    private _steps: Step[];
    private _currentStep: Step;
    private _projectType: string;

    constructor(private router: Router) {
        this.stepsChanged = new EventEmitter<Step[]>();
        this.currentStepChanged = new EventEmitter<Step>();
        this.projectChanged = new EventEmitter<ProjectChange>();
    }

    public get steps(): Step[] {
        return this._steps;
    }

    public set steps(value: Step[]) {
        this._steps = value;
        this.stepsChanged.emit(this._steps);
    }

    public get currentStep(): Step {
        return this._currentStep;
    }

    public set currentStep(value: Step) {
        this._currentStep = value;
        this.currentStepChanged.emit(this._currentStep);
    }

    public get projectId(): string {
        return this._projectId ? this._projectId : null;
    }

    public set projectId(value: string) {
        this._projectId = value;
    }

    public get project(): PermitProject | Project {
        return this._project;
    }

    public set project(value: PermitProject | Project) {
        this._project = value;
    }

    public get projectType(): string {
        return this._projectType;
    }

    public set projectType(value: string) {
        this._projectType = value;
    }

    public get isProjectPublished(): boolean {
        const currentProject = this.project as Project;
        if (!currentProject || !currentProject.projectStatus) {
            return false;
        }
        return (
            currentProject.projectStatus.statusWeight ===
            ProjectStatusEnum.published ||
            currentProject.projectStatus.statusWeight ===
            ProjectStatusEnum.archived
        );
    }

    public redirectToNextStep() {
        const nextStep = this._steps.find(
            (step) => step.id === this.currentStep.id + 1
        );
        if (!nextStep) {
            const homeRoute = `${routingConstants.permitProject}/${this._projectId}/(permit-project:overview)`;
            this.router.navigateByUrl(homeRoute);
            return;
        }
        this.navigateToStep(nextStep);
    }

    public navigateToStep(value: Step) {
        if (value.status === PermitStepStatusEnum.untouched) {
            return;
        }
        this.router.navigateByUrl(value.path);
    }

    public regeneratePermitSteps(
        project: PermitProject,
        redirectToNextStep?: boolean
    ) {
        this.project = project;
        this.steps = ProjectStepsArray.createPermitSteps(
            project as PermitProject
        );
        if (redirectToNextStep) {
            this.redirectToNextStep();
        }
    }

    public regenerateMunicipalitySteps(
        project: Project,
        redirectToNextStep?: boolean
    ) {
        this.project = project;
        this.steps = ProjectStepsArray.createGeneralProjectSteps(
            project as Project
        );
        if (redirectToNextStep) {
            this.redirectToNextStep();
        }
    }

    public regenerateInitiativeSteps(
        project: Project,
        redirectToNextStep?: boolean
    ) {
        this.project = project;
        this.steps = ProjectStepsArray.createInitiativeProjectSteps(
            project as Project
        );
        if (redirectToNextStep) {
            this.redirectToNextStep();
        }
    }

    public changeProjectModifiedState(newState: boolean) {
        this.projectModifiedState.next(newState);
    }

    public modifyCurrentStepId(redirect: boolean) {
        const project = this._project;

        if (
            !project ||
            !this._currentStep ||
            (project.currentStepId >= this._currentStep.id && !redirect)
        ) {
            return;
        }

        project.currentStepId = this._currentStep.id;

        const projectChanged = new ProjectChange();
        projectChanged.project = project;
        projectChanged.redirect = redirect;

        this.projectChanged.emit(projectChanged);
    }

    public resetCurrentStepIdForProject(stepId: number): void {
        const project = this._project;
        project.currentStepId = stepId;
        const projectChanged = new ProjectChange();
        projectChanged.project = project;
        this.projectChanged.emit(projectChanged);
    }

    public resetProject() {
        this.projectId = null;
        this.project = null;
        this.currentStep = null;
    }

    public getNextStep() {
        return this._steps.find((step) => step.id === this.currentStep.id + 1);
    }

    public generateStepsWithStatus(projectTypeName: string) {
        switch (projectTypeName) {
            case ProjectTypeNameEnum.pioneerPermitProject:
                this._steps = ProjectStepsArray.createPermitSteps(
                    this._project as PermitProject
                );
                break;
            case ProjectTypeNameEnum.pioneerInitiativeProject:
                this._steps = ProjectStepsArray.createInitiativeProjectSteps(
                    this._project as Project
                );
                break;
            case ProjectTypeNameEnum.adminProject:
                this._steps = ProjectStepsArray.createGeneralProjectSteps(
                    this._project as Project
                );
                break;
        }
        this._currentStep = this._steps.find(
            (step) => `/${step.path}` === this.router.url
        );
        if (
            !this._currentStep &&
            !this.router.url.includes('project:overview')
        ) {
            this.router.navigateByUrl(routingConstants.admin);
        }
    }
}
