<div class="main-container overflow" infiniteScroll [infiniteScrollThrottle]="300" (scrolled)="onParentScrollDown()"
    [scrollWindow]="false" [style.margin-top.px]="64">
    <section class="padding-0-32">
        <div class="flex-row-spaced wrap padding-12-0">
            <h4 [style.margin-right.px]="16">
                {{ codesLength }} {{ 'admin.codes.codesGenerated' | translate }}
            </h4>
            <section class="flex-row-end">
                <button *ngIf="!selection.isEmpty()" class="red-button" [style.margin-right.px]="20"
                    (click)="deleteCodes()">
                    <span class="material-icons" [style.margin-right.px]="8">
                        delete_forever
                    </span>
                    <h6>
                        {{ 'admin.projectCodes.deleteCodes' | translate }}
                        {{ selection.selected.length }} codes
                    </h6>
                </button>
                <button *ngIf="showResetButton()" class="secondary-button" [style.margin-right.px]="20"
                    (click)="resetFilters()">
                    <h6>{{ 'admin.projectCodes.resetFilters' | translate }}</h6>
                </button>
                <ng-container *ngIf="!isApp()">
                    <button class="secondary-button" (click)="createCSV()" value="citizenExportCsv">
                        <h6>{{ 'admin.projectCodes.button1' | translate }}</h6>
                    </button>
                </ng-container>
            </section>
        </div>

        <table mat-table class="full-width" [dataSource]="dataSource">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="
                            selection.hasValue() && !isAllSelected()
                        ">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Code Column -->
            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="flex-row-start relative">
                        {{ 'admin.projectCodes.table.collumn2' | translate }}
                        <span id="code" class="material-icons filter-icon" [class.active]="
                                checkAppliedFilter(1) ||
                                codesFilter.isCodeSearch
                            " (click)="showFilter(1)">filter_list</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">{{ row.username }}
                    <ng-container *ngIf="isApp()">
                        <span class="material-icons" (click)="onCodeCopy(row.username)"> content_copy </span>
                    </ng-container>
                </td>
            </ng-container>

            <!-- Created Date Column -->
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="flex-row-start relative">
                        {{ 'admin.projectCodes.table.collumn4' | translate }}
                        <span id="date" class="material-icons filter-icon" [class.active]="
                                checkAppliedFilter(2) ||
                                codesFilter.isDateFiltering
                            " (click)="showFilter(2)">filter_list</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.createdDate | date : 'dd MMMM yyyy' }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <ng-container *ngIf="!codes || (codes && codes.length === 0)">
            <div class="flex-column-center padding-20">
                <img src="../../../assets/images/no_data.svg" [style.height.px]="280" />
                <h4 class="placeholder-text">
                    {{ 'admin.projectCodes.noCodesForDates' | translate }}
                </h4>
            </div>
        </ng-container>

        <ng-container *ngIf="checkAppliedFilter(1)">
            <furban-table-filter [title]="'admin.projectCodes.searchCode'"
                [buttonText]="'admin.projectCodes.searchCode'" [filterId]="'code'"
                [disableAction]="!userCode.value || userCode.invalid" (cancelCallback)="showFilter(0)"
                (okCallback)="findByCode()">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label> Code </mat-label>
                    <input matInput placeholder="Code" maxlength="40" [formControl]="userCode" />
                    <mat-error *ngIf="hasControlError(userCode,'isHTML')">
                        {{ 'comments.noHTMLContent' | translate }}
                    </mat-error>
                </mat-form-field>
            </furban-table-filter>
        </ng-container>
        <ng-container *ngIf="checkAppliedFilter(2)">
            <furban-table-filter [title]="'admin.projectCodes.filterByDate'"
                [buttonText]="'admin.projectCodes.filterButton'" [filterId]="'date'" [disableAction]="
                    startDate.value &&
                    endDate.value &&
                    endDate.value < startDate.value
                " (cancelCallback)="showFilter(0)" (okCallback)="filter('isDateFiltering')">
                <div class="flex-row-spaced full-width relative">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>
                            {{
                            'admin.projectDetails.chooseStartDate'
                            | translate
                            }}
                        </mat-label>
                        <input matInput readonly placeholder="{{
                                'admin.projectDetails.chooseStartDate'
                                    | translate
                            }}" [matDatepicker]="picker1" [formControl]="startDate" (click)="picker1.open()" />
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                    <span class="material-icons date-close" (click)="startDate.reset()"
                        *ngIf="startDate.value !== null">close</span>
                </div>

                <div class="flex-row-spaced full-width relative">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>
                            {{
                            'admin.projectDetails.chooseEndDate' | translate
                            }}
                        </mat-label>
                        <input matInput readonly placeholder="{{
                                'admin.projectDetails.chooseEndDate' | translate
                            }}" [matDatepicker]="picker2" [formControl]="endDate" (click)="picker2.open()" />
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                    <span class="material-icons date-close" (click)="endDate.reset()"
                        *ngIf="endDate.value !== null">close</span>
                </div>
            </furban-table-filter>
        </ng-container>
    </section>
</div>