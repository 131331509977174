<ng-container *ngIf="isCitizenOrExpert">
    <div class="header-space-between">
        <ng-container *ngIf="shouldDisplayTooling()">
            <furban-tooling id="left-tooling-step">
                <ng-container *ngIf="toolingVisibility.editMode" buttons-type="main">
                    <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.addObjects' | translate }}"
                        (click)="onButtonClick(toolingActions.addObjects)">
                        <mat-icon svgIcon="add-objects-icon"
                            [class.active]="checkActiveButton(toolingActions.addObjects)"></mat-icon>
                    </button>

                    <ng-container *ngIf="!toolingVisibility.isPublished">
                        <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.generateObjects' | translate }}"
                            [class.active]="checkActiveButton(toolingActions.generateObjects)"
                            (click)="onButtonClick(toolingActions.generateObjects)">
                            <span class="material-icons">category</span>
                        </button>
                        <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.objectsInLine' | translate }}"
                            [class.active]="checkActiveButton(toolingActions.objectsInLine)"
                            (click)="onButtonClick(toolingActions.objectsInLine)">
                            <span class="material-icons-outlined">auto_graph</span>
                        </button>
                        <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.multiselect' | translate }}"
                            [class.active]="checkActiveButton(toolingActions.multiselect)"
                            (click)="onButtonClick(toolingActions.multiselect)">
                            <span class="material-icons">select_all</span>
                        </button>

                        <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.freeshape' | translate }}"
                            [class.active]="checkActiveButton(toolingActions.freeshape)"
                            (click)="onButtonClick(toolingActions.freeshape)">
                            <span class="material-icons">gesture</span>
                        </button>
                    </ng-container>
                </ng-container>
            </furban-tooling>
        </ng-container>
        <furban-tooling *ngIf="shouldDisplayTooling()" id="right-menu-options-step" class="tooling-right-container">
            <ng-container buttons-type="main">


                <ng-container *ngIf="!toolingVisibility.isPublished && !isProjectEnded() else isPublisedDesignButtons">
                    <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.reset' | translate }}"
                        [class.inactive]="isProjectEnded()" (click)="callToolingAction(toolingActions.restartDesign)">
                        <span class="material-icons">replay</span>
                    </button>
                    <button *ngIf="
                              toolingVisibility.editMode &&
                              toolingVisibility.state !== threeStateEnum.screenshot" class="tooling-button"
                        matTooltip="{{ 'buttonsTooltips.safetyArea' | translate }}"
                        (click)="callToolingAction(toolingActions.switchSafetyArea)">
                        <span class="material-icons">
                            {{toolingVisibility.isSafetyEnabled? 'leak_remove': 'leak_add'}}
                        </span>
                    </button>
                    <button *ngIf="toolingVisibility.editMode && !isIOS" class="tooling-button"
                        matTooltip="{{ 'buttonsTooltips.changeGridView' | translate }}"
                        (click)="callToolingAction(toolingActions.toggleGridView)">
                        <span class="material-icons">grid_on</span>
                    </button>
                    <ng-container *ngIf="!isApp()">
                        <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.exportDesign' | translate }}"
                            (click)="callToolingAction(toolingActions.exportSceneToGLTF)">
                            <span class="material-icons">cloud_download</span>
                        </button>
                    </ng-container>
                    <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.toggleTransparency' | translate }}"
                        (click)="callToolingAction(toolingActions.toggleTransparency)">
                        <span class="material-icons">{{toolingVisibility.isTransparencyEnabled ? 'layers_clear':
                            'layers'}}
                        </span>
                    </button>
                    <!-- <button class="round-button"
                            matTooltip="{{'buttonsTooltips.change3Dperspective' | translate}}"
                            id="change-perspective-step"
                            (click)="callToolingAction(toolingActions.changeCameraPerspective)">
                        <furban-svg-container [svgImage]="svgImageType.changePerspective"></furban-svg-container>
                    </button> -->

                    <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.viewFromTop' | translate }}"
                        (click)="callToolingAction(toolingActions.viewFromTheTop)">
                        <span class="material-icons">
                            vertical_align_top
                        </span>
                    </button>

                    <ng-container *ngTemplateOutlet="changePerspectiveButton"></ng-container>

                    <button *ngIf="toolingVisibility.state === threeStateEnum.screenshot" class="round-button"
                        matTooltip="{{ 'buttonsTooltips.takeScreenshot' | translate }}"
                        [class.pulsate]="toolingVisibility.state === threeStateEnum.screenshot"
                        (click)="callToolingAction(toolingActions.takeScreenshot)">
                        <span class="material-icons">add_a_photo</span>
                    </button>
                    <button class="round-button" [class.disabled]="!isUndoEnabled"
                        (click)="callToolingAction(toolingActions.undo)">
                        <span class="material-icons">undo</span>
                    </button>

                    <button class="round-button" [class.disabled]="!isRedoEnabled"
                        (click)="callToolingAction(toolingActions.redo)">
                        <span class="material-icons">redo</span>
                    </button>
                </ng-container>

            </ng-container>

        </furban-tooling>

        <ng-container *ngIf="!isPioneerOrAdminLoggedIn() && isProjectLoaded() && toolingVisibility.editMode">
            <furban-tooling>
                <ng-container *ngIf="!toolingVisibility.isPublished && !isProjectEnded() else isPublisedDesignButtons"
                    buttons-type="main">
                    <button class="primary-button custom" matTooltip="{{ 'buttonsTooltips.publish' | translate }}"
                        id="publish-step" [class.disabled]="toolingVisibility.isPublished || isProjectEnded()"
                        (click)="callToolingAction(toolingActions.publish)">
                        <h6>{{ 'user.publish.publishBtn' | translate }}</h6>
                    </button>
                </ng-container>
                <ng-template #isPublisedDesignButtons>
                    <button class="secondary-button custom" [class.inactive]="isProjectEnded()"
                        (click)="callToolingAction(toolingActions.restartDesign)">
                        <span class="material-icons">settings_backup_restore</span>
                        <h6>{{ 'buttonsTooltips.restart_design' | translate }}</h6>
                    </button>

                    <ng-container *ngTemplateOutlet="changePerspectiveButton"></ng-container>
                </ng-template>
            </furban-tooling>
        </ng-container>
    </div>
</ng-container>

<div class="flex-row-start" *ngIf="checkIfReady()">
    <furban-project-menu-three-d #sideMenuUserProj class="side-menu-user-proj"
        [class.collapsed]="!menuService.menuOpened" [class.hide]="!isEditMode" [project]="project" [objects]="objects"
        [availableBudget]="availableBudget" [freeShapeDrawing]="freeShapeDrawing"
        [currentlyEnabledTool]="currentlyEnabledTool" (added3DObject)="onObject3DAdd($event)"
        (addMultiple3DObject)="onMultiple3DObjectAdd($event)"
        (removeLastUnfinishedFreeShape)="removeLastUnfinishedFreeShape()" (addRowOfObjects)="onRowOfObjectsAdd()"
        (changedTool)="onToolChange($event)" (addGreenObjects)="onAddGreenObjects($event)"
        (highlightDropingArea)="onHighlightDropingArea($event)">
    </furban-project-menu-three-d>
    <furban-ngrx-three-editor *ngIf="is3dReady && authService.user" #threeJsEditorComponent [state]="threeState"
        [availableBudget]="availableBudget" [project]="project" [modified]="modified" [designProposalId]="dpId"
        (screenshotTaken)="onScreenshotTaken()" (isEditMode)="toggleEdit($event)"
        (projectPriceChange)="onProjectPriceChange($event)" (finishedDrawing)="onFinishedDrawing()"
        (disabledTool)="onToolDisable()" (modify)="modify($event)"
        (removeUploadObjectFromMenu)="onRemoveUpload($event)">
    </furban-ngrx-three-editor>

    <ng-container *ngIf="shouldShowUploadMenu">
        <div class="upload-menu-container">
            <div [class.scroll-hidden]="scrollHidden" [style.padding.px]="20">

                <ng-container *ngIf="checkIfRouteIncludes('project:objects')">
                    <div [style.margin-bottom.px]="20">
                        <h6 class="category-title" [style.margin-bottom.px]="20">
                            {{ 'user.projectMenu.uploadMultilayer' | translate }}
                        </h6>
                        <furban-drag-upload #customObjectUpload [acceptedFormats]="uploadMultilayerAcceptedFormats"
                            [maxSize]="maxSize" [project3DUpload]="customObjectProject3DUpload"
                            (uploadFile)="onFileUpload($event, uploadedObjectTypeEnum.customObject)"
                            (uploadTextureFile)="onObjectTextureUpload($event, uploadedObjectTypeEnum.customObject)"
                            (project3DUploadDelete)="onObjectUploadDelete($event, uploadedObjectTypeEnum.customObject)"></furban-drag-upload>
                    </div>

                    <div [style.margin-bottom.px]="20">
                        <h6 class="category-title" [style.margin-bottom.px]="20">
                            {{ 'user.projectMenu.uploadUnderground' | translate }}
                        </h6>
                        <furban-drag-upload #undergroundUpload [acceptedFormats]="uploadMultilayerAcceptedFormats"
                            [maxSize]="maxSize" [project3DUpload]="undergroundProject3DUpload"
                            (uploadFile)="onFileUpload($event, uploadedObjectTypeEnum.underground)"
                            (uploadTextureFile)="onObjectTextureUpload($event, uploadedObjectTypeEnum.underground)"
                            (project3DUploadDelete)="onObjectUploadDelete($event, uploadedObjectTypeEnum.underground)"></furban-drag-upload>
                    </div>
                </ng-container>

                <ng-container *ngIf="checkIfRouteIncludes('project:create-design')">
                    <div [style.margin-bottom.px]="20">
                        <h6 class="category-title" [style.margin-bottom.px]="20">
                            {{ 'user.projectMenu.uploadDesign' | translate }}
                        </h6>
                        <furban-drag-upload #fixedObjectUpload [acceptedFormats]="uploadMultilayerAcceptedFormats"
                            [maxSize]="maxSize" [project3DUpload]="fixedObjectProject3DUpload"
                            (uploadFile)="onFileUpload($event, uploadedObjectTypeEnum.fixedDesign)"
                            (uploadTextureFile)="onObjectTextureUpload($event, uploadedObjectTypeEnum.fixedDesign)"
                            (project3DUploadDelete)="onObjectUploadDelete($event, uploadedObjectTypeEnum.fixedDesign)"></furban-drag-upload>
                    </div>
                </ng-container>


                <button class="primary-button" (click)="onFinishUpload()">
                    <h6>{{ 'user.projectMenu.finishUploads' | translate }}</h6>
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="shouldDisplayFreeshapeMenu && toolingVisibility.editMode">
        <div class="freeshape-menu-container">
            <div class="items-container" [class.radius]="shouldDisplayPrice()" [class.scroll-hidden]="scrollHidden"
                [style.padding.px]="20">
                <div class="tools-container" [style.padding-bottom.px]="20">
                </div>
                <ng-container *ngFor="let object of objects">
                    <ng-template [ngIf]="
                            object.category.categoryName ===
                            'user.projectMenu.groundMaterials'
                        ">
                        <mat-form-field class="full-width" appearance="outline" id="shapes-step">
                            <mat-label>
                                {{
                                'user.projectMenu.selectGround' | translate
                                }}
                            </mat-label>
                            <mat-select disableOptionCentering placeholder="{{
                                    'user.projectMenu.selectGround' | translate
                                }}" [(value)]="selectedGroundMaterial">
                                <mat-option *ngFor="let model of object.models" [value]="model">
                                    {{
                                    model.furban3DModel.translationsLabel
                                    | translate
                                    }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <section class="flex-row-start wrap" cdkDropList cdkDropListSortingDisabled
                            [cdkDropListData]="shapes" (cdkDropListDropped)="dropShape($event)">
                            <div *ngFor="let type of shapes" class="menu-item-container pointer" cdkDrag
                                [cdkDragData]="type" [cdkDragStartDelay]="getDragDelay()" (mouseenter)="
                                    mouseEnterShapes(
                                        type,
                                        selectedGroundMaterial.furban3DModel
                                            .translationsLabel,
                                        $event
                                    )
                                " (mouseleave)="mouseLeave()" (click)="onShapeObjectSelection(type)">
                                <img class="menu-item-image full-width" alt="ProjPic" [src]="
                                        generateShapesAssetsURL(
                                            selectedGroundMaterial.furban3DModel
                                                .objectLookId,
                                            type
                                        )
                                    " />
                            </div>
                        </section>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-template #prevButton>
        <button class="secondary-button round">
            <span class="material-icons"> arrow_back </span>
        </button>
    </ng-template>

    <ng-template #nextButton>
        <button class="secondary-button round" [style.margin-left.px]="20">
            <span class="material-icons"> arrow_forward </span>
        </button>
    </ng-template>

    <ng-template #doneButton>
        <button class="primary-button" [style.margin-left.px]="20">
            <h6>{{ 'uiTour.dashboard.doneButtonText' | translate }}</h6>
        </button>
    </ng-template>
</div>

<ng-template #changePerspectiveButton>
    <button class="round-button" matTooltip="{{'buttonsTooltips.change3Dperspective' | translate}}"
        id="change-perspective-step" (click)="callToolingAction(toolingActions.changeCameraPerspective)">
        <furban-svg-container [svgImage]="svgImageType.changePerspective"></furban-svg-container>
    </button>
</ng-template>