<ng-container *ngIf="project">
    <div
        class="main-container overflow padding-0-32"
        infiniteScroll
        [infiniteScrollThrottle]="300"
        [scrollWindow]="false"
        (scrolled)="onScrollDown()"
    >
        <furban-app-design-proposals
            #designProposals
            [project]="project"
            [viewContainerRef]="viewContainerRef"
            [disabledActions]="true"
            [isEmbeded]="true"
            [isPublicPage]="true"
        >
        </furban-app-design-proposals>
    </div>
</ng-container>
