import { Component, Input, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, MediaService, ModalManager, MultilanguageService, UserProfile } from '@furban/utilities';
import { AdminProfileComponent } from '../../municipality/admin/admin-profile/admin-profile.component';
import { UserProfileComponent } from '../../municipality/user/user-profile/user-profile.component';


@Component({
  selector: 'furban-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss'],
})
export class ManageProfileComponent {

  @Input() public containerRef: ViewContainerRef;

  constructor(
    private modal: ModalManager,
    private dialog: MatDialog,
    private multilanguageService: MultilanguageService,
    private mediaService: MediaService,
    private authService: AuthService) { }

  get userProfile(): UserProfile {
    return this.authService.userProfile;
  }

  get hasAdministrativeRole(): boolean {
    return this.authService.hasAdministrativeRole();
  }

  get isCitizenOrExpertOrCollaborator(): boolean {
    return this.authService.isCitizenOrExpertOrCollaborator;
  }

  public getUserMedia(mediaId: string): string {
    return this.mediaService.getUserMedia(mediaId);
  }

  public openAdminProfileDialog(profileType: string): void {
    const userProfileDialogRef = this.dialog.open(AdminProfileComponent, {
      width: '600px',
    });
    userProfileDialogRef.disableClose = true;
    userProfileDialogRef.componentInstance.parentRef =
      this.containerRef;
    userProfileDialogRef.componentInstance.profileContent = profileType;

    const closeProfileSub =
      userProfileDialogRef.componentInstance.onProfileClose.subscribe(
        (data) => {
          userProfileDialogRef.close();
        }
      );

    userProfileDialogRef.afterClosed().subscribe((result) => {
      closeProfileSub.unsubscribe();
    });
  }

  public logout(): void {
    this.modal
      .showModal(
        this.containerRef,
        ModalManager.createConfiguration(
          'errors.warning',
          'auth.logoutMessage',
          'generic.yesBtn',
          'generic.noBtn'
        )
      )
      .subscribe((res) => {
        if (res) {
          this.authService.logoutRedirect();
        }
      });
  }

  public openUserProfileDialog(): void {
    const userProfileDialogRef = this.dialog.open(UserProfileComponent);
    userProfileDialogRef.disableClose = true;
    userProfileDialogRef.componentInstance.parentRef =
      this.containerRef;

    const closeProfileSub =
      userProfileDialogRef.componentInstance.onProfileClose.subscribe(
        (data) => {
          userProfileDialogRef.close();
        }
      );

    userProfileDialogRef.afterClosed().subscribe((result) => {
      closeProfileSub.unsubscribe();
    });
  }

  public deleteUserProfile(): void {
    this.modal
      .showModal(
        this.containerRef,
        ModalManager.createConfiguration(
          'errors.warning',
          'user.profile.deleteProfileWarning',
          'generic.yesBtn',
          'generic.noBtn'
        )
      )
      .subscribe((res) => {
        if (res) {
          this.deleteUserProfileConfirmation();
        }
      });
  }

  public onLanguageChanged(languageCode: string): void {
    this.multilanguageService.usedLang = languageCode;
    this.multilanguageService.updateUsedLanguage();
  }

  private deleteUserProfileConfirmation(): void {
    this.authService.removeUser().subscribe(() => {
      this.authService.logoutRedirect();
    });
  }
}
