import { AbstractControl } from '@angular/forms';
import { FORBIDDEN_DOMAINS } from '../_constants/domains.constants';

export function validateMunicipalityEmail(control: AbstractControl) {
    const forbiddenDomains: string[] = FORBIDDEN_DOMAINS;
    const text: string = control.value;
    if (text === '' || control.pristine) {
        return null;
    }

    const domains: string = text.split('@')[1];

    if (domains) {
        const domainList: string[] = domains.split('.');
        const clientDomain: string = domainList[0];

        const findedDomain = forbiddenDomains.find(
            (dom) => dom.toLowerCase() === clientDomain.toLowerCase()
        );
        if (findedDomain) {
            return { notMunicipalityEmail: true };
        }
    }

    return null;
}
