import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectInvolvementTypeEnum } from '@furban/utilities';

@Component({
    selector: 'furban-select-project-type',
    templateUrl: './select-project-type.component.html',
    styleUrls: ['./select-project-type.component.scss'],
})
export class SelectProjectTypeComponent {
    constructor(private dialogRef: MatDialogRef<SelectProjectTypeComponent>) {}

    public closePopup() {
        this.dialogRef.close();
    }

    public setProjectType(projectType: ProjectInvolvementTypeEnum) {
        this.dialogRef.close(projectType);
    }

    public get projectInvolvementType(): typeof ProjectInvolvementTypeEnum {
        return ProjectInvolvementTypeEnum;
    }
}
