import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminAuthService } from './admin-auth.service';
import {
    FormBuilder,
    FormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';

import {
    AuthService,
    ErrorService,
    FurbanUtil,
    MultilanguageService,
    MyErrorStateMatcher,
    routingConstants,
    UserProfile,
    Role,
    User,
    RoleEnum,
    LoginState,
    CaptchaUtil,
    validatePassword,
    ControlsUtil,
} from '@furban/utilities';
import { UserProfileService } from '../../../municipality/user/user-profile/user-profile.service';

import { LoginForm } from 'apps/furban-client/src/app/shared/prototypes/loginform';
import { map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ProjectDetailsService } from '../../../project-shared/project-details/project-details.service';
@Component({
    selector: 'furban-admin-auth',
    templateUrl: './admin-auth.component.html',
    styleUrls: ['./admin-auth.component.scss'],
})
export class AdminAuthComponent implements OnInit, LoginForm {
    public loginCheckForm: FormGroup;
    public changePassCheckForm: FormGroup;
    public recoverPasswordCheckForm: FormGroup;
    public captchaData = 'data:image/jpg;base64,VFa';
    public captchaId: string;
    public isBlocked: boolean;
    public state: string = LoginState.login;
    public profile: UserProfile;
    public errorMatcher = new MyErrorStateMatcher();
    public passwordConfirmValidator = FurbanUtil.passwordConfirmValidator;
    public noWhiteSpacesValidator = FurbanUtil.noWhiteSpacesValidator;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public getPasswordErrorMessage = FurbanUtil.getPasswordErrorMessage;

    public hasControlMaxError = ControlsUtil.hasControlMaxError;
    public hasControlMinError = ControlsUtil.hasControlMinError;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;
    public hasControlMaxLengthError = ControlsUtil.hasControlMaxLengthError;
    public hasControlMinLengthError = ControlsUtil.hasControlMinLengthError;
    public hasControlIsHtmlError = ControlsUtil.hasControlIsHtmlError;
    public hasControlWhitespacesError = ControlsUtil.hasControlWhitespacesError;
    public hasControlEmailError = ControlsUtil.hasControlEmailError;
    public isApp = FurbanUtil.isApp;

    constructor(
        public router: Router,
        public authService: AuthService,
        public translateService: TranslateService,
        private adminAuthService: AdminAuthService,
        private formBuilder: FormBuilder,
        private multilanguageService: MultilanguageService,
        private userProfileService: UserProfileService,
        private projectDetailsService: ProjectDetailsService,
        private errorService: ErrorService
    ) { }

    ngOnInit() {
        this.multilanguageService.setupLanguage();

        this.loginCheckForm = this.formBuilder.group({
            emailFormControl: ['', [Validators.required, Validators.email]],
            passwordFormControl: ['', Validators.required],
            remberMeFormControl: [],
            captchaAnswer: [],
        });

        this.recoverPasswordCheckForm = this.formBuilder.group({
            recoverEmailFormControl: [
                '',
                [Validators.required, Validators.email],
            ],
        });

        this.authService.redirectAdmin();
    }

    public login(): void {
        if (this.loginCheckForm.invalid) {
            return;
        }

        this.authService.redirectUserURL = '';
        this.errorService.clientErrorCode = 20001;
        this.errorService.errorHandler = this.dismissedErrorHandler;

        this.authService
            .login(
                this.loginCheckForm.get('emailFormControl').value,
                this.loginCheckForm.get('passwordFormControl').value,
                this.loginCheckForm.get('remberMeFormControl').value,
                this.captchaId,
                this.loginCheckForm.get('captchaAnswer').value
            )
            .subscribe(
                {
                    next: (data) => {
                        this.manageDataAfterLogin(data);
                        this.initializeProjectList();
                    },
                    error: (error) => {
                        if (
                            error.status === 403 &&
                            error.error['auth-not-confirmed']
                        ) {
                            return;
                        } else if (
                            error.status === 403 &&
                            error.error['auth-blocked']
                        ) {
                            this.showCaptcha();
                        }
                    }
                }
            );
    }

    public get getPasswordValidationErrors(): ValidationErrors | null {
        return this.loginCheckForm?.get('passwordFormControl')?.errors;
    }

    public manageDataAfterLogin(user: any) {
        if (user && user['userId']) {
            if (
                user['credentialsExpired'] &&
                this.checkIfRoleIsAdmin(user['role'])
            ) {
                this.initialisePasswordChangeForm();
                this.state = LoginState.changePassword;
                this.clearLoginFormData();
            } else {
                this.getUserProfile(user);
            }
        } else {
            console.error('something went wrong');
        }
    }

    public checkIfRoleIsAdmin(role: Role) {
        return role && role.roleId === RoleEnum.admin;
    }

    public dismissedErrorHandler() {
        console.log('dismissed');
    }

    public showCaptcha(event?: any) {
        if (event) {
            event.stopPropagation();
        }
        CaptchaUtil.loadCaptcha(this);
    }

    public getUserProfile(data) {
        this.authService.user = data as User;

        this.authService.getUserProfile().subscribe((userProfile) => {
            if (userProfile) {
                this.manageUserProfileData(userProfile);
            }
        });
    }

    public getClient() {
        this.authService.getClient().subscribe((data) => {
            if (this.authService.isAdmin() || this.authService.isSuperAdmin()) {
                this.authService.redirectAdmin();
            } else {
                this.authService.redirectPioneer();
            }
        });
    }

    public navigateToLandscape(): void {
        this.router.navigateByUrl(`/${routingConstants.landing}`);
    }

    public changeUserPass() {
        if (this.changePassCheckForm.invalid) {
            return;
        }

        this.adminAuthService
            .changeUserPass(
                this.changePassCheckForm.get('oldPasswordFormControl').value,
                this.changePassCheckForm.get('passwordFormControl').value,
                this.changePassCheckForm.get('passwordConfirmFormControl').value
            )
            .subscribe((data) => {
                if (data && data['userId']) {
                    this.authService.user = data;
                    this.authService
                        .getUserProfile()
                        .pipe(
                            map((userProfile) => ({
                                ...userProfile,
                                screenName: this.changePassCheckForm.get(
                                    'displayNameFormControl'
                                ).value,
                            })),
                            switchMap((result) =>
                                this.userProfileService.updateProfile(result)
                            )
                        )
                        .subscribe(
                            {
                                next: (data) => this.authService.userProfile = data,
                                complete: () => this.authService.redirectAdmin()
                            }

                        );
                }
            });
    }

    public clearLoginFormData() {
        this.loginCheckForm.reset();
        this.loginCheckForm.markAsUntouched();
    }

    public recover() {
        if (this.recoverPasswordCheckForm.invalid) {
            return;
        }

        this.adminAuthService
            .recoverAdminPass(
                this.recoverPasswordCheckForm
                    .get('recoverEmailFormControl')
                    .value.toLowerCase()
            )
            .subscribe((data) => {
                if (data) {
                    this.recoverPasswordCheckForm.reset();
                    this.recoverPasswordCheckForm.markAsUntouched();
                    this.state = LoginState.afterRecover;
                }
            });
    }

    public backToLogin() {
        this.recoverPasswordCheckForm.reset();
        this.recoverPasswordCheckForm.markAsUntouched();
        this.state = LoginState.login;
    }

    public goToAdminRegister() {
        this.router.navigate([routingConstants.adminRegister]);
    }

    private manageUserProfileData(userProfile: UserProfile) {
        this.profile = userProfile;

        if (this.authService.isPioneer()) {
            if (this.profile.acceptedTOS) {
                this.authService.redirectPioneer();
            } else {
                this.state = LoginState.changeName;
            }
        } else {
            this.getClient();
        }
    }

    private initialisePasswordChangeForm() {
        this.changePassCheckForm = this.formBuilder.group(
            {
                oldPasswordFormControl: ['', Validators.required],
                passwordFormControl: [
                    '',
                    [Validators.required, validatePassword],
                ],
                passwordConfirmFormControl: ['', Validators.required],
                displayNameFormControl: [
                    '',
                    [
                        Validators.required,
                        this.htmlValidator,
                        this.noWhiteSpacesValidator,
                        Validators.maxLength(60),
                    ],
                ],
            },
            { validator: this.passwordConfirmValidator }
        );
    }

    private initializeProjectList(): void {
        if (this.authService.isSuperAdmin()) {
            return;
        }
        this.projectDetailsService.getProjects().subscribe();
    }
}
