<furban-comment-card>
    <ng-container area-type="side">
        <furban-avatar-picture [size]="40" [name]="comment.createdBy">
        </furban-avatar-picture>
    </ng-container>

    <ng-container area-type="header">
        <div class="flex-row-start">
            <h6 [style.margin-right.px]="8">{{ comment.createdBy }}</h6>
            <ng-content select="[subarea-type=hour]"></ng-content>
        </div>

        <span class="material-icons pointer" [matMenuTriggerFor]="menuAdd">
            more_vert
        </span>

        <mat-menu #menuAdd="matMenu" overlapTrigger="false">
            <ng-content select="[subarea-type=menuTrigger]"></ng-content>
        </mat-menu>
    </ng-container>

    <ng-container area-type="body">
        <p>{{ comment.comment }}</p>
    </ng-container>

    <ng-container area-type="footer">
        <div class="flex-row-start">
            <ng-content select="[subarea-type=likes]"></ng-content>

            <ng-container *ngIf="hasReplies">
                <div class="flex-row-start pointer">
                    <div class="avatar-container">
                        <ng-container
                            *ngFor="let reply of distinctReplies; let i = index"
                        >
                            <furban-avatar-picture
                                class="avatar-overlay"
                                [style.margin-left.px]="i * 16"
                                [size]="24"
                                [name]="reply"
                            >
                            </furban-avatar-picture>
                        </ng-container>
                    </div>
                    <p
                        [style.margin-left.px]="
                            20 + distinctReplies.length * 16
                        "
                    >
                        {{ comment.replies.length }}
                        {{ 'conversation.replies' | translate }}
                    </p>
                </div>
            </ng-container>
            <ng-container *ngIf="!hasReplies">
                <div class="message-content">
                    <span class="material-icons" [style.margin-right.px]="4">
                        message
                    </span>
                    <p>{{ 'conversation.reply' | translate }}</p>
                </div>
            </ng-container>
        </div>
        <ng-content select="[subarea-type=attachments]"></ng-content>
    </ng-container>
</furban-comment-card>
