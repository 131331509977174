<section mat-dialog-title>
    <h4>{{ 'pioneer.permitInvolvement.reviewPermit' | translate }}</h4>
    <button class="ghost-button" (click)="closePopup()">
        <span class="material-icons">close</span>
    </button>
</section>

<section mat-dialog-content>
    <form [formGroup]="form">
        <mat-radio-group formControlName="requestResponse">
            <mat-radio-button [value]="review.APPROVED">{{
                'pioneer.permitInvolvement.approvePermit' | translate
            }}</mat-radio-button>
            <mat-radio-button [value]="review.REJECTED">{{
                'pioneer.permitInvolvement.rejectPermit' | translate
            }}</mat-radio-button>
            <mat-radio-button [value]="review.REQUIRED_CHANGED">{{
                'pioneer.permitInvolvement.wait' | translate
            }}</mat-radio-button>
        </mat-radio-group>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>
                {{ 'pioneer.permitInvolvement.message' | translate }}
            </mat-label>
            <textarea
                matInput
                formControlName="message"
                placeholder="{{
                    'pioneer.permitInvolvement.message' | translate
                }}"
            ></textarea>
        </mat-form-field>
    </form>
    <div class="warning-message">
        <h5>
            {{ 'pioneer.permitInvolvement.warningMessage' | translate }}
        </h5>
    </div>
</section>

<section mat-dialog-actions>
    <button
        class="secondary-button"
        [style.margin-right.px]="12"
        (click)="closePopup()"
    >
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button
        class="primary-button"
        [class.disabled]="isButtonDisabled()"
        (click)="resolveResponse()"
    >
        <h6>{{ 'pioneer.permitInvolvement.review' | translate }}</h6>
    </button>
</section>
