import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {
    AuthService,
    ModalManager,
    Client,
    Project,
    MunicipalityService,
    BackgroundModel,
    CustomToasterComponent,
    CustomToasterService,
    ExportFilesUtil,
} from '@furban/utilities';
import { BackgroundMediaService } from './background-media.service';
import { ProjectDetailsService } from '../../../project-shared/project-details/project-details.service';

@Component({
    selector: 'furban-app-background-media',
    templateUrl: './background-media.component.html',
    styleUrls: ['./background-media.component.scss'],
})
export class BackgroundMediaComponent implements OnInit {
    @ViewChild('uploadMediaForm', { static: true }) uploadMediaForm;

    public readonly maxFileSizeLimit: number = 4000000;
    public readonly integerNumber: number = 2147483647;
    public uploadMediaCheckForm: FormGroup;
    public municipalities: Client[];
    public projects: Project[];
    public document: BackgroundModel;
    public checked: boolean;
    public isProjectSelected: boolean;

    public filteredMunicipalitiesOptions: Client[];
    public selectedMunicipality: Client;

    constructor(
        public authService: AuthService,
        public router: Router,
        public municipalityService: MunicipalityService,
        public projectDetailsService: ProjectDetailsService,
        public backgroundMediaService: BackgroundMediaService,
        private viewContainerRef: ViewContainerRef,
        private modal: ModalManager,
        private translateService: TranslateService,
        private customToasterService: CustomToasterService,

        public formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.uploadMediaCheckForm = this.formBuilder.group({
            clientFormControl: ['', Validators.required],
            projectFormControl: ['', Validators.required],
            xCoordinateFormControl: [
                0,
                [
                    Validators.required,
                    Validators.max(this.integerNumber),
                    Validators.min(-this.integerNumber),
                ],
            ],
            yCoordinateFormControl: [
                0,
                [
                    Validators.required,
                    Validators.max(this.integerNumber),
                    Validators.min(-this.integerNumber),
                ],
            ],
        });
        this.getMunicipalities();
    }

    onChangeClient(clientId: number) {
        this.isProjectSelected = false;
        this.uploadMediaCheckForm.get('projectFormControl').reset();
        this.uploadMediaCheckForm.get('xCoordinateFormControl').setValue(0);
        this.uploadMediaCheckForm.get('yCoordinateFormControl').setValue(0);
        this.getProjects(clientId);
    }

    onChangeProject(projectId: string) {
        this.isProjectSelected = true;
        this.getBackgroundMedia(projectId);
    }

    clickUploadFileLink() {
        document.getElementById('file-upload-input').click();
    }

    fileChange(event: any) {
        if (event.target.files[0].size > this.maxFileSizeLimit) {
            this.snackbarMessage(
                'admin.projectDetails.exceedingFileSize',
                2000,
                this.translateService.instant('generic.okBtn'),
                'info'
            );
            return;
        }

        const coordinates = {
            x: this.uploadMediaCheckForm.get('xCoordinateFormControl').value,
            y: this.uploadMediaCheckForm.get('yCoordinateFormControl').value,
        };

        const file = event.target.files[0];
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append(
            'projectId',
            this.uploadMediaCheckForm.get('projectFormControl').value
        );
        formData.append(
            'clientId',
            this.uploadMediaCheckForm.get('clientFormControl').value.clientId
        );
        formData.append('coordinates', JSON.stringify(coordinates));

        this.backgroundMediaService
            .uploadBackgroundMedia(formData)
            .subscribe((data) => {
                this.document = data;
                this.checked = false;
                this.snackbarMessage(
                    'background.confirmUpload',
                    1500,
                    this.translateService.instant('generic.okBtn'),
                    'success'
                );
            });
    }

    deleteWithWarning(media: BackgroundModel) {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'background.deleteWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.deleteDocumentLabel(media);
                }
            });
    }

    deleteDocumentLabel(media: BackgroundModel) {
        media.clientId =
            this.uploadMediaCheckForm.get('clientFormControl').value;
        media.isActive = false;
        this.backgroundMediaService
            .updateBackgroundMedia(media)
            .subscribe((data) => {
                if (data) {
                    this.document = null;
                    this.uploadMediaCheckForm
                        .get('xCoordinateFormControl')
                        .setValue(0);
                    this.uploadMediaCheckForm
                        .get('yCoordinateFormControl')
                        .setValue(0);
                    this.snackbarMessage(
                        'background.confirmDelete',
                        1500,
                        this.translateService.instant('generic.okBtn'),
                        'success'
                    );
                }
            });
    }

    downloadDocument(media: BackgroundModel) {
        media.clientId =
            this.uploadMediaCheckForm.get('clientFormControl').value;
        this.backgroundMediaService
            .downloadBackgroundMedia(media.backgroundMediaId)
            .subscribe((data) => {
                ExportFilesUtil.createGLB(data, media.fileName);
            });
    }

    onToggleClick(media: BackgroundModel) {
        media.isVisible = !media.isVisible;
        media.clientId =
            this.uploadMediaCheckForm.get('clientFormControl').value;
        this.backgroundMediaService
            .updateBackgroundMedia(media)
            .subscribe((data) => {
                this.snackbarMessage(
                    'background.confirmToggle',
                    1500,
                    this.translateService.instant('generic.okBtn'),
                    'success'
                );
            });
    }

    updateBackgroundMediaCoordinates(media: BackgroundModel) {
        const coordinates = {
            x: this.uploadMediaCheckForm.get('xCoordinateFormControl').value,
            y: this.uploadMediaCheckForm.get('yCoordinateFormControl').value,
        };
        media.coordinates = JSON.stringify(coordinates);
        media.clientId =
            this.uploadMediaCheckForm.get('clientFormControl').value;
        this.backgroundMediaService.updateBackgroundMedia(media).subscribe(
            (data) => {
                this.snackbarMessage(
                    'background.confirmUpdateCoordinates',
                    1500,
                    this.translateService.instant('generic.okBtn'),
                    'success'
                );
            }
        );
    }

    snackbarMessage(
        message: string,
        duration: number,
        action?: string,
        type?: string
    ) {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            type === 'info' ? 'info' : 'check_circle',
            type,
            message,
            duration
        );
    }

    onSelectClient(event: Client) {
        this.onChangeClient(event['clientId']);
    }

    protected getMunicipalities() {
        this.municipalityService.getAllClients().subscribe((data) => {
            this.municipalities = data;
        });
    }

    protected getProjects(clientId: number) {
        this.projectDetailsService
            .getProjectsByClient(clientId)
            .subscribe((data) => {
                this.projects = data;
            });
    }

    protected getBackgroundMedia(projectId: string) {
        this.backgroundMediaService
            .getBackgroundMedia(projectId)
            .subscribe((data) => {
                this.document = data as BackgroundModel;
                if (data) {
                    const coordinates = JSON.parse(this.document.coordinates);
                    this.uploadMediaCheckForm
                        .get('xCoordinateFormControl')
                        .setValue(coordinates.x);
                    this.uploadMediaCheckForm
                        .get('yCoordinateFormControl')
                        .setValue(coordinates.y);
                    this.checked = this.document.isVisible;
                } else {
                    this.uploadMediaCheckForm
                        .get('xCoordinateFormControl')
                        .setValue(0);
                    this.uploadMediaCheckForm
                        .get('yCoordinateFormControl')
                        .setValue(0);
                }
            });
    }
}
