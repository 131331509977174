<section class="comments-container container-for-scroll vertical-scrollable"
         [class.open-emoji]="isEmojiOpened">
    <ng-container *ngIf="hasComments; else noComments">
        <section *ngFor="let comment of permitComments"
                 class="flex-row-start"
                 (click)="selectComment(comment)">
            <furban-comment class="avatar card-type full-width"
                            [comment]="comment"
                            [parentViewContainerRef]="viewContainerRef"
                            [project]="project">
            </furban-comment>

            <ng-container *ngIf="!isViewOnly">
                <furban-comment-actions class="comment-actions"
                                        [comment]="comment"
                                        [viewContainerRef]="viewContainerRef"
                                        (commentDeleted)="deleteCommentConfirmation($event)">
                </furban-comment-actions>
            </ng-container>
        </section>
    </ng-container>
    <ng-template #noComments>
        <div class="flex-column-center padding-20">
            <img src="../../../assets/images/no_comm.svg"
                 [style.height.px]="200"
                 [style.margin-bottom.px]="20" />
            <h4 [style.font-size.px]="18">
                {{ 'comments.noComments' | translate }}
            </h4>
        </div>
    </ng-template>
</section>

<ng-container *ngIf="!isViewOnly">
    <furban-permit-comment-form [isDialog]="isDialog"
                                [project]="project"
                                (commentSave)="onCommentSave($event)"
                                (toggleEmojiEvent)="onEmojiToggled($event)">
    </furban-permit-comment-form>
</ng-container>