import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserProfile } from '@furban/utilities';

@Component({
    selector: 'furban-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
    @Input()
    userProfile: UserProfile;
    isOpen = true;
    userSettingsCheckForm: FormGroup;

    constructor(private formBuilder: FormBuilder) {
        this.userSettingsCheckForm = this.formBuilder.group({
            show3DNotificationsFormControl: ['', [Validators.required]],
            isHoverHelperActiveControl: ['', [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.userSettingsCheckForm
            .get('show3DNotificationsFormControl')
            .setValue(this.userProfile.userSettings.show3DNotifications);
        this.userSettingsCheckForm
            .get('isHoverHelperActiveControl')
            .setValue(this.userProfile.userSettings.isHoverHelperActive);
    }
}
