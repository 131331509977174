import { Component, Input, OnDestroy } from '@angular/core';

import {
    CategoryObject,
    PermitCategoryObject,
    PermitAsset,
    HouseService,
    PermitThreeService,
    PermitKeyboardActionEnum,
    TabEnum,
    ToolNameEnum,
    MenuService,
    FurbanUtil,
} from '@furban/utilities';
import { Subscription } from 'rxjs';

@Component({
    selector: 'furban-obj-categories-menu',
    templateUrl: './obj-categories-menu.component.html',
    styleUrls: ['./obj-categories-menu.component.scss'],
})
export class ObjCategoriesMenuComponent implements OnDestroy {
    @Input() objects: PermitCategoryObject[] | CategoryObject[];

    public activeItem: PermitCategoryObject | CategoryObject;
    public currentCategory: string;
    public currentSelectedObject: PermitAsset;
    public activeTab = TabEnum.setupHouse;
    public enabledTool: string;
    public toolName = ToolNameEnum;
    private toolsSubscription: Subscription;

    public isAppOrMobile = FurbanUtil.isAppOrMobile;

    public get shouldShowMenu(): boolean {
        return this.menuService.shouldShowMenu;
    }

    constructor(
        private menuService: MenuService,
        private houseService: HouseService,
        private permitService: PermitThreeService,
    ) {
        this.subscribeToCancelObservable();
        this.subscribeToKeyboardObservable();
        this.subscribeToDisableTool();
    }
    ngOnDestroy(): void {
        this.toolsSubscription.unsubscribe();
    }

    get tabEnum(): typeof TabEnum {
        return TabEnum;
    }

    public checkForActiveTab(
        nm: PermitCategoryObject | CategoryObject
    ): boolean {
        return nm === this.activeItem;
    }

    public navigateToTab(item: PermitCategoryObject | CategoryObject): void {
        this.activeItem = item;
        this.currentCategory = item.category.categoryName;
        this.onObjectClick(this.currentSelectedObject);
    }

    public checkSetupTabIsActive(): boolean {
        return (
            this.activeItem === undefined &&
            this.activeTab === TabEnum.setupHouse
        );
    }

    public checkToolingTabIsActive(): boolean {
        return (
            this.activeItem === undefined && this.activeTab === TabEnum.tools
        );
    }

    public navigateToSetupTab(): void {
        this.activeItem = undefined;
        this.activeTab = TabEnum.setupHouse;
        this.onObjectClick(this.currentSelectedObject);
    }

    public navigateToToolsTab(): void {
        this.activeItem = undefined;
        this.activeTab = TabEnum.tools;
        this.onObjectClick(this.currentSelectedObject);
    }

    public onObjectClick(object: PermitAsset): void {
        if (this.currentSelectedObject === object) {
            this.currentSelectedObject = null;
        } else {
            this.currentSelectedObject = object;
        }
        this.houseService.changeObjectToAdd(
            this.currentSelectedObject as PermitAsset
        );
    }

    public isAssetSelected(object: PermitAsset): boolean {
        return (
            this.currentSelectedObject?.objectLookId === object?.objectLookId
        );
    }

    public removeItemSelection(): void {
        this.onObjectClick(this.currentSelectedObject);
    }

    private disableMultiselect(): void {
        this.permitService.deactivateMultiselect();
    }

    private subscribeToCancelObservable(): void {
        this.houseService.cancelSelectionObservable$.subscribe((data) => {
            if (data) {
                this.currentSelectedObject = null;
            }
        });
    }

    private subscribeToKeyboardObservable(): void {
        this.permitService.keyboardPressObservable.subscribe((data) => {
            if (data.type !== PermitKeyboardActionEnum.copy) {
                return;
            }

            const asset = data.object as PermitAsset;
            const assetCategory = this.getCategoryFromObject(asset);
            this.currentSelectedObject = asset;
            this.navigateToTab(assetCategory);
            this.onObjectClick(asset);
        });
    }

    private getCategoryFromObject(asset: PermitAsset): PermitCategoryObject {
        let foundCategory;

        for (const category of this.objects) {
            if (
                this.isAssetInsideArray(category.models as PermitAsset[], asset)
            ) {
                foundCategory = category;
                break;
            }
        }
        return foundCategory;
    }

    private isAssetInsideArray = (
        array: PermitAsset[],
        asset: PermitAsset
    ): PermitAsset => {
        return array.find((el) => el.objectLookId === asset.objectLookId);
    };

    private subscribeToDisableTool() {
        this.toolsSubscription = this.permitService.disableToolObservable.subscribe(
            (data) => {
                if (data) {
                    this.enabledTool = '';
                }
            }
        );
    }
}
