import { ThreeInstance } from './three-instance';
import { ThreeUtils } from './three.utils';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';

export class ThreeTransformControlsBuilder {
    public static addTransformControls(instance: ThreeInstance) {
        instance.transformControls = new TransformControls(instance.camera, instance.renderer.domElement);
        instance.transformControls.name = 'transform';
        instance.transformControls.showY = false;
        instance.transformControls.setRotationSnap(ThreeUtils.toRad(5));
        instance.transformControls.setSize(1);
        //this.removeArrows(instance);
        instance.transformGroup.add(instance.transformControls);
    }

    private static removeArrows(instance: ThreeInstance): void {
        for (let i = 8; i >= 0; i--) {
            instance.transformControls.children[0].children[0].remove(instance.transformControls.children[0].children[0].children[i]);
        }
    }
}
