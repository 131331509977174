import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@furban/app-config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Environment } from '../_models/environment-model';
import { PermitComment } from '../_models/permit-comment';

@Injectable({
    providedIn: 'root',
})
export class PermitCommentsService {
    public pinCommentSavedEmitter: EventEmitter<PermitComment> =
        new EventEmitter();
    public highlightedPermitCommentEmitter: EventEmitter<PermitComment> =
        new EventEmitter();
    public deletedPermitCommentEmitter: EventEmitter<number> =
        new EventEmitter();

    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        private http: HttpClient
    ) { }

    getAllPermitComments(projectId: string): Observable<PermitComment[]> {
        return this.http
            .get(`${this.environment.apiPath}/permit/comment/${projectId}`, {
                headers: this._headers,
            })
            .pipe(map((result) => result as PermitComment[]));
    }

    savePermitComment(comment: PermitComment): Observable<PermitComment> {
        return this.http
            .post(this.environment.apiPath + '/permit/comment', comment, {
                headers: this._headers,
            })
            .pipe(map((result) => result as PermitComment));
    }

    deletePermitComment(comment: PermitComment): Observable<void> {
        const httpOptions = {
            headers: this._headers,
            body: comment,
        };
        return this.http.delete<void>(
            `${this.environment.apiPath}/permit/comment/delete`,
            httpOptions
        );
    }
}
