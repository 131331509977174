export class DesignProposalRequestParam {
    id: string;
    page: number;
    criteriaPath: string;
    priceFilterType: string;
    isAdminDesignFilter?: string;

    constructor(
        id: string,
        page: number,
        criteriaPath: string,
        filterType: string
    ) {
        this.id = id;
        this.page = page;
        this.criteriaPath = criteriaPath;
        this.priceFilterType = filterType;
    }
}
