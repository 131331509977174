import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { Client } from '@furban/utilities';
import { Subscription } from 'rxjs';

@Component({
    selector: 'furban-dropdown-autocomplete',
    templateUrl: './dropdown-autocomplete.component.html',
    styleUrls: ['./dropdown-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownAutocompleteComponent implements AfterViewInit, OnDestroy {
    @ViewChild(MatAutocompleteTrigger)
    public trigger: MatAutocompleteTrigger;

    @Input() municipalities: Client[];
    @Input() formGroup: FormGroup;
    @Output() clientSelect = new EventEmitter<Client>();

    public selectedMunicipality: Client;
    public filteredMunicipalitiesOptions: Client[];
    private subscription: Subscription;

    constructor(private cdref: ChangeDetectorRef) { }

    ngAfterViewInit(): void {
        this.initializeFilteredMunicipalities();
        this.cdref.detectChanges();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public displayFn(client: Client): string {
        return client && client.clientName ? client.clientName : '';
    }

    public updateSelectedMunicipality(value): void {
        this.selectedMunicipality = value;
        this.clientSelect.emit(this.selectedMunicipality);
    }

    private initializeFilteredMunicipalities(): void {
        this.filteredMunicipalitiesOptions = this.municipalities;
        this.subscribeToPanelClosingActions();
        this.subscribeToFormControlChange();
    }

    private _filter(clientName: string): Client[] {
        const filterValue = clientName.toLowerCase();
        return this.municipalities.filter(
            (municipality) =>
                municipality.clientName.toLowerCase().indexOf(filterValue) === 0
        );
    }

    private subscribeToFormControlChange(): void {
        this.formGroup.controls['clientFormControl'].valueChanges.subscribe(
            (value) => {
                if (typeof value === 'string') {
                    this.selectedMunicipality = null;
                    this.filteredMunicipalitiesOptions = this._filter(value);
                }
            }
        );
    }

    private subscribeToPanelClosingActions(): void {
        this.subscription = this.trigger.panelClosingActions.subscribe(() => {
            if (this.selectedMunicipality === null) {
                if (this.filteredMunicipalitiesOptions.length === 0) {
                    this.formGroup
                        .get('clientFormControl')
                        .setErrors({ municipalityNotSelected: true });
                    this.formGroup.get('clientFormControl').patchValue(null);
                } else {
                    this.selectedMunicipality =
                        this.filteredMunicipalitiesOptions[0];
                    this.formGroup
                        .get('clientFormControl')
                        .patchValue(this.selectedMunicipality);
                }
            }
        });
    }
}
