import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { PermitInvolvementEnum } from '@furban/utilities';

@Component({
    selector: 'furban-permit-involvement',
    templateUrl: './permit-involvement.component.html',
    styleUrls: ['./permit-involvement.component.scss'],
})
export class PermitInvolvementComponent {
    constructor(private dialogRef: MatDialogRef<PermitInvolvementComponent>) { }

    public closePopup() {
        this.dialogRef.close();
    }

    public involveNeighbors() {
        this.dialogRef.close(PermitInvolvementEnum.neighbor);
    }

    public involveMunicipality() {
        this.dialogRef.close(PermitInvolvementEnum.municipality);
    }
}
