import { EventEmitter, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Comment } from '../_models/comment';
import { Environment } from '../_models/environment-model';

@Injectable({
    providedIn: 'root',
})
export class CommentsService {
    public highlightedComment: Comment;
    public highlightedCommentEmitter: EventEmitter<Comment> =
        new EventEmitter();
    public selectCommentEmitter: EventEmitter<boolean> = new EventEmitter();
    public removeCommentEmitter: EventEmitter<Comment> = new EventEmitter();
    public addPinCommentEmitter: EventEmitter<void> = new EventEmitter();
    public highlightPinSubject = new BehaviorSubject(false);
    public highlightPinObservable = this.highlightPinSubject.asObservable();

    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(@Inject('env') private environment: Environment, private http: HttpClient) { }

    deleteComment(comment: Comment): Observable<any> {
        return this.http
            .post(this.environment.apiPath + '/comment/delete', comment, {
                headers: this._headers,
            })
            .pipe(map((result) => result));
    }
}
