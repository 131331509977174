import { SubscriptionPlanColorEnum } from '../_enum/subscription-color-enum';

export class FurbanSubscription {
    public onboardingPrice?: string;
    public perMonthPrice?: string;
    public name: string;
    public benefits: string[];
    public labelColor: string;
    public emailBody: string;
    public emailSubject: string;

    public constructor(
        subscriptionType?: string,
        hasOnboarding?: boolean,
        numberOfBenefits?: number
    ) {
        this.labelColor = SubscriptionPlanColorEnum[subscriptionType];
        this.name = 'presentation.subscription.' + subscriptionType + '.name';
        this.perMonthPrice =
            'presentation.subscription.' + subscriptionType + '.perMonth';
        if (hasOnboarding) {
            this.onboardingPrice =
                'presentation.subscription.advanced.onboarding';
        }

        this.benefits = [];
        for (let benefitNo = 1; benefitNo <= numberOfBenefits; benefitNo++) {
            const benefit =
                'presentation.subscription.' +
                subscriptionType +
                '.benefit' +
                benefitNo;
            this.benefits.push(benefit);
        }

        this.emailBody =
            'presentation.subscription.' + subscriptionType + '.emailBody';
        this.emailSubject =
            'presentation.subscription.' + subscriptionType + '.emailSubject';
    }
}
