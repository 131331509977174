import { Inject, Injectable } from '@angular/core';
import { PathObject } from '../_models/path-object';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APP_CONFIG } from '@furban/app-config';
import { SilenceHttpParams } from '../_models/silence-http-param';
import { Environment } from '../_models/environment-model';

@Injectable({
    providedIn: 'root',
})
export class PathObjectsService {
    public defaultObjectsId = [];
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        private http: HttpClient
    ) { }

    getPathObjects(
        projectId: string,
        userprofileId: string
    ): Observable<PathObject[]> {
        const params = new HttpParams()
            .set('projectId', projectId)
            .set('userprofileId', userprofileId);
        return this.http
            .get(`${this.environment.apiPath}/project/path/objects`, {
                params: params,
            })
            .pipe(map((result) => result as PathObject[]));
    }

    getPathObjectsForDefaultDP(
        projectId: string,
        designProposalId: string
    ): Observable<PathObject[]> {
        const params = new HttpParams()
            .set('projectId', projectId)
            .set('designProposalId', designProposalId);
        return this.http
            .get(`${this.environment.apiPath}/design-proposal/path/objects`, {
                params: params,
            })
            .pipe(map((result) => result as PathObject[]));
    }

    getPathDefaultObjects(projectId: string): Observable<PathObject[]> {
        return this.http
            .get(
                `${this.environment.apiPath}/project/path/objects/default/${projectId}`
            )
            .pipe(map((result) => result as PathObject[]));
    }

    getLivePathObjects(dpId: string): Observable<PathObject[]> {
        return this.http
            .get(
                `${this.environment.apiPath}/project/path/objects/live/${dpId}`
            )
            .pipe(map((result) => result as PathObject[]));
    }

    savePathObjects(pathObjects: PathObject[]): Observable<PathObject[]> {
        return this.http
            .post(
                `${this.environment.apiPath}/project/path/objects`,
                pathObjects,
                {
                    headers: this._headers,
                    params: new SilenceHttpParams(true, false, false),
                }
            )
            .pipe(map((result) => result as PathObject[]));
    }

    updatePathObject(pathObject: PathObject): Observable<PathObject> {
        return this.http
            .put(
                `${this.environment.apiPath}/project/path/object`,
                pathObject,
                {
                    headers: this._headers,
                    params: new SilenceHttpParams(true, false, false),
                }
            )
            .pipe(map((result) => result as PathObject));
    }

    deletePathObjects(objectsToDelete: PathObject[]): Observable<void> {
        const httpOptions = {
            headers: this._headers,
            body: objectsToDelete,
            params: new SilenceHttpParams(true, false, false),
        };

        return this.http.delete<void>(
            `${this.environment.apiPath}/path-objects`,
            httpOptions
        );
    }
}
