export enum HouseFormValuesEnum {
    numberOfFloorsDefault = 1,
    numberOfFloorsMin = 1,
    numberOfFloorsMax = 20,
    floorHeightDefault = 260,
    floorHeightMin = 250,
    floorHeightMax = 400,
}

export enum ColorsDefaultValuesEnum {
    defaultHouseColor = '#755B4C',
    defaultRoofColor = '#544F47',
}
