import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { environment } from 'apps/furban-client/src/environments/environment';
import { Subscription } from 'rxjs';

import {
    ModalManager,
    StepperService,
    containSpaceErrorAndNotRequirredTriggered,
    FurbanUtil,
    CustomToasterComponent,
    DocumentModel,
    projectConstants,
    PermitProject,
    FileUploadService,
    CustomToasterService,
    ControlsUtil,
} from '@furban/utilities';
import { ImageUploadComponent } from '../../shared/image-upload/image-upload.component';
import { PermitProjectService } from './permit-project.service';

@Component({
    selector: 'furban-permit-project',
    templateUrl: './permit-project.component.html',
    styleUrls: ['./permit-project.component.scss'],
})
export class PermitProjectComponent implements OnInit, OnDestroy {
    public projectConstantValues = projectConstants;
    public projectCheckForm: FormGroup;
    public currentProject: PermitProject;
    public imageData: string;
    public documents: DocumentModel[];
    public today = new Date(Date.now());
    public datePipe = new DatePipe('en-US');
    public saveProjectSubscription: Subscription;

    public numberOfDocsInserted: number;
    public numberOfDocsOnProject: number;
    public minEndDate = new Date(Date.now());
    public minStartDate = new Date(
        this.today.setMonth(
            this.today.getMonth() -
            this.projectConstantValues
                .numberOfMonthsInPastForProrjectStartDate
        )
    );
    public noWhiteSpacesValidator = FurbanUtil.noWhiteSpacesValidator;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public containSpaceErrorAndNotRequirredTriggeredFunction =
        containSpaceErrorAndNotRequirredTriggered;

    private fileList: FileList;
    private formChangesSubscription: Subscription;
    public hasControlMaxError = ControlsUtil.hasControlMaxError;
    public hasControlMinError = ControlsUtil.hasControlMinError;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;
    public hasControlMaxLengthError = ControlsUtil.hasControlMaxLengthError;
    public hasControlMinLengthError = ControlsUtil.hasControlMinLengthError;
    public hasControlIsHtmlError = ControlsUtil.hasControlIsHtmlError;
    public hasControlWhitespacesError = ControlsUtil.hasControlWhitespacesError;
    public isApp = FurbanUtil.isApp;
    
    constructor(
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private viewContainerRef: ViewContainerRef,
        private customToasterService: CustomToasterService,
        private modal: ModalManager,
        private translateService: TranslateService,
        private stepperService: StepperService,
        private permitProjectService: PermitProjectService,
        private fileUploadService: FileUploadService
    ) {
        this.initializeCheckForm();
    }

    ngOnInit() {
        this.stepperService.changeProjectModifiedState(false);
        this.currentProject = this.stepperService.project as PermitProject;
        this.subscribeToProjectDetails();
        if (this.stepperService.projectId !== 'new') {
            this.getProjectDateFromSavedProject(this.currentProject);
        }

        this.formChangesSubscription =
            this.projectCheckForm.valueChanges.subscribe(() => {
                this.stepperService.changeProjectModifiedState(true);
            });
    }

    ngOnDestroy() {
        this.stepperService.changeProjectModifiedState(false);
        this.currentProject = null;
        this.saveProjectSubscription.unsubscribe();
    }

    initializeCheckForm() {
        this.projectCheckForm = this.formBuilder.group({
            projectNameFormControl: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(
                        this.projectConstantValues.displayNameMaxLength
                    ),
                    this.noWhiteSpacesValidator,
                    this.htmlValidator,
                ],
            ],
            descriptionFormControl: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(
                        this.projectConstantValues.descriptionMaxLength
                    ),
                    this.noWhiteSpacesValidator,
                    this.htmlValidator,
                ],
            ],
            projectStartDateFormControl: ['', [Validators.required]],
            projectEndDateFormControl: ['', [Validators.required]],
        });
    }

    subscribeToProjectDetails(): void {
        this.saveProjectSubscription =
            this.permitProjectService.saveProjectDetailsObservable$.subscribe(
                (data) => {
                    this.save();
                }
            );
    }

    getProjectDateFromSavedProject(project: PermitProject) {
        this.documents = project.documents;
        this.projectCheckForm
            .get('projectNameFormControl')
            .setValue(project.name, { emitEvent: false });
        this.projectCheckForm
            .get('descriptionFormControl')
            .setValue(project.description, { emitEvent: false });
        this.projectCheckForm
            .get('projectStartDateFormControl')
            .setValue(
                new Date(
                    this.getDateFormatted(project.startDate, this.datePipe)
                ),
                { emitEvent: false }
            );
        this.projectCheckForm
            .get('projectEndDateFormControl')
            .setValue(
                new Date(this.getDateFormatted(project.endDate, this.datePipe)),
                { emitEvent: false }
            );

        if (project.media) {
            this.imageData = `${environment.apiPath}/media/public/${project.media}`;
        }
    }

    public setProjectDetails() {
        this.currentProject.name = this.projectCheckForm
            .get('projectNameFormControl')
            .value.trim();
        this.currentProject.description = this.projectCheckForm
            .get('descriptionFormControl')
            .value.trim();
        this.currentProject.startDate = this.getDateFormatted(
            this.projectCheckForm.get('projectStartDateFormControl').value,
            this.datePipe
        );
        this.currentProject.endDate = this.getDateFormatted(
            this.projectCheckForm.get('projectEndDateFormControl').value,
            this.datePipe
        );
        this.currentProject.ended = null;
        this.currentProject.documents = this.documents;
    }

    openImageUploadDialog() {
        const imageDialogRef = this.dialog.open(ImageUploadComponent);
        imageDialogRef.disableClose = true;
        imageDialogRef.componentInstance.parentRef = this.viewContainerRef;
        imageDialogRef.componentInstance.imageWidth = 1800;
        imageDialogRef.componentInstance.imageHeight = 1000;
        imageDialogRef.componentInstance.imageUploadPath = '/project';

        const closeProfileSub =
            imageDialogRef.componentInstance.onImageUploadClose.subscribe(
                (data) => {
                    if (data) {
                        this.imageData = `${environment.apiPath}/media/public/${data}`;
                        this.currentProject.media = data;
                        this.stepperService.changeProjectModifiedState(true);
                    }

                    imageDialogRef.close();
                }
            );

        imageDialogRef.afterClosed().subscribe((result) => {
            closeProfileSub.unsubscribe();
        });

        imageDialogRef.backdropClick().subscribe(() => {
            imageDialogRef.componentInstance.closeImageUploadModal();
        });
    }

    public getDateFormatted(dateForm: string, datePipe: DatePipe) {
        return datePipe.transform(dateForm, 'yyyy-MM-dd');
    }

    public save(): void {
        if (this.projectCheckForm.invalid) {
            for (const key in this.projectCheckForm.controls) {
                if (Object.prototype.hasOwnProperty.call(this.projectCheckForm.controls, key)) {
                    const control: FormControl = <FormControl>(
                        this.projectCheckForm.controls[key]
                    );
                    control.markAsDirty();
                }
            }
            return;
        }

        this.setProjectDetails();

        if (!this.currentProject.projectId) {
            this.createProjectWithDocuments();
        } else {
            this.saveProject(this.currentProject);
        }

        this.stepperService.changeProjectModifiedState(false);
    }

    saveProject(project: PermitProject) {
        this.permitProjectService.saveProject(project).subscribe((data) => {
            if (data) {
                this.showConfirmationOnSave(data);
            }
        });
    }

    createProject(project: PermitProject) {
        this.permitProjectService.createProject(project).subscribe((data) => {
            this.showConfirmationOnSave(data);
        });
    }

    createProjectWithDocuments() {
        if (this.currentProject.documents) {
            this.fileUploadService
                .getFiles(this.currentProject.documents)
                .subscribe((data) => {
                    this.currentProject.documents = data;
                    this.createProject(this.currentProject);
                });
        } else {
            this.createProject(this.currentProject);
        }
    }

    fileChange(event) {
        this.fileList = event.target.files;
        this.numberOfDocsInserted = this.fileList.length;
        this.numberOfDocsOnProject = 0;

        if (this.documents) {
            this.numberOfDocsOnProject =
                this.numberOfDocsOnProject + this.documents.length;
        }

        if (
            this.numberOfDocsInserted > 0 &&
            this.numberOfDocsOnProject <
            this.projectConstantValues.maxNumberOfDocsAccepted
        ) {
            this.uploadFilesFromFormData();
        } else {
            this.displayUploadNotification(this.numberOfDocsInserted);
        }
    }

    uploadFilesFromFormData() {
        const formData: FormData = new FormData();
        for (let i = 0; i < this.numberOfDocsInserted; i++) {
            this.numberOfDocsOnProject++;
            if (
                this.numberOfDocsOnProject >
                this.projectConstantValues.maxNumberOfDocsAccepted
            ) {
                this.snackbarMessage(
                    'admin.projectDetails.exceedingNoDocs',
                    2000,
                    this.translateService.instant('generic.okBtn'),
                    'info'
                );
                break;
            }

            if (
                this.fileList[i].size >
                this.projectConstantValues.maxFileSizeLimit
            ) {
                this.snackbarMessage(
                    'admin.projectDetails.exceedingFileSize',
                    2000,
                    this.translateService.instant('generic.okBtn'),
                    'info'
                );
            } else {
                formData.append(
                    'files',
                    this.fileList[i],
                    FurbanUtil.reduceFileNameLength(this.fileList[i].name, 30)
                );
            }
        }
        this.uploadFiles(formData);
        this.stepperService.changeProjectModifiedState(true);
    }

    showConfirmationOnSave(data: any) {
        this.snackbarMessage(
            'admin.projectDetails.projectSaved',
            2000,
            this.translateService.instant('generic.okBtn'),
            'success'
        );
        this.currentProject = data as PermitProject;
        this.stepperService.regeneratePermitSteps(this.currentProject, true);

        this.projectCheckForm.markAsUntouched();
    }

    getProjectDescriptionPatternErrorMessage(): string {
        return this.projectCheckForm
            .get('descriptionFormControl')
            .value.trim() === ''
            ? this.translateService.instant(
                'admin.projectDetails.projectDescriptionBlankSpaces'
            )
            : this.translateService.instant(
                'admin.projectDetails.projectDescriptionInvalidCharacters'
            );
    }

    hasDocumentsOrNoWinner(): boolean {
        return this.documents.length !== 0;
    }

    clickUploadFileLink() {
        (<HTMLElement>(
            document.getElementsByClassName('file-upload-input')[0]
        )).click();
    }

    deleteDocumentLabel(permitDocument: DocumentModel) {
        this.showDeleteWarningSnackBar(this.deleteFile, permitDocument);
    }

    showDeleteWarningSnackBar(
        callbackFunction: any,
        permitDocument: DocumentModel
    ) {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.projectDetails.projectDeleteWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    if (permitDocument) {
                        callbackFunction.apply(this, [permitDocument]);
                        permitDocument.isActive = false;
                    } else {
                        callbackFunction.apply(this);
                    }
                }
            });
    }

    deleteFile(permitDocument: DocumentModel) {
        permitDocument.isActive = false;
        this.fileUploadService.deleteFile(permitDocument).subscribe();
        const indexOfDocument = FurbanUtil.findIndexWithAttr(
            this.documents,
            'documentId',
            permitDocument.documentId
        );
        this.documents.splice(indexOfDocument, 1);
    }

    snackbarMessage(
        message: string,
        duration: number,
        action?: string,
        type?: string
    ) {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            type === 'info' ? 'info' : '',
            type,
            message,
            duration
        );
    }

    uploadFiles(formData: FormData) {
        this.fileUploadService.uploadFiles(formData).subscribe((data) => {
            if (!this.documents) {
                this.documents = data;
            } else {
                this.documents = this.documents.concat(data);
            }
        });
    }

    downloadDocument(permitDocument: DocumentModel) {
        this.fileUploadService.openDocumentInNewTab(permitDocument.documentId);
    }

    displayUploadNotification(numberOfDocsInserted: number) {
        if (numberOfDocsInserted === 0) {
            this.snackbarMessage(
                'admin.projectDetails.noDocs',
                3000,
                this.translateService.instant('generic.okBtn'),
                'info'
            );
        } else {
            this.snackbarMessage(
                'admin.projectDetails.exceedingNoDocs',
                3000,
                this.translateService.instant('generic.okBtn'),
                'info'
            );
        }
    }
}
