export enum TextureColorEnum {
    neutral0 = '#000000',
    neutral1 = '#FFFFFF',
    neutral2 = '#B5B5B5',
    warm1 = '#F6B26B',
    warm2 = '#FFFAEB',
    warm3 = '#EFEBD8',
    alert3 = '#4a0000',
    lightBlue = '#325277',
    mildBlue = '#00578A',
    warning = '#fda50f',
    sceneHighlight = '#80A1C1',
    yellow = '#FFFF00',
}
