<div class="permit-viewer-container flex-row-start">
    <section *ngIf="house" id="three-cont" class="three-container">
        <furban-tooling>
            <ng-container buttons-type="main">
                <button class="primary-button" (click)="openReviewDialog()">
                    <h6>
                        {{
                            'pioneer.permitInvolvement.reviewPermit' | translate
                        }}
                    </h6>
                </button>
                <button
                    class="round-button"
                    matTooltip="{{
                        'buttonsTooltips.change3Dperspective' | translate
                    }}"
                    (click)="buttonPressed('changeCamera')"
                >
                    <furban-svg-container
                        [svgImage]="svgImageType.changePerspective"
                    ></furban-svg-container>
                </button>
                <button
                    class="round-button"
                    matTooltip="{{ 'buttonsTooltips.addComments' | translate }}"
                    (click)="addPinComments()"
                >
                    <span
                        class="material-icons"
                        [class.active]="addingCommentsEnabled"
                        >add_location</span
                    >
                </button>

                <button
                    class="round-button"
                    matTooltip="{{
                        'sunAnimation.toggleSunSlider' | translate
                    }}"
                    (click)="buttonPressed('toggleSunSlider')"
                >
                    <span class="material-icons">wb_sunny</span>
                </button>
            </ng-container>
        </furban-tooling>
        <furban-ngrx-permit-editor
            #permitThreeJs
            [deactivateControls]="true"
            [projectId]="house.projectId"
        >
        </furban-ngrx-permit-editor>
    </section>

    <section class="right-side-section" *ngIf="project">
        <section class="project-info-header">
            <div
                class="flex-row-spaced full-width"
                [style.margin-bottom.px]="12"
            >
                <div class="project-name padding-8-12 ellipsis">
                    {{ project.name }}
                </div>
            </div>
            <p
                class="description"
                [class.show-less]="showLess"
                (click)="toggleShowDescription()"
            >
                {{ project.description }}
            </p>
        </section>
        <div class="comments">
            <furban-permit-comments
                *ngIf="project"
                class="full-width"
                [project]="project"
                [viewContainerRef]="viewContainerRef"
                [isDialog]="false"
            >
            </furban-permit-comments>
        </div>
    </section>
</div>
