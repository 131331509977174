import * as THREE from 'three';

export class CanvasUtils {
    // REFACTOR NOT ANY
    public static triangulate = (points: number[], triangulateLibrary: any) => {
        // indices to coordinate "pairs" in the "flatPoints" array
        const indices = triangulateLibrary(points);
        const triangles = [];

        // three indices describe a triangle
        for (let i = 0; i < indices.length; i += 3) {
            const triangleIndices = [
                indices[i],
                indices[i + 1],
                indices[i + 2],
            ];
            const trianglePoints = triangleIndices.map((index) => {
                const x = points[index * 2];
                const y = points[index * 2 + 1];

                return [x, y];
            });

            // A triangle is a set of 3 points: [a, b, c] where each point has the form [x, y]
            triangles.push(trianglePoints);
        }
        return triangles;
    };

    public static flattenArrayOfPoints(arrayOfPoints: THREE.Vector3[]) {
        // [[x: x0, y: y0], [x: x1, y: y1], ...] ==> [x0,y0, x1,y1, ....]
        const flattenArray = [];
        arrayOfPoints.forEach((element) => {
            flattenArray.push(element.x);
            flattenArray.push(element.z);
        });
        return flattenArray.reverse();
    }

    public static selectRandomTriangle(triangles: number[][]) {
        const cumulativeDistribution = this.generateDistribution(triangles);
        const rnd = Math.random();
        const index = cumulativeDistribution.findIndex((v) => v > rnd);

        return triangles[index];
    }

    // Add documentation - REFACTOR
    public static calcRandomPoint(triangle: number[]) {
        let wb = Math.random();
        let wc = Math.random();

        // point will be outside of the triangle, invert weights
        if (wb + wc > 1) {
            wb = 1 - wb;
            wc = 1 - wc;
        }

        const [a, b, c] = triangle.map((coords) => ({
            x: coords[0],
            y: coords[1],
        }));

        const rbX = wb * (b.x - a.x);
        const rbY = wb * (b.y - a.y);
        const rcX = wc * (c.x - a.x);
        const rcY = wc * (c.y - a.y);

        const rX = rbX + rcX + a.x;
        const rY = rbY + rcY + a.y;

        return [rX, rY];
    }

    // This will remove the options for resize the object (only rotate will remain)
    public static setControlVisibility(obj: any) {
        obj.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: false,
            tl: false,
            br: false,
            bl: false,
        });
        // This needs to be set for touch devices to prevent resize with pitch
        obj.set({
            lockUniScaling: true,
            lockScalingX: true,
            lockScalingY: true,
        });
    }

    // This will change the design for the resize buttons
    public static setControlDesign(obj: any) {
        obj.set({
            transparentCorners: false,
            cornerColor: '#2d8735',
            cornerStrokeColor: 'white',
            borderColor: 'white',
            cornerSize: 20,
            padding: 2,
            cornerStyle: 'circle',
            borderDashArray: [3, 3],
        });
    }
    // REFACTOR OR ADD DOCUMETATION
    private static getTriangleArea(triangle: number[]) {
        const [a, b, c] = triangle;
        return (
            0.5 *
            ((b[0] - a[0]) * (c[1] - a[1]) - (c[0] - a[0]) * (b[1] - a[1]))
        );
    }

    private static generateDistribution(triangles: number[][]) {
        const totalArea: number = triangles.reduce(
            (sum, triangle) => sum + this.getTriangleArea(triangle),
            0
        );
        const cumulativeDistribution: number[] = [];

        for (let i = 0; i < triangles.length; i++) {
            const lastValue = cumulativeDistribution[i - 1] || 0;
            const nextValue =
                lastValue + this.getTriangleArea(triangles[i]) / totalArea;
            cumulativeDistribution.push(nextValue);
        }
        // [area1, area1 + aera2, area1 + area2 + area3, ...]
        return cumulativeDistribution;
    }
}
