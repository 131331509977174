import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    AuthService,
    CustomToasterService,
    DesignProposal,
    DesignProposalEnum,
    DesignProposalRequestParam,
    DesignProposalService,
    ModalManager,
    Project,
    routingConstants,
    SortTypeEnum,
    StepperService,
} from '@furban/utilities';
import { GenericMunicipalityCardsComponent } from '../generic-municipality-cards/generic-municipality-cards.component';
import { Location } from '@angular/common';
import { ProjectDetailsService } from '../project-details/project-details.service';

@Component({
    selector: 'furban-collaborative-sessions',
    templateUrl: './collaborative-sessions.component.html',
    styleUrls: ['./collaborative-sessions.component.scss'],
})
export class CollaborativeSessionsComponent
    extends GenericMunicipalityCardsComponent
    implements OnInit {
    private currentProject: Project;

    constructor(
        protected override stepperService: StepperService,
        protected override modal: ModalManager,
        protected override viewContainerRef: ViewContainerRef,
        protected override customToasterService: CustomToasterService,
        protected router: Router,
        protected override designProposalService: DesignProposalService,
        protected override route: ActivatedRoute,
        private location: Location,
        private authService: AuthService,
        private projectDetailsService: ProjectDetailsService
    ) {
        super(
            stepperService,
            modal,
            customToasterService,
            designProposalService,
            viewContainerRef,
            route
        );
    }

    public override ngOnInit(): void {
        super.ngOnInit();
        this.countDesignProposals();
        this.getProject();
        this.getDesignProposals(SortTypeEnum.SORT_BY_DATE);
    }

    public createCollaborativeDesign(): void {
        const newCollaborativeDesignProposal = DesignProposal.createCollaborativeDesignProposal(this.projectId);
        this.designProposalService.saveCollaborativeDesignProposal(newCollaborativeDesignProposal).subscribe((data) => {
            if (this.authService.isPioneer()) {
                this.router.navigateByUrl(`${routingConstants.initiativeProcess}/${this.projectId}/${routingConstants.collaborativeSessions}/${data.designProposalId}`);

            } else {
                this.router.navigateByUrl(`${routingConstants.process}/${this.projectId}/${routingConstants.collaborativeSessions}/${data.designProposalId}`);
            }
        });
    }

    public openLiveSession(dp: DesignProposal): void {
        if (this.authService.isPioneer()) {
            this.router.navigateByUrl(`${routingConstants.initiativeProcess}/${this.projectId}/${routingConstants.collaborativeSessions}/${dp.designProposalId}`);

        } else {
            this.router.navigateByUrl(`${routingConstants.process}/${this.projectId}/${routingConstants.collaborativeSessions}/${dp.designProposalId}`);
        }
    }

    public goBack(): void {
        this.location.back();
    }

    public isCreatingLiveSessionDisplayed(): boolean {
        return this.currentProject?.ended === false;
    }

    public override getPaginatedDesignProposals(
        dpRequestParam: DesignProposalRequestParam
    ): void {
        this.designProposalService
            .getCollaborativeLiveDesignProposals(dpRequestParam)
            .subscribe((data) => {
                this.curentDPs = this.curentDPs.concat(data);
            });
    }

    public override getDesignProposals(sortingCriteria: string): void {
        const dpRequestParam = new DesignProposalRequestParam(
            this.projectId,
            this.curentPage,
            sortingCriteria,
            DesignProposalEnum.allDesignProposals
        );
        this.designProposalService
            .getCollaborativeLiveDesignProposals(dpRequestParam)
            .subscribe((data) => {
                this.curentDPs = data;
                this.loaded = true;
            });
    }

    public override scrollToTop(): void {
        const element = document.getElementsByClassName('title-section')[0];
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    private countDesignProposals(): void {
        this.designProposalService
            .getCollaborativeDesignProposalCount(this.projectId)
            .subscribe((data) => {
                this.designProposalsCount = data;
            });
    }

    private getProject(): void {
        this.projectDetailsService
            .getProject(this.projectId)
            .subscribe((data) => {
                this.currentProject = data;
            });
    }
}
