import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ModalManager, Project3dModel, MenuService } from '@furban/utilities';

@Component({
    selector: 'furban-generate-green',
    templateUrl: './generate-green.component.html',
    styleUrls: ['./generate-green.component.scss'],
})
export class GenerateGreenComponent implements OnInit {
    @Input() public parentRef;
    @Input() public freeshapePoints: any;
    @Input() public projectId: any;

    public greenObjectCheckForm: FormGroup;
    public onModalClose = new EventEmitter();
    public availableGreenObjects: Project3dModel[];

    constructor(
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<GenerateGreenComponent>,
        private modal: ModalManager,
        public translateService: TranslateService,
        public menuService: MenuService
    ) { }

    ngOnInit() {
        this.getObjects();
    }

    getObjects() {
        this.greenObjectCheckForm = this.formBuilder.group({});
        this.menuService.getGreenOjects(this.projectId).subscribe((data) => {
            this.availableGreenObjects = data;
            this.availableGreenObjects.forEach((obj) => {
                this.greenObjectCheckForm.addControl(
                    obj.furban3DModel.objectLookId.toString(),
                    new FormControl('', [Validators.max(10), Validators.min(0)])
                );
                obj.furban3DModel.greenObjectsToGenerate = 0;
            });
        });
    }

    closeDialog() {
        // check if modified to save before close
        if (this.greenObjectCheckForm.dirty) {
            this.modal
                .showModal(
                    this.parentRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'errors.discardMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (res) {
                        this.dialogRef.close();
                    }
                });
        } else {
            this.dialogRef.close();
        }
    }

    composeImageString(id: number): string {
        return '/assets/images/objects/' + id + '/' + id + '_menu.png';
    }

    saveCustomObject() {
        if (this.greenObjectCheckForm.invalid) {
            this.greenObjectCheckForm.markAsDirty();
            return;
        }

        const mapObjects: Map<Project3dModel, number> = new Map<
            Project3dModel,
            number
        >();

        Object.keys(this.greenObjectCheckForm.controls).forEach(
            (key, index) => {
                this.availableGreenObjects.forEach((greenObject) => {
                    if (
                        greenObject.furban3DModel.objectLookId.toString() ===
                        key
                    ) {
                        mapObjects.set(
                            greenObject,
                            this.greenObjectCheckForm.get(key).value
                        );
                    }
                });
            }
        );

        this.onModalClose.emit(mapObjects);
    }
}
