import { Component, OnInit } from '@angular/core';

import { FurbanUtil } from '@furban/utilities';

@Component({
    selector: 'furban-city',
    templateUrl: './furban-city.component.html',
    styleUrls: [],
})
export class FurbanCityComponent implements OnInit {
    private appStoreLink =
        'https://apps.apple.com/us/app/furban-city/id1567182277?itsct=apps_box_badge&amp;itscg=30200';
    private googleStoreLink =
        'https://play.google.com/store/apps/details?id=com.Furban.FurbanCity';

    ngOnInit(): void {
        if (FurbanUtil.isIOS()) {
            window.location.replace(this.appStoreLink);
        } else {
            window.location.replace(this.googleStoreLink);
        }
    }
}
