<ng-container *ngIf="disabled">
    <div class="flex-row-start disabled">
        <h3>All</h3>
        <span class="material-icons"
              [style.margin-left.px]="8">
            expand_more
        </span>
    </div>
</ng-container>

<ng-container *ngIf="!disabled && projects && projects.length > 0">
    <ng-container *ngIf="projects.length === 1">
        <div class="flex-row-start"
             [class.pointer]="projects && projects.length > 1">
            <h3 class="project-name">{{ curentSelectedProject.name }}</h3>
        </div>
    </ng-container>
    <ng-container *ngIf="projects.length > 1">
        <div #trigger="matMenuTrigger"
             class="flex-row-start"
             [class.pointer]="projects && projects.length > 1"
             [matMenuTriggerFor]="projectsMenu">
            <h3 *ngIf="curentSelectedProject"
                class="project-name">
                {{ curentSelectedProject.name }}
            </h3>
            <h5 *ngIf="authService.isExpert()"
                matBadge="{{ curentSelectedProject.nrOfNotifications }}"
                matBadgeHidden="{{ curentSelectedProject.nrOfNotifications === 0 }}"></h5>
            <span class="material-icons"
                  [class.rotate]="trigger.menuOpen"
                  [style.margin-left.px]="8">
                expand_more
            </span>
            <mat-menu #projectsMenu="matMenu">
                <button *ngFor="let project of projects"
                        class="flex-row-start"
                        mat-menu-item
                        (click)="selectProject(project)">
                    <h3 class="project-name">{{ project.name }}</h3>
                    <h5 *ngIf="authService.isExpert()"
                        matBadge="{{ project.nrOfNotifications }}"
                        matBadgeHidden="{{ project.nrOfNotifications === 0 }}"></h5>
                </button>
            </mat-menu>
        </div>
    </ng-container>
</ng-container>