export enum DesignProposalRankEnum {
    first_place = 0,
    second_place = 1,
    third_place = 2,
}

export enum DesignProposalRankPointsEnum {
    first_place = 25,
    second_place = 18,
    third_place = 15,
}
