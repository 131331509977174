import { Component, Inject, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormControl } from '@angular/forms';

import {
    ModalManager,
    FurbanUtil,
    validateWhitespace,
    DesignProposal,
    ControlsUtil,
} from '@furban/utilities';

@Component({
    selector: 'furban-app-winner-popup',
    templateUrl: './winner-popup.component.html',
    styleUrls: ['./winner-popup.component.scss'],
})
export class WinnerPopupComponent {
    public parentViewContainerRef: ViewContainerRef;
    public designProposal: DesignProposal;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public hasControlError = ControlsUtil.hasControlError;

    public winnerMessage = new FormControl('', [
        this.htmlValidator,
        validateWhitespace,
        Validators.required,
        Validators.maxLength(2048),
    ]);

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<WinnerPopupComponent>,
        private modal: ModalManager
    ) {
        this.designProposal = data.designProposal;
    }

    closeDialog() {
        if (this.isModified()) {
            this.modal
                .showModal(
                    this.parentViewContainerRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'formChangesDialog.message',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res: any) => {
                    if (res) {
                        this.dialogRef.close();
                    }
                });
        } else {
            this.dialogRef.close();
        }
    }

    isModified(): boolean {
        if (this.winnerMessage.dirty) {
            return true;
        }
        return false;
    }

    saveWinner() {
        if (this.winnerMessage.invalid) {
            this.winnerMessage.markAsDirty();
            return;
        }

        this.designProposal.winner = true;
        this.designProposal.winnerMessage = this.winnerMessage.value;
        this.dialogRef.close(this.designProposal);
    }
}
