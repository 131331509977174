import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService, UserProfile } from '@furban/utilities';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    public signarure: string;

    private _talkingPerson: UserProfile;
    private _talkingConversationId: string;

    constructor(private http: HttpClient, private authService: AuthService) { }

    public set talkingPerson(value: UserProfile) {
        this._talkingPerson = value;
    }
    public get talkingPerson(): UserProfile {
        return this._talkingPerson;
    }

    public set talkingConversationId(value: string) {
        this._talkingConversationId = value;
    }
    public get talkingConversationId(): string {
        return this._talkingConversationId;
    }
}
