// REFACTOR - this should be only one enum

export enum ProjectTypeEnum {
    adminProject = 0,
    pioneerInitiativeProject = 1,
    pioneerPermitProject = 2,
}

export enum ProjectTypeNameEnum {
    adminProject = 'admin_project',
    pioneerInitiativeProject = 'initiative',
    pioneerPermitProject = 'permit',
}
