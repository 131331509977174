<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'notifications.create' | translate }}</h2>
    <button class="ghost-button" (click)="closePopup()">
        <span class="material-icons">close</span>
    </button>
</div>

<mat-dialog-content>
    <form class="flex-column-start" [formGroup]="notificationsForm">
        <mat-form-field class="full-width" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'notifications.message' | translate }}
            </mat-label>
            <textarea formControlName="message" matInput
                placeholder="{{ 'notifications.message' | translate }}"></textarea>
            <mat-hint>256 {{ 'notifications.characters' | translate }}</mat-hint>

            <mat-error *ngIf="hasControlRequiredError('message',notificationsForm)">
                {{ 'notifications.notificationMessageRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlIsHtmlError('message',notificationsForm)">
                {{ 'notifications.notificationMessageHTML' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlWhitespacesError('message',notificationsForm)">
                {{ 'notifications.notificationMessageWhitespace' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlMaxLengthError('message',notificationsForm)">
                {{ 'notifications.notificationMessageMax' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-radio-group *ngIf="checkIfIsSuperAdmin()" class="full-width" formControlName="type"
            [style.margin-bottom.px]="20" (change)="radioChange($event)">
            <mat-radio-button *ngFor="let radioButton of radioButtons" [value]="radioButton.radioButtonValue"
                [style.margin-right.px]="20">{{ radioButton.radioButtonName }}</mat-radio-button>
        </mat-radio-group>

        <ng-container *ngIf="showDropdown">
            <mat-form-field class="full-width" [style.margin-bottom.px]="20" appearance="outline">
                <mat-label>
                    {{ placeholder }}
                </mat-label>
                <mat-chip-list #chipList formControlName="chipListControl" aria-label="test">
                    <mat-chip *ngFor="let data of selectedData" [selectable]="selectable" [removable]="removable"
                        (removed)="remove(data)">
                        {{ data.notificationDropdownLabel }}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input [placeholder]="placeholder" aria-label="notificationDropdownLabel" matInput #dropDownInput
                        formControlName="dropdownControl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let dataItem of filteredData | async" [value]="dataItem">
                        {{ dataItem.notificationDropdownLabel }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="notificationsForm.get('chipListControl').errors">
                    {{
                    'notifications.notificationMessageRequired' | translate
                    }}
                </mat-error>
            </mat-form-field>
        </ng-container>

        <mat-form-field class="full-width" [style.margin-bottom.px]="20" (click)="picker.open()" appearance="outline">
            <mat-label>
                {{ 'notifications.expiringDate' | translate }}
            </mat-label>
            <input matInput autocomplete="off" placeholder="{{ 'notifications.expiringDate' | translate }}"
                formControlName="expiringDate" [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint>{{
                'notifications.expiringDefault' | translate
                }}</mat-hint>

            <mat-error *ngIf="hasControlDatePassedError('expiringDate',notificationsForm)">
                {{ 'notifications.expiringInThePast' | translate }}
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closePopup()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="sendNotification()">
        <h6>{{ 'generic.save' | translate }}</h6>
    </button>
</div>