import {
    Injectable,
    ViewContainerRef,
    ComponentFactoryResolver,
    ComponentFactory,
    ComponentRef,
} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { ModalComponent } from './furban-modal.component';
import { ModalConfigService } from './furban-modal.config';
import { ModalConfiguration } from './furban-modal.config';

@Injectable()
export class ModalManager {
    // sometimes we might need a PopupManager, but not now
    // public modals:ModalComponent[] = [];
    private factory: ComponentFactory<ModalComponent>;

    constructor(
        public configService: ModalConfigService,
        private resolver: ComponentFactoryResolver
    ) {
        this.factory =
            this.resolver.resolveComponentFactory<ModalComponent>(
                ModalComponent
            );
    }

    /**
     * sintactic sugar utility
     * @param title
     * @param message
     * @param okLabel
     * @param cancelLabel if present the modal will display two buttons
     */
    public static createConfiguration(
        title: string,
        message: string,
        okLabel: string = 'OK',
        cancelLabel?: string,
        callback?: any,
        cancelCallback?: any,
        scope?: any
    ): ModalConfiguration {
        return new ModalConfiguration(
            title,
            message,
            okLabel,
            cancelLabel,
            callback,
            cancelCallback,
            scope
        );
    }

    /**
     * displays a simple prompt with a title a message body and a dismiss button
     * @param viewContainerRef the refference where the container will be placed
     * @param conf a configuration object {title, message, buttonLabel}
     * @returns Observable
     */
    public showModal(
        viewContainerRef: ViewContainerRef,
        conf: ModalConfiguration
    ): Observable<any> {
        this.configService.conf = conf;
        const ref: ComponentRef<ModalComponent> =
            viewContainerRef.createComponent(this.factory);
        const component: ModalComponent = ref.instance;
        const replySubject: ReplaySubject<any> = new ReplaySubject(1);
        // configure the observable return value
        // and remove the modal dialog.
        component.modalOutput.subscribe(
            (arg: any) => {
                ref.destroy();
                replySubject.next(arg);
            },
            (err: any) => {
                ref.destroy();
                replySubject.error(err);
            },
            () => {
                ref.destroy();
                replySubject.complete();
            }
        );
        return replySubject;
    }
}
