<div class="important-space-between" mat-dialog-title>
    <h2 *ngIf="type === 'create'">{{ 'generic.create' | translate }}</h2>
    <h2 *ngIf="type === 'select'">
        {{ 'admin.groups.addExisting' | translate }}
    </h2>

    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons"> close </span>
    </button>
</div>

<mat-dialog-content>
    <form [formGroup]="groupForm" *ngIf="type === 'create'">
        <mat-form-field class="full-width grey" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'admin.groups.groupName' | translate }}
            </mat-label>
            <input matInput #projectName maxlength="32" placeholder="{{ 'admin.groups.groupName' | translate }}"
                formControlName="groupName" />
            <mat-hint>{{ 'admin.groups.unique' | translate }}</mat-hint>
            <mat-error *ngIf="hasControlRequiredError('groupName',groupForm)">
                {{ 'admin.groups.groupNameRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlIsHtmlError('groupName',groupForm)">
                {{ 'comments.noHTMLContent' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlMaxLengthError('groupName',groupForm)">
                {{ 'admin.groups.groupNameMaxLength' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlWhitespacesError('groupName',groupForm)">
                {{ 'admin.groups.whitespace' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width grey" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'admin.groups.noOfCitizens' | translate }}
            </mat-label>
            <input type="number" name="inputNumber" class="small-input" formControlName="citizensCount" matInput
                placeholder="{{ 'admin.groups.noOfCitizens' | translate }}" />
            <mat-error *ngIf="hasControlMaxError('citizensCount',groupForm)">
                {{ 'admin.groups.maxCitizen' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlMinError('citizensCount',groupForm)">
                {{ 'admin.groups.negativaValidation' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width grey" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'admin.groups.noOfExperts' | translate }}
            </mat-label>
            <input type="number" name="inputNumber" class="small-input" formControlName="expertsCount" matInput
                placeholder="{{ 'admin.groups.noOfExperts' | translate }}" />
            <mat-error *ngIf="hasControlMaxError('expertsCount',groupForm)">
                Maximum experts to add: {{ 5 - noOfExperts }} experts
            </mat-error>
            <mat-error *ngIf="hasControlMinError('expertsCount',groupForm)">
                {{ 'admin.groups.negativaValidation' | translate }}
            </mat-error>
        </mat-form-field>
        <div class="warning-message">
            <h4>{{ 'errors.warning' | translate }}!</h4>
            <h5>
                {{
                'admin.groups.maxExpertsWarrning'
                | translate
                : {
                noOfExperts: noOfExperts,
                remainingNoOfExperts: 5 - noOfExperts
                }
                }}
            </h5>
        </div>
    </form>

    <ng-container *ngIf="allGroups && type === 'select'">
        <mat-form-field class="full-width grey" [style.margin-bottom.px]="20" appearance="outline">
            <mat-label>
                {{ 'admin.groups.select' | translate }}
            </mat-label>
            <mat-select disableOptionCentering placeholder="{{ 'admin.groups.select' | translate }}"
                [formControl]="selectedGroup">
                <mat-option *ngFor="let group of allGroups" [value]="group">
                    {{ group.groupName }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="hasControlError(selectedGroup,'max')">
                {{
                'admin.groups.maxExpertError'
                | translate
                : {
                noOfExperts: selectedGroup.value.expertsCount,
                remainingNoOfExperts: 5 - noOfExperts
                }
                }}
            </mat-error>
        </mat-form-field>
        <div class="warning-message">
            <h4>{{ 'errors.warning' | translate }}!</h4>
            <h5>
                {{
                'admin.groups.maxExpertsWarrning'
                | translate
                : {
                noOfExperts: noOfExperts,
                remainingNoOfExperts: 5 - noOfExperts
                }
                }}
            </h5>
        </div>
    </ng-container>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closeDialog()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="saveGroup()">
        <h6>{{ 'generic.save' | translate }}</h6>
    </button>
</div>