import { NgModule } from '@angular/core';
import { PioneerDashboardComponent } from './pioneer-dashboard/pioneer-dashboard.component';
import { PioneerRoutingModule } from './pioneer-routing.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { PioneerLoginGuard } from './guards/pioneer-login.guard';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { ProjectSharedModule } from '../project-shared/project-shared.module';
import { PioneerProjectsComponent } from './pioneer-projects/pioneer-projects.component';

import { UtilitiesModule } from '@furban/utilities';
import { environment } from '../../environments/environment';

@NgModule({
    declarations: [
        PioneerDashboardComponent,
        EmailVerificationComponent,
        PioneerProjectsComponent,
    ],
    imports: [
        PioneerRoutingModule,
        SharedModule,
        UtilitiesModule.forRoot(environment),
        ProjectSharedModule,
    ],
    providers: [RouterModule, PioneerLoginGuard],
})
export class PioneerModule { }
