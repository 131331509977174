import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import {
    AuthService,
    ControlsUtil,
    DesignProposal,
    MediaService,
    ModalManager,
    UserProfile,
} from '@furban/utilities';
import { UserProfileService } from '../../municipality/user/user-profile/user-profile.service';
import { CollaborativeDesignService } from '../collaborative-design/collaborative-design.service';

@Component({
    selector: 'furban-manage-collaborative-users',
    templateUrl: './manage-collaborative-users.component.html',
    styleUrls: ['./manage-collaborative-users.component.scss'],
})
export class ManageCollaborativeUsersComponent implements OnInit {
    @Input() public isAdmin: boolean;
    @Input() public designProposal: DesignProposal;
    @Input() public shouldDisableAddingNewUsers: boolean;


    public inviteUserFormControl: FormControl;
    public hasControlError = ControlsUtil.hasControlError;

    constructor(
        private collaborativeDesignService: CollaborativeDesignService,
        private authService: AuthService,
        private dialogRef: MatDialogRef<ManageCollaborativeUsersComponent>,
        private userProfileService: UserProfileService,
        private viewContainerRef: ViewContainerRef,
        private mediaService: MediaService,
        private modal: ModalManager
    ) { }

    ngOnInit() {
        this.inviteUserFormControl = new FormControl('', [Validators.email]);
    }

    public get contributorsList(): UserProfile[] {
        return this.collaborativeDesignService.contributors;
    }

    public getUserMedia(mediaId: string): string {
        return this.mediaService.getUserMedia(mediaId);
    }

    public closePopup(): void {
        if (this.inviteUserFormControl.dirty) {
            this.modal
                .showModal(
                    this.viewContainerRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'errors.discardMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (res) {
                        this.dialogRef.close();
                    }
                });
        } else {
            this.dialogRef.close();
        }
    }

    public shouldAddNewUsers(): boolean {
        return this.isAdmin && !this.shouldDisableAddingNewUsers;
    }

    public addUser(): void {
        if (this.inviteUserFormControl.invalid) {
            return;
        }
        this.inviteNewUser();
        this.inviteUserFormControl.reset();
    }

    public removeUser(userProfileId: string): void {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'collaborativeDesign.deleteWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (!res) {
                    return;
                }
                this.deactivateUser(userProfileId);
            });
    }

    private deactivateUser(userProfileId: string): void {
        this.authService
            .removeUserByUserProfileId(userProfileId)
            .subscribe(() => {
                const index = this.collaborativeDesignService.contributors
                    .map((contributor) => {
                        return contributor.userProfileId;
                    })
                    .indexOf(userProfileId);

                this.collaborativeDesignService.contributors.splice(index, 1);
            });
    }

    private inviteNewUser() {
        this.collaborativeDesignService
            .inviteUsers(
                [this.inviteUserFormControl.value],
                this.designProposal.groupId,
                true
            )
            .subscribe(() => {
                this.updateContributorsList();
            });
    }

    private updateContributorsList(): void {
        this.userProfileService
            .getAllCollaboratorUserProfies(this.designProposal.groupId)
            .subscribe((data) => {
                this.collaborativeDesignService.contributors = data;
            });
    }
}
