<div class="main-container overflow" infiniteScroll [infiniteScrollThrottle]="300" [scrollWindow]="false"
  (scrolled)="onParentScrollDown()">
  <ng-container *ngIf="!isPreviewPopup">
    <div class="flex-row-spaced wrap padding-24-0">
      <div class="flex-row-start padding-24-0 header-container">
        <span class="material-icons pointer" matTooltip="{{ 'generic.backBtn' | translate }}" (click)="goToLastRoute()">
          chevron_left
        </span>
        <ng-container *ngIf="group">
          <h3 class="page-title">{{ group.groupName }}</h3>
          <span class="material-icons pointer" style="font-size: 18px"
            matTooltip="{{ 'admin.manageGroups.editGroup' | translate }}" (click)="openEditPopup()">
            edit
          </span>
        </ng-container>
      </div>
      <section class="action-buttons">
        <button *ngIf="!codeServiceInstance.selection.isEmpty()" class="red-button" (click)="deleteCodes()">
          <div class="delete-btn">
            <span class="material-icons"> delete_forever </span>
            <h6>
              {{ 'admin.projectCodes.deleteCodes' | translate }}
              {{ codeServiceInstance.selection.selected.length }} codes
            </h6>
          </div>
        </button>
        <button *ngIf="showResetButton()" class="secondary-button" (click)="resetFilters()">
          <h6>{{ 'admin.projectCodes.resetFilters' | translate }}</h6>
        </button>
        <ng-container *ngIf="!isApp()">
          <button class="secondary-button" [matMenuTriggerFor]="menu">
            <h6>{{ 'admin.projectCodes.button1' | translate }}</h6>
            <span class="material-icons-outlined">
              expand_more
            </span>
          </button>
          <mat-menu #menu="matMenu" overlapTrigger="false">
            <ng-container *ngIf="isPioneer; else exportAdmin">
              <button mat-menu-item (click)="exportNeighborCodes()" value="neighborExportCsv">
                {{ 'admin.manageGroups.neighborCodes' | translate }}
              </button>
            </ng-container>
            <ng-template #exportAdmin>
              <button mat-menu-item (click)="exportCodes($event)" value="citizenExportCsv">
                {{ 'admin.manageGroups.citizenCodes' | translate }}
              </button>
              <button mat-menu-item (click)="exportCodes($event)" value="expertExportCsv">
                {{ 'admin.manageGroups.expertCodes' | translate }}
              </button>
            </ng-template>
          </mat-menu>
        </ng-container>
        <button class="primary-button square" [matMenuTriggerFor]="menuAdd">
          {{ 'admin.manageGroups.addCodes' | translate }}
          <span class="material-icons-outlined">
            expand_more
          </span>
        </button>
        <mat-menu #menuAdd="matMenu" overlapTrigger="false">
          <button mat-menu-item (click)="openAddExistingCodeModal()" value="citizenExportCsv">
            {{ 'admin.codes.addExisting' | translate }}
          </button>
          <button mat-menu-item (click)="openGenerateCodesModal()" value="expertExportCsv">
            {{ 'admin.codes.generateCodes' | translate }}
          </button>

          <button mat-menu-item (click)="openSendEmailModal()">
            {{ 'admin.codes.emailCodes' | translate }}
          </button>
        </mat-menu>
      </section>
    </div>
  </ng-container>
  <table mat-table class="full-width" [dataSource]="dataSource">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="
            codeServiceInstance.selection.hasValue() && isAllSelected()
          " [indeterminate]="
            codeServiceInstance.selection.hasValue() && !isAllSelected()
          ">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? codeServiceInstance.selection.toggle(row) : null"
          [checked]="codeServiceInstance.selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Role Column -->
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>
        <div class="flex-row-start">
          <p class="bold">{{ 'admin.codes.role' | translate }}</p>
          <span id="role" class="material-icons-round filter-icon pointer" [class.active]="checkAppliedFilterForRole()"
            (click)="showFilter(codeFilterEnum.roleFilter)"
            matTooltip="{{ 'admin.groups.filter' | translate }}">filter_alt</span>
        </div>
      </th>
      <td mat-cell *matCellDef="let row">
        {{ 'admin.projectGenerate.' + row.roleName | translate }}
      </td>
    </ng-container>
    <!-- Code Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef>
        <div class="flex-row-start relative">
          <p class="bold">
            {{ 'admin.projectCodes.table.collumn2' | translate }}
          </p>
          <span id="code" class="material-icons-round filter-icon pointer" [class.active]="checkAppliedFilterForCode()"
            (click)="showFilter(codeFilterEnum.codeFilter)"
            matTooltip="{{ 'admin.groups.filter' | translate }}">filter_alt</span>
        </div>
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.username }}

        <!-- <ng-container *ngIf="isApp()">
          <span class="material-icons" (click)="onCodeCopy(row.username)">
            content_copy
          </span>
        </ng-container> -->
      </td>
    </ng-container>

    <!-- Created Date Column -->
    <ng-container matColumnDef="createdDate">
      <th mat-header-cell *matHeaderCellDef>
        <div class="flex-row-start relative">
          <p class="bold">
            {{ 'admin.projectCodes.table.collumn4' | translate }}
          </p>
          <span id="date" class="material-icons-round filter-icon pointer" [class.active]="checkAppliedFilterForDate()"
            (click)="showFilter(codeFilterEnum.dateFilter)"
            matTooltip="{{ 'admin.groups.filter' | translate }}">filter_alt</span>
        </div>
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.createdDate | date : 'dd MMMM yyyy' }}
      </td>
    </ng-container>

    <!-- Email sent Column -->
    <ng-container matColumnDef="emailSent">
      <th mat-header-cell *matHeaderCellDef>
        <div class="flex-row-start relative">
          <p class="bold">
            {{ 'admin.projectCodes.table.collumn5' | translate }}
          </p>
          <span id="emailSent" class="material-icons-round filter-icon pointer"
            [class.active]="checkAppliedFilterForEmail()" (click)="showFilter(codeFilterEnum.emailSentFilter)"
            matTooltip="{{ 'admin.groups.filter' | translate }}">filter_alt</span>
        </div>
      </th>
      <td mat-cell *matCellDef="let row" class="align-icon">
        <div class="text-container">
          <span class="material-icons-outlined email-status" [class.active]="row.emailSent"
            [class.inactive]="!row.emailSent">
            {{ row.emailSent ? 'task_alt' : 'cancel' }}
          </span>
          {{ getTranslatedEmailStatus(row) }}
        </div>
      </td>
    </ng-container>

    <!-- Active User Column -->
    <ng-container matColumnDef="activeUser">
      <th mat-header-cell *matHeaderCellDef>
        <p class="bold">
          {{ 'admin.projectCodes.table.collumn6' | translate }}
        </p>
      </th>
      <td mat-cell *matCellDef="let row" class="align-icon">
        <div class="status-label" [class.active]="row.acceptedTOS" [class.inactive]="!row.acceptedTOS">
          {{ getTranslatedStatusLabel(row) }}
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <ng-container *ngIf="hasData">
    <div class="no-groups-container">
      <img src=" ../../../assets/images/no_data.svg" />
      <h4 class="placeholder-text">
        {{ 'admin.projectCodes.noCodesForDates' | translate }}
      </h4>
    </div>
  </ng-container>

  <ng-container *ngIf="checkAppliedFilter(codeFilterEnum.codeFilter)">
    <furban-table-filter [title]="'admin.projectCodes.searchCode'" [buttonText]="'admin.projectCodes.searchCode'"
      [filterId]="'code'" [disableAction]="!userCode.value || userCode.invalid"
      (cancelCallback)="showFilter(codeFilterEnum.noFilters)" (okCallback)="findByCode()">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>
          {{ 'admin.projectCodes.name' | translate }}
        </mat-label>
        <input matInput placeholder="{{ 'admin.projectCodes.name' | translate }}" maxlength="40"
          [formControl]="userCode" />
        <mat-error *ngIf="hasControlError(userCode, 'isHTML')">
          {{ 'comments.noHTMLContent' | translate }}
        </mat-error>
      </mat-form-field>
    </furban-table-filter>
  </ng-container>
  <ng-container *ngIf="checkAppliedFilter(codeFilterEnum.dateFilter)">
    <furban-table-filter [title]="'admin.projectCodes.filterByDate'" [buttonText]="'admin.projectCodes.filterButton'"
      [filterId]="'date'" [disableAction]="
        startDate.value && endDate.value && endDate.value < startDate.value
      " (cancelCallback)="showFilter(codeFilterEnum.noFilters)" (okCallback)="filter('isDateFiltering')">
      <div class="flex-row-spaced full-width relative">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>
            {{ 'admin.projectDetails.chooseStartDate' | translate }}
          </mat-label>
          <input matInput readonly placeholder="{{
              'admin.projectDetails.chooseStartDate' | translate
            }}" [matDatepicker]="startDatePicker" [formControl]="startDate" (click)="startDatePicker.open()" />

          <span class="material-icons date-close" (click)="startDate.reset()"
            *ngIf="startDate.value !== null">close</span>

          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

      </div>

      <div class="flex-row-spaced full-width relative">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>
            {{ 'admin.projectDetails.chooseEndDate' | translate }}
          </mat-label>
          <input matInput readonly placeholder="{{ 'admin.projectDetails.chooseEndDate' | translate }}"
            [matDatepicker]="endDatePicker" [formControl]="endDate" (click)="endDatePicker.open()" />
          <span class="material-icons date-close" (click)="endDate.reset()" *ngIf="endDate.value !== null">close</span>
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </furban-table-filter>
  </ng-container>
  <ng-container *ngIf="checkAppliedFilter(codeFilterEnum.roleFilter)">
    <furban-table-filter [title]="'admin.codes.filterByRole'" [buttonText]="'admin.projectCodes.filterButton'"
      [filterId]="'role'" (cancelCallback)="showFilter(codeFilterEnum.noFilters)"
      (okCallback)="filter('isRoleFiltering')">
      <mat-radio-group [(ngModel)]="selectedRole">
        <mat-radio-button value="expert">
          {{ 'admin.projectGenerate.ROLE_EXPERT' | translate }}
        </mat-radio-button>
        <mat-radio-button value="citizen">
          {{ 'admin.projectGenerate.ROLE_USER' | translate }}
        </mat-radio-button>
        <mat-radio-button value="collaborator">
          {{ 'admin.projectGenerate.ROLE_COLLABORATOR' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </furban-table-filter>
  </ng-container>

  <ng-container *ngIf="checkAppliedFilter(codeFilterEnum.emailSentFilter)">
    <furban-table-filter [title]="'admin.codes.filterByEmailSent'" [buttonText]="'admin.projectCodes.filterButton'"
      [filterId]="'emailSent'" (cancelCallback)="showFilter(codeFilterEnum.noFilters)"
      (okCallback)="filter('isEmailSentFiltering')">
      <mat-radio-group [(ngModel)]="isEmailSentValue">
        <mat-radio-button value="true">
          {{ 'admin.codes.sent' | translate }}
        </mat-radio-button>
        <mat-radio-button value="false">
          {{ 'admin.codes.notSent' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </furban-table-filter>
  </ng-container>
</div>