import { Component, Input, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ProjectOverview } from '@furban/utilities';

@Component({
    selector: 'furban-project-overview-dialog',
    templateUrl: './project-overview-dialog.component.html',
    styleUrls: ['./project-overview-dialog.component.scss'],
})
export class ProjectOverviewDialogComponent {
    @Input() public parentRef;

    public onModalClose = new EventEmitter();
    public projectOverview: ProjectOverview;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<ProjectOverviewDialogComponent>
    ) {
        this.projectOverview = data;
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public publish(): void {
        this.onModalClose.emit(true);
    }
}
