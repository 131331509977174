
import { CustomToasterService, StepperService } from '@furban/utilities';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectDetailsService } from '../project-details/project-details.service';
import { Router, ActivatedRoute } from '@angular/router';

import {
    CustomToasterComponent,
    ModalManager,
    Project,
    ProjectStatusEnum,
    ProjectStorageService,
    ProjectTypeNameEnum,
    routingConstants,
    DesignProposalService,
    ProjectService,
    ProjectStatusNameEnum,
    FurbanUtil,
} from '@furban/utilities';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EmbededPopupComponent } from '../embeded-popup/embeded-popup.component';


@Component({
    selector: 'furban-app-project-activate',
    templateUrl: './project-activate.component.html',
    styleUrls: ['./project-activate.component.scss'],
})
export class ProjectActivateComponent implements OnInit {
    public project: Project;
    public projectStatus = ProjectStatusEnum;
    public projectType: string;

    constructor(
        private projectService: ProjectService,
        private translateService: TranslateService,
        private projectDetailsService: ProjectDetailsService,
        private modal: ModalManager,
        private containerRef: ViewContainerRef,
        private router: Router,
        private customToasterService: CustomToasterService,
        private projectStorageService: ProjectStorageService,
        private route: ActivatedRoute,
        private designProposalService: DesignProposalService,
        private stepperService: StepperService,
        private viewContainerRef: ViewContainerRef,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.getProjectType();
        this.initialSetup();
        this.getAllStatus();
    }

    public getProject() {
        this.projectDetailsService
            .getProject(this.project.projectId)
            .subscribe((data) => {

                if (!data) {
                    return;
                }

                this.projectDetailsService.updateProjectInList(data);
                this.statusChangeHandler(data);
            });
    }

    public showChangeStatusProjectWarning(status: string) {
        if (!this.checkIfHasDrawing()) {
            return;
        }
        if (this.checkIfIsCitizenDesignsOrIsUnpublishing(status)) {
            this.checkStatus(status);
            return;
        }
        this.changeStatus(status);

    }

    public changeStatus(status: string) {
        const project: Project = this.stepperService.project as Project;
        project.projectStatus = this.projectService.getStatus(status);

        this.projectDetailsService
            .updateProjectStatus(project)
            .subscribe((data) => {
                if (
                    data.projectStatus.statusWeight ===
                    ProjectStatusEnum.published
                ) {
                    this.customToasterService.openCustomToaster(
                        CustomToasterComponent,
                        'check_circle',
                        'success',
                        'admin.projectActivate.projectStatusSuccess',
                        2000
                    );
                }
                this.statusChangeHandler(data);
            });
    }

    public getAllStatus() {
        if (!this.projectService.projectStatuses) {
            this.projectDetailsService.getAllStatus().subscribe((data) => {
                this.projectService.projectStatuses = data;
            });
        }
    }

    public statusChangeHandler(project: Project) {
        this.project = project;
        this.stepperService.project = project;
    }

    public getProjectStatusWeight(): number {
        return this.project?.projectStatus.statusWeight;
    }

    public showRemoveProjectWarning() {
        this.modal
            .showModal(
                this.containerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.projectActivate.projectDeleteMessage',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.remove();
                }
            });
    }

    public remove() {
        const project: Project = this.stepperService.project as Project;
        project.isActive = false;

        this.projectDetailsService
            .updateProjectStatus(project)
            .subscribe((data) => {
                this.projectStorageService.emitOnProjectListModified();
                if (this.projectType === ProjectTypeNameEnum.adminProject) {
                    this.router.navigateByUrl(routingConstants.adminDashboard);
                } else {
                    this.router.navigateByUrl(
                        routingConstants.initiativesProjectList
                    );
                }
            });
    }

    public createEmbededLink(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.minWidth = '400px';
        dialogConfig.width = '40%';

        dialogConfig.data = {
            iframeText: FurbanUtil.generateIframeString(
                this.project.projectId,
                true
            ),
        };

        const userProfileDialogRef = this.dialog.open(
            EmbededPopupComponent,
            dialogConfig
        );
        userProfileDialogRef.componentInstance.parentViewContainerRef =
            this.viewContainerRef;
    }

    archiveProject() {
        this.modal
            .showModal(
                this.containerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.projectActivate.archiveWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.changeStatus('archived');
                }
            });
    }

    getParticipationDoc() {
        this.projectService
            .generateParticipationDocument(this.project.projectId)
            .subscribe((data) => {
                const documentData = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                });
                const url = window.URL.createObjectURL(documentData);
                const anchor = document.createElement('a');
                anchor.download = this.translateService.instant(
                    'fileGenerate.fileName'
                );
                anchor.href = url;
                anchor.click();
            });
    }

    public endProjectNow(): void {
        this.projectService
            .endProjectNow(this.project.projectId)
            .subscribe(() => {
                this.getProject();
            });
    }

    public checkIfNotArchived(): boolean {
        return (
            this.project &&
            this.project.ended &&
            this.project.hasWinner &&
            this.getProjectStatusWeight() !== this.projectStatus.archived
        );
    }

    private checkIfHasDrawing(): boolean {
        if (this.projectService.hasDrawing) {
            return true;
        }
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'warning',
            'info',
            'admin.projectDetails.pathUnsavedWarning',
            2000
        );
        return false;
    }

    private checkIfIsCitizenDesignsOrIsUnpublishing(status: string): boolean {
        return (
            FurbanUtil.isCollaborativeProject(this.project) ||
            status === ProjectStatusNameEnum.unpublished
        );
    }

    private getProjectType(): void {
        this.route.data.subscribe((data: any) => {
            this.projectType = data['type'] as string;
        });
    }

    private checkStatus(status: string) {
        if (status === ProjectStatusNameEnum.unpublished) {
            this.modal
                .showModal(
                    this.containerRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'admin.projectActivate.projectChangeStatusMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (!res) {
                        return;
                    }
                    this.changeStatus(status);
                });
        } else {
            this.changeStatus(status);
        }
    }

    private initialSetup() {
        this.project = this.stepperService.project as Project;
    }
}
