import * as THREE from 'three';

export class AssetSkeletonProperties {
    public distance: number;
    public position: THREE.Vector3;
    public rotation: number;

    constructor(distance: number, position: THREE.Vector3, rotation: number) {
        this.distance = distance;
        this.position = position;
        this.rotation = rotation;
    }
}
