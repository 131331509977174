import { Router } from '@angular/router';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';

import {
    AuthService,
    ControlsUtil,
    CustomToasterComponent,
    CustomToasterService,
    ExportFilesUtil,
    FurbanUtil,
    Group,
    GroupsFilter,
    ModalManager,
    routingConstants,
    validateWhitespace,
} from '@furban/utilities';
import { AddGroupPopupComponent } from '../add-group-popup/add-group-popup.component';
import { DisplayProjectsPopupComponent } from '../display-projects-popup/display-projects-popup.component';
import { GroupsService } from './groups.service';
import { CodeGroupPopupComponent } from '../code-group-popup/code-group-popup.component';
import { CodesService } from 'apps/furban-client/src/app/shared/_services/codes.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'furban-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {
    public displayedColumns: string[] = [
        'select',
        'groupName',
        'createdDate',
        'numberOfCitizens',
        'numberOfExperts',
        'numberOfProjects',
        'edit',
    ];
    public dataSource: MatTableDataSource<Group>;
    public selection = new SelectionModel<any>(true, []);
    public groups: Group[];
    public groupsFilter: GroupsFilter = new GroupsFilter();
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public groupName = new FormControl('', [
        this.htmlValidator,
        validateWhitespace,
    ]);
    public areGroupsLoaded = false;
    public hasControlError = ControlsUtil.hasControlError;

    public isApp = FurbanUtil.isApp;

    public get isPioneer(): boolean {
        return this.authService.isPioneer();
    }

    public get noExistingGroups(): boolean {
        return (
            this.areGroupsLoaded &&
            this.isNotEmptyProject() &&
            !this.groupsFilter.isGroupSearch
        );
    }

    constructor(
        public dialog: MatDialog,
        public router: Router,
        public modalManager: ModalManager,
        private codesService: CodesService,
        private groupsService: GroupsService,
        private containerRef: ViewContainerRef,
        private customToasterService: CustomToasterService,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        this.intialiseTable();
    }

    public exportNeighborCodes(group: Group): void {
        this.codesService.exportNeighborsCodes(group.groupId).subscribe((data) => {
            ExportFilesUtil.createCSV(data, 'neighbourExportCsv_codes_');
        });
    }

    public checkAppliedFilter(filter: number): boolean {
        return this.groupsFilter.filterToBeShown === filter;
    }

    public isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    public hasDataToDisplay(): boolean {
        return this.isNotEmptyProject() && this.groupsFilter.isGroupSearch;
    }

    public hasGroups(): boolean {
        return this.groups && this.groups.length > 0;
    }

    public changeGroup(group: Group): void {
        this.router.navigate([routingConstants.adminCodes, group.groupId]);
    }

    public showFilter(filter: number): void {
        this.groupsFilter.filterToBeShown = filter;
    }

    public masterToggle(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.dataSource.data.forEach((row) => this.selection.select(row));
        }
    }

    public exportCodes(event: MouseEvent, group: Group): void {
        const codesType = (event.target as HTMLInputElement).value;

        this.exportCodesObsv(
            this.codesService.exportExpertOrCitizenCodes(
                this.codesService.urls[codesType],
                [group.groupId]
            ),
            codesType.split('E')[0]
        );
    }

    public findByGroupName(): void {
        this.selection.clear();

        this.groups = this.groupsService.filterGroupsByGroupName(
            this.groupName.value
        );
        this.dataSource = new MatTableDataSource(this.groups);
        this.groupsFilter.isGroupSearch = true;
        this.groupsFilter.filterToBeShown = 0;
    }

    public resetFilters(): void {
        this.groupName.reset();
        this.groupsFilter = new GroupsFilter();
        this.selection.clear();
        this.groups = this.groupsService.groups;
        this.dataSource = new MatTableDataSource(this.groups);
    }

    public deleteGroupsWarning(): void {
        this.modalManager
            .showModal(
                this.containerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.groups.deleteWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.deleteGroups();
                    return;
                }
                this.selection.clear();
            });
    }

    public openGroupPopup(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.minWidth = '400px';
        dialogConfig.width = '32%';

        dialogConfig.data = {
            groups: this.groups,
        };
        const userProfileDialogRef = this.dialog.open(
            AddGroupPopupComponent,
            dialogConfig
        );
        userProfileDialogRef.componentInstance.parentViewContainerRef =
            this.containerRef;

        userProfileDialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.groups.unshift(data);
                this.router.navigate([routingConstants.adminCodes, data.groupId]);
            }
        });
    }

    public openProjectsPopup(group: Group): void {
        if (group.projectsCount === 0) {
            return;
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.minWidth = '400px';
        dialogConfig.width = '44%';

        dialogConfig.data = {
            groupId: group.groupId,
            groupName: group.groupName,
        };
        const displayProjectsDialogRef = this.dialog.open(
            DisplayProjectsPopupComponent,
            dialogConfig
        );
        displayProjectsDialogRef.componentInstance.parentViewContainerRef =
            this.containerRef;
    }

    public openCodesPopup(group: Group): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.minWidth = '400px';
        dialogConfig.width = '72%';

        dialogConfig.data = {
            groupId: group.groupId,
            groupName: group.groupName,
        };
        const displayProjectsDialogRef = this.dialog.open(
            CodeGroupPopupComponent,
            dialogConfig
        );
        displayProjectsDialogRef.componentInstance.parentViewContainerRef =
            this.containerRef;
    }

    private isNotEmptyProject(): boolean {
        return !this.groups || (this.groups && this.groups.length === 0);
    }

    private showSuccessToaster(): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            'admin.groups.deleteSuccess',
            200
        );
    }

    private deleteGroups(): void {
        this.groupsService
            .deleteGroups(this.selection.selected)
            .subscribe((data) => {
                this.intialiseTable();
                this.showSuccessToaster();
                this.selection.clear();
                this.resetFilters();
            });
    }

    private intialiseTable(): void {
        this.groupsService.getGroupsByClientId().subscribe((data) => {
            this.groups = data;
            this.areGroupsLoaded = true;
            this.dataSource = new MatTableDataSource(this.groups);
        });
    }

    private exportCodesObsv(
        observableResult: Observable<ArrayBuffer>,
        type: string
    ): void {
        observableResult.subscribe((data) => {
            ExportFilesUtil.createCSV(data, type + '_codes_');
        });
    }
}
