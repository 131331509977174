import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {
  LocationStrategy,
  PathLocationStrategy,
  TitleCasePipe,
  CurrencyPipe,
  DatePipe,
} from '@angular/common';
import {
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { CookieService } from 'ng2-cookies';
import { ImageCropperModule } from 'ngx-img-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrivacyModalComponent } from './furban-info/privacy-modal/privacy-modal.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PioneerModule } from './pioneer/pioneer.module';
import { MunicipalityModule } from './municipality/municipality.module';
import { SharedModule } from './shared/shared.module';
import { PermitModule } from './permit/permit.module';

import {
  FurbanHttpInterceptor,
  UtilitiesModule,
  FurbanMaterialModule,
  ThreeReducer,
  ThreeEffects,
} from '@furban/utilities';
import { APP_CONFIG } from '@furban/app-config';
import { environment } from '../environments/environment';
import { PublicEntryPageComponent } from './public-entry-page/public-entry-page.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { ProjectsOnAreaComponent } from './projects-on-area/projects-on-area.component';
import { PublicProjectDetailsComponent } from './public-project-details/public-project-details.component';
import { WinnerInterceptor } from './municipality/user/winner-congratulations/winner.interceptor';
import { FurbanInfoComponent } from './furban-info/furban-info.component';
import { FurbanCityComponent } from './furban-city/furban-city.component';
import '@google/model-viewer';
import { ProjectSharedModule } from './project-shared/project-shared.module';
import { PublicEmbededProjectComponent } from './public-embeded-project/public-embeded-project.component';
import { PublicEmbededDesignComponent } from './public-embeded-design/public-embeded-design.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FurbanAppLandingComponent } from './furban-app-landing/furban-app-landing.component';

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const BROWSER_LANGUAGE = 'Browser_Language';

const language = navigator.language || navigator['userLanguage'];

@NgModule({
  declarations: [
    AppComponent,
    PublicProjectDetailsComponent,
    ProjectsOnAreaComponent,
    PublicEntryPageComponent,
    DisclaimerComponent,
    FurbanInfoComponent,
    FurbanAppLandingComponent,
    FurbanCityComponent,
    PublicEmbededProjectComponent,
    PublicEmbededDesignComponent
  ],
  imports: [
    BrowserModule,
    HammerModule,
    HttpClientModule,
    ImageCropperModule,
    InfiniteScrollModule,
    PickerModule,
    EmojiModule,
    AppRoutingModule,
    FurbanMaterialModule,
    StoreModule.forRoot({ store: ThreeReducer }),
    EffectsModule.forRoot([ThreeEffects]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PioneerModule,
    PermitModule,
    MunicipalityModule,
    SharedModule,
    UtilitiesModule,
    ProjectSharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FurbanHttpInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    TranslateService,
    CookieService,
    { provide: BROWSER_LANGUAGE, useValue: language },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WinnerInterceptor,
      multi: true,
    },
    TitleCasePipe,
    CurrencyPipe,
    DatePipe,
    { provide: APP_CONFIG, useValue: environment },
  ],
  entryComponents: [PrivacyModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
