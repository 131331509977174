<div
  *ngIf="showViewAndEditMode()"
  class="button-edit-group floating-buttons bottom"
  id="view-edit-step"
>
  <button
    class="button-edit pointer"
    [class.selected]="!editMode"
    (click)="onToggleEditChange()"
  >
    <h6>{{ 'buttonsTooltips.viewMode' | translate }}</h6>
  </button>
  <button
    class="button-edit pointer"
    [class.selected]="editMode"
    (click)="onToggleEditChange()"
  >
    <h6>{{ 'buttonsTooltips.editMode' | translate }}</h6>
  </button>
</div>

<div id="htmlRenderer" class="remove-events"></div>

<canvas #canvas3d id="canvasThreeJS" class="gradient-background" cdkDropList>
</canvas>

<div
  id="lockedInfo"
  class="flex-column-center"
  [hidden]="checkIfLockedInfoHidden()"
>
  <button
    class="round-button shadow inactive"
    [matTooltip]="getTooltipTranslation('buttonsTooltips.locked')"
  >
    <span class="material-icons">lock</span>
  </button>
  <ng-container *ngTemplateOutlet="closeControlButton"></ng-container>
</div>

<div
  id="controlsHTML"
  class="flex-column-center"
  [hidden]="checkIfControlBtnsHidden()"
>
  <div class="inline-controls">
    <button
      *ngIf="!checkIfIsLocked()"
      class="control-btn"
      id="move-step"
      [class.active]="threeInstance.activeTab === threeObjectControlsEnum.move"
      [matTooltip]="getTooltipTranslation('buttonsTooltips.move')"
      [matTooltipPosition]="'above'"
      (click)="
        changeControlsOnObject(
          threeObjectControlsEnum.move,
          threeInstance.intersectedType,
          $event
        )
      "
    >
      <span class="material-icons inline-icon">control_camera</span>
    </button>
    <button
      *ngIf="
        isSquareOrElipseOrCustom(threeInstance.intersectedType) &&
        !checkIfIsLocked()
      "
      class="control-btn"
      id="scale-step"
      [class.active]="threeInstance.activeTab === threeObjectControlsEnum.scale"
      [matTooltip]="getTooltipTranslation('buttonsTooltips.scale')"
      [matTooltipPosition]="'above'"
      (click)="
        changeControlsOnObject(
          threeObjectControlsEnum.scale,
          threeInstance.intersectedType,
          $event
        )
      "
    >
      <span class="material-icons inline-icon">settings_overscan</span>
    </button>

    <button
      *ngIf="!checkIfIsLocked()"
      class="control-btn"
      id="lock-step"
      [matTooltip]="getTooltipTranslation('buttonsTooltips.lock')"
      [matTooltipPosition]="'above'"
      [class.active]="threeInstance.activeTab === threeObjectControlsEnum.lock"
      (click)="lock()"
    >
      <span class="material-icons inline-icon">lock</span>
    </button>

    <button
      *ngIf="checkIfIsLocked()"
      class="control-btn locked"
      [matTooltip]="getTooltipTranslation('buttonsTooltips.unlock')"
      [matTooltipPosition]="'above'"
      [class.active]="threeInstance.activeTab === threeObjectControlsEnum.lock"
      (click)="unlock()"
    >
      <span class="material-icons inline-icon">lock_open</span>
    </button>

    <button
      *ngIf="!checkIfIsLocked()"
      class="control-btn"
      id="rotate-step"
      [class.active]="
        threeInstance.activeTab === threeObjectControlsEnum.rotate
      "
      [matTooltip]="getTooltipTranslation('buttonsTooltips.rotate')"
      [matTooltipPosition]="'above'"
      (click)="
        changeControlsOnObject(
          threeObjectControlsEnum.rotate,
          threeInstance.intersectedType,
          $event
        )
      "
    >
      <span class="material-icons inline-icon">360</span>
    </button>

    <button
      *ngIf="!checkIfIsLocked()"
      class="control-btn"
      id="delete-step"
      (click)="deleteObject()"
    >
      <span class="material-icons inline-icon">delete</span>
    </button>
  </div>
  <ng-container *ngTemplateOutlet="closeControlButton"></ng-container>
</div>

<ng-template #closeControlButton>
  <button class="close-button" [style.margin-top.px]="12">
    <span
      class="material-icons"
      [matTooltip]="getTooltipTranslation('buttonsTooltips.close')"
      [matTooltipPosition]="'above'"
      (click)="closeButtons($event)"
      >close</span
    >
  </button>
</ng-template>

<!-- To-DO remove (click) "openReconnectModal()" after demo and maybe testing -->

<div class="autosave-message" (click)="openReconnectModal()">
  <span class="material-icons" [style.margin-right.px]="12">
    cloud_upload
  </span>
  <h3>{{ 'autoSave.message' | translate }}</h3>
</div>
