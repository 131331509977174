<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'user.dashboard.expertsForProject' | translate }}</h2>
    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons"> close </span>
    </button>
</div>

<mat-dialog-content>
    <ng-container *ngIf="experts && experts.length > 0">
        <div class="flex-row-spaced" *ngFor="let expert of experts">
            <div class="avatar flex-row-start">
                <div class="relative flex-column-center">
                    <furban-avatar-picture
                        *ngIf="!expert.mediaId"
                        [name]="expert.screenName"
                        [size]="56"
                        [mediaId]="getUserMedia(expert.mediaId)"
                    >
                    </furban-avatar-picture>
                    <div *ngIf="expert.online" class="chat-status"></div>
                </div>
                <div class="flex-column-start">
                    <h3>{{ expert.screenName }}</h3>
                </div>
            </div>
            <span
                class="material-icons green-icon pointer"
                (click)="talkToExpert(expert)"
            >
                chat
            </span>
        </div>
    </ng-container>
    <ng-container *ngIf="!experts || (experts && experts.length === 0)">
        <div class="flex-column-center padding-20-40 full-height">
            <img
                src="../../../assets/images/experts.svg"
                [style.height.px]="260"
            />
            <h4 class="placeholder-text">
                {{ 'user.dashboard.noExperts' | translate }}
            </h4>
        </div>
    </ng-container>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="primary-button" (click)="closeDialog()">
        <h6>{{ 'generic.okBtn' | translate }}</h6>
    </button>
</div>
