<ng-container *ngIf="isTopView()">
    <furban-app-citizen-proposals>
    </furban-app-citizen-proposals>
</ng-container>

<ng-container *ngIf="isVotingView()">
    <section *ngIf="currentDisplayedDp && (designProposalsCount > 0); else noDesignsImage"
        class="carousel parent full-width">
        <div class="card-three-container">
            <furban-public-three-js-editor *ngIf="threeIsReady" #threeJsEditorComponent [project]="project"
                [userObjects]="currentObjects" [designProposal]="currentDisplayedDp" [state]="threeState"
                [showButtons]="false" [userProfileId]="currentDisplayedDp.userProfile?.userProfileId"
                (threeJSCommentSave)="onThreeJSCommentSave($event)">
            </furban-public-three-js-editor>

            <!-- Can Testing Team Look into this? the dpBottomInfo does not exist. Do we need TopInfo here? or?  -->
            <!-- <section class="buttons-container flex-row-spaced">
                    <ng-template *ngTemplateOutlet="
                            dpBottomInfo;
                            context: {
                                $implicit: currentDisplayedDp,
                                showName: true
                            }
                        ">
                    </ng-template>
                </section> -->

            <div class="swipe-buttons flex-row-spaced">
                <button class="flex-row-center" (click)="swipe(swipeAction.left)">
                    <span class="material-icons"> chevron_left</span>
                </button>

                <div class="right-side">
                    <button class="right-button" (click)="swipe(swipeAction.right)">
                        <span class="material-icons"> chevron_right </span>
                    </button>

                    <furban-dp-quick-view #dpQuickView [hidden]="!isQuickViewToggled" [quickView]="quickView"
                        [designProposals]="designProposals" [project]="project" [viewContainerRef]="viewContainerRef"
                        [isCollaborativeProject]="isCollaborativeProject" [disabledActions]="project.ended"
                        [currentDp]="currentDisplayedDp" (scrollEmitter)="scrollEmitted($event)"
                        (designProposalSelect)="selectDP($event)" (commentButtonClick)="openCommentDialog($event)"
                        (quickViewClose)="closeQuickView()" (reportEvent)="report($event)">
                    </furban-dp-quick-view>
                </div>
            </div>

            <ng-template *ngTemplateOutlet="topInfo"></ng-template>

            <ng-container *ngIf="!isVotingExpiredOrWinnerSelected()">
                <div class="button-edit-group floating-buttons bottom">
                    <ng-container *ngIf="isReactionsView()">
                        <ng-template *ngTemplateOutlet="reactionsView"></ng-template>
                    </ng-container>
                    <ng-container *ngIf="isThankYouView()">
                        <ng-template *ngTemplateOutlet="thankYouView"></ng-template>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </section>
</ng-container>

<ng-template #noDesignsImage>

    <div class=" no-design-proposal ">
        <img src="../../../assets/images/no_design.svg" [style.height.px]="300" />
        <h4 class="placeholder-text">
            {{ 'user.dashboard.noDesignsMessage' | translate }}
        </h4>
    </div>
</ng-template>

<ng-template #reactionsView>
    <div *ngFor="let reactionId of reactions" class="icon-size" [class.selected]="isSelected(reactionId)"
        (click)="reactToDesignProposal(reactionId, currentDisplayedDp)">
        <img src="{{ getImageURL(reactionId) }}" />
    </div>
</ng-template>

<ng-template #thankYouView>
    <div class="already-voted">
        <img src="{{ getImageURL(currentDesignProposalReaction.reactionId) }}" />
        <p>{{ 'designProposals.thankyouVote' | translate }}</p>
        <button class="secondary-button" (click)="revote()">
            <h6>{{ 'generic.revote' | translate }}</h6>
        </button>
        <button class="primary-button" (click)="swipe(swipeAction.right)">
            <h6>{{ 'generic.next' | translate }}</h6>
        </button>
    </div>
</ng-template>

<ng-template #topInfo>
    <div class="top-container">
        <div class="container-info">
            <div class="flex-row-start">
                <span class="material-icons home" (click)="goToDashboard()">
                    home
                </span>
                <span class="home-line"> </span>

                <p>{{ computeInfoTitle }}</p>
            </div>
            <ng-container *ngIf="!isVotingExpiredOrWinnerSelected()">
                <span class="material-icons info" matTooltip="{{
                        'designProposals.infoAboutReaction' | translate
                    }}">
                    info_outline
                </span>
            </ng-container>
        </div>

        <ng-container *ngFor="let btn of quickViewBtns">
            <div class="toggle-quick-view">
                <span class="material-icons" [class.active]="isQuickViewBtnActive(btn)"
                    matTooltip="{{ getStatusTooltipText(btn) }}" (click)="toggleQuickView(btn)">
                    {{ isQuickViewComments(btn) ? 'comment' : 'list_alt' }}
                </span>
            </div>
        </ng-container>
    </div>
</ng-template>


<!-- this was deleted in a previous iteration -->
<!-- <ng-template #dpBottomInfo let-dp let-showName="showName">
    <div class="flex-row-start">
        <avatar-picture [name]="dp.userProfile.screenName" [size]="24" [mediaId]="getUserMedia(dp.userProfile.mediaId)">
        </avatar-picture>
        <p *ngIf="showName"> {{dp.userProfile.screenName}}</p>
    </div>

    <div class="flex-row-start" (click)="openCommentDialog(dp)">
        <span class="material-icons comm">comment</span>
        <h6 *ngIf="dp && dp.comments" [style.margin-left.px]="4">{{dp.comments.length}}</h6>
    </div>
</ng-template> -->