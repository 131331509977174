import { Object3D } from 'three';

export class CreatedHouse {
    public groupChildren: Object3D[];
    public houseHeight: number;

    constructor(groupChildren: Object3D[], height: number) {
        this.groupChildren = groupChildren;
        this.houseHeight = height;
    }
}
