import { Component, Input } from '@angular/core';
import { ConversationComment } from '../_models/conversation-comment';

@Component({
    selector: 'furban-general-comment',
    templateUrl: './general-comment.component.html',
    styleUrls: ['./general-comment.component.scss'],
})
export class GeneralCommentComponent {
    @Input() comment: ConversationComment;

    public get hasReplies(): boolean {
        return this.comment?.replies?.length > 0;
    }

    public get distinctReplies(): string[] {
        const distinctReplies = this.comment.replies.filter(
            (item, i, ar) => ar.indexOf(item) === i
        );

        if (distinctReplies.length <= 3) {
            return distinctReplies;
        } else {
            return distinctReplies.slice(0, 3);
        }
    }
}
