<ng-container *ngIf="project">
    <main>
        <section class="project-image-container">
            <img class="project-image"
                 [src]="getMedia(project.media)"
                 alt="ProjPic">
            <div class="padding-8-12 project-name">
                <span class="material-icons pointer"
                      (click)="goBack()"
                      [style.margin-right.px]="12">arrow_back</span>
                <div class="full-width">
                    <p>
                        {{ project.name }}
                    </p>
                    <p>
                        {{ project.startDate | date : 'dd MMMM yyyy' }} -
                        {{ project.endDate | date : 'dd MMMM yyyy' }}
                    </p>
                    <p>{{ project.description }}</p>
                </div>
            </div>
        </section>
        <section class="project-section flex-column-center">
            <ng-container *ngIf="hasMapImage(); else noMapImage">
                <div class="background-center full-width full-height"
                     [style.background-image]="'url(' + getMapImage(mapImageId) + ')'">
                </div>
            </ng-container>
            <ng-template #noMapImage>
                <img [style.width.px]="220"
                     src='{{ pickImageSrc("proj-area.png") }}' />
                <p>{{'user.dashboard.noMapSelected' | translate}}</p>
            </ng-template>
        </section>

        <section class="design-project flex-column-center">
            <img src="{{ pickImageSrc('design-proposal.png') }}" />
            <section>
                <h3>
                    {{ designProposalsCount }}
                    {{ 'user.dashboard.designsMessage1' | translate }}
                </h3>
                <p>{{ 'user.dashboard.designsMessage2' | translate }}</p>
            </section>
        </section>

        <section class="flex-row-spaced header-container">
            <h2>
                {{
                'admin.adminProcessDashboard.projectDetailsPageHeaderText'
                | translate
                }}
            </h2>
            <furban-app-project-activate></furban-app-project-activate>
        </section>

        <div class="steps-container">
            <section *ngFor="let step of steps"
                     class="process-step flex-row-spaced"
                     [class.hoverable]="!step.isUntouched()"
                     [class.pointer]="!step.isUntouched()"
                     (click)="goToStep(step)">
                <div class="flex-row-start"
                     [class.completed-status]="step.isCompleted()"
                     [class.in-progress-status]="step.isInProgress()"
                     [class.untouched-status]="step.isUntouched()"
                     [class.vertical-line]="step.id < 4">
                    <span class="index-container flex-row-center">
                        {{ step.id }}
                    </span>
                    <p>
                        {{ step.title | translate }}
                    </p>
                </div>
                <mat-icon *ngIf="step.isCompleted()"> create </mat-icon>
                <ng-container *ngIf="step.isInProgress()">
                    <p class="italic-text">
                        {{ 'generic.continueSetupButtonText' | translate }}
                    </p>
                </ng-container>
            </section>

            <section class="process-step-statistics flex-row-spaced ">
                <section class="div-1 flex-column-center"
                         (click)="navigateTo('user-statistics')">
                    <img src='{{ pickImageSrc("user-statistics.svg") }}' />
                    <p class="ellipsis">{{'user.statistics.userStatistics' | translate}} </p>
                </section>

                <section class="div-2 flex-column-center"
                         (click)="navigateTo('object-statistics')">
                    <img src='{{ pickImageSrc("object-overview-statistics.svg") }}' />
                    <p class="ellipsis">{{'user.statistics.objectStatistics' | translate}} </p>
                </section>
                <section class="div-3 flex-column-center"
                         (click)="navigateTo('poll-statistics')">
                    <img src='{{ pickImageSrc("poll.svg") }}' />
                    <p class="ellipsis">{{'user.statistics.poll' | translate}} </p>
                </section>
                <section class="div-4 flex-column-center"
                         (click)="navigateTo('statistics-overview')">
                    <img src='{{ pickImageSrc("statistics-overview.svg") }}' />
                    <p class="ellipsis">{{'user.statistics.statisticsOverview' | translate}} </p>
                </section>
                <section class="div-5 flex-column-center"
                         (click)="navigateTo('design-proposals')">
                    <img src='{{ pickImageSrc("design-proposal.png") }}' />
                    <p class="ellipsis">{{'user.dashboard.designProposals' | translate}} </p>
                </section>

                <ng-container *ngIf="isAdmin">
                    <section class="live-card flex-column-center">
                        <img src="{{ pickImageSrc('live-collaboration.svg') }}" />
                        <section>
                            <h3>
                                {{
                                'admin.adminProcessDashboard.liveSessionTitle'
                                | translate
                                }}
                            </h3>
                            <p>
                                {{
                                'admin.adminProcessDashboard.liveSessionDescription'
                                | translate
                                }}
                            </p>
                            <div class="flex-row-spaced full-width buttons-container end">
                                <button class="secondary-button"
                                        [class.disabled]="isPioneer"
                                        (click)="goToLiveSessions()">
                                    <h6>{{'admin.adminProcessDashboard.viewAllButon' | translate}}</h6>
                                </button>
                                <button class="primary-button space-left"
                                        [class.disabled]="projectEnded"
                                        (click)="createLiveSession()">
                                    <h6>{{'admin.adminProcessDashboard.createSessionButton' | translate}}</h6>
                                </button>
                            </div>
                        </section>
                    </section>
                </ng-container>



            </section>
        </div>
    </main>
</ng-container>