import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SuppliersService } from '../superadmin-suppliers/suppliers.service';
import { ControlsUtil, Furban3DModel, ModalManager } from '@furban/utilities';

@Component({
    selector: 'furban-superadmin-manage-safety-area',
    templateUrl: './superadmin-manage-safety-area.component.html'
})
export class SuperadminManageSafetyAreaComponent implements OnInit {
    public safetyAreaForm: FormGroup;
    public furban3dModel: Furban3DModel;
    public parentViewContainerRef: ViewContainerRef;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;

    constructor(
        private dialogRef: MatDialogRef<SuperadminManageSafetyAreaComponent>,
        private formBuilder: FormBuilder,
        private supplierService: SuppliersService,
        @Inject(MAT_DIALOG_DATA) data,
        private modal: ModalManager
    ) {
        this.furban3dModel = data.model;
    }

    public ngOnInit() {
        this.safetyAreaForm = this.formBuilder.group({
            safetyInCm: [
                this.furban3dModel.safetyArea,
                [
                    Validators.required,
                    Validators.maxLength(64),
                ],
            ],
            chipListControl: [],
            dropdownControl: [],
        });
    }

    public closeDialog(): void {
        if (this.safetyAreaForm.dirty) {
            this.modal
                .showModal(
                    this.parentViewContainerRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'errors.discardMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (res) {
                        this.dialogRef.close();
                    }
                });
        } else {
            this.dialogRef.close();
        }
    }

    public updateSafetyArea(): void {
        this.furban3dModel.safetyArea = this.safetyAreaForm.get("safetyInCm").value;
        this.supplierService.updateFurban3DModel(this.furban3dModel).subscribe(
            ()=>{
                this.dialogRef.close();
            }
        )
    }
}
