import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    NavigationStart,
} from '@angular/router';

import {
    AuthService,
    routingConstants,
    MultilanguageService,
} from '@furban/utilities';
import { Observable, from } from 'rxjs';
import { tap, concatMap } from 'rxjs/operators';

@Injectable()
export class UserLoginGuard implements CanActivate {
    private stayOnLogin = false;

    constructor(
        private router: Router,
        private authService: AuthService,
        private multilanguage: MultilanguageService
    ) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (
                    this.authService.previousUrl === event.url &&
                    this.authService.previousUrl === routingConstants.app
                ) {
                    this.stayOnLogin = true;
                }
                this.authService.previousUrl = event.url;
            }
        });
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> {
        const role = route.data['role'] as string;
        this.multilanguage.setupLanguage();

        if (this.stayOnLogin) {
            this.stayOnLogin = false;
            return true;
        }

        if (this.authService.user) {
            if (state.url === routingConstants.app) {
                if (this.authService.isCitizenOrExpertOrCollaborator) {
                    this.authService.redirectUser();
                    return false;
                }
            }

            if (!this.authService.hasPrivilegesOnRouteByUserAuthority(role)) {
                if (
                    this.authService.isAdmin() ||
                    this.authService.isSuperAdmin()
                ) {
                    this.authService.redirectAdmin();
                    return false;
                }
                if (this.authService.isPioneer()) {
                    this.authService.redirectPioneer();
                    return false;
                }
            }
        }

        if (this.authService.allInfoInPlace(role, state)) {
            return true;
        } else {
            const initialCalls = [
                { method: this.authService.getUser, context: this.authService },
                {
                    method: this.authService.getClient,
                    context: this.authService,
                },
            ];

            if (!this.authService.userProfile) {
                const userProfileApi = {
                    method: this.authService.getUserProfile,
                    context: this.authService,
                };
                initialCalls.unshift(userProfileApi);
            }

            return new Observable((observer) => {
                this.resume(initialCalls).subscribe(
                    {
                        next: (data) => {
                            if (
                                !data ||
                                (data.role &&
                                    !this.authService.hasPrivilegesOnRouteByUserAuthority(
                                        role
                                    ))
                            ) {
                                observer.next(false);
                                this.authService.goBackToLogin(role, state);
                            }
                        },
                        error: (err) => {
                            observer.next(false);
                            this.authService.goBackToLogin(role, state);
                        },
                        complete: () => {
                            observer.next(true);

                        }
                    }
                );
            });
        }
    }

    private resume(initialCalls: any[]): Observable<any> {
        return from(initialCalls).pipe(
            concatMap((request) => this.apiService(request))
        );
    }

    private apiService(request: any): Observable<any> {
        return request.method.apply(request.context, null).pipe(
            tap((resp) => {
                if (!resp && request === this.authService.getUserProfile) {
                    throw new Error('no profile...go to login...');
                }
            })
        );
    }
}
