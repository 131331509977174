import { Component, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalManager, Project, routingConstants } from '@furban/utilities';
import { Router } from '@angular/router';
import { CodesService } from 'apps/furban-client/src/app/shared/_services/codes.service';
import { CodesComponent } from '../codes/codes.component';

@Component({
    selector: 'furban-code-group-popup',
    templateUrl: './code-group-popup.component.html',
    styleUrls: ['./code-group-popup.component.scss'],
})
export class CodeGroupPopupComponent {
    @ViewChild('codesComponent') codesComponent: CodesComponent;

    public parentViewContainerRef: ViewContainerRef;
    public groupId: string;
    public groupName: string;
    public projects: Project[];

    public get codeServiceInstance(): CodesService {
        return this.codeService;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private modal: ModalManager,
        private dialogRef: MatDialogRef<CodeGroupPopupComponent>,
        private router: Router,
        private codeService: CodesService
    ) {
        this.groupId = data.groupId;
        this.groupName = data.groupName;
    }

    public changeGroup(groupId): void {
        this.router.navigate([routingConstants.adminCodes, groupId]);
        this.closeDialog();
    }

    public deleteCodes(): void {
        this.modal
            .showModal(
                this.parentViewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.projectCodes.codesDeleteWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.codesComponent.deleteCodesResultReset();
                } else {
                    this.codesComponent.codeServiceInstance.selection.clear();
                }
            });
    }

    public closeDialog(): void {
        this.dialogRef.close();
        this.codeService.selection.clear();
        this.codesComponent.resetFilters();
    }
}
