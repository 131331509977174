<div id="notificationPanel" class="relative">
    <section class="notifications" (click)="toggleNotifications()">
        <span class="material-icons"> notifications </span>
        <div class="notification-bubble" *ngIf="notificationsNumber > 0">
            <h6>{{ notificationsNumber }}</h6>
        </div>
    </section>

    <div *ngIf="openedNotifications && !loaded" class="notifications-dropdown">
        <div class="loader-overlay">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
    </div>

    <ul
        *ngIf="areNotificationsOpenedAndLoadedAndExist()"
        class="notifications-dropdown"
        infiniteScroll
        [infiniteScrollThrottle]="300"
        (scrolled)="onScrollDown()"
        [scrollWindow]="false"
    >
        <li
            *ngFor="let item of notificationList"
            class="flex-column-start"
            [class.unread]="!item.userNotification.isRead"
            (click)="markNotificationAsRead(item)"
        >
            <div class="flex-row-start" [style.margin-bottom.px]="8">
                <div class="notification-type">
                    <span class="material-icons">{{
                        returnIcon(item.notification)
                    }}</span>
                </div>
                <h6>{{ returnNotificationTypeAsString(item) }}</h6>
            </div>
            {{ getNotificationMessage(item.notification) }}
        </li>
    </ul>

    <div
        class="notifications-dropdown"
        *ngIf="
            openedNotifications &&
            (!notificationList ||
                (notificationList && notificationList.length === 0)) &&
            loaded
        "
    >
        <div class="flex-column-center" [style.margin-top.px]="40">
            <img
                src="../../../assets/images/no_notifications.svg"
                [style.height.px]="160"
            />
            <h4 class="placeholder-text">
                {{ 'notifications.emptyDropdownNotifications' | translate }}
            </h4>
        </div>
    </div>
</div>
