import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CitizenRegisterService } from './citizen-register.service';
import { LocationStrategy } from '@angular/common';

import { routingConstants } from '@furban/utilities';

@Component({
    selector: 'furban-citizen-register',
    templateUrl: './citizen-register.component.html',
    styleUrls: ['./citizen-register.component.scss'],
})
export class CitizenRegisterComponent implements OnInit {
    public registerForm: FormGroup;
    public formSubmitted = false;
    private projectId: string;
    private captcha: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private citizenRegisterService: CitizenRegisterService,
        private router: Router,
        private LocationStrategy: LocationStrategy
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe((data) => {
            this.projectId = data['projectId'] as string;
            this.initialiseregisterForm();
        });
    }

    public submitRegistration() {
        if (this.registerForm.invalid || !this.captcha) {
            return;
        }

        this.citizenRegisterService
            .submitRegistration(
                this.projectId,
                this.registerForm
                    .get('emailFormControl')
                    .value.trim()
                    .toLowerCase(),
                this.captcha
            )
            .subscribe(() => {
                this.formSubmitted = true;
            });
    }

    public resolved(captchaResponse: string) {
        this.captcha = captchaResponse;
    }

    private initialiseregisterForm() {
        this.registerForm = this.formBuilder.group({
            emailFormControl: ['', [Validators.required, Validators.email]],
        });
    }

    public redirectToGDPR() {
        const baseURL = this.LocationStrategy.getBaseHref();
        window.open(baseURL + routingConstants.gdpr, '_blank');
    }

    public goToCards() {
        this.router.navigate([
            routingConstants.publicEntryPage,
            this.projectId,
        ]);
    }
}
