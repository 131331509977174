interface Scripts {
    name: string;
    src: string;
}
export const scriptStore: Scripts[] = [
    { name: 'jquery', src: '../../assets/scripts/jquery-3.6.3.min.js' },
    {
        name: 'centricGeoComp',
        src: '../../assets/scripts/Centric.Gis.GeoComp.min.js',
    },
    { name: 'passiveEvents', src: '../../assets/scripts/passive-events.js' },

    { name: 'olJs', src: '../../assets/scripts/map-scripts/ol.js' },
    {
        name: 'olrotateFeatureJs',
        src: '../../assets/scripts/map-scripts/ol-rotate-feature.min.js',
    },
];
