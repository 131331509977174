import { Inject, Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotificationModel } from '../_models/notification';
import { map } from 'rxjs/operators';
import { LoaderHttpParams } from '../_models/loader-http-param';
import { FullNotification } from '../_models/full-notification';
import { UserNotification } from '../_models/user-notification';
import { TranslateService } from '@ngx-translate/core';
import { NotificationTypeEnum } from '../_enum/notifications-type.enum';
import { SilenceHttpParams } from '../_models/silence-http-param';
import { APP_CONFIG } from '@furban/app-config';
import { Environment } from '../_models/environment-model';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private _options = {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        private http: HttpClient,
        private translateService: TranslateService
    ) { }

    getNotifications(page: number): Observable<FullNotification[]> {
        const params = new HttpParams()
            .set('pageIndex', page.toString())
            .set('perPage', '10')
            .set('isIgnoreCase', 'true')
            .set('isLoaderHidden', 'true');

        return this.http
            .get(`${this.environment.apiPath}/notification`, {
                params: params,
            })
            .pipe(map((result) => result as FullNotification[]));
    }

    getNotificationsCount(): Observable<number> {
        return this.http
            .get(`${this.environment.apiPath}/notification/count`, {
                params: new SilenceHttpParams(true, true, false),
            })
            .pipe(map((result) => result as number));
    }

    postNotification(
        notification: NotificationModel[],
        typeId: number,
        userType: string
    ): Observable<NotificationModel> {
        const params = new HttpParams().set('typeId', typeId.toString());
        return this.http
            .put(
                `${this.environment.apiPath}/notification/${userType}`,
                notification,
                { params: params }
            )
            .pipe(map((result) => result as NotificationModel));
    }

    postUserNotification(
        notification: NotificationModel[],
        typeId: number
    ): Observable<NotificationModel> {
        const params = new HttpParams().set('typeId', typeId.toString());
        return this.http
            .put(
                `${this.environment.apiPath}/notification/user`,
                notification,
                { params: params }
            )
            .pipe(map((result) => result as NotificationModel));
    }

    readNotification(userNotification: UserNotification): Observable<string> {
        return this.http
            .post(
                this.environment.apiPath + '/notification/read',
                userNotification,
                {
                    headers: this._options.headers,
                    params: new LoaderHttpParams(true),
                }
            )
            .pipe(map((result) => result as string));
    }

    getNotificationsBySender(page: number): Observable<NotificationModel[]> {
        const params = new HttpParams()
            .set('pageIndex', page.toString())
            .set('perPage', '20');
        return this.http
            .get(`${this.environment.apiPath}/notification/sent`, {
                params: params,
            })
            .pipe(map((result) => result as NotificationModel[]));
    }

    countNotificationsBySender(): Observable<number> {
        return this.http
            .get(`${this.environment.apiPath}/notification/sent/count`)
            .pipe(map((result) => result as number));
    }

    countAllNotificationsForUser(): Observable<number> {
        return this.http
            .get(`${this.environment.apiPath}/notification/all/count`, {
                params: new LoaderHttpParams(true),
            })
            .pipe(map((result) => result as number));
    }

    returnNotificationTypeAsString(item: NotificationModel) {
        switch (item.notificationType.notificationTypeId) {
            case NotificationTypeEnum.project:
                return this.translateService.instant(
                    'notifications.projectNotification'
                );
            case NotificationTypeEnum.municipality:
                return this.translateService.instant(
                    'notifications.municipalityNotification'
                );
            case NotificationTypeEnum.system:
                return this.translateService.instant(
                    'notifications.systemNotification'
                );
            case NotificationTypeEnum.user:
                return this.translateService.instant(
                    'notifications.userNotification'
                );
        }
    }
}
