import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
    AuthService,
    ModalManager,
    MunicipalityService,
    routingConstants,
} from '@furban/utilities';

@Component({
    selector: 'furban-app-municipality-new',
    templateUrl: './municipality-new.component.html',
    styleUrls: ['./municipality-new.component.scss'],
})
export class MunicipalityNewComponent implements OnInit {
    nameFormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(110),
    ]);
    addressFormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(256),
    ]);
    phoneFormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(128),
    ]);

    public isSuperAdmin: boolean;

    constructor(
        private authService: AuthService,
        private municipalityService: MunicipalityService,
        private modal: ModalManager,
        private router: Router,
        private viewContainerRef: ViewContainerRef
    ) { }

    ngOnInit() {
        this.isSuperAdmin = this.authService.isSuperAdmin();

        if (!this.isSuperAdmin) {
            this.router.navigateByUrl(routingConstants.adminDashboard);
        }
    }

    // REFACTOR - should be toaster
    addNewMunicipality() {
        this.municipalityService
            .addNewMunicipality(
                this.nameFormControl.value,
                this.addressFormControl.value,
                this.phoneFormControl.value
            )
            .subscribe(
                {
                    next: (data) => {
                        this.modal
                            .showModal(
                                this.viewContainerRef,
                                ModalManager.createConfiguration(
                                    'Success',
                                    'The municipality was created'
                                )
                            )
                            .subscribe();
                    },
                    error: (error) => {
                        // login failed so display error
                        if (error && error.status === 401) {
                            this.modal
                                .showModal(
                                    this.viewContainerRef,
                                    ModalManager.createConfiguration(
                                        'Error',
                                        'The municipality was not created.'
                                    )
                                )
                                .subscribe();
                        }
                    }
                }

            );
    }
}
