import { layers } from './layers';

export const GEOCOMPCONFIG = {
    container: 'mapViewer',
    copyright: '',
    layers: layers,
    projection: 'EPSG:28992',
    projectionExtent: {
        minX: '-285401.92',
        minY: '22598.08',
        maxX: '595401.92',
        maxY: '903401.92',
    },
    mapExtent: {
        minX: '145832',
        minY: '460051',
        maxX: '167031',
        maxY: '468226',
    },
    selectableLayers: [],
    layerPanelButtonVisible: false,
    layerPanelVisible: false,
    infoBarVisible: true,
    geoLocationButtonsVisible: true,
    geoServerPrintUrl: '',
};
