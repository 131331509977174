export const layers = [
    {
        type: 'wmts',
        name: 'Actueel_orthoHR',
        label: 'Luchtfoto PDOK',
        url: 'https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0',
        opacity: '1.0',
        minResolution: '0.013125',
        maxResolution: '3440.64',
        variableOpacity: false,
        visible: false,
        expanded: false,
        identify: false,
        wmsProtocolVersion: '1.3.0',
        wfsProtocolVersion: '1.0.0',
        snappable: true,
        tileOrigin: {
            x: '-285401.92',
            y: '903401.92',
        },
        tileFullExtent: {
            minX: '-285401.92',
            minY: '22598.80',
            maxX: '595401.92',
            maxY: '903401.92',
        },
        projection: 'EPSG:28992',
        publish: false,
        publishLabel: 'bgtachtergrond_1',
        publishDescription: '',
        publishSource: '',
        wfsName: 'standaard',
        wfsUrl: '',
        wfsFeatureNamespace: '',
        wfsPropertyName: '',
        singleTile: false,
        groupId: -1,
        groupName: '',
        groupExpanded: true,
        groupVisible: true,
    },
    {
        type: 'wmts',
        name: 'water',
        label: 'Water',
        url: 'https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0',
        opacity: '1.0',
        minResolution: '0.105',
        maxResolution: '3440.64',
        variableOpacity: false,
        visible: true,
        expanded: false,
        identify: false,
        wmsProtocolVersion: '1.3.0',
        wfsProtocolVersion: '1.0.0',
        snappable: true,
        tileOrigin: {
            x: '-285401.92',
            y: '903401.92',
        },
        tileFullExtent: {
            minX: '-285401.92',
            minY: '22598.80',
            maxX: '595401.92',
            maxY: '903401.92',
        },
        projection: 'EPSG:28992',
        publish: false,
        publishLabel: 'BRT Achtergrondkaart',
        publishDescription: '',
        publishSource: '',
        wfsName: 'standaard',
        wfsUrl: '',
        wfsFeatureNamespace: '',
        wfsPropertyName: '',
        singleTile: false,
        groupId: -1,
        groupName: '',
        groupExpanded: true,
        groupVisible: true,
    },
];
