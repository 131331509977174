<ng-container *ngIf="!hideCards; else justMap">
    <furban-homepage-header></furban-homepage-header>
    <section class="main-section">
        <div class="custom-width-50">
            <div class="floating-buttons right">
                <mat-slide-toggle class="toggle" (change)="switchLayer($event)">
                </mat-slide-toggle>
                <button
                    class="round-button"
                    [style.margin-right.px]="0"
                    id="search-button"
                    matTooltip="{{
                        'buttonsTooltips.searchAddress' | translate
                    }}"
                    [class.active]="searchEnabled"
                    (click)="toggleSearch()"
                >
                    <span class="material-icons">search</span>
                </button>
                <button
                    class="round-button"
                    matTooltip="{{
                        'buttonsTooltips.goToCurentLocation' | translate
                    }}"
                    (click)="goToCurentLocation()"
                >
                    <span class="material-icons">gps_fixed</span>
                </button>
            </div>

            <div
                *ngIf="searchEnabled"
                class="floating-input floating-buttons bottom"
                [style.left.px]="searchPositionLeft"
                [style.width.px]="searchPositionWidth"
            >
                <furban-address-search
                    (changeAddress)="changeAddress($event)"
                ></furban-address-search>
                <span class="material-icons pointer" (click)="toggleSearch()"
                    >close</span
                >
            </div>
            <div id="mapViewer" class="map zoom-top"></div>
        </div>
        <div class="overflow-y custom-width-50">
            <div
                class="flex-row-start"
                [style.margin-left.px]="32"
                [style.margin-top.px]="32"
            >
                <span
                    class="material-icons cursor-pointer"
                    (click)="returnToHomepage()"
                    matTooltip="{{
                        'buttonsTooltips.goToHomepage' | translate
                    }}"
                    [style.margin-right.px]="8"
                >
                    chevron_left
                </span>
                <h3 class="page-title">
                    {{ 'mapLabels.title' | translate }}
                </h3>
            </div>

            <ng-container *ngIf="projectsLight.length > 0">
                <div class="cards">
                    <furban-app-card
                        *ngFor="let project of projectsLight"
                        class="admin-card"
                        [card]="cardConfig"
                        [backgroundImage]="getMedia(project.media)"
                        (click)="goToProject(project.projectId, $event)"
                    >
                        <div card-type="footer" class="full-width">
                            <div
                                [style.margin-bottom.px]="8"
                                class="flex-row-spaced full-width"
                            >
                                <span
                                    class="material-icons custom-icon"
                                    [matTooltip]="getTooltipText(project)"
                                >
                                    {{ getTitleIcon(project) }}
                                </span>
                                <h4
                                    class="custom-width-80 elipsis-on-exceeding-width-80"
                                >
                                    {{ project.name | titlecase }}
                                </h4>
                                <span
                                    class="project-label"
                                    *ngIf="project && project.projectType"
                                    [class.municipality]="
                                        project.projectType.projectTypeId === 0
                                    "
                                    [class.initiatives]="
                                        project.projectType.projectTypeId === 1
                                    "
                                    [class.permit]="
                                        project.projectType.projectTypeId === 2
                                    "
                                >
                                    {{ getProjectLabel(project) }}
                                </span>
                            </div>
                            <h5 class="full-width" [style.margin-bottom.px]="8">
                                {{ project.description }}
                            </h5>
                            <div class="flex-row-spaced full-width">
                                <h6>{{ getProjectPrice(project) }}</h6>
                                <h6>{{ getProjectDates(project) }}</h6>
                            </div>
                        </div>
                    </furban-app-card>
                </div>
                <mat-paginator
                    *ngIf="perPage < projectsLength"
                    class="transparent"
                    [length]="projectsLength"
                    [pageSize]="perPage"
                    [pageSizeOptions]="[6, 12, 24]"
                    [pageIndex]="pageIndex"
                    (page)="onPaginatorChange($event)"
                >
                </mat-paginator>
            </ng-container>

            <ng-container *ngIf="projectsLight.length === 0">
                <div class="flex-column-center" [style.margin-top.px]="40">
                    <img
                        src="../../../assets/images/no_projects.svg"
                        [style.height.px]="240"
                    />
                    <h4 class="placeholder-text">
                        {{ 'mapLabels.noProjects' | translate }}
                    </h4>
                </div>
            </ng-container>
        </div>
    </section>
</ng-container>

<ng-template #justMap>
    <div id="mapViewer" class="map"></div>
    <div *ngIf="displayLoader" class="loader-overlay big-loader">
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
</ng-template>
