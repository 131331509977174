<div [ngClass]="checkPermitRoute() ? 'header-space-between' : 'header-center'">
    <ng-container *ngIf="checkPermitRoute()">
        <furban-tooling>
            <ng-container buttons-type="main">
                <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.addObjects' | translate }}"
                    (click)="onButtonClick(toolingActions.addObjects)">
                    <mat-icon svgIcon="add-objects-icon" [class.active]="checkActiveButton(toolingActions.addObjects)">
                    </mat-icon>
                </button>
                <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.objectsInLine' | translate }}"
                    [class.active]="checkActiveButton(toolingActions.objectsInLine)"
                    (click)="onButtonClick(toolingActions.objectsInLine)">
                    <span class="material-icons-outlined">auto_graph</span>
                </button>

                <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.multiselect' | translate }}"
                    [class.active]="checkActiveButton(toolingActions.multiselect)"
                    (click)="onButtonClick(toolingActions.multiselect)">
                    <span class="material-icons">select_all</span>
                </button>
            </ng-container>
        </furban-tooling>
    </ng-container>
    <ng-container *ngIf="shouldDisplayStepper()">
        <furban-stepper [homeRoute]="homeRoute" [parentContainerRef]="containerRef">
        </furban-stepper>
    </ng-container>
    <ng-container *ngIf="checkPermitRoute()">
        <furban-tooling>
            <ng-container buttons-type="main">
                <button class="tooling-button" [class.disabled]="!isUndoEnabled"
                    matTooltip="{{ 'buttonsTooltips.undo' | translate }}" (click)="buttonPressed('undo')">
                    <span class="material-icons">undo</span>
                </button>

                <button class="tooling-button" [class.disabled]="!isRedoEnabled"
                    matTooltip="{{ 'buttonsTooltips.redo' | translate }}" (click)="buttonPressed('redo')">
                    <span class="material-icons">redo</span>
                </button>
                <button class="tooling-button" matTooltip="{{ 'buttonsTooltips.reset' | translate }}"
                    (click)="openResetModal()">
                    <span class="material-icons">replay</span>
                </button>

                <button class="round-button" matTooltip="{{'buttonsTooltips.change3Dperspective' | translate }}"
                    (click)="buttonPressed('changeCamera')">
                    <furban-svg-container [svgImage]="svgImageType.changePerspective"></furban-svg-container>
                </button>

                <button class="tooling-button" matTooltip="{{ 'sunAnimation.toggleSunSlider' | translate }}"
                    (click)="buttonPressed('toggleSunSlider')">
                    <span class="material-icons">wb_sunny</span>
                </button>

                <button class="primary-button" *ngIf="checkIfRouteIncludes('preview')"
                    (click)="buttonPressed('finishDesign')">
                    <h6>{{ 'buttonsTooltips.finishHouseDesign' | translate }}</h6>
                </button>

                <button class="primary-button" *ngIf="checkIfRouteIncludes('update')"
                    (click)="buttonPressed('finishUpdate')">
                    <h6>{{ 'buttonsTooltips.finishHouseUpdate' | translate }}</h6>
                </button>
            </ng-container>
        </furban-tooling>
    </ng-container>
    <ng-container *ngIf="showDetailsTooling()">
        <furban-tooling>
            <ng-container buttons-type="main">
                <button class="primary-button" matTooltip="{{ 'buttonsTooltips.save' | translate }}"
                    [style.margin-top.px]="2" (click)="saveProjectDetailsPermit()">
                    <h6>{{ 'admin.projectDetails.saveBtn' | translate }}</h6>
                </button>
            </ng-container>
        </furban-tooling>
    </ng-container>
</div>

<router-outlet *ngIf="isProjectLoaded()" name="permit-project"></router-outlet>