<div class="buttons-container" mat-dialog-title [style.margin-bottom.px]="24">
    <h2>{{ 'admin.projectOverview.title' | translate }}</h2>
    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons">close</span>
    </button>
</div>

<section class="flex-row-spaced">
    <img alt="Project Image" [src]="projectOverview.projectImage" />
    <div class="text-content">
        <p>
            {{ 'admin.projectOverview.projectDetailsFirstPart' | translate }}
            <b>{{ projectOverview.projectName }} </b>
            {{ 'admin.projectOverview.projectDetailsSecondPart' | translate }}
            <b>{{ projectOverview.clientName }} </b>
            {{ 'admin.projectOverview.projectDetailsThirdPart' | translate }}
            <b>{{ projectOverview.projectStartDate | date }}</b>
            {{ 'admin.projectOverview.projectDetailsFourthPath' | translate }}
            <b> {{ projectOverview.projectEndDate | date }} </b>
            {{ 'admin.projectOverview.projectDetailsFifthPath' | translate }}
        </p>
        <p>
            {{ 'admin.projectOverview.projectDescription' | translate }}
            <b> {{ projectOverview.projectDescription }}</b>
            <ng-container *ngIf="projectOverview.projectBudget">
                {{ 'admin.projectOverview.projectBudget' | translate }}
                <b>{{
                    projectOverview.projectBudget
                        | currency : 'EUR' : 'symbol' : '0.0-2'
                }}</b> </ng-container
            >.
        </p>
        <p>
            {{ 'admin.projectOverview.nextStepDescription' | translate }}
        </p>
    </div>
</section>

<div class="buttons-right-container" [style.margin-top.px]="24">
    <button class="secondary-button" (click)="closeDialog()">
        <h6>
            {{ 'admin.projectOverview.publishLaterButtonText' | translate }}
        </h6>
    </button>
    <button class="primary-button" (click)="publish()">
        <h6>{{ 'admin.projectOverview.publishNowButtonText' | translate }}</h6>
    </button>
</div>
