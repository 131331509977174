import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, catchError, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import {
    addMultipleObjectsRequest,
    addObjectRequest,
    deleteMultipleObjectsRequest,
    deleteObjectRequest,
    getDefaultDesignObjects,
    getDefaultObjects,
    getLiveObjects,
    getUserObjects,
    redoRequest,
    undoRequest,
    updateMultipleObjectsRequest,
    updateObjectRequest,
} from './three-actions';
import { PathObjectsService } from '../_services/path-objects.service';
import { SocketService } from '../_services/socket-service';
import { PathObject } from '../_models/path-object';
import { SocketActionsEnum } from '../_enum/socket-actions.enum';
import { FreezeAction } from '../_models/freeze-action';
import { FreezeActionEnum } from '../_enum/freeze-action.enum';
import { ThreeInstance } from '../_three-helpers/three-instance';

@Injectable()
export class ThreeEffects {
    constructor(
        private actions$: Actions,
        private pathObjectsService: PathObjectsService,
        private socketService: SocketService
    ) { }



    getUserObjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getUserObjects),
            switchMap((action: any) => {
                return this.pathObjectsService
                    .getPathObjects(action.projectId, action.userProfileId)
                    .pipe(
                        map((objects: PathObject[]) => ({
                            type: '[three-editor] load user objects',
                            objects: objects,
                        })),

                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    getDefaultObjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDefaultObjects),
            switchMap((action: any) => {
                return this.pathObjectsService
                    .getPathDefaultObjects(action.projectId)
                    .pipe(
                        map((objects: PathObject[]) => ({
                            type: '[three-editor] load default objects',
                            objects: objects,
                        })),
                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    getDefaultDesignObjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDefaultDesignObjects),
            switchMap((action: any) => {
                return this.pathObjectsService
                    .getPathObjectsForDefaultDP(
                        action.projectId,
                        action.designId
                    )
                    .pipe(
                        map((objects: PathObject[]) => ({
                            type: '[three-editor] load default design objects',
                            objects: objects,
                        })),

                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );


    getLiveObjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getLiveObjects),
            switchMap((action: any) => {
                return this.pathObjectsService
                    .getLivePathObjects(action.dpId)
                    .pipe(
                        map((objects: PathObject[]) => ({
                            type: '[three-editor] load default objects',
                            objects: objects,
                        })),

                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    saveObject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addObjectRequest),
            switchMap((action: any) => {
                return this.pathObjectsService
                    .savePathObjects([action.object])
                    .pipe(
                        map((objects: PathObject[]) => {
                            const socketId = action.socketId;

                            if (socketId) {
                                this.sendSocketInformation(socketId, objects, action.shouldSelect);
                            }

                            return {
                                type: '[three-editor] add user object',
                                object: objects[0],
                                shouldSelect: action.shouldSelect,
                                budget: action.budget,
                            };
                        }),

                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    removeObject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteObjectRequest),
            switchMap((action: any) => {
                return this.pathObjectsService
                    .deletePathObjects([action.object])
                    .pipe(
                        map(() => ({
                            type: '[three-editor] delete object',
                            object: action.object,
                            budget: action.budget,
                        })),

                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    removeMultipleObjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteMultipleObjectsRequest),
            switchMap((action: any) => {
                return this.pathObjectsService
                    .deletePathObjects(action.objects)
                    .pipe(
                        map(() => ({
                            type: '[three-editor] delete multiple objects',
                            objects: action.objects,
                            budget: action.budget,
                        })),
                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    updateObject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateObjectRequest),
            switchMap((action: any) => {
                return this.pathObjectsService
                    .updatePathObject(action.object)
                    .pipe(
                        map((object: PathObject) => ({
                            type: '[three-editor] update default object',
                            object: object,
                        })),
                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    updateMultipleObjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateMultipleObjectsRequest),
            switchMap((action: any) => {
                return this.pathObjectsService
                    .savePathObjects(action.objects)
                    .pipe(
                        map((objects: PathObject[]) => ({
                            type: '[three-editor] update multiple objects',
                            objects: objects,
                        })),
                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    addMultileObjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addMultipleObjectsRequest),
            switchMap((action: any) => {
                return this.pathObjectsService
                    .savePathObjects(action.objects)
                    .pipe(
                        map((objects: PathObject[]) => {
                            const socketId = action.socketId;

                            if (socketId) {
                                const roomAndMessage = {
                                    roomId: socketId,
                                    msg: objects,
                                };
                                const dataStringify =
                                    JSON.stringify(roomAndMessage);
                                const actionStringify = JSON.stringify(
                                    `${SocketActionsEnum.objectsAdd}`
                                );
                                this.socketService.socket.send(
                                    `{"action": ${actionStringify}, "data": ${dataStringify}}`
                                );
                            }

                            return {
                                type: '[three-editor] add multpile default objects',
                                objects: objects,
                                shouldGroup: action.shouldGroup,
                                shouldSelect: action.shouldSelect,
                                budget: action.budget,
                            };
                        }),
                        catchError((error) =>
                            of({
                                type: '[three-editor] error item',
                                message: error,
                            })
                        )
                    );
            })
        )
    );

    undoRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(undoRequest),
            switchMap((action: any) => {
                const requests = [
                    this.pathObjectsService.savePathObjects(
                        action.objectsToSave
                    ),
                    this.pathObjectsService.deletePathObjects(
                        action.objectsToRemove
                    ),
                ];

                return forkJoin(requests).pipe(
                    map((data) => ({
                        type: '[three-editor] undo',
                        objectsToReplace: action.objectsToSave,
                        newObjects: data[0],
                    })),
                    catchError((error) =>
                        of({
                            type: '[three-editor] error item',
                            message: error,
                        })
                    )
                );
            })
        )
    );

    redoRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(redoRequest),
            switchMap((action: any) => {
                const requests = [
                    this.pathObjectsService.savePathObjects(
                        action.objectsToSave
                    ),
                    this.pathObjectsService.deletePathObjects(
                        action.objectsToRemove
                    ),
                ];

                return forkJoin(requests).pipe(
                    map((data) => ({
                        type: '[three-editor] redo',
                        objectsToReplace: action.objectsToSave,
                        newObjects: data[0],
                    })),
                    catchError((error) =>
                        of({
                            type: '[three-editor] error item',
                            message: error,
                        })
                    )
                );
            })
        )
    );

    private sendSocketInformation(socketId: string, objects: any[], shouldSelect: boolean): void {
        const roomAndMessage = {
            roomId: socketId,
            msg: objects,
        };
        const dataStringify =
            JSON.stringify(roomAndMessage);
        const actionStringify = JSON.stringify(
            `${SocketActionsEnum.objectsAdd}`
        );
        this.socketService.socket.send(
            `{"action": ${actionStringify}, "data": ${dataStringify}}`
        );
        if (shouldSelect) {
            setTimeout(() => {
                const freezeAction = new FreezeAction(
                    FreezeActionEnum.freeze,
                    [objects[0].pathObjsId]
                );
                ThreeInstance.freezeSubject.next(
                    freezeAction
                );
            }, 700);
        }
    }
}
