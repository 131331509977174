import {
    OnInit,
    Component,
    ViewContainerRef,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModalManager } from '../furban-modal/furban-modal.manager';
import { CustomToasterComponent } from '../custom-toaster/custom-toaster.component';
import { User } from '../_models/user';
import { UserProfile } from '../_models/user-profile';
import { FurbanLoaderService } from '../furban-loader/furban-loader.service';
import { UserProfileWithRoles } from '../_models/user-profile-with-roles';
import { DesignProposal } from '../_models/design-proposal';
import { ReportService } from '../_services/report.service';
import { Comment } from '../_models/comment';
import { AuthService } from '../_services/auth.service';
import { CustomToasterService } from '../_services/custom-toaster.service';

@Component({
    selector: 'furban-app-user-action-menu',
    templateUrl: './user-action-menu.component.html',
    styleUrls: ['./user-action-menu.component.scss'],
})
export class UserActionMenuComponent implements OnInit {
    @Input() userProfile: UserProfile;
    @Input() parentViewContainerRef: ViewContainerRef;
    @Input() reportedDesignProposal: DesignProposal;
    @Input() reportedComment: Comment;
    @Input() projectName: string;
    @Output() userDelete: EventEmitter<string> = new EventEmitter<string>();

    public user: User;
    public isAdmin = false;

    constructor(
        private authService: AuthService,
        public dialog: MatDialog,
        private translateService: TranslateService,
        private modal: ModalManager,
        private customToasterService: CustomToasterService,
        private reportService: ReportService,
        private loaderService: FurbanLoaderService
    ) {}

    ngOnInit(): void {
        this.isAdmin = this.authService.isAdmin();
    }

    getUser() {
        if (!this.user) {
            this.loaderService.hide();
            this.authService
                .getUserByUserProfileId(this.userProfile.userProfileId)
                .subscribe((data) => {
                    this.user = data as User;
                });
        }
    }

    copyCode() {
        const textToBeCopied = this.user.username;
        if (!navigator || !navigator['clipboard']) {
            this.fallbackCopyTextToClipboard(textToBeCopied);
            return;
        }
        navigator['clipboard'].writeText(textToBeCopied).then(
            () => {
                this.showSuccessMessage();
            },
            (err) => {
                this.showFailMessage();
            }
        );
    }

    fallbackCopyTextToClipboard(text) {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            if (successful) {
                this.showSuccessMessage();
            } else {
                this.showFailMessage();
            }
        } catch (err) {
            this.showFailMessage();
        }

        document.body.removeChild(textArea);
    }

    showSuccessMessage() {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'success',
            this.user.role.roleWeight > 1
                ? this.translateService.instant(
                      'admin.userMenuActions.codeCopied',
                      { code: this.user.username }
                  )
                : 'admin.userMenuActions.emailCopied',
            3000
        );
    }

    showFailMessage() {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            'check_circle',
            'error',
            this.translateService.instant('admin.userMenuActions.failToCopy') +
                this.user.username,
            60000
        );
    }

    deleteUser() {
        this.authService.suspendUser(this.user.username).subscribe((data) => {
            if (this.reportedDesignProposal && !this.reportedComment) {
                this.reportService
                    .getUserProfilesForDP(
                        this.reportedDesignProposal.designProposalId
                    )
                    .subscribe((userProfiles) => {
                        this.afterDeleteUser(
                            userProfiles,
                            this.translateService.instant(
                                'user.chat.unproperUserMessage',
                                { projectName: this.projectName }
                            )
                        );
                    });
            } else if (this.reportedDesignProposal && this.reportedComment) {
                this.reportService
                    .getUserProfilesForComment(this.reportedComment.commentId)
                    .subscribe((userProfiles) => {
                        this.afterDeleteUser(
                            userProfiles,
                            this.translateService.instant(
                                'user.chat.unproperUserMessageComment',
                                { comment: this.reportedComment.commentText }
                            )
                        );
                    });
            }

            this.userDelete.emit(this.userProfile.userProfileId);
        });
    }

    afterDeleteUser(userProfiles: UserProfileWithRoles[], message: string) {
        if (userProfiles && userProfiles.length > 0) {
            /*
     TO-DO implement chat
     this.talkService.sendAdministrationMessageFromChat(userProfiles, this.reportedDesignProposal.projectId, this.projectName, message);
     */
        }
    }

    showWarningModal() {
        this.modal
            .showModal(
                this.parentViewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'admin.userMenuActions.deleteUserWarning',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.deleteUser();
                }
            });
    }

    // REFACTOR - use this from the service
    public isRoleAdmin(user: User): boolean {
        if (user.role.roleWeight < 2) {
            return true;
        }
        return false;
    }
}
