<h2 mat-dialog-title>{{ 'user.publish.dialogTitle' | translate }}</h2>
<mat-dialog-content>
    <div>
        <div *ngIf="
                publishService.designProposal &&
                publishService.designProposal.mediaId
            " class="publish-image">
            <img class="project-screenshot-image" [src]="getMedia(publishService.designProposal.mediaId)"
                alt="ProjPic" />
            <div class="overlay flex-row-end">
                <button class="secondary-button" (click)="changeToScreenShot()">
                    <h6>{{ 'user.publish.changeScreenShot' | translate }}</h6>
                </button>
            </div>
        </div>

        <div *ngIf="
                publishService.designProposal &&
                !publishService.designProposal.mediaId
            " class="popup-image">
            <span class="material-icons">add_a_photo</span>
            <button class="primary-button small" (click)="takeSS()">
                <h6>{{ 'user.publish.screenShot' | translate }}</h6>
            </button>
            <h6 class="warning">
                {{ 'user.publish.defaultWarning' | translate }}
            </h6>
        </div>
    </div>

    <form [formGroup]="publishForm">
        <mat-form-field appearance="outline" class="grey">
            <mat-label>
                {{ 'user.publish.descriptionPlaceholder' | translate }}
            </mat-label>
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" placeholder="{{
                    'user.publish.descriptionPlaceholder' | translate
                }}" formControlName="designDescription" #description cdkAutosizeMaxRows="5" maxlength="140">
            </textarea>
            <mat-hint align="end">{{ description.value.length }} / 140</mat-hint>
            <mat-error *ngIf="hasControlMaxLengthError('designDescription',publishForm)">
                {{ 'user.publish.descriptionLength' | translate }}
            </mat-error>
            <mat-error *ngIf="hasControlIsHtmlError('designDescription',publishForm)">
                {{ 'comments.noHTMLContent' | translate }}
            </mat-error>
        </mat-form-field>
    </form>
    <div class="info-message" [style.width.%]="100">
        <h4>{{ 'errors.info' | translate }}</h4>
        <h5 [style.font-size.px]="12">
            {{ 'user.publish.warningMessage' | translate }}
        </h5>
    </div>
</mat-dialog-content>

<div class="buttons-right-container">
    <button class="secondary-button" (click)="closeDialog()">
        <h6>{{ 'generic.cancelBtn' | translate }}</h6>
    </button>
    <button class="primary-button" (click)="publishDesign()">
        <h6>{{ 'user.publish.publishBtn' | translate }}</h6>
    </button>
</div>