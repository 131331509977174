import { ShepherdTourService } from './_services/shepherd-tour.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomToasterComponent } from './custom-toaster/custom-toaster.component';
import { TranslateModule } from '@ngx-translate/core';
import { MultilanguageService } from './multilanguage/multilanguage.service';
import { FurbanLoaderService } from './furban-loader/furban-loader.service';
import { ModalConfigService } from './furban-modal/furban-modal.config';
import { ScriptService } from './_services/script.service';
import { ReportService } from './_services/report.service';
import { HomepageHeaderComponent } from './homepage-header/homepage-header.component';
import { CardComponent } from './card/card.component';
import { StepperService } from './stepper/stepper.service';
import { FurbanStepperComponent } from './stepper/stepper.component';
import { ModalManager } from './furban-modal/furban-modal.manager';
import { ConfigurableHeaderComponent } from './configurable-header/configurable-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarPictureComponent } from './avatar-picture/avatar-picture.component';
import { UserActionMenuComponent } from './user-action-menu/user-action-menu.component';
import { AuthService } from './_services/auth.service';
import { PermitCommentsService } from './_services/permit-comments.service';
import { ProjectService } from './_services/project.service';
import { MapService } from './_services/map.service';
import { CommentsPopupComponent } from './comments-popup/comments-popup.component';
import { FurbanMaterialModule } from './material.module';
import { ModalComponent } from './furban-modal/furban-modal.component';
import { MenuService } from './_services/menu.service';
import { BaseMapComponent } from './base-map/base-map.component';
import { FurbanLoaderComponent } from './furban-loader/furban-loader.component';
import { MultilanguageComponent } from './multilanguage/multilanguage.component';
import { ErrorService } from './_services/error.service';
import { DesignProposalService } from './_services/design-proposals.service';
import { FileUploadService } from './_services/file-upload.service';
import { LoginGuard } from './_services/login.guard';
import { MunicipalityService } from './_services/municipality.service';
import { NotificationService } from './_services/notifications.service';
import { PathObjectsService } from './_services/path-objects.service';
import { PermitThreeService } from './_services/permit-three.service';
import { PreviousRouteService } from './_services/previous-route-service';
import { ProjectSettingsService } from './_services/project-settings.service';
import { ProjectStorageService } from './_services/project-storage.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UserVerificationEmailService } from './_services/user-verification-email.service';
import { HoldableDirective } from './_directives/holdable.directive';
import { CommentsComponent } from './comments/comments.component';
import { CommentsService } from './comments/comments.service';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { CustomToasterService } from './_services/custom-toaster.service';
import { SvgContainerComponent } from './svg-container/svg-container.component';
import { ThreeCommentPopupComponent } from './three-comment-popup/three-comment-popup.component';
import { DragUploadComponent } from './drag-upload/drag-upload.component';
import { TutorialsMenuComponent } from './tutorials-menu/tutorials-menu.component';
import { TutorialsService } from './_services/tutorials.service';
import { AdminCardComponent } from './admin-card/admin-card.component';
import { CommentCardComponent } from './comment-card/comment-card.component';
import { GeneralCommentComponent } from './general-comment/general-comment.component';
import { ConversationComponent } from './conversation/conversation.component';
import { ConversationDividerComponent } from './conversation-divider/conversation-divider.component';
import { SocketService } from './_services/socket-service';
import { AddressSearchComponent } from './address-search/address-search.component';
import { ProjectDetailsCardComponent } from './project-details-card/project-details-card.component';
import { HouseService } from './_services/house.service';
import { CommentFormComponent } from './comment/comment-form/comment-form.component';
import { CommentBodyComponent } from './comment/comment-body/comment-body.component';
import { Environment } from './_models/environment-model';
import { MediaService } from './_services/media.service';
import { ThreeRotateDirective } from './_directives/three-rotate.directive';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FurbanMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        PickerModule,
    ],
    declarations: [
        DragUploadComponent,
        ConfigurableHeaderComponent,
        CustomToasterComponent,
        HomepageHeaderComponent,
        CardComponent,
        FurbanStepperComponent,
        AvatarPictureComponent,
        UserActionMenuComponent,
        CommentsPopupComponent,
        CommentsComponent,
        ModalComponent,
        BaseMapComponent,
        FurbanLoaderComponent,
        MultilanguageComponent,
        HoldableDirective,
        ThreeRotateDirective,
        SvgContainerComponent,
        ThreeCommentPopupComponent,
        CommentFormComponent,
        CommentBodyComponent,
        TutorialsMenuComponent,
        AdminCardComponent,
        ProjectDetailsCardComponent,
        CommentCardComponent,
        GeneralCommentComponent,
        ConversationComponent,
        ConversationDividerComponent,
        AddressSearchComponent,
    ],
    exports: [
        DragUploadComponent,
        ConfigurableHeaderComponent,
        CustomToasterComponent,
        HomepageHeaderComponent,
        CardComponent,
        AvatarPictureComponent,
        UserActionMenuComponent,
        CommentsPopupComponent,
        CommentsComponent,
        ModalComponent,
        BaseMapComponent,
        FurbanLoaderComponent,
        MultilanguageComponent,
        HoldableDirective,
        ThreeRotateDirective,
        FurbanStepperComponent,
        SvgContainerComponent,
        ThreeCommentPopupComponent,
        TutorialsMenuComponent,
        AdminCardComponent,
        ProjectDetailsCardComponent,
        CommentCardComponent,
        GeneralCommentComponent,
        CommentFormComponent,
        ConversationComponent,
        ConversationDividerComponent,
        AddressSearchComponent,
    ],
    providers: [
        MultilanguageService,
        FurbanLoaderService,
        AuthService,
        ScriptService,
        StepperService,
        PermitCommentsService,
        ProjectService,
        MapService,
        ModalManager,
        ModalConfigService,
        MenuService,
        ErrorService,
        DesignProposalService,
        FileUploadService,
        LoginGuard,
        MunicipalityService,
        NotificationService,
        PathObjectsService,
        PermitThreeService,
        PreviousRouteService,
        ProjectSettingsService,
        ProjectStorageService,
        ReportService,
        ShepherdTourService,
        UserVerificationEmailService,
        CommentsService,
        CustomToasterService,
        TutorialsService,
        HouseService,
        SocketService,
        MediaService
    ],
})
export class UtilitiesModule {
    public static forRoot(environment: Environment): ModuleWithProviders<UtilitiesModule> {
        return {
            ngModule: UtilitiesModule,
            providers: [
                {
                    provide: 'env',
                    useValue: environment
                }
            ]
        };
    }
}
