export class Tutorial {
    name: string;
    gifPath: string;
    title: string;
    description: string;
    imagePath: string;

    public constructor(
        name?: string,
        title?: string,
        description?: string,
        gifPath?: string,
        imagePath?: string
    ) {
        this.name = name;
        this.gifPath = gifPath;
        this.title = title;
        this.description = description;
        this.imagePath = imagePath;
    }
}
