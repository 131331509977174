import {
    Component,
    AfterViewInit,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyModalComponent } from './privacy-modal/privacy-modal.component';
import { TermsAndConditionsComponent } from '../shared/terms-and-conditions/terms.component';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import '@google/model-viewer';

import {
    Card,
    DialogUtil,
    FurbanSubscription,
    routingConstants,
    FurbanUtil,
} from '@furban/utilities';
import { ProjectsOnAreaComponent } from '../projects-on-area/projects-on-area.component';

@Component({
    selector: 'furban-info',
    templateUrl: './furban-info.component.html',
    styleUrls: ['./furban-info.component.scss'],
})
export class FurbanInfoComponent implements AfterViewInit, OnInit {
    @ViewChild('projectsAroundMap') projectsAroundMap: ProjectsOnAreaComponent;
    public cardConfig: Card = new Card();
    public subscriptions: FurbanSubscription[];
    public selectedSubscription: FurbanSubscription;
    public displayMapOverlay = true;
    constructor(
        public dialog: MatDialog,
        private router: Router,
        private translateService: TranslateService,
        @Inject(DOCUMENT) private document: Document
    ) { }

    ngOnInit(): void {
        this.setCardConfig();
        this.setSubscriptions();
    }

    public get currentYear(): number {
        return new Date(Date.now()).getFullYear();
    }

    ngAfterViewInit(): void {
        const content = document.querySelector('.main-container');
        const projectsMap = document.querySelector('.projects-map');
        const scroll$ = fromEvent(content, 'scroll').pipe(map(() => content));
        scroll$.subscribe((element) => {
            if (this.projectsAroundMap) {
                this.projectsAroundMap.updateMapPosition();
            }
            const header = document.getElementById('mainNav');
            if (header) {
                if (content.scrollTop > 100) {
                    header.classList.add('navbar-shrink');
                } else {
                    header.classList.remove('navbar-shrink');
                }
            }

            if (!FurbanUtil.isInViewport(projectsMap)) {
                this.displayMapOverlay = true;
            }
        });
    }

    public openPrivacy(): void {
        DialogUtil.openModalFromTemplate(this.dialog, PrivacyModalComponent);
    }

    public openTerms(): void {
        DialogUtil.openModalFromTemplate(
            this.dialog,
            TermsAndConditionsComponent
        );
    }

    public goToAdmin(): void {
        this.router.navigateByUrl(routingConstants.admin);
    }

    public goToCitizen(): void {
        this.router.navigateByUrl(routingConstants.app);
    }

    public scrollToElement(elementString: string): void {
        const element = document.querySelector(elementString);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    public setCardConfig(): void {
        this.cardConfig.cardType = 'white';
        this.cardConfig.contentAlignmentHorizontal = 'start';
        this.cardConfig.contentAlignmentVertical = 'start';
        this.cardConfig.hasHeader = true;
        this.cardConfig.height = 224;
        this.cardConfig.hoverable = true;
    }

    public setSubscriptions(): void {
        this.subscriptions = [];

        const basicSubscription = new FurbanSubscription('basic', false, 4);
        this.subscriptions.push(basicSubscription);

        const mediumSubscription = new FurbanSubscription('medium', false, 6);
        this.subscriptions.push(mediumSubscription);
        this.selectedSubscription = mediumSubscription;

        const premiumSubscription = new FurbanSubscription('premium', false, 6);
        this.subscriptions.push(premiumSubscription);
    }

    public computeEmailString(subscription: FurbanSubscription): string {
        return (
            'mailto:sander.meinders@centric.eu?subject=' +
            this.translateService.instant(subscription.emailSubject) +
            '&body=' +
            this.translateService.instant(subscription.emailBody)
        );
    }

    public selectSubscription(subscription: FurbanSubscription): void {
        this.selectedSubscription = subscription;
    }

    public goToFindProjects(): void {
        this.router.navigateByUrl(routingConstants.takingPart);
    }

    public hideOverlay(): void {
        this.displayMapOverlay = false;
    }
}
