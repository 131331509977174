import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PioneerDashboardComponent } from './pioneer-dashboard/pioneer-dashboard.component';

import { environment } from 'apps/furban-client/src/environments/environment';
import { PioneerLoginGuard } from './guards/pioneer-login.guard';
import { RegisterComponent } from '../shared/register/register.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { ProjectDetailsComponent } from '../project-shared/project-details/project-details.component';
import { ProjectDesignsComponent } from '../project-shared/project-design-proposals/project-design-proposals.component';
import { ModifiedGuard } from '../shared/modifyStatus/modified.guard';
import { UserProjectComponent } from '../project-shared/user-project/project/user-project.component';
import { ProjectPioneerGroupComponent } from '../project-shared/project-pioneer-group/project-pioneer-group.component';
import { PioneerProjectsComponent } from './pioneer-projects/pioneer-projects.component';
import { DesignProposalDetailsComponent } from '../shared/design-proposal-details/design-proposal-details.component';

import { routingConstants, ProjectTypeNameEnum } from '@furban/utilities';
import { ProjectMapComponent } from '../project-shared/project-map/project-map.component';
import { ProjectContainerComponent } from '../project-shared/project-container/project-container.component';
import { ProjectDashboardComponent } from '../project-shared/project-dashboard/project-dashboard.component';
import { UserStatisticsComponent } from '../project-shared/user-statistics/user-statistics.component';
import { ObjectStatisticsComponent } from '../project-shared/object-statistics/object-statistics.component';
import { StatisticsOverviewComponent } from '../project-shared/statistics-overview/statistics-overview.component';
import { PollStatisticsComponent } from '../project-shared/poll-statistics/poll-statistics.component';

const pioneerRoutes: Routes = [
    {
        path: `${routingConstants.justApp}/partner/initiatives`,
        children: [
            {
                path: '',
                component: PioneerDashboardComponent,
                canActivate: [PioneerLoginGuard],
                data: { env: environment, role: 'pioneer' },
            },
            {
                path: 'projects',
                component: PioneerProjectsComponent,
                canActivate: [PioneerLoginGuard],
                data: { env: environment, role: 'pioneer' },
            },
            {
                path: 'process/:id/user-statistics',
                component: UserStatisticsComponent,
                canActivate: [PioneerLoginGuard],
                canDeactivate: [ModifiedGuard],
                data: { env: environment, role: 'pioneer' }
            },
            {
                path: 'process/:id/object-statistics',
                component: ObjectStatisticsComponent,
                canActivate: [PioneerLoginGuard],
                canDeactivate: [ModifiedGuard],
                data: { env: environment, role: 'pioneer' }
            },
            {
                path: 'process/:projectId/statistics-overview',
                component: StatisticsOverviewComponent,
                canActivate: [PioneerLoginGuard],
                canDeactivate: [ModifiedGuard],
                data: { env: environment, role: 'pioneer' }
            },
            {
                path: 'process/:id/poll-statistics',
                component: PollStatisticsComponent,
                canActivate: [PioneerLoginGuard],
                canDeactivate: [ModifiedGuard],
                data: { env: environment, role: 'pioneer' }
            },
            {
                path: 'process/:id/design-proposals',
                component: ProjectDesignsComponent,
                canActivate: [PioneerLoginGuard],
                canDeactivate: [ModifiedGuard],
                data: { env: environment, role: 'pioneer' }
            },
            {
                path: 'process/:id',
                component: ProjectContainerComponent,
                canActivate: [PioneerLoginGuard],
                canDeactivate: [ModifiedGuard],
                data: {
                    env: environment,
                    role: 'pioneer',
                    type: ProjectTypeNameEnum.pioneerInitiativeProject,
                },

                children: [
                    {
                        path: 'details',
                        component: ProjectDetailsComponent,
                        canActivate: [PioneerLoginGuard],
                        data: {
                            env: environment,
                            role: 'pioneer',
                            type: ProjectTypeNameEnum.pioneerInitiativeProject,
                        },
                        outlet: 'project',
                    },
                    {
                        path: 'area-definition',
                        component: ProjectMapComponent,
                        canActivate: [PioneerLoginGuard],
                        data: {
                            env: environment,
                            role: 'pioneer',
                            type: ProjectTypeNameEnum.pioneerInitiativeProject,
                        },
                        outlet: 'project',
                    },
                    {
                        path: 'objects',
                        component: UserProjectComponent,
                        canActivate: [PioneerLoginGuard],
                        data: {
                            env: environment,
                            role: 'pioneer',
                            type: ProjectTypeNameEnum.pioneerInitiativeProject,
                        },
                        outlet: 'project',
                    },
                    {
                        path: 'manage-users',
                        component: ProjectPioneerGroupComponent,
                        canActivate: [PioneerLoginGuard],
                        data: {
                            env: environment,
                            role: 'pioneer',
                            type: ProjectTypeNameEnum.pioneerInitiativeProject,
                        },
                        outlet: 'project',
                    },
                    {
                        path: 'project-designs',
                        component: ProjectDesignsComponent,
                        canActivate: [PioneerLoginGuard],
                        data: {
                            env: environment,
                            role: 'pioneer',
                            type: ProjectTypeNameEnum.pioneerInitiativeProject,
                        },
                        outlet: 'project',
                    },
                    {
                        path: 'overview',
                        component: ProjectDashboardComponent,
                        canActivate: [PioneerLoginGuard],
                        data: {
                            env: environment,
                            role: 'pioneer',
                            type: ProjectTypeNameEnum.pioneerInitiativeProject,
                        },
                        outlet: 'project',
                    },
                ],
            },
            {
                path: 'design-proposal/:id',
                component: DesignProposalDetailsComponent,
                canActivate: [PioneerLoginGuard],
                data: {
                    env: environment,
                    role: 'pioneer',
                    type: ProjectTypeNameEnum.pioneerInitiativeProject,
                },
            },
            {
                path: 'register',
                component: RegisterComponent,
                data: { env: environment, role: 'pioneer' },
            },
            {
                path: '**',
                redirectTo: '',
            },
        ],
    },
    {
        path: `${routingConstants.justApp}/partner/pioneer/confirmation/:sentEmailId`,
        component: EmailVerificationComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(pioneerRoutes)],
    exports: [RouterModule],
})
export class PioneerRoutingModule { }
