import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { tap } from 'rxjs/operators';
import { FurbanLoaderService } from './furban-loader.service';
import { ErrorService } from '../_services/error.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LoaderHttpParams } from '../_models/loader-http-param';
import { SkipErrorHttpParam } from '../_models/skip-error-http-param';
import { SilenceHttpParams } from '../_models/silence-http-param';
import { AuthService } from '../_services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class FurbanHttpInterceptor implements HttpInterceptor {
    private totalRequests = 0;

    constructor(
        private furbanLoaderService: FurbanLoaderService,
        private errorService: ErrorService,
        private translateService: TranslateService,
        private authService: AuthService,
        private router: Router
    ) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!this.checkIfIgnoreCase(req)) {
            this.totalRequests++;

            let isHalfSize;
            if (req.params.get('isHalfSize')) {
                isHalfSize = true;
            } else {
                isHalfSize = false;
            }
            this.showLoader(isHalfSize);
        }

        return next.handle(req).pipe(
            tap(
                (event: HttpEvent<any>) => this.success(event),
                (error: any) => {
                    if (this.checkIfSkippingError(req)) {
                        this.decreaseRequests();
                        return;
                    }
                    this.fail(error);
                }
            )
        );
    }

    private decreaseRequests() {
        this.totalRequests--;
        if (this.totalRequests <= 0) {
            this.onEnd();
        }
    }

    private success(event): void {
        if (event instanceof HttpResponse) {
            this.errorService.clientErrorCode = null;
            this.errorService.errorHandler = null;
            this.decreaseRequests();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private fail(error): Observable<any> {
        this.decreaseRequests();
        let message = this.translateService.instant('errors.generic');
        if (error) {
            if (error.headers && error.headers.get('x-err-code')) {
                const errorCode = error.headers.get('x-err-code');
                if (errorCode === '40022' || errorCode === '40023') {
                    message = errorCode;
                } else if (errorCode === '40008') {
                    message = this.translateService.instant('errors.40008');
                    this.authService.redirectByRole();
                } else {
                    message = this.translateService.instant(
                        `errors.${errorCode}`
                    );
                }
            } else if (
                this.errorService.clientErrorCode &&
                !error.error['auth-not-confirmed'] &&
                !error.error['auth-not-validated']
            ) {
                message = this.translateService.instant(
                    `errors.${this.errorService.clientErrorCode}`
                );
            } else if (
                error.status === 401 ||
                (error.status === 500 &&
                    error.message.indexOf('Invalid remember-me token') > -1)
            ) {
                // redirect user to auth
                this.authService.user = null;
                this.authService.userProfile = null;
                this.authService.redirectUserToLogin();
                // this.talkJsService.logoutFromTalkJs();
                return throwError(error);
            } else if (error.status === 403) {
                if (error.error['auth-blocked']) {
                    return;
                } else if (error.error['user-suspended']) {
                    message = this.translateService.instant('errors.suspended');
                } else if (error.error['auth-not-confirmed']) {
                    message = this.translateService.instant(
                        'errors.notConfirmed'
                    );
                } else if (error.error['auth-not-validated']) {
                    message = this.translateService.instant(
                        'errors.notValidated'
                    );
                } else {
                    message = this.translateService.instant('errors.403');
                }
            } else if (error.status === 404) {
                message = this.translateService.instant('errors.404');
            } else if (error.status === 500) {
                message = this.translateService.instant('errors.500');
            }
            this.errorService.addError(message);
            return throwError(error);
        }
    }

    private onEnd(): void {
        this.hideLoader();
    }
    private showLoader(half: boolean): void {
        this.furbanLoaderService.show(half);
    }

    private hideLoader(): void {
        this.furbanLoaderService.hide();
        this.totalRequests = 0;
    }

    private checkIfIgnoreCase(req: HttpRequest<any>): boolean {
        // 'LoaderHttpParams' is DEPRECATED and needs to be replaced with 'SilenceHttpParams'
        if (
            (req.params instanceof LoaderHttpParams &&
                req.params.isIgnoreCase) ||
            (req.params.get('isIgnoreCase') &&
                req.params.get('isIgnoreCase') === 'true')
        ) {
            return true;
        } else if (
            (req.params instanceof SilenceHttpParams &&
                req.params.isLoaderHidden) ||
            (req.params.get('isLoaderHidden') &&
                req.params.get('isLoaderHidden') === 'true')
        ) {
            return true;
        }
        return false;
    }

    private checkIfSkippingError(req: HttpRequest<any>): boolean {
        // 'SkipErrorHttpParam' is DEPRECATED and needs to be replaced with 'SilenceHttpParams'
        if (req.params instanceof SkipErrorHttpParam && req.params.skipError) {
            return true;
        } else if (
            (req.params instanceof SilenceHttpParams &&
                req.params.isErrorIgnored) ||
            (req.params.get('isErrorIgnored') &&
                req.params.get('isErrorIgnored') === 'true')
        ) {
            return true;
        }
        return false;
    }
}
