import { SunOrientation } from '../_models/sun-orientation';

export class SolarOrientationCalculator {
    public static getAzEl(
        lat: number,
        lon: number,
        date = new Date()
    ): SunOrientation {
        const jday = this.getJD(date);
        const tl = this.getTimeLocal(date);
        const tz = date.getTimezoneOffset() / -60;
        const total = jday + tl / 1440.0 - tz / 24.0;
        const T = this.calcTimeJulianCent(total);

        return this.calcAzEl(T, tl, lat, lon, tz);
    }

    public static getJD(date = new Date()): number {
        let docmonth = date.getMonth() + 1;
        let docday = date.getDate();
        let docyear = date.getFullYear();
        if (this.isLeapYear(docyear) && docmonth == 2) {
            if (docday > 29) {
                docday = 29;
            }
        } else {
            // 1900 is a known non-leap year
            if (docday > new Date(1900, docmonth, 0).getDate()) {
                docday = new Date(1900, docmonth, 0).getDate();
            }
        }
        if (docmonth <= 2) {
            docyear -= 1;
            docmonth += 12;
        }
        const A = Math.floor(docyear / 100);
        const B = 2 - A + Math.floor(A / 4);
        const JD =
            Math.floor(365.25 * (docyear + 4716)) +
            Math.floor(30.6001 * (docmonth + 1)) +
            docday +
            B -
            1524.5;
        return JD;
    }

    // Returns the current time in minutes without the DST
    public static getTimeLocal(date = new Date()): number {
        let totalMinutes = 0.0;
        totalMinutes += 60.0 * date.getHours();
        // TODO
        // Remove one hour if DST is in effect
        totalMinutes += date.getMinutes();
        totalMinutes += date.getSeconds() / 60.0;
        return totalMinutes;
    }

    public static calcTimeJulianCent(jd): number {
        const T = (jd - 2451545.0) / 36525.0;
        return T;
    }

    public static calcAzEl(
        T: number,
        localtime: number,
        latitude: number,
        longitude: number,
        zone: number
    ): SunOrientation {
        const result = new SunOrientation(0, 0);
        const eqTime = this.calcEquationOfTime(T);
        const theta = this.calcSunDeclination(T);
        const solarTimeFix = eqTime + 4.0 * longitude - 60.0 * zone;
        let trueSolarTime = localtime + solarTimeFix;
        while (trueSolarTime > 1440) {
            trueSolarTime -= 1440;
        }
        let hourAngle = trueSolarTime / 4.0 - 180.0;
        if (hourAngle < -180) {
            hourAngle += 360.0;
        }
        const haRad = this.degToRad(hourAngle);
        let csz =
            Math.sin(this.degToRad(latitude)) * Math.sin(this.degToRad(theta)) +
            Math.cos(this.degToRad(latitude)) *
                Math.cos(this.degToRad(theta)) *
                Math.cos(haRad);
        if (csz > 1.0) {
            csz = 1.0;
        } else if (csz < -1.0) {
            csz = -1.0;
        }
        const zenith = this.radToDeg(Math.acos(csz));
        const azDenom =
            Math.cos(this.degToRad(latitude)) * Math.sin(this.degToRad(zenith));
        let azimuth;
        if (Math.abs(azDenom) > 0.001) {
            let azRad =
                (Math.sin(this.degToRad(latitude)) *
                    Math.cos(this.degToRad(zenith)) -
                    Math.sin(this.degToRad(theta))) /
                azDenom;
            if (Math.abs(azRad) > 1.0) {
                if (azRad < 0) {
                    azRad = -1.0;
                } else {
                    azRad = 1.0;
                }
            }
            azimuth = 180.0 - this.radToDeg(Math.acos(azRad));
            if (hourAngle > 0.0) {
                azimuth = -azimuth;
            }
        } else {
            if (latitude > 0.0) {
                azimuth = 180.0;
            } else {
                azimuth = 0.0;
            }
        }
        if (azimuth < 0.0) {
            azimuth += 360.0;
        }
        const exoatmElevation = 90.0 - zenith;

        // Atmospheric Refraction correction
        let refractionCorrection = 0.0;

        if (exoatmElevation > 85.0) {
            refractionCorrection = 0.0;
        } else {
            const te = Math.tan(this.degToRad(exoatmElevation));
            if (exoatmElevation > 5.0) {
                refractionCorrection =
                    58.1 / te -
                    0.07 / (te * te * te) +
                    0.000086 / (te * te * te * te * te);
            } else if (exoatmElevation > -0.575) {
                refractionCorrection =
                    1735.0 +
                    exoatmElevation *
                        (-518.2 +
                            exoatmElevation *
                                (103.4 +
                                    exoatmElevation *
                                        (-12.79 + exoatmElevation * 0.711)));
            } else {
                refractionCorrection = -20.774 / te;
            }
            refractionCorrection = refractionCorrection / 3600.0;
        }

        const solarZen = zenith - refractionCorrection;

        result.azimuth = Math.floor(azimuth * 100 + 0.5) / 100.0;
        result.elevation = Math.floor((90.0 - solarZen) * 100 + 0.5) / 100.0;
        return result;
    }

    public static isLeapYear(yr): boolean {
        return (yr % 4 == 0 && yr % 100 != 0) || yr % 400 == 0;
    }

    public static radToDeg(angleRad: number): number {
        return (180.0 * angleRad) / Math.PI;
    }

    public static degToRad(angleDeg: number): number {
        return (Math.PI * angleDeg) / 180.0;
    }

    public static calcEquationOfTime(t: number): number {
        const epsilon = this.calcObliquityCorrection(t);
        const l0 = this.calcGeomMeanLongSun(t);
        const e = this.calcEccentricityEarthOrbit(t);
        const m = this.calcGeomMeanAnomalySun(t);

        let y = Math.tan(this.degToRad(epsilon) / 2.0);
        y *= y;

        const sin2l0 = Math.sin(2.0 * this.degToRad(l0));
        const sinm = Math.sin(this.degToRad(m));
        const cos2l0 = Math.cos(2.0 * this.degToRad(l0));
        const sin4l0 = Math.sin(4.0 * this.degToRad(l0));
        const sin2m = Math.sin(2.0 * this.degToRad(m));

        const Etime =
            y * sin2l0 -
            2.0 * e * sinm +
            4.0 * e * y * sinm * cos2l0 -
            0.5 * y * y * sin4l0 -
            1.25 * e * e * sin2m;
        return this.radToDeg(Etime) * 4.0; // in minutes of time
    }

    public static calcSunDeclination(t: number): number {
        const e = this.calcObliquityCorrection(t);
        const lambda = this.calcSunApparentLong(t);

        const sint =
            Math.sin(this.degToRad(e)) * Math.sin(this.degToRad(lambda));
        const theta = this.radToDeg(Math.asin(sint));
        return theta; // in degree
    }

    public static calcObliquityCorrection(t: number): number {
        const e0 = this.calcMeanObliquityOfEcliptic(t);
        const omega = 125.04 - 1934.136 * t;
        const e = e0 + 0.00256 * Math.cos(this.degToRad(omega));
        return e; // in degree
    }

    public static calcSunApparentLong(t: number): number {
        const o = this.calcSunTrueLong(t);
        const omega = 125.04 - 1934.136 * t;
        const lambda = o - 0.00569 - 0.00478 * Math.sin(this.degToRad(omega));
        return lambda; // in degrees
    }

    public static calcGeomMeanLongSun(t: number): number {
        let L0 = 280.46646 + t * (36000.76983 + t * 0.0003032);
        while (L0 > 360.0) {
            L0 -= 360.0;
        }
        while (L0 < 0.0) {
            L0 += 360.0;
        }
        return L0; // in degrees
    }

    public static calcEccentricityEarthOrbit(t: number): number {
        const e = 0.016708634 - t * (0.000042037 + 0.0000001267 * t);
        return e; // unitless
    }

    public static calcGeomMeanAnomalySun(t: number): number {
        const M = 357.52911 + t * (35999.05029 - 0.0001537 * t);
        return M; // in degrees
    }

    public static calcSunTrueAnomaly(t: number): number {
        const m = this.calcGeomMeanAnomalySun(t);
        const c = this.calcSunEqOfCenter(t);
        const v = m + c;
        return v; // in degrees
    }

    public static calcMeanObliquityOfEcliptic(t: number): number {
        const seconds = 21.448 - t * (46.815 + t * (0.00059 - t * 0.001813));
        const e0 = 23.0 + (26.0 + seconds / 60.0) / 60.0;
        return e0; // in degrees
    }

    public static calcSunTrueLong(t: number): number {
        const l0 = this.calcGeomMeanLongSun(t);
        const c = this.calcSunEqOfCenter(t);
        const O = l0 + c;
        return O; // in degrees
    }

    public static calcSunEqOfCenter(t: number): number {
        const m = this.calcGeomMeanAnomalySun(t);
        const mrad = this.degToRad(m);
        const sinm = Math.sin(mrad);
        const sin2m = Math.sin(mrad + mrad);
        const sin3m = Math.sin(mrad + mrad + mrad);
        const C =
            sinm * (1.914602 - t * (0.004817 + 0.000014 * t)) +
            sin2m * (0.019993 - 0.000101 * t) +
            sin3m * 0.000289;
        return C; // in degrees
    }
}
