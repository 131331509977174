import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { routingConstants } from '../_constants/routing-constants';
import { Project } from '../_models/project';
import { ProjectLight } from '../_models/project-light';
import { MediaService } from '../_services/media.service';

@Component({
    selector: 'furban-project-details-card',
    templateUrl: './project-details-card.component.html',
    styleUrls: ['./project-details-card.component.scss'],
})
export class ProjectDetailsCardComponent {
    @Input() project: Project | ProjectLight;

    constructor(private router: Router,
        private mediaService: MediaService) { }

    public goToProjectList(): void {
        this.router.navigate([routingConstants.takingPart]);
    }

    public getMedia(mediaId): string {
        return this.mediaService.getMedia(mediaId);
    }
}
