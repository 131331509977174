<div [style.margin-top.px]="76">
    <div class="info-message">
        <h4>{{ 'errors.info' | translate }}</h4>
        <h5>{{ 'admin.setupDefaultObjects.alreadyHaveDPs' | translate }}</h5>
    </div>
    <form [formGroup]="objectsPriceForm"
          *ngIf="loaded">
        <mat-tree [dataSource]="suppliersDataSource"
                  [treeControl]="suppliersTreeControl"
                  class="suppliers-tree">
            <mat-tree-node *matTreeNodeDef="let node"
                           matTreeNodeToggle>
                <li class="leaf-node-content flex-column-end"
                    *ngIf="node.furban3DModel"
                    [class.disabled-object]="isNodeDisabled(node)">
                    <mat-checkbox class="flex-column-end checklist-leaf-node"
                                  [disabled]="isNodeDisabled(node)"
                                  [checked]="checklistSelection.isSelected(node)"
                                  (click)="nodeToggleClick($event, node)"
                                  (change)="supplierLeafNodeSelectionToggle(node)"
                                  [matTooltip]="
                            getObjectTranslationsLabel(node) | translate
                        ">
                        <div [style.background-image]="getObjectImageURL(node)"
                             class="object-image"></div>
                    </mat-checkbox>
                    <fieldset class="flex-row-center"
                              [disabled]="isNodeSelectedAndHasPublishedDPs(node)">
                        <mat-form-field *ngIf="
                                !isFreeShape(node) &&
                                !isCustomObject(node) &&
                                !isWall(node)
                            "
                                        class="flex-row-end">
                            <input matInput
                                   type="number"
                                   max="1000000"
                                   required
                                   [placeholder]="
                                    'objectsManager.price' | translate
                                "
                                   [formControlName]="
                                    node.furban3DModel.furban3DModelId
                                " />
                        </mat-form-field>
                    </fieldset>
                </li>
            </mat-tree-node>

            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild"
                                  matTreeNodePadding>
                <li>
                    <button mat-icon-button
                            matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.itemName">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{
                            suppliersTreeControl.isExpanded(node)
                            ? 'expand_more'
                            : 'chevron_right'
                            }}
                        </mat-icon>
                    </button>
                    <mat-checkbox [disabled]="isParentNodeDisabled(node)"
                                  [checked]="descendantsAllSelected(node)"
                                  [indeterminate]="descendantsPartiallySelected(node)"
                                  (click)="nodeToggleClick($event, node)"
                                  (change)="supplierNodeSelectionToggle(node)">
                        <h5>
                            {{ node.itemName | translate }}
                        </h5>
                    </mat-checkbox>
                    <ul [class.suppliers-tree-invisible]="
                            !suppliersTreeControl.isExpanded(node)
                        ">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
        </mat-tree>
    </form>
</div>