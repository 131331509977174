import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {
    Project,
    FurbanUtil,
    DocumentModel,
    FileUploadService,
    DesignProposal,
    MediaService,
} from '@furban/utilities';

@Component({
    selector: 'furban-user-location-info-popup',
    templateUrl: './user-location-info-popup.component.html',
    styleUrls: ['./user-location-info-popup.component.scss'],
})
export class UserLocationInfoPopupComponent implements OnInit {
    public currentDp: DesignProposal;
    public project: Project;
    public imageData: string;
    public getProjectTypeDescription: string;

    constructor(
        private dialogRef: MatDialogRef<UserLocationInfoPopupComponent>,
        private mediaService: MediaService,
        private fileUploadService: FileUploadService
    ) { }

    ngOnInit(): void {
        this.imageData = this.mediaService.getMedia(this.project.media);
        this.getProjectTypeDescription =
            FurbanUtil.getDesignProposalTypeDescription(
                this.currentDp,
                this.project.isCitizenDesigns
            );
    }

    public closePopup(): void {
        this.dialogRef.close();
    }

    public downloadDocument(document: DocumentModel): void {
        if(FurbanUtil.isApp()){
            return;
        }
        this.fileUploadService.openDocumentInNewTab(document.documentId);
    }
}
