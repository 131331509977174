<div class="main-container overflow">
    <section class="header-container">
        <h3 class="page-title">{{ 'admin.groups.title' | translate }}</h3>
        <div class="buttons-container">
            <ng-container *ngIf="groupsFilter.isGroupSearch">
                <button class="secondary-button" (click)="resetFilters()">
                    <h6>{{ 'admin.projectCodes.resetFilters' | translate }}</h6>
                </button>
            </ng-container>
            <ng-container *ngIf="!selection.isEmpty()">
                <button class="red-button" (click)="deleteGroupsWarning()">
                    <h6>{{ 'admin.groups.delete' | translate }}</h6>
                </button>
            </ng-container>
            <button class="primary-button" (click)="openGroupPopup()">
                <h6>{{ 'admin.groups.create' | translate }}</h6>
            </button>
        </div>
    </section>

    <ng-container *ngIf="!noExistingGroups; else noGroups">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <ng-container *ngIf="hasGroups()">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="
                            selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </ng-container>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="groupName">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="flex-row-start">
                        <p class="bold">{{ 'admin.groups.groupName' | translate }}</p>
                        <span id="name" class="material-icons-round filter-icon pointer" [class.active]="
                                checkAppliedFilter(1) || groupsFilter.isGroupSearch" (click)="showFilter(1)"
                            matTooltip="{{ 'admin.groups.filter' | translate }}">filter_alt</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">{{ row.groupName }}</td>
            </ng-container>
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef>
                    <p class="bold">{{ 'admin.groups.createdDate' | translate }}</p>
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.createdDate | date : 'mediumDate' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="numberOfCitizens">
                <th mat-header-cell *matHeaderCellDef>
                    <p class="bold">{{ 'admin.groups.noOfCitizens' | translate }}</p>
                </th>
                <td mat-cell *matCellDef="let row">{{ row.usersCount }}</td>
            </ng-container>
            <ng-container matColumnDef="numberOfExperts">
                <th mat-header-cell *matHeaderCellDef>
                    <p class="bold">{{ 'admin.groups.noOfExperts' | translate }}</p>
                </th>
                <td mat-cell *matCellDef="let row">{{ row.expertsCount }}</td>
            </ng-container>
            <ng-container matColumnDef="numberOfProjects">
                <th mat-header-cell *matHeaderCellDef>
                    <p class="bold">{{ 'admin.groups.noOfProjects' | translate }}</p>
                </th>
                <td [class.pointer]="row.projectsCount > 0" mat-cell *matCellDef="let row"
                    (click)="openProjectsPopup(row)">
                    <p style="color:var(--primary-1)"><u>{{ row.projectsCount }}</u></p>
                </td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef>
                    <p class="bold">{{ 'admin.groups.actions' | translate }}</p>
                </th>
                <td mat-cell *matCellDef="let row">
                    <div class="action-buttons">
                        <span class="material-icons pointer" matTooltip="{{ 'admin.manageGroups.editGroup' | translate }}"
                            (click)="changeGroup(row)">edit</span>
                        <span class="material-icons pointer" matTooltip="{{ 'admin.manageGroups.preview' | translate }}"
                            (click)="openCodesPopup(row)">visibility</span>
                        <ng-container *ngIf="!isApp()">
                            <span class="material-icons-round pointer"
                                matTooltip="{{ 'admin.manageGroups.export' | translate }}"
                                [matMenuTriggerFor]="menu">download
                            </span>
                            <mat-menu #menu="matMenu" overlapTrigger="false">
                                <ng-container *ngIf="isPioneer; else exportAdmin">
                                    <button mat-menu-item (click)="exportNeighborCodes(row)" value="neighborExportCsv">
                                        {{ 'admin.manageGroups.neighborCodes' | translate }}
                                    </button>
                                </ng-container>
                                <ng-template #exportAdmin>
                                    <button mat-menu-item (click)="exportCodes($event,row)" value="citizenExportCsv">
                                        {{ 'admin.manageGroups.citizenCodes' | translate }}
                                    </button>
                                    <button mat-menu-item (click)="exportCodes($event,row)" value="expertExportCsv">
                                        {{ 'admin.manageGroups.expertCodes' | translate }}
                                    </button>
                                </ng-template>
                            </mat-menu>
                        </ng-container>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </ng-container>

    <ng-template #noGroups>
        <div class="no-groups-container">
            <img src="../../../assets/images/add_user.svg" />
            <h4 class="placeholder-text">
                {{ 'admin.groups.noGroupsForClient' | translate }}
            </h4>
        </div>
    </ng-template>

    <ng-container *ngIf="hasDataToDisplay()">
        <div class="no-groups-container">
            <img src="../../../assets/images/no_data.svg" />
            <h4 class="placeholder-text">
                {{ 'admin.groups.noGroupsForFilters' | translate }}
            </h4>
        </div>
    </ng-container>

    <ng-container *ngIf="checkAppliedFilter(1)">
        <furban-table-filter [title]="'admin.groups.searchByGroup'" [buttonText]="'admin.projectCodes.search'"
            [disableAction]="!groupName.value || groupName.invalid" [filterId]="'name'" (cancelCallback)="showFilter(0)"
            (okCallback)="findByGroupName()">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>
                    {{ 'admin.groups.groupName' | translate }}
                </mat-label>
                <input matInput placeholder="{{ 'admin.groups.groupName' | translate }}" maxlength="40"
                    [formControl]="groupName" autocomplete="off" />
                <mat-error *ngIf="hasControlError(groupName,'isHTML')">
                    {{ 'comments.noHTMLContent' | translate }}
                </mat-error>
                <mat-error *ngIf="hasControlError(groupName,'whitespace')">
                    {{ 'admin.groups.whitespace' | translate }}
                </mat-error>
            </mat-form-field>
        </furban-table-filter>
    </ng-container>
</div>