import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserLocationInfoPopupComponent } from '../user-location-info-popup/user-location-info-popup.component';
import { ExpertsModalComponent } from '../experts-modal/experts-modal.component';

import {
    AuthService,
    Card,
    Client,
    FurbanUtil,
    Project,
    ProjectStorageService,
    routingConstants,
    MenuService,
    ShepherdTourService,
    TourNameEnum,
    DesignProposal,
    DesignProposalService,
} from '@furban/utilities';

import { environment } from 'apps/furban-client/src/environments/environment';
import { NextStepsComponent } from '../../../shared/next-steps/next-steps.component';
import { ProjectDetailsService } from '../../../project-shared/project-details/project-details.service';

@Component({
    selector: 'furban-app-user-dashboard',
    templateUrl: './user-dashboard.component.html',
    styleUrls: ['./user-dashboard.component.scss'],
})
export class UserDashboardComponent implements OnInit {
    public userProfileDialogRef: any;
    public isPopupClosed: boolean;
    public currentDp: DesignProposal;
    public userProjects: Project[];
    public cardConfig: Card = new Card();
    public cardNotHoverableConfig: Card = new Card();
    public whiteCardConfig: Card = new Card();
    public designCardConfig: Card = new Card();
    public client: Client;
    public curentProject: Project;
    public daysRemainingOnProject: number;
    public isVotingDateExpired = false;
    private designProposal: DesignProposal;
    constructor(
        public dialog: MatDialog,
        private router: Router,
        public authService: AuthService,
        private tourService: ShepherdTourService,
        private projectStorageService: ProjectStorageService,
        private menuService: MenuService,
        private designProposalService: DesignProposalService,
        private projectDetailsSerice: ProjectDetailsService
    ) {
        projectStorageService.projectChanged.subscribe((item) => {
            this.curentProject = item;
            this.isVotingDateExpired = FurbanUtil.isDateExpiredForIOS(
                this.curentProject.votingExpiringDate
            );
            this.resetObjects();
            this.daysRemainingOnProject = this.calculateDays();
        });
    }

    ngOnInit() {
        this.initialiseDropdown();
    }

    private initialiseDropdown(): void {
        if (this.projectDetailsSerice.allProjects) {
            this.initializeProjects();
            return;
        }

        this.projectDetailsSerice.getProjects().subscribe(
            () => {
                this.initializeProjects();
            }
        );
    }

    private initializeProjects(): void {
        const projects = this.projectDetailsSerice.allProjects;
        this.curentProject =
            projects.length === 0
                ? null
                : projects[0];

        this.configCards();
        this.daysRemainingOnProject = this.calculateDays();
        this.getCollaboratorDesignProposal();
    }

    public get isCitizenOrCollaborator(): boolean {
        return (
            this.authService.isCitizen() || this.authService.isCollaborator()
        );
    }

    public startTour(): void {
        if (this.authService.client) {
            this.client = this.authService.client;
            this.checkForTour();
        } else {
            this.authService.getClient().subscribe((data) => {
                this.client = data;
                this.checkForTour();
            });
        }
    }

    public configCards(): void {
        this.cardConfig.cardType = 'colored';
        this.cardConfig.contentAlignmentVertical = 'center';
        this.cardConfig.contentAlignmentHorizontal = 'center';
        this.cardConfig.background = '#fff';
        this.cardConfig.headerBackground = '#fff';
        this.cardConfig.hoverable = true;

        this.whiteCardConfig.cardType = 'white';
        this.whiteCardConfig.contentAlignmentVertical = 'center';
        this.whiteCardConfig.contentAlignmentHorizontal = 'center';
        this.whiteCardConfig.hoverable = true;

        this.cardNotHoverableConfig.cardType = 'colored';
        this.cardNotHoverableConfig.contentAlignmentVertical = 'center';
        this.cardNotHoverableConfig.contentAlignmentHorizontal = 'center';
        this.cardNotHoverableConfig.background = '#fff';
        this.cardNotHoverableConfig.headerBackground = '#fff';
        this.cardNotHoverableConfig.hoverable = false;
    }

    public setCurrentDesignProposal(currentDp: DesignProposal): void {
        this.currentDp = currentDp;
        if (this.authService.isFirstTimeLogin) {
            this.openProjectInfoPopup();
            return;
        }
        this.startTour();
    }

    public getUserPhoto(mediaId): string {
        return this.authService.userProfile.mediaId
            ? `${environment.apiPath}/media/public/${mediaId}`
            : '../../../assets/images/img_avatar.png';
    }

    public checkForTour(): void {
        const hasTour = localStorage.getItem('has-dashboard-tour');
        if (
            this.authService.isCitizen() &&
            hasTour &&
            hasTour === 'false' &&
            this.curentProject
        ) {
            this.tourService.startTour(
                TourNameEnum.dashboardTour,
                this.curentProject.allowCitizenDesigns,
                true
            );
        }
    }

    public openProject(): void {
        if (!this.curentProject || this.curentProject.ended) {
            return;
        }

        if (this.authService.isCollaborator()) {
            this.router.navigate([
                routingConstants.collaborative,
                this.designProposal.designProposalId,
            ]);
            return;
        }

        this.router.navigate([
            routingConstants.project,
            this.curentProject.projectId,
        ]);
    }

    public transformImage(backgroundImage: string): string {
        return "url('" + backgroundImage + "')";
    }

    public openProjectInfoPopup(): void {
        this.userProfileDialogRef = this.dialog.open(
            UserLocationInfoPopupComponent,
            {
                width: '600px',
            }
        );
        this.userProfileDialogRef.disableClose = true;
        this.userProfileDialogRef.componentInstance.project =
            this.curentProject;
        this.userProfileDialogRef.componentInstance.currentDp = this.currentDp;
        this.userProfileDialogRef.afterClosed().subscribe(() => {
            if (this.authService.isFirstTimeLogin) {
                this.startTour();
                this.authService.isFirstTimeLogin = false;
            }
        });
    }

    public openExpertsPopup(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.minWidth = '500px';
        dialogConfig.width = '40%';
        this.dialog.open(ExpertsModalComponent, dialogConfig);
    }

    public openWinnersPopup(): void {
        if (!this.curentProject || !this.curentProject.ended) {
            return;
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.minWidth = '500px';
        dialogConfig.width = '40%';
        dialogConfig.data = {
            project: this.curentProject,
        };
        this.dialog.open(NextStepsComponent, dialogConfig);
    }

    public openDesignProposals(): void {
        this.router.navigate([routingConstants.designs, this.curentProject?.projectId]);
    }

    public calculateDays(): number {
        return this.curentProject
            ? FurbanUtil.calculateDays(this.curentProject.endDate)
            : 0;
    }

    public checkForWinnerPopup(): void {
        if (this.curentProject.ended) {
            this.openWinnersPopup();
        }
    }

    public computeDayOrDays(): 'user.dashboard.day' | 'user.dashboard.days' {
        return this.daysRemainingOnProject === 1
            ? 'user.dashboard.day'
            : 'user.dashboard.days';
    }

    public computeRemainedText():
        | 'user.dashboard.remained'
        | 'user.dashboard.remainedToVote' {
        return this.curentProject.allowCitizenDesigns
            ? 'user.dashboard.remained'
            : 'user.dashboard.remainedToVote';
    }

    public navigateToDP(): void {
        this.router.navigate([routingConstants.designs, this.curentProject?.projectId]);
    }

    public get nextStepMessageForEndedProjectTranslation(): string {
        return this.curentProject.isCitizenDesigns
            ? 'winner.nextStepsCollaborative'
            : this.isVotingDateExpired
                ? 'winner.nextStepsAfterVotingExpired'
                : 'winner.nextStepsBeforeVotingExpired';
    }

    private resetObjects(): void {
        this.menuService.availableObjects = [];
    }

    private getCollaboratorDesignProposal(): DesignProposal | void {
        if (!this.authService.isCollaborator()) {
            return;
        }

        this.designProposalService
            .getCollaborativeDesignProposal()
            .subscribe((data) => {
                if (!data) {
                    return;
                }

                this.designProposal = data;
            });
    }
}
