import { Furban3DModel } from '../_models/furban-3d-model';
import { ObjectTypeEnum } from '../_enum/object-type-enum';

export class ObjectUtil {
    public static isCustomObject(furban3DModel: Furban3DModel): boolean {
        return furban3DModel.category.categoryId === 2;
    }

    public static isGroundObject(clientObjectId: number): boolean {
        return clientObjectId >= 1001 && clientObjectId <= 1008;
    }

    public static isGroundOrCustomObject(name: string) {
        return (
            name === ObjectTypeEnum.square ||
            name === ObjectTypeEnum.elipse ||
            name === ObjectTypeEnum.freeshape ||
            name === ObjectTypeEnum.custom
        );
    }

    public static isSquareOrElipse = (objectType: ObjectTypeEnum) =>
        objectType === ObjectTypeEnum.square ||
        objectType === ObjectTypeEnum.elipse;

    public static isCustom = (objectType: ObjectTypeEnum) =>
        objectType === ObjectTypeEnum.custom;

    public static isSquareOrElipseOrCustom = (objectType: ObjectTypeEnum) =>
        ObjectUtil.isSquareOrElipse(objectType) ||
        ObjectUtil.isCustom(objectType);

    public static isCustomHouse(objectId: number): boolean {
        return objectId === 8001;
    }
}
