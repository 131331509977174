<ng-container *ngIf="project">
    <main>
        <section class="project-image-container">
            <img class="project-image" [src]="getMedia(this.project.media)" alt="ProjPic" />
            <div class="padding-8-12 project-name">
                <span class="material-icons pointer" (click)="goBack()" [style.margin-right.px]="12">arrow_back</span>
                <div class="full-width">
                    <p>
                        {{ project.name }}
                    </p>
                    <p>
                        {{ project.startDate | date : 'dd MMMM yyyy' }} -
                        {{ project.endDate | date : 'dd MMMM yyyy' }}
                    </p>
                    <p>{{ project.description }}</p>
                </div>
            </div>
        </section>
        <section class="project-section flex-column-center">
            <img [style.width.px]="150" src="../../../assets/images/project-dashboard-cards-images/proj-area.png" />
            <p>
                {{
                'pioneer.permitProcessDashboard.noHouseFloorPlan1st'
                | translate
                }}
            </p>
            <p>
                {{
                'pioneer.permitProcessDashboard.noHouseFloorPlan2nd'
                | translate
                }}
            </p>
        </section>

        <section class="project-section flex-column-center">
            <img [style.width.px]="150" src="../../../assets/images/homepage/looking_permit.png" />
            <p>
                {{
                'pioneer.permitProcessDashboard.noHouseDesign1st'
                | translate
                }}
            </p>
            <p>
                {{
                'pioneer.permitProcessDashboard.noHouseFloorPlan2nd'
                | translate
                }}
            </p>
        </section>

        <section class="header-container">
            <h2>
                {{ 'pioneer.permitProcessDashboard.permitProcess' | translate }}
            </h2>

            <span class="material-icons pointer" [matMenuTriggerFor]="menu"
                #clickMenuTrigger="matMenuTrigger">more_vert</span>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="showRemoveProjectWarning()">
                    <h6>{{ 'admin.projectActivate.removeBtn' | translate }}</h6>
                </button>
            </mat-menu>
        </section>

        <div class="steps-container">
            <section *ngFor="let step of steps" class="process-step flex-row-spaced"
                [class.pointer]="!step.isUntouched()" (click)="goToStep(step)">
                <div class="flex-row-start" [class.completed-status]="step.isCompleted()"
                    [class.in-progress-status]="step.isInProgress()" [class.untouched-status]="step.isUntouched()"
                    [class.vertical-line]="step.id < 4">
                    <span class="index-container flex-row-center">
                        {{ step.id }}
                    </span>
                    <p>
                        {{ step.title | translate }}
                    </p>
                </div>
                <mat-icon *ngIf="step.isCompleted()"> create </mat-icon>
                <ng-container *ngIf="step.isInProgress()">
                    <p class="italic-text">
                        {{ 'generic.continueSetupButtonText' | translate }}
                    </p>
                </ng-container>
            </section>

            <ng-container *ngIf="!hasAllStepsCompleted">
                <h6 class="info-message">
                    {{
                    'pioneer.permitProcessDashboard.finishProjectSetup'
                    | translate
                    }}
                </h6>
            </ng-container>

            <h2 [style.margin-bottom.px]="32">
                {{
                'pioneer.permitProcessDashboard.validatePermit' | translate
                }}
            </h2>

            <mat-accordion>
                <mat-expansion-panel [disabled]="!hasAllStepsCompleted" [expanded]="shouldExpandMunicipalityMenu">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{
                            'pioneer.permitProcessDashboard.involveMunicipality'
                            | translate
                            }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <section class="flex-grow-1">
                        <ng-container *ngIf="
                                project.municipalityInvolved;
                                else involveMunicipalitySection
                            ">
                            <span>
                                <h5>
                                    {{
                                    'pioneer.permitInvolvement.' +
                                    municipalityPermitStatus?.statusLabel
                                    | translate
                                    }}
                                </h5>
                            </span>
                            <p class="padding-12-0">
                                {{
                                'pioneer.permitInvolvement.statusMessages.' +
                                municipalityPermitStatus?.statusLabel
                                | translate
                                }}
                            </p>

                            <button class="secondary-button">
                                <h6>
                                    {{
                                    'pioneer.permitInvolvement.viewDiscussion'
                                    | translate
                                    }}
                                </h6>
                            </button>
                        </ng-container>
                        <ng-template #involveMunicipalitySection>
                            <p class="padding-12-0">
                                {{
                                'pioneer.permitInvolvement.involveMunicipalityText'
                                | translate
                                }}
                            </p>

                            <button class="primary-button" (click)="involveMunicipality()">
                                <h6>
                                    {{
                                    'pioneer.permitInvolvement.involveMunicipalityBtn'
                                    | translate
                                    }}
                                </h6>
                            </button>
                        </ng-template>
                    </section>
                </mat-expansion-panel>

                <mat-expansion-panel [disabled]="!hasAllStepsCompleted" [expanded]="shouldExpandNeighborMenu">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{
                            'pioneer.permitProcessDashboard.involveNeighbors'
                            | translate
                            }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <section class="flex-grow-1">
                        <ng-container *ngIf="isNeighborInvolved; else neighborNotInvolved">
                            <p class="padding-12-0">
                                {{
                                'pioneer.permitInvolvement.neighborsStatus'
                                | translate
                                : {
                                neighborsApproved:
                                reviewCounts?.approveCount,
                                neighborsRejected:
                                reviewCounts?.rejectCount
                                }
                                }}
                            </p>
                            <div class="flex-row-start">
                                <button class="primary-button" [style.margin-right.px]="12" (click)="openCodesStatus()">
                                    <h6>
                                        {{
                                        'pioneer.permitInvolvement.codesStatus'
                                        | translate
                                        }}
                                    </h6>
                                </button>
                                <button class="secondary-button">
                                    <h6>
                                        {{
                                        'pioneer.permitInvolvement.viewDiscussion'
                                        | translate
                                        }}
                                    </h6>
                                </button>
                            </div>
                        </ng-container>
                        <ng-template #neighborNotInvolved>
                            <p class="padding-12-0">
                                {{
                                'pioneer.permitInvolvement.involveNeighborText'
                                | translate
                                }}
                            </p>

                            <button class="primary-button" (click)="openCodesStatus()">
                                <h6>
                                    {{
                                    'pioneer.permitInvolvement.involveNeighborBtn'
                                    | translate
                                    }}
                                </h6>
                            </button>
                        </ng-template>
                    </section>
                </mat-expansion-panel>

                <mat-expansion-panel [disabled]="!hasAllStepsCompleted">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{
                            'pioneer.permitProcessDashboard.finishProcess'
                            | translate
                            }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <section>
                        <ng-container *ngIf="
                                !project.publicLinkGenerated;
                                else linkGenerated
                            ">
                            <button class="primary-button" (click)="generateLink()">
                                <h6>
                                    {{
                                    'pioneer.permitInvolvement.generateLink'
                                    | translate
                                    }}
                                </h6>
                            </button>
                        </ng-container>

                        <ng-template #linkGenerated>
                            <section class="flex-row-start">
                                <h6>
                                    {{ publicUrl }}
                                </h6>

                                <span class="material-icons pointer" [style.margin-left.px]="4" (click)="copyLink()">
                                    content_copy
                                </span>
                            </section>
                        </ng-template>
                    </section>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </main>
</ng-container>