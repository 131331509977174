import { Component } from '@angular/core';
import { ConversationComment } from '../_models/conversation-comment';
import { DesignProposal } from '../_models/design-proposal';

@Component({
    selector: 'furban-conversation',
    templateUrl: './conversation.component.html',
    styleUrls: ['./conversation.component.scss'],
})
export class ConversationComponent {
    public ownId = 'id1';
    public dp: DesignProposal;

    public conversationComments: ConversationComment[] = [
        new ConversationComment(
            'Jane Cooper',
            'February 10, 2022',
            '8:25 PM',
            'Twenty 30-second applications within half an hour is well in excess of almost anyone’s use of a sanitizer.',
            ['id1', 'id2'],
            ['id1', 'id2'],
            ['id1']
        ),
        new ConversationComment(
            'Theresa Webb',
            'February 10, 2022',
            '8:25 PM',
            'Articles all around CSS, JavaScript, front-end, UX and design. For designers and web developers.',
            ['id5', 'id2', 'id3'],
            [],
            ['id1']
        ),
        new ConversationComment(
            'Leslie Alexander',
            'February 10, 2022',
            '8:25 PM',
            'Articles all around CSS, JavaScript, front-end, UX and design. For designers and web developers.',
            ['id1', 'id2', 'id3'],
            ['id1', 'id2'],
            []
        ),
        new ConversationComment(
            'Robert Fox',
            'February 10, 2022',
            '10:25 PM',
            'Twenty 30-second applications within half an hour is well in excess of almost anyone’s use of a sanitizer.',
            ['id1', 'id2', 'id3'],
            ['id1', 'id2', 'id3', 'id4'],
            ['id1', 'id2']
        ),
    ];

    public hasAttachments(comm: ConversationComment): boolean {
        return comm.attachments?.length > 0;
    }

    public hasLikes(comm: ConversationComment): boolean {
        return comm.likes?.length > 0;
    }

    public ownLiked(comm: ConversationComment): boolean {
        return comm.likes?.indexOf(this.ownId) > -1;
    }
}
