import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserVerificationEmail } from '../_models/user-verification-email';
import { map } from 'rxjs/operators';
import { APP_CONFIG } from '@furban/app-config';
import { Environment } from '../_models/environment-model';

@Injectable({
    providedIn: 'root',
})
export class UserVerificationEmailService {
    private _sentUserVerificationEmailId: string;
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        private http: HttpClient
    ) { }

    public get sentUserVerificationEmailId(): string {
        return this._sentUserVerificationEmailId;
    }

    public set sentUserVerificationEmailId(value: string) {
        this._sentUserVerificationEmailId = value;
    }

    public resendValidationEmail(): Observable<void> {
        return this.http.put<void>(
            `${this.environment.apiPath}/user-verification/resend/${this.sentUserVerificationEmailId}`,
            { headers: this._headers }
        );
    }

    public getUserVerificationEmailById(
        userVerificationEmailId: string
    ): Observable<UserVerificationEmail> {
        return this.http
            .get(
                `${this.environment.apiPath}/user-verification/${userVerificationEmailId}`,
                { headers: this._headers }
            )
            .pipe(map((result) => result as UserVerificationEmail));
    }

    public markEmailAsConfirmedForUser(
        userVerificationEmailId: string
    ): Observable<void> {
        return this.http.put<void>(
            `${this.environment.apiPath}/user-verification/confirm/${userVerificationEmailId}`,
            { headers: this._headers }
        );
    }
}
