import { Tutorial } from '../_models/tutorial';
import { UserRoleEnum } from '../_enum/user-roles.enum';

export class TutorialsArray {
    public static getTutorials(userRole: string): Tutorial[] {
        const tutorialsArray = [];
        for (let i = 1; i <= 5; i++) {
            tutorialsArray.push(
                new Tutorial(
                    `tutorial${i}`,
                    `navigation.tutorials.tutorial${i}.title`,
                    `navigation.tutorials.tutorial${i}.description`,
                    `../../../assets/images/tutorials/tutorial_${i}.gif`,
                    `../../../assets/images/tutorials/tutorial_${i}.png`
                ) //remove *0 +1 when thre will be more tutorial files added in assets
            );
        }
        if (userRole === UserRoleEnum.pioneer) {
            tutorialsArray.splice(0, 1);
            tutorialsArray.splice(1, 1);
        }
        if (userRole === UserRoleEnum.collaborator) {
            tutorialsArray.splice(2, 1);
        }
        return tutorialsArray;
    }
}
