<div class="full-width full-height flex-row-center">
    <div
        class="new-municipality-container flex-column-center"
        *ngIf="isSuperAdmin"
    >
        <h4>Create new municipality</h4>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label> Municipality name </mat-label>
            <input
                maxlength="110"
                required
                matInput
                placeholder="Municipality name"
                [formControl]="nameFormControl"
            />
            <mat-error *ngIf="nameFormControl.hasError('required')">
                Municipality name is <strong>required</strong>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label> Municipality address </mat-label>
            <input
                maxlength="256"
                required
                matInput
                placeholder="Municipality address"
                [formControl]="addressFormControl"
            />
            <mat-error *ngIf="addressFormControl.hasError('required')">
                Municipality address is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width field-pass" appearance="outline">
            <mat-label> Phone number </mat-label>
            <input
                maxlength="128"
                required
                matInput
                placeholder="Phone number"
                [formControl]="phoneFormControl"
            />
            <mat-error *ngIf="phoneFormControl.hasError('required')">
                Phone number is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <button class="primary-button" (click)="addNewMunicipality()">
            <h6>Add new municipality</h6>
        </button>
    </div>
</div>
