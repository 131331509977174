import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@furban/utilities';
import { environment } from 'apps/furban-client/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermitReview } from '../_models/permit-review';

@Injectable({
    providedIn: 'root',
})
export class NeighbourService {
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(private http: HttpClient) {}

    public generateNeighborCodes(
        noOfCodes: number,
        groupId: string,
        projectId: string,
        emails?: string[]
    ): Observable<PermitReview[]> {
        const generateNeighbours = {
            neighborCount: noOfCodes,
            groupId: groupId,
        };

        return this.http
            .post(
                `${environment.apiPath}/neighbours/${projectId}`,
                generateNeighbours,
                { headers: this._headers }
            )
            .pipe(map((user) => user as PermitReview[]));
    }

    public inviteByEmail(
        emailList: string[],
        groupId: string,
        projectId: string
    ): Observable<PermitReview[]> {
        const generateNeighboursByEmailDto = {
            groupId: groupId,
            emailsList: emailList,
        };

        return this.http
            .post<PermitReview[]>(
                `${environment.apiPath}/neighbours/${projectId}/invite`,
                generateNeighboursByEmailDto,
                { headers: this._headers }
            )
            .pipe(map((user) => user as PermitReview[]));
    }
}
