export class DesignProposalReaction {
    public designProposalsReactionId?: string;
    public userProfileId: string;
    public designProposalId: string;
    public projectId: string;
    public reactionId: number;

    constructor(
        reactionId: number,
        userProfileId: string,
        projectId: string,
        designProposalId: string,
        designProposalsReactionId?: string
    ) {
        this.reactionId = reactionId;
        this.userProfileId = userProfileId;
        this.projectId = projectId;
        this.designProposalId = designProposalId;
        this.designProposalsReactionId = designProposalsReactionId;
    }
}
