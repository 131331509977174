import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TourEvent } from '../_enum/tour-event.enum';

export class DoneButton {
    public static createButton(
        translateServiceInstance: TranslateService,
        tourStepChangedEmitter?: EventEmitter<string>
    ) {
        return {
            action() {
                if (tourStepChangedEmitter) {
                    tourStepChangedEmitter.emit(TourEvent.endEvent);
                }
                return this.complete();
            },
            text: translateServiceInstance.instant(
                'uiTour.dashboard.doneButtonText'
            ),
            classes: 'tour-square-button margin-left-20',
        };
    }
}
export const NextButton = {
    action() {
        return this.next();
    },
    text: '<span class="material-icons">arrow_forward</span>',
    classes: 'tour-round-button margin-left-20',
};

export const PreviousButton = {
    action() {
        return this.back();
    },
    classes: 'tour-round-button',
    text: '<span class="material-icons">arrow_back</span>',
};
