import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FurbanUtil } from '@furban/utilities';
import { environment } from 'apps/furban-client/src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AddingCodesService {
    private _options = {
        headers: new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
        ),
    };
    private _headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    constructor(
        private translateService: TranslateService,
        private http: HttpClient
    ) {}

    assignUserToGroup(username: string, groupId: string) {
        const paramObject: any = {
            username: username,
            groupId: groupId,
        };

        return this.http
            .post(
                environment.apiPath + '/user/assign-group',
                FurbanUtil.encodeObjectURIComponents(paramObject),
                this._options
            )
            .pipe(map((result) => result));
    }
}
