<div class="remove-events"
     id="htmlRenderer"></div>

<canvas id="canvasPermit3D"
        class="gradient-background"
        #canvasPermit></canvas>
<furban-permit-controls-menu class="flex-row-center"
                             #controlsMenu
                             [menuSettings]="menuSettings"
                             [assetType]="assetType"
                             [isMultiselect]="isMultiselect"
                             (controlAction)="onControlSelected($event)">
</furban-permit-controls-menu>

<ng-container *ngIf="!shouldDisableActions && !deactivateControls">
    <permit-attach-events furbanAssetAttachEvents
                          [houseSceneConfig]="houseSceneConfig">
    </permit-attach-events>

    <permit-select-events furbanAssetSelectEvents
                          [houseSceneConfig]="houseSceneConfig"
                          (updateColorAndMaterial)="initializeRoofAssetConfigurationModal($event)">
    </permit-select-events>

    <permit-draw-line-events furbanPlaceObjectsInLine
                             [houseSceneConfig]="houseSceneConfig"
                             (addRowOfObjectsEvent)="addRowOfObjects($event)">
    </permit-draw-line-events>
</ng-container>

<div id="color-texture-popup-html"
     class="flex-row-start">
    <mat-form-field *ngIf="checkIfRoofMaterials()"
                    appearance="outline">
        <mat-label>{{ 'pioneer.permitProcess.roofTexture' | translate }}
        </mat-label>
        <div class="color-container">
            <mat-select disableOptionCentering
                        class="select-container"
                        panelClass="texture-select-dropdown"
                        [style.background]="getURLForMaterials(selectedRoofMaterial)"
                        [compareWith]="objectComparisonFunction"
                        (selectionChange)="roofMaterialUpdated()"
                        [(value)]="selectedRoofMaterial">
                <mat-option class="texture-select"
                            [value]="roofMaterial"
                            *ngFor="let roofMaterial of roofMaterials">
                    <img alt="objectPicture"
                         [src]="roofMaterial.materialIcon" />
                    <p>{{ roofMaterial.materialName | translate }}</p>
                </mat-option>
            </mat-select>
        </div>
    </mat-form-field>

    <mat-form-field class="flex-row-start"
                    [style.margin-left.px]="12"
                    appearance="outline">
        <mat-label>{{ 'pioneer.permitProcess.roofColor' | translate }}
        </mat-label>
        <div class="color-container">
            <input type="color"
                   [formControl]="roofAssetColorControl"
                   matInput />
        </div>
    </mat-form-field>
</div>

<ng-container *ngIf="shouldAddPermitDirectives()">
    <permit-add-pin-events furbanAddPinCommentEvents
                           [houseSceneConfig]="houseSceneConfig"
                           [permitProjectId]="house.projectId"
                           (updatePinCommentToDisplay)="updatePinCommentToDisplay($event)">
    </permit-add-pin-events>

    <ng-container *ngIf="houseSceneConfig?.pinHelper">
        <permit-select-pin furbanSelectPinComments
                           [houseSceneConfig]="houseSceneConfig"
                           [permitProjectId]="house.projectId"
                           (updatePinCommentToDisplay)="updatePinCommentToDisplay($event)">
        </permit-select-pin>
    </ng-container>

    <div id="pinPopupHTML"
         class="flex-column-center">
        <furban-three-comment-popup #commentPopup
                                    [permitProjectId]="house.projectId"
                                    (closePopUp)="onPinPopupClose($event)"
                                    (savedComment)="onPinCommentSaved($event)">
        </furban-three-comment-popup>
    </div>
</ng-container>

<ng-container *ngIf="!deactivateControls">
    <div class="autosave-message">
        <span class="material-icons"
              [style.margin-right.px]="12">
            cloud_upload
        </span>
        <h3>{{ 'autoSave.message' | translate }}</h3>
    </div>
</ng-container>

<ng-container *ngIf="shouldDisplayTimeSlider">
    <div class="time-slider">
        <section class="flex-row-start">
            <ng-container *ngIf="isSunAnimationRunning; else stopAnimation">
                <button class="round-button"
                        matTooltip="{{ 'sunAnimation.stop' | translate }}"
                        [style.margin-right.px]="8"
                        (click)="stopSunAnimation()">
                    <span class="material-icons">stop</span>
                </button>
            </ng-container>

            <ng-template #stopAnimation>
                <button class="round-button"
                        matTooltip="{{ 'sunAnimation.start' | translate }}"
                        [style.margin-right.px]="8"
                        (click)="playSunAnimation()">
                    <span class="material-icons">play_arrow</span>
                </button>
            </ng-template>

            <mat-slider thumbLabel
                        tickInterval="auto"
                        step="1"
                        min="0"
                        [max]="sunShiningMinutesInterval"
                        aria-label="units"
                        [displayWith]="timeLabel.bind(this)"
                        [value]="sunSliderValue"
                        (input)="updateTimeValue($event)">
            </mat-slider>
        </section>

        <section class="slider-legend">
            <div class="flex-row-start">
                <span class="material-icons"
                      [style.margin-right.px]="4">
                    wb_sunny
                </span>
                <h3>{{ sunriseTime }}</h3>
            </div>

            <div class="flex-row-start">
                <span class="material-icons"
                      [style.margin-right.px]="4">
                    nights_stay
                </span>
                <h3>{{ sunsetTime }}</h3>
            </div>
        </section>
    </div>
</ng-container>