import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';


import {
    AuthService,
    Client,
    CustomToasterComponent,
    CustomToasterService,
    Furban3DModel,
    FurbanUtil,
    MunicipalityService,
    Supplier,
    SupplierClient,
    SupplierDefaultEnum,
} from '@furban/utilities';
import { SuperadminAddSupplierComponent } from '../superadmin-add-supplier/superadmin-add-supplier.component';
import { SuppliersService } from './suppliers.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SuperadminManageSafetyAreaComponent } from '../superadmin-manage-safety-area/superadmin-manage-safety-area.component';

@Component({
    selector: 'furban-app-superadmin-suppliers',
    templateUrl: './superadmin-suppliers.component.html',
    styleUrls: ['./superadmin-suppliers.component.scss'],
})
export class SuperadminSuppliersComponent implements OnInit {
    public suppliers: Supplier[];
    public parentViewContainerRef: ViewContainerRef;
    public municipalities: Client[];
    public filteredMunicipalities: Client[];
    public composeImageString = FurbanUtil.composeImageString;
    public selectedClientUUID: string;
    public selectedView = 'allSuppliers';
    public clientSuppliers: Supplier[];
    public mappedSuppliers: Map<Supplier, boolean> = new Map<Supplier, boolean>();
    public clientSelectFormGroup: FormGroup;

    public selectedMunicipality: Client;
    public filteredMunicipalitiesOptions: Client[];
    public subscription: Subscription;
    public panelOpenState = true;

    constructor(
        private authService: AuthService,
        private suppliersService: SuppliersService,
        private dialog: MatDialog,
        private containerRef: ViewContainerRef,
        private municipalityService: MunicipalityService,
        private customToasterService: CustomToasterService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.initializeForm();
        this.getAllSuppliers();
        this.getMunicipalities();
    }

    public isSuperadmin(): boolean {
        return this.authService.isSuperAdmin();
    }

    public onSelectClientChange(client: Client) {
        this.mappedSuppliers.clear();
        this.selectedClientUUID = client.clientUuid;
        this.getSuppliersByClientId(this.selectedClientUUID);
    }

    public onRadioChange(event: any) {
        this.mappedSuppliers.clear();
        this.selectedClientUUID = null;
        this.selectedView = event.value;
    }

    public showClientSupplierView(): boolean {
        return (
            this.selectedView === 'clientSuppliers' &&
            this.suppliers !== undefined
        );
    }

    public showAllSuppliersView(): boolean {
        return (
            this.selectedView === 'allSuppliers' && this.suppliers !== undefined
        );
    }

    public onCheckBoxChange(event: any, supplierMap: any) {
        supplierMap.value = event.checked;
        const supplierClient = new SupplierClient();
        supplierClient.clientId = this.selectedClientUUID;
        supplierClient.supplierId = supplierMap.key.supplierId;
        if (event.checked) {
            this.assignSupplierToClient(supplierClient);
        } else {
            this.deleteSupplierFromClient(supplierClient);
        }
    }

    public openAddModal(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '40%';

        const supplierDialogRef = this.dialog.open(
            SuperadminAddSupplierComponent,
            dialogConfig
        );
        supplierDialogRef.componentInstance.parentViewContainerRef =
            this.containerRef;
        supplierDialogRef.componentInstance.municipalities =
            this.municipalities;

        supplierDialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.getAllSuppliers();
            }
        });
    }

    public openSafetyAreaModal(model: Furban3DModel): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '40%';
        dialogConfig.data = {
            model: model
        };

        const supplierDialogRef = this.dialog.open(
            SuperadminManageSafetyAreaComponent,
            dialogConfig
        );
        supplierDialogRef.componentInstance.parentViewContainerRef =
            this.containerRef;

    }

    public checkIfDisabledSupplier(mapSupplier: any) {
        return (
            mapSupplier.value &&
            mapSupplier.key.supplierName === SupplierDefaultEnum.default
        );
    }

    public setObjectsForSupplier(supplier: Supplier): void {
        if (!supplier.objects) {
            this.getSupplierWithItsObjects(supplier.supplierId);
        }
    }

    public onSelectClient(value: Client): void {
        this.selectedMunicipality = value;
        this.onSelectClientChange(this.selectedMunicipality);
    }

    private initializeForm(): void {
        this.clientSelectFormGroup = this.formBuilder.group({
            clientFormControl: ['', Validators.required],
        });
    }

    private getSuppliersByClientId(clientId: string): void {
        this.suppliersService.getSuppliersById(clientId).subscribe((data) => {
            this.clientSuppliers = data;
            this.suppliers.map((item) => {
                const exists = this.clientSuppliers.some(
                    (clientSupplier) =>
                        clientSupplier.supplierId === item.supplierId
                );
                this.mappedSuppliers.set(item, exists);
            });
        });
    }

    private getMunicipalities() {
        this.municipalityService.getAllClients().subscribe((data) => {
            this.municipalities = data;
        });
    }

    private getAllSuppliers(): void {
        this.suppliersService.getSuppliers().subscribe((data) => {
            this.suppliers = data;
        });
    }

    private assignSupplierToClient(supplierClient: SupplierClient) {
        this.suppliersService
            .assignSupplierToClient([supplierClient])
            .subscribe((data) => {
                this.showToaster(
                    'check_circle',
                    'success',
                    'suppliers.assignSuccess'
                );
            });
    }

    private deleteSupplierFromClient(supplierClient: SupplierClient) {
        this.suppliersService
            .deleteSupplierFromClient([supplierClient])
            .subscribe((data) => {
                this.showToaster(
                    'check_circle',
                    'success',
                    'suppliers.unassignSuccess'
                );
            });
    }

    private getSupplierWithItsObjects(supplierId: string): void {
        this.suppliersService
            .getAllObjectsBySupplierId(supplierId)
            .subscribe((data) => {
                this.suppliers
                    .filter((supplier) => supplier.supplierId === supplierId)
                    .map((supplier: Supplier) => {
                        supplier.objects = data.filter(
                            (object) => object.objectLookId >= 2000
                        );
                    });
            });
    }

    private showToaster(icon: string, type: string, text: string): void {
        this.customToasterService.openCustomToaster(
            CustomToasterComponent,
            icon,
            type,
            text,
            2000
        );
    }
}
