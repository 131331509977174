import { Observable, Subscription } from 'rxjs';

import {
    DesignProposalLight,
    DpCitizenViewEnum,
    DpSortObject,
    FurbanUtil,
    routingConstants,
    Top3ClassesEnum,
} from '@furban/utilities';
import {
    OnInit,
    Component,
    ViewContainerRef,
    Input,
    OnChanges,
    SimpleChanges,
    OnDestroy,
} from '@angular/core';

import {
    AuthService,
    DesignProposalService,
    DesignProposalRequestParam,
    DesignProposalIsAdminFilterEnum,
    dashboardListAnimations,
    DesignProposal,
    DesignProposalEnum,
    ProjectStatusEnum,
    Project,
    CommentsPopupComponent,
    SortingObjectsArray,
} from '@furban/utilities';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, RouterEvent } from '@angular/router';
import { FormControl } from '@angular/forms';
import { WinnerPopupComponent } from '../../shared/winner-popup/winner-popup.component';
import { Location } from '@angular/common';
@Component({
    selector: 'furban-app-design-proposals',
    templateUrl: './design-proposals.component.html',
    styleUrls: ['./design-proposals.component.scss'],
    animations: [dashboardListAnimations],
})
export class DesignProposalsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() viewContainerRef: ViewContainerRef;
    @Input() project: Project;
    @Input() disabledActions = false;
    @Input() isViewerOnly?= false;
    @Input() isPublicPage = false;
    @Input() isEmbeded?: boolean;
    public isCollaborativeProject: boolean;
    public currentDPs: DesignProposal[] | DesignProposalLight[];
    public currentPage = 0;
    public totalDPs: number;
    public getColorBasedOnProposalType = FurbanUtil.getColorBasedOnProposalType;
    public getDesignProposalTypeText = FurbanUtil.getDesignProposalTypeText;
    public getPoints = FurbanUtil.getPoints;
    public currentSorting: string;
    public noOfReportsToPrevent = 3;
    public filterType: string;
    public designsTypeFilter: string;
    public loaded = false;
    public sortingObjects: DpSortObject[] = [];
    public priceFilter: FormControl;
    public designTypeFilter: FormControl;
    private designTypeFilterSubscription: Subscription;
    private priceFilterSubscription: Subscription;

    constructor(
        public authService: AuthService,
        public dialog: MatDialog,
        private router: Router,
        private designProposalService: DesignProposalService,
        private location: Location
    ) { }

    public get top3Classes(): typeof Top3ClassesEnum {
        return Top3ClassesEnum;
    }

    public get designProposalPriceFilterEnum(): typeof DesignProposalEnum {
        return DesignProposalEnum;
    }

    public get designProposalIsAdminFilterEnum(): typeof DesignProposalIsAdminFilterEnum {
        return DesignProposalIsAdminFilterEnum;
    }

    ngOnInit(): void {
        this.setTypeOfProject();
        this.setAvailableSortingOptions();
        this.initializeFormControls();
        this.initialiseDesignProposals();
        this.subscribeToFilterChange();
        this.subscribeToNavigation();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['project'].currentValue !== changes['project'].previousValue) {
            this.currentDPs = [];
            this.currentPage = 0;
            this.project = changes['project'].currentValue;
            this.loaded = false;
            this.setTypeOfProject();
            this.setAvailableSortingOptions();
            this.initializeFormControls();
            this.initialiseDesignProposals();
        }
    }

    ngOnDestroy(): void {
        this.designTypeFilterSubscription?.unsubscribe();
        this.priceFilterSubscription?.unsubscribe();
    }

    public initialiseDesignProposals(): void {
        if (this.sortingObjects.length === 0) {
            return;
        }
        this.currentSorting = this.sortingObjects[0].tag;
        this.currentPage = 0;
        this.countDesignProposals();
        this.getDesignProposals();
    }

    public openDPDetails(dp?: DesignProposal): void {
        if (!this.authService.isLoggedIn()) {
            if (this.isViewerOnly) {
                this.router.navigate([
                    routingConstants.publicDesignProposal,
                    dp.designProposalId,
                ]);
            } else if (this.isEmbeded) {
                this.router.navigate(
                    [routingConstants.publicPageDesign, dp.designProposalId],
                    { state: { showBack: true } }
                );
            } else {
                this.router.navigate([
                    routingConstants.takingPartDesign,
                    dp.designProposalId,
                ]);
            }
        } else {
            if (this.authService.isAdmin()) {
                this.router.navigate([
                    routingConstants.adminDesignProposals,
                    dp.designProposalId,
                ]);
            } else if (this.authService.isPioneer()) {
                this.router.navigate([
                    routingConstants.pioneerDesignProposals,
                    dp.designProposalId,
                ]);
            } else {
                this.router.navigate([
                    routingConstants.designProposals,
                    dp.designProposalId,
                ]);
            }
        }
    }

    public get availableSortingOptions(): DpSortObject[] {
        return this.sortingObjects.filter((sortObject) =>
            this.checkIfSortingNotAvailableWhenLoggedOut(sortObject)
        );
    }

    public revote(): void {
        this.designProposalService.citizenView = DpCitizenViewEnum.vote;
        this.designProposalService.resetDPFiltersForProject();
    }

    public hasOnlyOneSortingType(): boolean {
        return this.sortingObjects.length <= 1;
    }

    public isAdmin(): boolean {
        return this.authService.hasAdministrativeRole();
    }

    public isUser(): boolean {
        return this.authService.isCitizen() || this.authService.isExpert();
    }

    public hasFilterByDesignType(): boolean {
        return (
            !this.project.isCitizenDesigns && this.project.allowCitizenDesigns
        );
    }

    public goBack(): void {
        this.location.back();
    }

    private setTypeOfProject(): void {
        this.isCollaborativeProject = this.project?.isCitizenDesigns;
    }

    private initializeFormControls(): void {
        this.priceFilter = new FormControl(
            this.designProposalService.selectedPriceValues
        );
        this.designTypeFilter = new FormControl(
            this.designProposalService.selectedDPTypeValues
        );
        this.setIsAdminFilter();
        this.setPriceFilter();
    }

    private subscribeToFilterChange(): void {
        this.designTypeFilterSubscription =
            this.designTypeFilter.valueChanges.subscribe((val) => {
                this.designProposalService.selectedDPTypeValues = val;
                this.setIsAdminFilter();
                this.initialiseDesignProposals();
            });

        this.priceFilterSubscription = this.priceFilter.valueChanges.subscribe(
            (val) => {
                this.designProposalService.selectedPriceValues = val;
                this.setPriceFilter();
                this.initialiseDesignProposals();
            }
        );
    }

    private getCountRequest(): Observable<number> {
        switch (this.designsTypeFilter) {
            case DesignProposalIsAdminFilterEnum.allDesigns:
                return this.designProposalService.getDesignProposalCount(
                    this.project.projectId
                );
            case DesignProposalIsAdminFilterEnum.citizenDesigns:
                return this.designProposalService.getCitizenDesignProposalCount(
                    this.project.projectId
                );
            case DesignProposalIsAdminFilterEnum.adminDesigns:
                return this.designProposalService.getMunicipalityDesignProposalCount(
                    this.project.projectId
                );
            default:
                console.error('Unknown method on map');
                break;
        }
    }

    private getCorrespoindingRequest(): Observable<
        DesignProposal[] | DesignProposalLight[]
    > {
        const dpRequestParam = new DesignProposalRequestParam(
            this.project.projectId,
            this.currentPage,
            this.currentSorting,
            this.filterType
        );

        dpRequestParam.isAdminDesignFilter = this.designsTypeFilter;

        return this.authService.isLoggedIn()
            ? this.designProposalService.getDesignProposals(dpRequestParam)
            : this.designProposalService.getPublicDesignProposals(
                dpRequestParam
            );
    }

    public onParentScrollDown() {
        if (
            this.currentDPs &&
            this.currentDPs.length > 0 &&
            this.currentDPs.length < this.totalDPs
        ) {
            this.currentPage = this.currentPage + 1;

            const request = this.getCorrespoindingRequest();

            request.subscribe((data) => {
                this.currentDPs = (this.currentDPs as any).concat(data);
            });
        }
    }

    public shouldDisplaySortingOptions(): boolean {
        return (
            this.loaded &&
            this.currentDPs &&
            this.currentDPs.length &&
            this.availableSortingOptions.length > 1
        );
    }

    public openCommentDialog(currentDp: DesignProposal): void {
        const userProfileDialogRef = this.dialog.open(CommentsPopupComponent, {
            width: '40%',
            data: {
                designProposal: currentDp,
                project: this.project,
            },
        });

        userProfileDialogRef.disableClose = true;
        userProfileDialogRef.componentInstance.parentViewContainerRef =
            this.viewContainerRef;
        userProfileDialogRef.afterClosed().subscribe((data) => {
            this.getDesignProposals();
        });
    }

    public openWinnerPopup(dp: DesignProposal) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '40%';
        dialogConfig.data = {
            designProposal: dp,
        };
        const winnerDialog = this.dialog.open(
            WinnerPopupComponent,
            dialogConfig
        );
        winnerDialog.componentInstance.parentViewContainerRef =
            this.viewContainerRef;

        winnerDialog.afterClosed().subscribe((data) => {
            if (data) {
                this.designProposalService
                    .saveDesignProposalAsWinner(data)
                    .subscribe(() => {
                        this.getDesignProposals();
                    });
            }
        });
    }

    public setSorting(type: string): void {
        this.currentPage = 0;
        this.currentSorting = type;
        this.countDesignProposals();
        this.getDesignProposals();
    }

    public checkIfIsAdmin(): boolean {
        return this.authService.isAdmin();
    }

    public checkIfVotingIsExpiredOrProjectArchived(): boolean {
        const isVotingExpired = FurbanUtil.isDateExpiredForIOS(
            this.project.votingExpiringDate
        );
        return (
            isVotingExpired &&
            this.project.projectStatus.statusWeight !==
            ProjectStatusEnum.archived
        );
    }

    public checkIfSortingNotAvailableWhenLoggedOut(
        sortObject: DpSortObject
    ): boolean {
        return (
            sortObject.title !== 'user.dashboard.mostLiked' ||
            this.authService.isLoggedIn()
        );
    }

    public showNoDesigns(): boolean {
        return (
            this.loaded &&
            (!this.currentDPs ||
                (this.currentDPs && this.currentDPs.length === 0))
        );
    }

    private setPriceFilter(): void {
        if (this.designProposalService.selectedPriceValues.length === 2) {
            this.filterType = DesignProposalEnum.allDesignProposals;
        } else if (
            this.designProposalService.selectedPriceValues.length === 1
        ) {
            this.filterType = this.designProposalService.selectedPriceValues[0];
        } else {
            this.filterType = DesignProposalEnum.none;
        }
    }

    private setIsAdminFilter(): void {
        if (this.designProposalService.selectedDPTypeValues.length === 2) {
            this.designsTypeFilter = DesignProposalIsAdminFilterEnum.allDesigns;
        } else if (
            this.designProposalService.selectedDPTypeValues.length === 1
        ) {
            this.designsTypeFilter =
                this.designProposalService.selectedDPTypeValues[0];
        } else {
            this.designsTypeFilter = DesignProposalIsAdminFilterEnum.none;
        }
    }

    private subscribeToNavigation(): void {
        this.router.events.subscribe((event: RouterEvent) => {
            this.designProposalService.checkIfShouldReset(event);
        });
    }

    private countDesignProposals(): void {
        if (
            this.designsTypeFilter === DesignProposalIsAdminFilterEnum.none ||
            this.filterType === DesignProposalEnum.none
        ) {
            this.totalDPs = 0;
            return;
        }

        const countDpRequest = this.getCountRequest();
        countDpRequest.subscribe((data) => {
            this.totalDPs = data;
        });
    }

    private setAvailableSortingOptions(): void {
        this.sortingObjects = SortingObjectsArray.getSortingObjects(
            this.isCollaborativeProject
        );
        this.currentSorting = this.sortingObjects[0]?.tag;
    }

    private getDesignProposals(): void {
        if (
            this.designsTypeFilter === DesignProposalIsAdminFilterEnum.none ||
            this.filterType === DesignProposalEnum.none
        ) {
            this.currentDPs = [];
            this.loaded = true;
            return;
        }

        const request = this.getCorrespoindingRequest();
        request.subscribe((data) => {
            this.currentDPs = data;
            this.loaded = true;
        });
    }
}
