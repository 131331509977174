import { EventEmitter } from '@angular/core';
import {
    RIGHT_MENU_TOUR_ITEMS,
    OBJECTS_MENU_TOUR_ITEMS,
    LEFT_TOOLING_TOUR_ITEMS,
    LEFT_TOOLING_TOUR_ITEMS_MOBILE,
} from './design-tour-items';
import { TranslateService } from '@ngx-translate/core';
import { TourEvent } from '../_enum/tour-event.enum';
import { AuthService } from '../_services/auth.service';
import { DoneButton, NextButton, PreviousButton } from './tour-buttons';
import { TourUtils } from '../helpers/tour-utils';
import { Project } from '../_models/project';

export class DashboardTourSteps {
    public static createSteps(
        authService: AuthService,
        translateServiceInstance: TranslateService,
        windowWidth?: number,
        existingProjectInLocalStorage?: boolean,
        allowCitizensDesign: boolean = false
    ) {
        if (authService.isPioneer()) {
            return [
                {
                    attachTo: {
                        element: '#welcome-step',
                        on: 'bottom',
                    },
                    buttons: [NextButton],
                    title: translateServiceInstance.instant(
                        'uiTour.dashboard.welcomMessageTitle'
                    ),
                    text: translateServiceInstance.instant(
                        'uiTour.dashboard.welcomMessage'
                    ),
                },
                {
                    attachTo: {
                        element: '#profile-step',
                        on: 'bottom',
                    },
                    buttons: [PreviousButton, NextButton],
                    title: translateServiceInstance.instant(
                        'uiTour.dashboard.profileTitle'
                    ),
                    text: translateServiceInstance.instant(
                        'uiTour.dashboard.profile'
                    ),
                },
                {
                    attachTo: {
                        element: '#initiative-card-step',
                        on: 'bottom',
                    },
                    buttons: [PreviousButton, NextButton],
                    title: translateServiceInstance.instant(
                        'uiTour.dashboard.createInitiativeProjectTitle'
                    ),
                    text: translateServiceInstance.instant(
                        'uiTour.dashboard.createInitiativeProject'
                    ),
                },
                {
                    attachTo: {
                        element: '#permit-card-step',
                        on: 'bottom',
                    },
                    buttons: [PreviousButton, NextButton],
                    title: translateServiceInstance.instant(
                        'uiTour.dashboard.createPermitProjectTitle'
                    ),
                    text: translateServiceInstance.instant(
                        'uiTour.dashboard.createPermitProject'
                    ),
                },
                {
                    attachTo: {
                        element: '#projects-card-step',
                        on: 'bottom',
                    },
                    buttons: [
                        PreviousButton,
                        DoneButton.createButton(translateServiceInstance),
                    ],
                    title: translateServiceInstance.instant(
                        'uiTour.dashboard.projectsCardTitle'
                    ),
                    text: translateServiceInstance.instant(
                        'uiTour.dashboard.projectsCard'
                    ),
                },
            ];
        }
        if (existingProjectInLocalStorage) {
            return windowWidth > 1024
                ? [
                    {
                        attachTo: {
                            element: '#welcome-step',
                            on: 'bottom',
                        },
                        buttons: [NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.welcomMessageTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.welcomMessage'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#projects-step',
                            on: 'bottom',
                        },
                        scrollTo: false,
                        popperOptions: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 20],
                                    },
                                },
                            ],
                        },
                        buttons: [PreviousButton, NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.projectDropdownTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.projectDropdown'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#notifications-step',
                            on: 'bottom',
                        },
                        buttons: [PreviousButton, NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.notificationsMessageTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.notifications'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#profile-step',
                            on: 'bottom',
                        },
                        buttons: [PreviousButton, NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.profileTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.profile'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#remaining-step',
                            on: 'top',
                            scrollTo: false,
                        },
                        buttons: [PreviousButton, NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.remainingTimeTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.remainingTime'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#start-step',
                            on: 'top',
                            scrollTo: false,
                        },
                        buttons: [PreviousButton, NextButton],
                        title: allowCitizensDesign
                            ? translateServiceInstance.instant(
                                'uiTour.dashboard.startDesignTitle'
                            )
                            : translateServiceInstance.instant(
                                'uiTour.dashboard.giveOpinionTitle'
                            ),
                        text: allowCitizensDesign
                            ? translateServiceInstance.instant(
                                'uiTour.dashboard.startDesign'
                            )
                            : translateServiceInstance.instant(
                                'uiTour.dashboard.giveOpinion'
                            ),
                    },
                    {
                        attachTo: {
                            element: '#location-step',
                            on: 'bottom',
                        },
                        buttons: [PreviousButton, NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.locationTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.location'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#talkToExpert-step',
                            on: 'top',
                            scrollTo: false,
                        },
                        buttons: [PreviousButton, NextButton],
                        showOn: authService.isCitizen(),
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.chatNotAvailableTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.chatNotAvailable'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#designProposals-step',
                            on: 'top',
                            scrollTo: false,
                        },
                        buttons: [PreviousButton, NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.latestDesignProposalsTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.latestDesignProposals'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#poll-step',
                            on: 'top',
                            scrollTo: false,
                        },
                        buttons: [
                            PreviousButton,
                            DoneButton.createButton(translateServiceInstance),
                        ],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.pollTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.poll'
                        ),
                    },
                ]
                : [
                    {
                        attachTo: {
                            element: '#welcome-step',
                            on: 'bottom',
                        },
                        buttons: [NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.welcomMessageTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.welcomMessage'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#projects-step',
                            on: 'bottom',
                        },
                        scrollTo: false,
                        popperOptions: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 20],
                                    },
                                },
                            ],
                        },
                        buttons: [PreviousButton, NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.projectDropdownTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.projectDropdown'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#notifications-step',
                            on: 'bottom',
                        },
                        buttons: [PreviousButton, NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.notificationsMessageTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.notifications'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#profile-step',
                            on: 'right',
                        },
                        buttons: [PreviousButton, NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.profileTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.profile'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#remaining-step',
                            on: 'top',
                            scrollTo: false,
                        },
                        buttons: [PreviousButton, NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.remainingTimeTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.remainingTime'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#start-step',
                            on: 'top',
                            scrollTo: false,
                        },
                        buttons: [PreviousButton, NextButton],
                        title: allowCitizensDesign
                            ? translateServiceInstance.instant(
                                'uiTour.dashboard.startDesignTitle'
                            )
                            : translateServiceInstance.instant(
                                'uiTour.dashboard.giveOpinionTitle'
                            ),
                        text: allowCitizensDesign
                            ? translateServiceInstance.instant(
                                'uiTour.dashboard.startDesign'
                            )
                            : translateServiceInstance.instant(
                                'uiTour.dashboard.giveOpinion'
                            ),
                    },
                    {
                        attachTo: {
                            element: '#talkToExpert-step',
                            on: 'top',
                            scrollTo: false,
                        },
                        buttons: [PreviousButton, NextButton],
                        showOn: authService.isCitizen(),
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.chatNotAvailableTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.chatNotAvailable'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#location-step',
                            on: 'bottom',
                        },
                        buttons: [PreviousButton, NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.locationTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.location'
                        ),
                    },

                    {
                        attachTo: {
                            element: '#designProposals-step',
                            on: 'top',
                            scrollTo: false,
                        },
                        buttons: [PreviousButton, NextButton],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.latestDesignProposalsTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.latestDesignProposals'
                        ),
                    },
                    {
                        attachTo: {
                            element: '#poll-step',
                            on: 'top',
                            scrollTo: false,
                        },
                        buttons: [
                            PreviousButton,
                            DoneButton.createButton(translateServiceInstance),
                        ],
                        title: translateServiceInstance.instant(
                            'uiTour.dashboard.pollTitle'
                        ),
                        text: translateServiceInstance.instant(
                            'uiTour.dashboard.poll'
                        ),
                    },
                ];
        }
        return [
            {
                attachTo: {
                    element: '#welcome-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.dashboard.welcomMessageTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.dashboard.welcomMessage'
                ),
            },

            {
                attachTo: {
                    element: '#notifications-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.dashboard.notificationsMessageTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.dashboard.notifications'
                ),
            },
            {
                attachTo: {
                    element: '#profile-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.dashboard.profileTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.dashboard.profile'
                ),
            },
            {
                attachTo: {
                    element: '#no-project-step',
                    on: 'top',
                    scrollTo: false,
                },
                buttons: [
                    PreviousButton,
                    DoneButton.createButton(translateServiceInstance),
                ],
                title: translateServiceInstance.instant(
                    'uiTour.dashboard.noProjectTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.dashboard.noProject'
                ),
            },
        ];
    }
}

export class DesignTourSteps {
    public static createSteps(
        translateServiceInstance: TranslateService,
        isMobile: boolean,
        tourStepChangedEmitter: EventEmitter<string>
    ): any[] {
        return [
            {
                buttons: [NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.objectsMenuTitle'
                ),
                text: TourUtils.getMenuStepText(
                    translateServiceInstance,
                    OBJECTS_MENU_TOUR_ITEMS
                ),
            },
            {
                id: 'tooling-step-2',
                attachTo: {
                    element: '#left-tooling-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.toolingTitle'
                ),
                text: isMobile
                    ? TourUtils.getMenuStepText(
                        translateServiceInstance,
                        LEFT_TOOLING_TOUR_ITEMS_MOBILE
                    )
                    : TourUtils.getMenuStepText(
                        translateServiceInstance,
                        LEFT_TOOLING_TOUR_ITEMS
                    ),
            },
            {
                id: 'tooling-step-3',
                attachTo: {
                    element: '#right-menu-options-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.toolingTitle'
                ),
                text: TourUtils.getMenuStepText(
                    translateServiceInstance,
                    RIGHT_MENU_TOUR_ITEMS
                ),
            },
            {
                attachTo: {
                    element: '#publish-step',
                    on: 'bottom',
                },
                when: {
                    show: () => {
                        tourStepChangedEmitter.emit('test');
                    },
                    hide: () => {
                        tourStepChangedEmitter.emit(TourEvent.showMenuEvent);
                        tourStepChangedEmitter.emit(
                            TourEvent.objectPlacementEvent
                        );
                    },
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.publishDesignTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.publishDesign'
                ),
            },
            {
                attachTo: {
                    element: '#controlsHTML',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.selectObjectsTitle'
                ),
                text: isMobile
                    ? translateServiceInstance.instant(
                        'uiTour.designMode.selectObjectsDesktop'
                    )
                    : translateServiceInstance.instant(
                        'uiTour.designMode.selectObjectsTablet'
                    ),
            },
            {
                attachTo: {
                    element: '#move-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.moveObjectsTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.moveObjects'
                ),
            },
            {
                attachTo: {
                    element: '#rotate-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.rotateObjectsTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.rotateObjects'
                ),
            },
            {
                attachTo: {
                    element: '#lock-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.lockObjectsTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.lockObjects'
                ),
            },
            {
                attachTo: {
                    element: '#scale-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.scaleObjectsTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.scaleObjects'
                ),
            },
            {
                attachTo: {
                    element: '#delete-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.deleteObjectsTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.deleteObjects'
                ),
            },
            {
                attachTo: {
                    element: '#view-edit-step',
                    on: 'bottom',
                },
                buttons: [
                    PreviousButton,
                    DoneButton.createButton(
                        translateServiceInstance,
                        tourStepChangedEmitter
                    )
                ],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.viewEditTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.viewEdit'
                ),
                scrollTo: true,
            }
        ];
    }
}

export class CollaboratorTourSteps {
    public static createSteps(
        translateServiceInstance: TranslateService,
        isMobile: boolean,
        tourStepChangedEmitter: EventEmitter<string>,
        project: Project
    ): any[] {
        return [
            {
                buttons: [NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.collaboratorDesigningTitle'
                ),
                text: TourUtils.getCollabIntroductionText(
                    translateServiceInstance,
                    project
                ),
            },

            {
                attachTo: {
                    element: '#menu-div',
                    on: 'right',
                },
                buttons: [NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.objectsMenuTitle'
                ),
                text: TourUtils.getMenuStepText(
                    translateServiceInstance,
                    OBJECTS_MENU_TOUR_ITEMS
                ),
            },
            {
                attachTo: {
                    element: '#multiple-objects-step',
                    on: 'right',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.multipleObjectsTitle'
                ),
                text: isMobile
                    ? translateServiceInstance.instant(
                        'uiTour.designMode.multipleObjectsTablet'
                    )
                    : translateServiceInstance.instant(
                        'uiTour.designMode.multipleObjectsDesktop'
                    ),
            },
            {
                attachTo: {
                    element: '#objects-in-line-step',
                    on: 'right',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.objectsInLineTitle'
                ),
                text: isMobile
                    ? translateServiceInstance.instant(
                        'uiTour.designMode.objectsInLineTablet'
                    )
                    : translateServiceInstance.instant(
                        'uiTour.designMode.objectsInLineDesktop'
                    ),
            },

            {
                attachTo: {
                    element: '#multiselect-step',
                    on: 'right',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.selectMultipleTitle'
                ),
                text: isMobile
                    ? translateServiceInstance.instant(
                        'uiTour.designMode.selectMultipleTablet'
                    )
                    : translateServiceInstance.instant(
                        'uiTour.designMode.selectMultipleDesktop'
                    ),
            },
            {
                attachTo: {
                    element: '#shapes-step',
                    on: 'right',
                },
                buttons: [PreviousButton, NextButton],
                when: {
                    hide: () => {
                        tourStepChangedEmitter.emit(
                            TourEvent.objectPlacementEvent
                        );
                    },
                },
                title: translateServiceInstance.instant(
                    'uiTour.designMode.shapesTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.shapes'
                ),
            },
            {
                attachTo: {
                    element: '#view-edit-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.viewEditTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.viewEdit'
                ),
                scrollTo: true,
            },
            {
                attachTo: {
                    element: '#controlsHTML',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.selectObjectsTitle'
                ),
                text: isMobile
                    ? translateServiceInstance.instant(
                        'uiTour.designMode.selectObjectsDesktop'
                    )
                    : translateServiceInstance.instant(
                        'uiTour.designMode.selectObjectsTablet'
                    ),
            },
            {
                attachTo: {
                    element: '#move-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.moveObjectsTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.moveObjects'
                ),
            },
            {
                attachTo: {
                    element: '#rotate-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.rotateObjectsTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.rotateObjects'
                ),
            },
            {
                attachTo: {
                    element: '#lock-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.lockObjectsTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.lockObjects'
                ),
            },
            {
                attachTo: {
                    element: '#scale-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.scaleObjectsTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.scaleObjects'
                ),
            },
            {
                attachTo: {
                    element: '#delete-step',
                    on: 'bottom',
                },
                buttons: [PreviousButton, NextButton],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.deleteObjectsTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.deleteObjects'
                ),
            },
            {
                attachTo: {
                    element: '#collaborators-section',
                    on: 'bottom',
                },
                buttons: [
                    PreviousButton,
                    DoneButton.createButton(
                        translateServiceInstance,
                        tourStepChangedEmitter
                    ),
                ],
                title: translateServiceInstance.instant(
                    'uiTour.designMode.collaboratorsSectionTitle'
                ),
                text: translateServiceInstance.instant(
                    'uiTour.designMode.collaboratorsSection'
                ),
                scrollTo: true,
            },
        ];
    }
}
