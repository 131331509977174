<form [formGroup]="commentForm" (submit)="saveComment()">
    <mat-form-field appearance="none">
        <input matInput class="iosHeight" placeholder="{{ 'comments.formText' | translate }}" formControlName="commentText"
            [maxLength]="256" [style.height.px]="28" (keyup)="onCommentKey($event)" (keydown)="onCommentKey($event)"
            #comment />
        <mat-error *ngIf="hasControlMaxLengthError('commentText', commentForm)">
            {{ 'comments.maxLengthError' | translate }}
        </mat-error>
        <mat-error *ngIf="hasControlIsHtmlError('commentText',commentForm)">
            {{ 'validators.noHTML' | translate }}
        </mat-error>
        <mat-error *ngIf="hasControlWhitespacesError('commentText',commentForm)">
            {{ 'validators.noWhiteSpaces' | translate }}
        </mat-error>
    </mat-form-field>

    <div class="comment-buttons" [class.no-padding]="emojiOpened">
        <ng-container *ngIf="!emojiOpened">
            <div class="flex-row-start">
                <span class="material-icons emoji" matTooltip="{{ 'buttonsTooltips.emoji' | translate }}"
                    (click)="openEmoji()" id="emojiButton">
                    insert_emoticon</span>

                <ng-container *ngIf="!hidePinBtn">
                    <span class="material-icons emoji" matTooltip="{{ 'buttonsTooltips.pin' | translate }}"
                        [class.active]="highlightPin" [hidden]="hidePinOnCommentForm" (click)="onPinEventEmit()">
                        add_location</span>
                </ng-container>
            </div>

            <button type="submit" class="primary-button small" (submit)="saveComment()">
                <h6>{{ 'generic.send' | translate }}</h6>
            </button>
        </ng-container>

        <!-- REFACTOR - this should be a component -->
        <ng-container *ngIf="emojiOpened">
            <div class="emoji-container" [class.no-dialog]="!isDialog || !isPinComment" [class.on-pin]="isPinComment">
                <span class="material-icons pointer" (click)="toggleEmoji()">
                    close
                </span>
                <emoji-mart exclude="{['flags']}" [isNative]="true" [hideRecent]="false" [showPreview]="false"
                    [enableSearch]="false" [color]="emojiColor" [darkMode]="false" (emojiSelect)="handleClick($event)">
                </emoji-mart>
            </div>
        </ng-container>
    </div>
</form>