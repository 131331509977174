<ng-container *ngIf="curentProjectId">
    <div class="statistics-panel">
        <section class="flex-row-center">
            <span class="material-icons green"> comment </span>
            <ng-container *ngIf="comments >= 0">
                <div class="flex-column-start">
                    <h1 class="green">{{ comments }}</h1>
                    <h4>{{ 'user.statistics.noOfComments' | translate }}</h4>
                </div>
            </ng-container>
        </section>
        <section class="flex-row-center">
            <span class="material-icons orange"> widgets </span>
            <ng-container *ngIf="designProposals >= 0">
                <div class="flex-column-start">
                    <h1 class="orange">{{ designProposals }}</h1>
                    <h4>{{ 'user.statistics.noOfProposals' | translate }}</h4>
                </div>
            </ng-container>
        </section>
    </div>

    <div class="user-statistics-container">
        <div class="flex-row-start wrap container-chart">
            <div class="chart">
                <h3>{{ 'user.statistics.userChart' | translate }}</h3>
                <div class="flex-row-start full-width">
                    <div class="progress-spinner-container">
                        <mat-progress-spinner class="back-spinner" mode="determinate" [value]="100" [diameter]="140">
                        </mat-progress-spinner>
                        <mat-progress-spinner class="front-spinner green" mode="determinate"
                            [value]="(activeUsers * 100) / totalUsers" [diameter]="140">
                        </mat-progress-spinner>
                        <h4 [style.width.px]="140" [style.height.px]="140">
                            {{
                            (activeUsers * 100) / totalUsers | number : '1.0-2'
                            }}%
                        </h4>
                    </div>
                    <div>
                        <ng-container *ngIf="activeUsers >= 0 && totalUsers >= 0">
                            <h5 [style.margin-top.px]="20">
                                {{
                                'user.statistics.activeUsers'
                                | translate
                                : {
                                activeUsers: activeUsers,
                                totalUsers: totalUsers
                                }
                                }}
                            </h5>
                        </ng-container>
                        <h6 class="info-message-text" [style.margin-top.px]="20">
                            {{ 'user.statistics.info' | translate }}
                        </h6>
                    </div>
                </div>
            </div>

            <div class="chart">
                <h3>{{ 'user.statistics.dpChart' | translate }}</h3>
                <div class="flex-row-start full-width">
                    <div class="progress-spinner-container" [style.margin-right.px]="40">
                        <mat-progress-spinner class="back-spinner" mode="determinate" [value]="100" [diameter]="140">
                        </mat-progress-spinner>
                        <mat-progress-spinner class="front-spinner blue" color="primary" mode="determinate"
                            [value]="(designProposals * 100) / totalUsers" [diameter]="140">
                        </mat-progress-spinner>
                        <h4 [style.width.px]="140" [style.height.px]="140">
                            {{
                            (designProposals * 100) / totalUsers
                            | number : '1.0-2'
                            }}%
                        </h4>
                    </div>
                    <ng-container *ngIf="designProposals >= 0 && totalUsers >= 0">
                        <h5 [style.margin-top.px]="20">
                            {{
                            'user.statistics.designProposals'
                            | translate
                            : {
                            designProposals: designProposals,
                            totalUsers: totalUsers
                            }
                            }}
                        </h5>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="chart polls">
            <furban-poll-statistics [currentProjectUser]="curentProject"></furban-poll-statistics>
        </div>
    </div>
</ng-container>