export class Project3DUpload {
    public project3DUploadId?: string;
    public projectId: string;
    public position: string;
    public rotation: string;
    public objectType: number;
    public createdDate: Date;
    public objectS3Key: string;
    public textureS3Key: string;
    public extension: string;
}
