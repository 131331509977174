import { Component, EventEmitter, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';

import { EmailUtil } from '@furban/utilities';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
    selector: 'furban-email-codes',
    templateUrl: './email-codes.component.html',
    styleUrls: ['./email-codes.component.scss'],
})
export class EmailCodesComponent {
    @Output()
    public usersAdded: EventEmitter<string[]> = new EventEmitter();

    public noEmailError = false;
    public emailList: string[] = [];
    public addOnBlur = true;

    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    public addUsers(): void {
        if (!this.emailList.length) {
            return;
        }
        this.usersAdded.emit(this.emailList);
        this.emailList = [];
    }

    public add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (!value) {
            return;
        }

        if (!EmailUtil.validateEmail(value)) {
            this.noEmailError = true;
            return;
        }

        this.emailList.push(value);
        event.input.value = '';
        this.noEmailError = false;
    }

    public remove(email: string): void {
        const index = this.emailList.indexOf(email);

        if (index >= 0) {
            this.emailList.splice(index, 1);
        }
    }
}
