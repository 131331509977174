import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Report } from '../_models/report';
import { Observable } from 'rxjs';
import { UserProfileWithRoles } from '../_models/user-profile-with-roles';
import { APP_CONFIG } from '@furban/app-config';
import { Environment } from '../_models/environment-model';

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    private _options = {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    constructor(
        @Inject(APP_CONFIG) private environment: Environment,
        private http: HttpClient
    ) { }

    public saveReport(report: Report): Observable<Report> {
        return this.http
            .post(this.environment.apiPath + '/report', report, {
                headers: this._options.headers,
            })
            .pipe(map((result) => result as Report));
    }

    public deleteReport(report: Report): Observable<Report> {
        return this.http
            .post(this.environment.apiPath + '/report/delete', report, {
                headers: this._options.headers,
            })
            .pipe(map((result) => result as Report));
    }

    public getUserProfilesForDP(
        designProposalId: string
    ): Observable<UserProfileWithRoles[]> {
        return this.http
            .get(
                `${this.environment.apiPath}/report/design/userprofiles/${designProposalId}`,
                this._options
            )
            .pipe(map((result) => result as UserProfileWithRoles[]));
    }

    public getUserProfilesForComment(
        commentId: number
    ): Observable<UserProfileWithRoles[]> {
        return this.http
            .get(
                `${this.environment.apiPath}/report/comment/userprofiles/${commentId}`,
                this._options
            )
            .pipe(map((result) => result as UserProfileWithRoles[]));
    }
}
