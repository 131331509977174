<div class="flex-row-start">
    <div [ngClass]="['icon-container flex-row-center', data.type]">
        <span class="material-icons">{{ data.icon }}</span>
    </div>
    <p>{{ data.message }}</p>
</div>

<button class="secondary-button" (click)="dismiss()">
    <h6>{{ data.action }}</h6>
</button>
