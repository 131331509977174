<furban-loader></furban-loader>

<div class="routing-container">
    <furban-app-navigation *ngIf="isNavigationDisplayed()"> </furban-app-navigation>
    <div class="inside-routing-container"
         [class.expand]="isExpand()">
        <div class="main-container">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>
<furban-app-cookie-banner></furban-app-cookie-banner>
<furban-app-multilanguage [hideButtons]="true"> </furban-app-multilanguage>
