export enum PermitAssetNamesEnum {
    wallGroup = 'permit_wall_group',
    roofGroup = 'permit_roof_group',
    neighborWallGroup = 'permit_neighbor_wall_group',
    neighborRoofGroup = 'permit_neighbor_roof_group',
    wallMaterial = 'wall_material',
    roofMaterial = 'roof_material',
    roofCustomMaterial = 'furban_custom_roof',
    roofAsetMaterial = 'roof_asset_material',
    extensionMaterial = 'extension_material',
    assetGroup = 'asset_object_group',
    selectionGroup = 'selected_objects_group',
    transformGroup = 'transform_control_group',
    dragControlsGroup = 'drag_controls_group',
    intersectionHelpersGroup = 'intersection_helper_group',
    ground = 'furban_ground',
    roofAssetGroup = 'roof_asset',
    extensionGroup = 'extension_group',
    dormersGroup = 'dormers_group',
    solarGroup = 'solar_group',
}
