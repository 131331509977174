import { PermitAssetNamesEnum } from '../_enum/permit-asset-names.enum';
import { Roof } from '../_models/roof';
import { PermitUtils } from './permit.utils';
import { House } from '../_models/house';
import { MiddleVector } from '../_models/middle-vectors';
import { PermitAssetTypeValues } from '../_constants/permit-asset-type-values';
import { RoofAssetEnum } from '../_enum/roof-asset-enum';
import { ThreeUtils } from '../_three-helpers/three.utils';
import {
    sRGBEncoding,
    Mesh,
    TextureLoader,
    RepeatWrapping,
    MeshPhongMaterial,
    DoubleSide,
    Group,
    BufferGeometry,
    BufferAttribute,
    Vector3,
    Euler,
    Scene,
} from 'three';
import { HouseAsset } from '../_models/house-asset';

export class ThreeRoofBuilder {
    public defaultRoofDivision = 0.1;
    public roof: Roof;
    public housePoints: any;
    public roofGroupName: string;
    public roofCase: string;

    constructor(
        roof: Roof,
        housePoints: any,
        roofGroupName: string = PermitAssetNamesEnum.roofGroup
    ) {
        this.roof = roof;
        this.housePoints = housePoints;
        this.roofGroupName = roofGroupName;
    }

    createRoofDivision(position: number): Mesh {
        const extrudedGeometry = PermitUtils.createExtrudeGeometry(
            this.housePoints,
            this.defaultRoofDivision,
            false
        );
        const material = this.createMaterial(
            this.roof.roofMaterial.backgroundImage,
            this.roof.roofColor,
            PermitAssetNamesEnum.roofMaterial
        );
        const floorMesh = this.createMesh(extrudedGeometry, material, position);
        ThreeUtils.applyPositionAndRotationToMesh(floorMesh, true);
        floorMesh.position.y = position;
        floorMesh.visible = false;
        floorMesh.castShadow = true;
        floorMesh.receiveShadow = true;
        return floorMesh;
    }

    public createMesh(geometry: any, material: any, position: any): Mesh {
        const floorMesh = new Mesh(geometry, material);
        floorMesh.rotation.x = -Math.PI / 2;
        floorMesh.updateMatrix();
        floorMesh.geometry.applyMatrix4(floorMesh.matrix);
        floorMesh.rotation.set(0, 0, 0);
        floorMesh.position.y = position + 0.1;
        return floorMesh;
    }

    public createMaterial(
        textureName: string,
        color: string,
        name: PermitAssetNamesEnum,
        repeat = 1
    ): MeshPhongMaterial {
        const texture = new TextureLoader().load(textureName);
        texture.encoding = sRGBEncoding;
        texture.wrapS = texture.wrapT = RepeatWrapping;
        texture.repeat.set(repeat, repeat);

        const material = new MeshPhongMaterial({
            map: texture,
            side: DoubleSide,
        });

        this.setupMaterialProperties(material, color, name);

        return material;
    }

    public createFlatRoof(position: number): Group {
        const roofGroup = new Group();
        roofGroup.name = this.roofGroupName;

        const wallDivision = this.createRoofDivision(position);

        wallDivision.visible = false;
        roofGroup.add(wallDivision);

        const extrudedGeometry = PermitUtils.createExtrudeGeometry(
            this.housePoints,
            this.defaultRoofDivision,
            true
        );
        const material = this.createMaterial(
            this.roof.roofMaterial.backgroundImage,
            this.roof.roofColor,
            PermitAssetNamesEnum.roofMaterial
        );
        const floorMesh = this.createMesh(extrudedGeometry, material, position);

        floorMesh.visible = false;
        ThreeUtils.applyPositionAndRotationToMesh(floorMesh, true);
        floorMesh.position.y = position + 0.1;
        roofGroup.add(floorMesh);

        return roofGroup;
    }

    private computeCustomGeometry(
        positions: number[],
        uvs: number[]
    ): BufferGeometry {
        const geometry = new BufferGeometry();
        const positionNumComponents = 3;
        const uvNumComponents = 2;
        geometry.setAttribute(
            'position',
            new BufferAttribute(
                new Float32Array(positions),
                positionNumComponents
            )
        );
        geometry.setAttribute(
            'uv',
            new BufferAttribute(new Float32Array(uvs), uvNumComponents)
        );
        geometry.computeVertexNormals();
        return geometry;
    }

    public getRoofPoints(objectLookId: string): Vector3[] {
        let roofPoints: Vector3[];

        switch (objectLookId) {
            case RoofAssetEnum.RFA101:
                roofPoints = [new Vector3(15, 4, 2.5), new Vector3(5, 4, 2.5)];
                this.roofCase = RoofAssetEnum.RFA101;
                break;
            case RoofAssetEnum.RFA102:
                roofPoints = [new Vector3(14, 4, 2.5), new Vector3(6, 4, 2.5)];
                this.roofCase = RoofAssetEnum.RFA102;
                break;
            case RoofAssetEnum.RFA103:
                roofPoints = [new Vector3(15, 4, 5), new Vector3(5, 4, 5), new Vector3(5, 4, 0), new Vector3(15, 4, 0)];
                this.roofCase = RoofAssetEnum.RFA103;
                break;
            default:
                break;
        }

        return roofPoints;
    }

    public createRoofAsset(
        roofAssetId: string,
        height: number,
        house?: House,
        position?: Vector3,
        rotation?: Euler,
        scale = new Vector3(1, 1, 1)
    ): Group {
        const roofPoints = this.getRoofPoints(roofAssetId);
        const points = [
            new Vector3(5, 0, 5),
            new Vector3(15, 0, 5),
            new Vector3(15, 0, 0),
            new Vector3(5, 0, 0),
        ];

        const roofGroup = this.createSideAndTopWalls(roofPoints, points, house, roofAssetId, scale, height);
        if (position) {
            roofGroup.position.copy(position);
        }

        if (rotation) {
            roofGroup.rotation.copy(rotation);
        }

        if (scale) {
            roofGroup.scale.copy(scale);
        }

        PermitUtils.setupDefaultOBBonGeometryLevel(roofGroup);

        return roofGroup;
    }

    public createRoofAssetWithCostumTexture(
        roofAssetId: string,
        height: number,
        roofAsset: HouseAsset,
        house?: House,
    ): Group {
        const roofPoints = this.getRoofPoints(roofAssetId);
        const points = [
            new Vector3(5, 0, 5),
            new Vector3(15, 0, 5),
            new Vector3(15, 0, 0),
            new Vector3(5, 0, 0),
        ];

        const position = roofAsset.coordinates as THREE.Vector3;
        const rotation = roofAsset.rotation as THREE.Euler;
        const scale = roofAsset.scale as THREE.Vector3;

        const roofGroup = this.createSideAndTopWallsWithCustomTexture(roofPoints, points, house, roofAssetId, scale, height, roofAsset);

        if (position) {
            roofGroup.position.copy(position);
        }

        if (rotation) {
            roofGroup.rotation.copy(rotation);
        }

        if (scale) {
            roofGroup.scale.copy(scale);
        }

        PermitUtils.setupDefaultOBBonGeometryLevel(roofGroup);

        return roofGroup;
    }

    private createSideAndTopWalls(roofPoints: Vector3[], points: any[], house: House, roofAssetId: string, scale: Vector3, height: number): Group {
        const roofGroup = new Group();
        roofGroup.name = PermitAssetTypeValues.FOR_ROOF.name;

        const sideWallMesh = this.createRoofAssetSideWall(roofAssetId, points, roofPoints, house, scale);
        ThreeUtils.applyPositionAndRotationToMesh(sideWallMesh, true);
        sideWallMesh.position.y = height;
        roofGroup.add(sideWallMesh);

        let topRoofMesh: Mesh | Group;
        switch (this.roofCase) {
            case 'RFA103':
                topRoofMesh = this.createRoofAssetTopSideRFA103(points, house, roofPoints, scale);
                topRoofMesh.children.forEach((mesh: Mesh) => {
                    ThreeUtils.applyPositionAndRotationToMesh(mesh, true);
                    mesh.position.y = height;
                });
                roofGroup.add(...topRoofMesh.children);
                break;
            default:
                topRoofMesh = this.createRoofAssetTopSide(points, house, roofPoints, scale);
                ThreeUtils.applyPositionAndRotationToMesh(topRoofMesh, true);
                topRoofMesh.position.y = height;
                roofGroup.add(topRoofMesh);
                break;
        }

        return roofGroup;
    }

    private createSideAndTopWallsWithCustomTexture(roofPoints: Vector3[], points: any[], house: House, roofAssetId: string, scale: Vector3, height: number, roofAsset: HouseAsset): Group {
        const roofGroup = new Group();
        roofGroup.name = PermitAssetTypeValues.FOR_ROOF.name;

        const sideWallMesh = this.createRoofAssetSideWallWithCustomTexture(roofAssetId, points, roofPoints, house, roofAsset, scale);
        ThreeUtils.applyPositionAndRotationToMesh(sideWallMesh, true);
        sideWallMesh.position.y = height;
        roofGroup.add(sideWallMesh);

        let topRoofMesh: Mesh | Group;
        switch (this.roofCase) {
            case 'RFA103':
                topRoofMesh = this.createRoofAssetTopSideRFA103WithCustomTexture(points, house, roofAsset, roofPoints, scale);
                topRoofMesh.children.forEach((mesh: Mesh) => {
                    ThreeUtils.applyPositionAndRotationToMesh(mesh, true);
                    mesh.position.y = height;
                });
                roofGroup.add(...topRoofMesh.children);
                break;
            default:
                topRoofMesh = this.createRoofAssetTopSideWithCustomTexture(points, house, roofAsset, roofPoints, scale);
                ThreeUtils.applyPositionAndRotationToMesh(topRoofMesh, true);
                topRoofMesh.position.y = height;
                roofGroup.add(topRoofMesh);
                break;
        }

        return roofGroup;
    }

    private createRoofAssetSideWall(assetId: string, points: Vector3[], roofPoints: Vector3[], house: House, scale = new Vector3(1, 1, 1)): Mesh {
        const sideWallPosition = [];
        const sideWallUvs = [];

        sideWallPosition.push(points[0].x, points[0].y, points[0].z);
        sideWallUvs.push(0, 1);

        sideWallPosition.push(
            roofPoints[1].x,
            roofPoints[1].y,
            roofPoints[1].z
        );
        sideWallUvs.push(0.5, 0.5);

        sideWallPosition.push(points[3].x, points[3].y, points[3].z);
        sideWallUvs.push(1, 1);

        sideWallPosition.push(points[1].x, points[1].y, points[1].z);
        sideWallUvs.push(0, 1);

        sideWallPosition.push(
            roofPoints[0].x,
            roofPoints[0].y,
            roofPoints[0].z
        );
        sideWallUvs.push(0.5, 0.5);

        sideWallPosition.push(points[2].x, points[2].y, points[2].z);
        sideWallUvs.push(1, 1);

        if (this.isRFA103(this.roofCase)) {
            const positionsAndUvsRFA103 = this.createRoofAssetSideWallRFA103(points, roofPoints);
            positionsAndUvsRFA103[0].forEach((point: any) => sideWallPosition.push(point));
            positionsAndUvsRFA103[1].forEach((point: any) => sideWallUvs.push(point));
        }

        const isSpecificRoofType = this.isRFA101(assetId) || this.isRFA103(assetId);
        const backgroundImage = isSpecificRoofType ? house.houseMaterial.backgroundImage : house.roof.roofMaterial.backgroundImage;
        const color = isSpecificRoofType ? house.houseColor : house.roof.roofColor;

        const sideWallGeometry = this.computeCustomGeometry(sideWallPosition, sideWallUvs);
        const sideWallMaterial = this.createMaterial(backgroundImage, color, isSpecificRoofType ? PermitAssetNamesEnum.wallMaterial : PermitAssetNamesEnum.roofMaterial, (8 * scale.y));
        const sideWallMesh = new Mesh(sideWallGeometry, sideWallMaterial);
        sideWallMesh.position.y = sideWallMesh.position.y + 0.1;

        return sideWallMesh;
    }

    private createRoofAssetSideWallWithCustomTexture(assetId: string, points: Vector3[], roofPoints: Vector3[], house: House, roofAsset: HouseAsset, scale = new Vector3(1, 1, 1)): Mesh {
        const sideWallPosition = [];
        const sideWallUvs = [];

        sideWallPosition.push(points[0].x, points[0].y, points[0].z);
        sideWallUvs.push(0, 1);

        sideWallPosition.push(
            roofPoints[1].x,
            roofPoints[1].y,
            roofPoints[1].z
        );
        sideWallUvs.push(0.5, 0.5);

        sideWallPosition.push(points[3].x, points[3].y, points[3].z);
        sideWallUvs.push(1, 1);

        sideWallPosition.push(points[1].x, points[1].y, points[1].z);
        sideWallUvs.push(0, 1);

        sideWallPosition.push(
            roofPoints[0].x,
            roofPoints[0].y,
            roofPoints[0].z
        );
        sideWallUvs.push(0.5, 0.5);

        sideWallPosition.push(points[2].x, points[2].y, points[2].z);
        sideWallUvs.push(1, 1);

        if (this.isRFA103(this.roofCase)) {
            const positionsAndUvsRFA103 = this.createRoofAssetSideWallRFA103(points, roofPoints);
            positionsAndUvsRFA103[0].forEach((point: any) => sideWallPosition.push(point));
            positionsAndUvsRFA103[1].forEach((point: any) => sideWallUvs.push(point));
        }

        const isSpecificRoofType = this.isRFA101(assetId) || this.isRFA103(assetId);
        const backgroundImage = isSpecificRoofType ? house.houseMaterial.backgroundImage : roofAsset.material.backgroundImage;
        const color = isSpecificRoofType ? house.houseColor : roofAsset.color;

        const sideWallGeometry = this.computeCustomGeometry(sideWallPosition, sideWallUvs);
        const sideWallMaterial = this.createMaterial(backgroundImage, color, isSpecificRoofType ? PermitAssetNamesEnum.wallMaterial : PermitAssetNamesEnum.roofMaterial, (8 * scale.y));
        const sideWallMesh = new Mesh(sideWallGeometry, sideWallMaterial);
        sideWallMesh.position.y = sideWallMesh.position.y + 0.1;

        return sideWallMesh;
    }

    private createRoofAssetSideWallRFA103(points: Vector3[], roofPoints: Vector3[]): any[][] {
        const sideWallPosition = [];
        const sideWallUvs = [];

        sideWallPosition.push(points[0].x, points[0].y, points[0].z);
        sideWallUvs.push(1, 1);

        sideWallPosition.push(roofPoints[1].x, roofPoints[1].y, roofPoints[1].z);
        sideWallUvs.push(0.5, 0.5);

        sideWallPosition.push(points[1].x, points[1].y, points[1].z);
        sideWallUvs.push(0, 1);

        sideWallPosition.push(points[1].x, points[1].y, points[1].z);
        sideWallUvs.push(0, 1);

        sideWallPosition.push(roofPoints[0].x, roofPoints[0].y, roofPoints[0].z);
        sideWallUvs.push(1, 1);

        sideWallPosition.push(roofPoints[1].x, roofPoints[1].y, roofPoints[1].z);
        sideWallUvs.push(0.5, 0.5);

        return [sideWallPosition, sideWallUvs];

    }



    public createDefaultRoofAsset(height: number, house?: House, position?: Vector3, rotation?: Euler, scale = new Vector3(1, 1, 1)): Group {
        const roofGroup = new Group();
        roofGroup.name = PermitAssetTypeValues.FOR_ROOF.name;

        const points = [
            new Vector3(5, 0, 5),
            new Vector3(15, 0, 5),
            new Vector3(15, 0, 0),
            new Vector3(5, 0, 0),
        ];
        const segments = PermitUtils.getSegmentsWithMiddleVectors(
            points,
            height
        );

        const sideWallMesh = this.createSideWalls(segments, house, scale);
        ThreeUtils.applyPositionAndRotationToMesh(sideWallMesh, true);
        sideWallMesh.position.y = height;
        const topRoofMesh = this.createTopRoof(segments, scale);
        ThreeUtils.applyPositionAndRotationToMesh(topRoofMesh, true);
        topRoofMesh.position.y = height;

        roofGroup.add(sideWallMesh);
        roofGroup.add(topRoofMesh);

        if (position) {
            roofGroup.position.copy(position);
        }

        if (rotation) {
            roofGroup.rotation.copy(rotation);
        }

        if (scale) {
            roofGroup.scale.copy(scale);
        }

        PermitUtils.setupDefaultOBBonGeometryLevel(roofGroup);

        return roofGroup;
    }

    /** This is work in progress. This will not work if you have a strange shape where the cennter is outside shape */
    public createPointyRoof(
        position: number,
        scene: Scene,
        house?: House
    ): Group {
        const roofGroup = new Group();
        roofGroup.name = PermitAssetNamesEnum.roofGroup;

        const wallDivision = this.createRoofDivision(position);
        roofGroup.add(wallDivision);

        const topVertices = PermitUtils.getTopVertices(
            this.housePoints,
            position
        );
        const segments = PermitUtils.getSegmentsWithMiddleVectors(
            topVertices,
            position
        );

        const sideWallMesh = this.createSideWalls(segments, house);

        const wallGroup = scene.getObjectByName(PermitAssetNamesEnum.wallGroup);
        wallGroup.add(sideWallMesh);

        const topRoofMesh = this.createTopRoof(segments);
        roofGroup.add(topRoofMesh);
        return roofGroup;
    }

    private isRFA101(assetId: string): boolean {
        return assetId === RoofAssetEnum.RFA101;
    }

    private isRFA103(assetId: string): boolean {
        return assetId === RoofAssetEnum.RFA103;
    }

    private createSideWalls(segments: MiddleVector[], house: House, scale = new Vector3(1, 1, 1)): Mesh {
        const sideWallPosition = [];
        const sideWallUvs = [];

        for (let i = 0; i < 2; i++) {
            sideWallPosition.push(
                segments[i].vectors[0].x,
                segments[i].vectors[0].y,
                segments[i].vectors[0].z
            );
            sideWallUvs.push(0, 1);

            sideWallPosition.push(
                segments[i].middle.x,
                segments[i].middle.y,
                segments[i].middle.z
            );
            sideWallUvs.push(0.5, 0.5);

            sideWallPosition.push(
                segments[i].vectors[1].x,
                segments[i].vectors[1].y,
                segments[i].vectors[1].z
            );
            sideWallUvs.push(1, 1);
        }

        const sideWallGeometry = this.computeCustomGeometry(
            sideWallPosition,
            sideWallUvs
        );
        const sideWallMaterial = this.createMaterial(
            house.houseMaterial.backgroundImage,
            house.houseColor,
            PermitAssetNamesEnum.wallMaterial,
            8 * scale.y
        );
        const sideWallMesh = new Mesh(sideWallGeometry, sideWallMaterial);
        sideWallMesh.position.y = sideWallMesh.position.y + 0.1;

        return sideWallMesh;
    }


    private createRoofAssetTopSide(points: Vector3[], house: House, roofPoints: Vector3[], scale = new Vector3(1, 1, 1)): Mesh {

        const roofPositions: number[] = [];
        const roofUvs: number[] = [];

        PermitUtils.generateFirstPart1(
            points,
            roofPoints,
            roofPositions,
            roofUvs
        );
        PermitUtils.generateSecondPart2(
            points,
            roofPoints,
            roofPositions,
            roofUvs
        );

        const backgroundImage = this.isRFA103(this.roofCase) ? house.houseMaterial.backgroundImage : house.roof.roofMaterial.backgroundImage;
        const color = this.isRFA103(this.roofCase) ? house.houseColor : house.roof.roofColor;
        const geometry = this.computeCustomGeometry(roofPositions, roofUvs);
        const material = this.createMaterial(backgroundImage, color, PermitAssetNamesEnum.roofMaterial, (8 * scale.y));
        const roofPointy = new Mesh(geometry, material);

        roofPointy.position.y = roofPointy.position.y + 0.1;

        return roofPointy;
    }

    private createRoofAssetTopSideWithCustomTexture(points: Vector3[], house: House, roofAsset: HouseAsset, roofPoints: Vector3[], scale = new Vector3(1, 1, 1)): Mesh {

        const roofPositions: number[] = [];
        const roofUvs: number[] = [];

        PermitUtils.generateFirstPart1(
            points,
            roofPoints,
            roofPositions,
            roofUvs
        );
        PermitUtils.generateSecondPart2(
            points,
            roofPoints,
            roofPositions,
            roofUvs
        );

        const backgroundImage = this.isRFA103(this.roofCase) ? house.houseMaterial.backgroundImage : roofAsset.material.backgroundImage;
        const color = this.isRFA103(this.roofCase) ? house.houseColor : roofAsset.color;
        const geometry = this.computeCustomGeometry(roofPositions, roofUvs);
        const material = this.createMaterial(backgroundImage, color, PermitAssetNamesEnum.roofMaterial, (8 * scale.y));
        const roofPointy = new Mesh(geometry, material);

        roofPointy.position.y = roofPointy.position.y + 0.1;

        return roofPointy;
    }

    private createRoofAssetTopSideRFA103(points: Vector3[], house: House, roofPoints: Vector3[], scale = new Vector3(1, 1, 1)): Group {

        const roofPositions: number[] = [];
        const roofUvs: number[] = [];
        const group = new Group();

        PermitUtils.generateSecondPart2(points, roofPoints, roofPositions, roofUvs);

        const roofPointy2 = this.generateGeometryRFA103(roofPositions, roofUvs, house, scale);
        group.attach(roofPointy2);

        return group;
    }

    private createRoofAssetTopSideRFA103WithCustomTexture(points: Vector3[], house: House, roofAsset: HouseAsset, roofPoints: Vector3[], scale = new Vector3(1, 1, 1)): Group {

        const roofPositions: number[] = [];
        const roofUvs: number[] = [];
        const group = new Group();

        PermitUtils.generateSecondPart2(points, roofPoints, roofPositions, roofUvs);

        const roofPointy2 = this.generateGeometryRFA103WithCustomTexture(roofPositions, roofUvs, house, roofAsset, scale);
        group.attach(roofPointy2);

        return group;
    }

    private generateGeometryRFA103(roofPositions: number[], roofUvs: number[], house: House, scale: { x?: number; y: any; z?: number; }): Mesh {

        const backgroundImage = house.roof.roofMaterial.backgroundImage;
        const color = house.roof.roofColor;
        const geometry = this.computeCustomGeometry(roofPositions, roofUvs);
        const material = this.createMaterial(backgroundImage, color, PermitAssetNamesEnum.roofMaterial, (8 * scale.y));
        const roofPointy = new Mesh(geometry, material);
        roofPointy.position.y = roofPointy.position.y + 0.1;

        return roofPointy;
    }

    private generateGeometryRFA103WithCustomTexture(roofPositions: number[], roofUvs: number[], house: House, roofAsset: HouseAsset, scale: { x?: number; y: any; z?: number; }): Mesh {

        const backgroundImage = roofAsset.material ? roofAsset.material.backgroundImage : house.roof.roofMaterial.backgroundImage;
        const color = roofAsset.color ? roofAsset.color : house.roof.roofColor;
        const geometry = this.computeCustomGeometry(roofPositions, roofUvs);
        const material = this.createMaterial(backgroundImage, color, PermitAssetNamesEnum.roofMaterial, (8 * scale.y));
        const roofPointy = new Mesh(geometry, material);
        roofPointy.position.y = roofPointy.position.y + 0.1;

        return roofPointy;
    }


    private createTopRoof(segments: MiddleVector[], scale = new Vector3(1, 1, 1)): Mesh {
        const referenceVectors = PermitUtils.getNearestAndFarestPointFromIndex(segments[0], segments[1])

        const roofPositions: number[] = [];
        const roofUvs: number[] = [];

        PermitUtils.generateFirstPart(
            segments[2],
            referenceVectors.first,
            referenceVectors.second,
            roofPositions,
            roofUvs
        );
        PermitUtils.generateSecondPart(
            segments[3],
            referenceVectors.first,
            referenceVectors.second,
            roofPositions,
            roofUvs
        );

        const geometry = this.computeCustomGeometry(roofPositions, roofUvs);
        const material = this.createMaterial(
            this.roof.roofMaterial.backgroundImage,
            this.roof.roofColor,
            PermitAssetNamesEnum.roofMaterial,
            8 * scale.y
        );
        const roofPointy = new Mesh(geometry, material);

        roofPointy.position.y = roofPointy.position.y + 0.1;

        return roofPointy;
    }

    private setupMaterialProperties(
        material: MeshPhongMaterial,
        color: string,
        name: PermitAssetNamesEnum
    ): void {
        material.color.set(color);
        material.needsUpdate = true;
        material.name = name;
    }
}
