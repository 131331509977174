<span *ngIf="highlight" class="material-icons municipality-overlay">
    business
</span>

<div
    *ngIf="!mediaId"
    class="svg-container"
    [style.width.px]="size"
    [style.height.px]="size"
    [style.fill]="calculateColor(name)"
>
    <svg x="0px" y="0px" viewBox="0 0 24 24">
        <g>
            <circle class="st0" cx="12" cy="12" r="12" />
            <path
                class="st1"
                d="M14.7,17.6v-2c0.5-0.3,1.5-1.2,1.6-3.2c0.4-0.3,0.7-0.9,0.7-1.5s-0.2-1.1-0.5-1.4c0.3-0.8,0.8-2.3,0.4-3.6
			c-0.3-1.5-2.4-2.1-4-2.1c-1.5,0-3.3,0.4-3.9,1.6C8.1,5.4,7.7,5.7,7.5,6c-0.8,1-0.2,2.6,0,3.6C7.2,9.8,6.9,10.4,6.9,11
			c0,0.8,0.2,1.3,0.7,1.5c0.1,2,1.1,2.8,1.6,3.2v2c-0.4,0.2-4.7,1.7-6,2.5C5.4,22.5,8.5,24,12,24s6.6-1.5,8.8-3.9
			C19.5,19.3,15.1,17.8,14.7,17.6z"
            />
        </g>
    </svg>
</div>

<img
    *ngIf="mediaId"
    class="image-badge"
    [style.width.px]="size"
    [style.height.px]="size"
    [src]="mediaId"
/>
