import { SupplierTreeNode } from './supplier-tree-node';

export class ParentTreeNode implements SupplierTreeNode {
    itemName: string;
    itemId: string | number;
    children: SupplierTreeNode[];
    canBeUnchecked = true;

    constructor(
        itemName: string,
        itemId: string | number,
        children: SupplierTreeNode[]
    ) {
        this.itemName = itemName;
        this.itemId = itemId;
        this.children = children;
    }
}
