import {
    Component,
    OnInit,
    ViewContainerRef,
    ViewChild,
    ElementRef,
    OnDestroy,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatAutocomplete } from '@angular/material/autocomplete';

import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import {
    AuthService,
    Client,
    FurbanUtil,
    ModalManager,
    MunicipalityService,
    NotificationModel,
    NotificationService,
    NotificationDropdown,
    NotificationRadioButtons,
    validateWhitespace,
    Project,
    ControlsUtil,
} from '@furban/utilities';
import { ProjectDetailsService } from '../../../project-shared/project-details/project-details.service';

@Component({
    selector: 'furban-notification-modal',
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit, OnDestroy {
    @ViewChild('dropDownInput') dropDownInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    public notificationsForm: FormGroup;
    public parentRef: ViewContainerRef;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public showDropdown = false;
    public filteredData: Observable<NotificationDropdown[]>;
    public notification: NotificationModel;
    public radioButtons: NotificationRadioButtons[] = [];
    public dropdownData: NotificationDropdown[];
    public placeholder: string;
    public selectedData: NotificationDropdown[] = [];
    public selectable = true;
    public removable = true;
    public separatorKeysCodes: number[] = [ENTER, COMMA];
    private projectsListSubscription: Subscription;
    public hasControlMaxError = ControlsUtil.hasControlMaxError;
    public hasControlMinError = ControlsUtil.hasControlMinError;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;
    public hasControlMaxLengthError = ControlsUtil.hasControlMaxLengthError;
    public hasControlMinLengthError = ControlsUtil.hasControlMinLengthError;
    public hasControlIsHtmlError = ControlsUtil.hasControlIsHtmlError;
    public hasControlWhitespacesError = ControlsUtil.hasControlWhitespacesError;
    public hasControlEmailError = ControlsUtil.hasControlEmailError;
    public hasControlDatePassedError = ControlsUtil.hasControlDatePassedError;

    constructor(
        private dialogRef: MatDialogRef<NotificationModalComponent>,
        private translateService: TranslateService,
        private formBuilder: FormBuilder,
        private modal: ModalManager,
        private municipalityService: MunicipalityService,
        private notificationsService: NotificationService,
        private authService: AuthService,
        private projectDetailsService: ProjectDetailsService
    ) { }

    ngOnInit() {
        this.notificationsForm = this.formBuilder.group({
            message: [
                '',
                [
                    this.htmlValidator,
                    validateWhitespace,
                    Validators.required,
                    Validators.maxLength(256),
                ],
            ],
            expiringDate: ['', [this.dateValidator]],
        });

        if (this.authService.isSuperAdmin()) {
            this.intitialiseSuperAdminNotifications();
        } else {
            this.initialiseAdminNotifications();
        }
    }

    ngOnDestroy(): void {
        this.projectsListSubscription?.unsubscribe();
    }

    remove(municipality: NotificationDropdown): void {
        const index = this.selectedData.indexOf(municipality);

        if (index >= 0) {
            this.selectedData.splice(index, 1);
        }

        this.setChipControlInvalidIfNoChips();
    }

    selected(dataItem: any): void {
        const newMun = dataItem.option.value;
        if (
            !this.selectedData.some(
                (item) =>
                    item.notificationDropdownValue ===
                    newMun.notificationDropdownValue
            )
        ) {
            this.selectedData.push(newMun);
        }
        this.dropDownInput.nativeElement.value = '';
        this.notificationsForm.get('dropdownControl').setValue(null);
        this.notificationsForm.get('chipListControl').setErrors(null);
        (document.activeElement as HTMLElement).blur();
    }

    checkIfIsSuperAdmin() {
        return this.authService.isSuperAdmin();
    }

    intitialiseSuperAdminNotifications() {
        this.placeholder = this.translateService.instant(
            'notifications.selectMunicipality'
        );
        this.radioButtons.push(new NotificationRadioButtons('System', '2'));
        this.radioButtons.push(
            new NotificationRadioButtons('Municipality', '1')
        );
        this.notificationsForm.addControl('type', new FormControl('2', []));
    }

    initialiseAdminNotifications() {
        this.placeholder = this.translateService.instant(
            'notifications.selectProject'
        );

        this.notificationsForm.addControl('type', new FormControl('0', []));

        if (this.projectDetailsService.allProjects) {
            this.setProjectsList(this.projectDetailsService.allProjects);
        } else {
            this.projectsListSubscription =
                this.projectDetailsService.allProjectsListObservable.subscribe(
                    (data) => {
                        this.setProjectsList(data);
                    }
                );
        }
    }

    createDropdownControl() {
        this.showDropdown = true;

        this.notificationsForm.addControl('chipListControl', new FormControl());
        this.notificationsForm.addControl('dropdownControl', new FormControl());
        this.filteredData = this.notificationsForm
            .get('dropdownControl')
            .valueChanges.pipe(
                startWith(''),
                map((dataItem: string | null) =>
                    dataItem
                        ? this._filteredData(dataItem)
                        : this.dropdownData.slice()
                )
            );

    }

    setChipControlInvalidIfNoChips() {
        if (this.showDropdown && this.selectedData.length === 0) {
            this.notificationsForm
                .get('chipListControl')
                .setErrors({ notExists: true });
        }
    }

    sendNotification() {
        this.setChipControlInvalidIfNoChips();

        if (this.notificationsForm.invalid) {
            this.markFormAsDirty();
            return;
        }
        const arrayOfNotifications: NotificationModel[] = [];
        let expiringDate: Date = this.getFormControlValue('expiringDate');
        if (!expiringDate) {
            expiringDate = new Date();
            expiringDate.setMonth(expiringDate.getMonth() + 1);
        }

        if (this.getFormControlValue('type') !== '2') {
            this.selectedData.forEach((item) => {
                this.addNotificationToArray(
                    arrayOfNotifications,
                    this.getFormControlValue('message'),
                    expiringDate,
                    item.notificationDropdownLabel,
                    item.notificationDropdownValue
                );
            });
        } else {
            this.addNotificationToArray(
                arrayOfNotifications,
                this.getFormControlValue('message'),
                expiringDate,
                'System'
            );
        }

        this.notificationsService
            .postNotification(
                arrayOfNotifications,
                this.getFormControlValue('type'),
                this.authService.getUserRoleAsString().toLowerCase()
            )
            .subscribe((data) => {
                this.dialogRef.close(data);
            });
    }

    radioChange(event: MatRadioChange) {
        if (event.value === '1') {
            this.municipalityService.getAllClients().subscribe((data) => {
                this.getMunicipalities(data);
            });
        } else {
            this.showDropdown = false;
        }
    }

    getMunicipalities(data: Client[]) {
        this.dropdownData = data.map(
            (dataItem) =>
                new NotificationDropdown(
                    dataItem.clientName,
                    dataItem.clientUuid
                )
        );
        this.createDropdownControl();
    }

    markFormAsDirty() {
        for (const field in this.notificationsForm.controls) {
            if (this.notificationsForm.get(field)) {
                this.notificationsForm.get(field).markAsDirty();
            }
        }
    }

    public dateValidator(control: FormControl) {
        const date = control.value;
        let isValid = true;

        if (date && date < new Date()) {
            isValid = false;
        }

        return isValid ? null : { datePassed: true };
    }

    closePopup() {
        if (this.notificationsForm.dirty) {
            this.modal
                .showModal(
                    this.parentRef,
                    ModalManager.createConfiguration(
                        'errors.warning',
                        'errors.discardMessage',
                        'generic.yesBtn',
                        'generic.noBtn'
                    )
                )
                .subscribe((res) => {
                    if (res) {
                        this.dialogRef.close();
                    }
                });
        } else {
            this.dialogRef.close();
        }
    }

    private setProjectsList(data: Project[]): void {
        this.dropdownData = data.map(
            (dataItem) =>
                new NotificationDropdown(dataItem.name, dataItem.projectId)
        );
        this.createDropdownControl();
    }

    private addNotificationToArray = (
        arrayOfNotifications: NotificationModel[],
        message: string,
        expiringDate: Date,
        targetName: string,
        targetId?: string
    ): void => {
        const notification: NotificationModel = new NotificationModel(
            message,
            expiringDate
        );
        notification.targetName = targetName;
        if (targetId) {
            notification.targetId = targetId;
        }
        arrayOfNotifications.push(notification);
    };

    private getFormControlValue(controlName: string) {
        return this.notificationsForm.get(controlName).value;
    }

    private _filteredData(value: any): NotificationDropdown[] {
        let filterValue;
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else {
            return this.selectedData;
        }

        return this.dropdownData.filter(
            (dataItem) =>
                dataItem.notificationDropdownLabel
                    .toLowerCase()
                    .indexOf(filterValue) === 0
        );
    }
}
