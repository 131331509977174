import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { AuthService, ControlsUtil, FurbanUtil, UserProfile } from '@furban/utilities';
import { UserProfileService } from '../../municipality/user/user-profile/user-profile.service';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms.component';

import { Project, ProjectStorageService } from '@furban/utilities';

@Component({
    selector: 'furban-change-name',
    templateUrl: './change-name.component.html',
    styleUrls: ['./change-name.component.scss'],
})
export class ChangeNameComponent implements OnInit {
    @Input() public profile: UserProfile;

    public changeNameCheckForm: FormGroup;
    public readonly userDisplayNameMaxLength = 64;
    public noWhiteSpacesValidator = FurbanUtil.noWhiteSpacesValidator;
    public htmlValidator = FurbanUtil.htmlContentValidator;
    public nameValidator = FurbanUtil.nameValidator;
    public saveIsEnabled = false;
    public curentProject: Project;
    public isLoaded: boolean;
    private codeUser: string;
    public hasControlRequiredError = ControlsUtil.hasControlRequiredError;
    public hasControlIsHtmlError = ControlsUtil.hasControlIsHtmlError;
    public hasControlWhitespacesError = ControlsUtil.hasControlWhitespacesError;
    public hasControlIsCodeNamedError = ControlsUtil.hasControlIsCodeNamedError;

    constructor(
        private authService: AuthService,
        private userProfileService: UserProfileService,
        private formBuilder: FormBuilder,
        private projectStorageService: ProjectStorageService,
        private dialog: MatDialog
    ) {
        this.getCurrentProject();
    }

    ngOnInit(): void {
        this.curentProject = this.projectStorageService.getCurentProject();
        this.codeUser = this.authService.codeUser;
        this.changeNameCheckForm = this.formBuilder.group({
            displayName: [
                '',
                [
                    Validators.required,
                    this.htmlValidator,
                    this.noWhiteSpacesValidator,
                    this.nameValidator(this.codeUser),
                ],
            ],
            termsAndConditionsFormControl: ['', [Validators.requiredTrue]],
        });
    }

    public submitChangeProfileForm(): void {
        if (this.changeNameCheckForm.invalid) {
            return;
        }
        this.profile.screenName = this.changeNameCheckForm
            .get('displayName')
            .value.trim();
        this.profile.acceptedTOS = this.changeNameCheckForm.get(
            'termsAndConditionsFormControl'
        ).value;
        this.userProfileService
            .updateProfile(this.profile)
            .subscribe((data) => {
                this.authService.userProfile = data;
                if (this.authService.isPioneer()) {
                    this.authService.redirectPioneer();
                } else {
                    this.authService.redirectUser();
                    localStorage.setItem('has-design-tour', 'false');
                    localStorage.setItem('has-dashboard-tour', 'false');
                    this.authService.isFirstTimeLogin = true;
                }
            });
    }

    public openTermsModal(
        event: MouseEvent | KeyboardEvent | TouchEvent
    ): void {
        if (event) {
            event.preventDefault();
        }
        const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
            width: '60%',
        });
        dialogRef.disableClose = false;
        dialogRef.componentInstance.showButtons = false;
    }

    public changeEvent(event: MouseEvent | KeyboardEvent | TouchEvent): void {
        this.saveIsEnabled = this.changeNameCheckForm.get(
            'termsAndConditionsFormControl'
        ).value;
    }

    private getCurrentProject(): void {
        this.projectStorageService.projectChanged.subscribe((item) => {
            this.curentProject = item;
        });
    }
}
