import { NotificationType } from './notification-type';

export class NotificationModel {
    public notificationId?: string;
    public message: string;
    public notificationType: NotificationType;
    public createdDate?: Date;
    public expiringDate?: Date;
    public targetId?: string;
    public senderId?: number;
    public targetName?: string;

    constructor(
        message: string,
        expiringDate: Date,
        targetId?: string,
        targetName?: string
    ) {
        this.message = message;
        this.expiringDate = expiringDate;
        if (targetId) {
            this.targetId = targetId;
        }
        if (targetName) {
            this.targetName = targetName;
        }
    }
}
