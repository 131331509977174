import { Step } from '../_models/step';
import { PermitStepStatusEnum } from '../_enum/permit-step-status.enum';
import { routingConstants } from './routing-constants';
import { PermitProject } from '../_models/permit-project';
import { Project } from '../_models/project';

export class ProjectStepsArray {
    public static createPermitSteps(project: PermitProject): Step[] {
        const projectId = project.projectId ? project.projectId : 'new';
        const currentStepId = project.currentStepId ? project.currentStepId : 0;

        return [
            new Step(
                1,
                'pioneer.permitProcessDashboard.1stStep',
                this.getProjectStepStatus(1, currentStepId),
                `${routingConstants.permitProject}/${projectId}/(permit-project:details)`
            ),
            new Step(
                2,
                'pioneer.permitProcessDashboard.2ndStep',
                this.getProjectStepStatus(2, currentStepId),
                `${routingConstants.permitProject}/${projectId}/(permit-project:map)`
            ),
            new Step(
                3,
                'pioneer.permitProcessDashboard.3rdStep',
                this.getProjectStepStatus(3, currentStepId),
                `${routingConstants.permitProject}/${projectId}/(permit-project:preview)`
            ),
            new Step(
                4,
                'pioneer.permitProcessDashboard.4thStep',
                this.getProjectStepStatus(4, currentStepId),
                `${routingConstants.permitProject}/${projectId}/(permit-project:update)`
            ),
        ];
    }

    public static createInitiativeProjectSteps(project: Project): Step[] {
        const projectId = project.projectId ? project.projectId : 'new';
        const currentStepId = project.currentStepId ? project.currentStepId : 0;
        return [
            new Step(
                1,
                'navigation.steps.1stStep',
                this.getProjectStepStatus(1, currentStepId),
                `${routingConstants.initiativeProcess}/${projectId}/(project:details)`
            ),
            new Step(
                2,
                'navigation.steps.2ndStep',
                this.getProjectStepStatus(2, currentStepId),
                `${routingConstants.initiativeProcess}/${projectId}/(project:area-definition)`
            ),
            new Step(
                3,
                'navigation.steps.4thStep',
                this.getProjectStepStatus(3, currentStepId),
                `${routingConstants.initiativeProcess}/${projectId}/(project:objects)`
            ),
            new Step(
                4,
                'navigation.steps.5thaStep',
                this.getProjectStepStatus(4, currentStepId),
                `${routingConstants.initiativeProcess}/${projectId}/(project:manage-users)`)
        ];
    }
    public static createGeneralProjectSteps(project: Project): Step[] {
        const projectId = project.projectId ? project.projectId : 'new';
        const currentStepId = project.currentStepId ? project.currentStepId : 0;

        if (project.isCitizenDesigns) {
            return [
                new Step(
                    1,
                    'navigation.steps.1stStep',
                    this.getProjectStepStatus(1, currentStepId),
                    `${routingConstants.process}/${projectId}/(project:details)`
                ),
                new Step(
                    2,
                    'navigation.steps.2ndStep',
                    this.getProjectStepStatus(2, currentStepId),
                    `${routingConstants.process}/${projectId}/(project:area-definition)`
                ),
                new Step(
                    3,
                    'navigation.steps.3rdStep',
                    this.getProjectStepStatus(3, currentStepId),
                    `${routingConstants.process}/${projectId}/(project:setup-default-objects)`
                ),
                new Step(
                    4,
                    'navigation.steps.4thStep',
                    this.getProjectStepStatus(4, currentStepId),
                    `${routingConstants.process}/${projectId}/(project:objects)`
                ),
                new Step(
                    5,
                    'navigation.steps.5thaStep',
                    this.getProjectStepStatus(5, currentStepId),
                    `${routingConstants.process}/${projectId}/(project:manage-users)`
                ),
            ];
        } else {
            const returnArray = [
                new Step(
                    1,
                    'navigation.steps.1stStep',
                    this.getProjectStepStatus(1, currentStepId),
                    `${routingConstants.process}/${projectId}/(project:details)`
                ),
                new Step(
                    2,
                    'navigation.steps.2ndStep',
                    this.getProjectStepStatus(2, currentStepId),
                    `${routingConstants.process}/${projectId}/(project:area-definition)`
                ),
                new Step(
                    3,
                    'navigation.steps.3rdStep',
                    this.getProjectStepStatus(3, currentStepId),
                    `${routingConstants.process}/${projectId}/(project:setup-default-objects)`
                ),
                new Step(
                    4,
                    'navigation.steps.4thStep',
                    this.getProjectStepStatus(4, currentStepId),
                    `${routingConstants.process}/${projectId}/(project:objects)`
                ),
                new Step(
                    5,
                    'navigation.steps.5thStep',
                    this.getProjectStepStatus(5, currentStepId),
                    `${routingConstants.process}/${projectId}/(project:create-default-designs)`
                ),
                new Step(
                    6,
                    'navigation.steps.5thaStep',
                    this.getProjectStepStatus(6, currentStepId),
                    `${routingConstants.process}/${projectId}/(project:manage-users)`
                ),
            ];

            return returnArray;
        }
    }

    private static getProjectStepStatus(
        step: number,
        currentSavedStep: number
    ) {
        if (currentSavedStep === 0) {
            return PermitStepStatusEnum.untouched;
        }

        const nextSavedStep = currentSavedStep + 1;
        if (step <= currentSavedStep) {
            return PermitStepStatusEnum.completed;
        } else if (step === nextSavedStep) {
            return PermitStepStatusEnum.inProgress;
        } else {
            return PermitStepStatusEnum.untouched;
        }
    }
}
