import {
    Component,
    OnInit,
    OnDestroy,
    ViewContainerRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProjectDetailsService } from '../../project-shared/project-details/project-details.service';

import {
    DesignProposal,
    PathObject,
    Project,
    FurbanUtil,
    ThreeStateEnum,
    DesignProposalService,
    PathObjectsService,
    MapService,
    AuthService,
    ReportService,
    ModalManager,
    ErrorService,
    Report,
    CustomToasterComponent,
    routingConstants,
    Comment,
    CustomToasterService,
    CommentsComponent,
    MediaService,
} from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

@Component({
    selector: 'furban-design-proposal-details',
    templateUrl: './design-proposal-details.component.html',
    styleUrls: ['./design-proposal-details.component.scss'],
})
export class DesignProposalDetailsComponent implements OnInit, OnDestroy {
    @ViewChild('comments') commentsRef: CommentsComponent;

    public dpId: string;
    public commentId: number;
    public designProposal: DesignProposal;
    public userObjects: PathObject[];
    public project: Project;
    public noOfReportsToPrevent = 3;
    public threeState: string = ThreeStateEnum.view;
    public threeIsPrepared = false;
    public isViewerOnly: boolean;
    public headerBackRoute: string;
    public isRightMenuVisible = !FurbanUtil.isMobile();
    public isMobile = FurbanUtil.isMobile();

    private listenToGetDetails = false;
    private subscriptions;

    constructor(
        private dpService: DesignProposalService,
        private pathObjectsService: PathObjectsService,
        private route: ActivatedRoute,
        private mapService: MapService,
        private projectService: ProjectDetailsService,
        private authService: AuthService,
        private router: Router,
        private reportService: ReportService,
        private modal: ModalManager,
        public viewContainerRef: ViewContainerRef,
        private errorService: ErrorService,
        private customToasterService: CustomToasterService,
        private translateService: TranslateService,
        private mediaService: MediaService,
        private location: Location
    ) { }

    ngOnInit(): void {
        this.subscribeToRoute();
        this.subscribeToNavigation();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public get isLoggedIn(): boolean {
        return this.authService.isLoggedIn();
    }

    public get userProfileId(): string {
        return this.designProposal.userProfile?.userProfileId;
    }

    public getMedia(mediaId): string {
        return this.mediaService.getMedia(mediaId);
    }

    public goBack(): void {
        if (this.authService.isLoggedIn()) {
            this.goBackIfLoggedIn();
        } else {
            this.goBackIfNotLoggedIn();
        }
    }

    public isNotLoggedInAndIsNotPublic(): boolean {
        return !this.authService.isLoggedIn() && !this.isViewerOnly;
    }

    public isLoggedInOrIsNotViewOnly(): boolean {
        return this.authService.isLoggedIn() || !this.isViewerOnly;
    }

    public shouldDisplayWarning(): boolean {
        return (
            (this.designProposal.reportsCount > 0 &&
                this.authService.isAdmin()) ||
            (this.designProposal.reportsCount > this.noOfReportsToPrevent - 1 &&
                !this.authService.isAdmin())
        );
    }

    public getTooltipTextForWarning(): string {
        if (
            this.designProposal.reportsCount > 0 &&
            this.authService.isAdmin()
        ) {
            return this.translateService.instant(
                'report.reportAdminInfoTooltip',
                { noOfReporters: this.designProposal.reportsCount }
            );
        }

        if (
            this.designProposal.reportsCount > this.noOfReportsToPrevent - 1 &&
            !this.authService.isAdmin()
        ) {
            return this.translateService.instant('report.reportInfoMessage');
        }
        return '';
    }

    public shouldDisplayMenu(): boolean {
        return (
            this.authService.isAdmin() && this.designProposal.reportsCount > 0
        );
    }

    public report(comment: Comment): void {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'report.warningCommentMessage',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (res) {
                    this.saveReport(comment);
                }
            });
    }

    public onThreeJSCommentSave(comment: Comment): void {
        const parentComment = comment;
        parentComment.childComments = [];
        this.designProposal.comments.push(parentComment);

        if (!this.commentsRef) {
            return;
        }
        this.commentsRef.sortedComments.push(parentComment);
    }

    public saveReport(comment: Comment): void {
        const report = new Report();
        report.reportType = 1;
        report.reportCommentId = comment.commentId;
        report.reportDesignProposalId = comment.designProposalId;
        report.reportingUserProfile =
            this.authService.userProfile.userProfileId;
        report.isActive = true;

        this.reportService.saveReport(report).subscribe((data) => {
            this.customToasterService.openCustomToaster(
                CustomToasterComponent,
                'check_circle',
                'success',
                'report.reportCommentSuccesfully',
                1000
            );
        });
    }

    public isAdmin(): boolean {
        return this.authService.isAdmin();
    }

    public updateRightSideMenuVisibilty(isRightSideMenuVisible: boolean): void {
        this.isRightMenuVisible = isRightSideMenuVisible;
    }

    public showDpPrice(): boolean {
        return !!this.project.price && !!this.designProposal.price;
    }

    public deleteReporting(designProposal: DesignProposal): void {
        const report = new Report();
        report.reportType = 0;
        report.reportDesignProposalId = designProposal.designProposalId;

        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'report.reportResetMessage',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .pipe(
                switchMap((result) => {
                    if (!result) {
                        return;
                    } else {
                        return this.reportService.deleteReport(report);
                    }
                })
            )
            .subscribe((data) => {
                designProposal.reportsCount = 0;
                this.customToasterService.openCustomToaster(
                    CustomToasterComponent,
                    'check_circle',
                    'success',
                    'report.reportResetSuccess',
                    1000
                );
            });
    }

    private subscribeToRoute(): void {
        this.subscriptions = combineLatest([
            this.route.params,
            this.route.data,
        ]).subscribe((data: any) => {
            this.dpId = data[0]['id'];
            this.commentId = parseInt(data[0]['commentId'], 10);

            this.isViewerOnly = data[1]['isViewerOnly'] as boolean;

            this.listenToGetDetails = true;
            this.subscribeToErrorService();
            this.getDesignProposalDetails();
        });
    }

    private getProjectDetailsAndObjects(requests): void {
        forkJoin(requests).subscribe((result) => {
            this.userObjects = result[1] ? (result[1] as PathObject[]) : [];
            this.project = result[3] as Project;
            if (result[2] != null) {
                this.userObjects = this.userObjects.concat(
                    result[2] as PathObject[]
                );
            }
            this.threeIsPrepared = true;
        });
    }

    private getDesignProposalDetails(): void {
        this.dpService.getDetails(this.dpId).subscribe((data) => {
            this.listenToGetDetails = false;
            this.designProposal = data;
            if (this.isViewerOnly) {
                this.headerBackRoute =
                    routingConstants.publicProject +
                    '/' +
                    this.designProposal.projectId;
            }
            const requests = [
                this.mapService.getPolygon(this.designProposal.projectId),
                this.pathObjectsService.getPathDefaultObjects(
                    this.designProposal.projectId
                ),
            ];

            this.addDesignSpecificObjectsRequest(requests);
            this.addProjectRequest(requests);
            this.getProjectDetailsAndObjects(requests);
        });
    }

    private addDesignSpecificObjectsRequest(requests): void {
        if (this.designProposal.isAdminDesign) {
            requests.push(
                this.pathObjectsService.getPathObjectsForDefaultDP(
                    this.designProposal.projectId,
                    this.designProposal.designProposalId
                )
            );
        } else if (this.designProposal.groupId) {
            requests.push(
                this.pathObjectsService.getLivePathObjects(
                    this.designProposal.designProposalId
                )
            );
        } else {
            requests.push(
                this.pathObjectsService.getPathObjects(
                    this.designProposal.projectId,
                    this.designProposal.userProfile.userProfileId
                )
            );
        }
    }

    private addProjectRequest(
        requests: Observable<any | PathObject[]>[]
    ): void {
        if (this.authService.isLoggedIn()) {
            requests.push(
                this.projectService.getProject(this.designProposal.projectId)
            );
        } else {
            requests.push(
                this.projectService.getPublicProject(
                    this.designProposal.projectId
                )
            );
        }
    }

    private subscribeToErrorService(): void {
        this.errorService.getError().subscribe((error) => {
            if (this.listenToGetDetails) {
                this.goBack();
            }
            this.listenToGetDetails = false;
        });
    }

    private goBackIfLoggedIn(): void {
        if (this.authService.isAdmin()) {
            this.location.back();
        } else if (this.authService.isPioneer()) {
            if (this.designProposal && this.designProposal.projectId) {
                this.router.navigate([
                    routingConstants.initiativesProject,
                    this.designProposal.projectId,
                    'project-designs',
                ]);
            } else {
                this.router.navigate([routingConstants.initiativesProjectList]);
            }
        } else {
            this.router.navigate([routingConstants.designs, this.project?.projectId]);
        }
    }

    private goBackIfNotLoggedIn(): void {
        if (this.designProposal && this.designProposal.projectId) {
            this.router.navigate([
                routingConstants.takingPart,
                this.designProposal.projectId,
            ]);
        } else {
            this.router.navigate([routingConstants.takingPart]);
        }
    }

    private subscribeToNavigation(): void {
        this.router.events.subscribe((event: RouterEvent) => {
            this.dpService.checkIfShouldReset(event);
        });
    }
}
