import { Component, OnInit } from '@angular/core';
import { FurbanLoaderService } from '../furban-loader/furban-loader.service';
import { ScriptService } from '../_services/script.service';
import { TranslateService } from '@ngx-translate/core';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { CustomToasterComponent } from '../custom-toaster/custom-toaster.component';
import { PointXY } from '../_models/point-xy';
import { FormBuilder } from '@angular/forms';
import { GEOCONFIG_IDENTIFY } from '../_configs/map-identify.config';

const mapContainer = 'mapViewer';
@Component({
    selector: 'furban-base-map',
    templateUrl: './base-map.component.html',
})
export class BaseMapComponent implements OnInit {
    public GeocompApi: any;
    public layers: any[];
    public layerType: string;

    constructor(
        public script: ScriptService,
        public loaderService: FurbanLoaderService,
        public translateService: TranslateService,
        public snackBar: MatSnackBar,
        public formBuilder: FormBuilder
    ) {}

    ngOnInit(): void {
        this.loadMapDependencies();
    }

    public loadMapDependencies(): void {
        this.script
            .load(
                'jquery',
                'olJs',
                'olrotateFeatureJs',
                'centricGeoComp',
                'passiveEvents'
            )
            .then((data) => {
                this.createMap(mapContainer);
                this.loaderService.hide();
                this.initAfterLoadingScripts();
            })
            .catch((error) => {
                this.loaderService.hide();
                const snackBarRef = this.snackBar.openFromComponent(
                    CustomToasterComponent,
                    {
                        verticalPosition:
                            'bottom' as MatSnackBarVerticalPosition,
                        horizontalPosition:
                            'right' as MatSnackBarHorizontalPosition,
                        duration: 3000,
                        data: {
                            icon: 'error',
                            message:
                                this.translateService.instant(
                                    'admin.map.mapError'
                                ),
                            action: this.translateService.instant(
                                'generic.reload'
                            ),
                            type: 'error',
                        },
                    }
                );
                snackBarRef.onAction().subscribe(() => {
                    window.location.reload();
                });
            });
    }

    public createMap(mapName: string): void {
        GEOCONFIG_IDENTIFY.container = mapName;
        this.layers = GEOCONFIG_IDENTIFY.layers;
        this.GeocompApi = new this.GeocompApi(GEOCONFIG_IDENTIFY);
        
    }

    public drawPolygonOnMap(pointsOfPolygon: Array<PointXY>): void {
        const exteriorRing = [];

        for (const coordinate of pointsOfPolygon) {
            exteriorRing.push({ xLat: coordinate.X, yLon: coordinate.Y });
        }

        const polygon = { exteriorRing: exteriorRing, interiorRings: [] };
        this.GeocompApi?.drawPolygon(polygon, 'Meters');
    }

    public selectLayer(layerToSelect: string): void {
        this.layers.forEach((layer) => {
            if (layer.name === layerToSelect) {
                const layersVisibility = [
                    { layerName: layer.name, visible: true },
                ];

                layer.visible = true;
                this.GeocompApi.setLayerVisibility(layersVisibility);
            } else {
                layer.visible = false;
            }
        });
    }

    public changeLayer(): void {
        const curentLayer = this.GeocompApi.getVisibleLayers()[0];
        const otherLayer = this.layers.find((x) => x.name !== curentLayer);

        const visibilityLayers = [
            { layerName: curentLayer, visible: false },
            { layerName: otherLayer.name, visible: true },
        ];

        this.GeocompApi.setLayerVisibility(visibilityLayers);
    }

    public switchLayer(event: any): void {
        if (this.layerType === 'Satelite') {
            this.layerType = 'Terain';
        } else {
            this.layerType = 'Satelite';
        }
        this.changeLayer();
    }

    public initAfterLoadingScripts(): void {
        // Override this method to execute actions once the scripts are loaded
    }

    public updateMapPosition(): void {
        if (!this.GeocompApi) {
            return;
        }
        this.GeocompApi.updateSize();
    }
}
