import { BubbleStyle } from './bubble-style';

export class ChartBubble {
    public translationLabel: string;
    public value: number;
    public percentage: string;
    public style: BubbleStyle;

    constructor(
        translationLabel: string,
        value: number,
        percentage: string,
        style: BubbleStyle
    ) {
        this.translationLabel = translationLabel;
        this.percentage = percentage;
        this.value = value;
        this.style = style;
    }
}
