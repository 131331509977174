<div class="important-space-between" mat-dialog-title>
    <h2>{{ 'admin.projectOverview.embededTitle' | translate }}</h2>

    <button class="ghost-button" (click)="closeDialog()">
        <span class="material-icons"> close </span>
    </button>
</div>

<mat-dialog-content>
    <span
        class="pointer"
        [cdkCopyToClipboard]="htmlText"
        (cdkCopyToClipboardCopied)="onCopyClipboard()"
    >
        {{ htmlText }}
    </span>
</mat-dialog-content>

<div class="buttons-right-container">
    <button
        class="primary-button"
        [cdkCopyToClipboard]="htmlText"
        (cdkCopyToClipboardCopied)="onCopyClipboard()"
    >
        <h6>{{ 'generic.copyEmbeded' | translate }}</h6>
    </button>
</div>
